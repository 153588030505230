import { Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import HeaderHelp from "../../components/Header/HeaderHelp/HeaderHelp";
import HeaderNotifications from "../../components/Header/HeaderNotifications/HeaderNotifications";
import useHeaderContent from "../../components/Header/HeaderProvider/useHeaderContent";
import HeaderSettings from "../../components/Header/HeaderSettings/HeaderSettings";
import ProjectSelectorWrapper from "../../components/ProjectSelectorWrapper/ProjectSelectorWrapper";
import { IPageParams } from "../type";
import useStyles from "./styles";
import HeaderShare from "../../components/Header/HeaderShare/HeaderShare";

interface IPageProps extends React.HTMLAttributes<HTMLElement> {
    noPadding?: boolean;
    noProjects?: boolean;
    noOverflow?: boolean;
}

const Page: React.FC<IPageProps> = ({ children, noPadding, noOverflow, noProjects }) => {
    const classes = useStyles();
    const { setHeaderContent, setHeaderSettings } = useHeaderContent();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const pageParams = useParams<IPageParams>();
    const location = useLocation();

    useEffect(() => {
        setHeaderContent(!noProjects && <ProjectSelectorWrapper location={location} pageParams={pageParams} />);
        setHeaderSettings(
            <>
                <HeaderNotifications isMobile={!isDesktop} />
                <HeaderShare isMobile={!isDesktop} />
                <HeaderSettings isMobile={!isDesktop} />
                <HeaderHelp isMobile={!isDesktop} />
            </>
        );
    }, [isDesktop, pageParams]);

    return (
        <Box
            className={clsx(classes.root, {
                [classes.noPadding]: noPadding,
                [classes.noOverflow]: noOverflow,
            })}
        >
            {children}
        </Box>
    );
};

export default Page;
