import { defineMessages } from "react-intl";

export default defineMessages({
    errorStepNotValidType: {
        id: "components.mapping.stepElementWrapper.errorStepNotValidType",
        defaultMessage: "Model position",
    },
    errorUnknownStep: {
        id: "components.mapping.stepElementWrapper.errorUnknownStep",
        defaultMessage: "Unknown step",
    },
});
