import { Box, Button, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import MappingDetailSummary from "../MappingDetailSummary/MappingDetailSummary";
import MappingDetailViewer from "../MappingDetailViewer/MappingDetailViewer";
import StepContentWrapper from "../StepContentWrapper/StepContentWrapper";
import MappingDetailStepper from "../Stepper/Stepper";
import messages from "./messages";
import useStyles from "./styles";
import { MappingStep } from "./type";
import MappingDetailViewObserver from "../MappingDetailViewObserver/MappingDetailViewObserver";

interface IMappingDetailViewProps {}

const MappingDetailView: React.FC<IMappingDetailViewProps> = () => {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState<MappingStep>(MappingStep.ELEMENT);

    const handleSetStep = (activeStep: MappingStep) => () => {
        setActiveStep(activeStep);
    };

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // translations
    const intl = useIntl();
    const transNext = intl.formatMessage({ ...messages.next });
    const transBack = intl.formatMessage({ ...messages.back });

    return (
        <>
            <MappingDetailViewObserver />
            <Box className={classes.detailViewBox}>
                <Box className={classes.summaryBox}>
                    <Paper className={classes.summaryPaper}>
                        <MappingDetailSummary />
                    </Paper>
                </Box>
                <Box className={classes.contentBox}>
                    <MappingDetailStepper activeStep={activeStep} handleSetStep={handleSetStep} />
                    <Box className={classes.stepperContent}>
                        <StepContentWrapper activeStep={activeStep} />
                    </Box>
                    <Box className={classes.stepBox}>
                        {activeStep !== MappingStep.ELEMENT && (
                            <Button
                                variant="contained"
                                color="default"
                                size="large"
                                onClick={handleBackStep}
                                className={classes.stepBackButton}
                            >
                                {transBack}
                            </Button>
                        )}
                        {activeStep !== MappingStep.MATERIAL && (
                            <Button variant="contained" color="secondary" size="large" onClick={handleNextStep}>
                                {transNext}
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box className={classes.viewerBox}>
                    <Paper className={classes.viewerPaper}>
                        <MappingDetailViewer activeStep={activeStep} />
                    </Paper>
                </Box>
            </Box>
        </>
    );
};

export default MappingDetailView;
