import { Box } from "@material-ui/core";
import React from "react";
import NewProjectWrapper from "../../components/NewProject/NewProjectWrapper/NewProjectWrapper";
import Page from "../Page/Page";
import useStyles from "./styles";

const ProjectNewPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page noProjects noOverflow>
            <Box className={classes.box}>
                <NewProjectWrapper />
            </Box>
        </Page>
    );
};

export default ProjectNewPage;
