import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { IAttachedMaterial, MaterialType } from "../../../../packages/Api/data/elements/types";
import { onMaterialCatalogue, useMappingAttachedMaterialPhysical } from "../../../../redux/mapping";
import Icon from "../../../Icon/Icon";
import MaterialDialog from "../MaterialDialog/MaterialDialog";
import useStyles from "./styles";
import ExporterImporter from "../../../ExporterImporter/ExporterImporter";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import { exportMaterial, importMaterial } from "../../../../packages/Api/data/material/client";
import { useKeycloak } from "@react-keycloak/web";

interface IMaterialDialogWrapperProps {}

const MaterialDialogWrapper: React.FC<IMaterialDialogWrapperProps> = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const attachedMaterialPhysical: IAttachedMaterial[] = useMappingAttachedMaterialPhysical();
    const dispatch = useDispatch<DispatchAction>();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
    const [addModeActive, setAddModeActive] = useState<boolean>(false);

    const materialDialogRef = useRef();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectedMaterialIds = (rowIDs: number[]) => {
        setSelectedMaterialIds(rowIDs);
    };

    const handleAddModeActive = (active: boolean) => {
        setAddModeActive(active);
    };

    // translations
    const intl = useIntl();
    const transExport = intl.formatMessage({ ...messages.export });
    const transImport = intl.formatMessage({ ...messages.import });
    const transMaterialCatalog = intl.formatMessage({ ...messages.materialCatalog });
    const transCreateNewMaterial = intl.formatMessage({ ...messages.createNewMaterial });
    const transAddPhysicalMaterialToPosition = intl.formatMessage({ ...messages.addPhysicalMaterialToPosition });

    const transButtonPartAddAdditional = intl.formatMessage({ ...messages.buttonPartAddAdditional });
    const transButtonPartMaterials = intl.formatMessage({ ...messages.buttonPartMaterials });
    const transButtonPartMaterial = intl.formatMessage({ ...messages.buttonPartMaterial });
    const transButtonPartToPosition = intl.formatMessage({ ...messages.buttonPartToPosition });

    return (
        <>
            <IconButton
                aria-label="add"
                color="primary"
                className={clsx("globalAddButton", classes.addButton)}
                onClick={handleOpen}
            >
                <Icon name="plus" size={20} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="material-position-dialog-title"
                aria-describedby="material-position-dialog-description"
                maxWidth={false}
                fullWidth
                fullScreen={fullScreen}
                className={classes.root}
                scroll="paper"
            >
                <DialogTitle id="material-position-dialog-title">
                    {transMaterialCatalog}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <MaterialDialog
                        ref={materialDialogRef}
                        addModeActive={addModeActive}
                        selectedMaterialIds={selectedMaterialIds}
                        handleAddModeActive={handleAddModeActive}
                        handleSelectedMaterialIds={handleSelectedMaterialIds}
                        handleClose={handleClose}
                    />
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                    {/*<NullableTooltip title="Create new material" placement="top">*/}
                    {/*    <IconButton*/}
                    {/*        aria-label="add"*/}
                    {/*        color="primary"*/}
                    {/*        className={clsx("globalAddButton", classes.addButton)}*/}
                    {/*        onClick={() => {*/}
                    {/*            // @ts-ignore*/}
                    {/*            materialDialogRef?.current?.handlePrepareAddWork();*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <Icon name="plus" size={20} />*/}
                    {/*    </IconButton>*/}
                    {/*</NullableTooltip>*/}
                    <Button
                        variant="outlined"
                        color="default"
                        size="medium"
                        onClick={() => {
                            // @ts-ignore
                            materialDialogRef?.current?.handlePrepareAddMaterial();
                        }}
                    >
                        {transCreateNewMaterial}
                    </Button>
                    <Box className={classes.exportImport}>
                        <ExporterImporter
                            fileName={"Material.xlsx"}
                            exportLabel={transExport}
                            importLabel={transImport}
                            exportCallback={(token, data) => {
                                exportMaterial(token);
                            }}
                            importCallback={importMaterial}
                            onImportDoneCallback={(response) => {
                                dispatch(onMaterialCatalogue({ token: keycloak?.token as string }))
                            }}
                        />
                    </Box>

                    <Box>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disabled={
                                attachedMaterialPhysical?.length === 1 ||
                                selectedMaterialIds?.length === 0 ||
                                selectedMaterialIds?.length > 1 ||
                                addModeActive
                            }
                            onClick={() => {
                                // @ts-ignore
                                materialDialogRef?.current?.handleAddMaterialToPosition(MaterialType?.PHYSICAL);
                                handleClose();
                            }}
                        >
                            {transAddPhysicalMaterialToPosition}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disabled={selectedMaterialIds?.length === 0 || addModeActive}
                            onClick={() => {
                                // @ts-ignore
                                materialDialogRef?.current?.handleAddMaterialToPosition(MaterialType?.ADDITIONAL);
                                handleClose();
                            }}
                        >
                            {transButtonPartAddAdditional} {selectedMaterialIds?.length > 1 ? transButtonPartMaterials : transButtonPartMaterial} {transButtonPartToPosition}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MaterialDialogWrapper;
