import { useContext } from "react";
import { HeaderContext, IHeaderContext } from "./HeaderProvider";

const useHeaderContent = () => {
    const { headerContent, headerSettings, setHeaderContent, setHeaderSettings } = useContext<IHeaderContext>(
        HeaderContext
    );

    return {
        headerContent,
        headerSettings,
        setHeaderContent,
        setHeaderSettings,
    };
};

export default useHeaderContent;
