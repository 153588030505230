import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultSwitcherSize } from "../EyeSwitcher/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            display: "inline-flex",
            flexFlow: "row wrap",
            width: `${defaultSwitcherSize}px`,
            height: `${defaultSwitcherSize}px`,
            borderRadius: "50px",
            background: theme.palette.grey["100"],
            "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
                marginLeft: 0,
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
            },
        },
        button: {
            margin: `0 !important`,
            width: `calc(${defaultSwitcherSize / 2}px)`,
            height: `calc(${defaultSwitcherSize / 2}px)`,
            backgroundColor: theme.palette.grey["100"],
            borderRadius: 0,
            zIndex: 20,
            "&:hover": {
                backgroundColor: darken(theme.palette.grey["100"], 0.05),
            },
            "& svg": {
                fill: theme.palette.grey["500"],
            },
            "&.Mui-selected": {
                backgroundColor: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: darken(theme.palette.common.white, 0.05),
                },
            },
            "&.active": {
                backgroundColor: theme.palette.common.white,
                "& svg": {
                    fill: theme.palette.success.dark,
                },
                "&:hover": {
                    backgroundColor: darken(theme.palette.common.white, 0.05),
                },
            },
            "&.topLeft": {
                position: "absolute",
                borderTopLeftRadius: "50px !important",
                "&.extern": {
                    top: "-28px",
                    left: "-27px",
                    width: "78px",
                    height: "78px",
                    borderTopLeftRadius: "74px !important",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    "& svg": {
                        position: "absolute",
                        top: "25px",
                        left: "25px",
                    },
                    "&.active": {
                        top: "-4px",
                        left: "-4px",
                        width: "54px",
                        height: "54px",
                        backgroundColor: theme.palette.success.dark,
                        borderTopLeftRadius: "52px !important",
                        "&:hover": {
                            backgroundColor: theme.palette.success.dark,
                        },
                    },
                },
            },
            "&.topRight": {
                position: "absolute",
                borderTopRightRadius: "50px !important",
                left: `calc(${defaultSwitcherSize / 2}px)`,
                "&.extern": {
                    top: "-28px",
                    left: "50px",
                    width: "78px",
                    height: "78px",
                    borderTopRightRadius: "74px !important",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    "& svg": {
                        position: "absolute",
                        top: "25px",
                        right: "25px",
                    },
                    "&.active": {
                        top: "-4px",
                        left: "51px",
                        width: "54px",
                        height: "54px",
                        backgroundColor: theme.palette.success.dark,
                        borderTopRightRadius: "52px !important",
                        "&:hover": {
                            backgroundColor: theme.palette.success.dark,
                        },
                    },
                },
            },
            "&.bottomLeft": {
                position: "absolute",
                width: `${defaultSwitcherSize / 2}px`,
                top: `calc(${defaultSwitcherSize / 2}px - 1px)`,
                borderBottomLeftRadius: "50px !important",
                "&.extern": {
                    left: "-27px",
                    width: "78px",
                    height: "78px",
                    borderBottomLeftRadius: "74px !important",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    "& svg": {
                        position: "absolute",
                        bottom: "25px",
                        left: "25px",
                    },
                    "&.active": {
                        bottom: "-4px",
                        left: "-4px",
                        width: "54px",
                        height: "54px",
                        backgroundColor: theme.palette.success.dark,
                        borderBottomLeftRadius: "52px !important",
                        "&:hover": {
                            backgroundColor: theme.palette.success.dark,
                        },
                    },
                },
            },
            "&.bottomRight": {
                position: "absolute",
                width: `${defaultSwitcherSize / 2}px`,
                top: `calc(${defaultSwitcherSize / 2}px - 1px)`,
                left: `calc(${defaultSwitcherSize / 2}px)`,
                borderBottomRightRadius: "50px !important",
                "&.extern": {
                    left: "50px",
                    width: "78px",
                    height: "78px",
                    borderBottomRightRadius: "74px !important",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    "& svg": {
                        position: "absolute",
                        bottom: "25px",
                        right: "25px",
                    },
                    "&.active": {
                        bottom: "-4px",
                        left: "50px",
                        width: "54px",
                        height: "54px",
                        backgroundColor: theme.palette.success.dark,
                        borderBottomRightRadius: "52px !important",
                        "&:hover": {
                            backgroundColor: theme.palette.success.dark,
                        },
                    },
                },
            },
            "&.bottomFull": {
                position: "absolute",
                width: `${defaultSwitcherSize}px`,
                top: `calc(${defaultSwitcherSize / 2}px - 1px)`,
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "0 !important",
                borderBottomLeftRadius: "50px !important",
                borderBottomRightRadius: "50px !important",
                paddingTop: "30px",
                "&.extern": {
                    position: "absolute",
                    top: `calc(${defaultSwitcherSize / 2}px - 1px)`,
                    left: "-27px",
                    width: `calc(${defaultSwitcherSize}px + 55px)`,
                    height: `calc(${defaultSwitcherSize / 2}px + 28px)`,
                    borderTopLeftRadius: "0 !important",
                    borderTopRightRadius: "0 !important",
                    borderBottomLeftRadius: "74px !important",
                    borderBottomRightRadius: "74px !important",
                    alignItems: "flex-end",
                    "& svg": {
                        position: "absolute",
                        bottom: "7px",
                        left: "69px",
                    },
                    "&.active": {
                        left: "-4px",
                        width: `calc(${defaultSwitcherSize}px + 8px)`,
                        height: `calc(${defaultSwitcherSize / 2}px + 4px)`,
                        backgroundColor: theme.palette.success.dark,
                        borderBottomLeftRadius: "54px !important",
                        borderBottomRightRadius: "54px !important",
                    },
                },
            },
        },
        buttonExtern: {
            position: "absolute",
            zIndex: 10,
            backgroundColor: theme.palette.text.secondary,
        },
    })
);

export default useStyles;
