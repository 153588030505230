import { Box } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

interface IPushpinTitleProps {
    title: string;
}

const PushpinTitle: React.FC<IPushpinTitleProps> = ({ title }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <p>{title}</p>
        </Box>
    );
};

export default PushpinTitle;
