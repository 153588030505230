import { defineMessages } from "react-intl";

export default defineMessages({
    everythingMapped: {
        id: "components.mapping.masterDataTree.mappingLight.everythingMapped",
        defaultMessage: "Everything mapped successfully",
    },
    oneError: {
        id: "components.mapping.masterDataTree.mappingLight.oneError",
        defaultMessage: "One mapping problem",
    },
    multipleErrors: {
        id: "components.mapping.masterDataTree.mappingLight.multipleErrors",
        defaultMessage: "More than one mapping problems",
    },
    notCalculable: {
        id: "components.mapping.masterDataTree.mappingLight.notCalculable",
        defaultMessage: "Mapping not calculable",
    },
    itemElement: {
        id: "components.mapping.masterDataTree.mappingLight.itemElement",
        defaultMessage: "Element lookup defined",
    },
    itemAttributes: {
        id: "components.mapping.masterDataTree.mappingLight.itemAttributes",
        defaultMessage: "Obligatory attributes mapping defined",
    },
    itemMaterial: {
        id: "components.mapping.masterDataTree.mappingLight.itemMaterial",
        defaultMessage: "Physical material assigned",
    },
    itemManual: {
        id: "components.mapping.masterDataTree.mappingLight.itemManual",
        defaultMessage: "Manual price assigned",
    },
    itemSubcontractor: {
        id: "components.mapping.masterDataTree.mappingLight.itemSubcontractor",
        defaultMessage: "Subcontractor price assigned",
    },
    itemDerivedCategories: {
        id: "components.mapping.masterDataTree.mappingLight.itemDerivedCategories",
        defaultMessage: "Derived categories mapped correctly",
    },
});
