import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: theme.spacing(1),
        },
        icon: {
            marginLeft: theme.spacing(1),
            fill: theme.palette.text.primary,
        },
        listRoot: {
            minWidth: "162px",
        },
        listItemTextPrimary: {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
        listItemTextLoading: {
            textAlign: "center",
        },
        isMapped: {
            paddingLeft: theme.spacing(1),
            "& svg": {
                width: theme.spacing(1.8),
                height: theme.spacing(1.8),
                marginBottom: "-3px",
            },
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
        link: {
            width: "100%",
            color: theme.palette.text.primary,
            textDecoration: "none",
            "&:hover": {
                textDecoration: "none",
            },
        }
    })
);

export default useStyles;
