import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inline: {
      display: "inline-block",
      maxWidth: "100%"
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      padding: "5px",
      borderRadius: "5px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      backgroundColor: theme.palette.grey[100]
    },
    wrapperTooltip: {
      display: "flex",
      alignItems: "center",
    },
    operator: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(12),
      borderRadius: "3px",
      padding: "3px 4px 1px 4px",
      margin: "0 10px",
      lineHeight: "1"
    },
    operatorText: {
      fontSize: theme.typography.pxToRem(10),
      padding: "3px 4px",
    },
    attributePsetWrapper: {
      maxWidth: "70%"
    },
    attribute: {
      fontSize: theme.typography.pxToRem(11),
      marginBottom: "5px",
      lineHeight: "1",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    pset: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: "1",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    value: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: "1",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    popover: {
      pointerEvents: 'none',
    },
    popoverInner: {
      padding: ".5rem .7rem"
    },
  })
);

export default useStyles;
