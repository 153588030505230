import { Box, Paper } from "@material-ui/core";
import clsx from "clsx";
import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import { useGetProjectQuery } from "../../../graphql/generated/graphql";
import { onSelectViewable, useInstancesAttributes, useInstancesAttributesLoading } from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { getMainProjectFileViewablesFromProject, getUrnFromProject } from "../../Viewer/utils/Project";
import ViewerLight from "../../Viewer/ViewerLight/ViewerLight";
import useStyles from "./styles";
import { ExternalSystem } from "../../Viewer/type";
import MappingDetailInstanceProperties, { IMappingDetailInstanceProperties } from "../MappingDetailInstanceProperties/MappingDetailInstanceProperties";
import { PropertyHelper } from "../../Viewer/helpers/propertyHelper";
import OverlayProgress from "../../OverlayProgress/OverlayProgress";
import { useDispatch } from "react-redux";


interface IMappingDetailViewerProps {
    activeStep: number;
}

const MappingDetailViewer: React.FC<IMappingDetailViewerProps> = ({ activeStep }) => {
    const classes = useStyles();
    const activeProject: ProjectData | undefined = useActiveProject();

    const instancesLoading = useInstancesAttributesLoading();
    const instances = useInstancesAttributes();

    const [instanceViewerIds, setInstanceViewerIds] = useState<number[]|undefined>();

    const dispatch = useDispatch<DispatchAction>();

    const [ propertiesData, setPropertiesData ] = useState<IMappingDetailInstanceProperties>();

    const { data, loading, error } = useGetProjectQuery({
        variables: {
            code: activeProject?.code,
        },
    });

    useEffect(() => {
        if(instances?.instanceIDs) {
            const ids = instances?.instanceIDs?.map(a => parseInt(a))
            setInstanceViewerIds(ids);
        }

    }, [instances]);


    const project = data?.projects?.[0];
    const urn = getUrnFromProject(project);


    const handleSelectViewable = (viewable: any) => {
        const viewables = getMainProjectFileViewablesFromProject(project);
        const viewableGuid = viewable.data.children.find(c => c.type == "resource").guid;
        const matchedViewable = viewables?.find(v => v?.projectFileVersionViewableExternals?.find(ve => ve?.externalSystem?.code == ExternalSystem.Forge)?.externalID == viewableGuid);
        if (matchedViewable) {
            dispatch(onSelectViewable({viewable: matchedViewable}))
        }
    }

    const handleShowProperties = (viewer: Autodesk.Viewing.Viewer3D, objectIds: number[]) => {
        if (objectIds?.length === 1) {
            viewer.getProperties(objectIds[0], (props) => {
                const propertySets = PropertyHelper.getInstancePropertySetFromResult(props);

                setPropertiesData({
                    instancesIDs: objectIds,
                    psets: propertySets
                })
            })
        }
        else {
            setPropertiesData({
                instancesIDs: objectIds,
                psets: []
            })
        }
    }

    return (
        <Box className={classes.root}>
            <OverlayProgress isLoading={instancesLoading} message={"Searching for instances..."} />
            <Paper className={clsx(classes.paper, classes.paperViewer)}>
                <Box className={classes.paperViewerBox}>
                    {activeProject && (
                        <>
                            {/*
                            <Paper classes={{ root: classes.instancesPaper }}>
                                <Typography variant="body2">Instances</Typography>
                                <List component="nav" aria-label="pages" classes={{ root: classes.instancesListRoot }}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                                        <ListItem
                                            key={item}
                                            button
                                            selected={item === selected}
                                            onClick={() => setSelected(item)}
                                            classes={{
                                                root: classes.instancesListItemRoot,
                                                selected: classes.instancesListItemSelected,
                                            }}
                                        >
                                            <ListItemText
                                                classes={{ root: classes.instancesListItemText }}
                                                primary={`${detailPosition?.code} - #${item}`}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                            */}
                            <ViewerLight
                                projectId={activeProject?.externalData?.[0]?.projectUrn}
                                urn={urn}
                                showViewables
                                onSelectViewable={handleSelectViewable}
                                isolatedObjects={instanceViewerIds}
                                onSelectObject={handleShowProperties}
                            />
                        </>
                    )}
                </Box>
            </Paper>
            <MappingDetailInstanceProperties
                instancesIDs={propertiesData?.instancesIDs}
                psets={propertiesData?.psets}
            />
        </Box>
    );
};

export default MappingDetailViewer;
