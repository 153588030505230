import { createSelector } from "reselect";
import { MaterialType } from "../../packages/Api/data/elements/types";
import { IMappingModuleState, IMappingState } from "./reducer";

export function selectMappingState(state: IMappingModuleState): IMappingState {
    return state.mappingState;
}

// mapping positions
export const selectMappingPositionsLoading = createSelector(selectMappingState, (state) => state.master.loading);
export const selectMappingPositions = createSelector(selectMappingState, (state) => state.master.positions);
export const selectMappingPositionsDeleteResult = createSelector(selectMappingState, (state) => state.master.deleteResult);
export const selectIndexedPositions = createSelector(selectMappingState, (state) => state.master.indexedPositions);

// catalog positions
export const selectMappingCatalogLoading = createSelector(selectMappingState, (state) => state.catalog.loading);
export const selectMappingCatalogPositionIDs = createSelector(selectMappingState, (state) => state.catalog.positionIDs);

// checked master/catalog
export const selectMappingCheckedMaster = createSelector(selectMappingState, (state) => state.master.checked);
export const selectMappingCheckedCatalog = createSelector(selectMappingState, (state) => state.catalog.checked);

// catalog
export const selectDetailPosition = createSelector(selectMappingState, (state) => state.catalog.detailPosition);
export const selectMasterLeafPositions = createSelector(selectMappingState, (state) => state.master.leafPositions);
export const selectCatalogLeafPositions = createSelector(selectMappingState, (state) => state.catalog.leafPositions);
export const selectDetailContext = createSelector(selectMappingState, (state) => state.stepper.context);

// element query
export const selectElementQueryLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.queryBuilder?.loading
);
export const selectElementQuery = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.queryBuilder?.query
);
export const selectElementQueryPsets = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.queryBuilder?.psets
);
export const selectElementQueryAttributes = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.queryBuilder?.attributes
);
export const selectElementQueryOperators = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.queryBuilder?.operators
);

// selectedViewable
export const selectViewable = createSelector(
    selectMappingState,
    (state) => state?.stepper?.viewer?.selectedViewable
);

// instance attributes
export const selectInstancesAttributes = createSelector(
    selectMappingState,
    (state) => state?.stepper?.matchingInstancesAttributes?.data
);
export const selectInstancesAttributesLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.matchingInstancesAttributes?.loading
);

// derived positions
export const selectMappingDerivedPositionsLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.derivedPositions?.loading
);
export const selectMappingDerivedPositions = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.derivedPositions?.positions
);

// basic positions
export const selectMappingBasicPositionsLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.basicPositions?.loading
);
export const selectMappingBasicPositions = createSelector(
    selectMappingState,
    (state) => state?.stepper?.element?.basicPositions?.positions
);

// attribute
export const selectMappingAttributeLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.attribute?.loading
);
export const selectMappingAttribute = createSelector(
    selectMappingState,
    (state) => state?.stepper?.attribute?.attribute
);
export const selectMappingAttributeAutocompletePsets = createSelector(
    selectMappingState,
    (state) => state?.stepper?.attribute?.autocompletePsets
);
export const selectMappingAttributeAutocompleteAttributes = createSelector(
    selectMappingState,
    (state) => state?.stepper?.attribute?.autocompleteAttributes
);
export const selectMappingAttributeFormValues = createSelector(
    selectMappingState,
    (state) => state?.stepper?.attribute?.formValues
);
export const selectMappingAttributeNormalizationFunctions = createSelector(
    selectMappingState,
    (state) => state?.stepper?.attribute?.normalizationFunctions
);

// work catalogue
export const selectMappingWorkLoading = createSelector(selectMappingState, (state) => state?.stepper?.work?.loading);
export const selectMappingWorkCatalogue = createSelector(
    selectMappingState,
    (state) => state?.stepper?.work?.catalogue
);

// attached work
export const selectMappingAttachedWork = createSelector(
    selectMappingState,
    (state) => state?.stepper?.work?.attachedWork
);

// graphics pattern catalogue
export const selectMappingGraphicsPatternLoading = createSelector(selectMappingState, (state) => state?.stepper?.graphicsPattern?.loading);
export const selectMappingGraphicsPatternCatalogue = createSelector(
    selectMappingState,
    (state) => state?.stepper?.graphicsPattern?.catalogue
);

// graphics catalogue
export const selectMappingGraphicsLoading = createSelector(selectMappingState, (state) => state?.stepper?.graphics?.loading);
export const selectMappingGraphicsCatalogue = createSelector(
    selectMappingState,
    (state) => state?.stepper?.graphics?.catalogue
);

// attached graphics
export const selectMappingAttachedGraphics = createSelector(
    selectMappingState,
    (state) => state?.stepper?.graphics?.attachedGraphics
);

// equipment catalogue
export const selectMappingEquipmentLoading = createSelector(selectMappingState, (state) => state?.stepper?.equipment?.loading);
export const selectMappingEquipmentCatalogue = createSelector(
    selectMappingState,
    (state) => state?.stepper?.equipment?.catalogue
);

// attached work
export const selectMappingAttachedEquipment = createSelector(
    selectMappingState,
    (state) => state?.stepper?.equipment?.attachedEquipment
);

// material catalogue
export const selectMappingMaterialLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.material?.loading
);
export const selectMappingMaterialCatalogue = createSelector(
    selectMappingState,
    (state) => state?.stepper?.material?.catalogue
);

// attached material - all
export const selectMappingAttachedMaterial = createSelector(
    selectMappingState,
    (state) => state?.stepper?.material?.attachedMaterial
);

// attached material - PHYSICAL
export const selectMappingAttachedMaterialPhysical = createSelector(selectMappingState, (state) =>
    state?.stepper?.material?.attachedMaterial?.filter((am) => am?.materialType === MaterialType.PHYSICAL)
);

// attached material - ADDITIONAL
export const selectMappingAttachedMaterialAdditional = createSelector(selectMappingState, (state) =>
    state?.stepper?.material?.attachedMaterial?.filter((am) => am?.materialType === MaterialType.ADDITIONAL)
);

// unit price
export const selectMappingUnitPriceLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.unitPrice?.loading
);
export const selectMappingUnitPrice = createSelector(
    selectMappingState,
    (state) => state?.stepper?.unitPrice?.unitPrice
);

// update position basics
export const selectMappingUpdatePositionBasicsLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.position?.positionBasics?.loading
);
export const selectMappingUpdatePositionBasics = createSelector(
    selectMappingState,
    (state) => state?.stepper?.position?.positionBasics?.positionBasics
);

// get manual position
// update manual position
export const selectMappingManualPositionLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.position?.manualPosition?.loading
);
export const selectMappingManualPosition = createSelector(
    selectMappingState,
    (state) => state?.stepper?.position?.manualPosition?.manualPosition
);

// get position form configuration
export const selectMappingPositionFormConfigurationLoading = createSelector(
    selectMappingState,
    (state) => state?.stepper?.position?.formConfiguration?.loading
);
export const selectMappingPositionFormConfiguration = createSelector(
    selectMappingState,
    (state) => state?.stepper?.position?.formConfiguration?.formConfiguration
);
