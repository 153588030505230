import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        elementDescList: {
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: theme.spacing(1),
        },
        elementDescKey: {
            flex: 1,
            flexBasis: "100%",
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                flexBasis: "20%",
            },
        },
        elementDescValue: {
            flex: 1,
            whiteSpace: "nowrap",
        },
        elementNameValue: {
        },
        elementDescTerm: {
            flex: 1,
        },
        empty: {
            color: theme.palette.secondary.main,
            background: theme.palette.secondary[50],
            borderRadius: "20px",
            padding: "1px 7px",
            marginTop: theme.spacing(0.2),
            display: "inline-block",
            fontSize: theme.typography.pxToRem(11),
        },
        stronger: {
            fontWeight: 400,
        },
        queryComparator: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(11),
            padding: "3px 4px 1px",
            borderRadius: "3px",
            lineHeight: "1",
            margin: "4px 0",
            display: "inline-block",
        },
        skeleton: {
            borderRadius: theme.shape.borderRadius,
        },
        textField: {
            flex: 1,
            minWidth: "80px",
            margin: 0,
            marginRight: theme.spacing(1),
            "& input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& textarea": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& fieldset > legend > span": {
                paddingLeft: 0,
                paddingRight: "2px",
            },
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.grey[400],
            },
        },
        table: {
          marginTop: ".2rem",
          "& td": {
            borderColor: theme.palette.grey["100"],
            padding: "2px 15px 2px 0",
            fontSize: theme.typography.pxToRem(11),
          },
          "& tr:last-of-type td": {
            border: "0"
          }
        }
    })
);

export default useStyles;
