import { Box, ClickAwayListener } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import Icon from "../Icon/Icon";
import useStyles from "./styles";

interface IEyeSwitcherProps {
    onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void,
    onEyeClick: () => void,
    onClickAway: () => void,
    value: any
}

const EyeSwitcher: React.FC<IEyeSwitcherProps> = ({
    children,
    onChange,
    onEyeClick,
    onClickAway,
    value
}) => {
    const classes = useStyles();


    const handleChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
        if (onChange) {
            onChange(event, value);
        }
    };

    const handleDefault = () => {
        if(onEyeClick) {
            onEyeClick();
        }
    };

    const handleClickAway = () => {
        if(onClickAway) {
            onClickAway()
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <ToggleButtonGroup value={value} onChange={handleChange} aria-label="view" className={classes.root}>
                <Box className={classes.eye} onClick={handleDefault}>
                    <Icon name="eye" size={22} />
                </Box>
                {children}
            </ToggleButtonGroup>
        </ClickAwayListener>
    );
};

export default EyeSwitcher;
