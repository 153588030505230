import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            flexFlow: "column",
            padding: theme.spacing(1),
            flexBasis: "100%",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row",
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(4),
            },
        },
        boxSync: {
            display: "flex",
            flexFlow: "column",
            width: "100%",
            flexBasis: "100%",
            marginTop: theme.spacing(4),
            [theme.breakpoints.up("md")]: {
                flexBasis: "50%",
                marginTop: 0,
                paddingLeft: theme.spacing(2),
            },
            [theme.breakpoints.up("lg")]: {
                flexBasis: "40%",
                paddingLeft: theme.spacing(4),
            },
        },
        boxSyncInner: {
            display: "flex",
            flexFlow: "row",
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
    })
);

export default useStyles;
