import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ApolloError } from "apollo-client";
import React from "react";
import { useIntl } from "react-intl";
import { AttributeType, CostsPerCategoriesQuery } from "../../../graphql/generated/graphql";
import Formatter from "../../../utils/Formatter";
import messages from "./messages";
import useStyles from "./styles";

interface IDataTableTotalProps {
    data: CostsPerCategoriesQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

const DataTableTotal: React.FC<IDataTableTotalProps> = ({ data, loading, error }) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transTotalCost = intl.formatMessage({ ...messages.totalCost });

    return (
        <Box className={classes.totalBox}>
            {loading || error ? (
                <Skeleton variant="rect" className={classes.skeleton} />
            ) : (
                <Typography className={classes.totalPrice}>
                    <Formatter
                        value={data?.costsPerCategories?.totalAmount}
                        attributeType={data?.costsPerCategories?.priceAttributeType as AttributeType}
                    />
                </Typography>
            )}

            <Typography className={classes.totalText}>{transTotalCost}</Typography>
        </Box>
    );
};

export default DataTableTotal;
