import { DispatchAction } from "@iolabs/redux-utils";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ITree } from "../../packages/Api/data/tree/types";
import { IPageParams } from "../../pages/type";
import { useActiveProject } from "../../redux/project";
import { onLoadNode, onResetData, setExpandedNodes, useData, useExpandedNodes } from "../../redux/tree";
import { getParentPathParts, getPath } from "../../utils/Urn";
import { ProjectData } from "../ProjectSelectorWrapper/type";
import { DataType } from "../Tree/TreeWrapper/TreeWrapper";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

export interface BreadcrumbData {
    name: string;
    path: string;
}

interface IBreadcrumbProps {
    activeProjectId?: string;
    activeFoldersIds: string[];
}

const BreadcrumbWrapper: React.FC<IBreadcrumbProps> = ({ activeProjectId, activeFoldersIds }) => {
    const { accountUrn, projectUrn } = useParams<IPageParams>();
    const treeData = useData();
    const activeProject: ProjectData | undefined = useActiveProject();
    const expandedNodes = useExpandedNodes();
    const dispatch = useDispatch<DispatchAction>();

    const handleProjectClick = () => {
        // reload all project data
        dispatch(onResetData()); // reset data on project click
        dispatch(onLoadNode({ nodeId: activeProjectId as string, depth: 2, parentNodeType: DataType.Project }));
    };

    const handleClick = () => {
        const parentPathParts = getParentPathParts(activeProjectId, activeFoldersIds, true);
        dispatch(setExpandedNodes({ expandedNodes: expandedNodes.concat(parentPathParts) }));
    };

    const getBreadcrumb = (
        treeData: ITree[],
        activeFolderIds: string[],
        lastFolders: string[],
        accountUrn: string,
        projectUrn: string
    ): BreadcrumbData[] => {
        let breadcrumb: BreadcrumbData[] = [];
        const id = activeFolderIds.shift();
        if (!treeData) {
            return breadcrumb;
        }
        const nextItem = treeData.find((item) => item.id === id);

        if (nextItem) {
            breadcrumb.push({
                name: nextItem.text,
                path: getPath(nextItem, false, accountUrn, projectUrn, activeProjectId, lastFolders, undefined),
            });
            lastFolders.push(id as string);
            if (activeFolderIds.length > 0 && nextItem.children) {
                breadcrumb = breadcrumb.concat(
                    getBreadcrumb(nextItem.children, activeFolderIds, lastFolders, accountUrn, projectUrn)
                );
            }
        }
        return breadcrumb;
    };

    return (
        <Breadcrumb
            breadcrumbs={getBreadcrumb(treeData, [...activeFoldersIds], [], accountUrn, projectUrn)}
            activeProject={activeProject}
            handleProjectClick={handleProjectClick}
            handleClick={handleClick}
        />
    );
};

export default BreadcrumbWrapper;
