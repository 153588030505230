import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: "100%",
            overflow: "auto",
            padding: theme.spacing(1, 0),
        },
        listRoot: {
            padding: 0,
        },
        listItemRoot: {
            minWidth: "40px",
            padding: 0,
            "&.showDerived": {
                background: theme.palette.primary[100],
            },
        },
        listItemIcon: {
            minWidth: "40px",
        },
        checkboxRoot: {
            padding: 0,
            marginLeft: theme.spacing(1),
        },
        listItemCustom: {
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
            height: "20px",
        },
        listItemValue: {},
        listItemCustomIcons: {
            minWidth: "70px",
            "& > span": {
                background: theme.palette.grey[400],
                borderRadius: "50px",
                color: theme.palette.common.white,
                height: 18,
                width: 18,
                textAlign: "center",
                fontSize: theme.typography.pxToRem(10),
                margin: "0 2px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
            },
            "& .error": {
                background: theme.palette.error.main,
            },
            "& .success": {
                background: theme.palette.success.main,
            },
            "& .warning": {
                background: theme.palette.warning.main,
            },
            margin: theme.spacing(0, 1),
            [theme.breakpoints.up("md")]: {
                margin: theme.spacing(0, 2),
            },
            [theme.breakpoints.up("xl")]: {
                margin: theme.spacing(0, 3),
            },
        },
        listItemDescription: {
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: theme.spacing(0.3),
            [theme.breakpoints.up("md")]: {
                width: "45%",
                minWidth: "45%",
                maxWidth: "45%",
            },
        },
        listItemPopover: {
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
        },
        positionName: {
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        loading: {
            display: "flex",
            flexFlow: "row nowrap",
            flex: 1,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
        statusPopover: {
            pointerEvents: 'none',
        },
        statusPopoverContent: {
            padding: '1rem',
            fontSize: '0.8rem'
        },
        statusPopoverContentList: {
            '& .MuiListItem-root': {
                paddingLeft: '4px',
                paddingRight: '4px',
                padding: 0,
            },
            '& .MuiIcon-root': {
              fontSize: '1.1rem',
                marginRight: '0.5rem'
            },
            '& .ok .MuiIcon-root': {
                color: theme.palette.success.main,
            },
            '& .nok .MuiIcon-root': {
                color: theme.palette.error.main,
            },
            '& .MuiTypography-root': {
                fontSize: '0.8rem'
            }
        }
    })
);

export default useStyles;
