import { DispatchAction } from "@iolabs/redux-utils";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useState } from "react";
import {
    createProject,
    getProjectList,
    getProjectOptions,
    updateProject,
} from "../../../packages/Api/data/projects/client";
import {
    IProjectListResponseData,
    IProjectOptionsResponseData,
    IProjectRequestData,
} from "../../../packages/Api/data/projects/types";
import { loadProjects, setProjects, useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import NewProjectForm from "../NewProjectForm/NewProjectForm";
import { INewProjectFormValues } from "../NewProjectForm/type";
import { useDispatch } from "react-redux";

interface INewProjectWrapper {
    editMode?: boolean;
}

const NewProjectWrapper: React.FC<INewProjectWrapper> = ({ editMode }) => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const activeProject: ProjectData | undefined = useActiveProject();
    const dispatch = useDispatch<DispatchAction>();

    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<IProjectOptionsResponseData>();
    const [initialFormValues, setInitialFormValues] = useState<INewProjectFormValues | null>(null);
    const [formValues, setFormValues] = useState<INewProjectFormValues | null>(null);

    const validate = (values: INewProjectFormValues): INewProjectFormValues | {} => {
        const errors: INewProjectFormValues | {} = {};

        if (!values?.name) {
            (errors as INewProjectFormValues).name = "Please enter a project name";
        }
        if (!values?.code) {
            (errors as INewProjectFormValues).code = "Please enter a code";
        }
        // if (!values?.lengthUnitID) {
        //     (errors as INewProjectFormValues).lengthUnitID = "Please select a length unit";
        // }
        // if (!values?.areaUnitID) {
        //     (errors as INewProjectFormValues).lengthUnitID = "Please select a area unit";
        // }
        // if (!values?.volumeUnitID) {
        //     (errors as INewProjectFormValues).lengthUnitID = "Please select a volume unit";
        // }
        // if (!values?.temperatureUnitID) {
        //     (errors as INewProjectFormValues).temperatureUnitID = "Please select a temperature unit";
        // }
        // if (!values?.currencyID) {
        //     (errors as INewProjectFormValues).currencyID = "Please select a currency";
        // }
        // if (!values?.masterFileName) {
        //     (errors as INewProjectFormValues).masterFileName = "Please enter a master file name";
        // }
        // if (!values?.updateTypeID) {
        //     (errors as INewProjectFormValues).updateTypeID = "Please select a type of data update";
        // }
        if (!values?.projectUrn) {
            (errors as INewProjectFormValues).projectUrn = "Please enter a project URN";
        }
        if (!values?.accountUrn) {
            (errors as INewProjectFormValues).accountUrn = "Please enter a account URN";
        }
        // if (!values?.fileUrn) {
        //     (errors as INewProjectFormValues).fileUrn = "Please enter a file URN";
        // }

        return errors;
    };

    const onSubmit =
        async (values: INewProjectFormValues) => {
            if (!keycloakInitialized) return;

            setFormValues(values);

            const data: IProjectRequestData = {
                name: values?.name,
                description: values?.description,
                code: values?.code,
                lengthUnitID: values?.lengthUnitID,
                areaUnitID: values?.areaUnitID,
                volumeUnitID: values?.volumeUnitID,
                temperatureUnitID: values?.temperatureUnitID,
                currencyID: values?.currencyID,
                updateTypeID: values?.updateTypeID,
                masterFileName: values?.masterFileName,
                externalData: [
                    {
                        externalSystemCode: "forge",
                        accountUrn: values?.accountUrn,
                        projectUrn: values?.projectUrn,
                    },
                ],
                externalMasterFileData: [
                    {
                        externalSystemCode: "forge",
                        fileUrn: values?.fileUrn,
                    },
                ],
                revitEnhancersIDs: values.revitEnhancersIDs
            };


            if (editMode) {
                // edit
                updateProject(keycloak?.token as string, activeProject?.projectID as number, data)
                    .then(async (response) => {
                        // update project list
                        await dispatch(loadProjects({ token: keycloak?.token as string, forceReload: true }));
                    })
                    .catch((erroe) => {
                        console.log(erroe);
                    });
            }
            else {
                // insert
                createProject(keycloak?.token as string, data)
                    .then((response) => {
                        // update project list
                        dispatch(loadProjects({ token: keycloak?.token as string, forceReload: true }));
                    })
                    .catch((erroe) => {
                        console.log(erroe);
                    });
            }


        };

    useEffect(() => {
        if (editMode && activeProject && options) {
            setInitialFormValues({
                name: activeProject?.name,
                description: "",
                code: activeProject?.code,
                lengthUnitID: "",
                areaUnitID: "",
                volumeUnitID: "",
                temperatureUnitID: "",
                currencyID: "",
                updateTypeID: "",
                masterFileName: "",
                projectUrn: activeProject?.externalData?.[0]?.projectUrn,
                accountUrn: activeProject?.externalData?.[0]?.accountUrn,
                fileUrn: activeProject?.externalData?.[0]?.masterFileUrn,
                revitEnhancersIDs: options ? options.revitEnhancers.filter(re => activeProject?.revitEnhancers.includes(re.code)).map(re => re.revitEnhancerID) : [],
            });
        }
    }, [editMode, activeProject, options]);

    useEffect(() => {
        if (!keycloakInitialized) return;

        setLoading(true);
        getProjectOptions(keycloak?.token as string).then((response) => {
            setOptions(response);
            setLoading(false);
        });
    }, [keycloakInitialized]);

    return (
        <NewProjectForm
            loading={loading}
            options={options}
            initialFormValues={initialFormValues}
            formValues={formValues}
            validate={validate}
            onSubmit={onSubmit}
            editMode={editMode}
        />
    );
};

export default NewProjectWrapper;
