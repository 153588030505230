import { Notifier } from "@iolabs/notifier";
import { IonApp, IonBackdrop, IonSpinner } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { useTheme } from "@material-ui/core/styles";
import { useKeycloak } from "@react-keycloak/web";
import { SnackbarProvider } from "notistack";
import React from "react";
import PageRouter from "../../pages/PageRouter";
import "../../theme/global.css";
import "../../theme/variables.css";
import Layout from "../Layout/Layout";
import useStyles from "./styles";

const App: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { initialized } = useKeycloak();

    return (
        <>
            {!initialized ? (
                <div className={classes.spinnerBox}>
                    <IonBackdrop tappable={false} />
                    <IonSpinner color="primary" className={classes.spinnerItem} />
                </div>
            ) : (
                <SnackbarProvider
                    maxSnack={3}
                    dense
                    preventDuplicate
                    classes={{
                        variantSuccess: classes.success,
                        variantError: classes.error,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                    }}
                >
                    <IonApp>
                        <IonReactRouter>
                            <Layout
                                stylesProps={{
                                    headerBarColor: theme.palette.grey["500"],
                                    headerBarBackground: theme.palette.primaryVariant.main,
                                    drawerHeaderBackground: theme.palette.primary.main,
                                    drawerBackground: theme.palette.primary.main,
                                    footerBarColor: theme.palette.common.black,
                                    footerBarBackground: theme.palette.primaryVariant.main,
                                }}
                            >
                                <PageRouter />
                            </Layout>
                        </IonReactRouter>
                    </IonApp>
                    <Notifier />
                </SnackbarProvider>
            )}
        </>
    );
};

export default App;
