import { defineMessages } from "react-intl";

export default defineMessages({
    name: {
        id: "components.documentViewWrapper.name",
        defaultMessage: "Name",
    },
    offline: {
        id: "components.documentViewWrapper.offline",
        defaultMessage: "Offline",
    },
    version: {
        id: "components.documentViewWrapper.version",
        defaultMessage: "Version",
    },
    issues: {
        id: "components.documentViewWrapper.issues",
        defaultMessage: "Issues",
    },
    format: {
        id: "components.documentViewWrapper.format",
        defaultMessage: "Format",
    },
    lastModified: {
        id: "components.documentViewWrapper.lastModified",
        defaultMessage: "Last modified",
    },
    updatedBy: {
        id: "components.documentViewWrapper.updatedBy",
        defaultMessage: "Updated by",
    },
    mark: {
        id: "components.documentViewWrapper.mark",
        defaultMessage: "Mark",
    },
    size: {
        id: "components.documentViewWrapper.size",
        defaultMessage: "Size",
    },
});
