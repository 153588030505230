import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IMappingMaterial, IMappingMaterialAddRequest, IMappingMaterialUpdateRequest } from "./types";
import fileDownload from "js-file-download";

const materialApi = new ApiClient("/Materials", config.api.baseUrl);

/**
 * Returns a list of all materials.
 * @param token
 */
export const getMaterialCatalogue = (token: string): Promise<IMappingMaterial[]> => {
    ApiClient.setAuthorizationToken(token);

    return materialApi
        .get("/list", {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IMappingMaterial[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Create material into catalogue and return created entity.
 * @param token
 * @param data
 */
export const addMaterialCatalogue = (token: string, data: IMappingMaterialAddRequest): Promise<IMappingMaterial> => {
    ApiClient.setAuthorizationToken(token);

    return materialApi.post("/add", data).then((response: AxiosResponse<IMappingMaterial>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update material in catalogue and return updated entity.
 * @param token
 * @param materialID
 * @param data
 */
export const updateMaterialCatalogue = (
    token: string,
    materialID: number,
    data: IMappingMaterialUpdateRequest
): Promise<IMappingMaterial> => {
    ApiClient.setAuthorizationToken(token);

    return materialApi.post(`/${materialID}/update`, data).then((response: AxiosResponse<IMappingMaterial>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Deletes material from catalogue.
 * @param token
 * @param materialID
 */
export const deleteMaterialCatalogue = (token: string, materialID: number): Promise<boolean> => {
    ApiClient.setAuthorizationToken(token);

    return materialApi.get(`/${materialID}/delete`).then((response: AxiosResponse<boolean>) => {
        return Promise.resolve(response.status === 204);
    });
};

/**
 * Export material, file is downloaded
 * @param token
 */
export const exportMaterial = (
    token: string
): Promise<void> => {
    ApiClient.setAuthorizationToken(token);

    return materialApi
        .get(`/export`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "material.xlsx");
            return;
        });
};

/**
 * Import material, file is uploaded
 * @param token
 * @param data
 */
export const importMaterial = (
    token: string,
    data: FormData
): Promise<void> => {
    ApiClient.setAuthorizationToken(token);

    return materialApi
        .post(`/import`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((response) => {
            return Promise.reject(response.response.data)
        })
        ;
};