import { defineMessages } from "react-intl";

export default defineMessages({
    folder: {
        id: "components.documentInlineEdit.folder",
        defaultMessage: "1 folder has been renamed",
    },
    document: {
        id: "components.documentInlineEdit.document",
        defaultMessage: "1 document has been renamed",
    },
    error: {
        id: "components.documentInlineEdit.error",
        defaultMessage: "Rename error",
    },
});
