import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        status: {
            padding: "2px 10px",
            fontSize: ".8rem",
            borderRadius: "50px",
            textTransform: "uppercase",
            color: theme.palette.common.white,
        },
    })
);

interface IStatusProps {
    issueStatus: any;
}

const Status: React.FC<IStatusProps> = ({ issueStatus }) => {
    const classes = useStyles();

    return (
        <Box draggable>
            <span
                className={classes.status}
                style={{
                    background: issueStatus?.highlightColor,
                }}
            >
                {issueStatus?.name}
            </span>
        </Box>
    );
};

export default Status;
