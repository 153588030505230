import { Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { TreeItem } from "@material-ui/lab";
import { TreeItemProps } from "@material-ui/lab/TreeItem";
import React from "react";
import { generatePath, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ITree } from "../../../packages/Api/data/tree/types";
import { Path } from "../../../pages/PageRouter";
import { IPageParams } from "../../../pages/type";
import useStyles from "./styles";

type StyledTreeItemProps = TreeItemProps & {
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    isActive: boolean;
    item: ITree;
    handleClick: (event: React.ChangeEvent<{}>, item: ITree) => void;
    handleMoreOpen: (event: React.MouseEvent<HTMLElement>, item: ITree) => void;
    handleMoreClose: () => void;
    anchorMore: null | HTMLElement;
    openMore: boolean;
    parentIds?: string[];
};

function StyledTreeItem(props: StyledTreeItemProps) {
    const {
        labelText,
        labelIcon: LabelIcon,
        labelInfo,
        isActive,
        item,
        handleClick,
        handleMoreOpen,
        handleMoreClose,
        anchorMore,
        openMore,
        parentIds,
        ...other
    } = props;

    const { accountUrn, projectUrn } = useParams<IPageParams>();
    const classes = useStyles();

    const getPath = (accountUrn: string, projectUrn: string) => {
        const ids = parentIds || [];
        ids.push(item.id);
        return generatePath(Path.DOCUMENTS, { accountUrn, projectUrn, nodeId: ids.join("_") });
    };

    return (
        <TreeItem
            className={isActive ? `${classes.activeTreeItem}` : ``}
            label={
                <Link
                    to={getPath(accountUrn, projectUrn)}
                    className={isActive ? `${classes.activeLabel} ${classes.labelRoot}` : `${classes.labelRoot}`}
                    onClick={(event) => handleClick(event, item)}
                >
                    <LabelIcon className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                </Link>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

export default StyledTreeItem;
