import { AnyAction } from "redux";
import { ITree } from "../../packages/Api/data/tree/types";
import { ActionTypes } from "./action";

export interface ITreeModuleState {
    treeState: ITreeState;
}

export interface ITreeState {
    data: ITree[];
    contentData?: ITree[] | undefined;
    depth: number;
    urn?: string;
    loading: boolean;
    activeNodeId: string;
    expandedNodes: string[];
}

export const initialState: Readonly<ITreeState> = {
    data: [],
    contentData: [],
    depth: 0,
    loading: false,
    activeNodeId: "",
    expandedNodes: [],
};

export function reducer(state: ITreeState = initialState, action: AnyAction): ITreeState {
    switch (action.type) {
        case ActionTypes.OnManualData: {
            return {
                ...state,
                loading: true,
            };
        }

        case ActionTypes.OnManualDataDone: {
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        }

        case ActionTypes.OnManualDataFail: {
            return { ...state, loading: false };
        }

        case ActionTypes.OnLoadNode: {
            return {
                ...state,
                loading: true,
            };
        }

        case ActionTypes.OnLoadDataLoadingDone: {
            return {
                ...state,
                loading: false,
            };
        }

        case ActionTypes.OnLoadNodeDone: {
            return {
                ...state,
                data: ([...action.payload] as unknown) as ITree[],
                loading: false,
            };
        }

        case ActionTypes.OnLoadNodeContentDone: {
            return {
                ...state,
                contentData: (action.payload as unknown) as ITree[],
                loading: false,
            };
        }

        case ActionTypes.OnLoadNodeFail: {
            return { ...state, loading: false };
        }

        case ActionTypes.OnResetData: {
            return { ...state, data: initialState.data };
        }

        case ActionTypes.SetActiveNodeId: {
            return {
                ...state,
                activeNodeId: action.payload.activeNodeId,
            };
        }

        case ActionTypes.SetExpandedNodes: {
            return {
                ...state,
                expandedNodes: action.payload.expandedNodes,
            };
        }

        default:
            return state;
    }
}
