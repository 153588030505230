import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.primary,
            "&:hover > $content": {
                backgroundColor: theme.palette.grey["50"],
            },
            "&:focus > $content": {
                borderRadius: theme.shape.borderRadius,
            },
            "&$selected > $content": {
                color: theme.palette.success.dark,
                backgroundColor: theme.palette.success.light,
                borderRadius: theme.shape.borderRadius,
            },
            "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
                backgroundColor: "transparent",
            },
        },
        content: {
            color: theme.palette.text.primary,
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightRegular as number,
            borderRadius: theme.shape.borderRadius,
            "$expanded > &": {
                // change style for expanded nodes
            },
        },
        group: {
            marginLeft: theme.spacing(3),
            "& $content": {
                paddingLeft: 0,
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: "inherit",
            color: "inherit",
            backgroundColor: "transparent !important",
            paddingLeft: 0,
        },
        labelRoot: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0.5, 0),
            textDecoration: "none",
            color: "inherit",
            "& svg": {
                fontSize: "1.3rem",
            },
        },
        labelIcon: {
            fill: theme.palette.primary.main,
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: "inherit",
            flexGrow: 1,
            whiteSpace: "nowrap",
        },
        activeTreeItem: {
            fontWeight: theme.typography.fontWeightBold as number,
        },
        activeLabel: {
            fontWeight: theme.typography.fontWeightBold as number,
            color: theme.palette.primary.main,
        },
        activeIcon: {
            color: theme.palette.primary.main,
        },
    })
);

export default useStyles;
