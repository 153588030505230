import { createSelector } from "reselect";
import { IIssueModuleState, IIssuesState } from "./reducer";

export function selectIssueState(state: IIssueModuleState): IIssuesState {
    return state.issueState;
}
export const selectIssuesList = createSelector(selectIssueState, (state) => state.issues);
export const selectIssueCreate = createSelector(selectIssueState, (state) => state.issueCreate);

// return issueEdit object by issue external id
export const selectIssueEdit = createSelector(selectIssueState, (state) => {
    const filteredIssues = state?.issues?.filter(
        (issue) => issue?.issueExternals?.[0]?.externalID === state.issueEditId
    );
    return filteredIssues?.pop();
});
export const selectIssueTab = createSelector(selectIssueState, (state) => state.issueTab);
export const selectIssueViewer = createSelector(selectIssueState, (state) => state.issueViewer);
export const selectIsIssueVisible = createSelector(selectIssueState, (state) => state.isVisible);
export const selectFromViewerPage = createSelector(selectIssueState, (state) => state.fromViewerPage);
