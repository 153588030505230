import * as uft from "../../../../utils/Flatten";

export interface ITreeNode {
    id: string,
    name: string,
    children?: ITreeNode[],
    dbIds?: number[]
}
export interface IIndexedNode {
    id: string,
    name: string,
    path: string[],
    dbIds?: number[],
    parentID?: string|number,
    level: number
}

// @ts-ignore
export class StructureTreeDelegate extends Autodesk.Viewing.UI.TreeDelegate {
    private viewer: Autodesk.Viewing.Viewer3D;

    public model: any;
    public instanceTree: any;
    public flatTree: {[key: string]: IIndexedNode}  = {};
    public rootID?: string|number;

    private selectedNodes : number[] = [];
    private container?: HTMLElement;

    constructor (viewer, tree, container) {

        super()
        this.container = container;
        const indexedNodes : IIndexedNode[] = uft.flatten([tree], (node: ITreeNode) => {
                return node.children as ITreeNode[];
            }, (node, parentNode, nodeId, parentNodeId, parentOutNode): IIndexedNode => {
                const outNode: IIndexedNode = {
                    name: node.name,
                    path: parentOutNode?.path ? [...(parentOutNode.path as string[]), node.name] : [node.name],
                    level: 0,
                    id: node.id,
                    dbIds: node.dbIds,
                    parentID: parentNode?.id
                }
                outNode.level = outNode.path.length??0
                return outNode;
            }, (node: ITreeNode) => node.id
        );
        indexedNodes.forEach(node => {
            this.flatTree[node.id] = node;
        });

        this.rootID = indexedNodes[0].id;


        this.viewer = viewer;
        this.model = {
            id: 0
        }

        this.instanceTree = {
            getNodeName: (id) => {
                return this.flatTree[id].name;
            },
            getNodeParentId: (id) => {
                return this.flatTree[id].parentID;
            }
        }
    }

    getRootId () {
        return this.rootID;
    }

    getTreeNodeId (node: ITreeNode) : string {
        return node.id;
    }

    filterIds (query): string[] {
        const queryLower: string = query.toLowerCase();
        const ids: string[] = [];
        Object.values(this.flatTree).forEach(node => {
            if (node.name.toLowerCase().includes(queryLower)) {
                ids.push(node.id as string);
            } ;
        });
        return ids;
    }

    isTreeNodeGroup (node: ITreeNode) {

        if (!node.children || !node.children.length) {

            return false
        }

        return true
    }

    onIndexedNodeClick (nodeId) {
        this.onTreeNodeClickInternal(this.flatTree[nodeId].dbIds);
    }

    onTreeNodeClickInternal (dbIds) {
        const remapped = dbIds.map(id => {
            // @ts-ignore
            return this.viewer.model.remapDbId(id)
        })

        this.viewer.select(remapped);
    }

    onTreeNodeClick (tree, node, event) {
        const id = node.id;
        if (event.ctrlKey) {
            // multiselect
            this.selectedNodes = this.selectedNodes?.includes(id) ? this.selectedNodes?.filter(sn => sn != id) : [...this.selectedNodes, id];
        }
        else {
            this.selectedNodes = this.selectedNodes?.includes(id) ? [] : [id];
        }

        let dbIds: number[] = []
        this.selectedNodes.forEach(nodeId => {
            dbIds = [...dbIds, ...this.flatTree[nodeId].dbIds as number[]];
        });
        this.applySelectionClasses();
        this.onTreeNodeClickInternal(dbIds);
    }

    applySelectionClasses() {
        // @ts-ignore
        const elements: HTMLElement[] = this.container?.querySelectorAll("group[lmv-nodeid]");
        elements.forEach((element : HTMLElement) => {
            element.classList.remove("active");
            if (this.selectedNodes.includes(parseInt(element.getAttribute("lmv-nodeid") as string))) {
                element.classList.add("active");
            }
        })
    }

    onTreeNodeDoubleClick (tree, node: ITreeNode, event) {
    }

    forEachChild (node: ITreeNode, callback) {

        if (node.children) {

            node.children.forEach((child) => {

                //if(child === 'some condition') mode.getProperties(child.dbId, ()=>{  decide if node or not})

                callback(child)
            })
        }
    }
}