import React from "react";
import { generatePath, Redirect, Route, Router, Switch, useHistory } from "react-router";
import { IIcon } from "../components/Icon/IconSet";
import DashboardPage from "./DashboardPage/DashboardPage";
import DocumentWrapperPage from "./DocumentWrapperPage/DocumentWrapperPage";
import IconsPage from "./IconsPage/IconsPage";
import MappingManagerPage from "./MappingManagerPage/MappingManagerPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import ProjectNewPage from "./ProjectNewPage/ProjectNewPage";
import ProjectSettingsPage from "./ProjectSettingsPage/ProjectSettingsPage";
import SpecificationPage from "./SpecificationPage/SpecificationPage";
import RootPage from "./RootPage/RootPage";
import { ProjectData } from "../components/ProjectSelectorWrapper/type";
import { useActiveProject } from "../redux/project";

export interface AppPage {
    title: string;
    path: string;
    exact?: boolean;
    icon: IIcon;
    component?: any;
    pages?: AppPage;
    wip?: boolean;
    inMenu?: boolean;
    children?: AppPage[];
}

export enum Path {
    ROOT = "/",
    ICONS = "/icons",
    PROJECTS = "/projects",
    DASHBOARD = "/dashboard",
    VERSION_COMPARATOR = "/:accountUrn/:projectUrn/version-comparator", // Versionsvergleich
    COST_COMPARATOR = "/:accountUrn/:projectUrn/cost-comparator", // Kostenschätzung
    SPECIFICATIONS = "/:accountUrn/:projectUrn/specifications", // Leistungsverzeichnis
    DOCUMENTS = "/:accountUrn/:projectUrn/documents/:nodeId?",
    DOCUMENTS_WITH_VIEWER = "/:accountUrn/:projectUrn/documents/:nodeId/viewer/:urn/:fileId/:tool?",
    PROJECT_NEW = "/new-project",
    PROJECT_SETTINGS = "/:accountUrn/:projectUrn/project-settings",
    MAPPING_MANAGER = "/:accountUrn/:projectUrn/mapping-manager",
}

export const appPages: AppPage[] = [
    // {
    //     title: "Projects",
    //     path: Path.PROJECTS,
    //     exact: true,
    //     icon: "chevron-left",
    //     component: CameraPage,
    //     wip: true,
    //     inMenu: true,
    // },
    {
        title: "Dashboard",
        path: Path.DASHBOARD,
        exact: true,
        icon: "chart-pie",
        component: DashboardPage,
        wip: true,
        inMenu: true,
    },
    {
        title: "Version comparator",
        path: Path.VERSION_COMPARATOR,
        exact: true,
        icon: "version",
        component: null,
        wip: true,
        inMenu: true,
    },
    {
        title: "Cost comparator",
        path: Path.COST_COMPARATOR,
        exact: true,
        icon: "dollar",
        component: null,
        wip: true,
        inMenu: true,
    },
    {
        title: "Leistungsverzeichnis",
        path: Path.SPECIFICATIONS,
        exact: true,
        icon: "list-border",
        component: SpecificationPage,
        inMenu: true,
    },
    {
        title: "Viewer",
        path: Path.DOCUMENTS_WITH_VIEWER,
        exact: true,
        icon: "file",
        component: DocumentWrapperPage,
        inMenu: false,
    },
    {
        title: "Documents",
        path: Path.DOCUMENTS,
        exact: true,
        icon: "file",
        component: DocumentWrapperPage,
        inMenu: true,
    },
    {
        title: "New project",
        path: Path.PROJECT_NEW,
        exact: true,
        icon: "file",
        component: ProjectNewPage,
        inMenu: false,
    },
    {
        title: "Project settings",
        path: Path.PROJECT_SETTINGS,
        exact: true,
        icon: "file",
        component: ProjectSettingsPage,
        inMenu: false,
    },
    {
        title: "Mapping manager",
        path: Path.MAPPING_MANAGER,
        exact: true,
        icon: "file",
        component: MappingManagerPage,
        inMenu: false,
    },
    {
        title: "Icons",
        path: Path.ICONS,
        exact: true,
        icon: "file",
        component: IconsPage,
        inMenu: false,
    },
    {
        title: "Root",
        path: Path.ROOT,
        exact: true,
        icon: "chart-pie",
        component: RootPage,
        wip: true,
        inMenu: false,
    },
];

function PageRoute({ component: Component, ...rest }) {
    return <Route {...rest} render={(routerProps) => <Component {...routerProps} />} />;
}

const PageRouter: React.FC = () => {
    const history = useHistory();

    return (
        <Router history={history}>
            <Switch>
                {appPages.map((appPage, index) => (
                    <PageRoute key={index} path={appPage.path} exact={appPage.exact} component={appPage.component} />
                ))}
                <Route path="/" exact>
                    <Redirect to={generatePath(Path.DASHBOARD)} />
                </Route>
                <Route component={NotFoundPage} />
            </Switch>
        </Router>
    );
};

export default PageRouter;
