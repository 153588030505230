import fileDownload from "js-file-download";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IPositionFormConfiguration } from "../elements/types";
import { AxiosResponse } from "axios";
import { InstanceInfo, SpecialInstancesResponse } from "./types";

const elementsApi = new ApiClient("/Instances", config.api.baseUrl);

/**
 * Export positions, file is downloaded
 * @param token
 */
export const exportInstances = (
    token: string,
    projectID: number
): Promise<void> => {
    ApiClient.setAuthorizationToken(token);

    return elementsApi
        .get(`/export/${projectID}`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "instances.xlsx");
            return;
        });
};


/**
 * Get position form configuration - returns form configuration.
 * @param token
 */
export const getSpecialInstances = (
    token: string,
    projectID: string,
): Promise<SpecialInstancesResponse> => {
    ApiClient.setAuthorizationToken(token);

    return elementsApi
        .get(`/special/${projectID}`)
        .then((response: AxiosResponse<SpecialInstancesResponse>) => {
            return Promise.resolve(response.data);
        });
};


/**
 * Get instance information.
 * @param token
 */
export const getInstanceInformation = (
    token: string,
    projectID: number,
    instanceExternalIDs: string[],
): Promise<InstanceInfo[]> => {
    ApiClient.setAuthorizationToken(token);

    return elementsApi
        .post(`/info/${projectID}`, instanceExternalIDs)
        .then((response: AxiosResponse<InstanceInfo[]>) => {
            return Promise.resolve(response.data);
        });
};