import React, { useEffect, useState } from "react";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { IMappingNavigation } from "../../../pages/MappingManagerPage/type";
import { useMappingCatalogLoading, useMappingCatalogPositionIDs, useMappingPositions } from "../../../redux/mapping";
import * as uft from "../../../utils/Flatten";
import ProjectCatalogTree from "../ProjectCatalogTree/ProjectCatalogTree";

interface IProjectCatalogTreeWrapper {
    checked: number[];
    handleChangeMainView: (mainView: boolean) => void;
    handleToggle: (positionID: number, isLeft: boolean) => void;
    navigation: IMappingNavigation;
    search: string;
}

const ProjectCatalogTreeWrapper: React.FC<IProjectCatalogTreeWrapper> = ({
    checked,
    handleChangeMainView,
    handleToggle,
    navigation,
    search,
}) => {
    const catalogLoading: boolean = useMappingCatalogLoading();
    const catalogPositionIDs: number[] = useMappingCatalogPositionIDs();
    const mappingPositions: IElementsPosition[] = useMappingPositions();

    const [flattenPositions, setFlattenPositions] = useState<IElementsPosition[]>([]);

    useEffect(() => {
        const flatten = uft.flattenPositions(
            mappingPositions,
            false,
            navigation,
            search,
            (node) => node?.childPositions as any,
            (node) => node?.derivedPositions as any,
            (node, parentNode) => {
                return Object.assign(node, parentNode?.positionID && { parentID: parentNode?.positionID });
            },
            () => undefined,
            catalogPositionIDs
        );

        setFlattenPositions(flatten);
    }, [mappingPositions, navigation, catalogPositionIDs, search]);

    return (
        <ProjectCatalogTree
            checked={checked}
            handleChangeMainView={handleChangeMainView}
            handleToggle={handleToggle}
            loading={catalogLoading}
            navigation={navigation}
            positions={flattenPositions}
            catalogPositionIDs={catalogPositionIDs}
        />
    );
};

export default ProjectCatalogTreeWrapper;
