import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "relative",
            width: "100%",
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            [theme.breakpoints.up("xl")]: {
                padding: theme.spacing(2),
            },
        },
        fieldBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "relative",
            clear: "both",
        },
        fieldBoxSkeleton: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "relative",
            clear: "both",
            height: "50px",
        },
        textField: {
            marginTop: "16px",
            marginBottom: "8px",
            "&:not(:last-child)": {
                marginRight: theme.spacing(1),
            },
            "& input": {
                fontSize: theme.typography.pxToRem(11),
                outline: "5px",
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px !important",
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset": {
                padding: "0.75em",
            },
            "& fieldset > legend": {
                maxWidth: "auto",
                visibility: "visible",
                fontSize: "0.70rem",
                lineHeight: "1em",
                '& span': {
                    background: theme.palette.common.white,
                }
            },
            "& label": {
                paddingLeft: "5px",
                paddingRight: "5px",
                background: theme.palette.common.white,
            },
        },
        fieldType: {
            flex: 1,
        },
        fieldCode: {
            flex: 4,
        },
        fieldTitle: {
            flex: 2,
            paddingTop: "1.5em",
        },
        fieldName: {
            flex: 6,
        },
        fieldDescription: {
            flex: 2,
        },
        fieldForeBack: {
            flex: 8,
            paddingLeft: "1em"
        },
        colorBox: {
            "& .MuiInputBase-inputMarginDense": {
                padding: "6px 8px",
            },
        },
        colorButton: {
            borderRadius: "3px",
        },
        textControl: {
            "& .MuiInputBase-input": {
                fontSize: theme.typography.pxToRem(11),
                marginTop: "-4px",
            }
        },
        skeletonBox: {
        },
        skeleton: {
            margin: "0.2em",
            height: "4em",
            width: "100%",
        },
        attachmentInfo: {
            marginTop: theme.spacing(1.5),
            fontSize:  theme.typography.pxToRem(11),
            padding: "3px 10px",
            "& .MuiAlert-icon": {
                fontSize: theme.typography.pxToRem(15),
            }
        },
    })
);

export default useStyles;
