import { createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinnerBox: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        },
        spinnerItem: {
            width: "50px",
            height: "50px",
        },
        success: {
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${theme.palette.success.main} !important`,
        },
        error: {
            backgroundColor: `${theme.palette.secondary.main} !important`,
        },
        warning: {
            backgroundColor: `${theme.palette.secondary.main} !important`,
        },
        info: {
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
    })
);

export default useStyles;
