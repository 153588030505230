import { DispatchAction } from "@iolabs/redux-utils";
import { useKeycloak } from "@react-keycloak/web";
import React, { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IElementsPosition, PositionType } from "../../../packages/Api/data/elements/types";
import {
    onAttachedEquipment,
    onAttachedGraphics,
    onAttachedMaterial,
    onAttachedWork,
    onAttribute,
    onBasicPositions,
    onDerivedPositions,
    onElementQuery,
    onElementQueryOperators,
    onUnitPrice,
    useDetailPosition,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { MappingStep } from "../MappingDetailView/type";
import StepAttribute from "../StepAttribute/StepAttribute";
import StepElement from "../StepElement/StepElement";
import StepWork from "../StepWork/StepWork";
import { Alert } from "@material-ui/lab";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IStepContentWrapperProps {
    activeStep: number;
}

const StepContentWrapper: React.FC<IStepContentWrapperProps> = ({ activeStep }) => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    // translations
    const intl = useIntl();
    const transErrorStepNotValidType = intl.formatMessage({...messages.errorStepNotValidType})
    const transErrorUnknownStep = intl.formatMessage({...messages.errorUnknownStep})

    useEffect(() => {
        if (!activeProject || !detailPosition) return;

        // step attribute
        dispatch(
            onAttribute({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
            })
        );

        // element query
        dispatch(
            onElementQuery({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
            })
        );

        // element query operators
        dispatch(onElementQueryOperators({ token: keycloak?.token as string }));

        // derived positions
        dispatch(
            onDerivedPositions({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );

        // basic positions
        dispatch(
            onBasicPositions({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );

        // attached graphics
        dispatch(
            onAttachedGraphics({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );

        // attached work
        dispatch(
            onAttachedWork({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );

        // attached equipment
        dispatch(
            onAttachedEquipment({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );

        // attached material
        dispatch(
            onAttachedMaterial({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );

        // unit price
        dispatch(
            onUnitPrice({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID,
                positionID: detailPosition?.positionID,
            })
        );
    }, [keycloakInitialized, activeProject, detailPosition]);

    const getStepContent = (step: MappingStep): ReactNode => {
        switch (step) {
            case MappingStep.ELEMENT:
                return (
                    <StepElement />
                );
            case MappingStep.ATTRIBUTE:
                return detailPosition?.type != PositionType.MANUAL ? (
                    <StepAttribute />

                ) : (
                    <>
                        <Alert severity={"error"} >{transErrorStepNotValidType}</Alert>
                    </>
                );
            case MappingStep.MATERIAL:
                return detailPosition?.type != PositionType.MANUAL ? (
                    <StepWork />
                ) : (
                    <>
                        <Alert severity={"error"} >{transErrorStepNotValidType}</Alert>
                    </>
                );
            default:
                return <Alert severity={"error"} >{transErrorUnknownStep}</Alert>;
        }
    };

    return <>{getStepContent(activeStep)}</>;
};

export default StepContentWrapper;
