import { ProjectFileVersionViewable } from "../../../graphql/generated/graphql";
import { ExternalSystem, FileType } from "../type";

/**
 * Filter project files by specific file type code
 * @param project
 * @param fileTypeCode
 */
const getFilesOfType = (project: any, fileTypeCode: FileType): any => {
    return project?.projectFiles?.filter((projectFile) => projectFile.fileType.code === fileTypeCode);
};

/**
 * Get URN from project
 * @param project
 * @param fileType
 */
export const getUrnFromProject = (project: any, fileType: FileType = FileType.Geometry): string => {
    const files = getFilesOfType(project, fileType);

    return files?.[0].projectFileVersions?.[0]?.projectFileVersionExternals?.find((projectFileVersionExternal) => {
        return projectFileVersionExternal?.externalSystem?.code === ExternalSystem.Forge;
    })?.externalID;
};

/**
 * Get Project file version from project
 * @param project
 * @param fileType
 */
export const getProjectFileVersionIdFromProject = (project: any, fileType: FileType = FileType.Geometry): number => {
    const files = getFilesOfType(project, fileType);

    return files?.[0]?.projectFileVersions?.[0]?.projectFileVersionID;
};

/**
 * Get Forge Id from project
 * @param project
 */
export const getForgeIdFromProject = (project: any): string => {
    return project?.projectInExternalSystems?.[0]?.externalId;
};

/**
 * Get Account Id from project
 * @param project
 */
export const getAccountIdFromProject = (project: any): string => {
    return project?.projectInExternalSystems?.[0]?.accountId;
};

/**
 * Get Viewables for main file
 * @param project
 */
export const getMainProjectFileViewablesFromProject = (project: any): ProjectFileVersionViewable[] => {
    const files = getFilesOfType(project, FileType.Geometry);

    return files?.[0].projectFileVersions?.[0]?.projectFileVersionViewables;
};
