import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            ...theme.typography.h3,
            paddingBottom: theme.spacing(2),
            fontSize: "18pt",
            "& .MuiTypography-h6": {
                fontWeight: 500
            }
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey["500"],
        },
        actions: {
            padding: theme.spacing(2, 2.5),
            "& button": {
                marginLeft: `${theme.spacing(2)} !important`,
            },
        },
        headerRow: {
            marginBottom: theme.spacing(2),
        },
        groupLabel: {
            marginBottom: theme.spacing(2),
        },
        searchCodeBox: {
            justifyContent: "flex-end",
            float: "right",
        },
        moveDetail: {
            marginTop: theme.spacing(3),
        },
        searchBox: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-end",
            "& .MuiFormControl-marginDense": {
                marginTop: 0,
            },
            "& .MuiOutlinedInput-root": {
                background: theme.palette.grey["100"],
                "&.MuiOutlinedInput-adornedStart": {
                    paddingLeft: theme.spacing(1.2),
                    color: theme.palette.grey["500"],
                },
                "& .MuiSvgIcon-root": {
                    fontSize: theme.typography.pxToRem(20),
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
                "& .MuiOutlinedInput-input": {
                    fontSize: theme.typography.pxToRem(13),
                    padding: theme.spacing(1.2),
                    "&::-webkit-input-placeholder": {
                        color: theme.palette.grey["500"],
                    }
                }
            }
        },
        search: {
            "& input": {
                paddingLeft: 0,
            },
        },
        listsBox: {
            display: "flex",
            flexFlow: "column wrap",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                flexFlow: "row nowrap",
                justifyContent: "space-between",
                margin: `${theme.spacing(1)} 0`,
            },
        },
        paperBox: {
            flex: 1,
            margin: `${theme.spacing(1)} 0`,
            [theme.breakpoints.up("md")]: {
                flex: 0,
                margin: 0,
                minWidth: `calc(20% - ${theme.spacing(1)})`,
            },
        },
        paper: {
            borderColor: theme.palette.grey["100"],
        },
        list: {
            position: "relative",
            overflow: "auto",
            minHeight: 300,
            maxHeight: 300,
            borderRadius: theme.shape.borderRadius,
        },
        listSubHeader: {
            display: "none",
            fontSize: theme.typography.pxToRem(10),
            color: theme.palette.common.black,
            backgroundColor: theme.palette.common.white,
            lineHeight: "24px",
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        },
        listItem: {
            padding: `0 ${theme.spacing(1.5)}`,
            transition: "background-color 500ms"
        },
        selectedPosition: {
            backgroundColor: theme.palette.primary.light,
        },
        searched: {
            backgroundColor: theme.palette.grey[100],
        },
        listItemIcon: {
            minWidth: "25px",
            [theme.breakpoints.up("lg")]: {
                minWidth: "30px",
            },
        },
        listItemText: {
            marginTop: "2px",
            marginBottom: "2px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        listItemTextPos: {
            flex: 0,
            minWidth: "30px",
            [theme.breakpoints.up("md")]: {
                flex: 1,
            },
        },
        listItemTextValue: {
            flex: "auto",
            [theme.breakpoints.up("md")]: {
                flex: "1 0 70%",
            },
        },
        listItemTextCheckboxValue: {
            flex: "auto",
            [theme.breakpoints.up("md")]: {
                flex: "1 0 45%",
            },
        },
        checkbox: {
            padding: 0,
            marginLeft: 0,
        },
        fieldset: {
            width: "100%",
            marginTop: theme.spacing(2),
        },
        legend: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.common.black,
        },
        textField: {
            "& input": {
                padding: "10px 14px",
                fontSize: ".8rem",
            },
            "& textarea": {
                fontSize: ".8rem",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 14px",
            },
            [theme.breakpoints.up("md")]: {
                "& input": {
                    padding: "18.5px 14px",
                },
                "& .MuiOutlinedInput-multiline": {
                    padding: "18.5px 14px",
                },
            },
        },
        addButton: {
            textTransform: "inherit",
            fontSize: theme.typography.pxToRem(12),
            fontWeight: "inherit",
            minWidth: 0,
            padding: "2px 4px !important",
            marginTop: "4px",
            color: theme.palette.grey["500"],
            whiteSpace: "nowrap",
            marginLeft: theme.spacing(1.5),
        },
        addIcon: {
            fill: theme.palette.success.main,
            fontSize: theme.typography.pxToRem(12),
        },
        selected: {
            fontWeight: "bold",
            color: theme.palette.common.black,
        },
        newPosition: {
            fontStyle: "italic",
            fontWeight: "bold",
            color: theme.palette.success.main,
        },
        loadingOuter: {
            padding: "26em 33em"
        }
    })
);

export default useStyles;
