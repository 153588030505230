import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogBox: {
            position: "relative",
            height: "100%",
            width: "100%",
        },
        mvtTable: {
            "& .topRightGrid": {
                color: "inherit",
                background: "inherit",
                borderBottom: `1px solid ${theme.palette.primary["500"]}`,
            },
            "& .topLeftGrid": {
                color: "inherit",
                background: "inherit",
                borderBottom: `1px solid ${theme.palette.primary["500"]}`,
                borderRight: `1px solid ${theme.palette.primary["500"]}`,
            },
            "& .bottomLeftGrid": {
                color: "inherit",
                background: "inherit",
                borderRight: `1px solid ${theme.palette.primary["500"]}`,
            },
        },
        mvtCellHeader: {
            color: "inherit",
        },
        mvtCellContents: {
            paddingLeft: "6px",
        },
        mvtCellSelected: {
            backgroundColor: theme.palette.success.light,
        },
        mvtInput: {
            width: "90%",
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(12),
        },
        iconButton: {
            "& svg": {
                fontSize: theme.typography.pxToRem(18),
            },
        },
        iconButtonSave: {
            "& svg": {
                fill: theme.palette.success.main,
            },
        },
        iconButtonRevert: {
            marginLeft: theme.spacing(0.5),
            "& svg": {
                fill: theme.palette.primary.main,
            },
        },
        iconButtonClose: {
            marginLeft: theme.spacing(0.5),
            "& svg": {
                fill: theme.palette.secondary.main,
            },
        },
        disabled: {
            "& svg": {
                fill: theme.palette.text.disabled,
            },
        },
        skeletonBox: {
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center",
            margin: "auto",
        },
        circularProgress: {
            color: theme.palette.primary.main,
            alignSelf: "center",
        },
    })
);

export default useStyles;
