import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultMappingPanelSize } from "../../../pages/MappingManagerPage/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            alignItems: "center",
            backgroundColor: theme.palette.primary[200],
            color: theme.palette.text.primary,
            display: "flex",
            flexFlow: "row nowrap",
            minHeight: `${defaultMappingPanelSize}px`,
            height: `${defaultMappingPanelSize}px`,
            justifyContent: "space-between",
            padding: theme.spacing(0, 2),
        },
        radioGroup: {
            marginLeft: theme.spacing(1),
            flexWrap: "nowrap",
        },
        formControlRadioRoot: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: "10px",
            marginBottom: "-12px",
            paddingTop: "4px",
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            "& .MuiRadio-root": {
                display: "none",
            },
            "&.active": {
                backgroundColor: theme.palette.grey[50],
                color: "black",
                "& .MuiTypography-root": {
                    fontWeight: 400,
                },
            },
        },
        switchGroup: {
            flexWrap: "nowrap",
            overflow: "auto",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
        formControlSwitchRoot: {
            marginRight: 0,
            marginLeft: theme.spacing(2),
        },
        formControlLabel: {
            fontSize: "inherit !important",
            whiteSpace: "nowrap",
        },
        detailPositionCode: {
            padding: theme.spacing(0, 1),
        },
        nonCataloguePosition: {
            opacity: "50%"
        }
    })
);

export default useStyles;
