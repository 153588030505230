import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { IMappingModuleState, reducer } from "./reducer";

export const MappingModule: IModule<IMappingModuleState> = {
    id: "mapping-module",
    reducerMap: {
        mappingState: reducer,
    },
    middlewares: middlewares,
};