import { IonRouterLink } from "@ionic/react";
import { Breadcrumbs, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import { Path } from "../../pages/PageRouter";
import { IPageParams } from "../../pages/type";
import { ProjectData } from "../ProjectSelectorWrapper/type";
import messages from "./messages";
import Icon from "../Icon/Icon";
import useStyles from "./styles";
import { BreadcrumbData } from "../BreadcrumbWrapper/BreadcrumbWrapper";

interface IBreadcrumbProps {
    breadcrumbs: BreadcrumbData[];
    activeProject: ProjectData | undefined;
    handleProjectClick: () => void;
    handleClick: () => void;
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ breadcrumbs, activeProject, handleProjectClick, handleClick }) => {
    const { accountUrn, projectUrn } = useParams<IPageParams>();
    const classes = useStyles();
    const theme = useTheme();

    // translations
    const intl = useIntl();
    const transProjectFiles = intl.formatMessage({ ...messages.projectFiles });

    return (
        <>
            {activeProject && breadcrumbs ? (
                <Breadcrumbs
                    separator={<Icon name="chevron-light-right" size={10} fill={theme.palette.text.primary} />}
                    aria-label="breadcrumb"
                    className={classes.root}
                >
                    <IonRouterLink
                        routerDirection="back"
                        color="textPrimary"
                        // routerLink={generatePath(Path.DOCUMENTS, {
                        //     accountUrn,
                        //     projectUrn,
                        //     nodeId: "", // activeProject.id, // TODO: nodeId from project (Jakub Jirous 2021-03-05 12:41:27)
                        // })}
                        onClick={handleProjectClick}
                    >
                        <Typography variant="body2">{activeProject.name}</Typography>
                    </IonRouterLink>
                    {breadcrumbs.length !== 0 ? (
                        <IonRouterLink
                            routerDirection="back"
                            color="textPrimary"
                            routerLink={generatePath(Path.DOCUMENTS, {
                                accountUrn,
                                projectUrn,
                                nodeId: undefined, // activeProject.id, // TODO: nodeId from project (Jakub Jirous 2021-03-05 12:41:02)
                            })}
                            onClick={handleProjectClick}
                        >
                            <Typography variant="body2">Project Files</Typography>
                        </IonRouterLink>
                    ) : (
                        <Typography variant="body2" color="textPrimary">
                            {transProjectFiles}
                        </Typography>
                    )}

                    {breadcrumbs.map((breadcrumb, index) => (
                        <div key={index}>
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" color="textPrimary">
                                    {breadcrumb.name}
                                </Typography>
                            ) : (
                                <IonRouterLink
                                    routerDirection="back"
                                    color="textPrimary"
                                    routerLink={breadcrumb.path}
                                    onClick={handleClick}
                                >
                                    <Typography variant="body2">{breadcrumb.name}</Typography>
                                </IonRouterLink>
                            )}
                        </div>
                    ))}
                </Breadcrumbs>
            ) : (
                <Skeleton variant="rect" height={21} width="15%" className={classes.skeleton} />
            )}
        </>
    );
};

export default Breadcrumb;
