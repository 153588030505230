import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: "100%",
        },
        textField: {
            "& input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset > legend": {
                maxWidth: 0,
            },
            "& label": {
                paddingLeft: "5px",
                paddingRight: "5px",
                background: theme.palette.common.white,
            },
        },
        checkboxes: {
            "& legend": {
                display: "none",
            },
            "& .MuiInputBase-input": {
                height: "auto",
            },
            "& .MuiFormControlLabel-label": {
                fontSize: theme.typography.pxToRem(11),
            }
        },
        fieldset: {
            fontWeight: 400,
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.grey[400],
            },
        },
        newProjectBox: {
            display: "flex",
            flexFlow: "column wrap",
            justifyContent: "space-between",
            width: "100%",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row nowrap",
                alignItems: "center",
                width: "calc(150% + 2.5rem)",
            },
        },
        newProject: {
            whiteSpace: "nowrap",
            flexBasis: "100%",
            margin: theme.spacing(0.5, 0, 1, 0),
            [theme.breakpoints.up("md")]: {
                marginLeft: "1.5rem",
                flexBasis: "50%",
                margin: theme.spacing(0, 0, 0, 1.5),
            },
        },
        circularProgress: {
            color: theme.palette.primary.main,
            background: theme.palette.common.white,
        },
        chips: {
            "&  .MuiChip-root": {
                fontSize: theme.typography.pxToRem(11),
                height: "auto",
                marginRight: theme.spacing(0.5),
            }
        }
    })
);

export default useStyles;
