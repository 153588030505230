import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldset: {
            width: "100%",
            marginTop: theme.spacing(2),
        },
        legend: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.common.black,
        },
        textField: {
            "& input, & .MuiSelect-select": {
                padding: "10px 14px",
                fontSize: ".8rem",
            },
            "& .MuiSelect-select:focus": {
              backgroundColor: 'transparent',
            },
            "& textarea": {
                fontSize: ".8rem",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 14px",
            },
            [theme.breakpoints.up("md")]: {
                "& .MuiSelect-select": {
                    padding: "15px 14px",
                },
                "& input": {
                    padding: "18.5px 14px",
                },
                "& .MuiOutlinedInput-multiline": {
                    padding: "18.5px 14px",
                },
            },
        },
    })
);

export default useStyles;
