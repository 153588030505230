import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Divider, IconButton, ListItemIcon, MenuItem, Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import useStyles from "./styles";

interface IHeaderHelp {
    isMobile?: boolean;
}

const HeaderHelp: React.FC<IHeaderHelp> = ({ isMobile }) => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();

    // translations
    const intl = useIntl();
    const transHelp = intl.formatMessage({ ...messages.help });

    return isMobile ? (
        <>
            <MenuItem onClick={() => setGlobalDialogOpen(true)}>
                <ListItemIcon className={classes.listItemIcon}>
                    <HelpOutlineIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{transHelp}</Typography>
            </MenuItem>
            <Divider className={classes.divider} />
        </>
    ) : (
        <IconButton
            aria-label="Help"
            className={clsx(classes.responsiveButton, classes.iconButton)}
            onClick={() => setGlobalDialogOpen(true)}
        >
            <HelpOutlineIcon fontSize="small" />
        </IconButton>
    );
};

export default HeaderHelp;
