import { Dialog, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import PositionDialogForm from "../PositionDialogForm/PositionDialogForm";
import { PositionDialogType, PositionLevel } from "../PositionDialogForm/type";
import useStyles from "./styles";

interface IPositionDialogProps {
    allowMultiSelect?: PositionLevel[];
}

const PositionDialog: React.FC<IPositionDialogProps> = ({allowMultiSelect}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [open, setOpen] = useState<boolean>(false);
    const [showNewPositionForm, setShowNewPositionForm] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        handleHideNewPositionForm();
    };

    const handleShowNewPositionForm = () => {
        setShowNewPositionForm(true);
    };

    const handleHideNewPositionForm = () => {
        setShowNewPositionForm(false);
    };

    return (
        <>
            <IconButton aria-label="AddIcon" color="primary" onClick={handleOpen}>
                <AddIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="new-position-dialog-title"
                aria-describedby="new-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
                className={classes.root}
            >
                <PositionDialogForm
                    type={PositionDialogType.newPosition}
                    showNewPositionForm={true}
                    handleShowNewPositionForm={handleShowNewPositionForm}
                    handleHideNewPositionForm={handleHideNewPositionForm}
                    handleClose={handleClose}
                    allowMultiSelect={allowMultiSelect}
                />
            </Dialog>
        </>
    );
};

export default PositionDialog;
