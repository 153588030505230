import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { setup } from "axios-cache-adapter";
import { handleHttpErrors } from "./errorHandler";

let authorizationToken: string | null = null;
let keycloakInstance: any | null = null;

export default class ApiClient {
    private client: AxiosInstance;

    constructor(basePath = "", baseUrl: string) {
        this.client = setup({
            baseURL: `${baseUrl}${basePath}`,
            cache: {
                // cache invalidation for specific request
                // set config param: clearCacheEntry = true
                invalidate: async (cfg, request) => {
                    if (request.clearCacheEntry) {
                        // @ts-ignore
                        await cfg.store.removeItem(cfg.uuid);
                    }
                },
            },
        });
    }

    private request(config: AxiosRequestConfig): Promise<any> {
        if (authorizationToken !== null) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${authorizationToken}`,
            };
        }

        return this.client
            .request({
                ...config,
            })
            .then((response) => Promise.resolve(response))
            .catch((error: AxiosError) => handleHttpErrors(error, keycloakInstance));
    }

    public static setAuthorizationToken(token: string): void {
        authorizationToken = token;
    }

    public static setKeycloakInstance(instance: any): void {
        keycloakInstance = instance;
    }

    public get(url: string, config: AxiosRequestConfig = {}) {
        return this.request({ ...config, url, method: "GET" });
    }

    public delete(url: string, config: AxiosRequestConfig = {}) {
        return this.request({ ...config, url, method: "DELETE" });
    }

    public head(url: string, config: AxiosRequestConfig = {}) {
        return this.request({ ...config, url, method: "HEAD" });
    }

    public post(url: string, data: object = {}, config: AxiosRequestConfig = {}) {
        return this.request({ ...config, url, method: "POST", data });
    }

    public put(url: string, data: object = {}, config: AxiosRequestConfig = {}) {
        return this.request({ ...config, url, method: "PUT", data });
    }

    public patch(url: string, data: object = {}, config: AxiosRequestConfig = {}) {
        return this.request({ ...config, url, method: "PATCH", data });
    }
}
