/**
 * Return not of two arrays of numbers
 * @param a
 * @param b
 */
export function not(a: number[], b: number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

/**
 * Return intersection of two arrays of numbers
 * @param a
 * @param b
 */
export function intersection(a: number[], b: number[]): number[] {
    return a.filter((value) => b.indexOf(value) !== -1);
}
