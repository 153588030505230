import { defineMessages } from "react-intl";

const globalMessages = defineMessages({
    loadingDataError: {
        id: "components.app.loadingDataError",
        defaultMessage: "Loading data error. Please try again later.",
    },
    // loaded from external package
    premiumDialogTitle: {
        id: "components__premium-dialog__title",
        defaultMessage: "Work in progress",
    },
    premiumDialogContent: {
        id: "components__premium-dialog__content",
        defaultMessage: "This app is under development. This functionality is activated in the full version.",
    },
    premiumDialogButton: {
        id: "components__premium-dialog__button",
        defaultMessage: "Close",
    },
});

export default globalMessages;
