import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultViewerPanelSize = 40;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        columnBox: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
        },
        rowBox: {
            display: "flex",
            flexFlow: "row nowrap",
            flex: 1,
            maxHeight: `calc(100% - ${defaultViewerPanelSize}px)`,
        },
        verticalBox: {
            flex: 1,
            flexBasis: `${defaultViewerPanelSize}px`,
            backgroundColor: theme.palette.grey["100"],
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "center",
            padding: theme.spacing(0.5, 0),
        },
        verticalButton: {
            color: theme.palette.common.black,
            marginBottom: theme.spacing(0.5),
            padding: "8px",
            "&:hover": {
                backgroundColor: darken(theme.palette.grey["100"], 0.1),
            },
        },
        gridContainer: {
            height: "100%",
        },
        gridItem: {
            maxHeight: "100%",
        },
        viewerBox: {
            height: "100%",
            width: "100%",
        },
    })
);

export default useStyles;
