import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Box, Dialog, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import Icon from "../../Icon/Icon";
import PageTitle from "../../PageTitle/PageTitle";
import messages from "./messages";
import useStyles from "./styles";
import PositionDialogForm from "../../Mapping/Dialogs/PositionDialogForm/PositionDialogForm";
import { PositionDialogType } from "../../Mapping/Dialogs/PositionDialogForm/type";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IDataTablePanelProps {
}

const DataTablePanel: React.FC<IDataTablePanelProps> = ({  }) => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const [tableViews, setTableViews] = useState<string[]>(() => ["ebkph"]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChangeTableViews = (event: React.MouseEvent<HTMLElement>, newTableViews: string[]) => {
        setTableViews(newTableViews);
        setGlobalDialogOpen(true);
    };

    const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);

    // translations
    const intl = useIntl();
    const transSpecifications = intl.formatMessage({ ...messages.specifications });
    const transAddNewPosition = intl.formatMessage({ ...messages.addNewPosition });
    const transEbkph = intl.formatMessage({ ...messages.ebkph });
    const transTrades = intl.formatMessage({ ...messages.trades });
    const transDin = intl.formatMessage({ ...messages.din });

    const handleNewDialogOpen = () => {
        setNewDialogOpen(true);
    };

    const handleNewDialogClose = () => {
        setNewDialogOpen(false);
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.titleBox}>
                <PageTitle title={transSpecifications} />
            </Box>
            <Box className={classes.iconBox}>
                <NullableTooltip title={transAddNewPosition}>
                    <IconButton
                        aria-label=""
                        className={clsx(classes.iconButton, classes.iconButtonDialog)}
                        onClick={handleNewDialogOpen}
                    >
                        <Icon name="plus" size={40} />
                    </IconButton>
                </NullableTooltip>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="print" size={20} />
                </IconButton>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="cog" size={20} />
                </IconButton>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="cloud-download" size={20} />
                </IconButton>

                <ToggleButtonGroup
                    value={tableViews}
                    onChange={handleChangeTableViews}
                    aria-label="table view"
                    className={classes.toggleButtonGroup}
                >
                    <ToggleButton value="ebkph" aria-label="ebkph-h" className={classes.toggleButton}>
                        {transEbkph}
                    </ToggleButton>
                    <ToggleButton value="gewerke" aria-label="gewerke nr" className={classes.toggleButton}>
                        {transTrades}
                    </ToggleButton>
                    <ToggleButton value="din276" aria-label="DIN 276" className={classes.toggleButton}>
                        {transDin}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Dialog
                open={newDialogOpen}
                onClose={handleNewDialogClose}
                aria-labelledby="new-position-dialog-title"
                aria-describedby="new-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
                className={classes.root}
            >
                <PositionDialogForm
                    type={PositionDialogType.newPosition}
                    showNewPositionForm={true}
                    // handleShowNewPositionForm={handleShowNewPositionForm}
                    // handleHideNewPositionForm={handleHideNewPositionForm}
                    handleClose={handleNewDialogClose}
                />
            </Dialog>
        </Box>
    );
};

export default DataTablePanel;
