import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultDocumentToolbarHeight = 60;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        gridContainer: {
            height: "100%",
            flexFlow: "column nowrap",
        },
        gridItemTitle: {
            flex: 0,
        },
        gridItemTree: {
            flex: 0,
            [theme.breakpoints.up("md")]: {
                flex: "1",
            },
        },
        documentContainer: {
            height: "100%",
            alignItems: "stretch",
            alignContent: "stretch",
            paddingTop: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                paddingTop: theme.spacing(2),
            },
        },
        treeBox: {
            maxHeight: "30vh",
            height: "100%",
            overflow: "auto",
            [theme.breakpoints.up("md")]: {
                maxHeight: `calc(78vh - ${defaultDocumentToolbarHeight}px)`,
            },
        },
        paper: {
            height: "100%",
        },
    })
);

export default useStyles;
