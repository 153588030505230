import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
        },
        addButton: {
            width: "20px",
            height: "20px",
            margin: theme.spacing(0.5, 0),
        },
    })
);

export default useStyles;
