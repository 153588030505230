import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { IProjectModuleState, reducer } from "./reducer";

export const ProjectModule: IModule<IProjectModuleState> = {
    id: "project-module",
    reducerMap: {
        projectState: reducer,
    },
    middlewares: middlewares,
};
