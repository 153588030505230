import { defineMessages } from "react-intl";

export default defineMessages({
    export: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.export",
        defaultMessage: "Export equipment",
    },
    import: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.import",
        defaultMessage: "Import equipment",
    },
    equipmentCatalogue: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.equipmentCatalogue",
        defaultMessage: "Equipment Catalogue",
    },
    createNewEquipment: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.createNewEquipment",
        defaultMessage: "Create new equipment",
    },


    equipment: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.equipment",
        defaultMessage: "Equipment",
    },
    device: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.device",
        defaultMessage: "device",
    },
    addToPosition: {
        id: "components.mapping.dialogs.equipmentDialogWrapper.addToPosition",
        defaultMessage: "add to position",
    },

});
