import { DispatchAction } from "@iolabs/redux-utils";
import React from "react";
import { useDispatch } from "react-redux";
import {
    onChangeSpecificationView,
    onDefaultSpecificationView,
    onResetSpecificationExtern,
    useSpecificationViewIsExternOpen,
    useSpecificationViewIsExterns,
    useSpecificationViewIsTable,
    useSpecificationViewIsTableViewer2D,
    useSpecificationViewIsViewer2D,
    useSpecificationViewIsViewer3D,
    useSpecificationViewIsViewer3DTable,
    useSpecificationViewIsViewer3DViewer2D,
    useSpecificationViews,
} from "../../../redux/specification";
import { SpecificationView } from "../type";
import EyeSwitcherButton, { ButtonType } from "../../EyeSwitcherButton/EyeSwitcherButton";
import EyeSwitcher from "../../EyeSwitcher/EyeSwitcher";

const ViewSwitcher: React.FC = () => {
    const views: SpecificationView[] = useSpecificationViews();
    const dispatch = useDispatch<DispatchAction>();

    const isTable: boolean = useSpecificationViewIsTable();
    const isExterns: boolean = useSpecificationViewIsExterns();
    const isExternOpen: boolean = useSpecificationViewIsExternOpen();
    const isViewer2D: boolean = useSpecificationViewIsViewer2D();
    const isViewer3D: boolean = useSpecificationViewIsViewer3D();
    const isViewer3DViewer2D: boolean = useSpecificationViewIsViewer3DViewer2D();
    const isViewer3DTable: boolean = useSpecificationViewIsViewer3DTable();
    const isTableViewer2D: boolean = useSpecificationViewIsTableViewer2D();

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, newViews: SpecificationView[]) => {
        dispatch(onChangeSpecificationView({ views: newViews }));
    };

    const handleDefault = () => {
        dispatch(onDefaultSpecificationView());
    };

    const handleClickAway = () => {
        if (isExterns && !isExternOpen) {
            dispatch(onResetSpecificationExtern());
        }
    };

    const handleShortPress = (newView: SpecificationView) => {
        dispatch(
            onChangeSpecificationView({
                views: views.includes(newView) ? views.filter((view) => view !== newView) : [newView, ...views],
            })
        );
    };

    const render = () => {
        if (isViewer3D) {
            return (
                [
                    <EyeSwitcherButton
                        key={SpecificationView.Table}
                        buttonType={ButtonType.BOTTOM_LEFT}
                        valueActive={views.includes(SpecificationView.Table)}
                        valueIcon="list-border"
                        externActive={views.includes(SpecificationView.TableExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Table)}
                        onExternPress={() => handleShortPress(SpecificationView.TableExtern)}
                    />,
                    <EyeSwitcherButton
                        key={SpecificationView.Viewer2D}
                        buttonType={ButtonType.BOTTOM_RIGHT}
                        valueActive={views.includes(SpecificationView.Viewer2D)}
                        valueIcon="maze"
                        externActive={views.includes(SpecificationView.Viewer2DExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Viewer2D)}
                        onExternPress={() => handleShortPress(SpecificationView.Viewer2DExtern)}
                    />
                ]
            );
        }
        if (isViewer2D) {
            return (
                [
                    <EyeSwitcherButton
                        key={SpecificationView.Viewer3D}
                        buttonType={ButtonType.BOTTOM_LEFT}
                        valueActive={views.includes(SpecificationView.Viewer3D)}
                        valueIcon="cube"
                        externActive={views.includes(SpecificationView.Viewer3DExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Viewer3D)}
                        onExternPress={() => handleShortPress(SpecificationView.Viewer3DExtern)}
                    />,
                    <EyeSwitcherButton
                        key={SpecificationView.Table}
                        buttonType={ButtonType.BOTTOM_RIGHT}
                        valueActive={views.includes(SpecificationView.Table)}
                        valueIcon="list-border"
                        externActive={views.includes(SpecificationView.TableExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Table)}
                        onExternPress={() => handleShortPress(SpecificationView.TableExtern)}
                    />
                ]
            );
        }
        if (isTable) {
            return (
                [
                    <EyeSwitcherButton
                        key={SpecificationView.Viewer3D}
                        buttonType={ButtonType.BOTTOM_LEFT}
                        valueActive={views.includes(SpecificationView.Viewer3D)}
                        valueIcon="cube"
                        externActive={views.includes(SpecificationView.Viewer3DExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Viewer3D)}
                        onExternPress={() => handleShortPress(SpecificationView.Viewer3DExtern)}
                    />,
                    <EyeSwitcherButton
                        key={SpecificationView.Viewer2D}
                        buttonType={ButtonType.BOTTOM_RIGHT}
                        valueActive={views.includes(SpecificationView.Viewer2D)}
                        valueIcon="maze"
                        externActive={views.includes(SpecificationView.Viewer2DExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Viewer2D)}
                        onExternPress={() => handleShortPress(SpecificationView.Viewer2DExtern)}
                    />
                ]
            );
        }
        if (isViewer3DViewer2D) {
            return (
                [
                    <EyeSwitcherButton
                        key={SpecificationView.Table}
                        buttonType={ButtonType.BOTTOM_FULL}
                        valueActive={views.includes(SpecificationView.Table)}
                        valueIcon="list-border"
                        externActive={views.includes(SpecificationView.TableExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Table)}
                        onExternPress={() => handleShortPress(SpecificationView.TableExtern)}
                    />
                ]
            );
        }
        if (isViewer3DTable) {
            return (
                [
                    <EyeSwitcherButton
                        key={SpecificationView.Viewer2D}
                        buttonType={ButtonType.BOTTOM_FULL}
                        valueActive={views.includes(SpecificationView.Viewer2D)}
                        valueIcon="maze"
                        externActive={views.includes(SpecificationView.Viewer2DExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Viewer2D)}
                        onExternPress={() => handleShortPress(SpecificationView.Viewer2DExtern)}
                    />
                ]
            );
        }
        if (isTableViewer2D) {
            return (
                [
                    <EyeSwitcherButton
                        key={SpecificationView.Viewer3D}
                        buttonType={ButtonType.BOTTOM_FULL}
                        valueActive={views.includes(SpecificationView.Viewer3D)}
                        valueIcon="cube"
                        externActive={views.includes(SpecificationView.Viewer3DExtern)}
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(SpecificationView.Viewer3D)}
                        onExternPress={() => handleShortPress(SpecificationView.Viewer3DExtern)}
                    />
                ]
            );
        }
        return (
            [
                <EyeSwitcherButton
                    key={SpecificationView.Viewer3D}
                    buttonType={ButtonType.TOP_LEFT}
                    valueActive={views.includes(SpecificationView.Viewer3D)}
                    valueIcon="cube"
                    externActive={views.includes(SpecificationView.Viewer3DExtern)}
                    externIcon="window-restore"
                    onShortPress={() => handleShortPress(SpecificationView.Viewer3D)}
                    onExternPress={() => handleShortPress(SpecificationView.Viewer3DExtern)}
                />,
                <EyeSwitcherButton
                    key={SpecificationView.Viewer2D}
                    buttonType={ButtonType.TOP_RIGHT}
                    valueActive={views.includes(SpecificationView.Viewer2D)}
                    valueIcon="maze"
                    externActive={views.includes(SpecificationView.Viewer2DExtern)}
                    externIcon="window-restore"
                    onShortPress={() => handleShortPress(SpecificationView.Viewer2D)}
                    onExternPress={() => handleShortPress(SpecificationView.Viewer2DExtern)}
                />,
                <EyeSwitcherButton
                    key={SpecificationView.Table}
                    buttonType={ButtonType.BOTTOM_FULL}
                    valueActive={views.includes(SpecificationView.Table)}
                    valueIcon="list-border"
                    externActive={views.includes(SpecificationView.TableExtern)}
                    externIcon="window-restore"
                    onShortPress={() => handleShortPress(SpecificationView.Table)}
                    onExternPress={() => handleShortPress(SpecificationView.TableExtern)}
                />
            ]
        );
    };

    return (
        <EyeSwitcher
            onChange={handleChangeView}
            onEyeClick={handleDefault}
            onClickAway={handleClickAway}
            value={views}
        >
            {render()}
        </EyeSwitcher>
    );
};

export default ViewSwitcher;
