import { createSelector } from "reselect";
import { SpecificationView } from "../../components/Specification/type";
import { ISpecificationModuleState, ISpecificationState } from "./reducer";

export function selectSpecificationState(state: ISpecificationModuleState): ISpecificationState {
    return state.specificationState;
}

export const selectViews = createSelector(selectSpecificationState, (state) => state.views);
export const selectExterns = createSelector(selectSpecificationState, (state) => state.externs);
export const selectBulkIsolations = createSelector(selectSpecificationState, (state) => state.bulkIsolations);

export const selectExpandedRows = createSelector(selectSpecificationState, (state) => state.dataTable.expandedRows);
export const selectSelectedRows = createSelector(selectSpecificationState, (state) => state.dataTable.selectedRows);

export const selectIsDefault = createSelector(selectSpecificationState, (state) => {
    return (
        !state.views.includes(SpecificationView.Table) &&
        !state.views.includes(SpecificationView.Viewer2D) &&
        !state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsExterns = createSelector(selectSpecificationState, (state) => {
    return (
        state.externs.includes(SpecificationView.TableExtern) ||
        state.externs.includes(SpecificationView.Viewer2DExtern) ||
        state.externs.includes(SpecificationView.Viewer3DExtern)
    );
});

export const selectIsExternOpen = createSelector(selectSpecificationState, (state) => {
    return (
        state.views.includes(SpecificationView.TableExtern) ||
        state.views.includes(SpecificationView.Viewer2DExtern) ||
        state.views.includes(SpecificationView.Viewer3DExtern)
    );
});

export const selectIsTable = createSelector(selectSpecificationState, (state) => {
    return (
        state.views.includes(SpecificationView.Table) &&
        !state.views.includes(SpecificationView.Viewer2D) &&
        !state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsViewer2D = createSelector(selectSpecificationState, (state) => {
    return (
        !state.views.includes(SpecificationView.Table) &&
        state.views.includes(SpecificationView.Viewer2D) &&
        !state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsViewer3D = createSelector(selectSpecificationState, (state) => {
    return (
        !state.views.includes(SpecificationView.Table) &&
        !state.views.includes(SpecificationView.Viewer2D) &&
        state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsViewer3DViewer2D = createSelector(selectSpecificationState, (state) => {
    return (
        !state.views.includes(SpecificationView.Table) &&
        state.views.includes(SpecificationView.Viewer2D) &&
        state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsViewer3DTable = createSelector(selectSpecificationState, (state) => {
    return (
        state.views.includes(SpecificationView.Table) &&
        !state.views.includes(SpecificationView.Viewer2D) &&
        state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsTableViewer2D = createSelector(selectSpecificationState, (state) => {
    return (
        state.views.includes(SpecificationView.Table) &&
        state.views.includes(SpecificationView.Viewer2D) &&
        !state.views.includes(SpecificationView.Viewer3D)
    );
});

export const selectIsViewers = createSelector(selectSpecificationState, (state) => {
    return (
        !state.views.includes(SpecificationView.Table) &&
        (state.views.includes(SpecificationView.Viewer2D) || state.views.includes(SpecificationView.Viewer3D))
    );
});
