import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            color: theme.palette.grey["500"],
            padding: "8px",
            marginRight: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                height: "40px",
            },
        },
        responsiveButton: {
            display: "none",
            [theme.breakpoints.up("lg")]: {
                display: "block",
            },
        },
        listItemIcon: {
            color: theme.palette.text.secondary,
            minWidth: "35px",
        },
        popoverContent: {
            padding:  theme.spacing(1),
            display: "flex",
            flexDirection: "column"
        },
        textField: {
            cursor: "pointer",
            flex: 1,
            minWidth: "100px",
            "& input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
                cursor: "pointer",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiInputAdornment-positionStart": {
                cursor: "pointer",
            },
        },
    })
);

export default useStyles;
