export const ExtensionID = "Viewing.Extension.ToolbarExtension";

export interface IToolbarExtensionOptions {
    isIssueVisible: boolean;
    handleChangeIssueVisibility: (isVisible: boolean) => void;
}

export const register = () => {
    class ToolbarExtension extends Autodesk.Viewing.Extension {
        private privateIsVisible: boolean;

        private privateToolbar: null | Autodesk.Viewing.UI.ToolBar;

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: IToolbarExtensionOptions) {
            super(viewer, options);
            this.privateIsVisible = options?.isIssueVisible;
            this.privateToolbar = null;
        }

        load(): boolean {
            console.log("ToolbarExtension is now loaded!");
            return true;
        }

        unload(): boolean {
            console.log("ToolbarExtension is now unloaded!");
            return true;
        }

        setIsVisible(isVisible) {
            this.privateIsVisible = isVisible;
        }

        onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar): void {
            const button1 = new Autodesk.Viewing.UI.Button("issues-button");
            // default button behaviour
            button1.setToolTip("Hide All Issues");
            button1.setIcon("issueicon-issue_hide");
            button1.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
            button1.onClick = () => {
                // change button behaviour on isVisible state
                this.handleChangeIssueVisibility(!this.privateIsVisible);
                button1.setState(
                    !this.privateIsVisible
                        ? Autodesk.Viewing.UI.Button.State.ACTIVE
                        : Autodesk.Viewing.UI.Button.State.INACTIVE
                );
                button1.setToolTip(!this.privateIsVisible ? "Hide All Issues" : "Show All Issues");
                button1.setIcon(!this.privateIsVisible ? "issueicon-issue_hide" : "issueicon-issue_normal");
            };

            // sub toolbar
            const subToolbar = new Autodesk.Viewing.UI.ControlGroup("issues-toolbar");
            subToolbar.addControl(button1);
            toolbar.addControl(subToolbar);
        }

        async handleIsVisible(paramIsVisible: boolean) {
            this.setIsVisible(paramIsVisible);
            const extension: any = await this.getToolbarExtension();
            extension.setIsVisible(paramIsVisible);
            if (this.privateToolbar) {
                extension.onToolbarCreated(this.privateToolbar);
            }
        }

        handleChangeIssueVisibility(paramIsVisible: boolean) {
            this.options.handleChangeIssueVisibility(paramIsVisible);
        }

        async getToolbarExtension(): Promise<any> {
            return new Promise<any>((resolve) => {
                const extension = this.viewer.getExtension(ExtensionID);
                if (extension) {
                    resolve(extension);
                } else {
                    this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (event) => {
                        if (event.extensionId === ExtensionID) {
                            resolve(this.viewer.getExtension(ExtensionID));
                        }
                    });
                }
            });
        }
    }

    // register extension - we need to do it here so extension is loaded by Viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, ToolbarExtension);
};
