import React from "react";
import { useParams } from "react-router";
import DocumentPage from "../DocumentPage/DocumentPage";
import { IPageParams } from "../type";
import ViewerPage from "../ViewerPage/ViewerPage";

const DocumentWrapperPage: React.FC = () => {
    const { urn } = useParams<IPageParams>();

    return urn ? <ViewerPage /> : <DocumentPage />;
};

export default DocumentWrapperPage;
