export const getStructure = ()  => [
  {
    "id": 1,
    "name": "Grundstuck",
    "children": [
      {
        "id": 2,
        "name": "Haus A",
        "children": [
          {
            "id": 3,
            "name": "Level 1",
            "children": [
              {
                "id": 4,
                "name": "Walls",
                "children": [
                  {
                    "id": 5,
                    "name": "Basic Wall",
                    "children": [
                      {
                        "id": 6,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 7,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3760
                            ]
                          },
                          {
                            "id": 8,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3761
                            ]
                          },
                          {
                            "id": 9,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3762
                            ]
                          },
                          {
                            "id": 10,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3763
                            ]
                          },
                          {
                            "id": 11,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3764
                            ]
                          },
                          {
                            "id": 12,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3765
                            ]
                          },
                          {
                            "id": 13,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3766
                            ]
                          },
                          {
                            "id": 14,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3767
                            ]
                          },
                          {
                            "id": 15,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3768
                            ]
                          },
                          {
                            "id": 16,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3769
                            ]
                          },
                          {
                            "id": 17,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3770
                            ]
                          },
                          {
                            "id": 18,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3771
                            ]
                          },
                          {
                            "id": 19,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              3772
                            ]
                          }
                        ],
                        "dbIds": [
                          3760,
                          3761,
                          3762,
                          3763,
                          3764,
                          3765,
                          3766,
                          3767,
                          3768,
                          3769,
                          3770,
                          3771,
                          3772
                        ]
                      },
                      {
                        "id": 20,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 21,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3773
                            ]
                          },
                          {
                            "id": 22,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3774
                            ]
                          },
                          {
                            "id": 23,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3775
                            ]
                          },
                          {
                            "id": 24,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3776
                            ]
                          },
                          {
                            "id": 25,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3777
                            ]
                          },
                          {
                            "id": 26,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3778
                            ]
                          },
                          {
                            "id": 27,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3779
                            ]
                          },
                          {
                            "id": 28,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3780
                            ]
                          },
                          {
                            "id": 29,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              3781
                            ]
                          }
                        ],
                        "dbIds": [
                          3773,
                          3774,
                          3775,
                          3776,
                          3777,
                          3778,
                          3779,
                          3780,
                          3781
                        ]
                      },
                      {
                        "id": 30,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 31,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3782
                            ]
                          },
                          {
                            "id": 32,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3783
                            ]
                          },
                          {
                            "id": 33,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3784
                            ]
                          },
                          {
                            "id": 34,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3785
                            ]
                          },
                          {
                            "id": 35,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              3786
                            ]
                          },
                          {
                            "id": 36,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              3787
                            ]
                          },
                          {
                            "id": 37,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3788
                            ]
                          },
                          {
                            "id": 38,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              3789
                            ]
                          },
                          {
                            "id": 39,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              3790
                            ]
                          },
                          {
                            "id": 103,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4202
                            ]
                          },
                          {
                            "id": 104,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4203
                            ]
                          },
                          {
                            "id": 105,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4204
                            ]
                          },
                          {
                            "id": 106,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4205
                            ]
                          }
                        ],
                        "dbIds": [
                          3782,
                          3783,
                          3784,
                          3785,
                          3786,
                          3787,
                          3788,
                          3789,
                          3790,
                          4202,
                          4203,
                          4204,
                          4205
                        ]
                      },
                      {
                        "id": 40,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 41,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3791
                            ]
                          },
                          {
                            "id": 42,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3792
                            ]
                          },
                          {
                            "id": 43,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3793
                            ]
                          },
                          {
                            "id": 44,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3794
                            ]
                          },
                          {
                            "id": 45,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              3795
                            ]
                          },
                          {
                            "id": 46,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              3796
                            ]
                          },
                          {
                            "id": 47,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3797
                            ]
                          },
                          {
                            "id": 48,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              3798
                            ]
                          },
                          {
                            "id": 49,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              3799
                            ]
                          },
                          {
                            "id": 107,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4206
                            ]
                          },
                          {
                            "id": 108,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4207
                            ]
                          },
                          {
                            "id": 109,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4208
                            ]
                          },
                          {
                            "id": 110,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4209
                            ]
                          }
                        ],
                        "dbIds": [
                          3791,
                          3792,
                          3793,
                          3794,
                          3795,
                          3796,
                          3797,
                          3798,
                          3799,
                          4206,
                          4207,
                          4208,
                          4209
                        ]
                      },
                      {
                        "id": 50,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 51,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3800
                            ]
                          },
                          {
                            "id": 52,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3801
                            ]
                          },
                          {
                            "id": 53,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3802
                            ]
                          },
                          {
                            "id": 54,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3803
                            ]
                          },
                          {
                            "id": 55,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              3804
                            ]
                          },
                          {
                            "id": 56,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              3805
                            ]
                          },
                          {
                            "id": 57,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3806
                            ]
                          },
                          {
                            "id": 58,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              3807
                            ]
                          },
                          {
                            "id": 59,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              3808
                            ]
                          }
                        ],
                        "dbIds": [
                          3800,
                          3801,
                          3802,
                          3803,
                          3804,
                          3805,
                          3806,
                          3807,
                          3808
                        ]
                      },
                      {
                        "id": 60,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 61,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3809
                            ]
                          },
                          {
                            "id": 62,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3810
                            ]
                          },
                          {
                            "id": 63,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3811
                            ]
                          },
                          {
                            "id": 64,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3812
                            ]
                          },
                          {
                            "id": 65,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              3813
                            ]
                          },
                          {
                            "id": 66,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              3814
                            ]
                          },
                          {
                            "id": 67,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3815
                            ]
                          },
                          {
                            "id": 68,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              3816
                            ]
                          },
                          {
                            "id": 69,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              3817
                            ]
                          },
                          {
                            "id": 111,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4210
                            ]
                          },
                          {
                            "id": 112,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4211
                            ]
                          },
                          {
                            "id": 113,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4212
                            ]
                          },
                          {
                            "id": 114,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4213
                            ]
                          }
                        ],
                        "dbIds": [
                          3809,
                          3810,
                          3811,
                          3812,
                          3813,
                          3814,
                          3815,
                          3816,
                          3817,
                          4210,
                          4211,
                          4212,
                          4213
                        ]
                      },
                      {
                        "id": 70,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 71,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3885
                            ]
                          },
                          {
                            "id": 72,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3886
                            ]
                          },
                          {
                            "id": 73,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3887
                            ]
                          },
                          {
                            "id": 74,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              3888
                            ]
                          },
                          {
                            "id": 75,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3889
                            ]
                          },
                          {
                            "id": 76,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              3890
                            ]
                          },
                          {
                            "id": 77,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3891
                            ]
                          },
                          {
                            "id": 78,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              3892
                            ]
                          },
                          {
                            "id": 79,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              3893
                            ]
                          }
                        ],
                        "dbIds": [
                          3885,
                          3886,
                          3887,
                          3888,
                          3889,
                          3890,
                          3891,
                          3892,
                          3893
                        ]
                      }
                    ],
                    "dbIds": [
                      3760,
                      3761,
                      3762,
                      3763,
                      3764,
                      3765,
                      3766,
                      3767,
                      3768,
                      3769,
                      3770,
                      3771,
                      3772,
                      3773,
                      3774,
                      3775,
                      3776,
                      3777,
                      3778,
                      3779,
                      3780,
                      3781,
                      3782,
                      3783,
                      3784,
                      3785,
                      3786,
                      3787,
                      3788,
                      3789,
                      3790,
                      3791,
                      3792,
                      3793,
                      3794,
                      3795,
                      3796,
                      3797,
                      3798,
                      3799,
                      3800,
                      3801,
                      3802,
                      3803,
                      3804,
                      3805,
                      3806,
                      3807,
                      3808,
                      3809,
                      3810,
                      3811,
                      3812,
                      3813,
                      3814,
                      3815,
                      3816,
                      3817,
                      3885,
                      3886,
                      3887,
                      3888,
                      3889,
                      3890,
                      3891,
                      3892,
                      3893,
                      4202,
                      4203,
                      4204,
                      4205,
                      4206,
                      4207,
                      4208,
                      4209,
                      4210,
                      4211,
                      4212,
                      4213
                    ]
                  }
                ],
                "dbIds": [
                  3760,
                  3761,
                  3762,
                  3763,
                  3764,
                  3765,
                  3766,
                  3767,
                  3768,
                  3769,
                  3770,
                  3771,
                  3772,
                  3773,
                  3774,
                  3775,
                  3776,
                  3777,
                  3778,
                  3779,
                  3780,
                  3781,
                  3782,
                  3783,
                  3784,
                  3785,
                  3786,
                  3787,
                  3788,
                  3789,
                  3790,
                  3791,
                  3792,
                  3793,
                  3794,
                  3795,
                  3796,
                  3797,
                  3798,
                  3799,
                  3800,
                  3801,
                  3802,
                  3803,
                  3804,
                  3805,
                  3806,
                  3807,
                  3808,
                  3809,
                  3810,
                  3811,
                  3812,
                  3813,
                  3814,
                  3815,
                  3816,
                  3817,
                  3885,
                  3886,
                  3887,
                  3888,
                  3889,
                  3890,
                  3891,
                  3892,
                  3893,
                  4202,
                  4203,
                  4204,
                  4205,
                  4206,
                  4207,
                  4208,
                  4209,
                  4210,
                  4211,
                  4212,
                  4213
                ]
              },
              {
                "id": 80,
                "name": "Floors",
                "children": [
                  {
                    "id": 81,
                    "name": "Floor",
                    "children": [
                      {
                        "id": 82,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 83,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              3895
                            ]
                          },
                          {
                            "id": 84,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              3896
                            ]
                          },
                          {
                            "id": 85,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              3897
                            ]
                          },
                          {
                            "id": 86,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              3898
                            ]
                          }
                        ],
                        "dbIds": [
                          3895,
                          3896,
                          3897,
                          3898
                        ]
                      },
                      {
                        "id": 87,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 88,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              3900
                            ]
                          },
                          {
                            "id": 89,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              3901
                            ]
                          },
                          {
                            "id": 90,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              3902
                            ]
                          },
                          {
                            "id": 91,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              3903
                            ]
                          }
                        ],
                        "dbIds": [
                          3900,
                          3901,
                          3902,
                          3903
                        ]
                      },
                      {
                        "id": 92,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 93,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              3905
                            ]
                          },
                          {
                            "id": 94,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              3906
                            ]
                          },
                          {
                            "id": 95,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              3907
                            ]
                          },
                          {
                            "id": 96,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              3908
                            ]
                          }
                        ],
                        "dbIds": [
                          3905,
                          3906,
                          3907,
                          3908
                        ]
                      }
                    ],
                    "dbIds": [
                      3895,
                      3896,
                      3897,
                      3898,
                      3900,
                      3901,
                      3902,
                      3903,
                      3905,
                      3906,
                      3907,
                      3908
                    ]
                  }
                ],
                "dbIds": [
                  3895,
                  3896,
                  3897,
                  3898,
                  3900,
                  3901,
                  3902,
                  3903,
                  3905,
                  3906,
                  3907,
                  3908
                ]
              },
              {
                "id": 97,
                "name": "Window",
                "children": [
                  {
                    "id": 98,
                    "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP",
                    "children": [
                      {
                        "id": 99,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [427463]",
                        "children": [],
                        "dbIds": [
                          4101
                        ]
                      },
                      {
                        "id": 100,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [427664]",
                        "children": [],
                        "dbIds": [
                          4106
                        ]
                      },
                      {
                        "id": 101,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [427770]",
                        "children": [],
                        "dbIds": [
                          4109
                        ]
                      },
                      {
                        "id": 102,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [427898]",
                        "children": [],
                        "dbIds": [
                          4114
                        ]
                      }
                    ],
                    "dbIds": [
                      4101,
                      4106,
                      4109,
                      4114
                    ]
                  }
                ],
                "dbIds": [
                  4101,
                  4106,
                  4109,
                  4114
                ]
              }
            ],
            "dbIds": [
              3760,
              3761,
              3762,
              3763,
              3764,
              3765,
              3766,
              3767,
              3768,
              3769,
              3770,
              3771,
              3772,
              3773,
              3774,
              3775,
              3776,
              3777,
              3778,
              3779,
              3780,
              3781,
              3782,
              3783,
              3784,
              3785,
              3786,
              3787,
              3788,
              3789,
              3790,
              3791,
              3792,
              3793,
              3794,
              3795,
              3796,
              3797,
              3798,
              3799,
              3800,
              3801,
              3802,
              3803,
              3804,
              3805,
              3806,
              3807,
              3808,
              3809,
              3810,
              3811,
              3812,
              3813,
              3814,
              3815,
              3816,
              3817,
              3885,
              3886,
              3887,
              3888,
              3889,
              3890,
              3891,
              3892,
              3893,
              3895,
              3896,
              3897,
              3898,
              3900,
              3901,
              3902,
              3903,
              3905,
              3906,
              3907,
              3908,
              4101,
              4106,
              4109,
              4114,
              4202,
              4203,
              4204,
              4205,
              4206,
              4207,
              4208,
              4209,
              4210,
              4211,
              4212,
              4213
            ]
          },
          {
            "id": 115,
            "name": "Level 2",
            "children": [
              {
                "id": 116,
                "name": "Walls",
                "children": [
                  {
                    "id": 117,
                    "name": "Basic Wall",
                    "children": [
                      {
                        "id": 118,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 119,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4230
                            ]
                          },
                          {
                            "id": 120,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4231
                            ]
                          },
                          {
                            "id": 121,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4232
                            ]
                          },
                          {
                            "id": 122,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4233
                            ]
                          },
                          {
                            "id": 123,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4234
                            ]
                          },
                          {
                            "id": 124,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4235
                            ]
                          },
                          {
                            "id": 125,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4236
                            ]
                          },
                          {
                            "id": 126,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4237
                            ]
                          },
                          {
                            "id": 127,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4238
                            ]
                          },
                          {
                            "id": 211,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4408
                            ]
                          },
                          {
                            "id": 212,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4409
                            ]
                          },
                          {
                            "id": 213,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4410
                            ]
                          },
                          {
                            "id": 214,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4411
                            ]
                          }
                        ],
                        "dbIds": [
                          4230,
                          4231,
                          4232,
                          4233,
                          4234,
                          4235,
                          4236,
                          4237,
                          4238,
                          4408,
                          4409,
                          4410,
                          4411
                        ]
                      },
                      {
                        "id": 128,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 129,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4239
                            ]
                          },
                          {
                            "id": 130,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4240
                            ]
                          },
                          {
                            "id": 131,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4241
                            ]
                          },
                          {
                            "id": 132,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4242
                            ]
                          },
                          {
                            "id": 133,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4243
                            ]
                          },
                          {
                            "id": 134,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4244
                            ]
                          },
                          {
                            "id": 135,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4245
                            ]
                          },
                          {
                            "id": 136,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4246
                            ]
                          },
                          {
                            "id": 137,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4247
                            ]
                          }
                        ],
                        "dbIds": [
                          4239,
                          4240,
                          4241,
                          4242,
                          4243,
                          4244,
                          4245,
                          4246,
                          4247
                        ]
                      },
                      {
                        "id": 138,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 139,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4248
                            ]
                          },
                          {
                            "id": 140,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4249
                            ]
                          },
                          {
                            "id": 141,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4250
                            ]
                          },
                          {
                            "id": 142,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4251
                            ]
                          },
                          {
                            "id": 143,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4252
                            ]
                          },
                          {
                            "id": 144,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4253
                            ]
                          },
                          {
                            "id": 145,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4254
                            ]
                          },
                          {
                            "id": 146,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4255
                            ]
                          },
                          {
                            "id": 147,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4256
                            ]
                          }
                        ],
                        "dbIds": [
                          4248,
                          4249,
                          4250,
                          4251,
                          4252,
                          4253,
                          4254,
                          4255,
                          4256
                        ]
                      },
                      {
                        "id": 148,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 149,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4257
                            ]
                          },
                          {
                            "id": 150,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4258
                            ]
                          },
                          {
                            "id": 151,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4259
                            ]
                          },
                          {
                            "id": 152,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4260
                            ]
                          },
                          {
                            "id": 153,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4261
                            ]
                          },
                          {
                            "id": 154,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4262
                            ]
                          },
                          {
                            "id": 155,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4263
                            ]
                          },
                          {
                            "id": 156,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4264
                            ]
                          },
                          {
                            "id": 157,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4265
                            ]
                          }
                        ],
                        "dbIds": [
                          4257,
                          4258,
                          4259,
                          4260,
                          4261,
                          4262,
                          4263,
                          4264,
                          4265
                        ]
                      },
                      {
                        "id": 158,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 159,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4266
                            ]
                          },
                          {
                            "id": 160,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4267
                            ]
                          },
                          {
                            "id": 161,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4268
                            ]
                          },
                          {
                            "id": 162,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4269
                            ]
                          },
                          {
                            "id": 163,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4270
                            ]
                          },
                          {
                            "id": 164,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4271
                            ]
                          },
                          {
                            "id": 165,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4272
                            ]
                          },
                          {
                            "id": 166,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4273
                            ]
                          },
                          {
                            "id": 167,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4274
                            ]
                          }
                        ],
                        "dbIds": [
                          4266,
                          4267,
                          4268,
                          4269,
                          4270,
                          4271,
                          4272,
                          4273,
                          4274
                        ]
                      },
                      {
                        "id": 168,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 169,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4275
                            ]
                          },
                          {
                            "id": 170,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4276
                            ]
                          },
                          {
                            "id": 171,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4277
                            ]
                          },
                          {
                            "id": 172,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4278
                            ]
                          },
                          {
                            "id": 173,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4279
                            ]
                          },
                          {
                            "id": 174,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4280
                            ]
                          },
                          {
                            "id": 175,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4281
                            ]
                          },
                          {
                            "id": 176,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4282
                            ]
                          },
                          {
                            "id": 177,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4283
                            ]
                          }
                        ],
                        "dbIds": [
                          4275,
                          4276,
                          4277,
                          4278,
                          4279,
                          4280,
                          4281,
                          4282,
                          4283
                        ]
                      },
                      {
                        "id": 178,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 179,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4311
                            ]
                          },
                          {
                            "id": 180,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4312
                            ]
                          },
                          {
                            "id": 181,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4313
                            ]
                          },
                          {
                            "id": 182,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4314
                            ]
                          },
                          {
                            "id": 183,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4315
                            ]
                          },
                          {
                            "id": 184,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4316
                            ]
                          },
                          {
                            "id": 185,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4317
                            ]
                          },
                          {
                            "id": 186,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4318
                            ]
                          },
                          {
                            "id": 187,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4319
                            ]
                          }
                        ],
                        "dbIds": [
                          4311,
                          4312,
                          4313,
                          4314,
                          4315,
                          4316,
                          4317,
                          4318,
                          4319
                        ]
                      }
                    ],
                    "dbIds": [
                      4230,
                      4231,
                      4232,
                      4233,
                      4234,
                      4235,
                      4236,
                      4237,
                      4238,
                      4239,
                      4240,
                      4241,
                      4242,
                      4243,
                      4244,
                      4245,
                      4246,
                      4247,
                      4248,
                      4249,
                      4250,
                      4251,
                      4252,
                      4253,
                      4254,
                      4255,
                      4256,
                      4257,
                      4258,
                      4259,
                      4260,
                      4261,
                      4262,
                      4263,
                      4264,
                      4265,
                      4266,
                      4267,
                      4268,
                      4269,
                      4270,
                      4271,
                      4272,
                      4273,
                      4274,
                      4275,
                      4276,
                      4277,
                      4278,
                      4279,
                      4280,
                      4281,
                      4282,
                      4283,
                      4311,
                      4312,
                      4313,
                      4314,
                      4315,
                      4316,
                      4317,
                      4318,
                      4319,
                      4408,
                      4409,
                      4410,
                      4411
                    ]
                  }
                ],
                "dbIds": [
                  4230,
                  4231,
                  4232,
                  4233,
                  4234,
                  4235,
                  4236,
                  4237,
                  4238,
                  4239,
                  4240,
                  4241,
                  4242,
                  4243,
                  4244,
                  4245,
                  4246,
                  4247,
                  4248,
                  4249,
                  4250,
                  4251,
                  4252,
                  4253,
                  4254,
                  4255,
                  4256,
                  4257,
                  4258,
                  4259,
                  4260,
                  4261,
                  4262,
                  4263,
                  4264,
                  4265,
                  4266,
                  4267,
                  4268,
                  4269,
                  4270,
                  4271,
                  4272,
                  4273,
                  4274,
                  4275,
                  4276,
                  4277,
                  4278,
                  4279,
                  4280,
                  4281,
                  4282,
                  4283,
                  4311,
                  4312,
                  4313,
                  4314,
                  4315,
                  4316,
                  4317,
                  4318,
                  4319,
                  4408,
                  4409,
                  4410,
                  4411
                ]
              },
              {
                "id": 188,
                "name": "Floors",
                "children": [
                  {
                    "id": 189,
                    "name": "Floor",
                    "children": [
                      {
                        "id": 190,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 191,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4321
                            ]
                          },
                          {
                            "id": 192,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4322
                            ]
                          },
                          {
                            "id": 193,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4323
                            ]
                          },
                          {
                            "id": 194,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4324
                            ]
                          }
                        ],
                        "dbIds": [
                          4321,
                          4322,
                          4323,
                          4324
                        ]
                      },
                      {
                        "id": 195,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 196,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4326
                            ]
                          },
                          {
                            "id": 197,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4327
                            ]
                          },
                          {
                            "id": 198,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4328
                            ]
                          },
                          {
                            "id": 199,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4329
                            ]
                          }
                        ],
                        "dbIds": [
                          4326,
                          4327,
                          4328,
                          4329
                        ]
                      },
                      {
                        "id": 200,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 201,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4331
                            ]
                          },
                          {
                            "id": 202,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4332
                            ]
                          },
                          {
                            "id": 203,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4333
                            ]
                          },
                          {
                            "id": 204,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4334
                            ]
                          }
                        ],
                        "dbIds": [
                          4331,
                          4332,
                          4333,
                          4334
                        ]
                      }
                    ],
                    "dbIds": [
                      4321,
                      4322,
                      4323,
                      4324,
                      4326,
                      4327,
                      4328,
                      4329,
                      4331,
                      4332,
                      4333,
                      4334
                    ]
                  }
                ],
                "dbIds": [
                  4321,
                  4322,
                  4323,
                  4324,
                  4326,
                  4327,
                  4328,
                  4329,
                  4331,
                  4332,
                  4333,
                  4334
                ]
              },
              {
                "id": 205,
                "name": "Window",
                "children": [
                  {
                    "id": 206,
                    "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP",
                    "children": [
                      {
                        "id": 207,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [430756]",
                        "children": [],
                        "dbIds": [
                          4335
                        ]
                      },
                      {
                        "id": 208,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [430757]",
                        "children": [],
                        "dbIds": [
                          4336
                        ]
                      },
                      {
                        "id": 209,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [430758]",
                        "children": [],
                        "dbIds": [
                          4337
                        ]
                      },
                      {
                        "id": 210,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [430759]",
                        "children": [],
                        "dbIds": [
                          4338
                        ]
                      }
                    ],
                    "dbIds": [
                      4335,
                      4336,
                      4337,
                      4338
                    ]
                  }
                ],
                "dbIds": [
                  4335,
                  4336,
                  4337,
                  4338
                ]
              }
            ],
            "dbIds": [
              4230,
              4231,
              4232,
              4233,
              4234,
              4235,
              4236,
              4237,
              4238,
              4239,
              4240,
              4241,
              4242,
              4243,
              4244,
              4245,
              4246,
              4247,
              4248,
              4249,
              4250,
              4251,
              4252,
              4253,
              4254,
              4255,
              4256,
              4257,
              4258,
              4259,
              4260,
              4261,
              4262,
              4263,
              4264,
              4265,
              4266,
              4267,
              4268,
              4269,
              4270,
              4271,
              4272,
              4273,
              4274,
              4275,
              4276,
              4277,
              4278,
              4279,
              4280,
              4281,
              4282,
              4283,
              4311,
              4312,
              4313,
              4314,
              4315,
              4316,
              4317,
              4318,
              4319,
              4321,
              4322,
              4323,
              4324,
              4326,
              4327,
              4328,
              4329,
              4331,
              4332,
              4333,
              4334,
              4335,
              4336,
              4337,
              4338,
              4408,
              4409,
              4410,
              4411
            ]
          },
          {
            "id": 215,
            "name": "Level 3",
            "children": [
              {
                "id": 216,
                "name": "Floors",
                "children": [
                  {
                    "id": 217,
                    "name": "Floor",
                    "children": [
                      {
                        "id": 218,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 219,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4422
                            ]
                          },
                          {
                            "id": 220,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4423
                            ]
                          },
                          {
                            "id": 221,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4424
                            ]
                          },
                          {
                            "id": 222,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4425
                            ]
                          }
                        ],
                        "dbIds": [
                          4422,
                          4423,
                          4424,
                          4425
                        ]
                      }
                    ],
                    "dbIds": [
                      4422,
                      4423,
                      4424,
                      4425
                    ]
                  }
                ],
                "dbIds": [
                  4422,
                  4423,
                  4424,
                  4425
                ]
              }
            ],
            "dbIds": [
              4422,
              4423,
              4424,
              4425
            ]
          }
        ],
        "dbIds": [
          3760,
          3761,
          3762,
          3763,
          3764,
          3765,
          3766,
          3767,
          3768,
          3769,
          3770,
          3771,
          3772,
          3773,
          3774,
          3775,
          3776,
          3777,
          3778,
          3779,
          3780,
          3781,
          3782,
          3783,
          3784,
          3785,
          3786,
          3787,
          3788,
          3789,
          3790,
          3791,
          3792,
          3793,
          3794,
          3795,
          3796,
          3797,
          3798,
          3799,
          3800,
          3801,
          3802,
          3803,
          3804,
          3805,
          3806,
          3807,
          3808,
          3809,
          3810,
          3811,
          3812,
          3813,
          3814,
          3815,
          3816,
          3817,
          3885,
          3886,
          3887,
          3888,
          3889,
          3890,
          3891,
          3892,
          3893,
          3895,
          3896,
          3897,
          3898,
          3900,
          3901,
          3902,
          3903,
          3905,
          3906,
          3907,
          3908,
          4101,
          4106,
          4109,
          4114,
          4202,
          4203,
          4204,
          4205,
          4206,
          4207,
          4208,
          4209,
          4210,
          4211,
          4212,
          4213,
          4230,
          4231,
          4232,
          4233,
          4234,
          4235,
          4236,
          4237,
          4238,
          4239,
          4240,
          4241,
          4242,
          4243,
          4244,
          4245,
          4246,
          4247,
          4248,
          4249,
          4250,
          4251,
          4252,
          4253,
          4254,
          4255,
          4256,
          4257,
          4258,
          4259,
          4260,
          4261,
          4262,
          4263,
          4264,
          4265,
          4266,
          4267,
          4268,
          4269,
          4270,
          4271,
          4272,
          4273,
          4274,
          4275,
          4276,
          4277,
          4278,
          4279,
          4280,
          4281,
          4282,
          4283,
          4311,
          4312,
          4313,
          4314,
          4315,
          4316,
          4317,
          4318,
          4319,
          4321,
          4322,
          4323,
          4324,
          4326,
          4327,
          4328,
          4329,
          4331,
          4332,
          4333,
          4334,
          4335,
          4336,
          4337,
          4338,
          4408,
          4409,
          4410,
          4411,
          4422,
          4423,
          4424,
          4425
        ]
      },
      {
        "id": 223,
        "name": "Haus B",
        "children": [
          {
            "id": 224,
            "name": "Level 1",
            "children": [
              {
                "id": 225,
                "name": "Walls",
                "children": [
                  {
                    "id": 226,
                    "name": "Basic Wall",
                    "children": [
                      {
                        "id": 227,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 228,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4446
                            ]
                          },
                          {
                            "id": 229,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4447
                            ]
                          },
                          {
                            "id": 230,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4448
                            ]
                          },
                          {
                            "id": 231,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4449
                            ]
                          },
                          {
                            "id": 232,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4450
                            ]
                          },
                          {
                            "id": 233,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4451
                            ]
                          },
                          {
                            "id": 234,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4452
                            ]
                          },
                          {
                            "id": 235,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4453
                            ]
                          },
                          {
                            "id": 236,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4454
                            ]
                          },
                          {
                            "id": 237,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4455
                            ]
                          },
                          {
                            "id": 238,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4456
                            ]
                          },
                          {
                            "id": 239,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4457
                            ]
                          },
                          {
                            "id": 240,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4458
                            ]
                          }
                        ],
                        "dbIds": [
                          4446,
                          4447,
                          4448,
                          4449,
                          4450,
                          4451,
                          4452,
                          4453,
                          4454,
                          4455,
                          4456,
                          4457,
                          4458
                        ]
                      },
                      {
                        "id": 241,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 242,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4459
                            ]
                          },
                          {
                            "id": 243,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4460
                            ]
                          },
                          {
                            "id": 244,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4461
                            ]
                          },
                          {
                            "id": 245,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4462
                            ]
                          },
                          {
                            "id": 246,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4463
                            ]
                          },
                          {
                            "id": 247,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4464
                            ]
                          },
                          {
                            "id": 248,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4465
                            ]
                          },
                          {
                            "id": 249,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4466
                            ]
                          },
                          {
                            "id": 250,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4467
                            ]
                          }
                        ],
                        "dbIds": [
                          4459,
                          4460,
                          4461,
                          4462,
                          4463,
                          4464,
                          4465,
                          4466,
                          4467
                        ]
                      },
                      {
                        "id": 251,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 252,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4468
                            ]
                          },
                          {
                            "id": 253,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4469
                            ]
                          },
                          {
                            "id": 254,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4470
                            ]
                          },
                          {
                            "id": 255,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4471
                            ]
                          },
                          {
                            "id": 256,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4472
                            ]
                          },
                          {
                            "id": 257,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4473
                            ]
                          },
                          {
                            "id": 258,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4474
                            ]
                          },
                          {
                            "id": 259,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4475
                            ]
                          },
                          {
                            "id": 260,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4476
                            ]
                          },
                          {
                            "id": 324,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4562
                            ]
                          },
                          {
                            "id": 325,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4563
                            ]
                          },
                          {
                            "id": 326,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4564
                            ]
                          },
                          {
                            "id": 327,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4565
                            ]
                          }
                        ],
                        "dbIds": [
                          4468,
                          4469,
                          4470,
                          4471,
                          4472,
                          4473,
                          4474,
                          4475,
                          4476,
                          4562,
                          4563,
                          4564,
                          4565
                        ]
                      },
                      {
                        "id": 261,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 262,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4477
                            ]
                          },
                          {
                            "id": 263,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4478
                            ]
                          },
                          {
                            "id": 264,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4479
                            ]
                          },
                          {
                            "id": 265,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4480
                            ]
                          },
                          {
                            "id": 266,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4481
                            ]
                          },
                          {
                            "id": 267,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4482
                            ]
                          },
                          {
                            "id": 268,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4483
                            ]
                          },
                          {
                            "id": 269,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4484
                            ]
                          },
                          {
                            "id": 270,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4485
                            ]
                          },
                          {
                            "id": 328,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4566
                            ]
                          },
                          {
                            "id": 329,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4567
                            ]
                          },
                          {
                            "id": 330,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4568
                            ]
                          },
                          {
                            "id": 331,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4569
                            ]
                          }
                        ],
                        "dbIds": [
                          4477,
                          4478,
                          4479,
                          4480,
                          4481,
                          4482,
                          4483,
                          4484,
                          4485,
                          4566,
                          4567,
                          4568,
                          4569
                        ]
                      },
                      {
                        "id": 271,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 272,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4486
                            ]
                          },
                          {
                            "id": 273,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4487
                            ]
                          },
                          {
                            "id": 274,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4488
                            ]
                          },
                          {
                            "id": 275,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4489
                            ]
                          },
                          {
                            "id": 276,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4490
                            ]
                          },
                          {
                            "id": 277,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4491
                            ]
                          },
                          {
                            "id": 278,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4492
                            ]
                          },
                          {
                            "id": 279,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4493
                            ]
                          },
                          {
                            "id": 280,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4494
                            ]
                          }
                        ],
                        "dbIds": [
                          4486,
                          4487,
                          4488,
                          4489,
                          4490,
                          4491,
                          4492,
                          4493,
                          4494
                        ]
                      },
                      {
                        "id": 281,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 282,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4495
                            ]
                          },
                          {
                            "id": 283,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4496
                            ]
                          },
                          {
                            "id": 284,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4497
                            ]
                          },
                          {
                            "id": 285,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4498
                            ]
                          },
                          {
                            "id": 286,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4499
                            ]
                          },
                          {
                            "id": 287,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4500
                            ]
                          },
                          {
                            "id": 288,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4501
                            ]
                          },
                          {
                            "id": 289,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4502
                            ]
                          },
                          {
                            "id": 290,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4503
                            ]
                          },
                          {
                            "id": 332,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4570
                            ]
                          },
                          {
                            "id": 333,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4571
                            ]
                          },
                          {
                            "id": 334,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4572
                            ]
                          },
                          {
                            "id": 335,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4573
                            ]
                          }
                        ],
                        "dbIds": [
                          4495,
                          4496,
                          4497,
                          4498,
                          4499,
                          4500,
                          4501,
                          4502,
                          4503,
                          4570,
                          4571,
                          4572,
                          4573
                        ]
                      },
                      {
                        "id": 291,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 292,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4531
                            ]
                          },
                          {
                            "id": 293,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4532
                            ]
                          },
                          {
                            "id": 294,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4533
                            ]
                          },
                          {
                            "id": 295,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4534
                            ]
                          },
                          {
                            "id": 296,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4535
                            ]
                          },
                          {
                            "id": 297,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4536
                            ]
                          },
                          {
                            "id": 298,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4537
                            ]
                          },
                          {
                            "id": 299,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4538
                            ]
                          },
                          {
                            "id": 300,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4539
                            ]
                          }
                        ],
                        "dbIds": [
                          4531,
                          4532,
                          4533,
                          4534,
                          4535,
                          4536,
                          4537,
                          4538,
                          4539
                        ]
                      }
                    ],
                    "dbIds": [
                      4446,
                      4447,
                      4448,
                      4449,
                      4450,
                      4451,
                      4452,
                      4453,
                      4454,
                      4455,
                      4456,
                      4457,
                      4458,
                      4459,
                      4460,
                      4461,
                      4462,
                      4463,
                      4464,
                      4465,
                      4466,
                      4467,
                      4468,
                      4469,
                      4470,
                      4471,
                      4472,
                      4473,
                      4474,
                      4475,
                      4476,
                      4477,
                      4478,
                      4479,
                      4480,
                      4481,
                      4482,
                      4483,
                      4484,
                      4485,
                      4486,
                      4487,
                      4488,
                      4489,
                      4490,
                      4491,
                      4492,
                      4493,
                      4494,
                      4495,
                      4496,
                      4497,
                      4498,
                      4499,
                      4500,
                      4501,
                      4502,
                      4503,
                      4531,
                      4532,
                      4533,
                      4534,
                      4535,
                      4536,
                      4537,
                      4538,
                      4539,
                      4562,
                      4563,
                      4564,
                      4565,
                      4566,
                      4567,
                      4568,
                      4569,
                      4570,
                      4571,
                      4572,
                      4573
                    ]
                  }
                ],
                "dbIds": [
                  4446,
                  4447,
                  4448,
                  4449,
                  4450,
                  4451,
                  4452,
                  4453,
                  4454,
                  4455,
                  4456,
                  4457,
                  4458,
                  4459,
                  4460,
                  4461,
                  4462,
                  4463,
                  4464,
                  4465,
                  4466,
                  4467,
                  4468,
                  4469,
                  4470,
                  4471,
                  4472,
                  4473,
                  4474,
                  4475,
                  4476,
                  4477,
                  4478,
                  4479,
                  4480,
                  4481,
                  4482,
                  4483,
                  4484,
                  4485,
                  4486,
                  4487,
                  4488,
                  4489,
                  4490,
                  4491,
                  4492,
                  4493,
                  4494,
                  4495,
                  4496,
                  4497,
                  4498,
                  4499,
                  4500,
                  4501,
                  4502,
                  4503,
                  4531,
                  4532,
                  4533,
                  4534,
                  4535,
                  4536,
                  4537,
                  4538,
                  4539,
                  4562,
                  4563,
                  4564,
                  4565,
                  4566,
                  4567,
                  4568,
                  4569,
                  4570,
                  4571,
                  4572,
                  4573
                ]
              },
              {
                "id": 301,
                "name": "Floors",
                "children": [
                  {
                    "id": 302,
                    "name": "Floor",
                    "children": [
                      {
                        "id": 303,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 304,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4541
                            ]
                          },
                          {
                            "id": 305,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4542
                            ]
                          },
                          {
                            "id": 306,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4543
                            ]
                          },
                          {
                            "id": 307,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4544
                            ]
                          }
                        ],
                        "dbIds": [
                          4541,
                          4542,
                          4543,
                          4544
                        ]
                      },
                      {
                        "id": 308,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 309,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4546
                            ]
                          },
                          {
                            "id": 310,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4547
                            ]
                          },
                          {
                            "id": 311,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4548
                            ]
                          },
                          {
                            "id": 312,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4549
                            ]
                          }
                        ],
                        "dbIds": [
                          4546,
                          4547,
                          4548,
                          4549
                        ]
                      },
                      {
                        "id": 313,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 314,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4551
                            ]
                          },
                          {
                            "id": 315,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4552
                            ]
                          },
                          {
                            "id": 316,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4553
                            ]
                          },
                          {
                            "id": 317,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4554
                            ]
                          }
                        ],
                        "dbIds": [
                          4551,
                          4552,
                          4553,
                          4554
                        ]
                      }
                    ],
                    "dbIds": [
                      4541,
                      4542,
                      4543,
                      4544,
                      4546,
                      4547,
                      4548,
                      4549,
                      4551,
                      4552,
                      4553,
                      4554
                    ]
                  }
                ],
                "dbIds": [
                  4541,
                  4542,
                  4543,
                  4544,
                  4546,
                  4547,
                  4548,
                  4549,
                  4551,
                  4552,
                  4553,
                  4554
                ]
              },
              {
                "id": 318,
                "name": "Window",
                "children": [
                  {
                    "id": 319,
                    "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP",
                    "children": [
                      {
                        "id": 320,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431503]",
                        "children": [],
                        "dbIds": [
                          4555
                        ]
                      },
                      {
                        "id": 321,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431504]",
                        "children": [],
                        "dbIds": [
                          4556
                        ]
                      },
                      {
                        "id": 322,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431505]",
                        "children": [],
                        "dbIds": [
                          4557
                        ]
                      },
                      {
                        "id": 323,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431506]",
                        "children": [],
                        "dbIds": [
                          4558
                        ]
                      }
                    ],
                    "dbIds": [
                      4555,
                      4556,
                      4557,
                      4558
                    ]
                  }
                ],
                "dbIds": [
                  4555,
                  4556,
                  4557,
                  4558
                ]
              }
            ],
            "dbIds": [
              4446,
              4447,
              4448,
              4449,
              4450,
              4451,
              4452,
              4453,
              4454,
              4455,
              4456,
              4457,
              4458,
              4459,
              4460,
              4461,
              4462,
              4463,
              4464,
              4465,
              4466,
              4467,
              4468,
              4469,
              4470,
              4471,
              4472,
              4473,
              4474,
              4475,
              4476,
              4477,
              4478,
              4479,
              4480,
              4481,
              4482,
              4483,
              4484,
              4485,
              4486,
              4487,
              4488,
              4489,
              4490,
              4491,
              4492,
              4493,
              4494,
              4495,
              4496,
              4497,
              4498,
              4499,
              4500,
              4501,
              4502,
              4503,
              4531,
              4532,
              4533,
              4534,
              4535,
              4536,
              4537,
              4538,
              4539,
              4541,
              4542,
              4543,
              4544,
              4546,
              4547,
              4548,
              4549,
              4551,
              4552,
              4553,
              4554,
              4555,
              4556,
              4557,
              4558,
              4562,
              4563,
              4564,
              4565,
              4566,
              4567,
              4568,
              4569,
              4570,
              4571,
              4572,
              4573
            ]
          },
          {
            "id": 336,
            "name": "Level 2",
            "children": [
              {
                "id": 337,
                "name": "Walls",
                "children": [
                  {
                    "id": 338,
                    "name": "Basic Wall",
                    "children": [
                      {
                        "id": 339,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 340,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4590
                            ]
                          },
                          {
                            "id": 341,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4591
                            ]
                          },
                          {
                            "id": 342,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4592
                            ]
                          },
                          {
                            "id": 343,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4593
                            ]
                          },
                          {
                            "id": 344,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4594
                            ]
                          },
                          {
                            "id": 345,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4595
                            ]
                          },
                          {
                            "id": 346,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4596
                            ]
                          },
                          {
                            "id": 347,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4597
                            ]
                          },
                          {
                            "id": 348,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4598
                            ]
                          },
                          {
                            "id": 432,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4702
                            ]
                          },
                          {
                            "id": 433,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4703
                            ]
                          },
                          {
                            "id": 434,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4704
                            ]
                          },
                          {
                            "id": 435,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4705
                            ]
                          }
                        ],
                        "dbIds": [
                          4590,
                          4591,
                          4592,
                          4593,
                          4594,
                          4595,
                          4596,
                          4597,
                          4598,
                          4702,
                          4703,
                          4704,
                          4705
                        ]
                      },
                      {
                        "id": 349,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 350,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4599
                            ]
                          },
                          {
                            "id": 351,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4600
                            ]
                          },
                          {
                            "id": 352,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4601
                            ]
                          },
                          {
                            "id": 353,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4602
                            ]
                          },
                          {
                            "id": 354,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4603
                            ]
                          },
                          {
                            "id": 355,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4604
                            ]
                          },
                          {
                            "id": 356,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4605
                            ]
                          },
                          {
                            "id": 357,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4606
                            ]
                          },
                          {
                            "id": 358,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4607
                            ]
                          }
                        ],
                        "dbIds": [
                          4599,
                          4600,
                          4601,
                          4602,
                          4603,
                          4604,
                          4605,
                          4606,
                          4607
                        ]
                      },
                      {
                        "id": 359,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 360,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4608
                            ]
                          },
                          {
                            "id": 361,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4609
                            ]
                          },
                          {
                            "id": 362,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4610
                            ]
                          },
                          {
                            "id": 363,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4611
                            ]
                          },
                          {
                            "id": 364,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4612
                            ]
                          },
                          {
                            "id": 365,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4613
                            ]
                          },
                          {
                            "id": 366,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4614
                            ]
                          },
                          {
                            "id": 367,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4615
                            ]
                          },
                          {
                            "id": 368,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4616
                            ]
                          }
                        ],
                        "dbIds": [
                          4608,
                          4609,
                          4610,
                          4611,
                          4612,
                          4613,
                          4614,
                          4615,
                          4616
                        ]
                      },
                      {
                        "id": 369,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 370,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4617
                            ]
                          },
                          {
                            "id": 371,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4618
                            ]
                          },
                          {
                            "id": 372,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4619
                            ]
                          },
                          {
                            "id": 373,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4620
                            ]
                          },
                          {
                            "id": 374,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4621
                            ]
                          },
                          {
                            "id": 375,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4622
                            ]
                          },
                          {
                            "id": 376,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4623
                            ]
                          },
                          {
                            "id": 377,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4624
                            ]
                          },
                          {
                            "id": 378,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4625
                            ]
                          }
                        ],
                        "dbIds": [
                          4617,
                          4618,
                          4619,
                          4620,
                          4621,
                          4622,
                          4623,
                          4624,
                          4625
                        ]
                      },
                      {
                        "id": 379,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 380,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4626
                            ]
                          },
                          {
                            "id": 381,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4627
                            ]
                          },
                          {
                            "id": 382,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4628
                            ]
                          },
                          {
                            "id": 383,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4629
                            ]
                          },
                          {
                            "id": 384,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4630
                            ]
                          },
                          {
                            "id": 385,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4631
                            ]
                          },
                          {
                            "id": 386,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4632
                            ]
                          },
                          {
                            "id": 387,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4633
                            ]
                          },
                          {
                            "id": 388,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4634
                            ]
                          }
                        ],
                        "dbIds": [
                          4626,
                          4627,
                          4628,
                          4629,
                          4630,
                          4631,
                          4632,
                          4633,
                          4634
                        ]
                      },
                      {
                        "id": 389,
                        "name": "MW-Außenmauerwerk, d=36.5 cm, AP+KP",
                        "children": [
                          {
                            "id": 390,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4635
                            ]
                          },
                          {
                            "id": 391,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4636
                            ]
                          },
                          {
                            "id": 392,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4637
                            ]
                          },
                          {
                            "id": 393,
                            "name": " Silikatische Fassadenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4638
                            ]
                          },
                          {
                            "id": 394,
                            "name": " Oberputz,Mineralisch,Scheibenputz,2,0mm,Außen,weiß",
                            "children": [],
                            "dbIds": [
                              4639
                            ]
                          },
                          {
                            "id": 395,
                            "name": " Kleber und Armierungsmörtel",
                            "children": [],
                            "dbIds": [
                              4640
                            ]
                          },
                          {
                            "id": 396,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4641
                            ]
                          },
                          {
                            "id": 397,
                            "name": " Unterputz,Kalk-Zement-Leichtputz,Außen ,Silo",
                            "children": [],
                            "dbIds": [
                              4642
                            ]
                          },
                          {
                            "id": 398,
                            "name": " AW, Hlz, MW, 12 DF, 0,7, kg dm³, 0,08 W mK, DM, d = 365 mm",
                            "children": [],
                            "dbIds": [
                              4643
                            ]
                          }
                        ],
                        "dbIds": [
                          4635,
                          4636,
                          4637,
                          4638,
                          4639,
                          4640,
                          4641,
                          4642,
                          4643
                        ]
                      },
                      {
                        "id": 399,
                        "name": "MW-Innenmauerwerk, d=17.5 cm, KP+KP",
                        "children": [
                          {
                            "id": 400,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4671
                            ]
                          },
                          {
                            "id": 401,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4672
                            ]
                          },
                          {
                            "id": 402,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4673
                            ]
                          },
                          {
                            "id": 403,
                            "name": " Sol-Silikat Innenfarbe Preisgruppe 1",
                            "children": [],
                            "dbIds": [
                              4674
                            ]
                          },
                          {
                            "id": 404,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4675
                            ]
                          },
                          {
                            "id": 405,
                            "name": " Oberputz, Mineralisch, Spachtelmasse, Innen, weiß",
                            "children": [],
                            "dbIds": [
                              4676
                            ]
                          },
                          {
                            "id": 406,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4677
                            ]
                          },
                          {
                            "id": 407,
                            "name": " Unterputz,Kalk-Leichtputz,Innen,Silo",
                            "children": [],
                            "dbIds": [
                              4678
                            ]
                          },
                          {
                            "id": 408,
                            "name": " IM, Hlz, 12 DF, 0,9, kg dm³, DM, d = 240 mm",
                            "children": [],
                            "dbIds": [
                              4679
                            ]
                          }
                        ],
                        "dbIds": [
                          4671,
                          4672,
                          4673,
                          4674,
                          4675,
                          4676,
                          4677,
                          4678,
                          4679
                        ]
                      }
                    ],
                    "dbIds": [
                      4590,
                      4591,
                      4592,
                      4593,
                      4594,
                      4595,
                      4596,
                      4597,
                      4598,
                      4599,
                      4600,
                      4601,
                      4602,
                      4603,
                      4604,
                      4605,
                      4606,
                      4607,
                      4608,
                      4609,
                      4610,
                      4611,
                      4612,
                      4613,
                      4614,
                      4615,
                      4616,
                      4617,
                      4618,
                      4619,
                      4620,
                      4621,
                      4622,
                      4623,
                      4624,
                      4625,
                      4626,
                      4627,
                      4628,
                      4629,
                      4630,
                      4631,
                      4632,
                      4633,
                      4634,
                      4635,
                      4636,
                      4637,
                      4638,
                      4639,
                      4640,
                      4641,
                      4642,
                      4643,
                      4671,
                      4672,
                      4673,
                      4674,
                      4675,
                      4676,
                      4677,
                      4678,
                      4679,
                      4702,
                      4703,
                      4704,
                      4705
                    ]
                  }
                ],
                "dbIds": [
                  4590,
                  4591,
                  4592,
                  4593,
                  4594,
                  4595,
                  4596,
                  4597,
                  4598,
                  4599,
                  4600,
                  4601,
                  4602,
                  4603,
                  4604,
                  4605,
                  4606,
                  4607,
                  4608,
                  4609,
                  4610,
                  4611,
                  4612,
                  4613,
                  4614,
                  4615,
                  4616,
                  4617,
                  4618,
                  4619,
                  4620,
                  4621,
                  4622,
                  4623,
                  4624,
                  4625,
                  4626,
                  4627,
                  4628,
                  4629,
                  4630,
                  4631,
                  4632,
                  4633,
                  4634,
                  4635,
                  4636,
                  4637,
                  4638,
                  4639,
                  4640,
                  4641,
                  4642,
                  4643,
                  4671,
                  4672,
                  4673,
                  4674,
                  4675,
                  4676,
                  4677,
                  4678,
                  4679,
                  4702,
                  4703,
                  4704,
                  4705
                ]
              },
              {
                "id": 409,
                "name": "Floors",
                "children": [
                  {
                    "id": 410,
                    "name": "Floor",
                    "children": [
                      {
                        "id": 411,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 412,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4681
                            ]
                          },
                          {
                            "id": 413,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4682
                            ]
                          },
                          {
                            "id": 414,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4683
                            ]
                          },
                          {
                            "id": 415,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4684
                            ]
                          }
                        ],
                        "dbIds": [
                          4681,
                          4682,
                          4683,
                          4684
                        ]
                      },
                      {
                        "id": 416,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 417,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4686
                            ]
                          },
                          {
                            "id": 418,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4687
                            ]
                          },
                          {
                            "id": 419,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4688
                            ]
                          },
                          {
                            "id": 420,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4689
                            ]
                          }
                        ],
                        "dbIds": [
                          4686,
                          4687,
                          4688,
                          4689
                        ]
                      },
                      {
                        "id": 421,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 422,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4691
                            ]
                          },
                          {
                            "id": 423,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4692
                            ]
                          },
                          {
                            "id": 424,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4693
                            ]
                          },
                          {
                            "id": 425,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4694
                            ]
                          }
                        ],
                        "dbIds": [
                          4691,
                          4692,
                          4693,
                          4694
                        ]
                      }
                    ],
                    "dbIds": [
                      4681,
                      4682,
                      4683,
                      4684,
                      4686,
                      4687,
                      4688,
                      4689,
                      4691,
                      4692,
                      4693,
                      4694
                    ]
                  }
                ],
                "dbIds": [
                  4681,
                  4682,
                  4683,
                  4684,
                  4686,
                  4687,
                  4688,
                  4689,
                  4691,
                  4692,
                  4693,
                  4694
                ]
              },
              {
                "id": 426,
                "name": "Window",
                "children": [
                  {
                    "id": 427,
                    "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP",
                    "children": [
                      {
                        "id": 428,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431644]",
                        "children": [],
                        "dbIds": [
                          4695
                        ]
                      },
                      {
                        "id": 429,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431645]",
                        "children": [],
                        "dbIds": [
                          4696
                        ]
                      },
                      {
                        "id": 430,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431646]",
                        "children": [],
                        "dbIds": [
                          4697
                        ]
                      },
                      {
                        "id": 431,
                        "name": "Grimm_Fenster_2-teilig_mit Pfosten_new frames_02B_WIP [431647]",
                        "children": [],
                        "dbIds": [
                          4698
                        ]
                      }
                    ],
                    "dbIds": [
                      4695,
                      4696,
                      4697,
                      4698
                    ]
                  }
                ],
                "dbIds": [
                  4695,
                  4696,
                  4697,
                  4698
                ]
              }
            ],
            "dbIds": [
              4590,
              4591,
              4592,
              4593,
              4594,
              4595,
              4596,
              4597,
              4598,
              4599,
              4600,
              4601,
              4602,
              4603,
              4604,
              4605,
              4606,
              4607,
              4608,
              4609,
              4610,
              4611,
              4612,
              4613,
              4614,
              4615,
              4616,
              4617,
              4618,
              4619,
              4620,
              4621,
              4622,
              4623,
              4624,
              4625,
              4626,
              4627,
              4628,
              4629,
              4630,
              4631,
              4632,
              4633,
              4634,
              4635,
              4636,
              4637,
              4638,
              4639,
              4640,
              4641,
              4642,
              4643,
              4671,
              4672,
              4673,
              4674,
              4675,
              4676,
              4677,
              4678,
              4679,
              4681,
              4682,
              4683,
              4684,
              4686,
              4687,
              4688,
              4689,
              4691,
              4692,
              4693,
              4694,
              4695,
              4696,
              4697,
              4698,
              4702,
              4703,
              4704,
              4705
            ]
          },
          {
            "id": 436,
            "name": "Level 3",
            "children": [
              {
                "id": 437,
                "name": "Floors",
                "children": [
                  {
                    "id": 438,
                    "name": "Floor",
                    "children": [
                      {
                        "id": 439,
                        "name": "FB-OG Parkett, d=18.0 cm",
                        "children": [
                          {
                            "id": 440,
                            "name": " Parkett Eiche hell",
                            "children": [],
                            "dbIds": [
                              4716
                            ]
                          },
                          {
                            "id": 441,
                            "name": " CA-C25-F5",
                            "children": [],
                            "dbIds": [
                              4717
                            ]
                          },
                          {
                            "id": 442,
                            "name": " EPS DES-sm 0,045 W mK 5 KN m²",
                            "children": [],
                            "dbIds": [
                              4718
                            ]
                          },
                          {
                            "id": 443,
                            "name": " EPS DEO 0,034 W mK",
                            "children": [],
                            "dbIds": [
                              4719
                            ]
                          }
                        ],
                        "dbIds": [
                          4716,
                          4717,
                          4718,
                          4719
                        ]
                      }
                    ],
                    "dbIds": [
                      4716,
                      4717,
                      4718,
                      4719
                    ]
                  }
                ],
                "dbIds": [
                  4716,
                  4717,
                  4718,
                  4719
                ]
              }
            ],
            "dbIds": [
              4716,
              4717,
              4718,
              4719
            ]
          }
        ],
        "dbIds": [
          4446,
          4447,
          4448,
          4449,
          4450,
          4451,
          4452,
          4453,
          4454,
          4455,
          4456,
          4457,
          4458,
          4459,
          4460,
          4461,
          4462,
          4463,
          4464,
          4465,
          4466,
          4467,
          4468,
          4469,
          4470,
          4471,
          4472,
          4473,
          4474,
          4475,
          4476,
          4477,
          4478,
          4479,
          4480,
          4481,
          4482,
          4483,
          4484,
          4485,
          4486,
          4487,
          4488,
          4489,
          4490,
          4491,
          4492,
          4493,
          4494,
          4495,
          4496,
          4497,
          4498,
          4499,
          4500,
          4501,
          4502,
          4503,
          4531,
          4532,
          4533,
          4534,
          4535,
          4536,
          4537,
          4538,
          4539,
          4541,
          4542,
          4543,
          4544,
          4546,
          4547,
          4548,
          4549,
          4551,
          4552,
          4553,
          4554,
          4555,
          4556,
          4557,
          4558,
          4562,
          4563,
          4564,
          4565,
          4566,
          4567,
          4568,
          4569,
          4570,
          4571,
          4572,
          4573,
          4590,
          4591,
          4592,
          4593,
          4594,
          4595,
          4596,
          4597,
          4598,
          4599,
          4600,
          4601,
          4602,
          4603,
          4604,
          4605,
          4606,
          4607,
          4608,
          4609,
          4610,
          4611,
          4612,
          4613,
          4614,
          4615,
          4616,
          4617,
          4618,
          4619,
          4620,
          4621,
          4622,
          4623,
          4624,
          4625,
          4626,
          4627,
          4628,
          4629,
          4630,
          4631,
          4632,
          4633,
          4634,
          4635,
          4636,
          4637,
          4638,
          4639,
          4640,
          4641,
          4642,
          4643,
          4671,
          4672,
          4673,
          4674,
          4675,
          4676,
          4677,
          4678,
          4679,
          4681,
          4682,
          4683,
          4684,
          4686,
          4687,
          4688,
          4689,
          4691,
          4692,
          4693,
          4694,
          4695,
          4696,
          4697,
          4698,
          4702,
          4703,
          4704,
          4705,
          4716,
          4717,
          4718,
          4719
        ]
      }
    ],
    "dbIds": [
      4848,
      3760,
      3761,
      3762,
      3763,
      3764,
      3765,
      3766,
      3767,
      3768,
      3769,
      3770,
      3771,
      3772,
      3773,
      3774,
      3775,
      3776,
      3777,
      3778,
      3779,
      3780,
      3781,
      3782,
      3783,
      3784,
      3785,
      3786,
      3787,
      3788,
      3789,
      3790,
      3791,
      3792,
      3793,
      3794,
      3795,
      3796,
      3797,
      3798,
      3799,
      3800,
      3801,
      3802,
      3803,
      3804,
      3805,
      3806,
      3807,
      3808,
      3809,
      3810,
      3811,
      3812,
      3813,
      3814,
      3815,
      3816,
      3817,
      3885,
      3886,
      3887,
      3888,
      3889,
      3890,
      3891,
      3892,
      3893,
      3895,
      3896,
      3897,
      3898,
      3900,
      3901,
      3902,
      3903,
      3905,
      3906,
      3907,
      3908,
      4101,
      4106,
      4109,
      4114,
      4202,
      4203,
      4204,
      4205,
      4206,
      4207,
      4208,
      4209,
      4210,
      4211,
      4212,
      4213,
      4230,
      4231,
      4232,
      4233,
      4234,
      4235,
      4236,
      4237,
      4238,
      4239,
      4240,
      4241,
      4242,
      4243,
      4244,
      4245,
      4246,
      4247,
      4248,
      4249,
      4250,
      4251,
      4252,
      4253,
      4254,
      4255,
      4256,
      4257,
      4258,
      4259,
      4260,
      4261,
      4262,
      4263,
      4264,
      4265,
      4266,
      4267,
      4268,
      4269,
      4270,
      4271,
      4272,
      4273,
      4274,
      4275,
      4276,
      4277,
      4278,
      4279,
      4280,
      4281,
      4282,
      4283,
      4311,
      4312,
      4313,
      4314,
      4315,
      4316,
      4317,
      4318,
      4319,
      4321,
      4322,
      4323,
      4324,
      4326,
      4327,
      4328,
      4329,
      4331,
      4332,
      4333,
      4334,
      4335,
      4336,
      4337,
      4338,
      4408,
      4409,
      4410,
      4411,
      4422,
      4423,
      4424,
      4425,
      4446,
      4447,
      4448,
      4449,
      4450,
      4451,
      4452,
      4453,
      4454,
      4455,
      4456,
      4457,
      4458,
      4459,
      4460,
      4461,
      4462,
      4463,
      4464,
      4465,
      4466,
      4467,
      4468,
      4469,
      4470,
      4471,
      4472,
      4473,
      4474,
      4475,
      4476,
      4477,
      4478,
      4479,
      4480,
      4481,
      4482,
      4483,
      4484,
      4485,
      4486,
      4487,
      4488,
      4489,
      4490,
      4491,
      4492,
      4493,
      4494,
      4495,
      4496,
      4497,
      4498,
      4499,
      4500,
      4501,
      4502,
      4503,
      4531,
      4532,
      4533,
      4534,
      4535,
      4536,
      4537,
      4538,
      4539,
      4541,
      4542,
      4543,
      4544,
      4546,
      4547,
      4548,
      4549,
      4551,
      4552,
      4553,
      4554,
      4555,
      4556,
      4557,
      4558,
      4562,
      4563,
      4564,
      4565,
      4566,
      4567,
      4568,
      4569,
      4570,
      4571,
      4572,
      4573,
      4590,
      4591,
      4592,
      4593,
      4594,
      4595,
      4596,
      4597,
      4598,
      4599,
      4600,
      4601,
      4602,
      4603,
      4604,
      4605,
      4606,
      4607,
      4608,
      4609,
      4610,
      4611,
      4612,
      4613,
      4614,
      4615,
      4616,
      4617,
      4618,
      4619,
      4620,
      4621,
      4622,
      4623,
      4624,
      4625,
      4626,
      4627,
      4628,
      4629,
      4630,
      4631,
      4632,
      4633,
      4634,
      4635,
      4636,
      4637,
      4638,
      4639,
      4640,
      4641,
      4642,
      4643,
      4671,
      4672,
      4673,
      4674,
      4675,
      4676,
      4677,
      4678,
      4679,
      4681,
      4682,
      4683,
      4684,
      4686,
      4687,
      4688,
      4689,
      4691,
      4692,
      4693,
      4694,
      4695,
      4696,
      4697,
      4698,
      4702,
      4703,
      4704,
      4705,
      4716,
      4717,
      4718,
      4719
    ]
  }
]