import { DispatchAction } from "@iolabs/redux-utils";
import { FormControl, FormGroup, FormLabel, Grid, Hidden, MenuItem } from "@material-ui/core";
import { TextField } from "mui-rff";
import React, { useEffect } from "react";
import { OnChange } from "react-final-form-listeners";
import { useIntl } from "react-intl";
import { INewPositionFormValues } from "../PositionDialogForm/type";
import messages from "./messages";
import useStyles from "./styles";
import {
    onPositionFormConfiguration,
    useMappingPositionFormConfiguration,
    useMappingPositionFormConfigurationLoading,
} from "../../../../redux/mapping";
import { useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { IPositionFormConfiguration } from "../../../../packages/Api/data/elements/types";
import { formatNumber, normalizeUnit } from "../../../../utils/Formatter";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";

interface IPositionDialogFormNewPositionProps {
    values: INewPositionFormValues;
    basicPropertiesReadonly?: boolean;
    minCodeSuffix: number;
    handleSetNewPosCodeSuffix: (codeSuffix: string, newPos: number) => void;
    handleSetNewPosName: (name: string, newPos: number) => void;
    handleSetNewPosDescription: (description: string, newPos: number) => void;
    handleSetNewPosShortText: (shortText: string, newPos: number) => void;
    handleSetNewPosLongText: (longText: string, newPos: number) => void;
    handleSetReferenceUnit: (referenceUnit: number, newPos: number) => void;
    handleSetStlbBauCode: (stlbBauCode: string, newPos: number) => void;
    handleSetDin276Structure: (din276Structure: string, newPos: number) => void;
    handleSetOmniclass: (omniclass: string, newPos: number) => void;
}

interface IPositionFormConfigurationFormValues {
    attributeTypeID: number;
}

const PositionDialogFormNewPosition: React.FC<IPositionDialogFormNewPositionProps> = ({
    values,
    basicPropertiesReadonly,
    minCodeSuffix,
    handleSetNewPosCodeSuffix,
    handleSetNewPosName,
    handleSetNewPosDescription,
    handleSetNewPosShortText,
    handleSetNewPosLongText,
    handleSetReferenceUnit,
    handleSetStlbBauCode,
    handleSetDin276Structure,
    handleSetOmniclass
}) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();


    // position form configuration
    const positionFormConfigurationLoading: boolean = useMappingPositionFormConfigurationLoading();
    const positionFormConfiguration: IPositionFormConfiguration | undefined = useMappingPositionFormConfiguration();

    // translations
    const intl = useIntl();
    const transFieldEbkpAttributes = intl.formatMessage({ ...messages.fieldEbkpAttributes });
    const transFieldUserAttributes = intl.formatMessage({ ...messages.fieldUserAttributes });
    const transFieldCode = intl.formatMessage({ ...messages.fieldCode });
    const transFieldName = intl.formatMessage({ ...messages.fieldName });
    const transFieldDescription = intl.formatMessage({ ...messages.fieldDescription });
    const transFieldShortText = intl.formatMessage({ ...messages.fieldShortText });
    const transFieldLongText = intl.formatMessage({ ...messages.fieldLongText });
    const transFieldStlbBauCode = intl.formatMessage({ ...messages.fieldStlbBauCode });
    const transFieldDin276Structure = intl.formatMessage({ ...messages.fieldDin276Structure });
    const transFieldOmniclass = intl.formatMessage({ ...messages.fieldOmniclass });
    const transFieldReferenceUnit = intl.formatMessage({ ...messages.fieldReferenceUnit });
    const transFieldUnitPrice = intl.formatMessage({ ...messages.fieldUnitPrice });
    const transFieldUnitPriceTooltip = intl.formatMessage({ ...messages.fieldUnitPriceTooltip });
    const transFieldSpecifyValue = intl.formatMessage({ ...messages.fieldSpecifyValue });


    useEffect(() => {
        dispatch(
            onPositionFormConfiguration({
                token: keycloak?.token as string,
            })
        );
    }, []);

    return (
        <>
            <FormControl component="fieldset" className={classes.fieldset}>
                <FormLabel component="legend" className={classes.legend}>
                    {transFieldEbkpAttributes}
                </FormLabel>
                <FormGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField name="newPos" style={{ display: "none" }} />
                            <TextField name="parentPositionID" style={{ display: "none" }} />
                            <TextField
                                disabled={basicPropertiesReadonly}
                                required
                                name="codeSuffix"
                                value={values?.codeSuffix ? (parseInt(values?.codeSuffix) >= 1000 ? 999 : values?.codeSuffix) : null}
                                label={transFieldCode}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                type={basicPropertiesReadonly?"text":"number"}
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: {
                                        min: parseInt(values?.codeSuffix as string) < 100 ? 0 : 100,
                                        max: 999,
                                    },
                                }}
                            />
                            <OnChange name="codeSuffix">
                                {(value) => {
                                    if (value < 1000 && values?.newPos) {
                                        handleSetNewPosCodeSuffix(value, values?.newPos);
                                    }
                                }}
                            </OnChange>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                required
                                disabled={basicPropertiesReadonly}
                                name="name"
                                label={transFieldName}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                    color: "primary",
                                }}
                            />
                            <OnChange name="name">{(value) => values?.newPos && handleSetNewPosName(value, values?.newPos)}</OnChange>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                required={false}
                                disabled={basicPropertiesReadonly}
                                name="description"
                                label={transFieldDescription}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <OnChange name="description">
                                {(value) => values?.newPos && handleSetNewPosDescription(value, values?.newPos)}
                            </OnChange>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                name="shortText"
                                label={transFieldShortText}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                multiline={true}
                                rows={3}
                                InputLabelProps={{
                                    shrink: true,
                                    color: "primary",
                                }}
                            />
                            <OnChange name="shortText">
                                {(value) => values?.newPos && handleSetNewPosShortText(value, values?.newPos)}
                            </OnChange>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <TextField
                                name="longText"
                                label={transFieldLongText}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                multiline={true}
                                rows={3}
                                InputLabelProps={{
                                    shrink: true,
                                    color: "primary",
                                }}
                            />
                            <OnChange name="longText">{(value) => values?.newPos && handleSetNewPosLongText(value, values?.newPos)}</OnChange>
                        </Grid>
                    </Grid>
                </FormGroup>
            </FormControl>
            <FormControl component="fieldset" className={classes.fieldset}>
                <FormLabel component="legend" className={classes.legend}>
                    {transFieldUserAttributes}
                </FormLabel>
                <FormGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2}>
                            <TextField
                                required={false}
                                name="referenceUnitID"
                                label={transFieldReferenceUnit}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                select={true}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                    color: "primary",
                                }}
                            >
                                {positionFormConfigurationLoading ? (
                                    <MenuItem key={0} value={0} disabled>...</MenuItem>
                                ) : (positionFormConfiguration?.allowedReferenceUnits?.map((aru, index) => (
                                    <MenuItem key={index} value={aru?.attributeTypeID}>
                                        {normalizeUnit(aru?.unit)}
                                    </MenuItem>
                                )))
                                }
                            </TextField>
                            {<OnChange name="referenceUnitID">{(value) => values?.newPos && handleSetReferenceUnit(value ? value : null, values?.newPos)}</OnChange>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <NullableTooltip title={transFieldUnitPriceTooltip}>
                                <TextField
                                    required={false}
                                    name="unitPrice"
                                    label={transFieldUnitPrice}
                                    placeholder={transFieldSpecifyValue}
                                    value={values?.unitPrice ? `${formatNumber(values?.unitPrice as number)} €` : null}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                        color: "primary",
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            min: 0,
                                            step: 0.01
                                        },
                                    }}
                                />
                            </NullableTooltip>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                required={false}
                                name="stlbBauCode"
                                label={transFieldStlbBauCode}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                    color: "primary",
                                }}
                            />
                            {<OnChange name="stlbBauCode">{(value) => values?.newPos && handleSetStlbBauCode(value, values?.newPos)}</OnChange>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                required={false}
                                name="din276Structure"
                                label={transFieldDin276Structure}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <OnChange name="din276Structure">
                                {(value) => values?.newPos && handleSetDin276Structure(value, values?.newPos)}
                            </OnChange>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Hidden mdDown>
                            <Grid item md={4}/>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField
                                required={false}
                                name="omniclass"
                                label={transFieldOmniclass}
                                placeholder={transFieldSpecifyValue}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <OnChange name="omniclass">
                                {(value) => values?.newPos && handleSetOmniclass(value, values?.newPos)}
                            </OnChange>
                        </Grid>
                    </Grid>
                </FormGroup>
            </FormControl>
        </>
    );
};

export default PositionDialogFormNewPosition;
