export enum IDocumentView {
    Table = "table",
    Thumbnail = "thumbnail",
}

export enum ISubmenuDots {
    Vertical = "vertical",
    Horizontal = "horizontal",
}

export enum IDocumentIconType {
    Table = "table",
    Thumbnail = "thumbnail",
}
