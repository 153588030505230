import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            fontSize: theme.typography.pxToRem(12),
            textAlign: "center",
            fontWeight: 500,
            position: "absolute",
            transform: "translate(-50%, 70%)",
            "& p": {
                margin: 0,
                padding: 0,
                lineHeight: theme.typography.pxToRem(12),
                whiteSpace: "nowrap",
            },
        },
    })
);

export default useStyles;
