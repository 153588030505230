import { defineMessages } from "react-intl";

export default defineMessages({
    tooltipHasMarkups: {
        id: "components.documentMarkups.tooltipHasMarkups",
        defaultMessage: "Has markups",
    },
    tooltipNoMarkups: {
        id: "components.documentMarkups.tooltipNoMarkups",
        defaultMessage: "No markups",
    },
});
