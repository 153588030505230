import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultSwitcherSize } from "../../components/EyeSwitcher/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            flexFlow: "row wrap",
            height: "100%",
        },
        viewSwitcher: {
            position: "absolute",
            textAlign: "center",
            left: `calc(50% - ${defaultSwitcherSize / 2}px)`,
            top: "calc(50% - 59px)",
        },
        viewSwitcherPinnedToTop: {
            top: `calc(0% - ${defaultSwitcherSize / 2}px)`,
        },
        viewerBox: {
            padding: theme.spacing(2),
            width: "100%",
            height: "50%",
        },
        tableBox: {
            width: "100%",
            height: "50%",
            maxHeight: "50%",
        },
        fullHeight: {
            height: "100%",
            maxHeight: "100%",
        },
        emptyBox: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
        },
        emptyIcon: {
            fill: theme.palette.common.black,
            marginBottom: theme.spacing(1),
        },
        loadingBox: {
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
    })
);

export default useStyles;
