import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import config from "../config/config";

let token;
const httpLink = createHttpLink({
    uri: config.api.baseUrl + config.graphql.baseUrl,
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const client = new ApolloClient<NormalizedCacheObject>({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

interface IProps {
    children: React.ReactNode;
}

const GraphqlProvider: React.FC<IProps> = ({ children }) => {
    const { keycloak } = useKeycloak();
    token = keycloak?.token;

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
