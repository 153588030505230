import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        moreMenu: {
            "& ul": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        itemSkeleton: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0 0 0 5px",
        },
    })
);

export default useStyles;
