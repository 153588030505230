import {
    ClickAwayListener,
    Grow,
    IconButton,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import Icon from "../../Icon/Icon";
import DocumentDeleteDialog from "../DocumentDeleteDialog/DocumentDeleteDialog";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import { ISubmenuDots } from "../type";
import messages from "./messages";
import useStyles from "./styles";

interface IDocumentSubMenuProps {
    item: Data;
    dotsType: ISubmenuDots;
    handleOpenInlineEdit: (item: Data) => void;
}

const DocumentSubMenu: React.FC<IDocumentSubMenuProps> = ({ item, dotsType, handleOpenInlineEdit }) => {
    const classes = useStyles();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [openDocumentSubMenu, setOpenDocumentSubMenu] = useState<boolean>(false);
    const [openDocumentDeleteDialog, setOpenDocumentDeleteDialog] = useState<boolean>(false);

    const handleToggleDocumentSubMenu = () => {
        setOpenDocumentSubMenu((prevOpen) => !prevOpen);
    };

    const handleDocumentSubMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenDocumentSubMenu(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenDocumentSubMenu(false);
        }
    }

    const handleOpenDocumentDeleteDialog = () => {
        setOpenDocumentDeleteDialog(true);
    };

    const handleCloseDocumentDeleteDialog = () => {
        setOpenDocumentDeleteDialog(false);
    };

    // translations
    const intl = useIntl();
    const transRename = intl.formatMessage({ ...messages.rename });
    const transDelete = intl.formatMessage({ ...messages.delete });

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={openDocumentSubMenu ? "table-item-menu-gitem" : undefined}
                aria-haspopup="true"
                aria-label="open table item menu"
                color="primary"
                className={classes.iconButton}
                onClick={handleToggleDocumentSubMenu}
            >
                {dotsType === ISubmenuDots.Vertical ? (
                    <MoreVertIcon fontSize="small" />
                ) : (
                    <MoreHorizIcon fontSize="small" />
                )}
            </IconButton>
            <Popper
                open={openDocumentSubMenu}
                anchorEl={anchorRef?.current}
                role={undefined}
                transition
                disablePortal
                placement="right-start"
                className={classes.popper}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "top left",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleDocumentSubMenuClose}>
                                <MenuList
                                    autoFocusItem={openDocumentSubMenu}
                                    id="table-item-menu-gitem"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem
                                        onClick={(event) => {
                                            handleDocumentSubMenuClose(event);
                                            handleOpenInlineEdit(item);
                                        }}
                                    >
                                        <ListItemIcon className={classes.itemIcon}>
                                            <Icon name="pencil-solid" size={20} />
                                        </ListItemIcon>
                                        <Typography variant="inherit">{transRename}</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(event) => {
                                            handleDocumentSubMenuClose(event);
                                            handleOpenDocumentDeleteDialog();
                                        }}
                                    >
                                        <ListItemIcon className={classes.itemIcon}>
                                            <Icon name="trash-alt" size={20} />
                                        </ListItemIcon>
                                        <Typography variant="inherit">{transDelete}</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <DocumentDeleteDialog
                item={item}
                open={openDocumentDeleteDialog}
                handleClose={handleCloseDocumentDeleteDialog}
            />
        </>
    );
};

export default DocumentSubMenu;
