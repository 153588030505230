import { defineMessages } from "react-intl";

export default defineMessages({
    fieldEbkph: {
        id: "components.mapping.stepElementDerivedPositions.fieldEbkph",
        defaultMessage: "eBKP-H",
    },
    fieldName: {
        id: "components.mapping.stepElementDerivedPositions.fieldName",
        defaultMessage: "Name",
    },
    fieldDescription: {
        id: "components.mapping.stepElementDerivedPositions.fieldDescription",
        defaultMessage: "Description",
    },
    fieldReferenceUnit: {
        id: "components.mapping.stepElementDerivedPositions.fieldReferenceUnit",
        defaultMessage: "Reference unit",
    },
    derivedPositionTitleDerived: {
        id: "components.mapping.stepElementDerivedPositions.derivedPositionTitleDerived",
        defaultMessage: "Derived positions",
    },
    derivedPositionTitleBasic: {
        id: "components.mapping.stepElementDerivedPositions.derivedPositionTitleBasic",
        defaultMessage: "Basic positions",
    },

    confirmDeleteMessage: {
        id: "components.mapping.stepElementDerivedPositions.confirmDeleteMessage",
        defaultMessage: "Are you sure you want to clear the if condition?",
    },
    confirmClose: {
        id: "components.mapping.stepElementDerivedPositions.confirmClose",
        defaultMessage: "Close",
    },
    confirmDelete: {
        id: "components.mapping.stepElementDerivedPositions.confirmDelete",
        defaultMessage: "Delete",
    },
});
