import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { defaultDocumentToolbarHeight } from "../../../pages/DocumentPage/styles";

const defaultGridSize = 200;
const defaultThumbSize = 242;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            overflowY: "auto",
            padding: theme.spacing(2),
            [theme.breakpoints.up("md")]: {
                maxHeight: `calc(78vh - ${defaultDocumentToolbarHeight}px)`,
            },
        },
        itemsBox: {
            width: "100%",
            display: "grid",
            gap: theme.spacing(2),
            gridAutoRows: "auto",
            gridTemplateColumns: `repeat( auto-fill, minmax(${defaultGridSize}px, 1fr) )`,
            justifyItems: "stretch",
            alignItems: "stretch",
        },
        itemBox: {},
        itemPaper: {
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            position: "relative",
            padding: theme.spacing(1),
            minHeight: `${defaultThumbSize}px`,
        },
        itemPaperCheckbox: {
            position: "absolute",
            top: theme.spacing(0.5),
            left: theme.spacing(0.5),
        },
        itemPaperSubMenu: {
            position: "absolute",
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
        itemPaperVersion: {
            position: "absolute",
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        },
        itemPaperStats: {
            display: "flex",
            position: "absolute",
            bottom: theme.spacing(1),
            left: theme.spacing(1),
        },
        itemPaperStatsMarkups: {},
        itemPaperStatsIssues: {
            marginLeft: theme.spacing(0.5),
        },
        itemIcon: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
        },
        itemName: {
            padding: theme.spacing(1),
        },
        itemFooter: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
        },
        emptyBox: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                marginTop: theme.spacing(1),
            },
        },
        skeletonBox: {
            width: "100%",
            display: "grid",
            gap: theme.spacing(2),
            gridAutoRows: "auto",
            gridTemplateColumns: `repeat( auto-fill, minmax(${defaultGridSize}px, 1fr) )`,
            justifyItems: "stretch",
            alignItems: "stretch",
        },
        skeleton: {
            minHeight: `${defaultThumbSize}px`,
            borderRadius: theme.shape.borderRadius,
        },
        truncate: {
            width: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    })
);

export default useStyles;
