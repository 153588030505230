import { useGlobalDialog } from "@iolabs/wip-dialog";
import { IconButton, List, ListItem, ListItemText, Popover } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import useStyles from "./styles";

const ProjectCatalogPopover: React.FC = () => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "project-catalog-popover" : undefined;

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    // translations
    // const intl = useIntl();
    // const transProjectSettings = intl.formatMessage({ ...messages.projectSettings });

    return (
        <>
            <IconButton aria-label="Actions" onClick={handleOpenPopover}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List component="nav" aria-label="settings">
                    <ListItem
                        button
                        /*component={RouterLink} to={Path.PROJECT_SETTINGS}*/ onClick={() => {
                            setGlobalDialogOpen(true);
                            handleClosePopover();
                        }}
                    >
                        <ListItemText primary="Custom action 1" />
                    </ListItem>
                    <ListItem
                        button
                        /*component={RouterLink} to={Path.PROJECT_SETTINGS}*/ onClick={() => {
                            setGlobalDialogOpen(true);
                            handleClosePopover();
                        }}
                    >
                        <ListItemText primary="Custom action 2" />
                    </ListItem>
                    <ListItem
                        button
                        /*component={RouterLink} to={Path.PROJECT_SETTINGS}*/ onClick={() => {
                            setGlobalDialogOpen(true);
                            handleClosePopover();
                        }}
                    >
                        <ListItemText primary="Custom action 3" />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

export default ProjectCatalogPopover;
