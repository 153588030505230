import React, { useEffect, useState } from "react";
import ProjectViewer from "../../ProjectViewer/ProjectViewer";
import config from "../../../config/config";
import { generateSharingKey } from "../../../packages/Api/data/sharing/client";
import { SplitViewType } from "../../../pages/ViewerPage/ViewerPage";
import { useFileId, useFileType } from "../../../redux/viewer";
import DocViewer from "../DocViewer/DocViewer";

export enum IFileType {
    AI = "ai",
    PSD = "psd",
    PDF = "pdf",
    DOC = "doc",
    DOCX = "docx",
    XLS = "xls",
    XLSX = "xlsx",
    CSV = "csv",
    TXT = "txt",
    XXX = "xxx",
}

interface IViewerManagerProps {
    urn: string;
    versionId?: string | null;
    projectId: string;
    splitView: SplitViewType;
    showPushpins?: boolean;
    isViewerPage?: boolean;
    isIssuePage?: boolean;
    hideMarkups?: boolean;
    hideAllIssuesBtn?: boolean;
    skipProjectLoad?: boolean;
}

const ViewerManager: React.FC<IViewerManagerProps> = ({
    urn,
    versionId,
    splitView,
    showPushpins,
    isViewerPage,
    isIssuePage,
    projectId,
    hideMarkups,
    hideAllIssuesBtn,
    skipProjectLoad,
}) => {
    const fileId = useFileId();
    const fileType = useFileType();
    const [url, setUrl] = useState<string | null>(null);
    let viewer;

    useEffect(() => {
        const urlBase =
            fileId || versionId ? `${config.api.baseUrl}/datamanagement/download/${versionId || fileId}` : null;
        if (urlBase) {
            generateSharingKey(urlBase).then((key) => {
                setUrl(`${urlBase}?x-share-key=${key}`);
            });
        }

        return () => {
            setUrl(null);
        };
    }, [fileId, versionId]);

    switch (fileType) {
        case IFileType.AI:
        case IFileType.PSD:
        case IFileType.TXT:
        case IFileType.PDF: {
            viewer = url && <DocViewer url={url} googleSupport />;
            break;
        }

        case IFileType.DOC:
        case IFileType.DOCX:
        case IFileType.XLS:
        case IFileType.XLSX:
        case IFileType.CSV: {
            viewer = url && <DocViewer url={url} />;
            break;
        }

        default:
            viewer = (
                <ProjectViewer
                    projectId={projectId}
                    urn={urn}
                    splitView={splitView}
                    showPushpins={showPushpins}
                    isViewerPage={isViewerPage}
                    isIssuePage={isIssuePage}
                    hideMarkups={hideMarkups}
                    hideAllIssuesBtn={hideAllIssuesBtn}
                    skipProjectLoad={skipProjectLoad}
                />
            );
            break;
    }

    return viewer;
};

export default ViewerManager;
