import { DispatchAction } from "@iolabs/redux-utils";
import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, InputAdornment } from "@material-ui/core";
import { TextField } from "mui-rff";
import clsx from "clsx";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import useStyles from "./styles";
import { HelpOutline } from "@material-ui/icons";
import { onUpdatePositionBasics, useDetailPosition, useMappingBasicPositionsLoading } from "../../../redux/mapping";
import { useKeycloak } from "@react-keycloak/web";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import { IElementsPosition, IUpdatePositionBasicsRequestData } from "../../../packages/Api/data/elements/types";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IStepElementCommonProps {
}
interface IStepElementCommonFormValues {
    fixedQuantity?: number
}

const StepElementCommon: React.FC<IStepElementCommonProps> = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();
    const positionLoading = useMappingBasicPositionsLoading();

    const [saving, setSaving] = useState<boolean>(false);
    const [initialValues, setInititalValues] = useState<IStepElementCommonFormValues>();
    const [filteringTimeout, setFilteringTimeout] = useState<ReturnType<typeof setTimeout>>();

    // translations
    const intl = useIntl();
    const transFieldFixedQuantity = intl.formatMessage({ ...messages.fieldFixedQuantity });
    const transFieldFixedQuantityPlaceholder = intl.formatMessage({ ...messages.fieldFixedQuantityPlaceholder });
    const transFieldFixedQuantityHelp = intl.formatMessage({ ...messages.fieldFixedQuantityHelp });

    useEffect(() => {
        if (detailPosition) {
            setInititalValues({
                fixedQuantity: detailPosition.fixedQuantity
            });
        }
    }, [detailPosition]);

    useEffect(() => {
        setSaving(positionLoading);
    }, [positionLoading]);

    const handleChange = async (values: IStepElementCommonFormValues) => {
        if (filteringTimeout) {
            clearTimeout(filteringTimeout);
        }

        const data: IUpdatePositionBasicsRequestData = {}
        if (values.fixedQuantity) {
            data.fixedQuantity = values.fixedQuantity;
        }
        else {
            data.clearFixedQuantity = true;
        }

        const timeout = setTimeout(() => {
            setSaving(true);
            dispatch(
                onUpdatePositionBasics({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    data: data
                })
            );
        }, 1000);

        setFilteringTimeout(timeout);

    };

    const onSubmit = useCallback(async (values) => {
        await handleChange(values);
    }, []);


    const validate = (values) => {
        const errors: any = {};
        return errors;
    }
    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
            {({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexBasis="70%"
                    >
                        <TextField
                            required={false}
                            name="fixedQuantity"
                            label={transFieldFixedQuantity}
                            placeholder={transFieldFixedQuantityPlaceholder}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            color="primary"
                            type={"number"}
                            disabled={saving}
                            className={clsx(classes.textField, {
                                [classes.placeholder]: !values?.["fixedQuantity"],
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    {
                                        saving ? <CircularProgress size={13}/> : <NullableTooltip title={transFieldFixedQuantityHelp}>
                                            <HelpOutline className={classes.help} />
                                        </NullableTooltip>
                                    }
                                </InputAdornment>,
                            }}
                        >
                        </TextField>
                        <OnChange name="fixedQuantity">
                            {(value, previous) => {
                                if (value != previous) {
                                    handleChange(values)
                                }
                            }}
                        </OnChange>

                    </Box>
                </form>
            )}
        </Form>
    );
}

export default StepElementCommon;