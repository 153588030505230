import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        totalBox: {
            position: "absolute",
            background: theme.palette.primaryVariant.main,
            bottom: "-11px",
            right: "-10px",
            borderTopLeftRadius: theme.shape.borderRadius * 2,
            padding: theme.spacing(1, 2),
            textAlign: "right",
        },
        totalPrice: {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 700,
            margin: 0,
        },
        totalText: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
            marginTop: "-4px",
        },
        skeleton: {
            width: "100%",
            height: "26px",
            marginBottom: "4px",
            borderRadius: theme.shape.borderRadius,
        },
    })
);

export default useStyles;
