import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useIntl } from "react-intl";
import DropZone from "../DropZone/DropZone";
import messages from "./messages";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        actions: {
            textAlign: "center",
            justifyContent: "center",
            "& p": {
                margin: 0,
                fontSize: ".8rem",
                color: theme.palette.grey[500],
            },
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h3">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface IDropZoneDialogProps extends WithStyles<typeof styles> {
    open: boolean;
    handleClose: () => void;
    nodeId: string;
}

const DropZoneDialog = withStyles(styles)((props: IDropZoneDialogProps) => {
    const { open, handleClose, nodeId, classes } = props;

    // translations
    const intl = useIntl();
    const transDropZoneHeader = intl.formatMessage({ ...messages.dropZoneHeader });
    const transDropZoneFooter = intl.formatMessage({ ...messages.dropZoneFooter });

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="upload-dialog-title">
            <DialogTitle id="upload-dialog-title" onClose={handleClose}>
                {transDropZoneHeader}
            </DialogTitle>
            <DialogContent dividers>
                <DropZone nodeId={nodeId} handleClose={handleClose} />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <p>{transDropZoneFooter}</p>
            </DialogActions>
        </Dialog>
    );
});

export default DropZoneDialog;
