import { IElementsPosition } from "../packages/Api/data/elements/types";
import { IIndexedPosition } from "../redux/mapping/type";
import * as uft from "./Flatten";

export const getIndexToSearch = (positions: IElementsPosition[]) : IIndexedPosition[] => {
    const flatten: IIndexedPosition[] = uft.flatten(
        positions,
        (position: IElementsPosition) => position.childPositions as IElementsPosition[],
        (position: IElementsPosition, parentPosition?: IElementsPosition, positionId?: number, parentPositionId?: number, parentOutNode?) : IIndexedPosition => {
            const outNode = {
                text: getNormalizedPositionText(position),
                positionPath: parentOutNode?.positionPath ? [...(parentOutNode.positionPath as number[]), positionId as number] : [positionId as number],
                level: 0
            }
            outNode.level = outNode.positionPath.length??0
            return outNode;
        },
        (position: IElementsPosition) => position.positionID
    );
    return flatten;
}
const getNormalizedPositionText = (position: IElementsPosition) : string => {
    return `${position.code}|${position.name}|${position.shortText}|${position.longText}`.toLowerCase();
}

export const findIndexedPositions = (positions: IIndexedPosition[], search: string) : IIndexedPosition[] => {
    const normalizedSearch = search.toLowerCase();
    return positions.filter(p => p.text.includes(normalizedSearch));

}