import { DispatchAction } from "@iolabs/redux-utils";
import { Button, CircularProgress, List, ListItem, ListItemText, Popover } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setActiveProject } from "../../redux/project";
import Icon from "../Icon/Icon";
import { ProjectData } from "../ProjectSelectorWrapper/type";
import messages from "./messages";
import useStyles from "./styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NullableTooltip from "../NullableTooltip/NullableTooltip";
import { useLocation, matchPath, match, Link } from "react-router-dom";
import { appPages, Path } from "../../pages/PageRouter";
import { getPath } from "../../utils/Urn";
import { generatePath } from "react-router";

interface IProjectSelectorProps {
    projects: ProjectData[] | undefined;
    activeProject?: ProjectData;
    loading: boolean;
}

const ProjectSelector: FC<IProjectSelectorProps> = ({ projects, activeProject, loading }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const { pathname } = useLocation();

    const [ currentRoute, setCurrentRoute ] = useState<match|null>();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "project-selector-popover" : undefined;

    useEffect(() => {
        const routes = appPages.map(a => a.path);
        const route = matchPath(pathname, routes);
        setCurrentRoute(route)
    }, [pathname]);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleSelectProject = (project: ProjectData) => {
        handleClosePopover();
    };

    // translations
    const intl = useIntl();
    const transSelect = intl.formatMessage({ ...messages.select });

    const getSwitchToPath = (): string => {
        if (currentRoute?.path == Path.DOCUMENTS_WITH_VIEWER) {
            return Path.DOCUMENTS;
        }
        return currentRoute?.path as string;
    }

    return (
        <>
            <Button onClick={handleOpenPopover} className={classes.button}>
                {activeProject ? activeProject.name : transSelect}
                <Icon
                    name={openPopover ? "triangle-up-solid" : "triangle-down-solid"}
                    size={10}
                    className={classes.icon}
                />
            </Button>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <List component="nav" aria-label="pages" classes={{ root: classes.listRoot }}>
                    {loading ? (
                        <ListItem>
                            <ListItemText
                                primary={<CircularProgress size={18} className={classes.circularProgress} />}
                                classes={{
                                    primary: classes.listItemTextLoading,
                                }}
                            />
                        </ListItem>
                    ) : (
                        <>
                            {projects?.map((project, index) => (
                                <ListItem
                                    key={index}
                                    button
                                    onClick={() => handleSelectProject(project)}
                                    // selected={project?.projectID === activeProject?.projectID} // TODO: check projectID @petr (Jakub Jirous 2021-03-05 13:33:28)
                                >
                                    <Link
                                        to={generatePath(getSwitchToPath(), {
                                            accountUrn: project?.externalData?.[0]?.accountUrn,
                                            projectUrn: project?.externalData?.[0]?.projectUrn,
                                        })}
                                        className={classes.link}
                                    >
                                        <ListItemText
                                            primary={
                                                <>
                                                    {project?.name}
                                                    {project?.isMapped && (
                                                        <span className={classes.isMapped}>
                                                        {/* TODO: translations (Jakub Jirous 2021-03-05 13:33:45) */}
                                                            <NullableTooltip title="Mapping done">
                                                            <InfoOutlinedIcon fontFamily="small" />
                                                        </NullableTooltip>
                                                    </span>
                                                    )}
                                                </>
                                            }
                                            classes={{
                                                primary: classes.listItemTextPrimary,
                                            }}
                                        />
                                    </Link>

                                </ListItem>
                            ))}
                        </>
                    )}
                </List>
            </Popover>
        </>
    );
};

export default ProjectSelector;
