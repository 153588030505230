import { Issue } from "../../graphql/generated/graphql";
import { ExternalSystem } from "../Viewer/type";

export const getForgeIssueData = (issue: Issue): any => {
    return JSON.parse(
        issue?.issueExternals?.find((issueExternal) => issueExternal?.externalSystem?.code === ExternalSystem.Forge)
            ?.position as string
    );
};
export const buildMarkupID = (externalID: string): string => {
    return `issue-${externalID}`;
};
