import { defineMessages } from "react-intl";

export default defineMessages({
    title: {
        id: "components.mapping.dialogs.derivedPositionIfConditionDialog.title",
        defaultMessage: "If condition editor",
    },
    closeButton: {
        id: "components.mapping.dialogs.derivedPositionIfConditionDialog.closeButton",
        defaultMessage: "Close",
    },
    saveButton: {
        id: "components.mapping.dialogs.derivedPositionIfConditionDialog.saveButton",
        defaultMessage: "Save",
    },
});
