import { defineMessages } from "react-intl";

export default defineMessages({
    issue: {
        id: "components.createIssue.issue",
        defaultMessage: "Issue", // Aufgabe
    },
    newIssue: {
        id: "components.createIssue.newIssue",
        defaultMessage: "New Issue", // Neue Aufgabe
    },
    cancel: {
        id: "components.createIssue.cancel",
        defaultMessage: "Cancel", // Abbrechen
    },
    save: {
        id: "components.createIssue.save",
        defaultMessage: "Save", // Speichern
    },
    void: {
        id: "components.createIssue.void",
        defaultMessage: "Delete issue", // Als ungültig festlegen
    },
    voidDescription: {
        id: "components.createIssue.voidDescription",
        defaultMessage: "This option deletes the issue", // Wählen Sie, ob Sie die Ausgabe löschen möchten
    },
    create: {
        id: "components.createIssue.create",
        defaultMessage: "Create", // Erstellen
    },
    preview: {
        id: "components.createIssue.preview",
        defaultMessage: "Preview", // Vorschau
    },
    markup: {
        id: "components.createIssue.markup",
        defaultMessage: "Attach markup", // Markup anhängen
    },
    markupInfo: {
        id: "components.createIssue.markupInfo",
        defaultMessage: "Please create a markup in the viewer", // Bitte erstellen Sie ein Markup im Viewer
    },
    fieldType: {
        id: "components.createIssue.fieldType",
        defaultMessage: "Type", // Typ
    },
    fieldTypePlaceholder: {
        id: "components.createIssue.fieldTypePlaceholder",
        defaultMessage: "Select...", // Auswählen...
    },
    fieldStatus: {
        id: "components.createIssue.fieldStatus",
        defaultMessage: "Status", // Status
    },
    fieldStatusPlaceholder: {
        id: "components.createIssue.fieldStatusPlaceholder",
        defaultMessage: "Select...", // Auswählen...
    },
    fieldTitle: {
        id: "components.createIssue.fieldTitle",
        defaultMessage: "Title", // Titel
    },
    fieldTitlePlaceholder: {
        id: "components.createIssue.fieldTitlePlaceholder",
        defaultMessage: "Specify...", // Angeben...
    },
    fieldDueDate: {
        id: "components.createIssue.fieldDueDate",
        defaultMessage: "Due Date", // Fälling am
    },
    fieldDueDatePlaceholder: {
        id: "components.createIssue.fieldDueDatePlaceholder",
        defaultMessage: "Choose date...", // Datum wählen...
    },
    fieldLocation: {
        id: "components.createIssue.fieldLocation",
        defaultMessage: "Location Details", // Standortdetails
    },
    fieldLocationPlaceholder: {
        id: "components.createIssue.fieldLocationPlaceholder",
        defaultMessage: "Enter location...", // Standort eingeben...
    },
    fieldRootCause: {
        id: "components.createIssue.fieldRootCause",
        defaultMessage: "Root Cause", // Fehlerursache
    },
    fieldRootCausePlaceholder: {
        id: "components.createIssue.fieldRootCausePlaceholder",
        defaultMessage: "Select...", // Auswählen...
    },
    fieldDescription: {
        id: "components.createIssue.fieldDescription",
        defaultMessage: "Description", // Beschreibung
    },
    fieldDescriptionPlaceholder: {
        id: "components.createIssue.fieldDescriptionPlaceholder",
        defaultMessage: "Specify...", // Angeben...
    },
});
