import React from "react";
import Page from "../Page/Page";
import { useIntl } from "react-intl";
import messages from "./messages";

const DashboardPage: React.FC = () => {

    // translations
    const intl = useIntl();
    const transDashboardPage = intl.formatMessage({ ...messages.dashboardPage });

    return <Page>{transDashboardPage}</Page>;
};

export default DashboardPage;
