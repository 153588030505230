import { Box, Button, CircularProgress, List, ListItem, ListItemText, Popover } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { IVersion } from "../../packages/Api/data/tree/types";
import messages from "./messages";
import useStyles from "./styles";

interface IVersionSelectorProps extends React.HTMLAttributes<HTMLElement> {
    versionText?: string;
    version: IVersion | undefined;
    versions: IVersion[];
    handleChangeVersion: (newVersion: IVersion) => void;
}

const VersionSelector: React.FC<IVersionSelectorProps> = ({ versionText, version, versions, handleChangeVersion }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "version-popover" : undefined;

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (newVersion: IVersion) => {
        handleChangeVersion(newVersion);
        handleClose();
    };

    // translations
    const intl = useIntl();
    const transSelect = intl.formatMessage({ ...messages.select });
    const transUploaded = intl.formatMessage({ ...messages.uploaded });
    const transUploadedBy = intl.formatMessage({ ...messages.uploadedBy });
    const transUploadedAt = intl.formatMessage({ ...messages.uploadedAt });

    return (
        <Box className={classes.box}>
            {versionText && <Box className={classes.label}>{versionText}</Box>}
            <Box>
                {version ? (
                    <>
                        <Button aria-describedby={id} variant="text" color={"secondary"} onClick={handleOpen} className={classes.button}>
                            V{version?.data?.version}
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <List dense>
                                <>
                                    <ListItem button disabled>
                                        <ListItemText
                                            primary={transSelect}
                                            classes={{
                                                primary: classes.listItemPrimary,
                                            }}
                                        />
                                    </ListItem>

                                    {versions?.map((version: IVersion, index: number) => {
                                        const createdTime: string = version?.data?.createdTime
                                            ? new Date(version?.data?.createdTime as string).toLocaleString("de-DE")
                                            : "";

                                        const versionExtend: string =
                                            version?.data?.createdUsername || version?.data?.createdUsername
                                                ? `${transUploaded}${
                                                      version?.data?.createdUsername
                                                          ? ` ${transUploadedBy} ${version?.data?.createdUsername}`
                                                          : ""
                                                  }${
                                                      version?.data?.createdTime
                                                          ? ` ${transUploadedAt} ${createdTime}`
                                                          : ""
                                                  }`
                                                : "";

                                        return (
                                            <ListItem button key={index} onClick={() => handleChange(version)}>
                                                <ListItemText
                                                    primary={`V${version?.data?.version}`}
                                                    secondary={versionExtend}
                                                    classes={{
                                                        primary: classes.listItemPrimary,
                                                        secondary: classes.listItemSecondary,
                                                    }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </>
                            </List>
                        </Popover>
                    </>
                ) : (
                    <CircularProgress size={16} className={classes.circularProgress} />
                )}
            </Box>
        </Box>
    );
};

export default VersionSelector;
