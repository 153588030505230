import { IMappingNavigation } from "../pages/MappingManagerPage/type";

/**
 * Forked: un-flatten-tree
 * https://github.com/iyegoroff/un-flatten-tree
 */

const map = Array.prototype.map;
const reduce = Array.prototype.reduce;

const find = <T>(list: ArrayLike<T>, predicate: (item: T) => boolean) => {
    const len = list.length;

    for (let i = 0; i < len; i++) {
        if (predicate(list[i])) {
            return list[i];
        }
    }

    return undefined;
};

const identity = <T>(x: T): T => x;

/**
 * Converts tree to list.
 *
 * @param tree Array-like object representing tree.
 * @param getChildNodes Function to return child nodes.
 * @param convertNode Function to modify each item of result list.
 * @param generateId Function to generate unique ids for each item of result list.
 * @return Returns list of out nodes.
 */
export function flatten<Node, OutNode, Id>(
    tree: ArrayLike<Node>,
    getChildNodes: (node: Node) => ArrayLike<Node>,
    // @ts-ignore
    convertNode: (node: Node, parentNode?: Node, nodeId?: Id, parentNodeId?: Id, parentOutNode?: OutNode) => OutNode = identity,
    // @ts-ignore
    generateId: (node: Node) => Id = () => undefined
): OutNode[] {
    const stack = tree && tree.length ? [{ pointer: tree, offset: 0 }] : [];
    const flat: OutNode[] = [];
    let current: { pointer: ArrayLike<Node>; offset: number; node?: Node; nodeId?: Id, outNode?: OutNode };

    while (stack.length) {
        // @ts-ignore
        current = stack.pop();

        while (current.offset < current.pointer.length) {
            const node = current.pointer[current.offset];
            const nodeId = generateId(node);
            const children = getChildNodes(node);
            const outNode = convertNode(node, current.node, nodeId, current.nodeId, current.outNode)
            flat.push(outNode);

            current.offset += 1;

            if (children) {
                stack.push(current);

                current = {
                    pointer: children,
                    offset: 0,
                    node,
                    nodeId,
                    outNode
                };
            }
        }
    }

    return flat;
}

/**
 * Converts tree to list.
 *
 * @param tree Array-like object representing tree.
 * @param isMaster
 * @param navigation
 * @param searchTerm
 * @param getPrimaryChildNodes
 * @param getSecondaryChildNodes
 * @param convertNode Function to modify each item of result list.
 * @param generateId Function to generate unique ids for each item of result list.
 * @param catalogPositionIDs
 * @return Returns list of out nodes.
 */
export function flattenPositions<Node, OutNode, Id>(
    tree: ArrayLike<Node>,
    isMaster: boolean,
    navigation: IMappingNavigation,
    searchTerm: string,
    getPrimaryChildNodes: (node: Node) => ArrayLike<Node>,
    getSecondaryChildNodes: (node: Node) => ArrayLike<Node>,
    // @ts-ignore
    convertNode: (node: Node, parentNode?: Node, nodeId?: Id, parentNodeId?: Id) => OutNode = identity,
    // @ts-ignore
    generateId: (node: Node) => Id = () => undefined,
    catalogPositionIDs?: number[]
): OutNode[] {
    const stack = tree && tree.length ? [{ pointer: tree, offset: 0, level: 0 }] : [];
    const flat: OutNode[] = [];
    let current: { pointer: ArrayLike<Node>; offset: number; level: number; node?: Node; nodeId?: Id };

    const renderLogic = (node: Node, isMaster: boolean, navigation: IMappingNavigation, level: number) => {
        if (isMaster) {
            if (!navigation?.showHierarchy && !navigation?.showDerived && level === 3) {
                return true;
            }

            if (navigation?.showHierarchy && !navigation?.showDerived && level < 4) {
                return true;
            }

            if (!navigation?.showHierarchy && navigation?.showDerived && level >= 3) {
                return true;
            }
            return !!(navigation?.showHierarchy && navigation?.showDerived);
        } else {
            // @ts-ignore
            return current.level !== 5 ? catalogPositionIDs?.includes(node?.positionID) : true;
        }
    };

    while (stack.length) {
        // @ts-ignore
        current = stack.pop();

        while (current.offset < current.pointer.length && current.level <= 5) {
            const node = Object.assign(current.pointer[current.offset], { level: current.level });
            const nodeId = generateId(node);
            const children = isMaster
                ? getPrimaryChildNodes(node)
                : current.level === 4
                ? // @ts-ignore
                  catalogPositionIDs?.includes(node?.positionID)
                    ? getSecondaryChildNodes(node)
                    : getPrimaryChildNodes(node)
                : getPrimaryChildNodes(node);

            // @ts-ignore
            if (renderLogic(node, isMaster, navigation, current.level)) {
                if (
                    // @ts-ignore
                    node?.code?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                    // @ts-ignore
                    node?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                ) {
                    flat.push(convertNode(node, current.node, nodeId, current.nodeId));
                }
            }

            current.offset += 1;

            if (children) {
                stack.push(current);

                current = {
                    pointer: children,
                    offset: 0,
                    level: current.level + 1,
                    node,
                    nodeId,
                };
            }
        }
    }

    return flat;
}

/**
 * Converts list to tree.
 *
 * @param list Array-like object representing list.
 * @param isChildNode Function to check for child-parent relation.
 * @param addChildNode Function to add out node to its parent node.
 * @return Returns tree of out nodes.
 */
export function unflatten<Node>(
    list: ArrayLike<Node>,
    isChildNode: (node: Node, parentNode: Node) => boolean,
    addChildNode: (node: Node, parentNode: Node) => void
): Node[];

export function unflatten<Node, OutNode>(
    list: ArrayLike<Node>,
    isChildNode: (node: Node, parentNode: Node) => boolean,
    addChildNode: (node: OutNode, parentNode: OutNode) => void,
    convertNode: (node: Node) => OutNode
): OutNode[];

export function unflatten<Node, OutNode>(
    list: ArrayLike<Node>,
    isChildNode: (node: Node, parentNode: Node) => boolean,
    addChildNode: (node: Node | OutNode, parentNode: Node | OutNode) => void,
    convertNode?: (node: Node) => OutNode
): Array<Node | OutNode> {
    if (convertNode === undefined) {
        // @ts-ignore
        return reduce.call(
            list,
            // @ts-ignore
            (tree: Node[], node: Node) => {
                const parentNode = find(list, (parent) => isChildNode(node, parent));

                if (parentNode === undefined) {
                    tree.push(node);
                } else {
                    addChildNode(node, parentNode);
                }

                return tree;
            },
            []
        );
    } else {
        // @ts-ignore
        const mappedList: { in: Node; out: OutNode }[] = map.call(list, (node: Node) => ({
            in: node,
            out: convertNode(node),
        }));

        // @ts-ignore
        return reduce.call(
            mappedList,
            // @ts-ignore
            (tree: OutNode[], node: { in: Node; out: OutNode }) => {
                const parentNode = find(mappedList, (parent) => isChildNode(node.in, parent.in));

                if (parentNode === undefined) {
                    tree.push(node.out);
                } else {
                    // @ts-ignore
                    addChildNode(node.out, find(mappedList, (treeNode) => treeNode.in === parentNode.in).out);
                }

                return tree;
            },
            []
        );
    }
}

export default { flatten, unflatten };
