import React from "react";
import useStyles from "./styles";

interface IDocViewerProps {
    url: string;
    googleSupport?: boolean;
}

const DocViewer: React.FC<IDocViewerProps> = ({ url, googleSupport }) => {
    const classes = useStyles();

    const iframeSrc = googleSupport
        ? `https://docs.google.com/viewer?url=${url}&embedded=true`
        : `https://view.officeapps.live.com/op/view.aspx?src=${url}`;

    return (
        <div className={classes.root}>
            <iframe
                title="doc-viewer"
                src={iframeSrc}
                width="100%"
                height="100%"
                frameBorder={0}
                className={classes.iframe}
            />
        </div>
    );
};

export default DocViewer;
