import { defineMessages } from "react-intl";

const messages = defineMessages({
    projectFiles: {
        id: "components.breadcrumb.projectFiles",
        defaultMessage: "Project Files",
    },
});

export default messages;
