import { defineMessages } from "react-intl";

export default defineMessages({

    labelItemNumber: {
        id: "components.mapping.stepWorkFormWork.labelItemNumber",
        defaultMessage: "Item number", // Artikel Nr. o. G TIN
    },
    labelProductId: {
        id: "components.mapping.stepWorkFormWork.labelProductId",
        defaultMessage: "Product-ID", // Produkt-ID
    },
    labelName: {
        id: "components.mapping.stepWorkFormWork.labelName",
        defaultMessage: "Name", // Name
    },
    labelListPrice: {
        id: "components.mapping.stepWorkFormWork.labelListPrice",
        defaultMessage: "List price", // Li.-Preis
    },
    labelSurcharge: {
        id: "components.mapping.stepWorkFormWork.labelSurcharge",
        defaultMessage: "Surcharge", // Zuschlag
    },
});
