import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { ISpecificationModuleState } from "./reducer";
import { reducer } from "./reducer";

export const SpecificationModule: IModule<ISpecificationModuleState> = {
    id: "specification-module",
    reducerMap: {
        specificationState: reducer,
    },
    middlewares: middlewares,
};
