import { defineMessages } from "react-intl";

export default defineMessages({
    fieldType: {
        id: "components.mapping.stepAttribute.fieldType",
        defaultMessage: "Type",
    },
    fieldTypeNUnitPerInstance: {
        id: "components.mapping.stepAttribute.fieldTypeNUnitPerInstance",
        defaultMessage: "N pcs per instance",
    },
    fieldTypeNUnitPerProject: {
        id: "components.mapping.stepAttribute.fieldTypeNUnitPerProject",
        defaultMessage: "N pcs per project",
    },
    fieldTypeEqualUnits: {
        id: "components.mapping.stepAttribute.fieldTypeEqualUnits",
        defaultMessage: "1 to 1",
    },
    fieldTypeMapping: {
        id: "components.mapping.stepAttribute.fieldTypeMapping",
        defaultMessage: "Mapping",
    },
    fieldTypeImplicit: {
        id: "components.mapping.stepAttribute.fieldTypeImplicit",
        defaultMessage: "Implicit",
    },
    fieldTypeFreeForm: {
        id: "components.mapping.stepAttribute.fieldTypeFreeForm",
        defaultMessage: "Free form",
    },
    fieldTypeCaptureFreeForm: {
        id: "components.mapping.stepAttribute.fieldTypeCaptureFreeForm",
        defaultMessage: "Capture + Free form",
    },
    fieldTypeCapture: {
        id: "components.mapping.stepAttribute.fieldTypeCapture",
        defaultMessage: "Capture",
    },
    titleDefinedPositions: {
        id: "components.mapping.stepAttribute.titleDefinedPositions",
        defaultMessage: "Defined positions",
    },
    titleElementAttributes: {
        id: "components.mapping.stepAttribute.titleElementAttributes",
        defaultMessage: "Element attributes",
    },
    labelFunction: {
        id: "components.mapping.stepAttribute.labelFunction",
        defaultMessage: "Function"
    },
    inputPlaceholderFunction: {
        id: "components.mapping.stepAttribute.inputPlaceholderFunction",
        defaultMessage:  "Please enter Function",
    },
    labelName: {
        id: "components.mapping.stepAttribute.labelName",
        defaultMessage: "Name",
    },
    inputPlaceholderName: {
        id: "components.mapping.stepAttribute.inputPlaceholderName",
        defaultMessage:  "Please enter Name",
    },
    labelPset: {
        id: "components.mapping.stepAttribute.labelPset",
        defaultMessage: "Pset",
    },
    inputPlaceholderPset: {
        id: "components.mapping.stepAttribute.inputPlaceholderPset",
        defaultMessage:  "Please enter Pset",
    },
    labelAttribute: {
        id: "components.mapping.stepAttribute.labelAttribute",
        defaultMessage: "Attribute",
    },
    inputPlaceholderAttribute: {
        id: "components.mapping.stepAttribute.inputPlaceholderAttribute",
        defaultMessage:  "Please enter Attribute",
    },
    labelParameter: {
        id: "components.mapping.stepAttribute.labelParameter",
        defaultMessage: "Parameter",
    },
    inputPlaceholderParameter: {
        id: "components.mapping.stepAttribute.inputPlaceholderParameter",
        defaultMessage:  "Please enter Parameter",
    },
    labelDataType: {
        id: "components.mapping.stepAttribute.labelDataType",
        defaultMessage: "Data type",
    },
    inputPlaceholderDataType: {
        id: "components.mapping.stepAttribute.inputPlaceholderDataType",
        defaultMessage:  "Please enter Data type",
    },
    buttonSave: {
        id: "components.mapping.stepAttribute.buttonSave",
        defaultMessage:  "Save",
    },
    buttonSaved: {
        id: "components.mapping.stepAttribute.buttonSaved",
        defaultMessage:  "Saved",
    },
    buttonSaving: {
        id: "components.mapping.stepAttribute.buttonSaving",
        defaultMessage:  "Saving ...",
    },


    typoReferenceUnit: {
        id: "components.mapping.stepAttribute.typoReferenceUnit",
        defaultMessage:  "Reference unit",
    },
    typoLevel: {
        id: "components.mapping.stepAttribute.typoLevel",
        defaultMessage:  "Level",
    }

});
