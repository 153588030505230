import { IssueStatus } from "../../graphql/generated/graphql";

export enum IIssueTab {
    List = 0,
    Create = 1,
    Edit = 2,
}

export interface IIssueState {
    issueId?: number | null;
    code: string;
    type: string | null;
    status: string | null;
    title: string;
    assignTo: number | null;
    dueDate: Date | null;
    location: string;
    locationDetails: string;
    owner: number | null;
    rootCause: string | null;
    description: string;
    externalId?: string;
    allowedStatuses?: IssueStatus[] | null | undefined;
}

export enum IIssueStatusType {
    Open = "open",
    Draft = "draft",
    Answered = "answered",
    Closed = "closed",
    Void = "void",
}

export type IIssueStatus = {
    id: number;
    type: IIssueStatusType;
    name: string;
};

export type IIssueSelect = {
    id: number;
    name: string;
    description?: string;
    isCategory?: boolean;
};
