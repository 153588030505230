export enum PositionLevel {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
}

export interface INewPositionFormValues {
    newPos?: 4 | 5;
    parentPositionID?: number;
    codeSuffix?: string;
    name?: string;
    description?: string;
    shortText?: string;
    longText?: string;
    stlbBauCode?: string;
    din276Structure?: string;
    omniclass?: string;
    unitPrice?: number;
    referenceUnitID?: number;
}

export interface IPositionFormErrors {
    codeSuffix: string;
    name: string;
    parentPositionID: string;
}

export enum PositionDialogType {
    newPosition = "newPosition",
    editPosition = "editPosition",
    derivedPosition = "derivedPosition",
    basicPosition = "basicPosition",
    movePosition = "movePosition",
}
