/**
 * Material schema
 */
import { AttributeType } from "../../../../graphql/generated/graphql";

interface IMappingMaterialSchema {
    class: string; // Identity - Descriptive / class
    comment: string;
    compressibility: number; // Thermal - Properties / Compressibility
    electricalResistivity: number; // Thermal - Properties / Electrical_Resistivity
    emissivity: number; // Thermal - Properties / Emissivity
    ep?: number;
    itemNumber: string; // Identity - Descriptive / Artikelnummer (G-TIN)
    itemTag: string; // Identity - Descriptive / Artikel-tag
    kMenge?: number;
    liPrice: number; // Identity - Descriptive / Li.-Preis
    link: string; // Identity - Product / URL
    manufacturer: string; // Identity - Product / Manufacturer
    model: string; // Identity - Product / Model
    permeability: number; // Thermal - Properties / Permeability.
    physicalBehavior: PhysicalBehavior;
    physicalDensity: number; // Physical - Mechanical / Density_Physical
    physicalDescription: string; // Physical - Information / Description_Physical
    physicalSource: string; // Physical - Information / Source_Physical
    physicalSourceLink: string; // Physical - Information / Source_URL_Physical
    poissonsRatioX: number; // Physical - Mechanical / Poissons_Ratio_X
    poissonsRatioY: number; // Physical - Mechanical / Poissons_Ratio_Y
    poissonsRatioZ: number; // Physical - Mechanical / Poissons_Ratio_Z
    porosity: number; // Thermal - Properties / Porosity
    price: number; // Identity - Product / Cost
    productID: string; // Identity - Descriptive / Produkt_ID
    reflectivity: number; // Thermal - Properties / Reflectivity
    shearModulusX: number; // Physical - Mechanical / Shear_Modulus_X
    shearModulusY: number; // Physical - Mechanical / Shear_Modulus_Y
    shearModulusZ: number; // Physical - Mechanical / Shear_Modulus_Z
    specificHeat: number; // Thermal - Properties / Specific_Heat
    structuralAssetClass: StructuralAssetClass;
    subclass: string; // Physical - Information / Subclass
    surcharge: number; // Identity - Descriptive / Zuschlag - in percent
    tensileStrength: number; // Physical - Strength / Tensile_Strength
    thermalConductivity: number; // Thermal - Properties / Thermal_Conductivity
    thermalExpansionCoefficientX: number; // Physical - Thermal / Thermal_Expansion_Coeficient_X
    thermalExpansionCoefficientY: number; // Physical - Thermal / Thermal_Expansion_Coeficient_Y
    thermalExpansionCoefficientZ: number; // Physical - Thermal / Thermal_Expansion_Coeficient_Z
    thermalMaterialType: ThermalMaterialType;
    thermicalBehavior: ThermicalBehavior;
    thermicalDensity: number; // Thermal - Properties / Density_Thermical
    thermicalDescription: string; // Thermal - Information / Description_Thermical
    thermicalSource: string; // Thermal - Information / Source_Thermical
    thermicalSourceLink: string; // Thermal - Information / Source_URL_Thermical
    transmitsLight: boolean; // Thermal - Properties / Transmits_light
    yieldStrength: number; // Physical - Strength / Tensile_Strength
    youngsModulusX: number; // Physical - Mechanical / Youngs_Modulus_X
    youngsModulusY: number; // Physical - Mechanical / Youngs_Modulus_Y
    youngsModulusZ: number; // Physical - Mechanical / Youngs_Modulus_Z
    referenceUnitID?: number;
    referenceUnit?: AttributeType;
}

export enum PhysicalBehavior {
    ISOTROPIC = "isotropic",
    UNKNOWN = "unknown",
}
export enum StructuralAssetClass {
    MAUERWERK = "mauerwerk",
    UNKNOWN = "unknown",
}
export enum ThermalMaterialType {
    SOLID = "solid",
    UNKNOWN = "unknown",
}
export enum ThermicalBehavior {
    ISOTROPIC = "isotropic",
    UNKNOWN = "unknown",
}

/**
 * Material catalogue
 */
export interface IMappingMaterial extends IMappingMaterialSchema {
    materialID: number; // Material identifier
    createdBy?: number;
    createdDate?: string;
    modifiedBy?: number;
    modifiedDate?: string;
}

export interface IMappingMaterialAddRequest extends IMappingMaterialSchema {}
export interface IMappingMaterialUpdateRequest extends IMappingMaterialSchema {}
