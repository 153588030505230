import { Box, Popover } from "@material-ui/core";
import React from "react";

import useStyles from "./styles";

interface IMappingDetailSummaryTooltipProps {
    value: string;
    children?: React.ReactNode;
    title?: string
}

const MappingDetailSummaryValue: React.FC<IMappingDetailSummaryTooltipProps> = ({ value, children, title}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <Box
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
            <span className={classes.value}>{value}</span>
        </Box>
        {children &&
          <Popover
            id={id}
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box className={classes.popoverInner}>
              {title && <Box className={classes.stronger}>{title}</Box>}
              {children}
            </Box>
          </Popover>
        }
      </>
    );
};

export default MappingDetailSummaryValue;
