import { useSelector } from "react-redux";
import {
    selectActiveViewables,
    selectFileId,
    selectFileType,
    selectForgeId, selectForgeToken,
    selectIsolateIds,
    selectIssuables,
    selectIssues,
    selectMarkupsData,
    selectMarkupsExternalHandling,
    selectModelById,
    selectModels,
    selectModelVariantIds,
    selectObjectsInContext,
    selectObjectsInContextSmart,
    selectObjectsInContextViewerIds,
    selectPreferredViewableGuid,
    selectPrintables,
    selectUrn,
    selectViewable,
    selectViewer,
} from "./selector";

export const useFileId = () => useSelector(selectFileId);
export const useFileType = () => useSelector(selectFileType);
export const useForgeId = () => useSelector(selectForgeId);
export const useIsolateIds = () => useSelector(selectIsolateIds);
export const useObjectsInContext = () => useSelector(selectObjectsInContext);
export const useObjectsInContextSmart = () => useSelector(selectObjectsInContextSmart);
export const useObjectsInContextViewerIds = () => useSelector(selectObjectsInContextViewerIds);
export const useUrn = () => useSelector(selectUrn);
export const useViewer = () => useSelector(selectViewer);
export const useIssues = () => useSelector(selectIssues);
export const useIssuables = () => useSelector(selectIssuables);
export const usePrintables = () => useSelector(selectPrintables);

export const useModelsById = (modelId: number) => useSelector(selectModelById(modelId));
export const useModels = () => useSelector(selectModels);
export const useModelVariantIds = () => useSelector(selectModelVariantIds);
export const usePreferredViewableGuid = () => useSelector(selectPreferredViewableGuid);
export const useViewable = () => useSelector(selectViewable);
export const useMarkupsExternalHandling = (): boolean => useSelector(selectMarkupsExternalHandling);
export const useMarkupsData = () => useSelector(selectMarkupsData);

export const useForgeToken = () => useSelector(selectForgeToken);
export const useActiveViewables = () => useSelector(selectActiveViewables);
