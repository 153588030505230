import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            margin: 0,
            padding: 0,
            overflow: "hidden",
            width: "100%",
            height: "100%",
        },
        iframe: {
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
    })
);

export default useStyles;
