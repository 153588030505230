import { defineMessages } from "react-intl";

export default defineMessages({
    dropZoneMessage: {
        id: "components.dropZone.message",
        defaultMessage: "Drag the file you want to upload here.",
    },
    dropZoneButton: {
        id: "components.dropZone.button",
        defaultMessage: "Choose a file",
    },
    dropZoneUploadStarted: {
        id: "components.dropZone.uploadStarter",
        defaultMessage: "Upload started",
    },
    dropZoneUploadReady: {
        id: "components.dropZone.uploadReady",
        defaultMessage: "Upload ready",
    },
    dropZoneUploadError: {
        id: "components.dropZone.uploadError",
        defaultMessage: "Upload error",
    },
});
