import { Box, CircularProgress, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import theme from "../../../theme/Theme";
import Icon from "../../Icon/Icon";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IDocumentOfflineProps {
    item: Data;
}

const DocumentOffline: React.FC<IDocumentOfflineProps> = ({ item }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [offline, setOffline] = useState<boolean>(false);

    const handleClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOffline(true);
        }, 2000);
    };

    // translations
    const intl = useIntl();
    const transTooltipAvailableOffline = intl.formatMessage({ ...messages.tooltipAvailableOffline });
    const transTooltipDownloadForOffline = intl.formatMessage({ ...messages.tooltipDownloadForOffline });

    return (
        <Box className={classes.box}>
            {loading ? (
                <CircularProgress size={18} className={classes.circularProgress} />
            ) : (
                <NullableTooltip title={offline ? transTooltipAvailableOffline : transTooltipDownloadForOffline} placement="top">
                    <IconButton aria-label="offline" onClick={handleClick}>
                        <Icon
                            name={offline ? "check" : "cloud-download-alt"}
                            size={18}
                            fill={offline ? theme.palette.success.dark : theme.palette.text.secondary}
                        />
                    </IconButton>
                </NullableTooltip>
            )}
        </Box>
    );
};

export default DocumentOffline;
