import { defineMessages } from "react-intl";

const messages = defineMessages({
    entityDeletedMessage: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.entityDeletedMessage",
        defaultMessage: "Position was successfully deleted.",
    },
    entityHasDependencies: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.entityHasDependencies",
        defaultMessage: "Position was not deleted, because some dependencies were found.",
    },
    entityHasDependenciesInfo: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.entityHasDependenciesInfo",
        defaultMessage: "Please contact system administrator to delete this position.",
    },
    entityNotCustom: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.entityNotCustom",
        defaultMessage: "Position was not deleted, because the position is not custom.",
    },
    entityNotFound: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.entityNotFound",
        defaultMessage: "Position was not deleted, because this position does not exist.",
    },
    deletionResult: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.deletionResult",
        defaultMessage: "Delete positions result",
    },
    closeDialog: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.closeDialog",
        defaultMessage: "Close",
    },
    requestIdentifier: {
        id: "components.mapping.dialogs.masterDataDeleteResponseDialog.requestIdentifier",
        defaultMessage: "Request identifier",
    },
});

export default messages;
