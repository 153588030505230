import React from "react";
import Icon from "../../components/Icon/Icon";
import Page from "../Page/Page";

const IconsPage: React.FC = () => {
    return (
        <Page>
            <>
                <ul style={{ listStyle: "none", marginBottom: "6rem" }}>
                    <li>
                        <h3>Icons list:</h3>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>arrow-alt-to-bottom</span>
                        <Icon name="arrow-alt-to-bottom" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>arrow-from-left</span>
                        <Icon name="arrow-from-left" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>blade</span>
                        <Icon name="blade" fill="#000" size={40} />
                        <Icon name="blade-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>calendar</span>
                        <Icon name="calendar" fill="#000" size={40} />
                        <Icon name="calendar-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>camera</span>
                        <Icon name="camera" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>chart-pie</span>
                        <Icon name="chart-pie" fill="#000" size={40} />
                        <Icon name="chart-pie-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>chart</span>
                        <Icon name="chart" fill="#000" size={40} />
                        <Icon name="chart-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>check</span>
                        <Icon name="check" fill="#000" size={40} />
                        <Icon name="check-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>chevron-left</span>
                        <Icon name="chevron-left" fill="#000" size={40} />
                        <Icon name="chevron-left-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>chevron-left-circle</span>
                        <Icon name="chevron-left-circle" fill="#000" size={40} />
                        <Icon name="chevron-right-circle" fill="#000" size={40} />
                        <Icon name="chevron-up-circle" fill="#000" size={40} />
                        <Icon name="chevron-down-circle" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>chevron-light-left</span>
                        <Icon name="chevron-light-left" fill="#000" size={40} />
                        <Icon name="chevron-light-right" fill="#000" size={40} />
                        <Icon name="chevron-light-up" fill="#000" size={40} />
                        <Icon name="chevron-light-down" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>chevron-left</span>
                        <Icon name="chevron-left" fill="#000" size={40} />
                        <Icon name="chevron-left-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>clock</span>
                        <Icon name="clock" fill="#000" size={40} />
                        <Icon name="clock-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>clone</span>
                        <Icon name="clone" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>cloud-upload-alt</span>
                        <Icon name="cloud-upload-alt" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>close</span>
                        <Icon name="close" fill="#000" size={40} />
                        <Icon name="close-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>cloud-download</span>
                        <Icon name="cloud-download" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>cloud-download-alt</span>
                        <Icon name="cloud-download-alt" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>cog</span>
                        <Icon name="cog" fill="#000" size={40} />
                        <Icon name="cog-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>comment</span>
                        <Icon name="comment" fill="#000" size={40} />
                        <Icon name="comment-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>comment-edit</span>
                        <Icon name="comment-edit" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>construction</span>
                        <Icon name="construction" fill="#000" size={40} />
                        <Icon name="construction-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>copy</span>
                        <Icon name="copy" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>cube</span>
                        <Icon name="cube" fill="#000" size={40} />
                        <Icon name="cube-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>dollar</span>
                        <Icon name="dollar" fill="#000" size={40} />
                        <Icon name="dollar-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ellipsis-h</span>
                        <Icon name="ellipsis-h-alt" fill="#000" size={40} />
                        <Icon name="ellipsis-h" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ellipsis-v</span>
                        <Icon name="ellipsis-v-alt" fill="#000" size={40} />
                        <Icon name="ellipsis-v" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>exclamation-triangle</span>
                        <Icon name="exclamation-triangle" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>eye</span>
                        <Icon name="eye" fill="#000" size={40} />
                        <Icon name="eye-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file</span>
                        <Icon name="file" fill="#000" size={40} />
                        <Icon name="file-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-archive</span>
                        <Icon name="file-archive" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-csv</span>
                        <Icon name="file-csv" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-excel</span>
                        <Icon name="file-excel" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-image</span>
                        <Icon name="file-image" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-music</span>
                        <Icon name="file-music" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-pdf</span>
                        <Icon name="file-pdf" fill="#000" size={40} />
                        <Icon name="file-pdf-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-powerpoint</span>
                        <Icon name="file-powerpoint" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-upload</span>
                        <Icon name="file-upload" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-video</span>
                        <Icon name="file-video" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-word</span>
                        <Icon name="file-word" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>file-x</span>
                        <Icon name="file-x" fill="#000" size={40} />
                        <Icon name="file-x-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>folder-plus</span>
                        <Icon name="folder-plus" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>folder-open</span>
                        <Icon name="folder-open" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>gaeb</span>
                        <Icon name="gaeb" fill="#000" size={40} />
                        <Icon name="gaeb-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>grip-horizontal</span>
                        <Icon name="grip-horizontal" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>grip-lines</span>
                        <Icon name="grip-lines" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>hand</span>
                        <Icon name="hand" fill="#000" size={40} />
                        <Icon name="hand-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>history</span>
                        <Icon name="history" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>info</span>
                        <Icon name="info" fill="#000" size={40} />
                        <Icon name="info-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>list</span>
                        <Icon name="list" fill="#000" size={40} />
                        <Icon name="list-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>list-border</span>
                        <Icon name="list-border" fill="#000" size={40} />
                        <Icon name="list-border-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>lock</span>
                        <Icon name="lock" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>maze</span>
                        <Icon name="maze" fill="#000" size={40} />
                        <Icon name="maze-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>minus</span>
                        <Icon name="minus" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>no-way</span>
                        <Icon name="no-way" fill="#000" size={40} />
                        <Icon name="no-way-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>pencil-solid</span>
                        <Icon name="pencil-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>people</span>
                        <Icon name="people" fill="#000" size={40} />
                        <Icon name="people-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>cloud-download</span>
                        <Icon name="cloud-download" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>person</span>
                        <Icon name="person" fill="#000" size={40} />
                        <Icon name="person-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>plus</span>
                        <Icon name="plus" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>plus-circle</span>
                        <Icon name="plus-circle" fill="#000" size={40} />
                        <Icon name="plus-circle-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>print</span>
                        <Icon name="print" fill="#000" size={40} />
                        <Icon name="print-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>rfi</span>
                        <Icon name="rfi" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ruler</span>
                        <Icon name="ruler" fill="#000" size={40} />
                        <Icon name="ruler-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>share-alt</span>
                        <Icon name="share-alt" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>sign-out-alt</span>
                        <Icon name="sign-out-alt" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>splitview-2d</span>
                        <Icon name="splitview-2d" fill="#000" size={40} />
                        <Icon name="splitview-both" fill="#000" size={40} />
                        <Icon name="splitview-3d" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>target</span>
                        <Icon name="target" fill="#000" size={40} />
                        <Icon name="target-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>times</span>
                        <Icon name="times" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>trash-alt</span>
                        <Icon name="trash-alt" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>triangle-left</span>
                        <Icon name="triangle-left" fill="#000" size={40} />
                        <Icon name="triangle-right" fill="#000" size={40} />
                        <Icon name="triangle-up" fill="#000" size={40} />
                        <Icon name="triangle-down" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>triangle-left-solid</span>
                        <Icon name="triangle-left-solid" fill="#000" size={40} />
                        <Icon name="triangle-right-solid" fill="#000" size={40} />
                        <Icon name="triangle-up-solid" fill="#000" size={40} />
                        <Icon name="triangle-down-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>version</span>
                        <Icon name="version" fill="#000" size={40} />
                        <Icon name="version-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>weight</span>
                        <Icon name="weight" fill="#000" size={40} />
                        <Icon name="weight-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>window-restore</span>
                        <Icon name="window-restore" fill="#000" size={40} />
                        <Icon name="window-restore-solid" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-planning</span>
                        <Icon name="ebkph-planning" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-additional</span>
                        <Icon name="ebkph-additional" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-construction</span>
                        <Icon name="ebkph-construction" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-environment</span>
                        <Icon name="ebkph-environment" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-equipment</span>
                        <Icon name="ebkph-equipment" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-expansion</span>
                        <Icon name="ebkph-expansion" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-exterior</span>
                        <Icon name="ebkph-exterior" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-preparation</span>
                        <Icon name="ebkph-preparation" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-property</span>
                        <Icon name="ebkph-property" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-reserve</span>
                        <Icon name="ebkph-reserve" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-roofing</span>
                        <Icon name="ebkph-roofing" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-specific</span>
                        <Icon name="ebkph-specific" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-tax</span>
                        <Icon name="ebkph-tax" fill="#000" size={40} />
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block", width: "150px" }}>ebkph-technology</span>
                        <Icon name="ebkph-technology" fill="#000" size={40} />
                    </li>
                </ul>
            </>
        </Page>
    );
};

export default IconsPage;
