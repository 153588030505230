import { Box, Typography } from "@material-ui/core";
import "date-fns";
import React from "react";
import { useIntl } from "react-intl";
import { Issue } from "../../../graphql/generated/graphql";
import { useIssuesList } from "../../../redux/issue";
import messages from "./messages";
import useIssuesStyles from "../IssuesWrapper/styles";
import Status from "../Status/Status";

interface IListIssueProps {
    handleIssueEdit: (issue: Issue) => void;
}

const ListIssue: React.FC<IListIssueProps> = ({ handleIssueEdit }) => {
    const classes = useIssuesStyles();
    const issues = useIssuesList();

    const handleClick = (issue: Issue) => {
        handleIssueEdit(issue);
    };

    // translations
    const intl = useIntl();
    const transAssignedTo = intl.formatMessage({ ...messages.assignedTo });
    const transLocation = intl.formatMessage({ ...messages.location });
    const transUnspecified = intl.formatMessage({ ...messages.unspecified });
    const transNotFound = intl.formatMessage({ ...messages.notFound });

    return (
        <Box>
            {issues && issues.length ? (
                <>
                    {issues?.map((issue: Issue, index: number) => {
                        const dueDate = new Date(issue?.dueDate);

                        return (
                            <Box key={index} className={classes.boxIssue} onClick={() => handleClick(issue)}>
                                <Box className={classes.boxStatus}>
                                    <Status issueStatus={issue?.issueStatus} />
                                    <p>
                                        {issue?.dueDate !== null ? (
                                            <span className={classes.highlight}>
                                                {dueDate.toLocaleDateString("en")}
                                            </span>
                                        ) : (
                                            <>{transUnspecified}</>
                                        )}
                                    </p>
                                </Box>
                                <Box>
                                    <Typography variant="h2">
                                        <strong>#{issue?.code}</strong> - {issue?.name}
                                    </Typography>
                                    <p>
                                        {transAssignedTo}
                                        {transUnspecified}
                                        {/* {issue.assignTo ? ( */}
                                        {/*     issueAssignTo?.[issue.assignTo]?.name */}
                                        {/* ) : ( */}
                                        {/*     <>Unspezifiziert</> */}
                                        {/* ) // Unspecified */}
                                        {/* } */}
                                    </p>
                                    <p>
                                        {transLocation}
                                        {issue?.locationDescription ? issue?.locationDescription : transUnspecified}
                                    </p>
                                </Box>
                            </Box>
                        );
                    })}
                </>
            ) : (
                <Box className={classes.noIssue}>
                    <Typography paragraph variant="body2">
                        {transNotFound}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ListIssue;
