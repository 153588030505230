import React from "react";
import useStyles from "./styles";
import { Box, Popover } from "@material-ui/core";
import clsx from "clsx";

interface IMappingDetailSummaryConditionValueProps {
  pset: string|null;
  attribute: string|null;
  operator: string|null;
  value: string|null;
}

const MappingDetailSummaryConditionValue: React.FC<IMappingDetailSummaryConditionValueProps> = ({pset,attribute,operator,value}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let operatorShort: string;
  let operatorLong: string;
  let isText: boolean = false;

  switch (operator) {
    case 'Contains':
      operatorShort = 'Cont.';
      operatorLong = 'Contains';
      isText = true;
      break;
    case 'Equals':
      operatorShort = '=';
      operatorLong = 'Equals';
      break;
    case 'IsDefined':
      operatorShort = 'Is def.';
      operatorLong = '';
      isText = true;
      break;
    case 'IsGreaterThan':
      operatorShort = '>';
      operatorLong = 'Is greater than';
      break;
    case 'IsGreaterThanOrEquals':
      operatorShort = '> =';
      operatorLong = 'Is greater than or equals';
      break;
    case 'IsLessThan':
      operatorShort = '<';
      operatorLong = 'Is less than';
      break;
    case 'IsLessThanOrEquals':
      operatorShort = '< =';
      operatorLong = 'Is less than or equals';
      break;
    case 'StartsWith':
      operatorShort = 'Starts.';
      operatorLong = 'Starts with';
      isText = true;
      break;
    default:
      operatorShort = '';
      operatorLong = '';
  }

  return (
    <>
      <Box className={classes.inline}>
        <Box
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.wrapper}
        >
          <Box className={classes.attributePsetWrapper}>
            <Box className={classes.attribute}>{attribute}</Box>
            <Box className={classes.pset}>{pset}</Box>
          </Box>
          <Box>
            <Box className={clsx(classes.operator, isText? classes.operatorText : null)}>{operatorShort}</Box>
          </Box>
          <Box className={classes.value}>{value}</Box>
        </Box>
      </Box>

      <Popover
          id={id}
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
      >
          <Box className={classes.popoverInner}>
            <Box className={classes.wrapperTooltip}>
              <Box>
                <Box className={classes.attribute}>{attribute}</Box>
                <Box className={classes.pset}>{pset}</Box>
              </Box>
              <Box>
                <Box className={clsx(classes.operator, classes.operatorText)}>{operatorLong}</Box>
              </Box>
              <Box className={classes.value}>{value}</Box>
            </Box>
          </Box>
      </Popover>
    </>
  )
}

export default MappingDetailSummaryConditionValue
