import { defineMessages } from "react-intl";

export default defineMessages({
    material: {
        id: "components.mapping.stepWork.material",
        defaultMessage: "Material",
    },
    materialPhysical: {
        id: "components.mapping.stepWork.materialPhysical",
        defaultMessage: "Physical material ",
    },
    materialAdditional: {
        id: "components.mapping.stepWork.materialAdditional",
        defaultMessage: "Additional material ",
    },
    work: {
        id: "components.mapping.stepWork.work",
        defaultMessage: "Work",
    },
    equipment: {
        id: "components.mapping.stepWork.equipment",
        defaultMessage: "Equipment",
    },
    unitPriceTypeCalculated: {
        id: "components.mapping.stepWork.unitPriceTypeCalculated",
        defaultMessage: "Calculated price",
    },
    unitPriceTypeSubcontractor: {
        id: "components.mapping.stepWork.unitPriceTypeSubcontractor",
        defaultMessage: "Subcontractor price",
    },
});
