import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    PaperProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import Draggable from "react-draggable";
import useStyles from "./styles";
import { IDraggableData, IPosition } from "../type";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IDialogPriceProps {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
}

function PaperComponent(props: PaperProps) {
    const [position, setPosition] = useState<IPosition>({
        x: 0,
        y: 0,
    });
    const handleDraggableEvent = (e: any, data: IDraggableData) => {
        setPosition({
            x: data.x,
            y: data.y,
        });
    };
    return (
        <Draggable
            handle="#price-dialog-title"
            cancel='[class*="MuiDialogContent-root"]'
            position={position}
            onStop={handleDraggableEvent}
            key="draggabdle-outer"
        >
            <Paper {...props} key="draggabdle-paper" />
        </Draggable>
    );
}

const DialogPrice: React.FC<IDialogPriceProps> = ({ open, handleClose }) => {
    const classes = useStyles();
    const [listData, setListData] = useState<any>({
        code: "G_C 2.1.003.100",
        name: "Mauerwerk, Planziegel, d=36,5 cm",
        quantity: 56, // Menge
        unitPrice: 257.485, // Einheitpreis
        surcharge: 0, // Zuschlag [%]
        totalPrice: 0, // Gasamtpreis
        profit: 13.5,
    });

    const round = (num) => {
        return Math.round(num * 100) / 100;
    };
    const getPrice = (data) => {
        return round(data.quantity * data.unitPrice);
    };
    const getTotal = (data) => {
        return round(data.quantity * data.unitPrice * (1 + data.profit / 100));
    };

    const handleChange = (name) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setListData({ ...listData, [name]: event.target.value });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="price-dialog-title"
            aria-describedby="price-dialog-description"
            PaperComponent={PaperComponent}
            maxWidth="md"
            fullWidth
            hideBackdrop
        >
            <DialogTitle id="price-dialog-title" className={classes.title}>
                Detailansicht
            </DialogTitle>
            <DialogContent>
                <IconButton aria-label="close" className={classes.closeButton} size="small" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>eBKP-H</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Menge</TableCell>
                                <TableCell align="left" className={classes.columnPrice}>
                                    Einheitspreis
                                </TableCell>
                                <TableCell align="left" className={classes.columnPrice}>
                                    Preis
                                </TableCell>
                                <TableCell align="left" className={classes.columnProfit}>
                                    Zuschlag
                                </TableCell>
                                <TableCell align="left" className={classes.columnPrice}>
                                    Gesamtpreis
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={1}>
                                <TableCell component="th" scope="row">
                                    {listData.code}
                                </TableCell>
                                <TableCell align="left">{listData.name}</TableCell>
                                <TableCell align="left">{listData.quantity}</TableCell>
                                <TableCell key="price-cell" align="left" className={classes.columnPrice}>
                                    <NullableTooltip title={`${listData.unitPrice} €`} aria-label="add">
                                        <Input
                                            className={classes.input}
                                            id="unitPrice"
                                            type="number"
                                            value={listData.unitPrice}
                                            fullWidth
                                            disableUnderline
                                            endAdornment=" €"
                                            readOnly
                                            // onChange={handleChange("unitPrice")}
                                        />
                                    </NullableTooltip>
                                </TableCell>
                                <TableCell align="left" className={classes.columnPrice}>
                                    <NullableTooltip title={`${getPrice(listData)} €`} aria-label="add">
                                        <Input
                                            className={classes.input}
                                            id="preis"
                                            type="number"
                                            value={getPrice(listData)}
                                            fullWidth
                                            disableUnderline
                                            endAdornment=" €"
                                            readOnly
                                        />
                                    </NullableTooltip>
                                </TableCell>
                                <TableCell key="profit-cell" align="left" className={classes.columnProfit}>
                                    <NullableTooltip title={`${listData.profit} %`} aria-label="add">
                                        <Input
                                            className={classes.input}
                                            id="profit-percent"
                                            type="number"
                                            value={listData.profit}
                                            fullWidth
                                            disableUnderline
                                            endAdornment=" %"
                                            readOnly
                                            // onChange={handleChange("profit")}
                                        />
                                    </NullableTooltip>
                                </TableCell>
                                <TableCell align="left" className={classes.columnPrice}>
                                    <NullableTooltip title={`${getTotal(listData)} €`} aria-label="add">
                                        <Input
                                            className={classes.input}
                                            id="total"
                                            type="number"
                                            value={getTotal(listData)}
                                            fullWidth
                                            disableUnderline
                                            endAdornment=" €"
                                            readOnly
                                        />
                                    </NullableTooltip>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* <Box className={classes.syncContainer}> */}
                {/*     <NullableTooltip title={"Upload to CRM"} aria-label="add"> */}
                {/*         <IconButton onClick={handleUploadToCrm} disabled={uploading || downloading}> */}
                {/*             {uploading ? <CircularProgress size={25} /> : <UploadIcon />} */}
                {/*         </IconButton> */}
                {/*     </NullableTooltip> */}
                {/*     <NullableTooltip title={"Download from CRM"} aria-label="add"> */}
                {/*         <IconButton onClick={handleDownloadFromCrm} disabled={uploading || downloading}> */}
                {/*             {downloading ? <CircularProgress size={25} /> : <DownloadIcon />} */}
                {/*         </IconButton> */}
                {/*     </NullableTooltip> */}
                {/* </Box> */}

                <Grid container spacing={0} className={classes.grid}>
                    <Grid item xs={12} sm={2}>
                        <Box className={classes.box}>
                            <Typography variant="body2">Bezugseinheit</Typography>
                            <Typography>
                                1 m<sup>3</sup>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={10} style={{ height: "100%" }}>
                        <TableContainer className={classes.table}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>eBKP-H</TableCell>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Menge</TableCell>
                                        <TableCell align="left">Einheit</TableCell>
                                        <TableCell align="left">Li.-Preis</TableCell>
                                        <TableCell align="left">Zuschlag</TableCell>
                                        <TableCell align="left">Einheitspreis</TableCell>
                                        <TableCell align="left" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={0}>
                                        <TableCell component="th" scope="row">
                                            01.02.0014
                                        </TableCell>
                                        <TableCell align="left">Lorem</TableCell>
                                        <TableCell align="left">0.25</TableCell>
                                        <TableCell align="left">m3</TableCell>
                                        <TableCell align="left">77</TableCell>
                                        <TableCell align="left">12</TableCell>
                                        <TableCell align="left">89.47</TableCell>
                                        <TableCell align="left">
                                            {/* <IconButton */}
                                            {/*     aria-label="collapse" */}
                                            {/*     size="small" */}
                                            {/*     onClick={handleOpenAnother} */}
                                            {/*     className={classes.iconButton} */}
                                            {/* > */}
                                            {/*     <EditIcon fontSize="small" /> */}
                                            {/* </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            01.02.0015
                                        </TableCell>
                                        <TableCell align="left">Lorem</TableCell>
                                        <TableCell align="left">0.25</TableCell>
                                        <TableCell align="left">St</TableCell>
                                        <TableCell align="left">19</TableCell>
                                        <TableCell align="left">30</TableCell>
                                        <TableCell align="left">53.34</TableCell>
                                        <TableCell align="left">
                                            {/* <IconButton */}
                                            {/*     aria-label="collapse" */}
                                            {/*     size="small" */}
                                            {/*     onClick={handleOpenAnother} */}
                                            {/*     className={classes.iconButton} */}
                                            {/* > */}
                                            {/*     <EditIcon fontSize="small" /> */}
                                            {/* </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogPrice;
