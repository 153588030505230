import { DispatchAction } from "@iolabs/redux-utils";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import { isEqual } from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane/lib/Pane";
import { useGetProjectQuery } from "../../graphql/generated/graphql";
import { viewerProxy } from "../../packages/Api/data/viewer/client";
import { IViewerProxyResponse } from "../../packages/Api/data/viewer/types";
import { SplitViewType } from "../../pages/ViewerPage/ViewerPage";
import { useActiveProject, useSpecialInstances } from "../../redux/project";
import { useBulkIsolations } from "../../redux/specification";
import {
    onActiveViewables,
    setObjectsToContext,
    useObjectsInContext,
    useObjectsInContextSmart,
} from "../../redux/viewer";
import { ProjectData } from "../ProjectSelectorWrapper/type";
import { PropertyHelper } from "../Viewer/helpers/propertyHelper";
import { ThemingHelper } from "../Viewer/helpers/themingHelper";
import { getMainProjectFileViewablesFromProject } from "../Viewer/utils/Project";
import Viewer, { IViewerLeaveState, IViewerProps } from "../Viewer/Viewer/Viewer";
import useStyles from "./styles";
import ProjectViewerViewSwitcher from "../ProjectViewerViewSwitcher/ProjectViewerViewSwitcher";
import { ExtensionID as CustomPropertiesExtensionID } from "../Viewer/extensions/Viewing.Extensions.CustomProperties/Viewing.Extensions.CustomPropertiesExtension";
import { getInstanceInformation } from "../../packages/Api/data/instances/client";
import { getInstanceProperties } from "../Viewer/Viewer/utils";

export enum ViewerRole {
    "3D" = "3d",
    "2D" = "2d",
}

export enum ViewableRole {
    "3D" = "3d",
    "2D" = "2d",
}

interface IProjectViewerProps {
    splitView: SplitViewType;
    hiddenControls?: boolean;
    showPushpins?: boolean;
    urn: string;
    projectId: string;
    isViewerPage?: boolean;
    isIssuePage?: boolean;
    hideMarkups?: boolean;
    hideAllIssuesBtn?: boolean;
    skipProjectLoad?: boolean;
    onStoreState?: (role: string, state: IViewerLeaveState) => void;
    statesToRestore?: { [key: string]: IViewerLeaveState | undefined };
    ref?: any; //temp hack
    disableSplitViewSwitcher?: boolean;
}

const ProjectViewer: React.FC<IProjectViewerProps> = forwardRef(
    (
        {
            splitView,
            hiddenControls,
            urn,
            projectId,
            isViewerPage,
            isIssuePage,
            hideMarkups,
            hideAllIssuesBtn,
            skipProjectLoad,
            onStoreState,
            statesToRestore,
            disableSplitViewSwitcher
        },
        ref
    ) => {
        const classes = useStyles();
        const dispatch = useDispatch<DispatchAction>();
        const { keycloak, initialized: keycloakInitialized } = useKeycloak();
        const activeProject: ProjectData | undefined = useActiveProject();
        const selectedObjects = useObjectsInContext();
        const selectedObjectsSmart = useObjectsInContextSmart();
        const bulkIsolations = useBulkIsolations();
        const specialInstances = useSpecialInstances();

        const [mapping, setMapping] = useState<any>({});
        const [selectedViewables, setSelectedViewables] = useState<{ [key: string]: any }>({});

        const [viewerState, setViewerState] = useState<any>();
        const viewerState3d = { ...viewerState };
        const viewerState2d = { ...viewerState };

        const [lastViewerState2d, setLastViewerState2d] = useState<IViewerLeaveState>();
        const [lastViewerState3d, setLastViewerState3d] = useState<IViewerLeaveState>();

        const [splitViewInternal, setSplitViewInternal] = useState<SplitViewType>(splitView);

        const isolations = useRef<any>({});
        const viewers = useRef<any>({});

        const viewer2dRef = useRef<React.FC<IViewerProps>>();
        const viewer3dRef = useRef();

        const { data, loading, error } = useGetProjectQuery({
            variables: {
                code: activeProject?.code,
            },
            skip: skipProjectLoad,
        });

        // The component instance will be extended
        // with whatever you return from the callback passed
        // as the second argument
        useImperativeHandle(ref, () => ({
            getState3d() {
                // @ts-ignore
                return viewer3dRef.current?.getState();
            },

            getState2d() {
                // @ts-ignore
                return viewer2dRef.current?.getState();
            },
        }));

        useEffect(() => {
            if (keycloak?.token) {
                viewerProxy(keycloak.token, urn, projectId, false)
                    .then((response: IViewerProxyResponse) => {
                        setViewerState({
                            api: response?.api,
                            urn: response?.urn,
                            isEmea: response?.isEmea,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }, [urn, projectId, keycloakInitialized]);

        const handleSelect = async (viewerDef: string, selection: any): Promise<void> => {
            if (selection.dbIdArray.length === 0) return;
            if (isEqual(selection?.dbIdArray?.sort(), isolations?.current[viewerDef]?.sort())) return;

            let objects: string[] = [];

            if (selection.dbIdArray && selection.dbIdArray.length > 0) {
                const mappingIds = await PropertyHelper.getMappingIds(selection.model, selection.dbIdArray);
                objects = Object.keys(mappingIds);
            }
            dispatch(setObjectsToContext({ viewerIds: selection.dbIdArray, objects: {}, objectsSmart: objects }));
        };

        const handleViewableSelected = (viewerDef: string, viewable: any): void => {
            setSelectedViewables({
                ...selectedViewables,
                [viewerDef]: viewable,
            });
        };

        useEffect(() => {
            let activeViewables: any[] = [];
            if (splitViewInternal == SplitViewType.BOTH) {
                activeViewables = [...Object.values(selectedViewables)];
            }
            else if (splitViewInternal == SplitViewType["3D"]) {
                activeViewables = [selectedViewables[ViewerRole["3D"]]];
            }
            else if (splitViewInternal == SplitViewType["2D"]) {
                activeViewables = [selectedViewables[ViewerRole["2D"]]];
            }

            dispatch(
                onActiveViewables({viewables: activeViewables})
            )

        }, [splitViewInternal, selectedViewables])

        const handleViewerLoaded = (viewerDef: any, viewer: Autodesk.Viewing.Viewer3D) => {
            viewers.current[viewerDef] = viewer;

            viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async () => {
                mapping[viewerDef] = await ThemingHelper.getIdMapping(viewer.model);
                setMapping(mapping);

                viewer
                    .loadExtension(CustomPropertiesExtensionID, {
                        onLoadProperties: (ids: string[], setProps) => {
                            getInstanceInformation(keycloak?.token as string, activeProject?.projectID as number, ids).then((resp) => {
                                let props: any[] = [];
                                resp.forEach(i => {
                                    props = props.concat(getInstanceProperties(i));
                                });
                                setProps(props);
                            })
                        }
                    });
            });
        };

        const getBulkIsolation = (viewerDef) => {
            const viewable = selectedViewables[viewerDef];
            if (!viewers.current[viewerDef] || !data || !viewable) return {};
            const viewables = getMainProjectFileViewablesFromProject(data?.projects?.[0]);
            const viewableId = viewables?.find((v) => v.name === viewable.data.name && viewable.data.role === viewerDef)
                ?.projectFileVersionViewableID as number;

            let isolations;

            if (bulkIsolations?.[viewableId]) {
                isolations = bulkIsolations[viewableId];
            }
            else if (bulkIsolations) {
                let vID = Object.keys(bulkIsolations)?.[0];
                if (vID) {
                    isolations = bulkIsolations[vID]??{};
                }
                else {
                    isolations = {};
                }
            }
            else {
                isolations = {};
            }

            if (specialInstances && specialInstances.instancesByViewablesType[viewableId]) {
                Object.keys(specialInstances.instancesByViewablesType[viewableId]).forEach(k => {
                    isolations[k] = {
                        viewerIds: specialInstances.instancesByViewablesType[viewableId][k].map(v => parseInt(v)),
                        title: k
                    };
                })
            }

            return isolations
        };

        const applySelection = (viewerDef, selection, selectionSmart): number[] => {
            if (selectionSmart && selectionSmart.length > 0) {
                if (!viewers.current[viewerDef] || !data || !selection || selectionSmart.length < 1) return [];
                let forgeIds: number[] = [];
                if (mapping[viewerDef]) {
                    selectionSmart.forEach((key) => {
                        forgeIds = forgeIds.concat(mapping[viewerDef][key]);
                    });
                }
                return forgeIds;
            }

            const viewable = selectedViewables[viewerDef];
            if (!viewers.current[viewerDef] || !data || !viewable || !selection || selection.length < 1) return [];
            const viewables = getMainProjectFileViewablesFromProject(data?.projects?.[0]);
            const viewableId = viewables?.find((v) => v.name === viewable.data.name && viewable.data.role === viewerDef)
                ?.projectFileVersionViewableID as number;
            return selection[viewableId];
        };

        isolations.current = {
            [ViewerRole["2D"]]: applySelection(ViewerRole["2D"], selectedObjects, selectedObjectsSmart)?.filter((v, i, a) => a.indexOf(v) === i),
            [ViewerRole["3D"]]: applySelection(ViewerRole["3D"], selectedObjects, selectedObjectsSmart)?.filter((v, i, a) => a.indexOf(v) === i),
        };

        viewerState2d.isolateIds = isolations.current[ViewerRole["2D"]];
        viewerState3d.isolateIds = isolations.current[ViewerRole["3D"]];

        viewerState2d.bulkIsolations = getBulkIsolation(ViewerRole["2D"]);
        viewerState3d.bulkIsolations = getBulkIsolation(ViewerRole["3D"]);

        return (
            <Box
                className={clsx(classes.root, {
                    [classes.resizer]: splitViewInternal !== SplitViewType.BOTH,
                })}
            >
                {(loading || error) && (
                    <Box className={classes.skeletonBox}>
                        <Skeleton variant="rect" className={classes.skeleton} />
                    </Box>
                )}
                {!disableSplitViewSwitcher && (
                    <Box
                        className={clsx(classes.viewSwitcher, {
                            [classes.viewSwitcherPinnedToTop]: true,
                        })}
                    >
                        <ProjectViewerViewSwitcher
                            isViewer2D={splitViewInternal === SplitViewType["2D"]}
                            isViewer3D={splitViewInternal === SplitViewType["3D"]}
                            onSet2D={() => {
                                setSplitViewInternal(SplitViewType["2D"])
                            }}
                            onSet3D={() => {
                                setSplitViewInternal(SplitViewType["3D"])
                            }}
                            onSplitView={() => {
                                setSplitViewInternal(SplitViewType.BOTH)
                            }}
                        />
                    </Box>
                )}

                {(data || skipProjectLoad) && (
                    <SplitPane split="vertical">
                        {(splitViewInternal === SplitViewType["3D"] || splitViewInternal === SplitViewType.BOTH) && (
                            <Pane
                                initialSize={splitViewInternal === SplitViewType["3D"] ? "100%" : "50%"}
                                minSize={splitViewInternal === SplitViewType["3D"] ? "100%" : "10%"}
                                maxSize={splitViewInternal === SplitViewType["3D"] ? "100%" : "90%"}
                            >
                                <Viewer
                                    ref={viewer3dRef}
                                    key="3d-viewer"
                                    viewerState={viewerState3d}
                                    role={ViewerRole["3D"]}
                                    hiddenControls={hiddenControls}
                                    onSelect={(selection) => handleSelect(ViewerRole["3D"], selection)}
                                    onViewableSelected={(selection) =>
                                        handleViewableSelected(ViewerRole["3D"], selection)
                                    }
                                    onViewerLoaded={(loadedViewer) =>
                                        handleViewerLoaded(ViewerRole["3D"], loadedViewer)
                                    }
                                    isViewerPage={isViewerPage}
                                    isIssuePage={isIssuePage}
                                    hideMarkups={hideMarkups}
                                    hideAllIssuesBtn={hideAllIssuesBtn}
                                    stateToRestore={lastViewerState3d ? lastViewerState3d : statesToRestore?.["3D"]}
                                    onStoreState={(state) => {
                                        if (onStoreState) {
                                            onStoreState("3D", state);
                                        }
                                        setLastViewerState3d(state);
                                    }}
                                />
                            </Pane>
                        )}
                        {(splitViewInternal === SplitViewType["2D"] ||
                            splitViewInternal === SplitViewType.BOTH ||
                            splitViewInternal === SplitViewType.UNIVERSAL) && (
                            <Pane
                                initialSize={
                                    splitViewInternal === SplitViewType["2D"] || splitViewInternal === SplitViewType.UNIVERSAL
                                        ? "100%"
                                        : "50%"
                                }
                                minSize={
                                    splitViewInternal === SplitViewType["2D"] || splitViewInternal === SplitViewType.UNIVERSAL
                                        ? "100%"
                                        : "10%"
                                }
                                maxSize={
                                    splitViewInternal === SplitViewType["2D"] || splitViewInternal === SplitViewType.UNIVERSAL
                                        ? "100%"
                                        : "90%"
                                }
                            >
                                <Viewer
                                    ref={viewer2dRef}
                                    key="2d-viewer"
                                    viewerState={viewerState2d}
                                    role={splitViewInternal === SplitViewType.UNIVERSAL ? undefined : ViewerRole["2D"]}
                                    hiddenControls={hiddenControls}
                                    onSelect={(selection) => handleSelect(ViewerRole["2D"], selection)}
                                    onViewableSelected={(selection) =>
                                        handleViewableSelected(ViewerRole["2D"], selection)
                                    }
                                    onViewerLoaded={(loadedViewer) =>
                                        handleViewerLoaded(ViewerRole["2D"], loadedViewer)
                                    }
                                    isViewerPage={isViewerPage}
                                    isIssuePage={isIssuePage}
                                    hideMarkups={hideMarkups}
                                    hideAllIssuesBtn={hideAllIssuesBtn}
                                    stateToRestore={lastViewerState2d ? lastViewerState2d : statesToRestore?.["2D"]}
                                    onStoreState={(state) => {
                                        if (onStoreState) {
                                            onStoreState("2D", state);
                                        }
                                        setLastViewerState2d(state);
                                    }}
                                    viewSelectorPosition={(isViewerPage || isIssuePage) && splitViewInternal === SplitViewType.BOTH ? {left: "55px"} : {}}
                                />
                            </Pane>
                        )}
                    </SplitPane>
                )}
            </Box>
        );
    }
);

export default ProjectViewer;
