import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { IDocumentView } from "../../components/DocumentView/type";

export enum ActionTypes {
    OnSplitView = "layout/ON_SPLIT_VIEW",
    OnDrawer = "layout/ON_DRAWER",
    OnDocumentView = "layout/ON_DOCUMENT_VIEW",
}

export type SplitViewOptions = {
    splitView: boolean;
};

export type DrawerOptions = {
    drawer: boolean;
};

export type DocumentViewOptions = {
    documentView: IDocumentView;
};

const Actions = {
    onSplitView: (options: SplitViewOptions) => createAction(ActionTypes.OnSplitView, options),
    onDrawer: (options: DrawerOptions) => createAction(ActionTypes.OnDrawer, options),
    onDocumentView: (options: DocumentViewOptions) => createAction(ActionTypes.OnDocumentView, options),
};

export function onSplitView(options: SplitViewOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onSplitView(options));
    };
}

export function onDrawer(options: DrawerOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDrawer(options));
    };
}

export function onDocumentView(options: DocumentViewOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDocumentView(options));
    };
}
