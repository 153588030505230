import { useSelector } from "react-redux";
import { SpecificationView } from "../../components/Specification/type";
import {
    selectBulkIsolations,
    selectExpandedRows,
    selectExterns,
    selectIsDefault,
    selectIsExternOpen,
    selectIsExterns,
    selectIsTable,
    selectIsTableViewer2D,
    selectIsViewer2D,
    selectIsViewer3D,
    selectIsViewer3DTable,
    selectIsViewer3DViewer2D,
    selectIsViewers,
    selectSelectedRows,
    selectViews,
} from "./selector";
import { BulkIsolations } from "./reducer";

// using ony inside function component
export const useSpecificationViews = (): SpecificationView[] => useSelector(selectViews);
export const useSpecificationExterns = (): SpecificationView[] => useSelector(selectExterns);
export const useBulkIsolations = (): BulkIsolations | undefined => useSelector(selectBulkIsolations);

export const useExpandedRows = (): string[] => useSelector(selectExpandedRows);
export const useSelectedRows = (): string[] => useSelector(selectSelectedRows);

export const useSpecificationViewIsDefault = (): boolean => useSelector(selectIsDefault);
export const useSpecificationViewIsExterns = (): boolean => useSelector(selectIsExterns);
export const useSpecificationViewIsExternOpen = (): boolean => useSelector(selectIsExternOpen);
export const useSpecificationViewIsTable = (): boolean => useSelector(selectIsTable);
export const useSpecificationViewIsViewer2D = (): boolean => useSelector(selectIsViewer2D);
export const useSpecificationViewIsViewer3D = (): boolean => useSelector(selectIsViewer3D);
export const useSpecificationViewIsViewer3DViewer2D = (): boolean => useSelector(selectIsViewer3DViewer2D);
export const useSpecificationViewIsViewer3DTable = (): boolean => useSelector(selectIsViewer3DTable);
export const useSpecificationViewIsTableViewer2D = (): boolean => useSelector(selectIsTableViewer2D);
export const useSpecificationViewIsViewers = (): boolean => useSelector(selectIsViewers);
