import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
        },
        addButton: {
            width: "20px",
            height: "20px",
            margin: theme.spacing(0.5, 0),
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey["500"],
        },
        dialogContentRoot: {
            padding: theme.spacing(0, 1),
            height: "70vh",
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(0, 2),
            },
            [theme.breakpoints.up("lg")]: {
                height: "80vh",
            },
        },
        dialogActionsRoot: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(2, 2.5),
            "& button": {
                marginLeft: `${theme.spacing(2)} !important`,
            },
        },
        exportImport: {
            marginLeft: "auto!important",
        },
        button: {
            backgroundColor: theme.palette.success.main,
            color: "white",
        }
    })
);

export default useStyles;
