import { defineMessages } from "react-intl";

export default defineMessages({
    title: {
        id: "components.mapping.stepElementGraphics.title",
        defaultMessage: "Graphics",
    },
    background: {
        id: "components.mapping.stepElementGraphics.background",
        defaultMessage: "Background",
    },
    foreground: {
        id: "components.mapping.stepElementGraphics.foreground",
        defaultMessage: "Foreground",
    },
    cut: {
        id: "components.mapping.stepElementGraphics.cut",
        defaultMessage: "Cut",
    },
    surface: {
        id: "components.mapping.stepElementGraphics.surface",
        defaultMessage: "Surface",
    },
    pattern: {
        id: "components.mapping.stepElementGraphics.pattern",
        defaultMessage: "Pattern",
    },
    color: {
        id: "components.mapping.stepElementGraphics.color",
        defaultMessage: "Color",
    },
    noGraphics: {
        id: "components.mapping.stepElementGraphics.noGraphics",
        defaultMessage: "No graphics attached, select from catalogue using the cog icon.",
    },
});
