import { Box, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import clsx from "clsx";
import { IInstancePropertySet, PropertyHelper } from "../../Viewer/helpers/propertyHelper";

export interface IMappingDetailInstanceProperties {
    instancesIDs?: number[];
    psets?: IInstancePropertySet[]
}

interface TabPanelProps {
    index: number;
    value: number;
    classes: any;
    children?: React.ReactNode;
}



function TabPanel(props: TabPanelProps) {
    const { value, index, classes, children, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            className={classes.tabPanel}
            {...other}
        >
            {value === index && <Box className={classes.tabPanelBox}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const panelContent = (pset: IInstancePropertySet) => (
    <>
        {pset.properties.sort((a, b) => `${PropertyHelper.getName(a)}`.localeCompare(`${PropertyHelper.getName(b)}`))?.map((property, index) => (
            <dl key={`property-${index}`}>
                <dt>{PropertyHelper.getName(property)}</dt>
                <dd>{PropertyHelper.getValue(property)}</dd>
            </dl>
        ))}
    </>
);


const MappingDetailInstanceProperties: React.FC<IMappingDetailInstanceProperties> = ({ instancesIDs, psets }) => {
    const classes = useStyles();

    const [tab, setTab] = useState<number>(0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newTab: number) => {
        setTab(newTab);
    };

    useEffect(() => {
        setTab(0);
    }, [psets]);

    return (
        <Paper className={clsx(classes.paper, classes.paperTabs)}>
            {instancesIDs?.length !== 1 ? (
                <Box className={classes.boxStep1}>
                    <Typography variant="body2" style={{ fontWeight: 400 }}>
                        Definieren Sie bitte den Element
                    </Typography>
                    <Typography variant="body2" style={{ marginTop: "1rem" }}>
                        {instancesIDs && instancesIDs.length > 1 ? "Please select only 1 element" : "Please select an element"}
                    </Typography>
                </Box>
            ) : (
                <>
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        classes={{
                            root: classes.tabsRoot,
                            indicator: classes.tabsIndicator,
                        }}
                    >
                        {psets?.sort((a, b) => a.name.localeCompare(b.name)).map( (pset, i) => (
                            <Tab
                                label={pset.name}
                                disableRipple
                                key={`pset-${i}`}
                                {...a11yProps(i)}
                                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            />
                            ))
                        }
                    </Tabs>
                    {psets?.map( (pset, i) => (
                        <TabPanel
                            key={`pset-${i}`}
                            value={tab}
                            index={i}
                            classes={classes}
                        >
                            {panelContent(pset)}
                        </TabPanel>
                    ))
                    }
                </>
            )}
        </Paper>
    );
};

export default MappingDetailInstanceProperties;
