import {
    ClickAwayListener,
    Divider,
    IconButton,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import React from "react";
import Icon from "../../../Icon/Icon";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IRowSubMenuProps {
    isAddMode: boolean;
    isUpdateMode: boolean;
    disabledRevert: boolean;
    row: any;
    rowID: number;
    handleAdd: (row: any) => void;
    handleUpdate: (rowID: number) => void;
    handleRevert: (rowID: number) => void;
    handleDiscardAdd: (rowID: number) => void;
    handleToggleUpdateMode: (rowID: number) => void;
    handleDelete: (row: any) => void;
}

const RowSubMenu: React.FC<IRowSubMenuProps> = ({
    isAddMode,
    isUpdateMode,
    disabledRevert,
    row,
    rowID,
    handleAdd,
    handleUpdate,
    handleRevert,
    handleDiscardAdd,
    handleToggleUpdateMode,
    handleDelete,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? "row-sub-menu-popover" : undefined;

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    // translations
    const intl = useIntl();
    const transDelete = intl.formatMessage({ ...messages.delete });
    const transRename = intl.formatMessage({ ...messages.rename });
    const transUpdate = intl.formatMessage({ ...messages.update });
    const transDiscard = intl.formatMessage({ ...messages.discard });
    const transClose = intl.formatMessage({ ...messages.close });
    const transRevert = intl.formatMessage({ ...messages.revert });
    const transAddNew = intl.formatMessage({ ...messages.addNew });
    const transSaveChanges = intl.formatMessage({ ...messages.saveChanges });

    return (
        <>
            <IconButton
                aria-describedby={id}
                aria-haspopup="true"
                aria-label="row sub menu"
                color="primary"
                className={classes.iconButton}
                onClick={handleOpen}
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="row-sub-menu">
                            {isUpdateMode ? (
                                <>
                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            isAddMode ? handleAdd(row) : handleUpdate(rowID);
                                            handleClose(event);
                                        }}
                                    >
                                        <ListItemIcon className={clsx(classes.itemIcon, classes.itemIconSave)}>
                                            <Icon name="check" size={18} />
                                        </ListItemIcon>
                                        <Typography variant="inherit">
                                            {
                                                isAddMode
                                                    ? transAddNew
                                                    : transSaveChanges
                                            }
                                        </Typography>
                                    </MenuItem>
                                    {!isAddMode && (
                                        <MenuItem
                                            onClick={(event) => {
                                                handleRevert(rowID);
                                                handleClose(event);
                                            }}
                                            disabled={disabledRevert}
                                        >
                                            <ListItemIcon className={clsx(classes.itemIcon, classes.itemIconRevert)}>
                                                <Icon name="history" size={18} />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                {transRevert}
                                            </Typography>
                                        </MenuItem>
                                    )}

                                    <MenuItem
                                        onClick={(event) => {
                                            isAddMode ? handleDiscardAdd(rowID) : handleToggleUpdateMode(rowID);
                                            handleClose(event);
                                        }}
                                    >
                                        <ListItemIcon className={clsx(classes.itemIcon, classes.itemIconClose)}>
                                            <Icon name="close" size={18} />
                                        </ListItemIcon>
                                        <Typography variant="inherit">
                                            {
                                                isAddMode
                                                    ? transDiscard
                                                    : transClose
                                            }
                                        </Typography>
                                    </MenuItem>
                                </>
                            ) : (
                                <MenuItem
                                    onClick={(event) => {
                                        handleToggleUpdateMode(rowID);
                                        handleClose(event);
                                    }}
                                >
                                    <ListItemIcon className={classes.itemIcon}>
                                        <Icon name="pencil-solid" size={18} />
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        {transUpdate}
                                    </Typography>
                                </MenuItem>
                            )}
                            {!isAddMode && (
                                <>
                                    <Divider />
                                    <MenuItem
                                        onClick={(event) => {
                                            handleDelete(row);
                                            handleClose(event);
                                        }}
                                    >
                                        <ListItemIcon className={clsx(classes.itemIcon, classes.itemIconClose)}>
                                            <Icon name="trash-alt" size={18} />
                                        </ListItemIcon>
                                        <Typography variant="inherit">
                                            {transDelete}
                                        </Typography>
                                    </MenuItem>
                                </>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </>
    );
};

export default RowSubMenu;
