import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { IViewerModuleState, reducer } from "./reducer";

export const ViewerModule: IModule<IViewerModuleState> = {
    id: "viewer-module",
    reducerMap: {
        viewerState: reducer,
    },
    middlewares: middlewares,
};
