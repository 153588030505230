import { defineMessages } from "react-intl";

export default defineMessages({
    unspecified: {
        id: "components.listIssue.unspecified",
        defaultMessage: "Unspecified", // Unspezifiziert
    },
    assignedTo: {
        id: "components.listIssue.assignedTo",
        defaultMessage: "Assigned to:", // Zuweisen zu:
    },
    location: {
        id: "components.listIssue.location",
        defaultMessage: "Location:", // Standort:
    },
    notFound: {
        id: "components.listIssue.notFound",
        defaultMessage: "No issues found", // Keine Probleme gefunden
    },
});
