import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ApolloError } from "apollo-client";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    AttributeType,
    CategoryReport,
    CostsPerCategoriesQuery,
    ElementCategoryType,
    Maybe,
} from "../../../graphql/generated/graphql";
import Formatter, { normalizeUnit } from "../../../utils/Formatter";
import Icon from "../../Icon/Icon";
import { IIcon } from "../../Icon/IconSet";
import { rowCodeIconsMap } from "../type";
import messages from "./messages";
import useStyles from "./styles";
import { onExpandedRows, useExpandedRows } from "../../../redux/specification";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IDataTableProps {
    data: CostsPerCategoriesQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    ids: string[];
    isExpanded: (id: string) => boolean;
    getRowId: (row: CategoryReport) => string;
    handleSelect: (row: CategoryReport) => void;
    handleExpand: (value: string) => void;
    handleSelectAndExpand: (row: CategoryReport) => void;
    handleOpenPriceDialog: () => void;
    handleClosePriceDialog: () => void;
    fullHeight: boolean;
}

const DataTable: React.FC<IDataTableProps> = ({
    data,
    loading,
    error,
    ids,
    isExpanded,
    getRowId,
    handleSelect,
    handleExpand,
    handleSelectAndExpand,
    handleOpenPriceDialog,
    handleClosePriceDialog,
    fullHeight,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    const [scrollActive, setScrollActive] = useState<boolean>(false);

    const expandedRows: string[] = useExpandedRows();
    const [scrollToCategory, setScrollToCategory] = useState<CategoryReport|undefined>(undefined);
    const scrollToRef = useRef(null);

    const handleScroll = () => {
        if (!scrollActive) {
            setScrollActive(true);
            setTimeout(() => {
                setScrollActive(false);
            }, 1000);
        }
    };

    // const renderSymbol = (symbol: ISymbol | ISymbol[] | null) => {
    //     if (Array.isArray(symbol)) {
    //         let render;
    //         return symbol.map((item) => {
    //             return [
    //                 render,
    //                 <span
    //                     className={clsx(classes.symbol, {
    //                         [classes.symbolSuccess]: item === ISymbol.Success,
    //                         [classes.symbolWarning]: item === ISymbol.Warning,
    //                         [classes.symbolError]: item === ISymbol.Error,
    //                     })}
    //                 />,
    //             ];
    //         });
    //     } else {
    //         return (
    //             symbol && (
    //                 <span
    //                     className={clsx(classes.symbol, {
    //                         [classes.symbolSuccess]: symbol === ISymbol.Success,
    //                         [classes.symbolWarning]: symbol === ISymbol.Warning,
    //                         [classes.symbolError]: symbol === ISymbol.Error,
    //                     })}
    //                 />
    //             )
    //         );
    //     }
    // };

    const renderIcon = (code: string) => {
        if (rowCodeIconsMap.has(code)) {
            return (
                <span className={classes.icon}>
                    <Icon name={rowCodeIconsMap.get(code) as IIcon} size={16} />
                </span>
            );
        }
    };

    // translations
    const intl = useIntl();
    const transEbkph = intl.formatMessage({ ...messages.ebkph });
    const transNumber = intl.formatMessage({ ...messages.number });
    const transName = intl.formatMessage({ ...messages.name });
    const transDescriptionShort = intl.formatMessage({ ...messages.descriptionShort });
    const transSource = intl.formatMessage({ ...messages.source });
    const transQuantity = intl.formatMessage({ ...messages.quantity });
    const transUnit = intl.formatMessage({ ...messages.unit });
    const transUnitPrice = intl.formatMessage({ ...messages.unitPrice });
    const transSurcharge = intl.formatMessage({ ...messages.surcharge });
    const transTotal = intl.formatMessage({ ...messages.total });
    const transDescription = intl.formatMessage({ ...messages.description });
    const transGewerk = intl.formatMessage({ ...messages.gewerk });
    const transSurchargePercentage = intl.formatMessage({ ...messages.surchargePercentage });
    const transElementCategoryTypeModel = intl.formatMessage({ ...messages.elementCategoryTypeModel });
    const transElementCategoryTypeManual = intl.formatMessage({ ...messages.elementCategoryTypeManual });
    const transElementCategoryTypeDerived = intl.formatMessage({ ...messages.elementCategoryTypeDerived });

    useEffect(() => {
        if (ids && ids.length > 0 && scrollToRef) {
            // expand all to scrolled
            // find path to first
            const pathToFirst = getFirstSelectedNodePath(1, data?.costsPerCategories?.categories as CategoryReport[]);
            if (pathToFirst?.length == 5) {
                const expanded = [...expandedRows, ...pathToFirst.map(cr => getRowId(cr))];
                dispatch(onExpandedRows({ expandedRows: [...new Set(expanded)] }));
                setScrollToCategory(pathToFirst[pathToFirst.length - 1])
            }
        }
    }, [ids]);

    useEffect(() => {
        if (scrollToCategory && scrollToRef?.current) {
            // @ts-ignore
            scrollToRef?.current?.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
        }
    }, [scrollToCategory]);

    const getFirstSelectedNodePath = (level: number, categories: CategoryReport[]): CategoryReport[] => {
        let path: CategoryReport[] = [];
        categories?.some(c => {
            if (level == 5 && ids?.includes(getRowId(c))) {
                path = [c];
                return true;
            }
            else if (level < 5 && c.children) {
                const childPath = getFirstSelectedNodePath(level+1, c.children as CategoryReport[]);
                if (childPath?.length > 0) {
                    path = [c, ...childPath];
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        });
        return path;

    }

    const getQuelle = (row: CategoryReport) => {
        switch (row?.elementCategory?.type) {
            case ElementCategoryType.MANUAL:
                return transElementCategoryTypeManual;
            case ElementCategoryType.MODEL:
                return transElementCategoryTypeModel;
            case ElementCategoryType.DERIVED:
                return transElementCategoryTypeDerived;
            default:
                return null;
        }
    }

    const renderRow = (
        row: CategoryReport,
        level: number,
        index: number,
        length: number,
        priceAttributeType: AttributeType | null,
        isOpen?: boolean
    ) => {
        const rowId = getRowId(row);

        let additionalRowProps: any = {};
        if (level == 4 && scrollToCategory == row) {
            additionalRowProps.ref = scrollToRef;
        }

        return (
            <React.Fragment key={rowId}>
                {level === 0 && index === 0 && (
                    <TableRow className={classes.separator}>
                        <TableCell width={2} />
                        <TableCell colSpan={14} className={classes.shadowBottom} />
                        <TableCell width={2} />
                    </TableRow>
                )}
                {level === 0 && index !== 0 && (
                    <TableRow className={classes.separator}>
                        <TableCell width={2} />
                        <TableCell colSpan={14} className={classes.shadowTopBottom} />
                        <TableCell width={2} />
                    </TableRow>
                )}
                {level === 1 && index === 0 && (
                    <TableRow className={classes.tableRowHeader}>
                        <TableCell className={classes.shadowLeft} />
                        <TableCell colSpan={2} />
                        <TableCell component="th" align="left">
                            {transEbkph}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transNumber}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transName}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transDescriptionShort}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transSource}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transQuantity}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transUnit}
                        </TableCell>
                        <TableCell />
                        <TableCell component="th" align="left">
                            {transUnitPrice}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transSurcharge}
                        </TableCell>
                        <TableCell component="th" align="left">
                            {transTotal}
                        </TableCell>
                        <TableCell />
                        <TableCell className={classes.shadowRight} />
                    </TableRow>
                )}
                <TableRow
                    key={rowId}
                    className={clsx(classes.tableRow, {
                        [classes.expandedRow]: isOpen,
                        [classes.firstRow]: !isOpen,
                        [classes.firstLevel]: level === 0,
                    })}
                    selected={ids?.includes(rowId)}
                    classes={{ selected: classes.selected }}
                    {...additionalRowProps}
                >
                    <TableCell className={classes.shadowLeft} />
                    {level === 0 ? (
                        <>
                            <TableCell className={classes.cellSymbol}>
                                {/* {renderSymbol(row.data.symbol)} */}
                            </TableCell>
                            <TableCell className={classes.cellIcon}>
                                {level === 0 && renderIcon(row?.elementCategory?.code as string)}
                            </TableCell>
                            <TableCell colSpan={2} onClick={() => handleSelectAndExpand(row)}>
                                <span className={classes.code}>{row?.elementCategory?.code}</span>
                                <br />
                                <span className={classes.codeName}>{row?.elementCategory?.name}</span>
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                align="left"
                                className={classes.cellDesc}
                                onClick={() => handleSelectAndExpand(row)}
                            >
                                {/* Beschreibung: <br /> */}
                                <NullableTooltip title={row?.elementCategory?.description as string}>
                                    <span>{row?.elementCategory?.description}</span>
                                </NullableTooltip>
                            </TableCell>
                            <TableCell colSpan={3} />
                            <TableCell align="left" onClick={() => handleSelectAndExpand(row)}>
                                {/* Gewerke */}
                                {row?.workNo}
                            </TableCell>
                            <TableCell align="left" />
                            <TableCell align="left" onClick={() => handleSelectAndExpand(row)}>
                                {/* Zuschlag */}
                                <Formatter
                                    value={row?.extraCharge}
                                    attributeType={priceAttributeType as AttributeType}
                                    disableUnit
                                />
                            </TableCell>
                            <TableCell align="left" onClick={() => handleSelectAndExpand(row)}>
                                {/* Gesamt */}
                                <Formatter
                                    value={row?.totalPrice}
                                    attributeType={priceAttributeType as AttributeType}
                                />
                            </TableCell>
                        </>
                    ) : (
                        <>
                            <TableCell className={classes.cellSymbol}>
                                {/* {renderSymbol(row.data.symbol)} */}
                            </TableCell>
                            <TableCell />
                            <TableCell
                                align="left"
                                style={{ paddingLeft: theme.spacing(2 * (level - 1)) }}
                                onClick={() => handleSelect(row)}
                            >
                                {row?.elementCategory?.code}
                            </TableCell>
                            <TableCell align="left" onClick={() => handleSelect(row)}>
                                {/* P. Nummer: */}
                            </TableCell>
                            <TableCell align="left" onClick={() => handleSelect(row)}>
                                {/* Name: <br /> */}
                                {row?.elementCategory?.name}
                            </TableCell>
                            <TableCell align="left" onClick={() => handleSelect(row)}>
                                {/* Kurzbeschreibung: <br /> */}
                            </TableCell>
                            {row?.children?.length === 0 ? (
                                <TableCell align="left" onClick={() => handleSelect(row)}>
                                    {/* Quelle: <br /> */}
                                    {getQuelle(row)}
                                </TableCell>
                            ) : (
                                <TableCell align="left" onClick={() => handleSelect(row)} />
                            )}

                            {row?.children?.length === 0 ? (
                                <TableCell align="left" onClick={() => handleSelect(row)}>
                                    {/* Menge: <br /> */}
                                    <Formatter
                                        value={row?.quantity}
                                        attributeType={row.attributeType as AttributeType}
                                        disableUnit
                                    />
                                </TableCell>
                            ) : (
                                <TableCell align="left" onClick={() => handleSelect(row)} />
                            )}
                            {row?.children?.length === 0 ? (
                                <TableCell align="left" onClick={() => handleSelect(row)}>
                                    {/* Einheit: <br /> */}
                                    {normalizeUnit(row.attributeType?.unit as string)}
                                </TableCell>
                            ) : (
                                <TableCell align="left" onClick={() => handleSelect(row)} />
                            )}
                            <TableCell align="left" onClick={() => handleSelect(row)}>
                                {/* Gewerke */}
                                {row?.workNo}
                            </TableCell>
                            {row?.children?.length === 0 ? (
                                <TableCell align="left" onClick={handleOpenPriceDialog}>
                                    {/* Einheitpreis */}
                                    <Formatter
                                        value={row?.unitPrice}
                                        attributeType={priceAttributeType as AttributeType}
                                    />
                                    <IconButton
                                        aria-label="collapse"
                                        size="small"
                                        className={classes.iconButton}
                                        onClick={handleOpenPriceDialog}
                                    >
                                        <Icon name="pencil-solid" size={16} />
                                    </IconButton>
                                </TableCell>
                            ) : (
                                <TableCell align="left" onClick={() => handleSelect(row)} />
                            )}
                            <TableCell align="left" onClick={() => handleSelect(row)}>
                                {/* Zuschlag */}
                                <Formatter
                                    value={row?.extraCharge ? row?.extraCharge * 100 : 0}
                                    attributeType={priceAttributeType as AttributeType}
                                    disableUnit
                                />
                            </TableCell>
                            <TableCell align="left" onClick={() => handleSelect(row)}>
                                {/* Gesamt */}
                                <Formatter
                                    value={row?.totalPrice}
                                    attributeType={priceAttributeType as AttributeType}
                                />
                            </TableCell>
                        </>
                    )}
                    <TableCell>
                        <div className={classes.expandedButton}>
                            {row.children && row.children.length > 0 && (
                                <IconButton
                                    aria-label="collapse"
                                    size="small"
                                    onClick={() =>
                                        handleExpand((row?.elementCategory?.elementCategoryID as number).toString())
                                    }
                                >
                                    {isExpanded((row?.elementCategory?.elementCategoryID as number).toString()) ? (
                                        <Icon name="chevron-light-down" size={16} />
                                    ) : (
                                        <Icon name="chevron-light-right" size={16} />
                                    )}
                                </IconButton>
                            )}
                        </div>
                    </TableCell>
                    <TableCell className={classes.shadowRight} />
                </TableRow>
                {level === 0 && index === length - 1 && (
                    <TableRow className={classes.separator}>
                        <TableCell width={2} />
                        <TableCell colSpan={14} className={classes.shadowTop} />
                        <TableCell width={2} />
                    </TableRow>
                )}
                {isExpanded((row?.elementCategory?.elementCategoryID as number).toString()) &&
                row.children &&
                row.children.length > 0
                    ? row.children.map((row: Maybe<CategoryReport>, index: number) =>
                          renderRow(
                              row as CategoryReport,
                              level + 1,
                              index,
                              row?.children?.length as number,
                              priceAttributeType,
                              true
                          )
                      )
                    : null}
            </React.Fragment>
        );
    };

    scrollToRef.current = null;

    return (
        <TableContainer
            className={clsx(classes.tableContainer, {
                [classes.fullHeight]: fullHeight,
                [classes.scrollActive]: scrollActive,
            })}
            onScroll={handleScroll}
        >
            {loading || error ? (
                <Box className={classes.skeletonBox}>
                    <Skeleton variant="rect" height={40} />
                    <Skeleton variant="rect" height={66} />
                    <Skeleton variant="rect" height={66} />
                    <Skeleton variant="rect" height={66} />
                </Box>
            ) : (
                <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow classes={{ selected: classes.selected }}>
                            <TableCell align="left" />
                            <TableCell align="left">
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left" colSpan={3}>
                                {transEbkph}
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left" colSpan={2} className={classes.cellDesc}>
                                {transDescription}
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left" colSpan={3}>
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left">
                                {transGewerk}
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left">
                                {transUnitPrice}
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left">
                                {transSurchargePercentage}
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left">
                                {transTotal}
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left">
                                <span className={classes.tableHeadShadow} />
                            </TableCell>
                            <TableCell align="left" />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {data?.costsPerCategories?.categories?.map((costRow, index: number) =>
                            renderRow(
                                costRow as CategoryReport,
                                0,
                                index,
                                data?.costsPerCategories?.categories?.length as number,
                                data?.costsPerCategories?.priceAttributeType as AttributeType
                            )
                        )}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
};

export default DataTable;
