import { defineMessages } from "react-intl";

export default defineMessages({
    export: {
        id: "components.mapping.dialogs.workDialogWrapper.export",
        defaultMessage: "Export work",
    },
    import: {
        id: "components.mapping.dialogs.workDialogWrapper.import",
        defaultMessage: "Import work",
    },
    workCatalog: {
        id: "components.mapping.dialogs.workDialogWrapper.workCatalog",
        defaultMessage: "Work Catalogue",
    },
    createNewWork: {
        id: "components.mapping.dialogs.workDialogWrapper.createNewWork",
        defaultMessage: "Create new work",
    },
    buttonPartWork1: {
        id: "components.mapping.dialogs.workDialogWrapper.buttonPartWork1",
        defaultMessage: "Work",
    },
    buttonPartWork2: {
        id: "components.mapping.dialogs.workDialogWrapper.buttonPartWork2",
        defaultMessage: "Work",
    },
    workToThePosition: {
        id: "components.mapping.dialogs.workDialogWrapper.workToThePosition",
        defaultMessage: "work to the position"
    }
});
