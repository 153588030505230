import useStyles from "./styles";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const OutlinedComponent = ({ children, label, className}) => {
    const classes = useStyles();
    return (
        <TextField
            variant="outlined"
            label={label}
            InputLabelProps={{ shrink: true }}
            fullWidth
            color="primary"
            margin="normal"
            className={clsx(classes.textField, className)}
            InputProps={{
                inputComponent: InputComponent
            } as any}
            inputProps={{
                children: children,
                readOnly: true,
            }}
        />
    );
};
export default OutlinedComponent;
