import { defineMessages } from "react-intl";

export default defineMessages({
    exportStarted: {
        id: "components.exporterimporter.exportStarted",
        defaultMessage: "Export started",
    },
    dropFile: {
        id: "components.exporterimporter.dropFile",
        defaultMessage: "Drop file to import",
    },
    chooseFile: {
        id: "components.exporterimporter.chooseFile",
        defaultMessage: "Choose file to import",
    },
    uploadStarted: {
        id: "components.exporterimporter.uploadStarted",
        defaultMessage: "Upload has started",
    },
    uploadFinished: {
        id: "components.exporterimporter.uploadFinished",
        defaultMessage: "Upload has ended",
    },
    singleFileOnly: {
        id: "components.exporterimporter.singleFileOnly",
        defaultMessage: "Upload exactly one file.",
    },
});
