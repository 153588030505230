import { useSelector } from "react-redux";
import { IIssueTab } from "../../components/Issues/type";
import { Issue } from "../../graphql/generated/graphql";
import { IIssueCreateState, IIssueViewer } from "./reducer";
import {
    selectFromViewerPage,
    selectIsIssueVisible,
    selectIssueCreate,
    selectIssueEdit,
    selectIssuesList,
    selectIssueTab,
    selectIssueViewer,
} from "./selector";

export const useIssuesList = (): Issue[] => useSelector(selectIssuesList);
export const useIssueCreate = (): IIssueCreateState => useSelector(selectIssueCreate);
export const useIssueEdit = (): Issue|undefined => useSelector(selectIssueEdit);
export const useIssueTab = (): IIssueTab => useSelector(selectIssueTab);
export const useIssueViewer = (): IIssueViewer => useSelector(selectIssueViewer);
export const useIsIssueVisible = (): boolean => useSelector(selectIsIssueVisible);
export const useIsIssueFromViewerPage = (): boolean => useSelector(selectFromViewerPage);
