import { defineMessages } from "react-intl";

const messages = defineMessages({
    mappingDetail: {
        id: "components.mapping.listItemPopover.mappingDetail",
        defaultMessage: "Show mapping details",
    },
    deleteCustomPosition: {
        id: "components.mapping.listItemPopover.deleteCustomPosition",
        defaultMessage: "Delete custom position", // Benutzerdefinierte Position löschen
    },
    moveCustomPosition: {
        id: "components.mapping.listItemPopover.moveCustomPosition",
        defaultMessage: "Move custom position",
    },
    moveCustomPositionTooltip: {
        id: "components.mapping.listItemPopover.moveCustomPositionTooltip",
        defaultMessage: "Move custom position to different parent",
    },
    moveCustomPositionTooltipDisabled: {
        id: "components.mapping.listItemPopover.moveCustomPositionTooltipDisabled",
        defaultMessage: "Move custom position is not permitted, the position is not custom.",
    },
    deletePosition: {
        id: "components.mapping.listItemPopover.deletePosition",
        defaultMessage: "Delete position", // Position löschen
    },
    editCustomPosition: {
        id: "components.mapping.listItemPopover.editCustomPosition",
        defaultMessage: "Edit custom position", // Benutzerdefinierte Position bearbeiten
    },
    editPosition: {
        id: "components.mapping.listItemPopover.editPosition",
        defaultMessage: "Edit position", // Position bearbeiten
    },
    operationNotPermitted: {
        id: "components.mapping.listItemPopover.operationNotPermitted",
        defaultMessage: "Operation not permitted, position is not custom",
    },
    mappingNotPossible: {
        id: "components.mapping.listItemPopover.mappingNotPossible",
        defaultMessage: "Position is not instantiable, mapping not possible",
    },

    // confirm dialog
    confirmDialogTitle: {
        id: "components.mapping.listItemPopover.confirmDialog.title",
        defaultMessage: "Delete", // Löschen
    },
    confirmDialogMessage: {
        id: "components.mapping.listItemPopover.confirmDialog.message",
        defaultMessage: "Are you sure you want to delete this position", // Sind Sie sicher, dass Sie diese Position löschen möchten
    },
    confirmDialogClose: {
        id: "components.mapping.listItemPopover.confirmDialog.close",
        defaultMessage: "Close", // Abbrechen
    },
    confirmDialogDelete: {
        id: "components.mapping.listItemPopover.confirmDialog.delete",
        defaultMessage: "Delete", // Löschen
    },
});

export default messages;
