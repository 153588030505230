import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tab,
    Tabs,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useIssueViewer } from "../../../redux/issue";
import { IIssueViewer } from "../../../redux/issue/reducer";
import { ViewableRole, ViewerRole } from "../../ProjectViewer/ProjectViewer";
import useStyles from "./styles";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

export interface IViewSelectorPosition {
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;
}

interface IViewSelector extends React.HTMLAttributes<HTMLElement> {
    viewables: any[];
    viewable: any;
    onSelectViewable: (viewable: Autodesk.Viewing.BubbleNode) => void;
    getViewableName: (viewable: Autodesk.Viewing.BubbleNode) => string;
    role?: ViewerRole;
    isIssuePage?: boolean;
    viewSelectorPosition?: IViewSelectorPosition
}


const ViewSelector: React.FC<IViewSelector> = ({
    viewable,
    viewables,
    onSelectViewable,
    getViewableName,
    role,
    isIssuePage,
    viewSelectorPosition,
}) => {
    const classes = useStyles();
    const issueViewer: IIssueViewer = useIssueViewer();
    const [activeRole, setActiveRole] = useState<any>(undefined);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [roles, setRoles] = useState<any>();
    const [viewablesByRole, setViewablesByRole] = useState<any>({});
    const [enteredMenu, setEnteredMenu] = useState<boolean>(false);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveRole(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setActiveRole(index);
    };

    const handleSelectViewable = (viewable: any) => {
        setAnchorEl(null);
        onSelectViewable(viewable);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setEnteredMenu(false);
    };

    const handleEnterMenu = () => {
        setEnteredMenu(true);
    };

    const handleExitMenu = () => {
        setEnteredMenu(false);
    };

    const tabAriaProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    };
    const getViewableRole = (viewable) => {
        return viewable.data ? viewable.data.role : viewable.role;
    };
    const getViewableGuid = (viewable) => {
        return viewable.data ? viewable.data.guid : viewable.guid;
    };

    useEffect(() => {
        const tempViewablesByRole = {};
        viewables?.forEach((viewable) => {
            const role = getViewableRole(viewable);
            if (!tempViewablesByRole[role]) {
                tempViewablesByRole[role] = [];
            }
            tempViewablesByRole[role].push(viewable);
        });

        const roleKeys = role ? [role] : Object?.keys(tempViewablesByRole)?.sort();

        // sort viewables
        roleKeys.forEach((role) => {
            tempViewablesByRole[role] = tempViewablesByRole[role]?.sort(
                (v1: Autodesk.Viewing.BubbleNode, v2: Autodesk.Viewing.BubbleNode) => {
                    return getViewableName(v1) < getViewableName(v2) ? -1 : 1;
                }
            );
        });

        setViewablesByRole(tempViewablesByRole);
        setRoles(roleKeys);
    }, [viewables, activeRole, role]);

    useEffect(() => {
        if (viewable && roles) {
            setTabIndex(activeRole !== null ? activeRole : roles?.indexOf(getViewableRole(viewable)));
        }
    }, [viewable, roles]);

    const getDefaultViewable = (role) => {
        const defaultViewable = viewables[0].doc.docRoot.getDefaultGeometry();
        if (!role || getViewableRole(defaultViewable) === role) {
            return defaultViewable;
        } else if (role === ViewableRole["2D"]) {
            return viewables.find(
                (v) => v?.data?.role === ViewableRole["2D"] && v?.data?.name?.includes("Grundriss EG")
            );
        } else {
            return null;
        }
    };

    // reload initial viewable after split view change
    useEffect(() => {
        if ((!viewable || getViewableRole(viewable) != role) && role && viewablesByRole) {
            if (viewablesByRole[role]) {
                let defaultViewable = getDefaultViewable(role);
                // sorting here again - because it is not sorted and I dont know why wtf
                defaultViewable = defaultViewable
                    ? defaultViewable
                    : viewablesByRole[role]?.sort(
                          (v1: Autodesk.Viewing.BubbleNode, v2: Autodesk.Viewing.BubbleNode) => {
                              return getViewableName(v1) < getViewableName(v2) ? -1 : 1;
                          }
                      )[0];
                if (isIssuePage && issueViewer?.viewable) {
                    const filteredViewable = viewablesByRole[role].filter(
                        (v) => v?.data?.name === issueViewer?.viewable?.data?.name
                    );
                    onSelectViewable(filteredViewable ? filteredViewable[0] : defaultViewable);
                } else {
                    onSelectViewable(defaultViewable);
                }
            }
        } else if (viewable && getViewableRole(viewable) == role) {
            onSelectViewable(viewable);
        }
    }, [role, viewablesByRole]);

    const getCurrentTabIndex = () => {
        return activeRole !== undefined ? activeRole : roles?.indexOf(getViewableRole(viewable));
    };

    function getViewablesMenu() {
        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={getCurrentTabIndex()}
                        // value={enteredMenu ? tabIndex : false}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="viewer tabs"
                        centered
                        className={classes.tabs}
                    >
                        {roles?.map((role: string, index: number) => {
                            return (
                                <Tab
                                    key={`role-${role}`}
                                    label={role}
                                    {...tabAriaProps(index)}
                                    className={classes.tab}
                                />
                            );
                        })}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis="x"
                    index={getCurrentTabIndex()}
                    onChangeIndex={handleChangeIndex}
                    className={classes.swipeableViews}
                >
                    {roles?.map((role: string, index?: number) => {
                        return (
                            <TabPanel key={`role-${role}`} value={getCurrentTabIndex()} index={index} dir="x">
                                <List component="nav">
                                    {viewablesByRole &&
                                        viewablesByRole?.[role]?.map((view) => {
                                            return (
                                                <ListItem
                                                    key={`role-${getViewableGuid(view)}`}
                                                    button
                                                    selected={view === viewable}
                                                    onClick={() => handleSelectViewable(view)}
                                                    className={classes.listItem}
                                                >
                                                    <ListItemText
                                                        primary={getViewableName(view)}
                                                        className={classes.listItemText}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </TabPanel>
                        );
                    })}
                </SwipeableViews>
            </div>
        );
    }
    const getCustomStyle = () => {
        const style: any = {...viewSelectorPosition};

        return style;
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                className={classes.layerButton}
                endIcon={<ExpandMoreIcon />}
                style={getCustomStyle()}
            >
                {viewable ? (
                    getViewableName(viewable)
                ) : (
                    <CircularProgress size={16} className={classes.circularProgress} />
                )}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                TransitionProps={{
                    onEntered: handleEnterMenu,
                    onExit: handleExitMenu
                }}
            >
                {viewables?.length > 0 && getViewablesMenu()}
            </Popover>
        </>
    );
};

export default ViewSelector;
