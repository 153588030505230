import {
    IMappingElementField,
    IMappingElements,
    MappingElementCombinator,
} from "../../../packages/Api/data/mapping/types";
import { IQuery, IQueryCombinator, IQueryField } from "../ElementQueryBuilder/type";

/**
 * Convert query to mapping elements
 * @param query
 */
export const getElements = (query: IQuery): IMappingElements => {
    return {
        combinator: (query?.combinator as unknown) as MappingElementCombinator,
        rules: query?.rules?.map((rule) => {
            if ("combinator" in rule) {
                return (getElements(rule) as unknown) as IMappingElements;
            } else {
                return ({
                    pset: rule?.field?.pset,
                    attribute: rule?.field?.attribute,
                    filterOperation: rule?.operator,
                    value: rule?.value,
                } as unknown) as IMappingElementField;
            }
        }),
    };
};

/**
 * Convert mapping elements to query
 * @param elements
 * @param newPsets
 * @param newAttributes
 */
export const getQuery = (elements: IMappingElements, newPsets: string[], newAttributes: string[]): IQuery => {
    return {
        combinator: (elements?.combinator as unknown) as IQueryCombinator,
        rules: elements?.rules?.map((rule) => {
            if ("combinator" in rule) {
                return (getQuery(rule, newPsets, newAttributes) as unknown) as IQuery;
            } else {
                if (rule?.pset && !newPsets.includes(rule?.pset)) {
                    newPsets.push(rule?.pset);
                }
                if (rule?.attribute && !newAttributes.includes(rule?.attribute)) {
                    newAttributes.push(rule?.attribute);
                }
                return ({
                    field: {
                        pset: rule?.pset,
                        attribute: rule?.attribute,
                    },
                    operator: rule?.filterOperation,
                    value: rule?.value,
                } as unknown) as IQueryField;
            }
        }),
    };
};
