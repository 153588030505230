import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Checkbox,
    CircularProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AutoSizer, List } from "react-virtualized";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { IMappingNavigation } from "../../../pages/MappingManagerPage/type";
import { setCatalogLeafPositions } from "../../../redux/mapping";
import Icon from "../../Icon/Icon";
import ListItemPopover from "../ListItemPopover/ListItemPopover";
import { ListItemPopoverType } from "../ListItemPopover/type";
import messages from "./messages";
import { useIntl } from "react-intl";

import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IProjectCatalogTree {
    checked: number[];
    handleChangeMainView: (mainView: boolean) => void;
    handleToggle: (positionID: number, isLeft: boolean) => void;
    loading: boolean;
    navigation: IMappingNavigation;
    positions: IElementsPosition[];
    catalogPositionIDs: number[];
}

const ProjectCatalogTree: React.FC<IProjectCatalogTree> = ({
    checked,
    handleChangeMainView,
    handleToggle,
    loading,
    navigation,
    positions,
    catalogPositionIDs,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transEmptyProjectCatalog = intl.formatMessage({...messages.emptyProjectCatalog})

    useEffect(() => {
        recursivePositions(positions);
    }, [positions]);

    const recursivePositions = (positions: IElementsPosition[]) => {
        // all leaf positions for future navigation (previous / next) in detail
        const leafPositions: IElementsPosition[] = positions?.filter(p => p.isInstantiable);
        dispatch(setCatalogLeafPositions({ leafPositions }));
    };

    const recursivePosition = (
        position: IElementsPosition,
        level: number,
        leafPositions: IElementsPosition[],
        isDerived: boolean = false
    ) => {
        if (renderLogic(position, isDerived) && level > 3) {
            leafPositions.push(position);
        }

        if (position?.childPositions) {
            position?.childPositions?.map((position) => recursivePosition(position, level + 1, leafPositions, false));
        }

        if (position?.derivedPositions && level > 3) {
            position?.derivedPositions?.map((position) => {
                if (renderLogic(position, true)) {
                    leafPositions.push(position);
                }
            });
        }
    };

    const renderLogic = (position: IElementsPosition, isDerived: boolean): boolean => {
        return !isDerived ? catalogPositionIDs?.includes(position?.positionID) : true;
    };

    function rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it)
    }) {
        const isDerived = (positions?.[index]?.level as number) === 5;
        return (
            <ListItem
                key={key}
                style={style}
                role="listitem"
                button
                disableRipple
                onClick={() => (!isDerived ? handleToggle(positions?.[index]?.positionID, false) : null)}
                classes={{ root: classes.listItemRoot }}
                className={isDerived ? classes.listItemDerived : ""}
            >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    {!isDerived && (
                        <Checkbox
                            checked={checked?.indexOf(positions?.[index]?.positionID) !== -1}
                            size="small"
                            color="secondary"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": positions?.[index]?.code }}
                            classes={{ root: classes.checkboxRoot }}
                        />
                    )}
                </ListItemIcon>
                <ListItemText
                    id={positions?.[index]?.code}
                    primary={
                        <div className={classes.listItemCustom}>
                            <div
                                className={classes.listItemValue}
                                style={{
                                    paddingLeft: navigation?.showHierarchy
                                        ? theme.spacing(positions?.[index]?.level as number)
                                        : "",
                                }}
                            >
                                {positions?.[index]?.code}
                            </div>
                            <div className={classes.listItemDescription}>
                                <NullableTooltip title={positions?.[index]?.description as string}>
                                    <span className={classes.positionName}>{positions?.[index]?.name}</span>
                                </NullableTooltip>
                            </div>
                            {((positions?.[index]?.level as number) > 3 || !positions?.[index].isFromEbkpCatalogue) && (
                                <div className={classes.listItemPopover}>
                                    <ListItemPopover
                                        type={ListItemPopoverType.CATALOG}
                                        position={positions?.[index]}
                                        handleChangeMainView={handleChangeMainView}
                                    />
                                </div>
                            )}
                        </div>
                    }
                />
            </ListItem>
        );
    }

    return (
        <Paper className={classes.paper} id="scrollablePaper">
            {loading && (
                <Box className={classes.loading}>
                    <CircularProgress size={36} className={classes.circularProgress} />
                </Box>
            )}

            {catalogPositionIDs?.length === 0 ? (
                <>
                    {!loading && (
                        <Box className={classes.emptyBox}>
                            <Icon name="folder-open" size={60} />
                            <Typography variant="body2">{transEmptyProjectCatalog}</Typography>
                        </Box>
                    )}
                </>
            ) : (
                <AutoSizer disableWidth>
                    {({ height }) => (
                        <List
                            width={1}
                            height={height}
                            rowCount={positions?.length}
                            rowHeight={28}
                            rowRenderer={rowRenderer}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                            }}
                            style={{
                                width: "100%",
                            }}
                            component="div"
                            role="list"
                            classes={{ root: classes.listRoot }}
                        />
                    )}
                </AutoSizer>
            )}
        </Paper>
    );
};

export default ProjectCatalogTree;
