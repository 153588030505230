import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";

const crmApi = new ApiClient("/Share", config.api.baseUrl);

export const generateSharingKey = (url: string): Promise<string> =>
    crmApi.get(`/generate?url=${encodeURIComponent(url)}`).then((response: AxiosResponse<string>) => {
        return Promise.resolve(response.data);
    });
