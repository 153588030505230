import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            zIndex: 0,
            height: "100%",

            "& .adsk-input": {
                float: "left",
                verticalAlign: "middle",
                height: "50px",
                "& input": {
                    color: "black",
                    margin: "2px",
                    height: "39px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                },
            },
        },
        box: {
            position: "relative",
            height: "100%",
        },
        skeleton: {
            height: "100%",
        },
        hiddenControls: {
            "& .adsk-viewing-viewer .adsk-toolbar": {
                display: "none !important",
            },
        },
    })
);

export default useStyles;
