import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            height: "100%",
            marginBottom: "58px",
            [theme.breakpoints.up("md")]: {
                marginBottom: "18px",
            },
        },
        noPadding: {
            padding: 0,
        },
        noOverflow: {
            overflow: "auto",
        },
        heading: {
            heading: {
                color: theme.palette.common.black,
            },
        },
    })
);

export default useStyles;
