import { AutocompleteData } from "mui-rff";
import { AnyAction } from "redux";
import { INormalizationFunction, IStepperAttributeFormValues } from "../../components/Mapping/StepAttribute/type";
import { IQuery, IQueryOperator } from "../../components/Mapping/ElementQueryBuilder/type";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork, IDeletePositionsResponse,
    IDerivedPosition,
    IElementsPosition,
    IManualPosition,
    IPositionFormConfiguration,
} from "../../packages/Api/data/elements/types";
import { IMappingAttribute } from "../../packages/Api/data/mapping/types";
import { IMappingMaterial } from "../../packages/Api/data/material/types";
import { IMappingWork } from "../../packages/Api/data/work/types";
import { ActionTypes, OnInstancesAttributesFailOptions } from "./action";
import { IIndexedPosition, IMappingContext } from "./type";
import { IMappingEquipment } from "../../packages/Api/data/equipment/types";
import { IGraphics, IGraphicsPattern } from "../../packages/Api/data/graphics/types";
import { getIndexToSearch } from "../../utils/Positions";
import { ProjectFileVersionViewable } from "../../graphql/generated/graphql";
import { InstancesAttributes } from "../../packages/Api/data/lookup/types";

export interface IMappingModuleState {
    mappingState: IMappingState;
}

export interface IMappingState {
    master: {
        loading: boolean;
        positions: IElementsPosition[];
        indexedPositions: IIndexedPosition[];
        deleteResult?: IDeletePositionsResponse;
        checked: number[];
        leafPositions: IElementsPosition[];
    };
    catalog: {
        loading: boolean;
        positionIDs: number[];
        checked: number[];
        detailPosition?: IElementsPosition; // active detail catalog position
        leafPositions: IElementsPosition[];
    };
    stepper: {
        context?: IMappingContext;
        viewer: {
            selectedViewable?: ProjectFileVersionViewable
        },
        matchingInstancesAttributes: {
            loading: boolean;
            data: InstancesAttributes | undefined;
            error: any;
        }
        element: {
            queryBuilder: {
                loading: boolean;
                query: IQuery | undefined;
                psets: string[];
                attributes: string[];
                operators: IQueryOperator[] | null;
            };
            derivedPositions: {
                loading: boolean;
                positions: IDerivedPosition[];
            };
            basicPositions: {
                loading: boolean;
                positions: IDerivedPosition[];
            };
        };
        attribute: {
            loading: boolean;
            attribute: IMappingAttribute | null;
            autocompletePsets: AutocompleteData[];
            autocompleteAttributes: AutocompleteData[];
            formValues?: IStepperAttributeFormValues;
            normalizationFunctions: INormalizationFunction[] | null;
        };
        work: {
            loading: boolean;
            catalogue: IMappingWork[];
            attachedWork: IAttachedWork[];
        };
        graphics: {
            loading: boolean;
            catalogue: IGraphics[];
            attachedGraphics?: IGraphics;
        };
        graphicsPattern: {
            loading: boolean;
            catalogue: IGraphicsPattern[];
        };
        equipment: {
            loading: boolean;
            catalogue: IMappingEquipment[];
            attachedEquipment: IAttachedEquipment[];
        };
        material: {
            loading: boolean;
            catalogue: IMappingMaterial[];
            attachedMaterial: IAttachedMaterial[];
        };
        unitPrice: {
            loading: boolean;
            unitPrice: number;
        };
        position: {
            positionBasics: {
                loading: boolean;
                positionBasics?: IElementsPosition;
            };
            manualPosition: {
                loading: boolean;
                manualPosition?: IManualPosition;
            };
            formConfiguration: {
                loading: boolean;
                formConfiguration?: IPositionFormConfiguration;
            };
        };
    };
}

export const initialState: Readonly<IMappingState> = {
    master: {
        loading: false,
        positions: [],
        indexedPositions: [],
        leafPositions: [],
        checked: [],
    },
    catalog: {
        loading: false,
        positionIDs: [],
        leafPositions: [],
        checked: [],
    },
    stepper: {
        viewer: {
        },
        matchingInstancesAttributes: {
            loading: false,
            data: undefined,
            error: undefined,
        },
        element: {
            queryBuilder: {
                loading: false,
                query: undefined,
                psets: [],
                attributes: [],
                operators: null,
            },
            derivedPositions: {
                loading: false,
                positions: [],
            },
            basicPositions: {
                loading: false,
                positions: [],
            },
        },
        attribute: {
            loading: false,
            attribute: null,
            autocompletePsets: [],
            autocompleteAttributes: [],
            normalizationFunctions: [],
        },
        work: {
            loading: false,
            catalogue: [],
            attachedWork: [],
        },
        graphics: {
            loading: false,
            catalogue: [],
            attachedGraphics: undefined,
        },
        graphicsPattern: {
            loading: false,
            catalogue: [],
        },
        equipment: {
            loading: false,
            catalogue: [],
            attachedEquipment: [],
        },
        material: {
            loading: false,
            catalogue: [],
            attachedMaterial: [],
        },
        unitPrice: {
            loading: false,
            unitPrice: 0,
        },
        position: {
            positionBasics: {
                loading: false,
            },
            manualPosition: {
                loading: false,
            },
            formConfiguration: {
                loading: false,
            },
        },
    },
};

export function reducer(state: IMappingState = initialState, action: AnyAction): IMappingState {
    switch (action.type) {
        // mapping positions
        case ActionTypes.OnMappingPositions:
        case ActionTypes.OnDeleteMappingPositions: {
            return {
                ...state,
                master: {
                    ...state.master,
                    loading: true,
                },
            };
        }
        case ActionTypes.OnMappingPositionsDone: {
            const { positions } = action.payload;
            const masterData = {
                ...state.master,
                loading: false,
                positions,
            };
            masterData.indexedPositions = getIndexToSearch(masterData.positions);
            return {
                ...state,
                master: masterData,
            };
        }
        case ActionTypes.OnDeleteMappingPositionsDone: {
            const { deletePositionsResponse } = action.payload;
            return {
                ...state,
                master: {
                    ...state.master,
                    loading: false,
                    deleteResult: deletePositionsResponse
                },
            };
        }
        case ActionTypes.OnMappingPositionsFail:
        case ActionTypes.OnDeleteMappingPositionsFail: {
            return {
                ...state,
                master: {
                    ...state.master,
                    loading: false,
                },
            };
        }

        // checked master/catalog
        case ActionTypes.OnCheckedMaster: {
            const { checked } = action.payload;
            return {
                ...state,
                master: {
                    ...state.master,
                    checked,
                },
            };
        }
        case ActionTypes.OnCheckedCatalog: {
            const { checked } = action.payload;
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    checked,
                },
            };
        }

        // catalog
        case ActionTypes.SetDetailPosition: {
            const { detailPosition } = action.payload;
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    detailPosition,
                },
            };
        }
        case ActionTypes.SetMasterLeafPositions: {
            const { leafPositions } = action.payload;
            return {
                ...state,
                master: {
                    ...state.master,
                    leafPositions,
                },
            };
        }
        case ActionTypes.SetCatalogLeafPositions: {
            const { leafPositions } = action.payload;
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    leafPositions,
                },
            };
        }
        case ActionTypes.SetDetailContext: {
            const { context } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state.stepper,
                    context,
                },
            };
        }

        // add/delete catalog positions IDS
        case ActionTypes.OnCatalogPositionIDs:
        case ActionTypes.OnAddCatalogPositionIDs:
        case ActionTypes.OnDeleteCatalogPositionIDs: {
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    loading: true,
                },
            };
        }
        case ActionTypes.OnCatalogPositionIDsDone:
        case ActionTypes.OnAddCatalogPositionIDsDone:
        case ActionTypes.OnDeleteCatalogPositionIDsDone: {
            const { positionIDs } = action.payload;
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    loading: false,
                    positionIDs,
                },
            };
        }
        case ActionTypes.OnCatalogPositionIDsFail:
        case ActionTypes.OnAddCatalogPositionIDsFail:
        case ActionTypes.OnDeleteCatalogPositionIDsFail: {
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    loading: false,
                },
            };
        }

        // element query
        case ActionTypes.SetElementQuery: {
            const { query } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state.stepper.element,
                        queryBuilder: {
                            ...state.stepper.element.queryBuilder,
                            query,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnElementQuery: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state.stepper.element,
                        queryBuilder: {
                            ...state.stepper.element.queryBuilder,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnElementQueryDone: {
            const { query, psets, attributes } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state.stepper.element,
                        queryBuilder: {
                            ...state.stepper.element.queryBuilder,
                            loading: false,
                            query,
                            psets,
                            attributes,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnSelectViewable: {
            const { viewable } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    viewer: {
                        ...state?.stepper.viewer,
                        selectedViewable: viewable
                    },
                },
            };
        }

        // instance attributes
        case ActionTypes.OnInstancesAttributesQuery: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    matchingInstancesAttributes: {
                        ...state?.stepper.matchingInstancesAttributes,
                        loading: true
                    },
                },
            };
        }
        case ActionTypes.OnInstancesAttributesDone: {
            const { instancesAttributes } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    matchingInstancesAttributes: {
                        ...state?.stepper.matchingInstancesAttributes,
                        loading: false,
                        data: instancesAttributes,
                    },
                },
            };
        }
        case ActionTypes.OnInstancesAttributesFail: {
            const { error } = action.payload as OnInstancesAttributesFailOptions;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    matchingInstancesAttributes: {
                        ...state?.stepper.matchingInstancesAttributes,
                        loading: false,
                        error: error,
                    },
                },
            };
        }

        // element query operators
        case ActionTypes.OnElementQueryOperators: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state.stepper.element,
                        queryBuilder: {
                            ...state.stepper.element.queryBuilder,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnElementQueryOperatorsDone: {
            const { operators } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state.stepper.element,
                        queryBuilder: {
                            ...state.stepper.element.queryBuilder,
                            loading: false,
                            operators,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnElementQueryFail:
        case ActionTypes.OnElementQueryOperatorsFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state.stepper.element,
                        queryBuilder: {
                            ...state.stepper.element.queryBuilder,
                            loading: false,
                        },
                    },
                },
            };
        }

        // derived positions
        case ActionTypes.OnDerivedPositions: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        derivedPositions: {
                            ...state?.stepper?.element?.derivedPositions,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnDerivedPositionsDone: {
            const { positions } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        derivedPositions: {
                            ...state?.stepper?.element?.derivedPositions,
                            loading: false,
                            positions,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnDeleteDerivedPositions: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        derivedPositions: {
                            ...state?.stepper?.element?.derivedPositions,
                            loading: true,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnDeleteDerivedPositionsDone: {
            const { positions } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        derivedPositions: {
                            ...state?.stepper?.element?.derivedPositions,
                            loading: false,
                            positions,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnDerivedPositionsFail:
        case ActionTypes.OnDeleteDerivedPositionsFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        derivedPositions: {
                            ...state?.stepper?.element?.derivedPositions,
                            loading: false,
                        },
                    },
                },
            };
        }

        // basic positions
        case ActionTypes.OnBasicPositions: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        basicPositions: {
                            ...state?.stepper?.element?.basicPositions,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnBasicPositionsDone: {
            const { positions } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        basicPositions: {
                            ...state?.stepper?.element?.basicPositions,
                            loading: false,
                            positions,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnDeleteBasicPositions: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        basicPositions: {
                            ...state?.stepper?.element?.basicPositions,
                            loading: true,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnDeleteBasicPositionsDone: {
            const { positions } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        basicPositions: {
                            ...state?.stepper?.element?.basicPositions,
                            loading: false,
                            positions,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnBasicPositionsFail:
        case ActionTypes.OnDeleteBasicPositionsFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    element: {
                        ...state?.stepper?.element,
                        basicPositions: {
                            ...state?.stepper?.element?.basicPositions,
                            loading: false,
                        },
                    },
                },
            };
        }

        // attribute
        case ActionTypes.OnAttribute: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    attribute: {
                        ...state?.stepper?.attribute,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnAttributeDone: {
            const { attribute, autocompletePsets, autocompleteAttributes, formValues } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    attribute: {
                        ...state?.stepper?.attribute,
                        loading: false,
                        attribute,
                        autocompletePsets,
                        autocompleteAttributes,
                        formValues,
                    },
                },
            };
        }
        case ActionTypes.OnAttributeFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    attribute: {
                        ...state?.stepper?.attribute,
                        loading: false,
                    },
                },
            };
        }

        case ActionTypes.SetAttributeFormValues: {
            const { formValues } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    attribute: {
                        ...state?.stepper?.attribute,
                        formValues,
                    },
                },
            };
        }

        case ActionTypes.OnAttributeFormInitDone: {
            const { normalizationFunctions } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    attribute: {
                        ...state?.stepper?.attribute,
                        normalizationFunctions
                    },
                },
            };
        }

        // work catalogue
        case ActionTypes.OnWorkCatalogue:
        case ActionTypes.OnAddWorkCatalogue:
        case ActionTypes.OnUpdateWorkCatalogue:
        case ActionTypes.OnDeleteWorkCatalogue: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    work: {
                        ...state?.stepper?.work,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnWorkCatalogueDone:
        case ActionTypes.OnAddWorkCatalogueDone:
        case ActionTypes.OnUpdateWorkCatalogueDone:
        case ActionTypes.OnDeleteWorkCatalogueDone: {
            const { catalogue } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    work: {
                        ...state?.stepper?.work,
                        loading: false,
                        catalogue,
                    },
                },
            };
        }
        case ActionTypes.OnWorkCatalogueFail:
        case ActionTypes.OnAddWorkCatalogueFail:
        case ActionTypes.OnUpdateWorkCatalogueFail:
        case ActionTypes.OnDeleteWorkCatalogueFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    work: {
                        ...state?.stepper?.work,
                        loading: false,
                    },
                },
            };
        }

        // graphics pattern catalogue
        case ActionTypes.OnGraphicsPatternCatalogue:{
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphicsPattern: {
                        ...state?.stepper?.graphicsPattern,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnGraphicsPatternCatalogueDone: {
            const { catalogue } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphicsPattern: {
                        ...state?.stepper?.graphicsPattern,
                        loading: false,
                        catalogue,
                    },
                },
            };
        }
        case ActionTypes.OnGraphicsPatternCatalogueFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphicsPattern: {
                        ...state?.stepper?.graphicsPattern,
                        loading: false,
                    },
                },
            };
        }

        // graphics catalogue
        case ActionTypes.OnGraphicsCatalogue:
        case ActionTypes.OnAddGraphicsCatalogue:
        case ActionTypes.OnUpdateGraphicsCatalogue:
        case ActionTypes.OnDeleteGraphicsCatalogue: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphics: {
                        ...state?.stepper?.graphics,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnGraphicsCatalogueDone:
        case ActionTypes.OnAddGraphicsCatalogueDone:
        case ActionTypes.OnUpdateGraphicsCatalogueDone:
        case ActionTypes.OnDeleteGraphicsCatalogueDone: {
            const { catalogue } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphics: {
                        ...state?.stepper?.graphics,
                        loading: false,
                        catalogue,
                    },
                },
            };
        }
        case ActionTypes.OnGraphicsCatalogueFail:
        case ActionTypes.OnAddGraphicsCatalogueFail:
        case ActionTypes.OnUpdateGraphicsCatalogueFail:
        case ActionTypes.OnDeleteGraphicsCatalogueFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphics: {
                        ...state?.stepper?.graphics,
                        loading: false,
                    },
                },
            };
        }

        // equipment catalogue
        case ActionTypes.OnEquipmentCatalogue:
        case ActionTypes.OnAddEquipmentCatalogue:
        case ActionTypes.OnUpdateEquipmentCatalogue:
        case ActionTypes.OnDeleteEquipmentCatalogue: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    equipment: {
                        ...state?.stepper?.equipment,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnEquipmentCatalogueDone:
        case ActionTypes.OnAddEquipmentCatalogueDone:
        case ActionTypes.OnUpdateEquipmentCatalogueDone:
        case ActionTypes.OnDeleteEquipmentCatalogueDone: {
            const { catalogue } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    equipment: {
                        ...state?.stepper?.equipment,
                        loading: false,
                        catalogue,
                    },
                },
            };
        }
        case ActionTypes.OnEquipmentCatalogueFail:
        case ActionTypes.OnAddEquipmentCatalogueFail:
        case ActionTypes.OnUpdateEquipmentCatalogueFail:
        case ActionTypes.OnDeleteEquipmentCatalogueFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    equipment: {
                        ...state?.stepper?.equipment,
                        loading: false,
                    },
                },
            };
        }

        // material catalogue
        case ActionTypes.OnMaterialCatalogue:
        case ActionTypes.OnAddMaterialCatalogue:
        case ActionTypes.OnUpdateMaterialCatalogue:
        case ActionTypes.OnDeleteMaterialCatalogue: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    material: {
                        ...state?.stepper?.material,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnMaterialCatalogueDone:
        case ActionTypes.OnAddMaterialCatalogueDone:
        case ActionTypes.OnUpdateMaterialCatalogueDone:
        case ActionTypes.OnDeleteMaterialCatalogueDone: {
            const { catalogue } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    material: {
                        ...state?.stepper?.material,
                        loading: false,
                        catalogue,
                    },
                },
            };
        }
        case ActionTypes.OnMaterialCatalogueFail:
        case ActionTypes.OnAddMaterialCatalogueFail:
        case ActionTypes.OnUpdateMaterialCatalogueFail:
        case ActionTypes.OnDeleteMaterialCatalogueFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    material: {
                        ...state?.stepper?.material,
                        loading: false,
                    },
                },
            };
        }

        // attached work
        case ActionTypes.OnAttachedWork:
        case ActionTypes.OnUpdateAttachedWork:
        case ActionTypes.OnDeleteAttachedWork:
        case ActionTypes.OnAddWorkToPosition: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    work: {
                        ...state?.stepper?.work,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnAttachedWorkDone:
        case ActionTypes.OnDeleteAttachedWorkDone:
        case ActionTypes.OnAddWorkToPositionDone: {
            const { attachedWork } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    work: {
                        ...state?.stepper?.work,
                        loading: false,
                        attachedWork,
                    },
                },
            };
        }
        case ActionTypes.OnUpdateAttachedWorkDone:
        case ActionTypes.OnUpdateAttachedWorkFail:
        case ActionTypes.OnAttachedWorkFail:
        case ActionTypes.OnDeleteAttachedWorkFail:
        case ActionTypes.OnAddWorkToPositionFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    work: {
                        ...state?.stepper?.work,
                        loading: false,
                    },
                },
            };
        }
        // attached graphics
        case ActionTypes.OnAttachedGraphics:
        case ActionTypes.OnAddGraphicsToPosition: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphics: {
                        ...state?.stepper?.graphics,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnAttachedGraphicsDone:
        case ActionTypes.OnAddGraphicsToPositionDone: {
            const { attachedGraphics } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphics: {
                        ...state?.stepper?.graphics,
                        loading: false,
                        attachedGraphics,
                    },
                },
            };
        }
        case ActionTypes.OnAttachedGraphicsFail:
        case ActionTypes.OnAddGraphicsToPositionFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    graphics: {
                        ...state?.stepper?.graphics,
                        loading: false,
                    },
                },
            };
        }

        // attached equipment
        case ActionTypes.OnAttachedEquipment:
        case ActionTypes.OnUpdateAttachedEquipment:
        case ActionTypes.OnDeleteAttachedEquipment:
        case ActionTypes.OnAddEquipmentToPosition: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    equipment: {
                        ...state?.stepper?.equipment,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnAttachedEquipmentDone:
        case ActionTypes.OnDeleteAttachedEquipmentDone:
        case ActionTypes.OnAddEquipmentToPositionDone: {
            const { attachedEquipment } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    equipment: {
                        ...state?.stepper?.equipment,
                        loading: false,
                        attachedEquipment,
                    },
                },
            };
        }
        case ActionTypes.OnUpdateAttachedEquipmentDone:
        case ActionTypes.OnUpdateAttachedEquipmentFail:
        case ActionTypes.OnAttachedEquipmentFail:
        case ActionTypes.OnDeleteAttachedEquipmentFail:
        case ActionTypes.OnAddEquipmentToPositionFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    equipment: {
                        ...state?.stepper?.equipment,
                        loading: false,
                    },
                },
            };
        }

        // attached material
        case ActionTypes.OnAttachedMaterial:
        case ActionTypes.OnUpdateAttachedMaterial:
        case ActionTypes.OnDeleteAttachedMaterial:
        case ActionTypes.OnAddMaterialToPosition: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    material: {
                        ...state?.stepper?.material,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnAttachedMaterialDone:
        case ActionTypes.OnDeleteAttachedMaterialDone:
        case ActionTypes.OnAddMaterialToPositionDone: {
            const { attachedMaterial } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    material: {
                        ...state?.stepper?.material,
                        loading: false,
                        attachedMaterial,
                    },
                },
            };
        }
        case ActionTypes.OnUpdateAttachedMaterialDone:
        case ActionTypes.OnUpdateAttachedMaterialFail:
        case ActionTypes.OnAttachedMaterialFail:
        case ActionTypes.OnDeleteAttachedMaterialFail:
        case ActionTypes.OnAddMaterialToPositionFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    material: {
                        ...state?.stepper?.material,
                        loading: false,
                    },
                },
            };
        }

        // unit price
        case ActionTypes.OnUnitPrice: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    unitPrice: {
                        ...state?.stepper.unitPrice,
                        loading: true,
                    },
                },
            };
        }
        case ActionTypes.OnUnitPriceDone: {
            const { unitPrice } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    unitPrice: {
                        ...state?.stepper.unitPrice,
                        loading: false,
                        unitPrice,
                    },
                },
            };
        }
        case ActionTypes.OnUnitPriceFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    unitPrice: {
                        ...state?.stepper.unitPrice,
                        loading: false,
                    },
                },
            };
        }

        // update position basics
        case ActionTypes.OnUpdatePositionBasics: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        positionBasics: {
                            ...state?.stepper?.position?.positionBasics,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnUpdatePositionBasicsDone: {
            const { positionBasics } = action.payload;
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    detailPosition: positionBasics,
                },
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        positionBasics: {
                            ...state?.stepper?.position?.positionBasics,
                            loading: false,
                            positionBasics,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnUpdatePositionBasicsFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        positionBasics: {
                            ...state?.stepper?.position?.positionBasics,
                            loading: false,
                        },
                    },
                },
            };
        }

        // get manual position
        // update manual position
        case ActionTypes.OnManualPosition:
        case ActionTypes.OnUpdateManualPosition: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        manualPosition: {
                            ...state?.stepper?.position?.manualPosition,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnManualPositionDone: {
            const { manualPosition } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        manualPosition: {
                            ...state?.stepper?.position?.manualPosition,
                            loading: false,
                            manualPosition,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnUpdateManualPositionDone: {
            const { manualPosition } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    unitPrice: {
                        ...state?.stepper?.unitPrice,
                        unitPrice: manualPosition?.unitPrice,
                    },
                    position: {
                        ...state?.stepper.position,
                        manualPosition: {
                            ...state?.stepper?.position?.manualPosition,
                            loading: false,
                            manualPosition,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnManualPositionFail:
        case ActionTypes.OnUpdateManualPositionFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        manualPosition: {
                            ...state?.stepper?.position?.manualPosition,
                            loading: false,
                        },
                    },
                },
            };
        }

        // get position form configuration
        case ActionTypes.OnPositionFormConfiguration: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        formConfiguration: {
                            ...state?.stepper?.position?.formConfiguration,
                            loading: true,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnPositionFormConfigurationDone: {
            const { formConfiguration } = action.payload;
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        formConfiguration: {
                            ...state?.stepper?.position?.formConfiguration,
                            loading: false,
                            formConfiguration,
                        },
                    },
                },
            };
        }
        case ActionTypes.OnPositionFormConfigurationFail: {
            return {
                ...state,
                stepper: {
                    ...state?.stepper,
                    position: {
                        ...state?.stepper.position,
                        formConfiguration: {
                            ...state?.stepper?.position?.formConfiguration,
                            loading: false,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
}
