import { LanguageProvider } from "@iolabs/language-provider";
import { GlobalDialogProvider } from "@iolabs/wip-dialog";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import GraphqlProvider from "../../graphql/GraphqlProvider";
import SecurityProvider from "../../redux/keyclock/provider";
import StoreProvider from "../../redux/provider";
import theme from "../../theme/Theme";
import de from "../../translations/de.json";
import en from "../../translations/en.json";
import App from "../App/App";

const AppWrapper: React.FC = () => {
    return (
        <StoreProvider>
            <SecurityProvider>
                <ThemeProvider theme={theme}>
                    <GraphqlProvider>
                        <LanguageProvider
                            defaultLocale="de"
                            messages={{
                                de,
                                en,
                            }}
                        >
                            <GlobalDialogProvider>
                                <App />
                            </GlobalDialogProvider>
                        </LanguageProvider>
                    </GraphqlProvider>
                </ThemeProvider>
            </SecurityProvider>
        </StoreProvider>
    );
};

export default AppWrapper;
