import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            "& input": {
                fontSize: ".8rem",
            },
            "& textarea": {
                fontSize: ".8rem",
            },
            "& .MuiFormLabel-root": {
                fontSize: theme.typography.pxToRem(13),
                color: theme.palette.primary.main,
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "13px 14px",
                fontSize: ".8rem",
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 14px",
            },
            "& .MuiInputAdornment-root": {
                color: theme.palette.grey[400],
                marginRight: "0",
                marginLeft: "0.5em",
            },
            width: "150px"
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.grey[400],
            },
        },
        help: {
            fontSize: theme.typography.pxToRem(13),
        }
    })
);

export default useStyles;
