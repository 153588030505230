import { createSelector } from "reselect";
import { IMarkupsData, IViewerModuleState, IViewerState } from "./reducer";

export function selectViewerState(state: IViewerModuleState): IViewerState {
    return state.viewerState;
}

export const selectViewer = createSelector(selectViewerState, (state) => state);

export const selectForgeToken = createSelector(selectViewerState, (state) => state.forgeToken);

export const selectUrn = createSelector(selectViewerState, (state) => state.urn);
export const selectForgeId = createSelector(selectViewerState, (state) => state.forgeId);
export const selectIsolateIds = createSelector(selectViewerState, (state) => state.isolateIds);
export const selectApi = createSelector(selectViewerState, (state) => state.api);
export const selectIsEmea = createSelector(selectViewerState, (state) => state.isEmea);
export const selectFileId = createSelector(selectViewerState, (state) => state.fileId);
export const selectFileType = createSelector(selectViewerState, (state) => state.fileType);
export const selectObjectsInContext = createSelector(selectViewerState, (state) => state.objectsInContext);
export const selectObjectsInContextSmart = createSelector(selectViewerState, (state) => state.objectsInContextSmart);
export const selectObjectsInContextViewerIds = createSelector(
    selectViewerState,
    (state) => state.objectsInContextViewerIds
);
export const selectIssues = createSelector(selectViewerState, (state) => state.issues);
export const selectIssuables = createSelector(selectViewerState, (state) => state.issuables);
export const selectPrintables = createSelector(selectViewerState, (state) => state.printables);
export const selectPreferredViewableGuid = createSelector(selectViewerState, (state) => state.preferredViewableGuid);

export const selectModelById = (modelId: number) =>
    createSelector(selectViewerState, (state) => state.models.find((model) => model.id === modelId));

export const selectModels = createSelector(selectViewerState, (state) => state.models);
export const selectModelVariantIds = createSelector(selectViewerState, (state) =>
    state.models.map((m) => m.variantId as number).filter((m) => m != null)
);

export const selectViewable = createSelector(selectViewerState, (state) => state.viewable);
export const selectMarkupsExternalHandling = createSelector(
    selectViewerState,
    (state) => state.markupsExternalHandling
);
export const selectMarkupsData = createSelector(selectViewerState, (state) => state.markupsData as IMarkupsData);
export const selectActiveViewables = createSelector(selectViewerState, (state) => state.activeViewables as any[]);
