import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            color: theme.palette.grey["500"],
            padding: "8px",
            marginRight: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                height: "40px",
            },
        },
        responsiveButton: {
            display: "none",
            [theme.breakpoints.up("lg")]: {
                display: "block",
            },
        },
        listItemIcon: {
            color: theme.palette.text.secondary,
            minWidth: "35px",
        },
        subMenuItem: {
            paddingLeft: theme.spacing(5.3),
        },
        icon: {
          marginRight: ".2rem",
          width: "2rem",
          display: "flex",
          alignItems: "center"
        }
    })
);

export default useStyles;
