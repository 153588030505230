import { defineMessages } from "react-intl";

export default defineMessages({
    author: {
        id: "components.footer.author",
        defaultMessage: "ioLabs AG | iolabs.ch",
    },
    client: {
        id: "components.footer.client",
        defaultMessage: "sumoo GmbH | sumoo.tech",
    },
    websiteClient: {
        id: "components.footer.website",
        defaultMessage: "www.iolabs.ch",
    },
    copyright: {
        id: "components.footer.copyright",
        defaultMessage: `Copyright © {year} - ioLabs AG`,
    },
    developedBy: {
        id: "components.footer.developedBy",
        defaultMessage: 'Developed by:',
    },
    developedByAnd: {
        id: "components.footer.developedAnd",
        defaultMessage: 'and',
    },
    update: {
        id: "components.footer.update",
        defaultMessage: "Latest update",
    },
});
