import { defineMessages } from "react-intl";

export default defineMessages({
    next: {
        id: "components.mapping.mappingDetailView.next",
        defaultMessage: "Next",
    },
    back: {
        id: "components.mapping.mappingDetailView.back",
        defaultMessage: "Back",
    },
});
