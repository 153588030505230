import MuiQueryBuilder from "@iolabs/mui-querybuilder";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./styles";
import StepElementQueryBuilder from "../../StepElementQueryBuilder/StepElementQueryBuilder";
import {
    setElementQuery,
    useMappingQueryAttributes,
    useMappingQueryOperators,
    useMappingQueryPsets,
} from "../../../../redux/mapping";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";
import messages from "./messages";
import ElementQueryBuilder from "../../ElementQueryBuilder/ElementQueryBuilder";
import { IQuery, IQueryOperator } from "../../ElementQueryBuilder/type";
import { IMappingElements } from "../../../../packages/Api/data/mapping/types";
import { getElements } from "../../StepContentWrapper/utils";

interface IDerivedPositionIfConditionDialogProps {
    onClose?: () => void;
    onSave?: (condition: IMappingElements) => void;
    query: IQuery|undefined;
}

const DerivedPositionIfConditionDialog: React.FC<IDerivedPositionIfConditionDialogProps> = ({
    onClose,
    onSave,
    query
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const queryPsets: string[] = [];
    const queryAttributes: string[] = [];
    // const queryPsets: string[] = useMappingQueryPsets();
    // const queryAttributes: string[] = useMappingQueryAttributes();
    const queryOperators: IQueryOperator[] | null = useMappingQueryOperators();


    const [validQuery, setValidQuery] = useState<boolean>(false);
    const [activeQuery, setActiveQuery] = useState<IQuery|null>(null);

    const [open, setOpen] = useState<boolean>(false);

    // translations
    const intl = useIntl();
    const transTitle = intl.formatMessage({ ...messages.title });
    const transCloseButton = intl.formatMessage({ ...messages.closeButton });
    const transSaveButton = intl.formatMessage({ ...messages.saveButton });



    const handleClose = () => {
        if (onClose) {
            onClose();
        }

    };

    const handleSave = () => {
        if (activeQuery) {
            const newElements: IMappingElements = getElements(activeQuery);
            if (onSave) {
                onSave(newElements);
            }
            if (onClose) {
                onClose();
            }
        }
    }

    const handleChangeQuery = (query, valid) => {
        setActiveQuery(query);
        setValidQuery(
            query?.rules?.length === 0 ? false : MuiQueryBuilder.isQueryValid(query)
        );
    }

    return (
        <>
            <DialogTitle id="position-dialog-title" className={classes.title}>
                {transTitle}
            </DialogTitle>
            <DialogContent>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <ElementQueryBuilder
                    loading={false}
                    query={query}
                    operators={queryOperators}
                    autocompletePsets={queryPsets}
                    autocompleteAttributes={queryAttributes}
                    onQueryChange={handleChangeQuery}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" size="medium" onClick={handleClose}>
                    {transCloseButton}
                </Button>
                <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="medium"
                    disabled={!validQuery/* || saving || saved*/}
                    onClick={handleSave}
                >
                    {transSaveButton}
                </Button>
            </DialogActions>
        </>
    );
};

export default DerivedPositionIfConditionDialog;
