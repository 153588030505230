import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IAccount, IProject, ITree, ITreeManualData, ITreeResponse } from "./types";

export enum TreeNodeType {
    Project = "project",
}

const treeApi = new ApiClient("/DataManagement", config.api.baseUrl);

export const treeDataNode = (nodeId: string, depth: number, token?: string): Promise<ITree> => {
    if (token) {
        ApiClient.setAuthorizationToken(token);
    }

    return treeApi.post(`/tree?nodeId=${nodeId}&depth=${depth}`).then((response: AxiosResponse<ITree>) => {
        return Promise.resolve(response.data);
    });
};

export const treeDataManual = (token: string, data: ITreeManualData): Promise<ITree> => {
    ApiClient.setAuthorizationToken(token);

    return treeApi
        .post(
            `/tree/manual?nodeType=${data?.nodeType}&parentId=${data?.parentId}&nodeId=${data?.nodeId}&depth=${data?.depth}&rootId=${data?.rootId}`
        )
        .then((response: AxiosResponse<ITree>) => {
            return Promise.resolve(response.data);
        });
};

export const getProjects = (token: string): Promise<IProject[]> => {
    ApiClient.setAuthorizationToken(token);

    return treeApi.get(`/tree?depth=2`).then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(treeResponseToProjects((response.data as unknown) as ITree[]));
    });
};

export const treeResponseToProjects = (response: ITree[]): IProject[] => {
    const projects: IProject[] = [];

    response.forEach((account) => {
        const acc: IAccount = {
            id: account.id,
            name: account.text,
            urn: account.data.urn,
        };
        account.children?.forEach((project) => {
            projects.push({
                account: acc,
                id: project.id,
                name: project.text,
                urn: project.data.urn,
            });
        });
    });

    return projects;
};
