import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {},
        dialogTitle: {
            margin: 0,
            padding: theme.spacing(1, 1, 1, 2),
            cursor: "move",
            minHeight: "46px",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogContent: {
            width: "100%",
            height: "calc(100% - 46px)",
            maxHeight: "calc(100% - 46px)",
            padding: theme.spacing(1),
            overflow: "hidden",
        },
        dialogContentBox: {
            overflow: "auto",
        },
        resizable: {
            position: "relative",
            "& .react-resizable-handle": {
                position: "absolute",
                width: 20,
                height: 20,
                bottom: 0,
                right: 0,
                background:
                    "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
                "background-position": "bottom right",
                padding: "0 3px 3px 0",
                "background-repeat": "no-repeat",
                "background-origin": "content-box",
                "box-sizing": "border-box",
                cursor: "se-resize",
            },
        },
    })
);

export default useStyles;
