import { ElementCategoryMappingStatusType, IElementsPosition } from "../../../packages/Api/data/elements/types";
import React from "react";
import { Icon, List, ListItem, ListItemText, Popover, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IMappingLightProps {
    position: IElementsPosition;
}

const MappingLight: React.FC<IMappingLightProps> = ({
   position
}) => {
    let className: string;
    let message: string;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    // translations
    const intl = useIntl();
    const transEverythingMapped = intl.formatMessage({ ...messages.everythingMapped });
    const transOneError = intl.formatMessage({ ...messages.oneError });
    const transMultipleErrors = intl.formatMessage({ ...messages.multipleErrors });
    const transNotCalculable = intl.formatMessage({ ...messages.notCalculable });
    const transItemElement = intl.formatMessage({ ...messages.itemElement });
    const transItemAttributes = intl.formatMessage({ ...messages.itemAttributes });
    const transItemMaterial = intl.formatMessage({ ...messages.itemMaterial });
    const transItemDerivedCategories = intl.formatMessage({ ...messages.itemDerivedCategories });
    const transItemManual = intl.formatMessage({ ...messages.itemManual});
    const transItemSubcontractor = intl.formatMessage({ ...messages.itemSubcontractor});


    if (position.mappingStatus == null) {
        className = "default";
        message = transNotCalculable;
    }
    else {
        switch (position.mappingStatus.status) {
            case ElementCategoryMappingStatusType.EverythingOk:
                className = "success";
                message = transEverythingMapped;
                break;
            case ElementCategoryMappingStatusType.OneError:
                className = "warning";
                message = transOneError;
                break;
            case ElementCategoryMappingStatusType.MultipleErrors:
                className = "error";
                message = transMultipleErrors;
                break;
            case ElementCategoryMappingStatusType.NotCalculable:
                className = "default";
                message = transNotCalculable;
                break;
        }

    }

    const getDetailsClassName = (item: string): string => {
        if (position.mappingStatus) {
            return position.mappingStatus[item].passed ? "ok" : "nok";
        }
        return "nok";
    }

    const getDetailsIcon = (item: string): string => {
        if (position.mappingStatus) {
            return position.mappingStatus[item].passed ? "check_circle" : "cancel";
        }
        return "cancel";
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <>
        <span className={className}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handleClick}
              onMouseLeave={handleClose}
        >
            M
        </span>
        <Popover
            id={'mouse-over-popover'}
            open={open}
            className={classes.statusPopover}
            classes={{
                paper: classes.statusPopoverContent,
            }}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Typography variant={"caption"}>{message}</Typography>
            {position.mappingStatus && position.mappingStatus.status != ElementCategoryMappingStatusType.NotCalculable && <List component="nav" className={classes.statusPopoverContentList}>
                {position.mappingStatus.element && (
                    <ListItem button className={getDetailsClassName("element")}>
                        <Icon>{getDetailsIcon("element")}</Icon>
                        <ListItemText primary={transItemElement} />
                    </ListItem>
                )}
                {position.mappingStatus.manual && (
                    <ListItem button className={getDetailsClassName("manual")}>
                        <Icon>{getDetailsIcon("manual")}</Icon>
                        <ListItemText primary={transItemManual} />
                    </ListItem>
                )}
                {position.mappingStatus.attributes && (
                    <ListItem button className={getDetailsClassName("attributes")}>
                        <Icon>{getDetailsIcon("attributes")}</Icon>
                        <ListItemText primary={transItemAttributes} />
                    </ListItem>
                )}
                {position.mappingStatus.material && (
                    <ListItem button className={getDetailsClassName("material")}>
                        <Icon>{getDetailsIcon("material")}</Icon>
                        <ListItemText primary={transItemMaterial} />
                    </ListItem>
                )}
                {position.mappingStatus.subcontractor && (
                    <ListItem button className={getDetailsClassName("subcontractor")}>
                        <Icon>{getDetailsIcon("subcontractor")}</Icon>
                        <ListItemText primary={transItemSubcontractor} />
                    </ListItem>
                )}
                {position.mappingStatus.derivedCategories && (
                    <ListItem button className={getDetailsClassName("derivedCategories")}>
                        <Icon>{getDetailsIcon("derivedCategories")}</Icon>
                        <ListItemText primary={transItemDerivedCategories} />
                    </ListItem>
                )}
            </List>}
        </Popover>
    </>
};

export default MappingLight;
