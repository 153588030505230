import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overlay: {
            background: "rgba(0,0,0,0.4)",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 99999,
            transition: "0.2s opacity",
            opacity: 0,
            pointerEvents: 'none',
        },
        loading: {
            opacity: 1,
            pointerEvents: 'all',
        },
        progressWrapper: {
            color: theme.palette.primary.main,
            position: "relative",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center"
        },
        progress: {
            color: theme.palette.common.white
        },
        message: {
            color: theme.palette.common.white
        }
    })
);

export default useStyles;
