import { OnLoadPropertiesCallback } from "./Viewing.Extensions.CustomPropertiesExtension";
import { xor } from "lodash";

export class CustomPropertiesPanel extends Autodesk.Viewing.UI.PropertyPanel {
    private viewer: Autodesk.Viewing.GuiViewer3D;

    private onLoadProperties?: OnLoadPropertiesCallback;
    private loadingElement: HTMLElement;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, onLoadProperties?: OnLoadPropertiesCallback) {
        super(<HTMLElement>viewer.container, "custom-properties", "Zapper properties");
        this.viewer = viewer;
        this.onLoadProperties = onLoadProperties;
        let lastSelection = [];
        this.loadingElement = this.createLoading();
        this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (selection: any) => {
            if (this.onLoadProperties && xor(selection.dbIdArray, lastSelection).length > 0) {
                this.showLoading();
                lastSelection = selection.dbIdArray;
                this.onLoadProperties(selection.dbIdArray, this.setProps.bind(this));
            }
        });

        this.container.style.resize = "none";
        this.container.style.left = "50px";
        this.container.style.top = "50px";
        this.container.style.height = "500px";
        this.container.style.width = "500px";
    }

    setProps(props: any) {
        this.setProperties(props);
        this.hideLoading();
    }

    createLoading(): HTMLElement {
        const loading = document.createElement("div");
        loading.id = "loading-spinner";
        loading.textContent = "loading...";
        return loading;
    }

    showLoading() {
        this.scrollContainer.appendChild(this.loadingElement);
    }
    hideLoading() {
        this.scrollContainer.removeChild(this.loadingElement);
    }
}