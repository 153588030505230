import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switchBox: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            width: "100%",
            padding: theme.spacing(0, 1),
        },
        switchLabel: {
            cursor: "pointer",
            fontWeight: 400,
            color: theme.palette.primary[500],
        },
        switchRoot: {
            margin: theme.spacing(0, 1),
        },
        switchActive: {
            color: theme.palette.primary.main,
        },
        switchBase: {
            color: theme.palette.common.white,
            "&.Mui-checked": {
                color: theme.palette.success.main,
            },
        },
        switchColorPrimary: {},
        switchThumb: {
            color: theme.palette.success.main,
        },
        switchTrack: {
            backgroundColor: theme.palette.primary[500],
        },
        switchChecked: {
            color: theme.palette.success.main,
            // track color if switch is checked
            "& + .MuiSwitch-track": {
                backgroundColor: `${theme.palette.primary[500]} !important`,
            },
        },
        paper: {
            width: "100%",
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            [theme.breakpoints.up("xl")]: {
                padding: theme.spacing(2),
            },
        },
        form: {
            width: "100%",
        },
        fieldBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        positionTypeSelector: {
            "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
                fontSize: theme.typography.pxToRem(13),
            },
            '& .MuiSvgIcon-root': {
                fontSize: theme.typography.pxToRem(20),
            },
        },
        textField: {
            "&:not(:last-child)": {
                marginRight: theme.spacing(1),
            },
            "& input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset > legend": {
                maxWidth: 0,
            },
            "& label": {
                paddingLeft: "5px",
                paddingRight: "5px",
                background: theme.palette.common.white,
            },
        },
    })
);

export default useStyles;
