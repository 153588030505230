import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Checkbox, CircularProgress, IconButton, Input, TableSortLabel, Tooltip } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import MuiVirtualizedTable from "mui-virtualized-table";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { IElementsPosition } from "../../../../packages/Api/data/elements/types";
import { IMappingWork } from "../../../../packages/Api/data/work/types";
import {
    onAddWorkCatalogue,
    onAddWorkToPosition,
    onDeleteWorkCatalogue,
    onUpdateWorkCatalogue,
    onWorkCatalogue,
    useDetailPosition,
    useMappingWorkCatalogue,
    useMappingWorkLoading,
} from "../../../../redux/mapping";
import { useActiveProject } from "../../../../redux/project";
import { formatNumber } from "../../../../utils/Formatter";
import Icon from "../../../Icon/Icon";
import { ProjectData } from "../../../ProjectSelectorWrapper/type";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import RowSubMenu from "../RowSubMenu/RowSubMenu";
import useStyles from "./styles";
import { getComparator, Order, stableSort } from "./utils";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IDefaultProps {
    headerHeight: number;
    rowHeight: number;
    minTableWidth: number;
}

interface IMappingWorkUpdate extends IMappingWork {
    isUpdateMode: boolean;
    isAddMode: boolean;
}

interface IWorkDialogProps {
    addModeActive: boolean;
    selectedWorkIds: number[];
    handleAddModeActive: (active: boolean) => void;
    handleSelectedWorkIds: (rowIDs: number[]) => void;
    handleClose: () => void;
    ref: any;
}

const WorkDialog: React.FC<IWorkDialogProps> = forwardRef(
    ({ addModeActive, selectedWorkIds, handleAddModeActive, handleSelectedWorkIds, handleClose }, ref) => {
        const classes = useStyles();
        const { keycloak, initialized: keycloakInitialized } = useKeycloak();
        const dispatch = useDispatch<DispatchAction>();
        const activeProject: ProjectData | undefined = useActiveProject();
        const detailPosition: IElementsPosition | undefined = useDetailPosition();
        const workLoading: boolean = useMappingWorkLoading();
        const workCatalogue: IMappingWork[] = useMappingWorkCatalogue();

        const addModeRef = useRef(null);

        const [defaultProps] = useState<IDefaultProps>({
            headerHeight: 28,
            rowHeight: 28,
            minTableWidth: 2000,
        });

        const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
        const [confirmDelete, setConfirmDelete] = useState<IMappingWorkUpdate | null>(null);

        const [updateWorkCatalogue, setUpdateWorkCatalogue] = useState<IMappingWorkUpdate[]>([]);
        const [previous, setPrevious] = useState({});

        const [order, setOrder] = useState<Order>(Order.ASC);
        const [orderBy, setOrderBy] = useState<keyof IMappingWorkUpdate>("itemNumber");

        const handleConfirmOpen = () => {
            setConfirmOpen(true);
        };

        const handleConfirmClose = () => {
            setConfirmOpen(false);
        };

        useImperativeHandle(ref, () => ({
            handlePrepareAddWork() {
                // @ts-ignore
                addModeRef?.current?.scrollIntoView();
                setUpdateWorkCatalogue((prevState) => {
                    return [
                        ...prevState,
                        {
                            // workID: updateWorkCatalogue?.[updateWorkCatalogue?.length - 1]?.workID + 1,
                            workID: -666,
                            productID: "New work",
                            productName: "",
                            itemNumber: "",
                            liPrice: 0,
                            surcharge: 0,
                            ep: 0,
                            isAddMode: true,
                            isUpdateMode: true,
                        },
                    ];
                });
                handleAddModeActive(true);
            },

            handleAddWorkToPosition() {
                if (!activeProject && !detailPosition) return;
                dispatch(
                    onAddWorkToPosition({
                        token: keycloak?.token as string,
                        projectID: activeProject?.projectID as number,
                        positionID: detailPosition?.positionID as number,
                        workIDs: selectedWorkIds,
                    })
                );
                handleAddModeActive(false);
                handleSelectedWorkIds([]);
                handleClose();
            },
        }));

        const handleAddWork = (row: IMappingWorkUpdate) => {
            dispatch(
                onAddWorkCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    request: {
                        productID: row?.productID,
                        productName: row?.productName,
                        itemNumber: row?.itemNumber,
                        liPrice: row?.liPrice,
                        surcharge: row?.surcharge,
                        ep: row?.ep,
                    },
                })
            );
            handleAddModeActive(false);
        };

        const handleDiscardAddWork = (workID: number) => {
            handleAddModeActive(false);
            setUpdateWorkCatalogue((prevState) => {
                return prevState?.filter((work) => work?.workID !== workID);
            });
        };

        const handleChange = (event, row: IMappingWorkUpdate) => {
            event.stopPropagation();

            if (!previous[row?.workID] && !row?.isAddMode) {
                setPrevious((prevState) => ({ ...prevState, [row?.workID]: row }));
            }
            const value = event.target.value;
            const name = event.target.name;
            const updatedWorkCatalogue = updateWorkCatalogue?.map((work) => {
                return Object.is(work?.workID, row?.workID) ? { ...work, [name]: value } : work;
            });
            setUpdateWorkCatalogue(updatedWorkCatalogue);
        };

        const handleToggleUpdateMode = (workID: number) => {
            setUpdateWorkCatalogue((prevState) => {
                return prevState?.map((work) => {
                    return work?.workID === workID ? { ...work, isUpdateMode: !work.isUpdateMode } : work;
                });
            });
        };

        const handleUpdateWork = (workID: number) => {
            const updatedWork = updateWorkCatalogue?.find((uwc) => uwc?.workID === workID);
            if (!updatedWork) return;

            dispatch(
                onUpdateWorkCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    workID: workID,
                    request: {
                        ep: updatedWork?.ep,
                        itemNumber: updatedWork?.itemNumber,
                        liPrice: updatedWork?.liPrice,
                        productID: updatedWork?.productID,
                        productName: updatedWork?.productName,
                        surcharge: updatedWork?.surcharge,
                    },
                })
            );
            handleToggleUpdateMode(workID);
        };

        const handleRevertWork = (workID: number) => {
            const revertedWorkCatalogue = updateWorkCatalogue?.map((work) => {
                return work.workID === workID && previous[workID] ? previous[workID] : work;
            });

            const revertedWork = revertedWorkCatalogue?.find((rwc) => rwc?.workID === workID);
            if (!revertedWork) return;

            setUpdateWorkCatalogue(revertedWorkCatalogue);
            setPrevious((prevState) => {
                delete prevState[workID];
                return prevState;
            });

            dispatch(
                onUpdateWorkCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    workID: workID,
                    request: {
                        ep: revertedWork?.ep,
                        itemNumber: revertedWork?.itemNumber,
                        liPrice: revertedWork?.liPrice,
                        productID: revertedWork?.productID,
                        productName: revertedWork?.productName,
                        surcharge: revertedWork?.surcharge,
                    },
                })
            );
            handleToggleUpdateMode(workID);
        };

        const handleDeleteWork = (row: IMappingWorkUpdate) => {
            setConfirmDelete(row);
            handleConfirmOpen();
        };

        const handleConfirmDeleteWork = () => {
            if (!confirmDelete) return;

            dispatch(
                onDeleteWorkCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    workID: confirmDelete?.workID,
                })
            );
            setConfirmDelete(null);
            handleConfirmClose();
        };

        const handleRequestSort = (property: keyof IMappingWorkUpdate) => () => {
            const isAsc = orderBy === property && order === Order.ASC;
            setOrder(isAsc ? Order.DESC : Order.ASC);
            setOrderBy(property);
        };

        useEffect(() => {
            // @ts-ignore
            setUpdateWorkCatalogue(stableSort(updateWorkCatalogue, getComparator(order, orderBy)));
        }, [order, orderBy]);

        useEffect(() => {
            // @ts-ignore
            setUpdateWorkCatalogue(stableSort(workCatalogue, getComparator(order, orderBy)));
        }, [workCatalogue]);

        useEffect(() => {
            dispatch(onWorkCatalogue({ token: keycloak?.token as string }));
        }, [keycloakInitialized]);


      // translations
      const intl = useIntl();
      const transLabelItemNumber = intl.formatMessage({ ...messages.labelItemNumber });
      const transLabelProductId = intl.formatMessage({ ...messages.labelProductId });
      const transLabelProductName = intl.formatMessage({ ...messages.labelProductName });
      const transLabelListPrice = intl.formatMessage({ ...messages.labelListPrice });
      const transLabelSurcharge = intl.formatMessage({ ...messages.labelSurcharge });
      const transTooltipAddNewWork = intl.formatMessage({ ...messages.tooltipAddNewWork });
      const transTooltipSaveChanges = intl.formatMessage({ ...messages.tooltipSaveChanges });
      const transTooltipToReturn = intl.formatMessage({ ...messages.tooltipToReturn });
      const transTooltipDiscard = intl.formatMessage({ ...messages.tooltipDiscard });
      const transTooltipClose = intl.formatMessage({ ...messages.tooltipClose });
      const transTooltipUpdate = intl.formatMessage({ ...messages.tooltipUpdate });
      const transTooltipDelete = intl.formatMessage({ ...messages.tooltipDelete });
      const transConfirmDeleteTitle = intl.formatMessage({ ...messages.confirmDeleteTitle });
      const transConfirmDeleteMessage = intl.formatMessage({ ...messages.confirmDeleteMessage });
      const transConfirmClose = intl.formatMessage({ ...messages.confirmClose });
      const transConfirmDelete = intl.formatMessage({ ...messages.confirmDelete });

        return (
            <Box className={classes.dialogBox}>
                {workLoading && (
                    <Box className={classes.skeletonBox}>
                        <CircularProgress size={40} className={classes.circularProgress} />
                    </Box>
                )}

                <AutoSizer>
                    {({ width, height }) => (
                        <MuiVirtualizedTable
                            data={updateWorkCatalogue}
                            columns={[
                                {
                                    name: "checkbox",
                                    header: (
                                        <Checkbox
                                            size="small"
                                            checked={selectedWorkIds.length > 0}
                                            onChange={() =>
                                                handleSelectedWorkIds(
                                                    selectedWorkIds?.length === workCatalogue?.length
                                                        ? []
                                                        : updateWorkCatalogue?.map((work) => work?.workID)
                                                )
                                            }
                                            {...(selectedWorkIds.length > 0 &&
                                                selectedWorkIds.length !== updateWorkCatalogue?.length && {
                                                    indeterminate: true,
                                                    color: "default",
                                                })}
                                        />
                                    ),
                                    cell: (row) => (
                                        <Checkbox
                                            size="small"
                                            checked={selectedWorkIds?.some((id) => row?.workID === id)}
                                            tabIndex={row?.workID}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "itemNumber",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "itemNumber"}
                                            direction={orderBy === "itemNumber" ? order : Order.ASC}
                                            onClick={handleRequestSort("itemNumber")}
                                        >
                                          {transLabelItemNumber}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="itemNumber"
                                                    value={row?.itemNumber}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.workID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddWork(row)
                                                                : handleUpdateWork(row?.workID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.itemNumber
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "subMenu",
                                    header: "",
                                    cell: (row) => (
                                        <RowSubMenu
                                            isAddMode={row?.isAddMode as boolean}
                                            isUpdateMode={row?.isUpdateMode as boolean}
                                            disabledRevert={!previous[row?.workID]}
                                            row={row}
                                            rowID={row?.workID}
                                            handleAdd={handleAddWork}
                                            handleUpdate={handleUpdateWork}
                                            handleRevert={handleRevertWork}
                                            handleDiscardAdd={handleDiscardAddWork}
                                            handleToggleUpdateMode={handleToggleUpdateMode}
                                            handleDelete={handleDeleteWork}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "productID",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "productID"}
                                            direction={orderBy === "productID" ? order : Order.ASC}
                                            onClick={handleRequestSort("productID")}
                                        >
                                          {transLabelProductId}
                                        </TableSortLabel>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="productID"
                                                    value={row?.productID}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.workID}
                                                    autoFocus={row?.isAddMode}
                                                    ref={row?.isAddMode ? addModeRef : null}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddWork(row)
                                                                : handleUpdateWork(row?.workID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.productID
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "productName",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "productName"}
                                            direction={orderBy === "productName" ? order : Order.ASC}
                                            onClick={handleRequestSort("productName")}
                                        >
                                          {transLabelProductName}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 500 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="productName"
                                                    value={row?.productName}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.workID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddWork(row)
                                                                : handleUpdateWork(row?.workID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.productName
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "liPrice",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "liPrice"}
                                            direction={orderBy === "liPrice" ? order : Order.ASC}
                                            onClick={handleRequestSort("liPrice")}
                                        >
                                          {transLabelListPrice}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="liPrice"
                                                    value={row?.liPrice}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.workID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddWork(row)
                                                                : handleUpdateWork(row?.workID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.liPrice && formatNumber(row?.liPrice)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "ep",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "ep"}
                                            direction={orderBy === "ep" ? order : Order.ASC}
                                            onClick={handleRequestSort("ep")}
                                        >
                                            EP
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="ep"
                                                    value={row?.ep}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.workID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddWork(row)
                                                                : handleUpdateWork(row?.workID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.ep && formatNumber(row?.ep)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "surcharge",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "surcharge"}
                                            direction={orderBy === "surcharge" ? order : Order.ASC}
                                            onClick={handleRequestSort("surcharge")}
                                        >
                                          {transLabelSurcharge}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="surcharge"
                                                    value={row?.surcharge}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.workID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddWork(row)
                                                                : handleUpdateWork(row?.workID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.surcharge && `${formatNumber(row?.surcharge)} %`
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "update",
                                    header: "",
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <>
                                                    <NullableTooltip
                                                        title={
                                                            row?.isAddMode
                                                                ? transTooltipAddNewWork // "Add new work"
                                                                : transTooltipSaveChanges // "Save changes"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="save"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                row?.isAddMode
                                                                    ? handleAddWork(row)
                                                                    : handleUpdateWork(row?.workID);
                                                            }}
                                                            className={clsx(classes.iconButton, classes.iconButtonSave)}
                                                        >
                                                            <Icon name="check" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                    {!row?.isAddMode && (
                                                        <NullableTooltip title={transTooltipToReturn} placement="top">
                                                            {/*Revert*/}
                                                            <IconButton
                                                                size="small"
                                                                aria-label="revert"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleRevertWork(row?.workID);
                                                                }}
                                                                className={clsx(
                                                                    classes.iconButton,
                                                                    classes.iconButtonRevert,
                                                                    {
                                                                        [classes.disabled]: !previous[row?.workID],
                                                                    }
                                                                )}
                                                                disabled={!previous[row?.workID]}
                                                            >
                                                                <Icon name="history" size={18} />
                                                            </IconButton>
                                                        </NullableTooltip>
                                                    )}
                                                    <NullableTooltip
                                                        title={
                                                            row?.isAddMode
                                                                ? transTooltipDiscard // "Discard"
                                                                : transTooltipClose // "Close"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="close"
                                                            onClick={(event) => {
                                                                event.stopPropagation();

                                                                row?.isAddMode
                                                                    ? handleDiscardAddWork(row?.workID)
                                                                    : handleToggleUpdateMode(row?.workID);
                                                            }}
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.iconButtonClose
                                                            )}
                                                        >
                                                            <Icon name="close" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                </>
                                            ) : (
                                                <NullableTooltip title={transTooltipUpdate} placement="top">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="update"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleToggleUpdateMode(row?.workID);
                                                        }}
                                                        className={classes.iconButton}
                                                    >
                                                        <Icon name="pencil-solid" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </>
                                    ),
                                    width: 100,
                                },
                                {
                                    name: "delete",
                                    header: "",
                                    cell: (row) => (
                                        <>
                                            {!row?.isAddMode && (
                                                <NullableTooltip title={transTooltipDelete} placement="top">
                                                    {/*Delete*/}
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleDeleteWork(row);
                                                        }}
                                                        className={clsx(classes.iconButton, classes.iconButtonClose)}
                                                    >
                                                        <Icon name="trash-alt" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </>
                                    ),
                                    width: 50,
                                },
                            ]}
                            width={width}
                            maxHeight={height}
                            includeHeaders={true}
                            fixedRowCount={1}
                            fixedColumnCount={3}
                            rowHeight={defaultProps.rowHeight}
                            isCellSelected={(column, row) => selectedWorkIds?.some((id) => row && row?.workID === id)}
                            onCellClick={(event, { column, rowData, data }) => {
                                const workID = rowData?.workID as number;
                                if (workID) {
                                    handleSelectedWorkIds(
                                        selectedWorkIds?.some((id) => workID === id)
                                            ? selectedWorkIds?.filter((id) => id !== workID)
                                            : [...selectedWorkIds, workID]
                                    );
                                }
                            }}
                            orderBy={orderBy}
                            orderDirection={order}
                            classes={{
                                table: classes.mvtTable,
                                cellHeader: classes.mvtCellHeader,
                                cellContents: classes.mvtCellContents,
                                cellSelected: classes.mvtCellSelected,
                            }}
                        />
                    )}
                </AutoSizer>
                <ConfirmDialog
                    open={confirmOpen}
                    handleClose={handleConfirmClose}
                    handleDelete={handleConfirmDeleteWork}
                    messages={{
                        dialogTitle: transConfirmDeleteTitle, // "Delete confirm"
                        confirmMessage: (
                            <>
                                {transConfirmDeleteMessage}
                                <strong>
                                    {confirmDelete?.productID} - {confirmDelete?.productName}
                                </strong>
                                ?
                            </>
                        ),
                        closeButton: transConfirmClose, // Close
                        deleteButton: transConfirmDelete, // Delete
                    }}
                />
            </Box>
        );
    }
);

export default WorkDialog;
