import { DispatchAction } from "@iolabs/redux-utils";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Vector2, Vector3 } from "three";
import { Issue } from "../../../graphql/generated/graphql";
import {
    onCreateChangeLocationInfo,
    onCreatePushpinPlaced,
    onIssueEdit,
    useIssueCreate,
    useIssueEdit,
    useIssueViewer,
} from "../../../redux/issue";
import { IIssueCreateState, IIssueViewer } from "../../../redux/issue/reducer";
import { onViewableNavigate } from "../../../redux/viewer";
import { getForgeIssueData } from "../../Issues/helpers";
import { IIssueTab } from "../../Issues/type";
import PushpinWrapper from "../../Pushpin/PushpinWrapper/PushpinWrapper";
import { ExternalSystem } from "../type";
import useStyles from "./styles";

export interface IViewerPushpin {
    flag?: string;
    title?: string;
    externalId?: string;
    viewableGuid?: string;
    color: string;
    coords: Vector3;
}

interface IPushpinsInViewerProps {
    viewer: Autodesk.Viewing.Viewer3D;
    isViewerPage?: boolean;
    issuesList?: Issue[];
}

const PushpinsInViewer: React.FC<IPushpinsInViewerProps> = ({ viewer, isViewerPage, issuesList }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const issueCreate: IIssueCreateState = useIssueCreate();
    const issueEdit: Issue|undefined = useIssueEdit();
    const history = useHistory();
    const issueViewer: IIssueViewer = useIssueViewer();

    const [viewerPushpins, setViewerPushpins] = useState<IViewerPushpin[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [draggable, setDraggable] = useState<IViewerPushpin>();

    useEffect(() => {
        if (issueEdit) {
            try {
                const position = issueEdit.issueExternals?.find(
                    (ie) => ie?.externalSystem?.code === ExternalSystem.Forge
                )?.position;
                if (position) {
                    const parsedPosition: any = JSON.parse(position);
                    const objectId = parseInt(parsedPosition.pushpinAttributes.object_id);
                    viewer.select(objectId);
                }
            } catch (e) {
                // ignore
            }
        } else {
            viewer.clearSelection();
        }
    }, [issueEdit]);

    useEffect(() => {
        viewer.clearSelection();
    }, [issueCreate.isModeActive]);

    // re-render pushpins positions on viewer camera change
    useEffect(() => {
        if (viewer && issuesList && issueCreate) {
            viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, renderPushpins);
        }
    }, [viewer, issuesList, issueCreate]);

    useEffect(() => {
        if (viewer && issuesList && issueCreate) {
            renderPushpins();
        }
    }, [viewer, issuesList, issueCreate]);

    const renderPushpins = () => {
        const tempPushpins: IViewerPushpin[] = [];
        const viewerDimensions = viewer.getDimensions();

        if (issuesList && !issueCreate.isModeActive) {
            issuesList?.map((issue) => {
                const position = JSON.parse(issue?.issueExternals?.[0]?.position as string);
                const locationVector = position?.pushpinAttributes?.location as Vector3;
                const clientCoords = viewer.worldToClient(locationVector);

                const externalIssueData = getForgeIssueData(issue);
                const viewableGuid = externalIssueData?.sheetMetadata?.sheetGuid;

                if (isInViewport(clientCoords, viewerDimensions)) {
                    tempPushpins.push({
                        externalId: issue?.issueExternals?.[0]?.externalID as string,
                        viewableGuid,
                        color: issue?.issueStatus?.highlightColor as string,
                        coords: clientCoords,
                    });
                }
            });
        }

        if (issueCreate && issueCreate.coords && issueCreate.isModeActive) {
            const issueCreateCoords = viewer.worldToClient(issueCreate.coords);
            if (isInViewport(issueCreateCoords, viewerDimensions)) {
                tempPushpins.push({
                    flag: "new",
                    color: issueCreate.color,
                    coords: issueCreateCoords,
                });
            }
        }

        setViewerPushpins(tempPushpins);
    };

    /**
     * Get client (screen) coordinates from mouse and drag event
     * @param event
     * @return Vector2
     */
    const getClientCoordsFromEvent = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.DragEvent<HTMLElement>
    ): Vector2 => {
        const clientRect = event?.currentTarget?.getBoundingClientRect();
        return new Vector2(event?.clientX - clientRect?.left, event?.clientY - clientRect?.top);
    };

    /**
     * Get client (screen) coordinates from touch event on mobile devices
     * @param event
     * @return Vector2
     */
    const getClientCoordsFromTouchEvent = (event: React.TouchEvent<HTMLElement>): Vector2 => {
        const clientRect = event.currentTarget.getBoundingClientRect();
        return new Vector2(
            event.changedTouches[0].clientX - clientRect.left,
            event.changedTouches[0].clientY - clientRect.top
        );
    };

    /**
     * Counting correct coordinates from mouse and drag event
     * @param viewerCoords
     */
    const getWorldCoordsFromViewerCoords = (viewerCoords: Vector2): Vector3 => {
        let worldCoords = viewer.clientToWorld(viewerCoords.x, viewerCoords.y);
        if (worldCoords) {
            worldCoords = worldCoords.point;
        } else {
            worldCoords = viewer.impl.intersectGround(viewerCoords.x, viewerCoords.y);
        }
        return worldCoords;
    };

    /**
     * Get intersected object by client coords
     * @param viewerCoords
     */
    const getIntersectedObject = (viewerCoords: Vector2): number => {
        const intersection = viewer.hitTest(viewerCoords.x, viewerCoords.y, false);
        return intersection?.dbId;
    };

    const selectIntersectedObject = (viewerCoords: Vector2): number => {
        const dbId = getIntersectedObject(viewerCoords);
        if (dbId) {
            viewer.select(dbId);
        }
        return dbId;
    };

    const handleCreatePushpinPlaced = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const viewerCoords = getClientCoordsFromEvent(event);
        const worldCoords = getWorldCoordsFromViewerCoords(viewerCoords);
        const dbId = selectIntersectedObject(viewerCoords);
        dispatch(
            onCreatePushpinPlaced({
                coords: worldCoords,
                isPushpinPlaced: true,
            })
        );

        viewer.getProperties(dbId, (data) => {
            let state = viewer.getState();

            try {
                state["globalOffset"] = viewer.model.getData().globalOffset;
            } catch (error) {
                console.warn("Unable to get global offset", error);
            }
            dispatch(
                onCreateChangeLocationInfo({
                    locationInfo: {
                        pushpinAttributes: {
                            location: worldCoords, // The x, y, z coordinates of the pushpin.
                            object_id: dbId,
                            external_id: data.externalId,
                            viewer_state: state,
                        },
                    },
                })
            );
        });
    };

    const handleEdit = (viewerPushpin: IViewerPushpin, isViewerPage?: boolean | undefined) => {
        if (viewerPushpin?.externalId && viewerPushpin?.viewableGuid) {
            dispatch(
                onIssueEdit({
                    issueEditId: viewerPushpin?.externalId as string,
                    issueTab: IIssueTab.Edit,
                    fromViewerPage: isViewerPage,
                })
            );
            dispatch(onViewableNavigate({ guid: viewerPushpin?.viewableGuid as string }));
        }

        if (isViewerPage) {
            history.push(`${issueViewer?.path}/issues`);
        }
    };

    const handleIsDragging = () => {
        setIsDragging(true);
    };

    const isInViewport = (point: Vector3, dimension: any) => {
        return point.x >= 0 && point.x <= 0 + dimension.width && point.y >= 0 && point.y <= 0 + dimension.height;
    };

    return (
        <div
            className={clsx(classes.pushpinBox, {
                [classes.modeActive]: issueCreate.isModeActive,
                [classes.pushpinPlaced]: issueCreate.isPushpinPlaced && !isDragging,
                [classes.isDragging]: isDragging,
            })}
            onClick={async (event) => {
                if (issueCreate.isModeActive && !issueCreate.isPushpinPlaced) {
                    handleCreatePushpinPlaced(event);
                }
            }}
            onMouseMove={(event) => {
                if (issueCreate.isModeActive && !issueCreate.isPushpinPlaced) {
                    const viewerCoords = getClientCoordsFromEvent(event);

                    // @ts-ignore rolloverObject method in not included in forge viewer types
                    // highlighting model object onMouseMove
                    viewer.impl.rolloverObject(viewerCoords.x, viewerCoords.y);
                }
            }}
            onDragOver={(event) => {
                if (issueCreate.isModeActive) {
                    const viewerCoords = getClientCoordsFromEvent(event);

                    // @ts-ignore rolloverObject method in not included in forge viewer types
                    // highlighting model object onDragOver
                    viewer.impl.rolloverObject(viewerCoords.x, viewerCoords.y);

                    // alternative
                    // viewer.renderContext.rolloverObjectId(OBJECT_ID)
                }
                event.preventDefault();
            }}
            onTouchMove={(event) => {
                event.preventDefault();
            }}
            onDrop={async (event) => {
                setIsDragging(false);
                const viewerCoords = getClientCoordsFromEvent(event);
                const worldCoords = getWorldCoordsFromViewerCoords(viewerCoords);
                selectIntersectedObject(viewerCoords);
                dispatch(
                    onCreatePushpinPlaced({
                        coords: worldCoords,
                        isPushpinPlaced: true,
                    })
                );
                dispatch(
                    onCreateChangeLocationInfo({
                        locationInfo: {
                            pushpinAttributes: {
                                location: worldCoords, // The x, y, z coordinates of the pushpin.
                            },
                        },
                    })
                );
            }}
            onTouchEnd={async (event) => {
                setIsDragging(false);

                const viewerCoords = getClientCoordsFromTouchEvent(event);
                const worldCoords = getWorldCoordsFromViewerCoords(viewerCoords);
                selectIntersectedObject(viewerCoords);
                dispatch(
                    onCreatePushpinPlaced({
                        coords: worldCoords,
                        isPushpinPlaced: true,
                    })
                );
                dispatch(
                    onCreateChangeLocationInfo({
                        locationInfo: {
                            pushpinAttributes: {
                                location: worldCoords, // The x, y, z coordinates of the pushpin.
                            },
                        },
                    })
                );
            }}
        >
            <PushpinWrapper
                viewerPushpins={viewerPushpins}
                handleEdit={handleEdit}
                handleDrag={setDraggable}
                handleIsDragging={handleIsDragging}
                isViewerPage={isViewerPage}
            />
        </div>
    );
};

export default PushpinsInViewer;
