import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "300px",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: "absolute",
        left: "20px",
        bottom: "20px",
        zIndex: 9999,
    },
}));

export default useStyles;
