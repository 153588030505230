import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            maxHeight: "82%",
            overflowY: "auto",
            scrollbarWidth: "none",
            transition: "all 0.3s ease-in-out",
            "&::-webkit-scrollbar": {
                width: "5px !important",
                height: "5px !important",
                backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "none !important",
                backgroundColor: "transparent",
                borderRadius: theme.shape.borderRadius * 2,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
                borderRadius: theme.shape.borderRadius * 2,
            },
        },
        scrollActive: {
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
                width: "5px !important",
                height: "5px !important",
                backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "none !important",
                backgroundColor: "transparent",
                borderRadius: theme.shape.borderRadius * 2,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey["500"],
                borderRadius: theme.shape.borderRadius * 2,
            },
        },
        fullHeight: {
            maxHeight: "91%",
        },
        table: {
            height: "100%",
            width: "100%",
        },
        tableHead: {
            "& td, th": {
                height: "52px",
                fontSize: theme.typography.pxToRem(12),
                borderBottom: "none",
                whiteSpace: "nowrap",
            },
        },
        tableHeadShadow: {
            // display: "block",
            // width: "100%",
            // bottom: "-5px",
            // height: "5px",
            // position: "absolute",
            // boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -5px inset !important", // top shadow
            // "-webkit-box-shadow": "rgba(0, 0, 0, 0.2) 0px 5px 5px -5px inset !important", // top shadow
            // "-webkit-appearance": "none",
        },
        tableBody: {
            borderSpacing: "0 15px",
            "& td, th": {
                position: "relative",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                height: "28px",
                padding: "0 6px 0 0",
                backgroundColor: theme.palette.common.white,
                fontSize: theme.typography.pxToRem(12),
            },
            "& td:last-child, th:last-child": {
                paddingRight: "6px",
                textAlign: "right",
            },
        },
        tableRow: {
            cursor: "pointer",
            "&$selected, &$selected:hover": {
                backgroundColor: theme.palette.grey[500],
            },
            scrollMarginTop: "60px",
            "&:hover td, &:hover th": {
              backgroundColor: theme.palette.grey[100],
            }
        },
        separator: {
            "& td, th": {
                height: "16px",
                borderBottom: "none",
            },
        },
        shadowTop: {
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 5px -5px inset !important", // top shadow
            "-webkit-box-shadow": "rgba(0, 0, 0, 0.2) 0px 8px 5px -5px inset !important", // top shadow
            "-webkit-appearance": "none",
        },
        shadowTopBottom: {
            boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 8px 5px -5px inset, rgba(0, 0, 0, 0.2) 0px -3px 5px -5px inset !important", // top and bottom shadow
            "-webkit-box-shadow":
                "rgba(0, 0, 0, 0.2) 0px 8px 5px -5px inset, rgba(0, 0, 0, 0.2) 0px -3px 5px -5px inset !important", // top and bottom shadow
            "-webkit-appearance": "none",
        },
        shadowBottom: {
            boxShadow: "rgba(0, 0, 0, 0.2) 0px -3px 5px -5px inset !important", // bottom shadow
            "-webkit-box-shadow": "rgba(0, 0, 0, 0.2) 0px -3px 5px -5px inset !important", // bottom shadow
            "-webkit-appearance": "none",
        },
        shadowLeft: {
            borderTop: "0 !important",
            width: "3px",
            background: `${theme.palette.common.white} !important`,
            boxShadow: "rgba(0, 0, 0, 0.2) -5px 0 5px -5px inset !important", // right shadow
            "-webkit-box-shadow": "rgba(0, 0, 0, 0.2) -5px 0 5px -5px inset !important", // right shadow
            "-webkit-appearance": "none",
        },
        shadowRight: {
            borderTop: "0 !important",
            width: "3px",
            background: `${theme.palette.common.white} !important`,
            boxShadow: "rgba(0, 0, 0, 0.2) 5px 0 5px -5px inset !important", // left shadow
            "-webkit-box-shadow": "rgba(0, 0, 0, 0.2) 5px 0 5px -5px inset !important", // left shadow
            "-webkit-appearance": "none",
        },
        tableRowHeader: {
            "& td, th": {
                height: "40px",
                borderTop: `1px solid ${theme.palette.text.secondary}`,
                borderBottom: "none",
                color: theme.palette.grey[500],
                fontWeight: 500,
                fontSize: theme.typography.pxToRem(12),
            },
        },
        firstRow: {
            "& td, th": {
                borderBottom: "none",
                fontSize: theme.typography.pxToRem(14),
            },
            "& > td:last-child, th:last-child": {
                textAlign: "right",
            },
        },
        firstLevel: {
            position: "relative",
            "& td, th": {
                height: "66px",
            },
        },
        cellSymbol: {
            width: "18px",
        },
        cellIcon: {},
        cellDesc: {
            width: "220px",
            maxWidth: "220px",
            "& span": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
        expandedRow: {
            "& th, td": {
                borderTop: `1px solid ${theme.palette.text.secondary}`,
                borderBottom: "none",
            },
        },
        expandedButton: {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
            "& svg": {
                fill: theme.palette.text.primary,
            },
        },
        selected: {
            "& td, & th": {
                backgroundColor: `${theme.palette.success.light} !important`,
            },
            "&:hover td, &:hover th": {
                backgroundColor: `#c6e1d9 !important`,
            },
            "& > td:first-child, th:first-child": {
                backgroundColor: `${theme.palette.common.white} !important`,
            },
            "& > td:last-child, th:last-child": {
                backgroundColor: `${theme.palette.common.white} !important`,
            },
        },
        symbol: {
            position: "absolute",
            top: 0,
            bottom: 0,
            paddingRight: "3px",
            borderLeftWidth: "4px",
            borderLeftStyle: "solid",
            height: "100%",
        },
        symbolSuccess: {
            borderLeftColor: theme.palette.success.main,
        },
        symbolWarning: {
            borderLeftColor: theme.palette.warning.main,
        },
        symbolError: {
            borderLeftColor: theme.palette.error.main,
        },
        icon: {
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "32px",
            height: "32px",
            borderRadius: "50px",
            backgroundColor: theme.palette.grey["100"],
        },
        code: {
            color: theme.palette.common.black,
        },
        codeName: {
            color: theme.palette.grey["500"],
        },
        iconButton: {
            "& svg": {
                fontSize: "1rem",
                fill: theme.palette.grey[500],
            },
        },
        skeletonBox: {
            "& span": {
                width: "100%",
                marginBottom: "12px",
                borderRadius: theme.shape.borderRadius,
            },
        },
    })
);

export default useStyles;
