import { defineMessages } from "react-intl";

export default defineMessages({
    name: {
        id: "components.mapping.dialogs.graphicsDialog.name",
        defaultMessage: "Name",
    },
    shading: {
        id: "components.mapping.dialogs.graphicsDialog.shading",
        defaultMessage: "Shading",
    },
    transparency: {
        id: "components.mapping.dialogs.graphicsDialog.transparency",
        defaultMessage: "Transparency",
    },
    cutBackgroundPattern: {
        id: "components.mapping.dialogs.graphicsDialog.cutBackgroundPattern",
        defaultMessage: "Cut background pattern",
    },
    cutBackground: {
        id: "components.mapping.dialogs.graphicsDialog.cutBackground",
        defaultMessage: "Cut background",
    },
    cutForegroundPattern: {
        id: "components.mapping.dialogs.graphicsDialog.cutForegroundPattern",
        defaultMessage: "Cut foreground pattern",
    },
    cutForeground: {
        id: "components.mapping.dialogs.graphicsDialog.cutForeground",
        defaultMessage: "Cut foreground",
    },
    surfaceBackgroundPattern: {
        id: "components.mapping.dialogs.graphicsDialog.surfaceBackgroundPattern",
        defaultMessage: "Surface background pattern",
    },
    surfaceBackground: {
        id: "components.mapping.dialogs.graphicsDialog.surfaceBackground",
        defaultMessage: "Surface background",
    },
    surfaceForegroundPattern: {
        id: "components.mapping.dialogs.graphicsDialog.surfaceForegroundPattern",
        defaultMessage: "Surface foreground pattern",
    },
    surfaceForeground: {
        id: "components.mapping.dialogs.graphicsDialog.surfaceForeground",
        defaultMessage: "Surface foreground",
    },
    newGraphics: {
        id: "components.mapping.dialogs.graphicsDialog.newGraphics",
        defaultMessage: "Add new graphics",
    },
    saveGraphics: {
        id: "components.mapping.dialogs.graphicsDialog.saveGraphics",
        defaultMessage: "Save changes",
    },
    discard: {
        id: "components.mapping.dialogs.graphicsDialog.discard",
        defaultMessage: "Discard",
    },
    close: {
        id: "components.mapping.dialogs.graphicsDialog.close",
        defaultMessage: "Close",
    },
    edit: {
        id: "components.mapping.dialogs.graphicsDialog.edit",
        defaultMessage: "Edit",
    },
    back: {
        id: "components.mapping.dialogs.graphicsDialog.back",
        defaultMessage: "Back",
    },
    delete: {
        id: "components.mapping.dialogs.graphicsDialog.delete",
        defaultMessage: "Delete",
    },
    confirmMessage: {
        id: "components.mapping.dialogs.graphicsDialog.confirmMessage",
        defaultMessage: "Are you sure you want to delete the graphics",
    },
});
