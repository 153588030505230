import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Checkbox, CircularProgress, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { AutoSizer, List } from "react-virtualized";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { IMappingNavigation } from "../../../pages/MappingManagerPage/type";
import ListItemPopover from "../ListItemPopover/ListItemPopover";
import { ListItemPopoverType } from "../ListItemPopover/type";
import useStyles from "./styles";
import MappingLight from "./MappingLight";
import messages from "./messages";
import { useIntl } from "react-intl";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IMasterDataTree {
    checked: number[];
    handleChangeMainView: (mainView: boolean) => void;
    handleToggle: (positionID: number, isLeft: boolean) => void;
    loading: boolean;
    navigation: IMappingNavigation;
    positions: IElementsPosition[];
}

const MasterDataTree: React.FC<IMasterDataTree> = ({
    checked,
    handleChangeMainView,
    handleToggle,
    loading,
    navigation,
    positions,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transNotCalculable = intl.formatMessage({ ...messages.notCalculable });

    function rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it)
    }) {
        return (
            <ListItem
                key={key}
                style={style}
                role="listitem"
                button
                disableRipple
                onClick={() => handleToggle(positions?.[index]?.positionID, true)}
                classes={{ root: classes.listItemRoot }}
            >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <Checkbox
                        checked={checked?.indexOf(positions?.[index]?.positionID) !== -1}
                        size="small"
                        color="secondary"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": positions?.[index]?.code }}
                        classes={{ root: classes.checkboxRoot }}
                    />
                </ListItemIcon>
                <ListItemText
                    id={positions?.[index]?.code}
                    primary={
                        <div className={classes.listItemCustom}>
                            <div
                                className={classes.listItemValue}
                                style={{
                                    paddingLeft: navigation?.showHierarchy
                                        ? theme.spacing(positions?.[index]?.level as number)
                                        : "",
                                    minWidth: navigation?.showHierarchy
                                        ? "130px"
                                        : navigation?.showDerived
                                        ? "90px"
                                        : "90px",
                                }}
                            >
                                {positions?.[index]?.code}
                            </div>

                            <div className={classes.listItemCustomIcons}>
                                {(positions?.[index]?.level as number) >= 4 && (
                                    <>
                                        <MappingLight position={positions?.[index]} />
                                        <NullableTooltip title={transNotCalculable}>
                                            <span>
                                                S
                                            </span>
                                        </NullableTooltip>
                                        <NullableTooltip title={transNotCalculable}>
                                            <span>
                                                P
                                            </span>
                                        </NullableTooltip>
                                    </>
                                )}
                            </div>
                            <div className={classes.listItemDescription}>
                                {positions?.[index]?.description ? (
                                    <NullableTooltip title={positions?.[index]?.description as string}>
                                        <span className={classes.positionName}>{positions?.[index]?.name}</span>
                                    </NullableTooltip>
                                ) : (
                                    <span className={classes.positionName}>{positions?.[index]?.name}</span>
                                )}
                            </div>
                            <div className={classes.listItemPopover}>
                                <ListItemPopover
                                    type={ListItemPopoverType.MASTER}
                                    position={positions?.[index]}
                                    handleChangeMainView={handleChangeMainView}
                                />
                            </div>
                        </div>
                    }
                />
            </ListItem>
        );
    }

    return (
        <Paper className={classes.paper} id="scrollablePaper">
            {loading ? (
                <Box className={classes.loading}>
                    <CircularProgress size={36} className={classes.circularProgress} />
                </Box>
            ) : (
                <AutoSizer disableWidth>
                    {({ height }) => (
                        <List
                            width={1}
                            height={height}
                            rowCount={positions?.length}
                            rowHeight={28}
                            rowRenderer={rowRenderer}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                            }}
                            style={{
                                width: "100%",
                            }}
                            component="div"
                            role="list"
                            classes={{ root: classes.listRoot }}
                        />
                    )}
                </AutoSizer>
            )}
        </Paper>
    );
};

export default MasterDataTree;
