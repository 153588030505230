import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {
    IProjectRequestData,
    ICreateProjectResponseData,
    IProjectListResponseData,
    IProjectOptionsResponseData,
} from "./types";

const projectsApi = new ApiClient("/Projects", config.api.baseUrl);

/**
 * Returns a list of options for project configuration form
 * @param token
 */
export const getProjectOptions = (token: string): Promise<IProjectOptionsResponseData> => {
    ApiClient.setAuthorizationToken(token);

    return projectsApi.get("/options").then((response: AxiosResponse<IProjectOptionsResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Returns a list of projects
 * @param token
 */
export const getProjectList = (token: string, force: boolean = false): Promise<IProjectListResponseData[]> => {
    ApiClient.setAuthorizationToken(token);

    return projectsApi.get("/list", {
        clearCacheEntry: force
    }).then((response: AxiosResponse<IProjectListResponseData[]>) => {
        console.log(response.data);
        return Promise.resolve(response.data);
    });
};

/**
 * Returns synced project
 * @param token
 * @param projectID
 */
export const syncProject = (token: string, projectID: number): Promise<IProjectListResponseData> => {
    ApiClient.setAuthorizationToken(token);

    return projectsApi.get(`/sync/${projectID}`).then((response: AxiosResponse<IProjectListResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Creates projects in system and returns its entity representation.
 * @param token
 * @param data
 */
export const createProject = (
    token: string,
    data: IProjectRequestData
): Promise<ICreateProjectResponseData> => {
    ApiClient.setAuthorizationToken(token);

    return projectsApi.post("/create", data).then((response: AxiosResponse<ICreateProjectResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Updates projects in system and returns its entity representation.
 * @param token
 * @param data
 */
export const updateProject = (
    token: string,
    projectID: number,
    data: IProjectRequestData
): Promise<ICreateProjectResponseData> => {
    ApiClient.setAuthorizationToken(token);

    return projectsApi.post(`/update/${projectID}`, data).then((response: AxiosResponse<ICreateProjectResponseData>) => {
        return Promise.resolve(response.data);
    });
};
