import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultMappingPanelSize = 40;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        columnBox: {
            backgroundColor: theme.palette.primary[50],
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
        },
        emptyBox: {
            backgroundColor: theme.palette.primary[50],
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
        },
        emptyIcon: {
            fill: theme.palette.common.black,
            marginBottom: theme.spacing(1),
        },
    })
);

export default useStyles;
