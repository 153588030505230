import { addNotification, INotification } from "@iolabs/notifier";
import { AnyAction } from "redux";
import { enrichFolderIds, mergeNodesToTreeState, setDataAsLoaded } from "../../components/Tree/node";
import { DataType } from "../../components/Tree/TreeWrapper/TreeWrapper";
import { treeDataManual, treeDataNode } from "../../packages/Api/data/tree/client";
import { ITree, ITreeResponse } from "../../packages/Api/data/tree/types";
import {
    ActionTypes,
    onLoadNodeContentDone,
    onLoadNodeDone,
    onLoadNodeFail,
    onManualDataDone,
    onManualDataFail, setActiveNodeId,
} from "./action";
import { selectData } from "./selector";

const onTreeNodeMiddleware = ({ dispatch, getState }) => (next) => (action: AnyAction) => {
    const resolve = next(action);

    if (action.type === ActionTypes.OnLoadNode) {
        const { token, nodeId, depth, parentNodeType } = action.payload;

        treeDataNode(nodeId, depth, token)
            .then((data: ITree) => {
                let childrenData = data.children;

                if (Array.isArray(childrenData) && !childrenData.length) {
                    const notification: INotification = {
                        variant: "info",
                        // todo localization
                        message: "This folder is empty",
                    };
                    dispatch(addNotification({ notification }));
                }

                let treeData;
                if (parentNodeType === DataType.Project) {
                    // filter root folders
                    // @ts-ignore
                    childrenData = ((childrenData as unknown) as ITree[]).filter((item) => item.text === "Project Files");
                    // skip project files level and directly load all children's files/folders
                    treeData = childrenData?.[0]?.children?.length !== 0 ? childrenData?.[0]?.children : [];
                } else {
                    // select tree state
                    treeData = [...selectData(getState())];
                }

                // set new data as loaded
                childrenData = setDataAsLoaded(childrenData);

                childrenData = enrichFolderIds(
                    (childrenData as unknown) as ITree[],
                    parentNodeType === DataType.Project ? (({ pathIds: [nodeId] } as unknown) as ITree) : undefined
                );
                dispatch(onLoadNodeContentDone((childrenData as unknown) as ITree[]));

                // merge new data from server to specific place in tree
                const mergedTreeData = mergeNodesToTreeState(nodeId, (childrenData as unknown) as ITree, treeData);

                return dispatch(onLoadNodeDone(mergedTreeData));
            })
            .catch((error) => {
                return dispatch(onLoadNodeFail(error));
            });
    }

    if (action.type === ActionTypes.OnManualData) {
        const { token, data } = action.payload;

        treeDataManual(token, data)
            .then((response: ITree) => {
                const items = response.children as ITree[];
                const filteredResponse = items.filter(
                    (item) => item.text === "Project Files"
                );
                const filteredResponseChildren: any =
                    filteredResponse?.[0]?.children !== undefined ? (filteredResponse?.[0]?.children as ITree[]) : [];

                dispatch(setActiveNodeId({activeNodeId: filteredResponse?.[0]?.id}))

                return dispatch(onManualDataDone({ data: filteredResponseChildren }));
            })
            .catch((error) => {
                return dispatch(onManualDataFail(error));
            });
    }

    return resolve;
};

export default [onTreeNodeMiddleware];
