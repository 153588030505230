import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { SpecificationView } from "../../components/Specification/type";
import { IIsolation } from "../../components/Viewer/extensions/Viewing.Extensions.BulkIsolation/Viewing.Extensions.BulkIsolationExtension";

export enum ActionTypes {
    OnChangeView = "specification/ON_CHANGE_VIEW",
    OnShowExtern = "specification/ON_SHOW_EXTERN",
    OnResetExtern = "specification/ON_RESET_EXTERN",
    OnDefaultView = "specification/ON_DEFAULT_VIEW",
    OnResetView = "specification/ON_RESET_VIEW",
    OnBulkIsolationAdd = "specification/ON_BULKISOLATION_ADD",
    OnExpandedRows = "specification/ON_EXPANDED_ROWS",
    OnSelectedRows = "specification/ON_SELECTED_ROWS",
}

export type OnChangeViewOptions = {
    views: SpecificationView[];
};

export type OnShowExternOptions = {
    extern: SpecificationView | null;
};

export type OnResetViewOptions = {
    view: SpecificationView;
};
export type OnBulkIsolationAddOptions = {
    isolations: { [key: number]: { [key: string]: IIsolation } };
};

export type OnExpandedRowsOptions = {
    expandedRows: string[];
};

export type OnSelectedRowsOptions = {
    selectedRows: string[];
};

export const Actions = {
    onChangeView: (options: OnChangeViewOptions) => createAction(ActionTypes.OnChangeView, options),
    onShowExtern: (options: OnShowExternOptions) => createAction(ActionTypes.OnShowExtern, options),
    onResetExtern: () => createAction(ActionTypes.OnResetExtern),
    onDefaultView: () => createAction(ActionTypes.OnDefaultView),
    onResetView: (options: OnResetViewOptions) => createAction(ActionTypes.OnResetView, options),
    onBulkIsolationAdd: (options: OnBulkIsolationAddOptions) => createAction(ActionTypes.OnBulkIsolationAdd, options),
    onExpandedRows: (options: OnExpandedRowsOptions) => createAction(ActionTypes.OnExpandedRows, options),
    onSelectedRows: (options: OnSelectedRowsOptions) => createAction(ActionTypes.OnSelectedRows, options),
};

export function onChangeSpecificationView(options: OnChangeViewOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onChangeView(options));
    };
}

export function onShowSpecificationExtern(options: OnShowExternOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onShowExtern(options));
    };
}

export function onResetSpecificationExtern(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onResetExtern());
    };
}

export function onDefaultSpecificationView(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDefaultView());
    };
}

export function onResetSpecificationView(options: OnResetViewOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onResetView(options));
    };
}

export function onBulkIsolationAdd(options: OnBulkIsolationAddOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onBulkIsolationAdd(options));
    };
}

export function onExpandedRows(options: OnExpandedRowsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onExpandedRows(options));
    };
}

export function onSelectedRows(options: OnSelectedRowsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onSelectedRows(options));
    };
}
