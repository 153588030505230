import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultSwitcherSize } from "../EyeSwitcher/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            position: "relative",
        },
        skeletonBox: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
        },
        skeleton: {
            width: "100%",
            height: "100%",
        },
        gridItem: {
            height: "100%",
        },
        gridItemSplit: {
            height: "50%",
            overflow: "hidden",
            [theme.breakpoints.up("sm")]: {
                height: "100%",
            },
        },
        hidden: {
            visibility: "hidden",
        },
        visible: {
            visibility: "visible",
        },
        resizer: {
            '& [data-type="Resizer"]': {
                display: "none",
            },
        },
        viewSwitcher: {
            position: "absolute",
            textAlign: "center",
            left: `calc(50% - ${defaultSwitcherSize / 2}px)`,
            top: "calc(50% - 59px)",
        },
        viewSwitcherPinnedToTop: {
            top: `calc(0% - ${defaultSwitcherSize / 2}px)`,
        },
    })
);

export default useStyles;
