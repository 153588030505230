import React, { PropsWithChildren } from "react";
import { Box, ButtonBase, InputBaseComponentProps, Popover, Typography } from "@material-ui/core";
import { ColorBox, createColor } from "material-ui-color";
import clsx from "clsx";
import useStyles from "./styles";
import Icon from "../../Icon/Icon";
import { useTheme } from "@material-ui/core/styles";

interface IColorPickerPopoverClassesProps {
    root?: string,
    label?: string,
}

interface IColorPickerPopoverProps extends PropsWithChildren<InputBaseComponentProps> {
    color: string,
    onColorChange?: (color: string) => void,
    classes?: IColorPickerPopoverClassesProps,
    disabled?: boolean
}

/**
 * Get color (black/white) depending on bgColor so it would be clearly seen.
 * @param bgColor
 * @returns {string}
 */
const contrastColor = (color, theme) => {
    if (!color) return "#000000";
    if (!isValid(color)) return theme.palette.error.main;
    const hex   = color.replace(/#/, '');
    const r     = parseInt(hex.substr(0, 2), 16);
    const g     = parseInt(hex.substr(2, 2), 16);
    const b     = parseInt(hex.substr(4, 2), 16);

    // Counting the perceptive luminance - human eye favors green color...
    const luminance = ( 0.299 * r + 0.587 * g + 0.114 * b)/255;

    return luminance > 0.5 ? "#000000" : "#FFFFFF";
}
const isValid = (color) => {
    return /^#[0-9A-F]{6}$/i.test(color)
}

const ColorPickerPopover: React.ElementType<IColorPickerPopoverProps> = ({color, onColorChange, classes, disabled}) => {

    const styles = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box onClick={e => !disabled && e.stopPropagation()}>
            <ButtonBase
                disabled={!!disabled}
                style={{
                    backgroundColor: color,
                    color: contrastColor(color, theme)
                }}
                onClick={handleOpen}
                className={clsx(styles.button, classes?.root)}
            >
                {isValid(color) ? (
                    <Typography className={clsx(styles.label, classes?.label)}>
                        {color}
                    </Typography>
                ) : (
                    <Icon name="exclamation-triangle" size={18} />
                )}

            </ButtonBase>

            {!disabled && (
                <Popover
                    id={'color-popover'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <ColorBox
                        value={color}
                        onChange={(a) => {
                            if (onColorChange) {
                                onColorChange(`#${a.hex}`);
                            }
                        }}
                        disableAlpha={true}
                    />
                </Popover>
            )}
        </Box>
    )
}
export default ColorPickerPopover