import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";

export interface IOverlayProgressProps {
    message?: string;
    isLoading: boolean;
}

const OverlayProgress: React.FC<IOverlayProgressProps> = ({message, isLoading}) => {

    const classes = useStyles();
    return (
        <Box className={clsx(classes.overlay, {[classes.loading]: isLoading})}>
            <Box className={classes.progressWrapper}>
                <CircularProgress size={40} className={classes.progress} />
                {message && <Typography className={classes.message}>{message}</Typography>}
            </Box>

        </Box>
    );
};

export default OverlayProgress;
