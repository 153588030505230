import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultDrawerWidth } from "../Layout/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            width: `${defaultDrawerWidth}px`,
            paddingTop: theme.spacing(2),
        },
        listItemIcon: {
            minWidth: "38px",
        },
        menuIcon: {
            color: theme.palette.text.secondary,
        },
        menuIconActive: {
            color: theme.palette.secondary.main,
        },
        menuText: {
            paddingLeft: theme.spacing(1),
            whiteSpace: "nowrap",
            "& span": {
                lineHeight: "1.2rem",
            },
        },
        listItem: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
            "& a": {
                display: "flex",
                color: theme.palette.text.secondary,
                textDecoration: "none"
            }
        },
        collapseButton: {
            color: theme.palette.text.secondary,
        },
        nested: {
            paddingLeft: theme.spacing(5),
        },
        active: {
            color: theme.palette.common.white,
        },
        divider: {
            backgroundColor: theme.palette.primary.light,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
    })
);

export default useStyles;
