import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import arrayMutators from "final-form-arrays";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { saveMappingProjectAttributes } from "../../../packages/Api/data/mapping/client";
import {
    IDerivedMappingAttribute,
    IMappingAdditionalAttribute,
    IMappingAttribute,
} from "../../../packages/Api/data/mapping/types";
import {
    onMappingPositions,
    setAttributeFormValues,
    useDetailPosition,
    useMappingAttributeFormValues,
    useMappingAttributeLoading,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import useStyles from "./styles";
import { IStepperAttributeFormValues } from "./type";
import messages from "./messages";
import { useIntl } from "react-intl";
import StepAttributeMain from "./StepAttributeMain";
import StepAttributeDerived from "./StepAttributeDerived";
import StepAttributeAdditional from "./StepAttributeAdditional";

interface IStepAttributeProps {
}

const StepAttribute: React.FC<IStepAttributeProps> = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    // attribute
    const loading: boolean = useMappingAttributeLoading();
    const initialFormValues: IStepperAttributeFormValues | undefined = useMappingAttributeFormValues();

    const [saving, setSaving] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);


    // translations
    const intl = useIntl();


    const transButtonSave = intl.formatMessage({ ...messages.buttonSave });
    const transButtonSaved = intl.formatMessage({ ...messages.buttonSaved });
    const transButtonSaving = intl.formatMessage({ ...messages.buttonSaving });


    const validate = (values) => {
        const errors: any = {};
        // if (!values.name) {
        //     errors.name = "Required";
        // }
        return errors;
    };

    // react final form submitting
    const onSubmit = useCallback(async (values) => {
        await handleSave(values);
    }, []);

    const handleSave = async (formValues) => {
        setSaving(true);

        const additionalAttributes: IMappingAdditionalAttribute[] = [];
        formValues?.additionalAttributes &&
            formValues?.additionalAttributes?.map((attr) => {
                additionalAttributes.push({
                    propertySet: attr?.propertySet ? (attr?.propertySet as string) : "",
                    attribute: attr?.attribute ? (attr?.attribute as string) : "",
                    function: attr?.function ? (attr?.function as string) : "",
                    parameter: attr?.parameter ? (attr?.parameter as string) : "",
                    // code: attr?.code ? (attr?.code as string) : "", // TODO: temporarily not sending (Jakub Jirous 2021-03-24 19:36:07)
                    name: attr?.name ? (attr?.name as string) : "",
                    dataType: attr?.dataType ? (attr?.dataType as string) : "",
                });
            });

        const derivedPositionsReferenceUnits: IDerivedMappingAttribute[] = [];
        formValues?.derivedPositionsReferenceUnits &&
        formValues?.derivedPositionsReferenceUnits?.map((attr) => {
            derivedPositionsReferenceUnits.push({
                derivedPositionID: attr?.derivedPositionID,
                propertySet: attr?.propertySet ? (attr?.propertySet as string) : "",
                attribute: attr?.attribute ? (attr?.attribute as string) : "",
                function: attr?.function ? (attr?.function as string) : "",
                parameter: attr?.parameter ? (attr?.parameter as string) : "",
            });
        });


        const newAttribute: IMappingAttribute = {
            level: {
                propertySet: formValues?.level?.propertySet ? (formValues?.level?.propertySet as string) : "",
                attribute: formValues?.level?.attribute ? (formValues?.level?.attribute as string) : "",
                function: formValues?.level?.function ? (formValues?.level?.function as string) : "",
                parameter: formValues?.level?.parameter ? (formValues?.level?.parameter as string) : "",
            },
            referenceUnit: {
                propertySet: formValues?.reference?.propertySet ? (formValues?.reference?.propertySet as string) : "",
                attribute: formValues?.reference?.attribute ? (formValues?.reference?.attribute as string) : "",
                function: formValues?.reference?.function ? (formValues?.reference?.function as string) : "",
                parameter: formValues?.reference?.parameter ? (formValues?.reference?.parameter as string) : "",
            },
            additionalAttributes: additionalAttributes,
            derivedPositionsReferenceUnits: derivedPositionsReferenceUnits,
        };

        await saveMappingProjectAttributes(
            keycloak?.token as string,
            activeProject?.projectID as number,
            detailPosition?.positionID as number,
            newAttribute
        )
            .then((response) => {
                setSaved(true);
                const savedAttributes: IStepperAttributeFormValues = {
                    reference: response?.referenceUnit,
                    level: response?.level,
                    additionalAttributes: response?.additionalAttributes,
                    derivedPositionsReferenceUnits: response?.derivedPositionsReferenceUnits,
                };

                dispatch(setAttributeFormValues({ formValues: savedAttributes }));
                dispatch(
                    onMappingPositions({
                        token: keycloak?.token as string,
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    useEffect(() => {
        if (saved) {
            setTimeout(() => {
                setSaved(false);
            }, 3000);
        }
    }, [saved]);

    const skeleton = (
        <Box className={classes.skeletonBox}>
            <Box>
                <Skeleton variant="rect" height={18} width={100} className={classes.skeleton} />
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
            </Box>
            <Box mt={2.6}>
                <Skeleton variant="rect" height={18} width={80} className={classes.skeleton} />
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
            </Box>
            <Box mt={5}>
                <Skeleton variant="rect" height={18} width={150} className={classes.skeleton} />
                <Skeleton variant="rect" height={30} width="96%" className={classes.skeleton} />
                <Skeleton variant="rect" height={20} width={20} className={classes.skeletonCircle} />
            </Box>
        </Box>
    );

    return (
        <Box className={classes.root}>
            {loading ? (
                skeleton
            ) : (
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialFormValues}
                    validate={validate}
                    mutators={{
                        ...arrayMutators,
                    }}
                    subscription={{ submitting: true }}
                >
                    {({
                        handleSubmit,
                        form: {
                            mutators: { push },
                        },
                        submitting,
                        pristine,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <StepAttributeMain />
                            <StepAttributeDerived />
                            <StepAttributeAdditional values={values} push={push} />
                            <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    disabled={saving || saved}
                                >
                                    {saved ? transButtonSaved : saving ? transButtonSaving : transButtonSave}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Form>
            )}
        </Box>
    );
};

export default StepAttribute;
