import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            flexFlow: "column nowrap",
            height: "100%",
            width: "100%",
            justifyContent: "space-between",
            "& form": {
                width: "100%",
                paddingRight: theme.spacing(1),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
        },
        textField: {
            minWidth: "100px",
            "& input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& textarea": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset > legend > span": {
                paddingLeft: 0,
                paddingRight: "2px",
            },
        },
        controlGroup: {
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
        },
        controlGroupItem: {
            flex: 1,
            marginRight: theme.spacing(1),
            "& > .MuiFormControl-root": {
                width: "100%",
            }
        },
        derivedPosition: {
            marginTop: theme.spacing(1),
        },
        derivedPositionLabel: {
            display: "block",
            marginBottom: theme.spacing(-1),
        },
        fieldset: {
            fontWeight: 400,
        },
        paper: {
            flex: 1,
            flexBasis: "50%",
            margin: theme.spacing(1, 0, 2, 0),
            padding: theme.spacing(1),
            overflow: "auto",
            "& > p, & > div > p": {
                fontWeight: 400,
            },
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                // color: theme.palette.grey[400],
            },
        },
        removeButton: {
            width: "20px",
            height: "20px",
            marginTop: "8px",
        },
        addButton: {
            width: "20px",
            height: "20px",
        },
        skeletonBox: {},
        skeleton: {
            marginBottom: theme.spacing(2.1),
            borderRadius: theme.shape.borderRadius,
        },
        skeletonCircle: {
            marginTop: `-${theme.spacing(1)}`,
            borderRadius: "50%",
        },
    })
);

export default useStyles;
