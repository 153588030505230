import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        marginRight: "1rem",
        display: "flex",
        alignItems: "center"
      }
    })
);

export default useStyles;
