import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IMappingAttribute, IMappingElements, IMappingOperation } from "./types";
import { INormalizationFunction } from "../../../../components/Mapping/StepAttribute/type";

const mappingApi = new ApiClient("/Mapping", config.api.baseUrl);

/**
 * Returns a list of allowed operations for element filtering.
 * @param token
 */
export const getMappingOperations = (token: string): Promise<IMappingOperation[]> => {
    ApiClient.setAuthorizationToken(token);

    return mappingApi.get("/operations").then((response: AxiosResponse<IMappingOperation[]>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Returns a tree of element mapping filters.
 * @param token
 * @param projectID
 * @param positionID
 */
export const getMappingProjectElements = (
    token: string,
    projectID: number,
    positionID: number
): Promise<IMappingElements | null> => {
    ApiClient.setAuthorizationToken(token);

    return mappingApi
        .get(`/${projectID}/${positionID}/elements`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IMappingElements | null>) => {
            return Promise.resolve(response?.status === 200 ? response.data : null);
        });
};

/**
 * Save element mapping filter rules.
 * @param token
 * @param projectID
 * @param positionID
 * @param data
 */
export const saveMappingProjectElements = (
    token: string,
    projectID: number,
    positionID: number,
    data: IMappingElements
): Promise<IMappingElements> => {
    ApiClient.setAuthorizationToken(token);

    return mappingApi
        .post(`/${projectID}/${positionID}/elements`, data)
        .then((response: AxiosResponse<IMappingElements>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Returns a list of attributes mapping for specific project and position.
 * @param token
 * @param projectID
 * @param positionID
 */
export const getMappingProjectAttributes = (
    token: string,
    projectID: number,
    positionID: number
): Promise<IMappingAttribute> => {
    ApiClient.setAuthorizationToken(token);

    return mappingApi
        .get(`/${projectID}/${positionID}/attributes`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IMappingAttribute>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Returns a list of attributes mapping for specific project and position.
 * @param token
 * @param projectID
 * @param positionID
 */
export const getNormalizationFunctions = (
    token: string,
): Promise<INormalizationFunction[]> => {
    ApiClient.setAuthorizationToken(token);

    return mappingApi
        .get(`/functions`)
        .then((response: AxiosResponse<INormalizationFunction[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Save attributes mapping for specific project and position.
 * @param token
 * @param projectID
 * @param positionID
 * @param data
 */
export const saveMappingProjectAttributes = (
    token: string,
    projectID: number,
    positionID: number,
    data: IMappingAttribute
): Promise<IMappingAttribute> => {
    ApiClient.setAuthorizationToken(token);

    return mappingApi
        .post(`/${projectID}/${positionID}/attributes`, data)
        .then((response: AxiosResponse<IMappingAttribute>) => {
            return Promise.resolve(response.data);
        });
};
