import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import { defaultDocumentToolbarHeight } from "../../../pages/DocumentPage/styles";

const useDataTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        tableContainer: {
            maxHeight: "30vh",
            height: "100%",
            [theme.breakpoints.up("md")]: {
                maxHeight: `calc(78vh - ${defaultDocumentToolbarHeight}px)`,
            },
        },
        table: {
            ...theme.typography.body2,
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        toolbarHighlight: {
            color: theme.palette.common.white,
            backgroundColor: lighten(theme.palette.success.dark, 0.15),
        },
        toolbarTitle: {
            flex: "1 1 100%",
        },
        tableHead: {},
        tableHeadRow: {
            whiteSpace: "nowrap",
        },
        tableHeadCell: {
            backgroundColor: theme.palette.common.white,
        },
        tableHeadLabel: {
            height: "40px",
            color: theme.palette.grey["500"],
            "&:hover": {
                color: theme.palette.common.black,
            },
        },
        tableRow: {
            whiteSpace: "nowrap",
        },
        tableRowSelected: {
            backgroundColor: `${lighten(theme.palette.success.light, 0.15)} !important`,
        },
        tableCell: {
            borderBottom: "none",
        },
        tableRowEmpty: {
            borderBottom: "none",
        },
        tableCellEmptyTop: {
            borderBottom: "none",
            paddingTop: "20%",
        },
        tableCellEmptyBottom: {
            borderBottom: "none",
            [theme.breakpoints.down("md")]: {
                paddingBottom: "20%",
            },
        },
        ionRouterLink: {
            color: theme.palette.common.black,
            cursor: "pointer",
        },
        icon: {
            fontSize: "1.3rem",
            fill: theme.palette.primary.main,
            marginRight: "0.6rem",
            verticalAlign: "middle",
        },
    })
);

export default useDataTableStyles;
