import { ToggleButton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import LongPressable from "react-longpressable";
import Icon from "../Icon/Icon";
import { IIcon } from "../Icon/IconSet";
import useStyles from "./styles";

export enum ButtonType {
    TOP_LEFT = "topLeft",
    TOP_RIGHT = "topRight",
    BOTTOM_LEFT = "bottomLeft",
    BOTTOM_RIGHT = "bottomRight",
    BOTTOM_FULL = "bottomFull",
}

interface IViewSwitcherButtonProps {
    buttonType: ButtonType;
    valueActive: boolean;
    valueIcon: IIcon;
    externActive?: boolean;
    externIcon: IIcon;
    onShortPress: () => void;
    onExternPress?: () => void;
}

const longPressTime = 700; // ms;

const EyeSwitcherButton: React.FC<IViewSwitcherButtonProps> = ({
    buttonType,
    valueActive,
    valueIcon,
    externActive,
    externIcon,
    onShortPress,
    onExternPress
}) => {
    const classes = useStyles();

    const [externShown, setExternShown] = useState<boolean>(false);

    return (
        <>
            {(externShown || externActive) && (
                <ToggleButton
                    value="toggle-extern"
                    onClick={() => {
                        setExternShown(false);
                        if(onExternPress) {
                            onExternPress();
                        }
                    }}
                    className={clsx(classes.button, classes.buttonExtern, {
                        [buttonType]: true,
                        extern: true,
                        active: externActive,
                    })}
                >
                    <Icon name={externIcon} size={14} />
                </ToggleButton>
            )}
            <LongPressable
                onShortPress={() => {
                    setExternShown(false);
                    onShortPress();
                }}
                onLongPress={() => {
                    setExternShown(true)
                }}
                longPressTime={longPressTime}
            >
                <ToggleButton
                    value="toggle-intern"
                    className={clsx(classes.button, {
                        [buttonType]: true,
                        active: valueActive,
                    })}
                >
                    <Icon name={valueIcon} size={14} />
                </ToggleButton>
            </LongPressable>
        </>
    );
};

export default EyeSwitcherButton;
