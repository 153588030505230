/**
 * Query builder autocomplete
 */
export interface IAutocomplete {
    id: number;
    label: string;
}

/**
 * Query builder operator
 */
export interface IQueryOperator {
    label: string;
    value: string;
    types: string[];
}

/**
 * Query builder query
 */
export interface IQuery {
    combinator: IQueryCombinator;
    rules: (IQuery | IQueryField)[];
}

export enum IQueryCombinator {
    AND = "and",
    OR = "or",
}

export interface IQueryField {
    field: {
        pset: string | null;
        attribute: string | null;
    };
    operator: string | null;
    value: string | null;
}
