import { createSelector } from "reselect";
import { ITreeModuleState, ITreeState } from "./reducer";

export function selectTreeState(state: ITreeModuleState): ITreeState {
    return state.treeState;
}
export const selectLoading = createSelector(selectTreeState, (state) => state.loading);
export const selectData = createSelector(selectTreeState, (state) => state.data);
export const selectContentData = createSelector(selectTreeState, (state) => state.contentData);
export const selectActiveNodeId = createSelector(selectTreeState, (state) => state.activeNodeId);
export const selectExpandedNodes = createSelector(selectTreeState, (state) => state.expandedNodes);
