import { PropertyHelper } from "./propertyHelper";
import { ModelHelper } from "./modelHelper";

export class IdMapping {
    public uuid3dToForgeIdMap: { [key: string]: number[] } = {};

    public uuid2dToForgeIdMap: { [key: string]: number[] } = {};
}

export class ThemingHelper {
    public static async getIdMapping(model: Autodesk.Viewing.Model): Promise<{ [key: string]: number[] }> {
        const ids: number[] = ModelHelper.getAllModelIds(model);

        console.debug("All IDS taken from model", ids);

        return await PropertyHelper.getMappingIds(model, ids);
    }
}
