import { onError } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { CategoryReport, useCostsPerCategoriesQuery } from "../../../graphql/generated/graphql";
import {
    onBulkIsolationAdd,
    onExpandedRows,
    onSelectedRows,
    useExpandedRows,
    useSelectedRows,
} from "../../../redux/specification";
import { setObjectsToContext, useObjectsInContextViewerIds } from "../../../redux/viewer";
import globalMessages from "../../App/messages";
import DataTable from "../DataTable/DataTable";
import DataTablePanel from "../DataTablePanel/DataTablePanel";
import DataTableTotal from "../DataTableTotal/DataTableTotal";
import DialogPrice from "../DialogPrice/DialogPrice";

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            height: "100%",
            position: "relative",
        },
    })
);

interface IDataTableWrapperProps {
    projectFileVersionID: number;
    fullHeight: boolean;
}

const DataTableWrapper: React.FC<IDataTableWrapperProps> = ({ projectFileVersionID, fullHeight }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const selectedObjectsViewerIds = useObjectsInContextViewerIds();
    const expandedRows: string[] = useExpandedRows();
    const selectedRows: string[] = useSelectedRows();

    const [guids, setGuids] = useState<{ [key: number]: any[] }>([]);
    const [guidToCategoryMap, setGuidToCategoryMap] = useState<{ [key: number]: string[] }>({});
    const [openPriceDialog, setOpenPriceDialog] = useState<boolean>(false);
    const [openNewPositionDialog, setOpenNewPositionDialog] = useState<boolean>(false);

    const isExpanded = useCallback(
        (id: string) => (Array.isArray(expandedRows) ? expandedRows.indexOf(id) !== -1 : false),
        [expandedRows]
    );

    // translations
    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });

    const { data, loading, error } = useCostsPerCategoriesQuery({
        variables: {
            projectFileVersionId: projectFileVersionID,
        },
    });

    if (error) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            })
        );
    }

    useEffect(() => {
        getSelectedIds();
    }, [selectedObjectsViewerIds]);

    useEffect(() => {
        if (data) {
            const map: { [key: number]: string[] } = {};
            // create map guid -> categories
            data?.costsPerCategories?.categories?.forEach((category) => {
                mapCategory(category as CategoryReport, map);
            });
            setGuidToCategoryMap(map);
        }
        return () => {
            setGuidToCategoryMap({});
        };
    }, [data]);

    const mapCategory = (category: CategoryReport, map: { [key: number]: string[] }) => {
        const childrenGuids = getChildrenGuids(category);

        // todo smarter
        if (category.elementCategory?.code === "C") {
            const isolations = {};
            Object.keys(childrenGuids).forEach((viewableId) => {
                isolations[viewableId] = {
                    "isolate-kg": {
                        title: `Isolate ${category.elementCategory?.code} - ${category.elementCategory?.name}`,
                        viewerIds: childrenGuids[viewableId].map((id) => parseInt(id)),
                    },
                };
            });
            dispatch(
                onBulkIsolationAdd({
                    isolations,
                })
            );
        }
        Object.values(childrenGuids).forEach((guids) => {
            guids.forEach((guid) => {
                if (!map[guid]) {
                    map[guid] = [];
                }
                map[guid].push(getRowIdString(category));
            });
        });
        category.children?.forEach((childCategory) => {
            mapCategory(childCategory as CategoryReport, map);
        });
    };

    const handleOpenPriceDialog = () => {
        setOpenPriceDialog(true);
    };

    const handleClosePriceDialog = () => {
        setOpenPriceDialog(false);
    };

    const handleOpenNewPositionDialog = () => {
        setOpenNewPositionDialog(true);
    };

    const handleCloseNewPositionDialog = () => {
        setOpenNewPositionDialog(false);
    };

    const handleExpand = (value: string) => {
        let newExpanded;
        if (expandedRows.indexOf(value) !== -1) {
            newExpanded = expandedRows.filter((id) => id !== value);
        } else {
            newExpanded = [value, ...expandedRows];
        }
        dispatch(onExpandedRows({ expandedRows: newExpanded }));
    };

    const handleSelect = (row: CategoryReport) => {
        let newGuids: { [key: number]: any[] } = {};
        let newSelectedRows: string[] = [];

        // selecting and deselecting objects in Viewer
        newGuids = getChildrenGuids(row, newGuids);

        // highlighting and un-highlighting rows in table
        newSelectedRows = getChildrenRows(row, newSelectedRows);
        if (newSelectedRows) {
            newSelectedRows = newSelectedRows.flat(Infinity);
            newSelectedRows = Array.from(new Set(newSelectedRows));

            if (selectedRows.toString() === newSelectedRows.toString()) {
                setGuids({});
                dispatch(setObjectsToContext({ objects: {}, objectsSmart: [] }));
                dispatch(onSelectedRows({ selectedRows: [] }));
            } else {
                setGuids(newGuids);
                dispatch(setObjectsToContext({ objects: newGuids, objectsSmart: [] }));
                dispatch(onSelectedRows({ selectedRows: newSelectedRows }));
            }
        }
    };

    const handleSelectAndExpand = (row: CategoryReport) => {
        handleExpand((row?.elementCategory?.elementCategoryID as number).toString());
        handleSelect(row);
    };

    const getChildrenGuids = (row: CategoryReport, newGuids: { [key: number]: any[] } = {}) => {
        const ids: { [key: number]: any[] } = {};
        row.instancesPerFileVersionViewable?.forEach((instancesPerViewable) => {
            const viewableId = instancesPerViewable?.viewable?.projectFileVersionViewableID as number;
            ids[viewableId] = [];
            instancesPerViewable?.instances?.forEach((instance) => {
                ids[viewableId].push(parseInt(instance as string));
            });
        });
        return ids;
    };

    const getChildrenRows = (row: CategoryReport, newSelectedRows: string[]) => {
        const rowId = getRowIdString(row);
        newSelectedRows = [rowId, ...newSelectedRows];
        if (row.children && row.children.length > 0) {
            return row.children.map((row) => getChildrenRows(row as any, newSelectedRows));
        }
        return newSelectedRows;
    };

    const getRowIdString = (row: CategoryReport): string => {
        return getRowId(row).toString() as string;
    };
    const getRowId = (row: CategoryReport): number => {
        return row.elementCategory?.elementCategoryID as number;
    };

    const getSelectedIds = () => {
        let selected;
        if (selectedObjectsViewerIds) {
            selectedObjectsViewerIds?.forEach((guid) => {
                const guidCategories = guidToCategoryMap[guid];
                if (guidCategories) {
                    selected = guidCategories;
                }
            });
        } else {
            selected = selectedRows;
        }

        return selected;
    };

    return (
        <Box className={classes.box}>
            <DataTablePanel />
            <DataTable
                data={data}
                loading={loading}
                error={error}
                ids={getSelectedIds()}
                isExpanded={isExpanded}
                getRowId={getRowIdString}
                handleSelect={handleSelect}
                handleExpand={handleExpand}
                handleSelectAndExpand={handleSelectAndExpand}
                handleOpenPriceDialog={handleOpenPriceDialog}
                handleClosePriceDialog={handleClosePriceDialog}
                fullHeight={fullHeight}
            />
            <DataTableTotal data={data} loading={loading} error={error} />

            <DialogPrice
                open={openPriceDialog}
                handleOpen={handleOpenPriceDialog}
                handleClose={handleClosePriceDialog}
            />
        </Box>
    );
};

export default DataTableWrapper;
