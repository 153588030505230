import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultSwitcherSize = 100;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            display: "inline-flex",
            flexFlow: "row wrap",
            width: `${defaultSwitcherSize}px`,
            height: `${defaultSwitcherSize}px`,
            borderRadius: "50px",
            background: theme.palette.grey["100"],
            "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
                marginLeft: 0,
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
            },
        },
        eye: {
            position: "absolute",
            width: `calc(${defaultSwitcherSize}px / 2.2)`,
            height: `calc(${defaultSwitcherSize}px / 2.2)`,
            borderRadius: "50% !important",
            background: theme.palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            zIndex: 9999,
            border: `2px solid ${theme.palette.secondary["50"]}`,
            cursor: "pointer",
            "& svg": {
                fill: theme.palette.text.secondary,
            },
            "&:hover, &:focus": {
                backgroundColor: darken(theme.palette.common.white, 0.05),
            },
        },
    })
);

export default useStyles;
