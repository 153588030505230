import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            paddingTop: "calc(100% - 62px)",
            position: "relative",
            outline: "none",
        },
        iconBox: {
            width: "10rem",
            height: "10rem",
            background: theme.palette.grey["50"],
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all .2s ease-in-out",
            margin: "auto",
            [theme.breakpoints.up("sm")]: {
                margin: 0,
                marginTop: "95px",
            },
        },
        dragActive: {
            transform: "scale(1.2)",
        },
        dropzone: {
            outline: "none",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column nowrap",
            },
        },
        message: {
            fontSize: "1rem",
            marginTop: theme.spacing(4),
        },
        button: {
            marginTop: theme.spacing(2),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            "&:hover": {
                backgroundColor: darken(theme.palette.success.dark, 0.1),
            },
        },
    })
);

export default useStyles;
