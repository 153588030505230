import { defineMessages } from "react-intl";

export default defineMessages({
    dialogTitle: {
        id: "components.mapping.positionDialogForm.title",
        defaultMessage: "Add new BKP-H position",
    },
    dialogTitleDerived: {
        id: "components.mapping.positionDialogForm.titleDerived",
        defaultMessage: "Add new derived position",
    },
    dialogTitleBasic: {
        id: "components.mapping.positionDialogForm.titleBasic",
        defaultMessage: "Add new basic position",
    },
    movedPositionCode: {
        id: "components.mapping.positionDialogForm.movedPositionCode",
        defaultMessage: "New position code",
    },
    selectNewParent: {
        id: "components.mapping.positionDialogForm.selectNewParent",
        defaultMessage: "Select parent position",
    },
    dialogTitleMove: {
        id: "components.mapping.positionDialogForm.dialogTitleMove",
        defaultMessage: "Move custom position",
    },
    nonUniqueCode: {
        id: "components.mapping.positionDialogForm.nonUniqueCode",
        defaultMessage: "New code is not unique",
    },
    parentSelf: {
        id: "components.mapping.positionDialogForm.parentSelf",
        defaultMessage: "Can't move the position to itself, select new parent",
    },
    movedSuccessfully: {
        id: "components.mapping.positionDialogForm.movedSuccessfully",
        defaultMessage: "Custom position was moved successfully",
    },
    dialogEditTitle: {
        id: "components.mapping.positionDialogForm.editTitle",
        defaultMessage: "Edit custom position",
    },
    dialogEditCustomTitle: {
        id: "components.mapping.positionDialogForm.editCustomTitle",
        defaultMessage: "Edit position",
    },
    // buttons
    buttonAddPosition: {
        id: "components.mapping.positionDialogForm.buttonAddPosition",
        defaultMessage: "Add position",
    },
    buttonBack: {
        id: "components.mapping.positionDialogForm.buttonBack",
        defaultMessage: "Back",
    },
    buttonAdd: {
        id: "components.mapping.positionDialogForm.buttonAdd",
        defaultMessage: "Add",
    },
    buttonMove: {
        id: "components.mapping.positionDialogForm.buttonMove",
        defaultMessage: "Move",
    },
    buttonSaving: {
        id: "components.mapping.positionDialogForm.buttonSaving",
        defaultMessage: "Saving ...",
    },
    // pbs
    pbOne: {
        id: "components.mapping.positionDialogForm.positionBoxOne",
        defaultMessage: "Position 1",
    },
    pbTwo: {
        id: "components.mapping.positionDialogForm.positionBoxTwo",
        defaultMessage: "Position 2",
    },
    pbThree: {
        id: "components.mapping.positionDialogForm.positionBoxThree",
        defaultMessage: "Position 3",
    },
    pbFour: {
        id: "components.mapping.positionDialogForm.positionBoxFour",
        defaultMessage: "Position 4",
    },
    pbFive: {
        id: "components.mapping.positionDialogForm.positionBoxFive",
        defaultMessage: "Position 5",
    },
    // fields
    fieldSearch: {
        id: "components.mapping.positionDialogForm.fieldSearch",
        defaultMessage: "e BKP-H code search",
    },
    fieldSpecifyValue: {
        id: "components.mapping.positionDialogForm.fieldSpecifyValue",
        defaultMessage: "Specify value",
    },
    errorCode: {
        id: "components.mapping.positionDialogForm.errorCode",
        defaultMessage: "Please enter a position code",
    },
    errorName: {
        id: "components.mapping.positionDialogForm.errorName",
        defaultMessage: "Please enter a position name",
    },
    errorParentPositionID: {
        id: "components.mapping.positionDialogForm.errorParentPositionID",
        defaultMessage: "Please select parent position",
    },
});
