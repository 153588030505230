import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IViewerProxyResponse } from "./types";

const forgeApi = new ApiClient("/forge/datamanagement", config.api.baseUrl);

export const viewerProxy = (
    token: string,
    urn: string,
    projectId: string,
    fetchLatest = true
): Promise<IViewerProxyResponse> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/viewerfileinfo/${encodeURIComponent(urn)}?projectId=${projectId}&fetchLatest=${fetchLatest}`;

    return forgeApi.get(url).then((response: AxiosResponse<IViewerProxyResponse>) => {
        return Promise.resolve(response.data);
    });
};

export const viewerToken = (
    token: string
): Promise<string> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/ViewerToken`;

    return forgeApi.get(url).then((response: AxiosResponse<string>) => {
        return Promise.resolve(response.data);
    });
};
