import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { IconButton, InputBase, Paper } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { renameFile } from "../../../packages/Api/data/datamanagement/client";
import { onLoadNode, useActiveNodeId } from "../../../redux/tree";
import Icon from "../../Icon/Icon";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import messages from "./messages";
import useStyles from "./styles";

interface IDocumentInlineEditProps {
    item: Data;
    handleClose: () => void;
}

const DocumentInlineEdit: React.FC<IDocumentInlineEditProps> = ({ item, handleClose }) => {
    const classes = useStyles();
    const activeNodeId = useActiveNodeId();
    const dispatch = useDispatch<DispatchAction>();
    const [newName, setNewName] = useState<string>("");

    const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.target.select();
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewName(event.target.value);
    };

    // translations
    const intl = useIntl();
    const transFolder = intl.formatMessage({ ...messages.folder });
    const transDocument = intl.formatMessage({ ...messages.document });
    const transError = intl.formatMessage({ ...messages.error });

    const handleEdit = (item: Data) => {
        handleClose();

        renameFile(item.nodeId, newName)
            .then(() => {
                const notification: INotification = {
                    variant: "success",
                    message: item.isDir ? transFolder : transDocument,
                };
                dispatch(addNotification({ notification }));
                dispatch(onLoadNode({ nodeId: activeNodeId, depth: 1 }));
            })
            .catch(() => {
                const notification: INotification = {
                    variant: "error",
                    message: transError,
                };
                dispatch(addNotification({ notification }));
            });
    };

    return (
        <Paper className={classes.root}>
            <InputBase
                className={classes.input}
                defaultValue={item.name}
                inputProps={{ "aria-label": "rename table item" }}
                autoFocus
                onFocus={handleFocus}
                onChange={handleChangeInput}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        handleEdit(item);
                    }
                    if (event.key === "Escape") {
                        handleClose();
                    }
                }}
            />
            <IconButton color="secondary" className={classes.button} aria-label="cancel" onClick={handleClose}>
                <Icon name="close" size={20} />
            </IconButton>
            <IconButton
                className={clsx(classes.button, classes.buttonSave)}
                aria-label="save"
                onClick={() => handleEdit(item)}
            >
                <Icon name="check" size={20} />
            </IconButton>
        </Paper>
    );
};

export default DocumentInlineEdit;
