import { IModule } from "redux-dynamic-modules";
import { IIssueModuleState, reducer } from "./reducer";
import middlewares from "./middleware";

export const IssueModule: IModule<IIssueModuleState> = {
    id: "issue-module",
    reducerMap: {
        issueState: reducer,
    },
    middlewares: middlewares,
};
