import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultDocumentToolbarHeight } from "../../../pages/DocumentPage/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            textAlign: "center",
            minHeight: `${defaultDocumentToolbarHeight}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        buttonGroup: {
            margin: theme.spacing(0, 1),
            [theme.breakpoints.up("md")]: {
                width: "140px",
            },
            "& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
                borderRightColor: theme.palette.common.white,
            },
        },
        button: {
            height: "39px",
            padding: theme.spacing(1),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            cursor: "pointer",
            "&:hover": {
                backgroundColor: darken(theme.palette.success.dark, 0.1),
            },
        },
        buttonText: {
            flex: 1,
        },
        icon: {
            fill: theme.palette.common.white,
        },
        popper: {
            zIndex: 10,
        },
        itemIcon: {
            color: theme.palette.common.black,
            minWidth: "35px",
        },
    })
);

export default useStyles;
