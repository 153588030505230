import { defineMessages } from "react-intl";

export default defineMessages({
    rename: {
        id: "components.documentSubMenu.rename",
        defaultMessage: "Rename", // Umbenennen
    },
    delete: {
        id: "components.documentSubMenu.delete",
        defaultMessage: "Delete", // Löschen
    },
    update: {
        id: "components.documentSubMenu.update",
        defaultMessage: "Update",
    },
    discard: {
        id: "components.documentSubMenu.discard",
        defaultMessage: "Discard",
    },
    close: {
        id: "components.documentSubMenu.close",
        defaultMessage: "Close",
    },
    revert: {
        id: "components.documentSubMenu.revert",
        defaultMessage: "Revert",
    },
    addNew: {
        id: "components.documentSubMenu.addNew",
        defaultMessage: "Add new",
    },
    saveChanges: {
        id: "components.documentSubMenu.saveChanges",
        defaultMessage: "Save changes",
    },
});
