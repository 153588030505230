import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        skeleton: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        skeleton10: {
            marginLeft: theme.spacing(2),
        },
        skeleton20: {
            marginLeft: theme.spacing(4),
        },
        skeleton30: {
            marginLeft: theme.spacing(6),
        },
        emptyProject: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& p": {
                marginTop: theme.spacing(1),
            },
        },
    })
);

export default useStyles;
