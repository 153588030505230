export enum ThumbStatus {
    SUCCESS = 200,
    LATER = 202,
    ERROR = 404,
}

/**
 * Supported types for thumbnails
 * https://forge.autodesk.com/en/docs/model-derivative/v2/developers_guide/supported-translations/
 */
export enum ThumbFormat {
    A = "a",
    ASM = "asm",
    BRD = "brd",
    CATPART = "catpart",
    CATPRODUCT = "catproduct",
    CGR = "cgr",
    COLLABORATION = "collaboration",
    DAE = "dae",
    DGN = "dgn",
    DLV3 = "dlv3",
    DM3 = "3dm",
    DS3 = "3ds",
    DWF = "dwf",
    DWFX = "dwfx",
    DWG = "dwg",
    DWT = "dwt",
    DXF = "dxf",
    EMODE = "emode",
    EXP = "exp",
    F2D = "f2d",
    F3D = "f3d",
    FBX = "fbx",
    G = "g",
    GBXML = "gbxml",
    GLB = "glb",
    GLTF = "gltf",
    IAM = "iam",
    IDW = "idw",
    IFC = "ifc",
    IGE = "ige",
    IGES = "iges",
    IGS = "igs",
    IPT = "ipt",
    IWM = "iwm",
    JT = "jt",
    MAX = "max",
    MODEL = "model",
    MPF = "mpf",
    MSR = "msr",
    NEU = "neu",
    NWC = "nwc",
    NWD = "nwd",
    OBJ = "obj",
    PAR = "par",
    PDF = "pdf",
    PMLPRJ = "pmlprj",
    PMLPRJZ = "pmlprjz",
    PRT = "prt",
    PSM = "psm",
    PSMODEL = "psmodel",
    RCP = "rcp",
    RVT = "rvt",
    SAB = "sab",
    SAT = "sat",
    SCH = "sch",
    SESSION = "session",
    SKP = "skp",
    SLDASM = "sldasm",
    SLDPRT = "sldprt",
    STE = "ste",
    STEP = "step",
    STL = "stl",
    STLA = "stla",
    STLB = "stlb",
    STP = "stp",
    STPZ = "stpz",
    VUE = "vue",
    WIRE = "wire",
    X_B = "x_b",
    X_T = "x_t",
    XAS = "xas",
    XPR = "xpr",
    ZIP = "zip",
    // bonus
    AI = "ai",
    BMP = "bmp",
    DOC = "doc",
    DOCX = "docx",
    JPEG = "jpeg",
    JPG = "jpg",
    PNG = "png",
    HEIC = "heic",
    PPT = "ppt",
    PPTX = "pptx",
    PSD = "psd",
    XLS = "xls",
    XLSX = "xlsx",
    // not-available
    MP3 = "mp3",
    M3U = "m3u",
    WAV = "wav",
    MP4 = "mp4",
    MOV = "mov",
    CSV = "csv",
}

export const availableThumbFormats: ThumbFormat[] = [
    ThumbFormat.A,
    ThumbFormat.ASM,
    ThumbFormat.BRD,
    ThumbFormat.CATPART,
    ThumbFormat.CATPRODUCT,
    ThumbFormat.CGR,
    ThumbFormat.COLLABORATION,
    ThumbFormat.DAE,
    ThumbFormat.DGN,
    ThumbFormat.DLV3,
    ThumbFormat.DM3,
    ThumbFormat.DS3,
    ThumbFormat.DWF,
    ThumbFormat.DWFX,
    ThumbFormat.DWG,
    ThumbFormat.DWT,
    ThumbFormat.DXF,
    ThumbFormat.EMODE,
    ThumbFormat.EXP,
    ThumbFormat.F2D,
    ThumbFormat.F3D,
    ThumbFormat.FBX,
    ThumbFormat.G,
    ThumbFormat.GBXML,
    ThumbFormat.GLB,
    ThumbFormat.GLTF,
    ThumbFormat.IAM,
    ThumbFormat.IDW,
    ThumbFormat.IFC,
    ThumbFormat.IGE,
    ThumbFormat.IGES,
    ThumbFormat.IGS,
    ThumbFormat.IPT,
    ThumbFormat.IWM,
    ThumbFormat.JT,
    ThumbFormat.MAX,
    ThumbFormat.MODEL,
    ThumbFormat.MPF,
    ThumbFormat.MSR,
    ThumbFormat.NEU,
    ThumbFormat.NWC,
    ThumbFormat.NWD,
    ThumbFormat.OBJ,
    ThumbFormat.PAR,
    ThumbFormat.PDF,
    ThumbFormat.PMLPRJ,
    ThumbFormat.PMLPRJZ,
    ThumbFormat.PRT,
    ThumbFormat.PSM,
    ThumbFormat.PSMODEL,
    ThumbFormat.RCP,
    ThumbFormat.RVT,
    ThumbFormat.SAB,
    ThumbFormat.SAT,
    ThumbFormat.SCH,
    ThumbFormat.SESSION,
    ThumbFormat.SKP,
    ThumbFormat.SLDASM,
    ThumbFormat.SLDPRT,
    ThumbFormat.STE,
    ThumbFormat.STEP,
    ThumbFormat.STL,
    ThumbFormat.STLA,
    ThumbFormat.STLB,
    ThumbFormat.STP,
    ThumbFormat.STPZ,
    ThumbFormat.VUE,
    ThumbFormat.WIRE,
    ThumbFormat.X_B,
    ThumbFormat.X_T,
    ThumbFormat.XAS,
    ThumbFormat.XPR,
    ThumbFormat.ZIP,
    // bonus
    ThumbFormat.AI,
    ThumbFormat.BMP,
    ThumbFormat.DOC,
    ThumbFormat.DOCX,
    ThumbFormat.JPEG,
    ThumbFormat.JPG,
    ThumbFormat.PNG,
    ThumbFormat.PPT,
    ThumbFormat.PPTX,
    ThumbFormat.PSD,
    ThumbFormat.XLS,
    ThumbFormat.XLSX,
];
