import { Box } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Issue } from "../../../graphql/generated/graphql";
import { useIssueCreate, useIssueEdit } from "../../../redux/issue";
import { IViewerPushpin } from "../../Viewer/PusphinsInViewer/PusphinsInViewer";
import PushpinTitle from "../PushpinTitle/PushpinTitle";
import useStyles from "./styles";

interface IPushpinWrapperProps {
    viewerPushpins: IViewerPushpin[];
    handleEdit: (viewerPushpin: IViewerPushpin, isViewerPage?: boolean | undefined) => void;
    handleDrag: (viewerPushpin: IViewerPushpin) => void;
    handleIsDragging: () => void;
    isViewerPage?: boolean;
}

const PushpinWrapper: React.FC<IPushpinWrapperProps> = ({
    viewerPushpins,
    handleEdit,
    handleDrag,
    handleIsDragging,
    isViewerPage,
}) => {
    const classes = useStyles();
    const issueCreate = useIssueCreate();
    const issueEdit = useIssueEdit();

    const handleClick = (pushpin: IViewerPushpin, isViewerPage: boolean | undefined) => {
        if (issueCreate?.isModeActive && pushpin?.flag === "new") {
            handleDrag(pushpin);
        } else {
            // redirect to issue page and show specific issue detail
            isViewerPage ? handleEdit(pushpin, isViewerPage) : handleEdit(pushpin);
        }
    };

    const highlightEditPushpin = (pushpin: IViewerPushpin, issueEdit?: Issue): boolean => {
        return pushpin?.externalId === issueEdit?.issueExternals?.[0]?.externalID;
    };

    return (
        <>
            {viewerPushpins.map((pushpin, index) => {
                return (
                    <Box
                        key={index}
                        className={classes.pushpin}
                        style={{
                            left: `${pushpin.coords.x}px`,
                            top: `${pushpin.coords.y}px`,
                        }}
                    >
                        <div
                            draggable={issueCreate?.isModeActive && pushpin?.flag === "new"}
                            onClick={() => handleClick(pushpin, isViewerPage)}
                            onDragStart={() => {
                                handleIsDragging();
                            }}
                            onTouchStart={() => {
                                handleIsDragging();
                            }}
                            className={clsx(classes.point, {
                                [classes.pointLarge]:
                                    (issueCreate?.isModeActive && pushpin?.flag === "new") ||
                                    highlightEditPushpin(pushpin, issueEdit),
                                [classes.draggable]: issueCreate?.isModeActive && pushpin?.flag === "new",
                            })}
                            style={{
                                backgroundColor: `${pushpin?.color as string}50`,
                                borderColor: pushpin?.color as string,
                            }}
                        />
                        {issueCreate?.isModeActive && issueCreate?.title && pushpin?.flag === "new" && (
                            <PushpinTitle title={issueCreate?.title} />
                        )}

                        {highlightEditPushpin(pushpin, issueEdit) && issueEdit !== undefined && (
                            <PushpinTitle title={issueEdit?.name as string} />
                        )}
                    </Box>
                );
            })}
        </>
    );
};

export default PushpinWrapper;
