import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        deleteResultDialogRoot: {
            position: "relative"
        },
        deleteResultDialogTitle: {
        },
        deleteResultDialogCloseButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey["500"],
        },
        accordionHeading: {
            fontWeight: theme.typography.fontWeightRegular as number,
        },
        secondaryHeading: {
            marginLeft: theme.spacing(1),
        },
        accordionDetails: {
            display: "block",
        },
        dependenciesTable: {
            fontFamily: "monospace",
            display: "block",
            marginLeft: theme.spacing(5),
            marginBottom: theme.spacing(2),
            "& th": {
                paddingRight: theme.spacing(1),
                textAlign: "right",
            }
        },
        deleteRequest: {
            color: theme.palette.grey["400"],
            marginLeft: theme.spacing(1),
            float: "left",
            display: "inline",
        },
        deleteRequestTitle: {
            display: "inline",
            float: "left",
        },
        deleteStatusIcon: {
            marginRight: theme.spacing(1),
            color: theme.palette.grey["400"],
            fontSize: "1.5em"
        },
        disabled: {
            pointerEvents: "none",
        }
    })
);

export default useStyles;
