import { defineMessages } from "react-intl";

export default defineMessages({
    labelItemNumber: {
        id: "components.mapping.dialogs.workDialog.labelItemNumber",
        defaultMessage: "Item Number",
    },
    labelProductId: {
        id: "components.mapping.dialogs.workDialog.productId",
        defaultMessage: "Product-ID",
    },
    labelProductName: {
        id: "components.mapping.dialogs.workDialog.labelProductName",
        defaultMessage: "Product name",
    },
    labelListPrice: {
        id: "components.mapping.dialogs.workDialog.labelListPrice",
        defaultMessage: "List price",
    },
    labelSurcharge: {
        id: "components.mapping.dialogs.workDialog.labelSurcharge",
        defaultMessage: "Surcharge",
    },
    tooltipAddNewWork: {
        id: "components.mapping.dialogs.workDialog.tooltipAddNewWork",
        defaultMessage: "Add new work",
    },
    tooltipSaveChanges: {
        id: "components.mapping.dialogs.workDialog.tooltipSaveChanges",
        defaultMessage: "Save changes",
    },
    tooltipToReturn: {
        id: "components.mapping.dialogs.workDialog.tooltipToReturn",
        defaultMessage: "To return",
    },
    tooltipDiscard: {
        id: "components.mapping.dialogs.workDialog.tooltipDiscard",
        defaultMessage: "Discard",
    },
    tooltipDelete: {
        id: "components.mapping.dialogs.workDialog.tooltipDelete",
        defaultMessage: "Delete",
    },
    tooltipClose: {
        id: "components.mapping.dialogs.workDialog.tooltipClose",
        defaultMessage: "Close",
    },
    tooltipUpdate: {
        id: "components.mapping.dialogs.workDialog.tooltipUpdate",
        defaultMessage: "Update",
    },
    confirmDeleteTitle: {
        id: "components.mapping.dialogs.workDialog.confirmDeleteTitle",
        defaultMessage: "Delete confirm",
    },
    confirmDeleteMessage: {
        id: "components.mapping.dialogs.workDialog.confirmDeleteMessage",
        defaultMessage: "Are you sure you want to delete the equipment",
    },
    confirmClose: {
        id: "components.mapping.dialogs.workDialog.confirmClose",
        defaultMessage: "Close",
    },
    confirmDelete: {
        id: "components.mapping.dialogs.workDialog.confirmDelete",
        defaultMessage: "Delete",
    },
});
