import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import Icon from "../../../Icon/Icon";
import GraphicsDialog from "../GraphicsDialog/GraphicsDialog";
import useStyles from "./styles";
import ExporterImporter from "../../../ExporterImporter/ExporterImporter";
import { onGraphicsCatalogue } from "../../../../redux/mapping";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import { useKeycloak } from "@react-keycloak/web";
import { exportGraphics, importGraphics } from "../../../../packages/Api/data/graphics/client";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";

interface IGraphicsDialogWrapperProps {}

const GraphicsDialogWrapper: React.FC<IGraphicsDialogWrapperProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedGraphicsIds, setSelectedGraphicsIds] = useState<number[]>([]);
    const [addModeActive, setAddModeActive] = useState<boolean>(false);

    const graphicsDialogRef = useRef();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectedGraphicsIds = (rowIDs: number[]) => {
        setSelectedGraphicsIds(rowIDs);
    };

    const handleAddModeActive = (active: boolean) => {
        setAddModeActive(active);
    };

    // translations
    const intl = useIntl();
    const transExport = intl.formatMessage({ ...messages.export });
    const transImport = intl.formatMessage({ ...messages.import });
    const transShowCatalogue = intl.formatMessage({ ...messages.showCatalog });
    const transSelect = intl.formatMessage({ ...messages.select });
    const transCatalogue = intl.formatMessage({ ...messages.catalogue });
    const transNew = intl.formatMessage({ ...messages.new });

    return (
        <>
            <NullableTooltip title={transShowCatalogue}>
                <IconButton
                    aria-label="select"
                    color="primary"
                    className={clsx("globalAddButton", classes.addButton)}
                    onClick={handleOpen}
                >
                    <Icon name="cog-solid" size={20} />
                </IconButton>
            </NullableTooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="graphics-position-dialog-title"
                aria-describedby="graphics-position-dialog-description"
                maxWidth={false}
                fullWidth
                fullScreen={fullScreen}
                className={classes.root}
                scroll="paper"
            >
                <DialogTitle id="graphics-position-dialog-title">
                    {transCatalogue}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <GraphicsDialog
                        ref={graphicsDialogRef}
                        addModeActive={addModeActive}
                        selectedGraphicsIds={selectedGraphicsIds}
                        handleAddModeActive={handleAddModeActive}
                        handleSelectedGraphicsIds={handleSelectedGraphicsIds}
                        handleClose={handleClose}
                    />
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                    <Button
                        variant="outlined"
                        color="default"
                        size="medium"
                        onClick={() => {
                            // @ts-ignore
                            graphicsDialogRef?.current?.handlePrepareAddGraphics();
                        }}
                    >
                        {transNew}
                    </Button>

                    <Box className={classes.exportImport}>
                        <ExporterImporter
                            fileName={"Arbeit.xlsx"}
                            exportLabel={transExport}
                            importLabel={transImport}
                            exportCallback={(token, data) => {
                                exportGraphics(token);
                            }}
                            importCallback={importGraphics}
                            onImportDoneCallback={(response) => {
                                dispatch(onGraphicsCatalogue({ token: keycloak?.token as string }))
                            }}
                        />
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="medium"
                        disabled={selectedGraphicsIds?.length === 0 || addModeActive}
                        onClick={() => {
                            // @ts-ignore
                            graphicsDialogRef?.current?.handleAddGraphicsToPosition();
                            handleClose();
                        }}
                    >
                        {transSelect}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GraphicsDialogWrapper;
