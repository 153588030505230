import { Camera, CameraResultType, CameraSource, FilesystemDirectory } from "@capacitor/core";
import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { isPlatform } from "@ionic/react";
import { useFilesystem } from "@ionic/react-hooks/filesystem";
import { useStorage } from "@ionic/react-hooks/storage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFiles } from "../packages/Api/data/datamanagement/client";
import { IUploadResponseStatus } from "../packages/Api/data/datamanagement/types";
import { onLoadNode } from "../redux/tree";

export interface Photo {
    filepath: string;
    webviewPath?: string;
    base64?: string;
}

const PHOTO_STORAGE = "photos";

export function usePhotoGallery(): any {
    const [photos, setPhotos] = useState<Photo[]>([]);
    // const { getPhoto } = useCamera();
    const { deleteFile, readFile } = useFilesystem(); // use writeFile for savePicture
    const { get, set } = useStorage();
    const dispatch = useDispatch<DispatchAction>();

    useEffect(() => {
        const loadSaved = async () => {
            const photosString = await get(PHOTO_STORAGE);
            const photosInStorage = (photosString ? JSON.parse(photosString) : []) as Photo[];
            // If running on the web...
            if (!isPlatform("hybrid")) {
                for (const photo of photosInStorage) {
                    const file = await readFile({
                        path: photo.filepath,
                        directory: FilesystemDirectory.Data,
                    });
                    // Web platform only: Save the photo into the base64 field
                    photo.base64 = `data:image/jpeg;base64,${file.data}`;
                }
            }
            setPhotos(photosInStorage);
        };
        loadSaved();
    }, [get, readFile]);

    const takeAndUploadPhoto = async (nodeId: string) => {
        const { cameraPhoto, fileName } = await takePhoto();

        if (cameraPhoto.webPath) {
            fetch(cameraPhoto.webPath)
                .then((response) => response.blob())
                .then((blob) => {
                    const formData = new FormData();
                    formData.append("files", blob, fileName);

                    const notification: INotification = {
                        variant: "success",
                        message: "Upload started",
                    };
                    dispatch(addNotification({ notification }));

                    uploadFiles(nodeId, formData).then((response) => {
                        if (response) {
                            response.map((item: any) => {
                                if (item.status === IUploadResponseStatus.ERROR) {
                                    const notification: INotification = {
                                        variant: "error",
                                        message: `${item.data.errors[0].detail} (${item.fileName})`,
                                    };
                                    dispatch(addNotification({ notification }));
                                } else if (item.status === IUploadResponseStatus.SUCCESS) {
                                    const notification: INotification = {
                                        variant: "success",
                                        message: "Upload ready",
                                    };
                                    dispatch(addNotification({ notification }));
                                }
                            });

                            // reload tree item by nodeId
                            dispatch(onLoadNode({ nodeId, depth: 1 }));
                        }
                    });
                });
        }
    };

    const takePhoto = async () => {
        const fileName = `${new Date().getTime()}.jpeg`;
        const cameraPhoto = await Camera.getPhoto({
            saveToGallery: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
        });

        // const savedFileImage = await savePicture(cameraPhoto, fileName);
        // const newPhotos = [savedFileImage, ...photos];
        // setPhotos(newPhotos);
        // set(
        //     PHOTO_STORAGE,
        //     isPlatform("hybrid")
        //         ? JSON.stringify(newPhotos)
        //         : JSON.stringify(
        //               newPhotos.map(p => {
        //                   // Don't save the base64 representation of the photo data,
        //                   // since it's already saved on the Filesystem
        //                   const photoCopy = { ...p };
        //                   delete photoCopy.base64;
        //                   return photoCopy;
        //               })
        //           )
        // );

        return { cameraPhoto, fileName };
    };

    // const savePicture = async (photo: CameraPhoto, fileName: string): Promise<Photo> => {
    //     let base64Data: string;
    //     // "hybrid" will detect Cordova or Capacitor;
    //     if (isPlatform("hybrid")) {
    //         const file = await readFile({
    //             path: photo.path!,
    //         });
    //         base64Data = file.data;
    //     } else {
    //         base64Data = await base64FromPath(photo.webPath!);
    //     }
    //     const savedFile = await writeFile({
    //         path: fileName,
    //         data: base64Data,
    //         directory: FilesystemDirectory.Data,
    //     });
    //
    //     if (isPlatform("hybrid")) {
    //         // Display the new image by rewriting the 'file://' path to HTTP
    //         // Details: https://ionicframework.com/docs/building/webview#file-protocol
    //         return {
    //             filepath: savedFile.uri,
    //             webviewPath: Capacitor.convertFileSrc(savedFile.uri),
    //         };
    //     }
    //     // Use webPath to display the new image instead of base64 since it's
    //     // already loaded into memory
    //     return {
    //         filepath: fileName,
    //         webviewPath: photo.webPath,
    //     };
    // };

    const deletePhoto = async (photo: Photo) => {
        // Remove this photo from the Photos reference data array
        const newPhotos = photos.filter((p) => p.filepath !== photo.filepath);

        // Update photos array cache by overwriting the existing photo array
        set(PHOTO_STORAGE, JSON.stringify(newPhotos));

        // delete photo file from filesystem
        const filename = photo.filepath.substr(photo.filepath.lastIndexOf("/") + 1);
        await deleteFile({
            path: filename,
            directory: FilesystemDirectory.Data,
        });
        setPhotos(newPhotos);
    };

    return {
        photos,
        takePhoto,
        takeAndUploadPhoto,
        deletePhoto,
    };
}
