import { defineMessages } from "react-intl";

export default defineMessages({
    export: {
        id: "components.mapping.dialogs.materialDialogWrapper.export",
        defaultMessage: "Export material",
    },
    import: {
        id: "components.mapping.dialogs.materialDialogWrapper.import",
        defaultMessage: "Import material",
    },
    materialCatalog: {
        id: "components.mapping.dialogs.materialDialogWrapper.materialCatalog",
        defaultMessage: "Material Catalogue"
    },
    createNewMaterial: {
        id: "components.mapping.dialogs.materialDialogWrapper.createNewMaterial",
        defaultMessage: "Create new material"
    },
    addPhysicalMaterialToPosition: {
        id: "components.mapping.dialogs.materialDialogWrapper.addPhysicalMaterialToPosition",
        defaultMessage: "Add physical material to position"
    },

    buttonPartAddAdditional: {
        id: "components.mapping.dialogs.materialDialogWrapper.buttonPartAddAdditional",
        defaultMessage: "Add additional",
    },
    buttonPartMaterials: {
        id: "components.mapping.dialogs.materialDialogWrapper.buttonPartMaterials",
        defaultMessage: "materials",
    },
    buttonPartMaterial: {
        id: "components.mapping.dialogs.materialDialogWrapper.buttonPartMaterial",
        defaultMessage: "material",
    },
    buttonPartToPosition: {
        id: "components.mapping.dialogs.materialDialogWrapper.buttonPartToPosition",
        defaultMessage: "to position",
    }
});
