import { AnyAction } from "redux";
import { Vector3 } from "three";
import { IIssueTab } from "../../components/Issues/type";
import { Issue } from "../../graphql/generated/graphql";
import { IVersion } from "../../packages/Api/data/tree/types";
import { SplitViewType } from "../../pages/ViewerPage/ViewerPage";
import { ActionTypes } from "./action";

export interface IIssueModuleState {
    issueState: IIssuesState;
}

export interface IIssueCreateLocationInfo {
    sheetMetadata?: {
        is3D?: any;
        sheetGuid?: any;
        sheetName?: any;
    };
    pushpinAttributes?: {
        type?: string; // This is the only type currently available
        object_id?: any; // (Only for 3D models) The object the pushpin is situated on.
        location?: any; // The x, y, z coordinates of the pushpin.
        viewer_state?: any; // T
        external_id?: any;
    };
}

export interface IIssueCreateState {
    // code: string;
    type: string | null;
    status: string | null;
    title: string;
    // assignTo: number | null;
    dueDate: Date | null;
    location: string;
    locationDetails: string;
    // owner: number | null;
    rootCause: string | null;
    description: string;
    color: string;
    coords: Vector3 | null;
    locationInfo: IIssueCreateLocationInfo | null;
    isModeActive: boolean;
    isPushpinPlaced: boolean;
    isDone: boolean;
    attachMarkups: boolean;
}

export interface IIssueViewer {
    urn: string | null;
    fileId: string | null;
    version: IVersion | null;
    viewable: any | null;
    path: string | null;
    splitView: SplitViewType;
}

export interface IIssuesState {
    issues: Issue[];
    issueCreate: IIssueCreateState;
    issueEditId: string;
    fromViewerPage: boolean;
    issueTab: IIssueTab;
    issueViewer: IIssueViewer;
    isVisible: boolean;
}

export const initialState: Readonly<IIssuesState> = {
    issues: [],
    // number 0 representing placeholder for select fields
    issueCreate: {
        // code: "",
        type: "0",
        status: "0",
        title: "",
        // assignTo: null,
        dueDate: null,
        location: "",
        locationDetails: "",
        // owner: null,
        rootCause: "0",
        description: "",
        color: "",
        coords: null,
        locationInfo: {
            sheetMetadata: {},
            pushpinAttributes: {
                type: "TwoDVectorPushpin",
            },
        },
        isModeActive: false,
        isPushpinPlaced: false,
        isDone: false,
        attachMarkups: false,
    },
    issueEditId: "",
    fromViewerPage: false,
    issueTab: IIssueTab.List,
    issueViewer: {
        urn: null,
        fileId: null,
        version: null,
        viewable: null,
        path: null,
        splitView: SplitViewType["3D"],
    },
    isVisible: true,
};

export function reducer(state: IIssuesState = initialState, action: AnyAction): IIssuesState {
    switch (action.type) {
        case ActionTypes.OnIssuesList: {
            return {
                ...state,
                issues: action.payload.issues,
            };
        }

        case ActionTypes.OnIssueEdit: {
            const { issueEditId, issueTab, fromViewerPage } = action.payload;
            return {
                ...state,
                issueEditId,
                issueTab: issueTab || state.issueTab,
                fromViewerPage,
            };
        }

        case ActionTypes.OnIssueEditReset: {
            return {
                ...state,
                issueEditId: initialState.issueEditId,
                fromViewerPage: initialState.fromViewerPage,
            };
        }

        case ActionTypes.OnChangeTab: {
            return {
                ...state,
                issueTab: action.payload.issueTab,
            };
        }

        case ActionTypes.OnCreateChange: {
            return {
                ...state,
                issueCreate: {
                    ...state.issueCreate,
                    [action.payload.prop]: action.payload.value,
                },
            };
        }

        case ActionTypes.OnCreateChangeLocationInfo: {
            return {
                ...state,
                issueCreate: {
                    ...state.issueCreate,
                    locationInfo: {
                        sheetMetadata: {
                            ...state.issueCreate.locationInfo?.sheetMetadata,
                            ...action.payload.locationInfo?.sheetMetadata,
                        },
                        pushpinAttributes: {
                            ...state.issueCreate.locationInfo?.pushpinAttributes,
                            ...action.payload.locationInfo?.pushpinAttributes,
                        },
                    },
                },
            };
        }

        case ActionTypes.OnCreateModeActive: {
            const { isModeActive } = action.payload;

            return isModeActive
                ? {
                      ...state,
                      issueCreate: {
                          ...state.issueCreate,
                          isModeActive,
                      },
                  }
                : {
                      ...state,
                      issueCreate: {
                          ...initialState.issueCreate,
                          isModeActive,
                      },
                  };
        }

        case ActionTypes.OnCreatePushpinPlaced: {
            return {
                ...state,
                issueCreate: {
                    ...state.issueCreate,
                    color: action.payload.color ? action.payload.color : state.issueCreate.color,
                    coords: action.payload.coords ? action.payload.coords : state.issueCreate.coords,
                    isPushpinPlaced: action.payload.isPushpinPlaced
                        ? action.payload.isPushpinPlaced
                        : state.issueCreate.isPushpinPlaced,
                },
            };
        }

        case ActionTypes.OnCreateDone: {
            return {
                ...state,
                issueCreate: initialState.issueCreate,
            };
        }

        case ActionTypes.OnIssueViewer: {
            const { urn, fileId, version } = action.payload;
            return {
                ...state,
                issueViewer: {
                    ...state.issueViewer,
                    urn: urn || state.issueViewer.urn,
                    fileId: fileId || state.issueViewer.fileId,
                    version: version || state.issueViewer.version,
                },
            };
        }

        case ActionTypes.OnIssueViewerChangeVersion: {
            const { version } = action.payload;
            return {
                ...state,
                issueViewer: {
                    ...state.issueViewer,
                    version,
                },
            };
        }

        case ActionTypes.OnIssueViewerChangeViewable: {
            const { viewable } = action.payload;
            return {
                ...state,
                issueViewer: {
                    ...state.issueViewer,
                    viewable,
                },
            };
        }

        case ActionTypes.OnIssueViewerChangePath: {
            const { path } = action.payload;
            return {
                ...state,
                issueViewer: {
                    ...state.issueViewer,
                    path,
                },
            };
        }

        case ActionTypes.OnIssueViewerChangeSplitView: {
            const { splitView } = action.payload;
            return {
                ...state,
                issueViewer: {
                    ...state.issueViewer,
                    splitView,
                },
            };
        }

        case ActionTypes.OnIssueViewerReset: {
            return {
                ...state,
                issueViewer: initialState.issueViewer,
            };
        }

        case ActionTypes.OnIssuesChangeVisibility: {
            return {
                ...state,
                isVisible: action.payload.isVisible,
            };
        }

        default:
            return state;
    }
}
