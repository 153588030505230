import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
        },
    })
);

export default useStyles;
