import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0 2px",
            display: "flex",
            alignItems: "center",
        },
        input: {
            ...theme.typography.body2,
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        button: {
            padding: theme.spacing(0.5),
        },
        buttonSave: {
            color: theme.palette.success.dark,
        },
    })
);

export default useStyles;
