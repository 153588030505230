import { defineMessages } from "react-intl";

export default defineMessages({
    exportPositions: {
        id: "components.mapping.mappingMainView.exportPositions",
        defaultMessage: "Export positions",
    },
    importPositions: {
        id: "components.mapping.mappingMainView.importPositions",
        defaultMessage: "Import positions",
    },

    titleBaseData: {
        id: "components.mapping.mappingMainView.baseData",
        defaultMessage: "Base data"
    },
    titleProjectCatalog: {
        id: "components.mapping.mappingMainView.titleProjectCatalog",
        defaultMessage: "Project catalog"
    },
    labelTemplate: {
        id: "components.mapping.mappingMainView.labelTemplate",
        defaultMessage: "Template"
    },
    fieldTemplate: {
        id: "components.mapping.mappingMainView.fieldTemplate",
        defaultMessage: "Please enter Template"
    },
    fieldSearch: {
        id: "components.mapping.mappingMainView.fieldSearch",
        defaultMessage: "Search ..."
    },
    tooltipAddCheckedPosition: {
        id: "components.mapping.mappingMainView.tooltipAddCheckedPosition",
        defaultMessage: "Add checked positions to project catalog"
    },
    tooltipDeleteCheckedPosition: {
        id: "components.mapping.mappingMainView.tooltipDeleteCheckedPosition",
        defaultMessage: "Delete checked positions from project catalog"
    },
    labelCopyProjectCatalog: {
        id: "components.mapping.mappingMainView.labelCopyProjectCatalog",
        defaultMessage: "Copy project catalog" // Projektkatalog kopieren
    },
});
