import Typography from "@material-ui/core/Typography";
import React from "react";
import useStyles from "./styles";

interface IPageTitleProps {
    title: string;
}

const PageTitle: React.FC<IPageTitleProps> = ({ title }) => {
    const classes = useStyles();

    return (
        <Typography variant="h3" component="h1" className={classes.root}>
            {title}
        </Typography>
    );
};

export default PageTitle;
