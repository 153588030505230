import { defineMessages } from "react-intl";

export default defineMessages({
    labelItemNumber: {
        id: "components.mapping.stepWorkFormMaterial.labelItemNumber",
        defaultMessage: "Item number", // Artikel Nr. o. G TIN
    },
    labelProductId: {
        id: "components.mapping.stepWorkFormMaterial.labelProductId",
        defaultMessage: "Product ID", // Produkt-ID
    },
    // __: {
    //     id: "components.mapping.stepWorkFormMaterial.",
    //     defaultMessage: "kMenge", // K.-Menge // todo translate de
    // },
    labelListPrice: {
        id: "components.mapping.stepWorkFormMaterial.labelListPrice",
        defaultMessage: "Li Price", // Li.-Preis
    },
    labelEp: {
        id: "components.mapping.stepWorkFormMaterial.labelEp",
        defaultMessage: "EP", // EP
    },
    labelSurcharge: {
        id: "components.mapping.stepWorkFormMaterial.labelSurcharge",
        defaultMessage: "Surcharge", // Zuschlag
    },
    // __: {
    //     id: "components.mapping.stepWorkFormMaterial.",
    //     defaultMessage: "EP Per Menge", // EP/Menge  // todo translate de
    // },
});
