import { AnyAction } from "redux";
import { SpecificationView } from "../../components/Specification/type";
import { ActionTypes } from "./action";
import { IIsolation } from "../../components/Viewer/extensions/Viewing.Extensions.BulkIsolation/Viewing.Extensions.BulkIsolationExtension";

export interface ISpecificationModuleState {
    specificationState: ISpecificationState;
}

export type BulkIsolations = { [key: number]: { [key: string]: IIsolation } };

export interface ISpecificationState {
    views: SpecificationView[];
    externs: SpecificationView[];
    bulkIsolations: BulkIsolations;
    dataTable: {
        expandedRows: string[];
        selectedRows: string[];
    };
}

const initialState: Readonly<ISpecificationState> = {
    views: [],
    externs: [],
    bulkIsolations: {},
    dataTable: {
        expandedRows: [],
        selectedRows: [],
    },
};

export function reducer(state: ISpecificationState = initialState, action: AnyAction): ISpecificationState {
    switch (action.type) {
        case ActionTypes.OnChangeView: {
            const views = action.payload.views;
            const isDefault =
                views.includes(SpecificationView.Viewer3D) &&
                views.includes(SpecificationView.Viewer2D) &&
                views.includes(SpecificationView.Table);
            const isExtern =
                views.includes(SpecificationView.Viewer3DExtern) ||
                views.includes(SpecificationView.Viewer2DExtern) ||
                views.includes(SpecificationView.TableExtern);
            return {
                ...state,
                views: isDefault ? initialState.views : views,
                externs: isExtern ? state.externs : initialState.externs,
            };
        }

        case ActionTypes.OnShowExtern: {
            const { extern } = action.payload;
            return {
                ...state,
                externs: state.externs.includes(extern)
                    ? state.externs.filter((ext) => ext !== extern)
                    : [extern, ...state.externs],
            };
        }

        case ActionTypes.OnResetExtern: {
            return {
                ...state,
                externs: initialState.externs,
            };
        }

        case ActionTypes.OnDefaultView: {
            return {
                ...state,
                views: initialState.views,
                externs: initialState.externs,
            };
        }

        case ActionTypes.OnResetView: {
            const { view } = action.payload;
            return {
                ...state,
                views: state.views.includes(view) ? state.views.filter((v) => v !== view) : [...state.views],
                externs: state.externs.includes(view) ? state.externs.filter((v) => v !== view) : [...state.externs],
            };
        }

        case ActionTypes.OnBulkIsolationAdd: {
            const { isolations } = action.payload;
            return {
                ...state,
                bulkIsolations: isolations,
            };
        }

        case ActionTypes.OnExpandedRows: {
            const { expandedRows } = action.payload;
            return {
                ...state,
                dataTable: {
                    ...state.dataTable,
                    expandedRows,
                },
            };
        }

        case ActionTypes.OnSelectedRows: {
            const { selectedRows } = action.payload;
            return {
                ...state,
                dataTable: {
                    ...state.dataTable,
                    selectedRows,
                },
            };
        }

        default:
            return state;
    }
}
