export enum SpecificationView {
    Table = 1,
    Viewer2D = 2,
    Viewer3D = 3,
    TableExtern = 4,
    Viewer2DExtern = 5,
    Viewer3DExtern = 6,
}

export enum ISymbol {
    Success = 1,
    Warning = 2,
    Error = 3,
}

export type IDraggableData = {
    node: HTMLElement;
    x: number;
    y: number;
    deltaX: number;
    deltaY: number;
    lastX: number;
    lastY: number;
};

export type IPosition = {
    x: number;
    y: number;
};

export const rowCodeIconsMap = new Map([
    ["A", "ebkph-property"],
    ["B", "ebkph-preparation"],
    ["C", "ebkph-construction"],
    ["D", "ebkph-technology"],
    ["E", "ebkph-exterior"],
    ["F", "ebkph-roofing"],
    ["G", "ebkph-expansion"],
    ["H", "ebkph-specific"],
    ["I", "ebkph-environment"],
    ["J", "ebkph-equipment"],
    ["V", "ebkph-planning"],
    ["Y", "ebkph-additional"],
    ["W", "ebkph-reserve"],
    ["Z", "ebkph-tax"],
]);
