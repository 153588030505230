import { DispatchAction } from "@iolabs/redux-utils";
import { IonRouterLink } from "@ionic/react";
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import { Skeleton } from "@material-ui/lab";
import prettyBytes from "pretty-bytes";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { onIssueViewerChangePath } from "../../../redux/issue";
import { setActiveNodeId, setExpandedNodes, useExpandedNodes } from "../../../redux/tree";
import { onLoadUrn } from "../../../redux/viewer";
import { getComparator, Order, stableSort } from "../../../utils/DataTable";
import { getParentPathParts } from "../../../utils/Urn";
import DocumentIcon from "../../DocumentView/DocumentIcon/DocumentIcon";
import DocumentInlineEdit from "../../DocumentView/DocumentInlineEdit/DocumentInlineEdit";
import DocumentIssues from "../../DocumentView/DocumentIssues/DocumentIssues";
import DocumentMarkups from "../../DocumentView/DocumentMarkups/DocumentMarkups";
import DocumentOffline from "../../DocumentView/DocumentOffline/DocumentOffline";
import DocumentSubMenu from "../../DocumentView/DocumentSubMenu/DocumentSubMenu";
import { Data, EMPTY, HeadCell } from "../../DocumentView/DocumentViewWrapper/DocumentViewWrapper";
import { IDocumentIconType, ISubmenuDots } from "../../DocumentView/type";
import Icon from "../../Icon/Icon";
import DataTableHead from "../DataTableHead/DataTableHead";
import messages from "./messages";
import useDataTableStyles from "./styles";

interface IDataTableProps {
    rows: Data[];
    headCells: HeadCell[];
    selectedNodes: string | undefined;
    loading?: boolean;
    activeProjectId?: string;
    activeFoldersIds: string[];
}

const DataTable: React.FC<IDataTableProps> = ({
    rows,
    headCells,
    selectedNodes,
    loading,
    activeProjectId,
    activeFoldersIds,
}) => {
    const classes = useDataTableStyles();
    const dispatch = useDispatch<DispatchAction>();
    const expanded = useExpandedNodes();
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof Data>("name");
    const [selected, setSelected] = useState<string[]>([]);
    const [openInlineEdit, setOpenInlineEdit] = useState<string | null>(null);
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleOpenInlineEdit = (row: Data) => {
        setOpenInlineEdit(row.nodeId);
    };

    const handleCloseInlineEdit = () => {
        setOpenInlineEdit(null);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // const handleFolderClick = (event: React.MouseEvent<unknown>, item: Data) => {
    //     dispatch(onLoadNodeContentDone(undefined));
    //     dispatch(onLoadNode({ nodeId: item.nodeId, depth: 1 }));
    // };

    const handleCheckBoxClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleFileClick = (event: React.MouseEvent<unknown>, row: Data) => {
        dispatch(
            onLoadUrn({
                urn: row.urn,
                fileId: row.fileId as string,
                fileType: row.fileType as string,
            })
        );
        dispatch(onIssueViewerChangePath({ path: row?.pathIssues }));
    };

    const handleFolderClick = (event: React.MouseEvent<unknown>, row: Data) => {
        let expandedNodes = [...expanded];
        if (!expanded.includes(row.nodeId)) {
            if (selectedNodes) {
                expandedNodes = expandedNodes.concat(selectedNodes);
            } else {
                expandedNodes = [row.nodeId, ...expandedNodes];
            }
        }

        const parentPathParts = getParentPathParts(activeProjectId, activeFoldersIds, true);
        dispatch(setExpandedNodes({ expandedNodes: expandedNodes.concat(parentPathParts) }));

        // set active node Id of tree item
        dispatch(setActiveNodeId({ activeNodeId: row.nodeId }));
    };

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    // };
    //
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    // translations
    const intl = useIntl();
    const transEmptyFolder = intl.formatMessage({ ...messages.emptyFolder });

    // sort rows by parameters
    rows = stableSort(rows, getComparator(order, orderBy))

    // sort rows by always folders first
    let folderRows:Data[] = [];
    let fileRows:Data[] = [];
    if(rows.length) {
        rows.map((row:Data) => {
            row.isDir ? folderRows.push(row) : fileRows.push(row);
        })
    }
    rows = [...folderRows, ...fileRows];

    return (
        <Box className={classes.root}>
            {/* <DataTableToolbar classes={classes} numSelected={selected.length} /> */}
            <TableContainer className={classes.tableContainer}>
                <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="documents"
                    size="small"
                    aria-label="data table"
                >
                    <DataTableHead
                        classes={classes}
                        headCells={headCells}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {loading ? (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                classes={{ selected: classes.tableRowSelected }}
                                className={classes.tableRow}
                            >
                                <TableCell padding="checkbox" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell component="th" scope="row" className={classes.tableCell} colSpan={2}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    <Skeleton height={35} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {rows.length ? (
                                    // stableSort(rows, getComparator(order, orderBy))
                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.name);
                                            const labelId = `data-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    selected={isItemSelected}
                                                    classes={{ selected: classes.tableRowSelected }}
                                                    className={classes.tableRow}
                                                >
                                                    <TableCell padding="checkbox" className={classes.tableCell}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            size="small"
                                                            inputProps={{ "aria-labelledby": labelId }}
                                                            color="secondary"
                                                            onClick={(event) => handleCheckBoxClick(event, row.name)}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        className={classes.tableCell}
                                                    >
                                                        {row.nodeId === openInlineEdit ? (
                                                            <DocumentInlineEdit
                                                                item={row}
                                                                handleClose={handleCloseInlineEdit}
                                                            />
                                                        ) : (
                                                            <>
                                                                {row.isDir ? (
                                                                    <IonRouterLink
                                                                        routerLink={row.path}
                                                                        className={classes.ionRouterLink}
                                                                        onClick={(event) =>
                                                                            handleFolderClick(event, row)
                                                                        }
                                                                    >
                                                                        <FolderIcon className={classes.icon} />
                                                                        {row.name}
                                                                    </IonRouterLink>
                                                                ) : (
                                                                    <IonRouterLink
                                                                        routerLink={row.path}
                                                                        routerDirection="forward"
                                                                        className={classes.ionRouterLink}
                                                                        onClick={(event) => handleFileClick(event, row)}
                                                                    >
                                                                        <DocumentIcon
                                                                            item={row}
                                                                            type={IDocumentIconType.Table}
                                                                        />
                                                                        {row.name}
                                                                    </IonRouterLink>
                                                                )}
                                                            </>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        <DocumentSubMenu
                                                            item={row}
                                                            handleOpenInlineEdit={handleOpenInlineEdit}
                                                            dotsType={ISubmenuDots.Vertical}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        <DocumentOffline item={row} />
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {row.version ? `V${row.version}` : EMPTY}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {row.lastModified}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {row.updatedBy ? row.updatedBy : EMPTY}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {!row.isDir ? <DocumentMarkups item={row} /> : EMPTY}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {!row.isDir ? <DocumentIssues item={row} /> : EMPTY}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {row.format ? row.format : EMPTY}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableCell}>
                                                        {!row.isDir ? prettyBytes(row.size) : EMPTY}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                ) : (
                                    <>
                                        <TableRow className={classes.tableRow}>
                                            <TableCell
                                                align="center"
                                                colSpan={10}
                                                className={classes.tableCellEmptyTop}
                                            >
                                                <Icon name="folder-open" size={60} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.tableRow}>
                                            <TableCell
                                                align="center"
                                                colSpan={10}
                                                className={classes.tableCellEmptyBottom}
                                            >
                                                <Typography variant="body2">{transEmptyFolder}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </>
                        )}
                        {/*
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*
           <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            */}
        </Box>
    );
};

export default DataTable;
