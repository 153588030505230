import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Popover } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../../../pages/PageRouter";
import messages from "./messages";
import useStyles from "./styles";
import Icon from "../../Icon/Icon";
import { useGetPath } from "../../../utils/Menu";

interface IHeaderSettings {
    isMobile?: boolean;
}

const HeaderSettings: React.FC<IHeaderSettings> = ({ isMobile }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "header-settings-popover" : undefined;

    const { getPath} = useGetPath();

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    // translations
    const intl = useIntl();
    const transSettings = intl.formatMessage({ ...messages.settings });
    const transProjectSettings = intl.formatMessage({ ...messages.projectSettings });
    const transNewProject = intl.formatMessage({ ...messages.newProject });
    const transMappingManager = intl.formatMessage({ ...messages.mappingManager });

    return isMobile ? (
        <>
            <MenuItem disabled>
                <ListItemIcon className={classes.listItemIcon}>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{transSettings}</Typography>
            </MenuItem>
            <MenuItem component={RouterLink} to={getPath(Path.PROJECT_SETTINGS)} classes={{ root: classes.subMenuItem }}>
                <Typography variant="inherit">{transProjectSettings}</Typography>
            </MenuItem>
            <MenuItem component={RouterLink} to={getPath(Path.PROJECT_NEW)} classes={{ root: classes.subMenuItem }}>
                <Typography variant="inherit">{transNewProject}</Typography>
            </MenuItem>
            <MenuItem component={RouterLink} to={getPath(Path.MAPPING_MANAGER)} classes={{ root: classes.subMenuItem }}>
                <Typography variant="inherit">{transMappingManager}</Typography>
            </MenuItem>
        </>
    ) : (
        <>
            <IconButton
                aria-label="Settings"
                className={clsx(classes.responsiveButton, classes.iconButton)}
                onClick={handleOpenPopover}
            >
                <SettingsIcon fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List component="nav" aria-label="settings">
                    <ListItem button component={RouterLink} to={getPath(Path.PROJECT_SETTINGS)} onClick={handleClosePopover}>
                      <Box className={classes.icon}>
                        <SettingsIcon fontSize="small" />
                      </Box>
                      <ListItemText primary={transProjectSettings} />
                    </ListItem>
                    <ListItem button component={RouterLink} to={getPath(Path.PROJECT_NEW)} onClick={handleClosePopover}>
                      <Box className={classes.icon}>
                        <Icon name="pencil-solid" size={20} />
                      </Box>
                      <ListItemText primary={transNewProject} />
                    </ListItem>
                    <ListItem button component={RouterLink} to={getPath(Path.MAPPING_MANAGER)} onClick={handleClosePopover}>
                      <Box className={classes.icon}>
                        <Icon name="arrow-from-left" size={20} />
                      </Box>
                      <ListItemText primary={transMappingManager} />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

export default HeaderSettings;
