import { defineMessages } from "react-intl";

export default defineMessages({
    dropZoneHeader: {
        id: "components.dropZone.header",
        defaultMessage: "Upload file",
    },
    dropZoneFooter: {
        id: "components.dropZone.footer",
        defaultMessage: "By submitting your data, you confirm that you are freely available to Grimm GmbH.",
    },
});
