import { AxiosError } from "axios";

import { createError } from "./errorFactory";
import UnauthorizedHttpError from "./errors/UnauthorizedHttpError";
import ForbiddenHttpError from "./errors/ForbiddenHttpError";
import NetworkError from "./errors/NetworkError";

export const handleHttpErrors = (error: AxiosError, keycloakInstance) => {
    if (error.response) {
        if (error.response.status === 401) {
            keycloakInstance.login();
            throw new UnauthorizedHttpError();
        } else if (error.response.status === 403) {
            throw new ForbiddenHttpError();
        } else if (error.response.data.errorId) {
            throw createError(error.response.data.errorId, error.response.data);
        } else {
            throw error;
        }
    } else if (error.request) {
        throw new NetworkError();
    } else {
        throw error;
    }
};
