import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { IIssueState } from "../../components/Issues/type";
import { ProjectFileVersion } from "../../graphql/generated/graphql";
import { IIssuable, IMarkupsData, IPrintable } from "./reducer";

export enum ActionTypes {
    OnLoadUrn = "viewer/ON_LOAD_URN",
    OnIsolateIds = "viewer/ON_ISOLATE_IDS",
    OnProxy = "viewer/ON_PROXY",
    OnProxyDone = "viewer/ON_PROXY_DONE",
    OnProxyFail = "viewer/ON_PROXY_FAIL",
    SetObjectsToContext = "viewer/SET_OBJECTS_TO_CONTEXT",
    OnRegisterPrintable = "viewer/ON_REGISTER_PRINTABLE",
    OnUnregisterPrintable = "viewer/ON_UNREGISTER_PRINTABLE",
    OnCreateIssue = "viewer/ON_CREATE_ISSUE",
    OnRegisterIssuable = "viewer/ON_REGISTER_ISSUABLE",
    OnUnregisterIssuable = "viewer/ON_UNREGISTER_ISSUABLE",
    OnViewableSelected = "viewer/ON_VIEWABLE_SELECT",
    OnViewableNavigate = "viewer/ON_VIEWABLE_NAVIGATE",
    OnViewable = "viewer/ON_VIEWABLE",
    OnMarkupsSetData = "viewer/ON_MARKUPS_SET_DATA",
    OnMarkupsSetExternalHandling = "viewer/ON_MARKUPS_SET_EXTERNAL_HANDLING",
    OnViewerToken = "viewer/ON_VIEWER_TOKEN",
    OnViewerTokenRequest = "viewer/ON_VIEWER_TOKEN_REQUEST",
    OnActiveViewables = "viewer/ON_ACTIVE_VIEWABLES",
}

export type LoadUrnOptions = {
    urn: string;
    forgeId?: string;
    fileId?: string;
    fileType?: string;
};

export type IsolateIdsOptions = {
    isolateIds: number[];
};

export type ProxyDoneOptions = {
    api: string;
    urn: string;
    isEmea: boolean;
};

export type ObjectsToContextOptions = {
    objects: { [key: number]: any[] };
    objectsSmart: string[];
    viewerIds?: number[];
};

export type RegisterIssuableOptions = {
    issuable: IIssuable;
};

export type RegisterPrintableOptions = {
    printable: IPrintable;
};

export type UnregisterPrintableOptions = {
    id: string;
};
export type UnregisterIssuableOptions = {
    id: string;
};

export type CreateIssueOptions = {
    issue: IIssueState;
};

export type ViewableSelectOptions = {
    index: number;
    viewable: ProjectFileVersion;
};

export type ViewableNavigateOptions = {
    guid: string;
};

export type ViewableOptions = {
    viewable: any;
};

export type ActiveViewablesOptions = {
    viewables: any[];
};

export type SetMarkupsDataOptions = {
    markupsData: IMarkupsData | null;
};
export type SetMarkupsExternalHandlingOptions = {
    externalHandling: boolean;
};
export type ViewerTokenOptions = {
    token: string;
};
export type ViewerTokenRequestOptions = {
    onTokenFetched?: (string) => void
};

export const Actions = {
    onLoadUrn: (options: LoadUrnOptions) => createAction(ActionTypes.OnLoadUrn, options),
    onIsolateIds: (options: IsolateIdsOptions) => createAction(ActionTypes.OnIsolateIds, options),
    onProxy: () => createAction(ActionTypes.OnProxy),
    onProxyDone: (options: ProxyDoneOptions) => createAction(ActionTypes.OnProxyDone, options),
    onProxyFail: (options: any) => createAction(ActionTypes.OnProxyFail, options),
    setObjectsToContext: (options: ObjectsToContextOptions) => createAction(ActionTypes.SetObjectsToContext, options),
    onCreateIssue: (options: CreateIssueOptions) => createAction(ActionTypes.OnCreateIssue, options),
    onRegisterIssuable: (options: RegisterIssuableOptions) => createAction(ActionTypes.OnRegisterIssuable, options),
    onUnregisterIssuable: (options: UnregisterIssuableOptions) =>
        createAction(ActionTypes.OnUnregisterIssuable, options),
    onRegisterPrintable: (options: RegisterPrintableOptions) => createAction(ActionTypes.OnRegisterPrintable, options),
    onUnregisterPrintable: (options: UnregisterPrintableOptions) =>
        createAction(ActionTypes.OnUnregisterPrintable, options),
    onMarkupsSetData: (options: SetMarkupsDataOptions) => createAction(ActionTypes.OnMarkupsSetData, options),
    onMarkupsSetExternalHandling: (options: SetMarkupsExternalHandlingOptions) =>
        createAction(ActionTypes.OnMarkupsSetExternalHandling, options),
    onViewableSelect: (options: ViewableSelectOptions) => createAction(ActionTypes.OnViewableSelected, options),
    onViewableNavigate: (options: ViewableNavigateOptions) => createAction(ActionTypes.OnViewableNavigate, options),
    onViewable: (options: ViewableOptions) => createAction(ActionTypes.OnViewable, options),
    onViewerToken: (options: ViewerTokenOptions) => createAction(ActionTypes.OnViewerToken, options),
    onViewerTokenRequest: (options: ViewerTokenRequestOptions) => createAction(ActionTypes.OnViewerTokenRequest, options),
    onActiveViewables: (options: ActiveViewablesOptions) => createAction(ActionTypes.OnActiveViewables, options),
};

export function onLoadUrn(options: LoadUrnOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onLoadUrn(options));
    };
}

export function onIsolateIds(options: IsolateIdsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIsolateIds(options));
    };
}

export function onProxy(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onProxy());
    };
}

export function onProxyDone(options: ProxyDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onProxyDone(options));
    };
}

export function onProxyFail(options: any): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onProxyFail(options));
    };
}

export function setObjectsToContext(options: ObjectsToContextOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setObjectsToContext(options));
    };
}

export function onCreateIssue(options: CreateIssueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCreateIssue(options));
    };
}

export function onRegisterIssuable(options: RegisterIssuableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onRegisterIssuable(options));
    };
}

export function onUnregisterIssuable(options: UnregisterIssuableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUnregisterIssuable(options));
    };
}

export function onRegisterPrintable(options: RegisterPrintableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onRegisterPrintable(options));
    };
}

export function onUnregisterPrintable(options: UnregisterPrintableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUnregisterPrintable(options));
    };
}

export function onViewableSelect(options: ViewableSelectOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onViewableSelect(options));
    };
}

export function onMarkupsSetData(options: SetMarkupsDataOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMarkupsSetData(options));
    };
}
export function onMarkupsSetExternalHandling(options: SetMarkupsExternalHandlingOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMarkupsSetExternalHandling(options));
    };
}

export function onViewableNavigate(options: ViewableNavigateOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onViewableNavigate(options));
    };
}

export function onViewable(options: ViewableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onViewable(options));
    };
}

export function onViewerToken(options: ViewerTokenOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onViewerToken(options));
    };
}
export function onViewerTokenRequest(options: ViewerTokenRequestOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onViewerTokenRequest(options));
    };
}
export function onActiveViewables(options: ActiveViewablesOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onActiveViewables(options));
    };
}
