import { AttributeInfo, InstanceInfo } from "../../../packages/Api/data/instances/types";

export const getValueWithUnit = (attribute: AttributeInfo): string => {
    let out = attribute.value;
    if (attribute.type.unit) {
        out += ` ${attribute.type.unit}`;
    }
    return out;
}

export const getInstanceProperties = (instance: InstanceInfo): any[] => {
    const group = `${instance.position.code} - ${instance.globalID}`
    let props = [
        {
            displayName: "Global ID",
            displayValue: instance.globalID,
            displayCategory: group
        },
        {
            displayName: "Name",
            displayValue: instance.name,
            displayCategory: group
        },
        {
            displayName: "Position",
            displayValue: `${instance.position.code} - ${instance.position.name}`,
            displayCategory: group,
        }
    ];
    instance.attributes?.forEach(a => {
        props = props.concat([
            {
                displayName: a.name,
                displayValue: getValueWithUnit(a),
                displayCategory: group,
            }
        ]);
    });
    instance.derivedInstances?.forEach(derivedInstance => {
        props = props.concat(getInstanceProperties(derivedInstance));
    });
    return props;
}