import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { AutocompleteData } from "mui-rff";
import { INormalizationFunction, IStepperAttributeFormValues } from "../../components/Mapping/StepAttribute/type";
import { IQuery, IQueryOperator } from "../../components/Mapping/ElementQueryBuilder/type";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork, IDeletePositionsResponse,
    IDerivedPosition,
    IElementsPosition,
    IManualPosition,
    IPositionFormConfiguration, IUpdateAttachedEquipmentRequestData,
    IUpdateAttachedMaterialRequestData,
    IUpdateAttachedWorkRequestData,
    IUpdateManualPositionRequestData,
    IUpdatePositionBasicsRequestData,
    MaterialType,
} from "../../packages/Api/data/elements/types";
import { IMappingAttribute, IMappingElements } from "../../packages/Api/data/mapping/types";
import {
    IMappingMaterial,
    IMappingMaterialAddRequest,
    IMappingMaterialUpdateRequest,
} from "../../packages/Api/data/material/types";
import { IMappingWork, IMappingWorkAddRequest, IMappingWorkUpdateRequest } from "../../packages/Api/data/work/types";
import { IMappingContext } from "./type";
import {
    IMappingEquipment,
    IMappingEquipmentAddRequest,
    IMappingEquipmentUpdateRequest,
} from "../../packages/Api/data/equipment/types";
import {
    IGraphics,
    IGraphicsAddRequest,
    IGraphicsPattern,
    IGraphicsUpdateRequest,
} from "../../packages/Api/data/graphics/types";
import { ProjectFileVersionViewable } from "../../graphql/generated/graphql";
import { InstancesAttributes } from "../../packages/Api/data/lookup/types";

/**
 * Action types
 */
export enum ActionTypes {
    // mapping positions
    OnMappingPositions = "mapping/ON_MAPPING_POSITIONS",
    OnMappingPositionsDone = "mapping/ON_MAPPING_POSITIONS_DONE",
    OnMappingPositionsFail = "mapping/ON_MAPPING_POSITIONS_FAIL",

    // delete mapping positions
    OnDeleteMappingPositions = "mapping/ON_DELETE_MAPPING_POSITIONS",
    OnDeleteMappingPositionsDone = "mapping/ON_DELETE_MAPPING_POSITIONS_DONE",
    OnDeleteMappingPositionsFail = "mapping/ON_DELETE_MAPPING_POSITIONS_FAIL",

    // checked master/catalog
    OnCheckedMaster = "mapping/ON_CHECKED_MASTER",
    OnCheckedCatalog = "mapping/ON_CHECKED_CATALOG",

    // catalog
    SetDetailPosition = "mapping/SET_DETAIL_POSITION",
    SetMasterLeafPositions = "mapping/SET_MASTER_LEAF_POSITIONS",
    SetCatalogLeafPositions = "mapping/SET_CATALOG_LEAF_POSITIONS",
    SetDetailContext = "mapping/SET_DETAIL_CONTEXT",

    // catalog positions IDS
    OnCatalogPositionIDs = "mapping/ON_CATALOG_POSITION_IDS",
    OnCatalogPositionIDsDone = "mapping/ON_CATALOG_POSITION_IDS_DONE",
    OnCatalogPositionIDsFail = "mapping/ON_CATALOG_POSITION_IDS_FAIL",

    // add catalog positions IDS
    OnAddCatalogPositionIDs = "mapping/ON_ADD_CATALOG_POSITION_IDS",
    OnAddCatalogPositionIDsDone = "mapping/ON_ADD_CATALOG_POSITION_IDS_DONE",
    OnAddCatalogPositionIDsFail = "mapping/ON_ADD_CATALOG_POSITION_IDS_FAIL",

    // delete catalog positions IDS
    OnDeleteCatalogPositionIDs = "mapping/ON_DELETE_CATALOG_POSITION_IDS",
    OnDeleteCatalogPositionIDsDone = "mapping/ON_DELETE_CATALOG_POSITION_IDS_DONE",
    OnDeleteCatalogPositionIDsFail = "mapping/ON_DELETE_CATALOG_POSITION_IDS_FAIL",

    // element query
    OnElementQuery = "mapping/ON_ELEMENT_QUERY",
    OnElementQueryDone = "mapping/ON_ELEMENT_QUERY_DONE",
    OnElementQueryFail = "mapping/ON_ELEMENT_QUERY_FAIL",

    SetElementQuery = "mapping/SET_ELEMENT_QUERY",
    OnSelectViewable = "mapping/ON_SELECT_VIEWABLE",

    OnInstancesAttributesQuery = "mapping/ON_INSTANCES_ATTRIBUTES_QUERY",
    OnInstancesAttributesDone = "mapping/ON_INSTANCES_ATTRIBUTES_DONE",
    OnInstancesAttributesFail = "mapping/ON_INSTANCES_ATTRIBUTES_FAIL",

    // save element query
    OnSaveElementQuery = "mapping/ON_SAVE_ELEMENT_QUERY",
    OnSaveElementQueryDone = "mapping/ON_SAVE_ELEMENT_QUERY_DONE",
    OnSaveElementQueryFail = "mapping/ON_SAVE_ELEMENT_QUERY_FAIL",

    // element query operators
    OnElementQueryOperators = "mapping/ON_ELEMENT_QUERY_OPERATORS",
    OnElementQueryOperatorsDone = "mapping/ON_ELEMENT_QUERY_OPERATORS_DONE",
    OnElementQueryOperatorsFail = "mapping/ON_ELEMENT_QUERY_OPERATORS_FAIL",

    // derived positions
    OnDerivedPositions = "mapping/ON_DERIVED_POSITIONS",
    OnDerivedPositionsDone = "mapping/ON_DERIVED_POSITIONS_DONE",
    OnDerivedPositionsFail = "mapping/ON_DERIVED_POSITIONS_FAIL",

    // delete derived positions
    OnDeleteDerivedPositions = "mapping/ON_DERIVED_POSITIONS_DELETE",
    OnDeleteDerivedPositionsDone = "mapping/ON_DERIVED_POSITIONS_DELETE_DONE",
    OnDeleteDerivedPositionsFail = "mapping/ON_DERIVED_POSITIONS_DELETE_FAIL",

    // update derived positions
    OnUpdateDerivedPosition = "mapping/ON_DERIVED_POSITION_UPDATE",
    OnUpdateDerivedPositionDone = "mapping/ON_DERIVED_POSITION_UPDATE_DONE",
    OnUpdateDerivedPositionFail = "mapping/ON_DERIVED_POSITION_UPDATE_FAIL",

    // basic positions
    OnBasicPositions = "mapping/ON_BASIC_POSITIONS",
    OnBasicPositionsDone = "mapping/ON_BASIC_POSITIONS_DONE",
    OnBasicPositionsFail = "mapping/ON_BASIC_POSITIONS_FAIL",

    // delete basic positions
    OnDeleteBasicPositions = "mapping/ON_BASIC_POSITIONS_DELETE",
    OnDeleteBasicPositionsDone = "mapping/ON_BASIC_POSITIONS_DELETE_DONE",
    OnDeleteBasicPositionsFail = "mapping/ON_BASIC_POSITIONS_DELETE_FAIL",

    // update basic positions
    OnUpdateBasicPosition = "mapping/ON_BASIC_POSITION_UPDATE",
    OnUpdateBasicPositionDone = "mapping/ON_BASIC_POSITION_UPDATE_DONE",
    OnUpdateBasicPositionFail = "mapping/ON_BASIC_POSITION_UPDATE_FAIL",
    
    // attribute
    OnAttribute = "mapping/ON_ATTRIBUTE",
    OnAttributeDone = "mapping/ON_ATTRIBUTE_DONE",
    OnAttributeFail = "mapping/ON_ATTRIBUTE_FAIL",

    OnAttributeFormInitDone = "mapping/ON_ATTRIBUTE_FORM_INIT_DONE",
    SetAttributeFormValues = "mapping/SET_ATTRIBUTE_FORM_VALUES",

    // work catalogue
    OnWorkCatalogue = "mapping/ON_WORK_CATALOGUE",
    OnWorkCatalogueDone = "mapping/ON_WORK_CATALOGUE_DONE",
    OnWorkCatalogueFail = "mapping/ON_WORK_CATALOGUE_FAIL",

    // add work catalogue
    OnAddWorkCatalogue = "mapping/ON_ADD_WORK_CATALOGUE",
    OnAddWorkCatalogueDone = "mapping/ON_ADD_WORK_CATALOGUE_DONE",
    OnAddWorkCatalogueFail = "mapping/ON_ADD_WORK_CATALOGUE_FAIL",

    // update work catalogue
    OnUpdateWorkCatalogue = "mapping/ON_UPDATE_WORK_CATALOGUE",
    OnUpdateWorkCatalogueDone = "mapping/ON_UPDATE_WORK_CATALOGUE_DONE",
    OnUpdateWorkCatalogueFail = "mapping/ON_UPDATE_WORK_CATALOGUE_FAIL",

    // delete work catalogue
    OnDeleteWorkCatalogue = "mapping/ON_DELETE_WORK_CATALOGUE",
    OnDeleteWorkCatalogueDone = "mapping/ON_DELETE_WORK_CATALOGUE_DONE",
    OnDeleteWorkCatalogueFail = "mapping/ON_DELETE_WORK_CATALOGUE_FAIL",

    // graphics pattern catalogue
    OnGraphicsPatternCatalogue = "mapping/ON_GRAPHICS_PATTERN_CATALOGUE",
    OnGraphicsPatternCatalogueDone = "mapping/ON_GRAPHICS_PATTERN_CATALOGUE_DONE",
    OnGraphicsPatternCatalogueFail = "mapping/ON_GRAPHICS_PATTERN_CATALOGUE_FAIL",

    // graphics catalogue
    OnGraphicsCatalogue = "mapping/ON_GRAPHICS_CATALOGUE",
    OnGraphicsCatalogueDone = "mapping/ON_GRAPHICS_CATALOGUE_DONE",
    OnGraphicsCatalogueFail = "mapping/ON_GRAPHICS_CATALOGUE_FAIL",

    // add graphics catalogue
    OnAddGraphicsCatalogue = "mapping/ON_ADD_GRAPHICS_CATALOGUE",
    OnAddGraphicsCatalogueDone = "mapping/ON_ADD_GRAPHICS_CATALOGUE_DONE",
    OnAddGraphicsCatalogueFail = "mapping/ON_ADD_GRAPHICS_CATALOGUE_FAIL",

    // update graphics catalogue
    OnUpdateGraphicsCatalogue = "mapping/ON_UPDATE_GRAPHICS_CATALOGUE",
    OnUpdateGraphicsCatalogueDone = "mapping/ON_UPDATE_GRAPHICS_CATALOGUE_DONE",
    OnUpdateGraphicsCatalogueFail = "mapping/ON_UPDATE_GRAPHICS_CATALOGUE_FAIL",

    // delete graphics catalogue
    OnDeleteGraphicsCatalogue = "mapping/ON_DELETE_GRAPHICS_CATALOGUE",
    OnDeleteGraphicsCatalogueDone = "mapping/ON_DELETE_GRAPHICS_CATALOGUE_DONE",
    OnDeleteGraphicsCatalogueFail = "mapping/ON_DELETE_GRAPHICS_CATALOGUE_FAIL",


    // equipment catalogue
    OnEquipmentCatalogue = "mapping/ON_EQUIPMENT_CATALOGUE",
    OnEquipmentCatalogueDone = "mapping/ON_EQUIPMENT_CATALOGUE_DONE",
    OnEquipmentCatalogueFail = "mapping/ON_EQUIPMENT_CATALOGUE_FAIL",

    // add equipment catalogue
    OnAddEquipmentCatalogue = "mapping/ON_ADD_EQUIPMENT_CATALOGUE",
    OnAddEquipmentCatalogueDone = "mapping/ON_ADD_EQUIPMENT_CATALOGUE_DONE",
    OnAddEquipmentCatalogueFail = "mapping/ON_ADD_EQUIPMENT_CATALOGUE_FAIL",

    // update equipment catalogue
    OnUpdateEquipmentCatalogue = "mapping/ON_UPDATE_EQUIPMENT_CATALOGUE",
    OnUpdateEquipmentCatalogueDone = "mapping/ON_UPDATE_EQUIPMENT_CATALOGUE_DONE",
    OnUpdateEquipmentCatalogueFail = "mapping/ON_UPDATE_EQUIPMENT_CATALOGUE_FAIL",

    // delete equipment catalogue
    OnDeleteEquipmentCatalogue = "mapping/ON_DELETE_EQUIPMENT_CATALOGUE",
    OnDeleteEquipmentCatalogueDone = "mapping/ON_DELETE_EQUIPMENT_CATALOGUE_DONE",
    OnDeleteEquipmentCatalogueFail = "mapping/ON_DELETE_EQUIPMENT_CATALOGUE_FAIL",

    // material catalogue
    OnMaterialCatalogue = "mapping/ON_MATERIAL_CATALOGUE",
    OnMaterialCatalogueDone = "mapping/ON_MATERIAL_CATALOGUE_DONE",
    OnMaterialCatalogueFail = "mapping/ON_MATERIAL_CATALOGUE_FAIL",

    // add material catalogue
    OnAddMaterialCatalogue = "mapping/ON_ADD_MATERIAL_CATALOGUE",
    OnAddMaterialCatalogueDone = "mapping/ON_ADD_MATERIAL_CATALOGUE_DONE",
    OnAddMaterialCatalogueFail = "mapping/ON_ADD_MATERIAL_CATALOGUE_FAIL",

    // update material catalogue
    OnUpdateMaterialCatalogue = "mapping/ON_UPDATE_MATERIAL_CATALOGUE",
    OnUpdateMaterialCatalogueDone = "mapping/ON_UPDATE_MATERIAL_CATALOGUE_DONE",
    OnUpdateMaterialCatalogueFail = "mapping/ON_UPDATE_MATERIAL_CATALOGUE_FAIL",

    // delete material catalogue
    OnDeleteMaterialCatalogue = "mapping/ON_DELETE_MATERIAL_CATALOGUE",
    OnDeleteMaterialCatalogueDone = "mapping/ON_DELETE_MATERIAL_CATALOGUE_DONE",
    OnDeleteMaterialCatalogueFail = "mapping/ON_DELETE_MATERIAL_CATALOGUE_FAIL",

    // attached work
    OnAttachedWork = "mapping/ON_ATTACHED_WORK",
    OnAttachedWorkDone = "mapping/ON_ATTACHED_WORK_DONE",
    OnAttachedWorkFail = "mapping/ON_ATTACHED_WORK_FAIL",
    
    // attached graphics
    OnAttachedGraphics = "mapping/ON_ATTACHED_GRAPHICS",
    OnAttachedGraphicsDone = "mapping/ON_ATTACHED_GRAPHICS_DONE",
    OnAttachedGraphicsFail = "mapping/ON_ATTACHED_GRAPHICS_FAIL",

    // update attached work
    OnUpdateAttachedWork = "mapping/ON_UPDATE_ATTACHED_WORK",
    OnUpdateAttachedWorkDone = "mapping/ON_UPDATE_ATTACHED_WORK_DONE",
    OnUpdateAttachedWorkFail = "mapping/ON_UPDATE_ATTACHED_WORK_FAIL",

    // delete attached work
    OnDeleteAttachedWork = "mapping/ON_DELETE_ATTACHED_WORK",
    OnDeleteAttachedWorkDone = "mapping/ON_DELETE_ATTACHED_WORK_DONE",
    OnDeleteAttachedWorkFail = "mapping/ON_DELETE_ATTACHED_WORK_FAIL",

    // add work to position
    OnAddWorkToPosition = "mapping/ON_ADD_WORK_TO_POSITION",
    OnAddWorkToPositionDone = "mapping/ON_ADD_WORK_TO_POSITION_DONE",
    OnAddWorkToPositionFail = "mapping/ON_ADD_WORK_TO_POSITION_FAIL",

    // add graphics to position
    OnAddGraphicsToPosition = "mapping/ON_ADD_GRAPHICS_TO_POSITION",
    OnAddGraphicsToPositionDone = "mapping/ON_ADD_GRAPHICS_TO_POSITION_DONE",
    OnAddGraphicsToPositionFail = "mapping/ON_ADD_GRAPHICS_TO_POSITION_FAIL",

    // attached equipment
    OnAttachedEquipment = "mapping/ON_ATTACHED_EQUIPMENT",
    OnAttachedEquipmentDone = "mapping/ON_ATTACHED_EQUIPMENT_DONE",
    OnAttachedEquipmentFail = "mapping/ON_ATTACHED_EQUIPMENT_FAIL",

    // update attached equipment
    OnUpdateAttachedEquipment = "mapping/ON_UPDATE_ATTACHED_EQUIPMENT",
    OnUpdateAttachedEquipmentDone = "mapping/ON_UPDATE_ATTACHED_EQUIPMENT_DONE",
    OnUpdateAttachedEquipmentFail = "mapping/ON_UPDATE_ATTACHED_EQUIPMENT_FAIL",

    // delete attached equipment
    OnDeleteAttachedEquipment = "mapping/ON_DELETE_ATTACHED_EQUIPMENT",
    OnDeleteAttachedEquipmentDone = "mapping/ON_DELETE_ATTACHED_EQUIPMENT_DONE",
    OnDeleteAttachedEquipmentFail = "mapping/ON_DELETE_ATTACHED_EQUIPMENT_FAIL",

    // add equipment to position
    OnAddEquipmentToPosition = "mapping/ON_ADD_EQUIPMENT_TO_POSITION",
    OnAddEquipmentToPositionDone = "mapping/ON_ADD_EQUIPMENT_TO_POSITION_DONE",
    OnAddEquipmentToPositionFail = "mapping/ON_ADD_EQUIPMENT_TO_POSITION_FAIL",

    // attached material
    OnAttachedMaterial = "mapping/ON_ATTACHED_MATERIAL",
    OnAttachedMaterialDone = "mapping/ON_ATTACHED_MATERIAL_DONE",
    OnAttachedMaterialFail = "mapping/ON_ATTACHED_MATERIAL_FAIL",

    // update attached material
    OnUpdateAttachedMaterial = "mapping/ON_UPDATE_ATTACHED_MATERIAL",
    OnUpdateAttachedMaterialDone = "mapping/ON_UPDATE_ATTACHED_MATERIAL_DONE",
    OnUpdateAttachedMaterialFail = "mapping/ON_UPDATE_ATTACHED_MATERIAL_FAIL",

    // delete attached material
    OnDeleteAttachedMaterial = "mapping/ON_DELETE_ATTACHED_MATERIAL",
    OnDeleteAttachedMaterialDone = "mapping/ON_DELETE_ATTACHED_MATERIAL_DONE",
    OnDeleteAttachedMaterialFail = "mapping/ON_DELETE_ATTACHED_MATERIAL_FAIL",

    // add material to position
    OnAddMaterialToPosition = "mapping/ON_ADD_MATERIAL_TO_POSITION",
    OnAddMaterialToPositionDone = "mapping/ON_ADD_MATERIAL_TO_POSITION_DONE",
    OnAddMaterialToPositionFail = "mapping/ON_ADD_MATERIAL_TO_POSITION_FAIL",

    // unit price
    OnUnitPrice = "mapping/ON_UNIT_PRICE",
    OnUnitPriceDone = "mapping/ON_UNIT_PRICE_DONE",
    OnUnitPriceFail = "mapping/ON_UNIT_PRICE_FAIL",

    // update position basics
    OnUpdatePositionBasics = "mapping/ON_UPDATE_POSITION_BASICS",
    OnUpdatePositionBasicsDone = "mapping/ON_UPDATE_POSITION_BASICS_DONE",
    OnUpdatePositionBasicsFail = "mapping/ON_UPDATE_POSITION_BASICS_FAIL",

    // update manual position
    OnUpdateManualPosition = "mapping/ON_UPDATE_MANUAL_POSITION",
    OnUpdateManualPositionDone = "mapping/ON_UPDATE_MANUAL_POSITION_DONE",
    OnUpdateManualPositionFail = "mapping/ON_UPDATE_MANUAL_POSITION_FAIL",

    // get manual position
    OnManualPosition = "mapping/ON_MANUAL_POSITION",
    OnManualPositionDone = "mapping/ON_MANUAL_POSITION_DONE",
    OnManualPositionFail = "mapping/ON_MANUAL_POSITION_FAIL",

    // get position form configuration
    OnPositionFormConfiguration = "mapping/ON_POSITION_FORM_CONFIGURATION",
    OnPositionFormConfigurationDone = "mapping/ON_POSITION_FORM_CONFIGURATION_DONE",
    OnPositionFormConfigurationFail = "mapping/ON_POSITION_FORM_CONFIGURATION_FAIL",
}

/**
 * Action types options
 */
// mapping positions
export type OnMappingPositionsOptions = {
    token: string;
};
export type OnMappingPositionsDoneOptions = {
    positions: IElementsPosition[];
};
export type OnMappingPositionsFailOptions = {
    error?: any;
};

// delete mapping positions
export type OnDeleteMappingPositionsOptions = {
    token: string;
    positionIDs: number[];
};
export type OnDeleteMappingPositionsDoneOptions = {
    deletePositionsResponse?: IDeletePositionsResponse;
};
export type OnDeleteMappingPositionsFailOptions = {
    error?: any;
};

// checked master/catalog
export type OnCheckedMasterOptions = {
    checked: number[];
};
export type OnCheckedCatalogOptions = {
    checked: number[];
};

// catalog
export type SetDetailPositionOptions = {
    detailPosition: IElementsPosition;
};
export type SetMasterLeafPositionsOptions = {
    leafPositions: IElementsPosition[];
};
export type SetCatalogLeafPositionsOptions = {
    leafPositions: IElementsPosition[];
};
export type SetDetailContextOptions = {
    context: IMappingContext;
};

// catalog positions IDS
export type OnCatalogPositionIDsOptions = {
    token: string;
    projectID: number;
};
export type OnCatalogPositionIDsDoneOptions = {
    positionIDs: number[];
};
export type OnCatalogPositionIDsFailOptions = {
    error?: any;
};

// add catalog positions IDS
export type OnAddCatalogPositionIDsOptions = {
    token: string;
    projectID: number;
    positionIDs: number[];
};
export type OnAddCatalogPositionIDsDoneOptions = {
    positionIDs: number[];
};
export type OnAddCatalogPositionIDsFailOptions = {
    error?: any;
};

// delete catalog positions IDS
export type OnDeleteCatalogPositionIDsOptions = {
    token: string;
    projectID: number;
    positionIDs: number[];
};
export type OnDeleteCatalogPositionIDsDoneOptions = {
    positionIDs: number[];
};
export type OnDeleteCatalogPositionIDsFailOptions = {
    error?: any;
};

// element query
export type SetElementQueryOptions = {
    query: IQuery | null;
};
export type OnSelectViewableOptions = {
    viewable: ProjectFileVersionViewable | null;
};
export type OnElementQueryOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnElementQueryDoneOptions = {
    query: IQuery | null;
    psets: string[];
    attributes: string[];
};
export type OnElementQueryFailOptions = {
    error?: any;
};

// instances attributes
export type OnInstancesAttributesOptions = {
    token: string,
    viewableID: number,
    rule: IMappingElements
};
export type OnInstancesAttributesDoneOptions = {
    instancesAttributes: InstancesAttributes | undefined;
};
export type OnInstancesAttributesFailOptions = {
    error?: any;
};


// element query operators
export type OnElementQueryOperatorsOptions = {
    token: string;
};
export type OnElementQueryOperatorsDoneOptions = {
    operators: IQueryOperator[] | null;
};
export type OnElementQueryOperatorsFailOptions = {
    error?: any;
};

// derived positions
export type OnDerivedPositionsOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnDerivedPositionsDoneOptions = {
    positions: IDerivedPosition[];
};
export type OnDerivedPositionsFailOptions = {
    error?: any;
};

// delete derived positions
export type OnDeleteDerivedPositionsOptions = {
    token: string;
    derivedPositionIDs: number[];
};
export type OnDeleteDerivedPositionsDoneOptions = {
    positions: IDerivedPosition[];
};
export type OnDeleteDerivedPositionsFailOptions = {
    error?: any;
};

// update derived position
export type OnUpdateDerivedPositionOptions = {
    token: string;
    derivedPositionID: number;
    derivedPosition: IDerivedPosition;
    projectID: number;
    positionID: number;
};
export type OnUpdateDerivedPositionDoneOptions = {
    position: IDerivedPosition;
};
export type OnUpdateDerivedPositionFailOptions = {
    error?: any;
};

// basic positions
export type OnBasicPositionsOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnBasicPositionsDoneOptions = {
    positions: IDerivedPosition[];
};
export type OnBasicPositionsFailOptions = {
    error?: any;
};

// delete basic positions
export type OnDeleteBasicPositionsOptions = {
    token: string;
    basicPositionIDs: number[];
};
export type OnDeleteBasicPositionsDoneOptions = {
    positions: IDerivedPosition[];
};
export type OnDeleteBasicPositionsFailOptions = {
    error?: any;
};

// update basic position
export type OnUpdateBasicPositionOptions = {
    token: string;
    basicPositionID: number;
    basicPosition: IDerivedPosition;
    projectID: number;
    positionID: number;
};
export type OnUpdateBasicPositionDoneOptions = {
    position: IDerivedPosition;
};
export type OnUpdateBasicPositionFailOptions = {
    error?: any;
};

// attribute
export type OnAttributeOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnAttributeDoneOptions = {
    attribute: IMappingAttribute;
    autocompletePsets: AutocompleteData[];
    autocompleteAttributes: AutocompleteData[];
    formValues: IStepperAttributeFormValues;
};
export type OnAttributeFailOptions = {
    error?: any;
};

export type SetAttributeFormValuesOptions = {
    formValues: IStepperAttributeFormValues;
};
export type OnAttributeFormInitDoneOptions = {
    normalizationFunctions: INormalizationFunction[];
};

// work catalogue
export type OnWorkCatalogueOptions = {
    token: string;
};
export type OnWorkCatalogueDoneOptions = {
    catalogue: IMappingWork[];
};
export type OnWorkCatalogueFailOptions = {
    error?: any;
};

// add work catalogue
export type OnAddWorkCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    request: IMappingWorkAddRequest;
};
export type OnAddWorkCatalogueDoneOptions = {
    catalogue: IMappingWork[];
};
export type OnAddWorkCatalogueFailOptions = {
    error?: any;
};

// update work catalogue
export type OnUpdateWorkCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    workID: number;
    request: IMappingWorkUpdateRequest;
};
export type OnUpdateWorkCatalogueDoneOptions = {
    catalogue: IMappingWork[];
};
export type OnUpdateWorkCatalogueFailOptions = {
    error?: any;
};

// delete work catalogue
export type OnDeleteWorkCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    workID: number;
};
export type OnDeleteWorkCatalogueDoneOptions = {
    catalogue: IMappingWork[];
};
export type OnDeleteWorkCatalogueFailOptions = {
    error?: any;
};

// graphics pattern catalogue
export type OnGraphicsPatternCatalogueOptions = {
    token: string;
};
export type OnGraphicsPatternCatalogueDoneOptions = {
    catalogue: IGraphicsPattern[];
};
export type OnGraphicsPatternCatalogueFailOptions = {
    error?: any;
};

// graphics catalogue
export type OnGraphicsCatalogueOptions = {
    token: string;
};
export type OnGraphicsCatalogueDoneOptions = {
    catalogue: IGraphics[];
};
export type OnGraphicsCatalogueFailOptions = {
    error?: any;
};

// add graphics catalogue
export type OnAddGraphicsCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    request: IGraphicsAddRequest;
};
export type OnAddGraphicsCatalogueDoneOptions = {
    catalogue: IGraphics[];
};
export type OnAddGraphicsCatalogueFailOptions = {
    error?: any;
};

// update graphics catalogue
export type OnUpdateGraphicsCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    graphicsID: number;
    request: IGraphicsUpdateRequest;
};
export type OnUpdateGraphicsCatalogueDoneOptions = {
    catalogue: IGraphics[];
};
export type OnUpdateGraphicsCatalogueFailOptions = {
    error?: any;
};

// delete graphics catalogue
export type OnDeleteGraphicsCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    graphicsID: number;
};
export type OnDeleteGraphicsCatalogueDoneOptions = {
    catalogue: IGraphics[];
};
export type OnDeleteGraphicsCatalogueFailOptions = {
    error?: any;
};

// equipment catalogue
export type OnEquipmentCatalogueOptions = {
    token: string;
};
export type OnEquipmentCatalogueDoneOptions = {
    catalogue: IMappingEquipment[];
};
export type OnEquipmentCatalogueFailOptions = {
    error?: any;
};

// add equipment catalogue
export type OnAddEquipmentCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    request: IMappingEquipmentAddRequest;
};
export type OnAddEquipmentCatalogueDoneOptions = {
    catalogue: IMappingEquipment[];
};
export type OnAddEquipmentCatalogueFailOptions = {
    error?: any;
};

// update equipment catalogue
export type OnUpdateEquipmentCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    equipmentID: number;
    request: IMappingEquipmentUpdateRequest;
};
export type OnUpdateEquipmentCatalogueDoneOptions = {
    catalogue: IMappingEquipment[];
};
export type OnUpdateEquipmentCatalogueFailOptions = {
    error?: any;
};

// delete equipment catalogue
export type OnDeleteEquipmentCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    equipmentID: number;
};
export type OnDeleteEquipmentCatalogueDoneOptions = {
    catalogue: IMappingEquipment[];
};
export type OnDeleteEquipmentCatalogueFailOptions = {
    error?: any;
};

// material catalogue
export type OnMaterialCatalogueOptions = {
    token: string;
};
export type OnMaterialCatalogueDoneOptions = {
    catalogue: IMappingMaterial[];
};
export type OnMaterialCatalogueFailOptions = {
    error?: any;
};

// add material catalogue
export type OnAddMaterialCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    request: IMappingMaterialAddRequest;
};
export type OnAddMaterialCatalogueDoneOptions = {
    catalogue: IMappingMaterial[];
};
export type OnAddMaterialCatalogueFailOptions = {
    error?: any;
};

// update material catalogue
export type OnUpdateMaterialCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    materialID: number;
    request: IMappingMaterialUpdateRequest;
};
export type OnUpdateMaterialCatalogueDoneOptions = {
    catalogue: IMappingMaterial[];
};
export type OnUpdateMaterialCatalogueFailOptions = {
    error?: any;
};

// delete material catalogue
export type OnDeleteMaterialCatalogueOptions = {
    token: string;
    projectID: number;
    positionID: number;
    materialID: number;
};
export type OnDeleteMaterialCatalogueDoneOptions = {
    catalogue: IMappingMaterial[];
};
export type OnDeleteMaterialCatalogueFailOptions = {
    error?: any;
};

// attached work
export type OnAttachedWorkOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnAttachedWorkDoneOptions = {
    attachedWork: IAttachedWork[];
};
export type OnAttachedWorkFailOptions = {
    error?: any;
};

// attached graphics
export type OnAttachedGraphicsOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnAttachedGraphicsDoneOptions = {
    attachedGraphics: IGraphics;
};
export type OnAttachedGraphicsFailOptions = {
    error?: any;
};

// update attached work
export type OnUpdateAttachedWorkOptions = {
    token: string;
    projectID: number;
    positionID: number;
    attachedWorkID: number;
    data: IUpdateAttachedWorkRequestData;
};
export type OnUpdateAttachedWorkDoneOptions = {
    attachedWork: IAttachedWork;
};
export type OnUpdateAttachedWorkFailOptions = {
    error?: any;
};

// delete attached work
export type OnDeleteAttachedWorkOptions = {
    token: string;
    projectID: number;
    positionID: number;
    attachedWorkIDs: number[];
};
export type OnDeleteAttachedWorkDoneOptions = {
    attachedWork: IAttachedWork[];
};
export type OnDeleteAttachedWorkFailOptions = {
    error?: any;
};

// add work to position
export type OnAddWorkToPositionOptions = {
    token: string;
    projectID: number;
    positionID: number;
    workIDs: number[];
};
export type OnAddWorkToPositionDoneOptions = {
    attachedWork: IAttachedWork[];
};
export type OnAddWorkToPositionFailOptions = {
    error?: any;
};

// add graphics to position
export type OnAddGraphicsToPositionOptions = {
    token: string;
    projectID: number;
    positionID: number;
    graphicsID: number;
};
export type OnAddGraphicsToPositionDoneOptions = {
    attachedGraphics: IGraphics;
};
export type OnAddGraphicsToPositionFailOptions = {
    error?: any;
};


// attached equipment
export type OnAttachedEquipmentOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnAttachedEquipmentDoneOptions = {
    attachedEquipment: IAttachedEquipment[];
};
export type OnAttachedEquipmentFailOptions = {
    error?: any;
};

// update attached equipment
export type OnUpdateAttachedEquipmentOptions = {
    token: string;
    projectID: number;
    positionID: number;
    attachedEquipmentID: number;
    data: IUpdateAttachedEquipmentRequestData;
};
export type OnUpdateAttachedEquipmentDoneOptions = {
    attachedEquipment: IAttachedEquipment;
};
export type OnUpdateAttachedEquipmentFailOptions = {
    error?: any;
};

// delete attached equipment
export type OnDeleteAttachedEquipmentOptions = {
    token: string;
    projectID: number;
    positionID: number;
    attachedEquipmentIDs: number[];
};
export type OnDeleteAttachedEquipmentDoneOptions = {
    attachedEquipment: IAttachedEquipment[];
};
export type OnDeleteAttachedEquipmentFailOptions = {
    error?: any;
};

// add equipment to position
export type OnAddEquipmentToPositionOptions = {
    token: string;
    projectID: number;
    positionID: number;
    equipmentIDs: number[];
};
export type OnAddEquipmentToPositionDoneOptions = {
    attachedEquipment: IAttachedEquipment[];
};
export type OnAddEquipmentToPositionFailOptions = {
    error?: any;
};

// attached material
export type OnAttachedMaterialOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnAttachedMaterialDoneOptions = {
    attachedMaterial: IAttachedMaterial[];
};
export type OnAttachedMaterialFailOptions = {
    error?: any;
};

// update attached material
export type OnUpdateAttachedMaterialOptions = {
    token: string;
    projectID: number;
    positionID: number;
    attachedMaterialID: number;
    data: IUpdateAttachedMaterialRequestData;
};
export type OnUpdateAttachedMaterialDoneOptions = {
    attachedMaterial: IAttachedMaterial;
};
export type OnUpdateAttachedMaterialFailOptions = {
    error?: any;
};

// delete attached material
export type OnDeleteAttachedMaterialOptions = {
    token: string;
    projectID: number;
    positionID: number;
    attachedMaterialIDs: number[];
};
export type OnDeleteAttachedMaterialDoneOptions = {
    attachedMaterial: IAttachedMaterial[];
};
export type OnDeleteAttachedMaterialFailOptions = {
    error?: any;
};

// add material to position
export type OnAddMaterialToPositionOptions = {
    token: string;
    projectID: number;
    positionID: number;
    materialType: MaterialType;
    materialIDs: number[];
};
export type OnAddMaterialToPositionDoneOptions = {
    attachedMaterial: IAttachedMaterial[];
};
export type OnAddMaterialToPositionFailOptions = {
    error?: any;
};

// unit price
export type OnUnitPriceOptions = {
    token: string;
    projectID: number;
    positionID: number;
};
export type OnUnitPriceDoneOptions = {
    unitPrice: number;
};
export type OnUnitPriceFailOptions = {
    error?: any;
};

// update position basics
export type OnUpdatePositionBasicsOptions = {
    token: string;
    projectID: number;
    positionID: number;
    data: IUpdatePositionBasicsRequestData;
};
export type OnUpdatePositionBasicsDoneOptions = {
    positionBasics: IElementsPosition;
};
export type OnUpdatePositionBasicsFailOptions = {
    error?: any;
};

// update manual position
export type OnUpdateManualPositionOptions = {
    token: string;
    projectID: number;
    positionID: number;
    data: IUpdateManualPositionRequestData;
};
export type OnUpdateManualPositionDoneOptions = {
    manualPosition: IManualPosition;
};
export type OnUpdateManualPositionFailOptions = {
    error?: any;
};

// get manual position
export type OnManualPositionOptions = {
    token: string;
    positionID: number;
};
export type OnManualPositionDoneOptions = {
    manualPosition: IManualPosition;
};
export type OnManualPositionFailOptions = {
    error?: any;
};

// get position form configuration
export type OnPositionFormConfigurationOptions = {
    token: string;
};
export type OnPositionFormConfigurationDoneOptions = {
    formConfiguration: IPositionFormConfiguration;
};
export type OnPositionFormConfigurationFailOptions = {
    error?: any;
};

/**
 * Actions
 */
export const Actions = {
    // mapping positions
    onMappingPositions: (options: OnMappingPositionsOptions) => createAction(ActionTypes.OnMappingPositions, options),
    onMappingPositionsDone: (options: OnMappingPositionsDoneOptions) =>
        createAction(ActionTypes.OnMappingPositionsDone, options),
    onMappingPositionsFail: (options: OnMappingPositionsFailOptions) =>
        createAction(ActionTypes.OnMappingPositionsFail, options),

    // delete mapping positions
    onDeleteMappingPositions: (options: OnDeleteMappingPositionsOptions) =>
        createAction(ActionTypes.OnDeleteMappingPositions, options),
    onDeleteMappingPositionsDone: (options: OnDeleteMappingPositionsDoneOptions) =>
        createAction(ActionTypes.OnDeleteMappingPositionsDone, options),
    onDeleteMappingPositionsFail: (options: OnDeleteMappingPositionsFailOptions) =>
        createAction(ActionTypes.OnDeleteMappingPositionsFail, options),

    // checked master/catalog
    onCheckedMaster: (options: OnCheckedMasterOptions) => createAction(ActionTypes.OnCheckedMaster, options),
    onCheckedCatalog: (options: OnCheckedCatalogOptions) => createAction(ActionTypes.OnCheckedCatalog, options),

    // catalog
    setDetailPosition: (options: SetDetailPositionOptions) => createAction(ActionTypes.SetDetailPosition, options),
    setMasterLeafPositions: (options: SetMasterLeafPositionsOptions) =>
        createAction(ActionTypes.SetMasterLeafPositions, options),
    setCatalogLeafPositions: (options: SetCatalogLeafPositionsOptions) =>
        createAction(ActionTypes.SetCatalogLeafPositions, options),
    setDetailContext: (options: SetDetailContextOptions) => createAction(ActionTypes.SetDetailContext, options),

    // catalog positions IDS
    onCatalogPositionIDs: (options: OnCatalogPositionIDsOptions) =>
        createAction(ActionTypes.OnCatalogPositionIDs, options),
    onCatalogPositionIDsDone: (options: OnCatalogPositionIDsDoneOptions) =>
        createAction(ActionTypes.OnCatalogPositionIDsDone, options),
    onCatalogPositionIDsFail: (options: OnCatalogPositionIDsFailOptions) =>
        createAction(ActionTypes.OnCatalogPositionIDsFail, options),

    // add catalog positions IDS
    onAddCatalogPositionIDs: (options: OnAddCatalogPositionIDsOptions) =>
        createAction(ActionTypes.OnAddCatalogPositionIDs, options),
    onAddCatalogPositionIDsDone: (options: OnAddCatalogPositionIDsDoneOptions) =>
        createAction(ActionTypes.OnAddCatalogPositionIDsDone, options),
    onAddCatalogPositionIDsFail: (options: OnAddCatalogPositionIDsFailOptions) =>
        createAction(ActionTypes.OnAddCatalogPositionIDsFail, options),

    // delete catalog positions IDS
    onDeleteCatalogPositionIDs: (options: OnDeleteCatalogPositionIDsOptions) =>
        createAction(ActionTypes.OnDeleteCatalogPositionIDs, options),
    onDeleteCatalogPositionIDsDone: (options: OnDeleteCatalogPositionIDsDoneOptions) =>
        createAction(ActionTypes.OnDeleteCatalogPositionIDsDone, options),
    onDeleteCatalogPositionIDsFail: (options: OnDeleteCatalogPositionIDsFailOptions) =>
        createAction(ActionTypes.OnDeleteCatalogPositionIDsFail, options),

    // element query
    setElementQuery: (options: SetElementQueryOptions) => createAction(ActionTypes.SetElementQuery, options),
    onElementQuery: (options: OnElementQueryOptions) => createAction(ActionTypes.OnElementQuery, options),
    onElementQueryDone: (options: OnElementQueryDoneOptions) => createAction(ActionTypes.OnElementQueryDone, options),
    onElementQueryFail: (options?: OnElementQueryFailOptions) => createAction(ActionTypes.OnElementQueryFail, options),

    onSelectViewable: (options: OnSelectViewableOptions) => createAction(ActionTypes.OnSelectViewable, options),

    onInstancesAttributes: (options: OnInstancesAttributesOptions) => createAction(ActionTypes.OnInstancesAttributesQuery, options),
    onInstancesAttributesDone: (options: OnInstancesAttributesDoneOptions) => createAction(ActionTypes.OnInstancesAttributesDone, options),
    onInstancesAttributesFail: (options: OnInstancesAttributesFailOptions) => createAction(ActionTypes.OnInstancesAttributesFail, options),

    // element query operators
    onElementQueryOperators: (options: OnElementQueryOperatorsOptions) =>
        createAction(ActionTypes.OnElementQueryOperators, options),
    onElementQueryOperatorsDone: (options: OnElementQueryOperatorsDoneOptions) =>
        createAction(ActionTypes.OnElementQueryOperatorsDone, options),
    onElementQueryOperatorsFail: (options?: OnElementQueryOperatorsFailOptions) =>
        createAction(ActionTypes.OnElementQueryOperatorsFail, options),

    // derived positions
    onDerivedPositions: (options: OnDerivedPositionsOptions) => createAction(ActionTypes.OnDerivedPositions, options),
    onDerivedPositionsDone: (options: OnDerivedPositionsDoneOptions) =>
        createAction(ActionTypes.OnDerivedPositionsDone, options),
    onDerivedPositionsFail: (options?: OnDerivedPositionsFailOptions) =>
        createAction(ActionTypes.OnDerivedPositionsFail, options),

    // delete derived positions
    onDeleteDerivedPositions: (options: OnDeleteDerivedPositionsOptions) =>
        createAction(ActionTypes.OnDeleteDerivedPositions, options),
    onDeleteDerivedPositionsDone: (options: OnDeleteDerivedPositionsDoneOptions) =>
        createAction(ActionTypes.OnDeleteDerivedPositionsDone, options),
    onDeleteDerivedPositionsFail: (options?: OnDeleteDerivedPositionsFailOptions) =>
        createAction(ActionTypes.OnDeleteDerivedPositionsFail, options),

    // update derived position
    onUpdateDerivedPosition: (options: OnUpdateDerivedPositionOptions) =>
        createAction(ActionTypes.OnUpdateDerivedPosition, options),
    onUpdateDerivedPositionDone: (options: OnUpdateDerivedPositionDoneOptions) =>
        createAction(ActionTypes.OnUpdateDerivedPositionDone, options),
    onUpdateDerivedPositionFail: (options?: OnUpdateDerivedPositionFailOptions) =>
        createAction(ActionTypes.OnUpdateDerivedPositionFail, options),

    // basic positions
    onBasicPositions: (options: OnBasicPositionsOptions) => createAction(ActionTypes.OnBasicPositions, options),
    onBasicPositionsDone: (options: OnBasicPositionsDoneOptions) =>
        createAction(ActionTypes.OnBasicPositionsDone, options),
    onBasicPositionsFail: (options?: OnBasicPositionsFailOptions) =>
        createAction(ActionTypes.OnBasicPositionsFail, options),

    // delete basic positions
    onDeleteBasicPositions: (options: OnDeleteBasicPositionsOptions) =>
        createAction(ActionTypes.OnDeleteBasicPositions, options),
    onDeleteBasicPositionsDone: (options: OnDeleteBasicPositionsDoneOptions) =>
        createAction(ActionTypes.OnDeleteBasicPositionsDone, options),
    onDeleteBasicPositionsFail: (options?: OnDeleteBasicPositionsFailOptions) =>
        createAction(ActionTypes.OnDeleteBasicPositionsFail, options),

    // update basic position
    onUpdateBasicPosition: (options: OnUpdateBasicPositionOptions) =>
        createAction(ActionTypes.OnUpdateBasicPosition, options),
    onUpdateBasicPositionDone: (options: OnUpdateBasicPositionDoneOptions) =>
        createAction(ActionTypes.OnUpdateBasicPositionDone, options),
    onUpdateBasicPositionFail: (options?: OnUpdateBasicPositionFailOptions) =>
        createAction(ActionTypes.OnUpdateBasicPositionFail, options),

    // attribute
    onAttribute: (options: OnAttributeOptions) => createAction(ActionTypes.OnAttribute, options),
    onAttributeDone: (options: OnAttributeDoneOptions) => createAction(ActionTypes.OnAttributeDone, options),
    onAttributeFail: (options?: OnAttributeFailOptions) => createAction(ActionTypes.OnAttributeFail, options),

    setAttributeFormValues: (options: SetAttributeFormValuesOptions) =>
        createAction(ActionTypes.SetAttributeFormValues, options),
    onAttributeFormInitDone: (options: OnAttributeFormInitDoneOptions) =>
        createAction(ActionTypes.OnAttributeFormInitDone, options),

    // work catalogue
    onWorkCatalogue: (options: OnWorkCatalogueOptions) => createAction(ActionTypes.OnWorkCatalogue, options),
    onWorkCatalogueDone: (options: OnWorkCatalogueDoneOptions) =>
        createAction(ActionTypes.OnWorkCatalogueDone, options),
    onWorkCatalogueFail: (options?: OnWorkCatalogueFailOptions) =>
        createAction(ActionTypes.OnWorkCatalogueFail, options),

    // add work catalogue
    onAddWorkCatalogue: (options: OnAddWorkCatalogueOptions) => createAction(ActionTypes.OnAddWorkCatalogue, options),
    onAddWorkCatalogueDone: (options: OnAddWorkCatalogueDoneOptions) =>
        createAction(ActionTypes.OnAddWorkCatalogueDone, options),
    onAddWorkCatalogueFail: (options?: OnAddWorkCatalogueFailOptions) =>
        createAction(ActionTypes.OnAddWorkCatalogueFail, options),

    // update work catalogue
    onUpdateWorkCatalogue: (options: OnUpdateWorkCatalogueOptions) =>
        createAction(ActionTypes.OnUpdateWorkCatalogue, options),
    onUpdateWorkCatalogueDone: (options: OnUpdateWorkCatalogueDoneOptions) =>
        createAction(ActionTypes.OnUpdateWorkCatalogueDone, options),
    onUpdateWorkCatalogueFail: (options?: OnUpdateWorkCatalogueFailOptions) =>
        createAction(ActionTypes.OnUpdateWorkCatalogueFail, options),

    // delete work catalogue
    onDeleteWorkCatalogue: (options: OnDeleteWorkCatalogueOptions) =>
        createAction(ActionTypes.OnDeleteWorkCatalogue, options),
    onDeleteWorkCatalogueDone: (options: OnDeleteWorkCatalogueDoneOptions) =>
        createAction(ActionTypes.OnDeleteWorkCatalogueDone, options),
    onDeleteWorkCatalogueFail: (options?: OnDeleteWorkCatalogueFailOptions) =>
        createAction(ActionTypes.OnDeleteWorkCatalogueFail, options),

    // graphics pattern catalogue
    onGraphicsPatternCatalogue: (options: OnGraphicsPatternCatalogueOptions) => createAction(ActionTypes.OnGraphicsPatternCatalogue, options),
    onGraphicsPatternCatalogueDone: (options: OnGraphicsPatternCatalogueDoneOptions) =>
        createAction(ActionTypes.OnGraphicsPatternCatalogueDone, options),
    onGraphicsPatternCatalogueFail: (options?: OnGraphicsPatternCatalogueFailOptions) =>
        createAction(ActionTypes.OnGraphicsPatternCatalogueFail, options),

    // graphics catalogue
    onGraphicsCatalogue: (options: OnGraphicsCatalogueOptions) => createAction(ActionTypes.OnGraphicsCatalogue, options),
    onGraphicsCatalogueDone: (options: OnGraphicsCatalogueDoneOptions) =>
        createAction(ActionTypes.OnGraphicsCatalogueDone, options),
    onGraphicsCatalogueFail: (options?: OnGraphicsCatalogueFailOptions) =>
        createAction(ActionTypes.OnGraphicsCatalogueFail, options),

    // add graphics catalogue
    onAddGraphicsCatalogue: (options: OnAddGraphicsCatalogueOptions) => createAction(ActionTypes.OnAddGraphicsCatalogue, options),
    onAddGraphicsCatalogueDone: (options: OnAddGraphicsCatalogueDoneOptions) =>
        createAction(ActionTypes.OnAddGraphicsCatalogueDone, options),
    onAddGraphicsCatalogueFail: (options?: OnAddGraphicsCatalogueFailOptions) =>
        createAction(ActionTypes.OnAddGraphicsCatalogueFail, options),

    // update graphics catalogue
    onUpdateGraphicsCatalogue: (options: OnUpdateGraphicsCatalogueOptions) =>
        createAction(ActionTypes.OnUpdateGraphicsCatalogue, options),
    onUpdateGraphicsCatalogueDone: (options: OnUpdateGraphicsCatalogueDoneOptions) =>
        createAction(ActionTypes.OnUpdateGraphicsCatalogueDone, options),
    onUpdateGraphicsCatalogueFail: (options?: OnUpdateGraphicsCatalogueFailOptions) =>
        createAction(ActionTypes.OnUpdateGraphicsCatalogueFail, options),

    // delete graphics catalogue
    onDeleteGraphicsCatalogue: (options: OnDeleteGraphicsCatalogueOptions) =>
        createAction(ActionTypes.OnDeleteGraphicsCatalogue, options),
    onDeleteGraphicsCatalogueDone: (options: OnDeleteGraphicsCatalogueDoneOptions) =>
        createAction(ActionTypes.OnDeleteGraphicsCatalogueDone, options),
    onDeleteGraphicsCatalogueFail: (options?: OnDeleteGraphicsCatalogueFailOptions) =>
        createAction(ActionTypes.OnDeleteGraphicsCatalogueFail, options),

    // equipment catalogue
    onEquipmentCatalogue: (options: OnEquipmentCatalogueOptions) => createAction(ActionTypes.OnEquipmentCatalogue, options),
    onEquipmentCatalogueDone: (options: OnEquipmentCatalogueDoneOptions) =>
        createAction(ActionTypes.OnEquipmentCatalogueDone, options),
    onEquipmentCatalogueFail: (options?: OnEquipmentCatalogueFailOptions) =>
        createAction(ActionTypes.OnEquipmentCatalogueFail, options),

    // add equipment catalogue
    onAddEquipmentCatalogue: (options: OnAddEquipmentCatalogueOptions) => createAction(ActionTypes.OnAddEquipmentCatalogue, options),
    onAddEquipmentCatalogueDone: (options: OnAddEquipmentCatalogueDoneOptions) =>
        createAction(ActionTypes.OnAddEquipmentCatalogueDone, options),
    onAddEquipmentCatalogueFail: (options?: OnAddEquipmentCatalogueFailOptions) =>
        createAction(ActionTypes.OnAddEquipmentCatalogueFail, options),

    // update equipment catalogue
    onUpdateEquipmentCatalogue: (options: OnUpdateEquipmentCatalogueOptions) =>
        createAction(ActionTypes.OnUpdateEquipmentCatalogue, options),
    onUpdateEquipmentCatalogueDone: (options: OnUpdateEquipmentCatalogueDoneOptions) =>
        createAction(ActionTypes.OnUpdateEquipmentCatalogueDone, options),
    onUpdateEquipmentCatalogueFail: (options?: OnUpdateEquipmentCatalogueFailOptions) =>
        createAction(ActionTypes.OnUpdateEquipmentCatalogueFail, options),

    // delete equipment catalogue
    onDeleteEquipmentCatalogue: (options: OnDeleteEquipmentCatalogueOptions) =>
        createAction(ActionTypes.OnDeleteEquipmentCatalogue, options),
    onDeleteEquipmentCatalogueDone: (options: OnDeleteEquipmentCatalogueDoneOptions) =>
        createAction(ActionTypes.OnDeleteEquipmentCatalogueDone, options),
    onDeleteEquipmentCatalogueFail: (options?: OnDeleteEquipmentCatalogueFailOptions) =>
        createAction(ActionTypes.OnDeleteEquipmentCatalogueFail, options),

    // material catalogue
    onMaterialCatalogue: (options: OnMaterialCatalogueOptions) =>
        createAction(ActionTypes.OnMaterialCatalogue, options),
    onMaterialCatalogueDone: (options: OnMaterialCatalogueDoneOptions) =>
        createAction(ActionTypes.OnMaterialCatalogueDone, options),
    onMaterialCatalogueFail: (options?: OnMaterialCatalogueFailOptions) =>
        createAction(ActionTypes.OnMaterialCatalogueFail, options),

    // add material catalogue
    onAddMaterialCatalogue: (options: OnAddMaterialCatalogueOptions) =>
        createAction(ActionTypes.OnAddMaterialCatalogue, options),
    onAddMaterialCatalogueDone: (options: OnAddMaterialCatalogueDoneOptions) =>
        createAction(ActionTypes.OnAddMaterialCatalogueDone, options),
    onAddMaterialCatalogueFail: (options?: OnAddMaterialCatalogueFailOptions) =>
        createAction(ActionTypes.OnAddMaterialCatalogueFail, options),

    // update material catalogue
    onUpdateMaterialCatalogue: (options: OnUpdateMaterialCatalogueOptions) =>
        createAction(ActionTypes.OnUpdateMaterialCatalogue, options),
    onUpdateMaterialCatalogueDone: (options: OnUpdateMaterialCatalogueDoneOptions) =>
        createAction(ActionTypes.OnUpdateMaterialCatalogueDone, options),
    onUpdateMaterialCatalogueFail: (options?: OnUpdateMaterialCatalogueFailOptions) =>
        createAction(ActionTypes.OnUpdateMaterialCatalogueFail, options),

    // delete material catalogue
    onDeleteMaterialCatalogue: (options: OnDeleteMaterialCatalogueOptions) =>
        createAction(ActionTypes.OnDeleteMaterialCatalogue, options),
    onDeleteMaterialCatalogueDone: (options: OnDeleteMaterialCatalogueDoneOptions) =>
        createAction(ActionTypes.OnDeleteMaterialCatalogueDone, options),
    onDeleteMaterialCatalogueFail: (options?: OnDeleteMaterialCatalogueFailOptions) =>
        createAction(ActionTypes.OnDeleteMaterialCatalogueFail, options),

    // attached work
    onAttachedWork: (options: OnAttachedWorkOptions) => createAction(ActionTypes.OnAttachedWork, options),
    onAttachedWorkDone: (options: OnAttachedWorkDoneOptions) => createAction(ActionTypes.OnAttachedWorkDone, options),
    onAttachedWorkFail: (options?: OnAttachedWorkFailOptions) => createAction(ActionTypes.OnAttachedWorkFail, options),

    // attached graphics
    onAttachedGraphics: (options: OnAttachedGraphicsOptions) => createAction(ActionTypes.OnAttachedGraphics, options),
    onAttachedGraphicsDone: (options: OnAttachedGraphicsDoneOptions) => createAction(ActionTypes.OnAttachedGraphicsDone, options),
    onAttachedGraphicsFail: (options?: OnAttachedGraphicsFailOptions) => createAction(ActionTypes.OnAttachedGraphicsFail, options),

    // update attached work
    onUpdateAttachedWork: (options: OnUpdateAttachedWorkOptions) =>
        createAction(ActionTypes.OnUpdateAttachedWork, options),
    onUpdateAttachedWorkDone: (options: OnUpdateAttachedWorkDoneOptions) =>
        createAction(ActionTypes.OnUpdateAttachedWorkDone, options),
    onUpdateAttachedWorkFail: (options?: OnUpdateAttachedWorkFailOptions) =>
        createAction(ActionTypes.OnUpdateAttachedWorkFail, options),

    // delete attached work
    onDeleteAttachedWork: (options: OnDeleteAttachedWorkOptions) =>
        createAction(ActionTypes.OnDeleteAttachedWork, options),
    onDeleteAttachedWorkDone: (options: OnDeleteAttachedWorkDoneOptions) =>
        createAction(ActionTypes.OnDeleteAttachedWorkDone, options),
    onDeleteAttachedWorkFail: (options?: OnDeleteAttachedWorkFailOptions) =>
        createAction(ActionTypes.OnDeleteAttachedWorkFail, options),

    // add work to position
    onAddWorkToPosition: (options: OnAddWorkToPositionOptions) =>
        createAction(ActionTypes.OnAddWorkToPosition, options),
    onAddWorkToPositionDone: (options: OnAddWorkToPositionDoneOptions) =>
        createAction(ActionTypes.OnAddWorkToPositionDone, options),
    onAddWorkToPositionFail: (options?: OnAddWorkToPositionFailOptions) =>
        createAction(ActionTypes.OnAddWorkToPositionFail, options),

    // add graphics to position
    onAddGraphicsToPosition: (options: OnAddGraphicsToPositionOptions) =>
        createAction(ActionTypes.OnAddGraphicsToPosition, options),
    onAddGraphicsToPositionDone: (options: OnAddGraphicsToPositionDoneOptions) =>
        createAction(ActionTypes.OnAddGraphicsToPositionDone, options),
    onAddGraphicsToPositionFail: (options?: OnAddGraphicsToPositionFailOptions) =>
        createAction(ActionTypes.OnAddGraphicsToPositionFail, options),

    // attached equipment
    onAttachedEquipment: (options: OnAttachedEquipmentOptions) => createAction(ActionTypes.OnAttachedEquipment, options),
    onAttachedEquipmentDone: (options: OnAttachedEquipmentDoneOptions) => createAction(ActionTypes.OnAttachedEquipmentDone, options),
    onAttachedEquipmentFail: (options?: OnAttachedEquipmentFailOptions) => createAction(ActionTypes.OnAttachedEquipmentFail, options),

    // update attached equipment
    onUpdateAttachedEquipment: (options: OnUpdateAttachedEquipmentOptions) =>
        createAction(ActionTypes.OnUpdateAttachedEquipment, options),
    onUpdateAttachedEquipmentDone: (options: OnUpdateAttachedEquipmentDoneOptions) =>
        createAction(ActionTypes.OnUpdateAttachedEquipmentDone, options),
    onUpdateAttachedEquipmentFail: (options?: OnUpdateAttachedEquipmentFailOptions) =>
        createAction(ActionTypes.OnUpdateAttachedEquipmentFail, options),

    // delete attached equipment
    onDeleteAttachedEquipment: (options: OnDeleteAttachedEquipmentOptions) =>
        createAction(ActionTypes.OnDeleteAttachedEquipment, options),
    onDeleteAttachedEquipmentDone: (options: OnDeleteAttachedEquipmentDoneOptions) =>
        createAction(ActionTypes.OnDeleteAttachedEquipmentDone, options),
    onDeleteAttachedEquipmentFail: (options?: OnDeleteAttachedEquipmentFailOptions) =>
        createAction(ActionTypes.OnDeleteAttachedEquipmentFail, options),

    // add equipment to position
    onAddEquipmentToPosition: (options: OnAddEquipmentToPositionOptions) =>
        createAction(ActionTypes.OnAddEquipmentToPosition, options),
    onAddEquipmentToPositionDone: (options: OnAddEquipmentToPositionDoneOptions) =>
        createAction(ActionTypes.OnAddEquipmentToPositionDone, options),
    onAddEquipmentToPositionFail: (options?: OnAddEquipmentToPositionFailOptions) =>
        createAction(ActionTypes.OnAddEquipmentToPositionFail, options),

    // attached material
    onAttachedMaterial: (options: OnAttachedMaterialOptions) => createAction(ActionTypes.OnAttachedMaterial, options),
    onAttachedMaterialDone: (options: OnAttachedMaterialDoneOptions) =>
        createAction(ActionTypes.OnAttachedMaterialDone, options),
    onAttachedMaterialFail: (options?: OnAttachedMaterialFailOptions) =>
        createAction(ActionTypes.OnAttachedMaterialFail, options),

    // update attached material
    onUpdateAttachedMaterial: (options: OnUpdateAttachedMaterialOptions) =>
        createAction(ActionTypes.OnUpdateAttachedMaterial, options),
    onUpdateAttachedMaterialDone: (options: OnUpdateAttachedMaterialDoneOptions) =>
        createAction(ActionTypes.OnUpdateAttachedMaterialDone, options),
    onUpdateAttachedMaterialFail: (options?: OnUpdateAttachedMaterialFailOptions) =>
        createAction(ActionTypes.OnUpdateAttachedMaterialFail, options),

    // delete attached material
    onDeleteAttachedMaterial: (options: OnDeleteAttachedMaterialOptions) =>
        createAction(ActionTypes.OnDeleteAttachedMaterial, options),
    onDeleteAttachedMaterialDone: (options: OnDeleteAttachedMaterialDoneOptions) =>
        createAction(ActionTypes.OnDeleteAttachedMaterialDone, options),
    onDeleteAttachedMaterialFail: (options?: OnDeleteAttachedMaterialFailOptions) =>
        createAction(ActionTypes.OnDeleteAttachedMaterialFail, options),

    // add material to position
    onAddMaterialToPosition: (options: OnAddMaterialToPositionOptions) =>
        createAction(ActionTypes.OnAddMaterialToPosition, options),
    onAddMaterialToPositionDone: (options: OnAddMaterialToPositionDoneOptions) =>
        createAction(ActionTypes.OnAddMaterialToPositionDone, options),
    onAddMaterialToPositionFail: (options?: OnAddMaterialToPositionFailOptions) =>
        createAction(ActionTypes.OnAddMaterialToPositionFail, options),

    // unit price
    onUnitPrice: (options: OnUnitPriceOptions) => createAction(ActionTypes.OnUnitPrice, options),
    onUnitPriceDone: (options: OnUnitPriceDoneOptions) => createAction(ActionTypes.OnUnitPriceDone, options),
    onUnitPriceFail: (options?: OnUnitPriceFailOptions) => createAction(ActionTypes.OnUnitPriceFail, options),

    // update position basics
    onUpdatePositionBasics: (options: OnUpdatePositionBasicsOptions) =>
        createAction(ActionTypes.OnUpdatePositionBasics, options),
    onUpdatePositionBasicsDone: (options: OnUpdatePositionBasicsDoneOptions) =>
        createAction(ActionTypes.OnUpdatePositionBasicsDone, options),
    onUpdatePositionBasicsFail: (options?: OnUpdatePositionBasicsFailOptions) =>
        createAction(ActionTypes.OnUpdatePositionBasicsFail, options),

    // update manual position
    onUpdateManualPosition: (options: OnUpdateManualPositionOptions) =>
        createAction(ActionTypes.OnUpdateManualPosition, options),
    onUpdateManualPositionDone: (options: OnUpdateManualPositionDoneOptions) =>
        createAction(ActionTypes.OnUpdateManualPositionDone, options),
    onUpdateManualPositionFail: (options?: OnUpdateManualPositionFailOptions) =>
        createAction(ActionTypes.OnUpdateManualPositionFail, options),

    // get manual position
    onManualPosition: (options: OnManualPositionOptions) => createAction(ActionTypes.OnManualPosition, options),
    onManualPositionDone: (options: OnManualPositionDoneOptions) =>
        createAction(ActionTypes.OnManualPositionDone, options),
    onManualPositionFail: (options?: OnManualPositionFailOptions) =>
        createAction(ActionTypes.OnManualPositionFail, options),

    // get position form configuration
    onPositionFormConfiguration: (options: OnPositionFormConfigurationOptions) =>
        createAction(ActionTypes.OnPositionFormConfiguration, options),
    onPositionFormConfigurationDone: (options: OnPositionFormConfigurationDoneOptions) =>
        createAction(ActionTypes.OnPositionFormConfigurationDone, options),
    onPositionFormConfigurationFail: (options?: OnPositionFormConfigurationFailOptions) =>
        createAction(ActionTypes.OnPositionFormConfigurationFail, options),
};

/**
 * Dispatch functions
 */
// mapping positions
export function onMappingPositions(options: OnMappingPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMappingPositions(options));
    };
}
export function onMappingPositionsDone(options: OnMappingPositionsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMappingPositionsDone(options));
    };
}
export function onMappingPositionsFail(options: OnMappingPositionsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMappingPositionsFail(options));
    };
}

// delete mapping positions
export function onDeleteMappingPositions(options: OnDeleteMappingPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteMappingPositions(options));
    };
}
export function onDeleteMappingPositionsDone(options: OnDeleteMappingPositionsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteMappingPositionsDone(options));
    };
}
export function onDeleteMappingPositionsFail(options: OnDeleteMappingPositionsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteMappingPositionsFail(options));
    };
}

// checked master/catalog
export function onCheckedMaster(options: OnCheckedMasterOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCheckedMaster(options));
    };
}
export function onCheckedCatalog(options: OnCheckedCatalogOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCheckedCatalog(options));
    };
}

// catalog
export function setDetailPosition(options: SetDetailPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setDetailPosition(options));
    };
}
export function setMasterLeafPositions(options: SetMasterLeafPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setMasterLeafPositions(options));
    };
}
export function setCatalogLeafPositions(options: SetCatalogLeafPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setCatalogLeafPositions(options));
    };
}
export function setDetailContext(options: SetDetailContextOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setDetailContext(options));
    };
}

// catalog positions IDS
export function onCatalogPositionIDs(options: OnCatalogPositionIDsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCatalogPositionIDs(options));
    };
}
export function onCatalogPositionIDsDone(options: OnCatalogPositionIDsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCatalogPositionIDsDone(options));
    };
}
export function onCatalogPositionIDsFail(options: OnCatalogPositionIDsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCatalogPositionIDsFail(options));
    };
}

// add catalog positions IDS
export function onAddCatalogPositionIDs(options: OnAddCatalogPositionIDsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddCatalogPositionIDs(options));
    };
}
export function onAddCatalogPositionIDsDone(options: OnAddCatalogPositionIDsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddCatalogPositionIDsDone(options));
    };
}
export function onAddCatalogPositionIDsFail(options: OnAddCatalogPositionIDsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddCatalogPositionIDsFail(options));
    };
}

// delete catalog positions IDS
export function onDeleteCatalogPositionIDs(options: OnDeleteCatalogPositionIDsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteCatalogPositionIDs(options));
    };
}
export function onDeleteCatalogPositionIDsDone(options: OnDeleteCatalogPositionIDsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteCatalogPositionIDsDone(options));
    };
}
export function onDeleteCatalogPositionIDsFail(options: OnDeleteCatalogPositionIDsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteCatalogPositionIDsFail(options));
    };
}

// element query
export function setElementQuery(options: SetElementQueryOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setElementQuery(options));
    };
}
export function onElementQuery(options: OnElementQueryOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onElementQuery(options));
    };
}
export function onElementQueryDone(options: OnElementQueryDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onElementQueryDone(options));
    };
}
export function onElementQueryFail(options?: OnElementQueryFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onElementQueryFail(options));
    };
}

export function onSelectViewable(options: OnSelectViewableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onSelectViewable(options));
    };
}

// instances attributes
export function onInstancesAttributes(options: OnInstancesAttributesOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onInstancesAttributes(options));
    };
}
export function onInstancesAttributesDone(options: OnInstancesAttributesDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onInstancesAttributesDone(options));
    };
}
export function onInstancesAttributesFail(options: OnInstancesAttributesFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onInstancesAttributesFail(options));
    };
}

// element query operators
export function onElementQueryOperators(options: OnElementQueryOperatorsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onElementQueryOperators(options));
    };
}
export function onElementQueryOperatorsDone(options: OnElementQueryOperatorsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onElementQueryOperatorsDone(options));
    };
}
export function onElementQueryOperatorsFail(options?: OnElementQueryOperatorsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onElementQueryOperatorsFail(options));
    };
}

// derived positions
export function onDerivedPositions(options: OnDerivedPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDerivedPositions(options));
    };
}
export function onDerivedPositionsDone(options: OnDerivedPositionsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDerivedPositionsDone(options));
    };
}
export function onDerivedPositionsFail(options?: OnDerivedPositionsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDerivedPositionsFail(options));
    };
}

// delete derived positions
export function onDeleteDerivedPositions(options: OnDeleteDerivedPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteDerivedPositions(options));
    };
}
export function onDeleteDerivedPositionsDone(options: OnDeleteDerivedPositionsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteDerivedPositionsDone(options));
    };
}
export function onDeleteDerivedPositionsFail(options?: OnDeleteDerivedPositionsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteDerivedPositionsFail(options));
    };
}

// update derived positions
export function onUpdateDerivedPosition(options: OnUpdateDerivedPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateDerivedPosition(options));
    };
}
export function onUpdateDerivedPositionDone(options: OnUpdateDerivedPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateDerivedPositionDone(options));
    };
}
export function onUpdateDerivedPositionsFail(options?: OnUpdateDerivedPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateDerivedPositionFail(options));
    };
}

// basic positions
export function onBasicPositions(options: OnBasicPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onBasicPositions(options));
    };
}
export function onBasicPositionsDone(options: OnBasicPositionsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onBasicPositionsDone(options));
    };
}
export function onBasicPositionsFail(options?: OnBasicPositionsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onBasicPositionsFail(options));
    };
}

// delete basic positions
export function onDeleteBasicPositions(options: OnDeleteBasicPositionsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteBasicPositions(options));
    };
}
export function onDeleteBasicPositionsDone(options: OnDeleteBasicPositionsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteBasicPositionsDone(options));
    };
}
export function onDeleteBasicPositionsFail(options?: OnDeleteBasicPositionsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteBasicPositionsFail(options));
    };
}

// update basic positions
export function onUpdateBasicPosition(options: OnUpdateBasicPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateBasicPosition(options));
    };
}
export function onUpdateBasicPositionDone(options: OnUpdateBasicPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateBasicPositionDone(options));
    };
}
export function onUpdateBasicPositionsFail(options?: OnUpdateBasicPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateBasicPositionFail(options));
    };
}

// attribute
export function onAttribute(options: OnAttributeOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttribute(options));
    };
}
export function onAttributeDone(options: OnAttributeDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttributeDone(options));
    };
}
export function onAttributeFail(options?: OnAttributeFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttributeFail(options));
    };
}

export function setAttributeFormValues(options: SetAttributeFormValuesOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setAttributeFormValues(options));
    };
}
export function onAttributeFormInitDone(options: OnAttributeFormInitDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttributeFormInitDone(options));
    };
}

// work catalogue
export function onWorkCatalogue(options: OnWorkCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onWorkCatalogue(options));
    };
}
export function onWorkCatalogueDone(options: OnWorkCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onWorkCatalogueDone(options));
    };
}
export function onWorkCatalogueFail(options?: OnWorkCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onWorkCatalogueFail(options));
    };
}

// add work catalogue
export function onAddWorkCatalogue(options: OnAddWorkCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddWorkCatalogue(options));
    };
}
export function onAddWorkCatalogueDone(options: OnAddWorkCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddWorkCatalogueDone(options));
    };
}
export function onAddWorkCatalogueFail(options?: OnAddWorkCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddWorkCatalogueFail(options));
    };
}

// update work catalogue
export function onUpdateWorkCatalogue(options: OnUpdateWorkCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateWorkCatalogue(options));
    };
}
export function onUpdateWorkCatalogueDone(options: OnUpdateWorkCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateWorkCatalogueDone(options));
    };
}
export function onUpdateWorkCatalogueFail(options?: OnUpdateWorkCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateWorkCatalogueFail(options));
    };
}

// delete work catalogue
export function onDeleteWorkCatalogue(options: OnDeleteWorkCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteWorkCatalogue(options));
    };
}
export function onDeleteWorkCatalogueDone(options: OnDeleteWorkCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteWorkCatalogueDone(options));
    };
}
export function onDeleteWorkCatalogueFail(options?: OnDeleteWorkCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteWorkCatalogueFail(options));
    };
}

// graphics pattern catalogue
export function onGraphicsPatternCatalogue(options: OnGraphicsPatternCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onGraphicsPatternCatalogue(options));
    };
}
export function onGraphicsPatternCatalogueDone(options: OnGraphicsPatternCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onGraphicsPatternCatalogueDone(options));
    };
}
export function onGraphicsPatternCatalogueFail(options?: OnGraphicsPatternCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onGraphicsPatternCatalogueFail(options));
    };
}

// graphics catalogue
export function onGraphicsCatalogue(options: OnGraphicsCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onGraphicsCatalogue(options));
    };
}
export function onGraphicsCatalogueDone(options: OnGraphicsCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onGraphicsCatalogueDone(options));
    };
}
export function onGraphicsCatalogueFail(options?: OnGraphicsCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onGraphicsCatalogueFail(options));
    };
}

// add graphics catalogue
export function onAddGraphicsCatalogue(options: OnAddGraphicsCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddGraphicsCatalogue(options));
    };
}
export function onAddGraphicsCatalogueDone(options: OnAddGraphicsCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddGraphicsCatalogueDone(options));
    };
}
export function onAddGraphicsCatalogueFail(options?: OnAddGraphicsCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddGraphicsCatalogueFail(options));
    };
}

// update graphics catalogue
export function onUpdateGraphicsCatalogue(options: OnUpdateGraphicsCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateGraphicsCatalogue(options));
    };
}
export function onUpdateGraphicsCatalogueDone(options: OnUpdateGraphicsCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateGraphicsCatalogueDone(options));
    };
}
export function onUpdateGraphicsCatalogueFail(options?: OnUpdateGraphicsCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateGraphicsCatalogueFail(options));
    };
}

// delete graphics catalogue
export function onDeleteGraphicsCatalogue(options: OnDeleteGraphicsCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteGraphicsCatalogue(options));
    };
}
export function onDeleteGraphicsCatalogueDone(options: OnDeleteGraphicsCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteGraphicsCatalogueDone(options));
    };
}
export function onDeleteGraphicsCatalogueFail(options?: OnDeleteGraphicsCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteGraphicsCatalogueFail(options));
    };
}

// equipment catalogue
export function onEquipmentCatalogue(options: OnEquipmentCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onEquipmentCatalogue(options));
    };
}
export function onEquipmentCatalogueDone(options: OnEquipmentCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onEquipmentCatalogueDone(options));
    };
}
export function onEquipmentCatalogueFail(options?: OnEquipmentCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onEquipmentCatalogueFail(options));
    };
}

// add equipment catalogue
export function onAddEquipmentCatalogue(options: OnAddEquipmentCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddEquipmentCatalogue(options));
    };
}
export function onAddEquipmentCatalogueDone(options: OnAddEquipmentCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddEquipmentCatalogueDone(options));
    };
}
export function onAddEquipmentCatalogueFail(options?: OnAddEquipmentCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddEquipmentCatalogueFail(options));
    };
}

// update equipment catalogue
export function onUpdateEquipmentCatalogue(options: OnUpdateEquipmentCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateEquipmentCatalogue(options));
    };
}
export function onUpdateEquipmentCatalogueDone(options: OnUpdateEquipmentCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateEquipmentCatalogueDone(options));
    };
}
export function onUpdateEquipmentCatalogueFail(options?: OnUpdateEquipmentCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateEquipmentCatalogueFail(options));
    };
}

// delete equipment catalogue
export function onDeleteEquipmentCatalogue(options: OnDeleteEquipmentCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteEquipmentCatalogue(options));
    };
}
export function onDeleteEquipmentCatalogueDone(options: OnDeleteEquipmentCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteEquipmentCatalogueDone(options));
    };
}
export function onDeleteEquipmentCatalogueFail(options?: OnDeleteEquipmentCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteEquipmentCatalogueFail(options));
    };
}

// material catalogue
export function onMaterialCatalogue(options: OnMaterialCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMaterialCatalogue(options));
    };
}
export function onMaterialCatalogueDone(options: OnMaterialCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMaterialCatalogueDone(options));
    };
}
export function onMaterialCatalogueFail(options?: OnMaterialCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onMaterialCatalogueFail(options));
    };
}

// add material catalogue
export function onAddMaterialCatalogue(options: OnAddMaterialCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddMaterialCatalogue(options));
    };
}
export function onAddMaterialCatalogueDone(options: OnAddMaterialCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddMaterialCatalogueDone(options));
    };
}
export function onAddMaterialCatalogueFail(options?: OnAddMaterialCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddMaterialCatalogueFail(options));
    };
}

// update material catalogue
export function onUpdateMaterialCatalogue(options: OnUpdateMaterialCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateMaterialCatalogue(options));
    };
}
export function onUpdateMaterialCatalogueDone(options: OnUpdateMaterialCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateMaterialCatalogueDone(options));
    };
}
export function onUpdateMaterialCatalogueFail(options?: OnUpdateMaterialCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateMaterialCatalogueFail(options));
    };
}

// delete material catalogue
export function onDeleteMaterialCatalogue(options: OnDeleteMaterialCatalogueOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteMaterialCatalogue(options));
    };
}
export function onDeleteMaterialCatalogueDone(options: OnDeleteMaterialCatalogueDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteMaterialCatalogueDone(options));
    };
}
export function onDeleteMaterialCatalogueFail(options?: OnDeleteMaterialCatalogueFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteMaterialCatalogueFail(options));
    };
}

// attached work
export function onAttachedWork(options: OnAttachedWorkOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedWork(options));
    };
}
export function onAttachedWorkDone(options: OnAttachedWorkDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedWorkDone(options));
    };
}
export function onAttachedWorkFail(options?: OnAttachedWorkFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedWorkFail(options));
    };
}

// attached graphics
export function onAttachedGraphics(options: OnAttachedGraphicsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedGraphics(options));
    };
}
export function onAttachedGraphicsDone(options: OnAttachedGraphicsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedGraphicsDone(options));
    };
}
export function onAttachedGraphicsFail(options?: OnAttachedGraphicsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedGraphicsFail(options));
    };
}

// update attached work
export function onUpdateAttachedWork(options: OnUpdateAttachedWorkOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedWork(options));
    };
}
export function onUpdateAttachedWorkDone(options: OnUpdateAttachedWorkDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedWorkDone(options));
    };
}
export function onUpdateAttachedWorkFail(options?: OnUpdateAttachedWorkFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedWorkFail(options));
    };
}

// delete attached work
export function onDeleteAttachedWork(options: OnDeleteAttachedWorkOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedWork(options));
    };
}
export function onDeleteAttachedWorkDone(options: OnDeleteAttachedWorkDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedWorkDone(options));
    };
}
export function onDeleteAttachedWorkFail(options?: OnDeleteAttachedWorkFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedWorkFail(options));
    };
}

// add work to position
export function onAddWorkToPosition(options: OnAddWorkToPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddWorkToPosition(options));
    };
}
export function onAddWorkToPositionDone(options: OnAddWorkToPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddWorkToPositionDone(options));
    };
}
export function onAddWorkToPositionFail(options?: OnAddWorkToPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddWorkToPositionFail(options));
    };
}

// add graphics to position
export function onAddGraphicsToPosition(options: OnAddGraphicsToPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddGraphicsToPosition(options));
    };
}
export function onAddGraphicsToPositionDone(options: OnAddGraphicsToPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddGraphicsToPositionDone(options));
    };
}
export function onAddGraphicsToPositionFail(options?: OnAddGraphicsToPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddGraphicsToPositionFail(options));
    };
}

// attached equipment
export function onAttachedEquipment(options: OnAttachedEquipmentOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedEquipment(options));
    };
}
export function onAttachedEquipmentDone(options: OnAttachedEquipmentDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedEquipmentDone(options));
    };
}
export function onAttachedEquipmentFail(options?: OnAttachedEquipmentFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedEquipmentFail(options));
    };
}

// update attached equipment
export function onUpdateAttachedEquipment(options: OnUpdateAttachedEquipmentOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedEquipment(options));
    };
}
export function onUpdateAttachedEquipmentDone(options: OnUpdateAttachedEquipmentDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedEquipmentDone(options));
    };
}
export function onUpdateAttachedEquipmentFail(options?: OnUpdateAttachedEquipmentFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedEquipmentFail(options));
    };
}

// delete attached equipment
export function onDeleteAttachedEquipment(options: OnDeleteAttachedEquipmentOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedEquipment(options));
    };
}
export function onDeleteAttachedEquipmentDone(options: OnDeleteAttachedEquipmentDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedEquipmentDone(options));
    };
}
export function onDeleteAttachedEquipmentFail(options?: OnDeleteAttachedEquipmentFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedEquipmentFail(options));
    };
}

// add equipment to position
export function onAddEquipmentToPosition(options: OnAddEquipmentToPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddEquipmentToPosition(options));
    };
}
export function onAddEquipmentToPositionDone(options: OnAddEquipmentToPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddEquipmentToPositionDone(options));
    };
}
export function onAddEquipmentToPositionFail(options?: OnAddEquipmentToPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddEquipmentToPositionFail(options));
    };
}

// attached material
export function onAttachedMaterial(options: OnAttachedMaterialOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedMaterial(options));
    };
}
export function onAttachedMaterialDone(options: OnAttachedMaterialDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedMaterialDone(options));
    };
}
export function onAttachedMaterialFail(options?: OnAttachedMaterialFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAttachedMaterialFail(options));
    };
}

// update attached material
export function onUpdateAttachedMaterial(options: OnUpdateAttachedMaterialOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedMaterial(options));
    };
}
export function onUpdateAttachedMaterialDone(options: OnUpdateAttachedMaterialDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedMaterialDone(options));
    };
}
export function onUpdateAttachedMaterialFail(options?: OnUpdateAttachedMaterialFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateAttachedMaterialFail(options));
    };
}

// delete attached material
export function onDeleteAttachedMaterial(options: OnDeleteAttachedMaterialOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedMaterial(options));
    };
}
export function onDeleteAttachedMaterialDone(options: OnDeleteAttachedMaterialDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedMaterialDone(options));
    };
}
export function onDeleteAttachedMaterialFail(options?: OnDeleteAttachedMaterialFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onDeleteAttachedMaterialFail(options));
    };
}

// add material to position
export function onAddMaterialToPosition(options: OnAddMaterialToPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddMaterialToPosition(options));
    };
}
export function onAddMaterialToPositionDone(options: OnAddMaterialToPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddMaterialToPositionDone(options));
    };
}
export function onAddMaterialToPositionFail(options?: OnAddMaterialToPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onAddMaterialToPositionFail(options));
    };
}

// unit price
export function onUnitPrice(options: OnUnitPriceOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUnitPrice(options));
    };
}
export function onUnitPriceDone(options: OnUnitPriceDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUnitPriceDone(options));
    };
}
export function onUnitPriceFail(options?: OnUnitPriceFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUnitPriceFail(options));
    };
}

// update position basics
export function onUpdatePositionBasics(options: OnUpdatePositionBasicsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdatePositionBasics(options));
    };
}
export function onUpdatePositionBasicsDone(options: OnUpdatePositionBasicsDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdatePositionBasicsDone(options));
    };
}
export function onUpdatePositionBasicsFail(options?: OnUpdatePositionBasicsFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdatePositionBasicsFail(options));
    };
}

// update manual position
export function onUpdateManualPosition(options: OnUpdateManualPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateManualPosition(options));
    };
}
export function onUpdateManualPositionDone(options: OnUpdateManualPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateManualPositionDone(options));
    };
}
export function onUpdateManualPositionFail(options?: OnUpdateManualPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onUpdateManualPositionFail(options));
    };
}

// get manual position
export function onManualPosition(options: OnManualPositionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onManualPosition(options));
    };
}
export function onManualPositionDone(options: OnManualPositionDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onManualPositionDone(options));
    };
}
export function onManualPositionFail(options?: OnManualPositionFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onManualPositionFail(options));
    };
}

// get position form configuration
export function onPositionFormConfiguration(options: OnPositionFormConfigurationOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onPositionFormConfiguration(options));
    };
}
export function onPositionFormConfigurationDone(options: OnPositionFormConfigurationDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onPositionFormConfigurationDone(options));
    };
}
export function onPositionFormConfigurationFail(options?: OnPositionFormConfigurationFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onPositionFormConfigurationFail(options));
    };
}
