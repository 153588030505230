import React from "react";
import useStyles from "./styles";
import { Box } from "@material-ui/core";

interface IProps {
  children: React.ReactNode;
}

const ContextMenuItem = (props:IProps) => {

  const classes = useStyles();

  return (
    <Box className={classes.icon}>
      {props.children}
    </Box>
  )
}

export default ContextMenuItem
