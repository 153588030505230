import { DispatchAction } from "@iolabs/redux-utils";
import React from "react";
import { useDispatch } from "react-redux";
import EyeSwitcherButton, { ButtonType } from "../EyeSwitcherButton/EyeSwitcherButton";
import EyeSwitcher from "../EyeSwitcher/EyeSwitcher";

export enum ProjectViewerView {
    Viewer2D = 2,
    Viewer3D = 3,
}
interface IProjectViewerViewSwitcher {
    isViewer3D?: boolean;
    isViewer2D: boolean;
    onSet2D: () => void;
    onSet3D: () => void;
    onSplitView: () => void;
}

const ProjectViewerViewSwitcher: React.FC<IProjectViewerViewSwitcher> = ({
    isViewer3D,
    isViewer2D,
    onSet2D,
    onSet3D,
    onSplitView
}) => {
    const dispatch = useDispatch<DispatchAction>();


    const handleChangeView = (event: React.MouseEvent<HTMLElement>, newViews: ProjectViewerView[]) => {

    };

    const handleDefault = () => {
        onSplitView();
    };

    const handleClickAway = () => {

    };

    const handleShortPress = (newView: ProjectViewerView) => {
        switch (newView) {
            case ProjectViewerView.Viewer2D:
                onSet2D();
                break;
            case ProjectViewerView.Viewer3D:
                onSet3D();
                break;
        }
    };

    const render = () => {
        if (isViewer3D) {
            return (
                [
                    <EyeSwitcherButton
                        key={ProjectViewerView.Viewer2D}
                        buttonType={ButtonType.BOTTOM_FULL}
                        valueActive={false}
                        valueIcon="maze"
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(ProjectViewerView.Viewer2D)}
                    />
                ]
            );
        }
        if (isViewer2D) {
            return (
                [
                    <EyeSwitcherButton
                        key={ProjectViewerView.Viewer3D}
                        buttonType={ButtonType.BOTTOM_FULL}
                        valueActive={false}
                        valueIcon="cube"
                        externIcon="window-restore"
                        onShortPress={() => handleShortPress(ProjectViewerView.Viewer3D)}

                    />
                ]
            );
        }
        return (
            [
                <EyeSwitcherButton
                    key={ProjectViewerView.Viewer3D}
                    buttonType={ButtonType.BOTTOM_LEFT}
                    valueActive={false}
                    valueIcon="cube"
                    externIcon="window-restore"
                    onShortPress={() => handleShortPress(ProjectViewerView.Viewer3D)}
                />,
                <EyeSwitcherButton
                    key={ProjectViewerView.Viewer2D}
                    buttonType={ButtonType.BOTTOM_RIGHT}
                    valueActive={false}
                    valueIcon="maze"
                    externIcon="window-restore"
                    onShortPress={() => handleShortPress(ProjectViewerView.Viewer2D)}
                />
            ]
        );
    };

    return (
        <EyeSwitcher
            onChange={handleChangeView}
            onEyeClick={handleDefault}
            onClickAway={handleClickAway}
            value={[]}
        >
            {render()}
        </EyeSwitcher>
    );
};

export default ProjectViewerViewSwitcher;
