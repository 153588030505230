import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const pushpinSize = 20;

const useStyles = makeStyles(() =>
    createStyles({
        pushpin: {
            position: "absolute",
            zIndex: 100,
            overflow: "visible",
            transform: "translate(-50%, -50%)",
        },
        point: {
            position: "absolute",
            pointerEvents: "all",
            transform: "translate(-50%, -50%)",
            zIndex: 20,
            width: pushpinSize,
            height: pushpinSize,
            borderRadius: "100%",
            cursor: "pointer",
            borderWidth: "3px",
            borderStyle: "solid",
        },
        pointLarge: {
            width: pushpinSize * 1.5,
            height: pushpinSize * 1.5,
            borderWidth: "4px",
        },
        draggable: {
            cursor: "move",
        },
    })
);

export default useStyles;
