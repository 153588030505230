import { createStyles, darken, lighten, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
        },
        tabs: {
            minHeight: "30px",
        },
        tab: {
            minHeight: "30px",
            textTransform: "uppercase",
            color: darken(theme.palette.text.secondary, 0.2),
        },
        layerButton: {
            position: "absolute",
            left: theme.spacing(1),
            top: theme.spacing(1),
            zIndex: 20,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            "&:hover, &:focus": {
                backgroundColor: lighten(theme.palette.primary.main, 0.1),
            },
        },
        circularProgress: {
            color: theme.palette.primary.main,
            margin: "3px",
        },
        swipeableViews: {
            maxHeight: "calc(100vh - 170px)",
        },
        listItem: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        listItemText: {
            marginTop: "3px",
            marginBottom: "3px",
        },
    })
);

export default useStyles;
