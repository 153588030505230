export enum Emea {
    eu = "streamingV2_EU",
    default = "streamingV2",
}

export enum FileType {
    Geometry = "GeometryFile",
    Model = "ModelFile",
    Template = "ModelTemplateFile",
    Mapping = "MappingFile",
}

export enum ExternalSystem {
    Forge = "forge",
}

export type ViewerState = {
    api: string;
    urn: string;
    isEmea: boolean;
};
