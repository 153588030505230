import { defineMessages } from "react-intl";

export default defineMessages({

    labelItemNumber: {
        id: "components.mapping.stepWorkFormEquipment.labelItemNumber",
        defaultMessage: "Item Number", // Artikel Nr. o. G TIN
    },
    labelProductId: {
        id: "components.mapping.stepWorkFormEquipment.productId",
        defaultMessage: "Product-ID",
    },
    // __: {
    //     id: "components.mapping.stepWorkFormEquipment.",
    //     defaultMessage: "", // K.-Menge // todo translate de
    // },
    labelListPrice: {
        id: "components.mapping.stepWorkFormEquipment.labelListPrice",
        defaultMessage: "List price", // Li.-Preis
    },
    labelSurcharge: {
        id: "components.mapping.stepWorkFormEquipment.labelSurcharge",
        defaultMessage: "Surcharge", // Zuschlag
    },
    // __: {
    //     id: "components.mapping.stepWorkFormEquipment.",
    //     defaultMessage: "", // EP/Menge // todo translate de
    // },
});
