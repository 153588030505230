import numbro from "numbro";
import languageDE from "numbro/dist/languages/de-DE.min";
import React from "react";
import { AttributeType, DataType } from "../graphql/generated/graphql";

const numbroFormat: numbro.Format = {
    thousandSeparated: true,
    mantissa: 2,
    trimMantissa: true,
};

interface IFormatterProps {
    value: any;
    attributeType: AttributeType;
    disableUnit?: boolean;
}

const Formatter: React.FC<IFormatterProps> = ({ value, attributeType, disableUnit }) => {
    const getStringRepresentation = (value: any, attributeType: AttributeType) => {
        let numberValue;
        if (attributeType === null) {
            return value;
        }
        if (value === null) {
            return null;
        }
        switch (attributeType.dataType) {
            case DataType.DOUBLE:
                numberValue = parseFloat(value as string);
                break;
            case DataType.INT:
                numberValue = parseInt(value as string, 10);
                break;
            default:
                return value;
        }

        numbro.registerLanguage(languageDE);
        numbro.setLanguage("de-DE");

        return numbro(numberValue).format(numbroFormat);
    };

    const getValueWithUnit = (value: string, attributeType: AttributeType) => {
        if (attributeType === null) {
            return value;
        }
        if (value === null) {
            return "-";
        }
        const unit = normalizeUnit(attributeType.unit as string);
        // TODO: better detection, add some Mask to attribute type, to suppoort prefix/suffix, etc
        const unitSeparator = unit === "%" ? "" : " ";
        const valueWithUnit = `${value}${unitSeparator}${unit}`;
        return valueWithUnit.trim();
    };

    let stringRepresentation = getStringRepresentation(value, attributeType);
    if (!disableUnit) {
        stringRepresentation = getValueWithUnit(stringRepresentation, attributeType);
    }

    return <span>{stringRepresentation}</span>;
};

export const normalizeUnit = (unit?: string): string => {
    if (!unit) {
        return "";
    }
    const matchUnit = unit.match(/^(\w)+(\^)?([2,3])$/);

    if (matchUnit) {
        return `${matchUnit[1]}${matchUnit[3] === "2" ? "²" : "³"}`;
    }
    if (unit === "EUR") {
        return "€";
    }
    return unit;
};

export const formatNumber = (value: number): string => {
    numbro.registerLanguage(languageDE);
    numbro.setLanguage("de-DE");

    return numbro(value).format(numbroFormat);
};

export default Formatter;
