export type ExtractParamType = {
    nodeIds: string[];
    projectId: string;
    lastNodeId: string;
};

/**
 * Extract variables from URL param
 * @param nodeId
 */
export const extractParam = (nodeId: string): ExtractParamType => {
    let nodeIds: string[] = [];
    let projectId = "";
    let lastNodeId = "";

    if (nodeId) {
        nodeIds = nodeId.split("_");
        projectId = nodeIds.shift() as string;

        const [, ...middleNodeIds] = nodeIds;
        lastNodeId = middleNodeIds?.pop() as string;
    }
    return { nodeIds, projectId, lastNodeId };
};
