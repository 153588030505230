import DateFnsUtils from "@date-io/date-fns";
import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    TextField,
    useTheme,
} from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import { Skeleton } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ApolloError } from "apollo-client";
import clsx from "clsx";
import "date-fns";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Issue } from "../../../graphql/generated/graphql";
import { onIssueEdit } from "../../../redux/issue";
import Icon from "../../Icon/Icon";
import messages from "../CreateIssue/messages";
import useIssuesStyles from "../IssuesWrapper/styles";
import { IIssueState, IIssueTab } from "../type";

interface IEditIssueProps {
    issueEdit: Issue;
    issue: IIssueState;
    isValid: () => boolean;
    selectTypes: any[];
    selectStatuses: any[];
    selectCauses: any[];
    formConfigLoading: boolean;
    formConfigError: ApolloError | undefined;
    handleChangeTab: (tab: IIssueTab) => void;
    handleChange: (prop: keyof IIssueState) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (date: Date | null) => void;
    handleEdit: () => void;
}

const EditIssue: React.FC<IEditIssueProps> = ({
    issueEdit,
    issue,
    isValid,
    selectTypes,
    selectStatuses,
    selectCauses,
    formConfigLoading,
    formConfigError,
    handleChangeTab,
    handleChange,
    handleChangeDate,
    handleEdit,
}) => {
    const classes = useIssuesStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    const handleBackButton = () => {
        handleChangeTab(IIssueTab.List);
        dispatch(onIssueEdit({ issueEditId: "" }));
    };

    // translations
    const intl = useIntl();
    const transIssue = intl.formatMessage({ ...messages.issue });
    const transCancel = intl.formatMessage({ ...messages.cancel });
    const transSave = intl.formatMessage({ ...messages.save });
    const transSetAsVoid = intl.formatMessage({ ...messages.void });
    const transFieldType = intl.formatMessage({ ...messages.fieldType });
    const transFieldTypePlaceholder = intl.formatMessage({ ...messages.fieldTypePlaceholder });
    const transFieldStatus = intl.formatMessage({ ...messages.fieldStatus });
    const transFieldStatusPlaceholder = intl.formatMessage({ ...messages.fieldStatusPlaceholder });
    const transFieldTitle = intl.formatMessage({ ...messages.fieldTitle });
    const transFieldTitlePlaceholder = intl.formatMessage({ ...messages.fieldTitlePlaceholder });
    const transFieldDueDate = intl.formatMessage({ ...messages.fieldDueDate });
    const transFieldDueDatePlaceholder = intl.formatMessage({ ...messages.fieldDueDatePlaceholder });
    const transFieldLocation = intl.formatMessage({ ...messages.fieldLocation });
    const transFieldLocationPlaceholder = intl.formatMessage({ ...messages.fieldLocationPlaceholder });
    const transFieldRootCause = intl.formatMessage({ ...messages.fieldRootCause });
    const transFieldRootCausePlaceholder = intl.formatMessage({ ...messages.fieldRootCausePlaceholder });
    const transFieldDescription = intl.formatMessage({ ...messages.fieldDescription });
    const transFieldDescriptionPlaceholder = intl.formatMessage({ ...messages.fieldDescriptionPlaceholder });

    return (
        <>
            <IconButton aria-label="back" size="medium" className={classes.backButton} onClick={handleBackButton}>
                <Icon name="chevron-light-left" size={16} fill={theme.palette.grey["500"]} />
            </IconButton>
            <DialogTitle id="issues-dialog-title" className={clsx(classes.title, classes.titleBack)}>
                {transIssue} #{issue?.code}
            </DialogTitle>
            <DialogContent className={classes.content}>
                {formConfigLoading || formConfigError ? (
                    <Box className={classes.skeletonBox}>
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Divider className={classes.divider} />
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Divider className={classes.divider} />
                        <Skeleton variant="rect" height={119} />
                    </Box>
                ) : (
                    <Box className={classes.box}>
                        <Box className={classes.field}>
                            <TextField
                                id="select-type"
                                required
                                fullWidth
                                select
                                label={transFieldType}
                                variant="outlined"
                                margin="dense"
                                value={selectTypes?.length ? issue?.type : "0"}
                                onChange={handleChange("type")}
                                className={issue?.type === "0" ? classes.placeholder : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={0} value="0" disabled>
                                    {transFieldTypePlaceholder}
                                </MenuItem>
                                {selectTypes}
                            </TextField>
                        </Box>

                        <Box className={classes.field}>
                            <TextField
                                id="select-status"
                                required
                                fullWidth
                                select
                                label={transFieldStatus}
                                variant="outlined"
                                margin="dense"
                                value={selectStatuses?.length ? issue?.status : "0"}
                                onChange={handleChange("status")}
                                className={issue?.status === "0" ? classes.placeholder : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={0} value="0" disabled>
                                    {transFieldStatusPlaceholder}
                                </MenuItem>
                                {selectStatuses}
                            </TextField>
                        </Box>
                        <Box className={classes.field}>
                            <TextField
                                disabled
                                id="title-field"
                                required
                                fullWidth
                                label={transFieldTitle}
                                placeholder={transFieldTitlePlaceholder}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                value={issue?.title}
                                onChange={handleChange("title")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Divider className={classes.divider} />
                        {/* <Box className={classes.field}>
                        <TextField
                        disabled
                            id="select-assign-to"
                            select
                            fullWidth
                            label="Zuweisen zu" // Assign To
                            variant="outlined"
                            margin="dense"
                            value={issue?.assignTo}
                            onChange={handleChange("assignTo")}
                            className={issue?.assignTo === 0 ? classes.placeholder : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key={0} value="0" disabled>
                                Benutzer, Rolle o. Firma wählen...
                                 Select User, Role or Company...
                            </MenuItem>
                            {issueAssignTo.map((item: IIssueSelect, index: number) => {
                                return item.isCategory ? (
                                    <ListSubheader key={index} className={classes.listSubheader}>
                                        {item.name}
                                    </ListSubheader>
                                ) : (
                                    <MenuItem key={index} value={item.id}>
                                        {item.name}
                                        {item.description ? ` (${item.description})` : ""}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box> */}
                        <Box className={classes.field}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled
                                    id="datapicker-field"
                                    label={transFieldDueDate}
                                    placeholder={transFieldDueDatePlaceholder}
                                    variant="inline"
                                    inputVariant="outlined"
                                    margin="dense"
                                    disableToolbar
                                    format="MM/dd/yyyy"
                                    value={issue?.dueDate}
                                    onChange={handleChangeDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    keyboardIcon={<TodayIcon fontSize="small" />}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    className={classes.datepicker}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        {/* <Box className={classes.field}>
                        <TextField
                        disabled
                            id="location-field"
                            label="Standort" // Location
                            placeholder="Standort eingeben..." // Enter location...
                            type="text"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={issue?.location}
                            onChange={handleChange("location")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box> */}
                        <Box className={classes.field}>
                            <TextField
                                disabled
                                id="location-details-field"
                                label={transFieldLocation}
                                placeholder={transFieldLocationPlaceholder}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={issue?.locationDetails}
                                onChange={handleChange("locationDetails")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        {/* <Box className={classes.field}>
                        <TextField
                        disabled
                            id="select-owner"
                            select
                            label="Ersteller" // Owner
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={issue?.owner}
                            onChange={handleChange("owner")}
                            className={issue?.owner === 0 ? classes.placeholder : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key={0} value="0" disabled>
                                Auswählen...
                                 Select...
                            </MenuItem>
                            {issueOwners.map((item: IIssueSelect, index: number) => {
                                return (
                                    <MenuItem key={index} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box> */}
                        <Box className={classes.field}>
                            <TextField
                                disabled
                                id="select-root-cause"
                                select
                                label={transFieldRootCause}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={selectCauses?.length ? issue?.rootCause : "0"}
                                onChange={handleChange("rootCause")}
                                className={issue?.rootCause === "0" ? classes.placeholder : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={0} value="0" disabled>
                                    {transFieldRootCausePlaceholder}
                                </MenuItem>
                                {selectCauses}
                            </TextField>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.field}>
                            <TextField
                                disabled
                                id="description-field"
                                label={transFieldDescription}
                                placeholder={transFieldDescriptionPlaceholder}
                                multiline
                                rows={5}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={issue?.description}
                                onChange={handleChange("description")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions className={clsx(classes.actions, classes.actionsList)}>
                <Button variant="outlined" color="default" size="large" onClick={handleBackButton}>
                    {transCancel}
                </Button>

                {issue?.status !== issueEdit?.issueStatus?.issueStatusExternals?.[0]?.externalID &&
                    issue?.status !== "void" && (
                        <Button variant="contained" color="secondary" size="large" onClick={handleEdit}>
                            {transSave}
                        </Button>
                    )}

                {issue?.status === "void" && (
                    <Button variant="contained" color="secondary" size="large" onClick={handleEdit}>
                        {transSetAsVoid}
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default EditIssue;
