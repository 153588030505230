import { AppModule } from "@iolabs/app";
import { NotifierModule } from "@iolabs/notifier";
import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import { combineReducers, compose } from "redux";
import { createStore as createDynamicStore } from "redux-dynamic-modules";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import { PersistGate } from "redux-persist/integration/react";
import config from "../config/config";
import { IssueModule } from "./issue/module";
import { KeycloakModule } from "./keyclock/module";
import { LayoutModule } from "./layout/module";
import { MappingModule } from "./mapping/module";
import { ProjectModule } from "./project/module";
import { SpecificationModule } from "./specification/module";
import { TreeModule } from "./tree/module";
import { ViewerModule } from "./viewer/module";
import { parse, stringify } from "flatted";
import * as localforage from "localforage";

// add Redux debugging tool as Chrome extension when development environment is active
const composeEnhancers =
    config.envType === "development" ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export const transformCircular = createTransform(
    (inboundState, key) => stringify(inboundState),
    (outboundState, key) => parse(outboundState),
)

export const store = createDynamicStore(
    {
        initialState: {},
        advancedComposeEnhancers: composeEnhancers,
        extensions: [getThunkExtension()],
        advancedCombineReducers: (reducers) =>
            persistReducer(
                {
                    key: "persist-reducer",
                    storage: localforage,
                    throttle: 1000,
                    // whitelist: [], // only these redux states will be persisted
                    blacklist: ["keycloakState", "treeState", "viewerState", "issueState"], // these redux states will not be persisted
                    serialize: true,
                    transforms: [
                        createBlacklistFilter("projectState", ["projects"]),
                        transformCircular,
                    ],
                },
                combineReducers(reducers)
            ) as any,
    },
    AppModule,
    NotifierModule,
    KeycloakModule,
    LayoutModule,
    TreeModule,
    ViewerModule,
    IssueModule,
    SpecificationModule,
    ProjectModule,
    MappingModule
);

const persistor = persistStore(store);

type Props = {
    children: ReactNode;
};

function StoreProvider(props: Props): JSX.Element {
    const { children } = props;
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>{children}</PersistGate>
        </Provider>
    );
}


export default StoreProvider;
