import { defineMessages } from "react-intl";

export default defineMessages({
    rename: {
        id: "components.documentSubMenu.rename",
        defaultMessage: "Rename", // Umbenennen
    },
    delete: {
        id: "components.documentSubMenu.delete",
        defaultMessage: "Delete", // Löschen
    },
});
