import { defineMessages } from "react-intl";

const messages = defineMessages({
    settings: {
        id: "components.header.headerSettings.settings",
        defaultMessage: "Settings",
    },
    newProject: {
        id: "components.header.headerSettings.newProject",
        defaultMessage: "New project",
    },
    projectSettings: {
        id: "components.header.headerSettings.projectSettings",
        defaultMessage: "Project settings",
    },
    mappingManager: {
        id: "components.header.headerSettings.mappingManager",
        defaultMessage: "Mapping manager",
    },
});

export default messages;
