import { createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(1),
            color: theme.palette.primary.main,
        },
        skeleton: {
            marginLeft: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
        },
    })
);

export default useStyles;
