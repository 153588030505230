import { Step, StepButton, StepConnector, StepIconProps, StepLabel, Stepper as MuiStepper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import theme from "../../../theme/Theme";
import { MappingStep } from "../MappingDetailView/type";
import useStyles, { useQontoStyles } from "./styles";
import { IElementsPosition, PositionType } from "../../../packages/Api/data/elements/types";
import { useDetailPosition } from "../../../redux/mapping";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: theme.palette.secondary.main,
        },
    },
    completed: {
        "& $line": {
            borderColor: theme.palette.secondary.main,
        },
    },
    line: {
        borderColor: theme.palette.grey[100],
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

function QontoStepIcon(props: StepIconProps) {
    const classes = useQontoStyles();
    const { active, completed } = props;

    return (
        <div className={classes.root}>
            <div
                className={clsx(classes.circle, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            />
        </div>
    );
}

function getSteps() {
    return ["Element Definition", "Attribute", "Material und Arbeit"];
}

interface IMappingDetailStepperProps {
    activeStep: MappingStep;
    handleSetStep: (activeStep: MappingStep) => () => void;
}

const Stepper: React.FC<IMappingDetailStepperProps> = ({ activeStep, handleSetStep }) => {
    const classes = useStyles();
    const steps = getSteps();

    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const isStepDisabled = (step, index) => {
        return (detailPosition?.type == PositionType.MANUAL && index > 0) || (detailPosition?.type == PositionType.DERIVED && index == 1/*attribute*/);
    }

    return (
        <MuiStepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            classes={{ root: classes.muiStepperRoot }}
        >
            {steps.map((label, index) => (
                <Step key={label} className={clsx({[classes.disabled]: isStepDisabled(label, index)})}>
                    <StepButton onClick={handleSetStep(index)} disabled={isStepDisabled(label, index)} disableRipple>
                        <StepLabel
                            StepIconComponent={QontoStepIcon}
                            classes={{ label: classes.stepLabel, active: classes.stepLabelActive }}
                        >
                            {label}
                        </StepLabel>
                    </StepButton>
                </Step>
            ))}
        </MuiStepper>
    );
};

export default Stepper;
