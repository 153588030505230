import { generatePath } from "react-router";
import { ITree } from "../packages/Api/data/tree/types";
import { Path } from "../pages/PageRouter";

/**
 * Escape special character in URN
 * @param urn
 */
export const escapeUrn = (urn: string): string => {
    return escape(urn);
};

export const path = (parts: string[], pathSeparator = "/"): string => {
    return parts.filter((p) => p && p.replace(/^[\s\/]+|[\s\/]+$/g, "")).join(pathSeparator);
};

export const getParentPathParts = (
    activeProjectId?: string,
    activeFoldersIds?: string[],
    includeProject = false
): string[] => {
    let ids: string[] = [];
    if (includeProject && activeProjectId) {
        ids.push(activeProjectId);
    }
    if (activeFoldersIds) {
        ids = ids.concat(activeFoldersIds);
    }
    return ids;
};

export const getPath = (
    item: ITree,
    isFile: boolean,
    accountUrn: string,
    projectUrn: string,
    activeProjectId?: string,
    activeFoldersIds?: string[],
    activeFileId?: string
): string => {
    const ids: string[] = getParentPathParts(activeProjectId, activeFoldersIds, true);
    if (isFile) {
        return generatePath(Path.DOCUMENTS_WITH_VIEWER, {
            accountUrn: accountUrn as string,
            projectUrn: projectUrn as string,
            nodeId: ids.join("_"),
            urn: item.data.urn as string,
            fileId: activeFileId as string,
        });
    }
    ids.push(item.id);
    return generatePath(Path.DOCUMENTS, {
        accountUrn,
        projectUrn,
        nodeId: ids.join("_"),
    });
};
