import { defineMessages } from "react-intl";

const messages = defineMessages({
    mappingDetail: {
        id: "components.mapping.stepElementDerivedPositionsPopover.mappingDetail",
        defaultMessage: "Show mapping details",
    },
    ifConditionSet: {
        id: "components.mapping.stepElementDerivedPositionsPopover.ifConditionSet",
        defaultMessage: "Set IF conditional",
    },
    ifConditionEdit: {
        id: "components.mapping.stepElementDerivedPositionsPopover.ifConditionEdit",
        defaultMessage: "Adjust IF conditional",
    },
    ifConditionDelete: {
        id: "components.mapping.stepElementDerivedPositionsPopover.ifConditionDelete",
        defaultMessage: "Delete IF conditional",
    },
    confirmDeleteDialogTitle: {
        id: "components.mapping.stepElementDerivedPositionsPopover.transConfirmDeleteDialogTitle",
        defaultMessage: "Confirm deletion",
    },
    confirmDeleteDialogMessage: {
        id: "components.mapping.stepElementDerivedPositionsPopover.transConfirmDeleteDialogMessage",
        defaultMessage: "Are you sure to delete the IF condition for position",
    },
    confirmDeleteDialogDelete: {
        id: "components.mapping.stepElementDerivedPositionsPopover.transConfirmDeleteDialogDelete",
        defaultMessage: "Delete",
    },
    confirmDeleteDialogClose: {
        id: "components.mapping.stepElementDerivedPositionsPopover.transConfirmDeleteDialogClose",
        defaultMessage: "Close",
    }
});

export default messages;
