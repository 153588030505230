import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { KeycloakEvent } from "@react-keycloak/web";

export enum ActionTypes {
    OnEvent = "keycloak/ON_EVENT",
    OnTokens = "keycloak/ON_TOKENS",
}

export type OnEventOptions = {
    event: KeycloakEvent;
};

export type OnTokensOptions = {
    idToken: string;
    refreshToken: string;
    token: string;
};

export const Actions = {
    onEvent: (options: OnEventOptions): void => createAction(ActionTypes.OnEvent, options),
    onTokens: (options: OnTokensOptions): void => createAction(ActionTypes.OnTokens, options),
};

export function onEvent(options: OnEventOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.onEvent(options));
    };
}

export function onTokens(options: OnTokensOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.onTokens(options));
    };
}
