import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const mvtTableHeaderHeight = 80;
const mvtTableHeaderFilterWidth = 130;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogBox: {
            position: "relative",
            height: "95%",
            width: "100%",
        },
        mvtTable: {
            "& .topRightGrid": {
                color: "inherit",
                background: "inherit",
                borderBottom: `1px solid ${theme.palette.primary["500"]}`,
            },
            "& .topLeftGrid": {
                color: "inherit",
                background: "inherit",
                borderBottom: `1px solid ${theme.palette.primary["500"]}`,
                borderRight: `1px solid ${theme.palette.primary["500"]}`,
            },
            "& .bottomLeftGrid": {
                color: "inherit",
                background: "inherit",
                borderRight: `1px solid ${theme.palette.primary["500"]}`,
            },
            // mvt table header row
            "& > div:first-child": {
                "& > div:first-child": {
                    height: `${mvtTableHeaderHeight}px !important`,
                    "& > div": {
                        height: `${mvtTableHeaderHeight}00px !important`,
                        "& > div": {
                            height: `${mvtTableHeaderHeight}px !important`,
                            maxHeight: `${mvtTableHeaderHeight}00px !important`,
                            "& > div": {
                                height: `${mvtTableHeaderHeight}px !important`,
                                maxHeight: `${mvtTableHeaderHeight}00px !important`,
                            },
                        },
                    },
                },
            },
        },
        mvtCellHeader: {
            color: "inherit",
            overflow: "inherit",
            "& > div": {
                overflow: "visible",
                "& > span": {
                    display: "flex",
                    flexFlow: "column",
                    // mui table sort label
                    "& > span": {
                        display: "flex",
                        flexFlow: "row",
                    },
                },
            },
        },
        mvtCellContents: {
            paddingLeft: "6px",
        },
        mvtCellSelected: {
            backgroundColor: theme.palette.success.light,
        },
        mvtInput: {
            width: "90%",
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(12),
            "& .MuiSelect-root": {
                fontSize: theme.typography.pxToRem(12),
                paddingTop: 0,
                paddingBottom: "3px",
            },
        },
        mvtSelect: {
            width: "90%",
            fontWeight: 400,
            "& .MuiSelect-select": {
                fontSize: "0.8rem",
                paddingTop: "6px",
                paddingBottom: "6px",
            }
        },
        iconButton: {
            "& svg": {
                fontSize: theme.typography.pxToRem(18),
            },
        },
        iconButtonSave: {
            "& svg": {
                fill: theme.palette.success.main,
            },
        },
        iconButtonRevert: {
            marginLeft: theme.spacing(0.5),
            "& svg": {
                fill: theme.palette.primary.main,
            },
        },
        iconButtonClose: {
            marginLeft: theme.spacing(0.5),
            "& svg": {
                fill: theme.palette.secondary.main,
            },
        },
        disabled: {
            "& svg": {
                fill: theme.palette.text.disabled,
            },
        },
        skeletonBox: {
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center",
            margin: "auto",
        },
        circularProgress: {
            color: theme.palette.primary.main,
            alignSelf: "center",
        },
        textField: {
            minWidth: `${mvtTableHeaderFilterWidth}px`,
            maxWidth: `${mvtTableHeaderFilterWidth}px`,
            "& input": {
                padding: "10px 14px",
                fontSize: ".8rem",
            },
            "& textarea": {
                fontSize: ".8rem",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 14px",
            },
        },
    })
);

export default useStyles;
