import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexFlow: "column nowrap",
            flex: 1,
            height: "100%",
            width: "100%",
        },
        fieldset: {
            fontWeight: 400,
        },
        paper: {
            flex: 1,
            flexBasis: "50%",
            margin: theme.spacing(1, 0, 2, 0),
            padding: theme.spacing(1),
            overflow: "auto",
            "& > p, & > div > p": {
                fontWeight: 400,
            },
        },

        switchBox: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            width: "100%",
            padding: theme.spacing(0, 1),
        },

        switchLabel: {
            cursor: "pointer",
            fontWeight: 400,
            color: theme.palette.primary[500],
        },
        switchRoot: {
            margin: theme.spacing(0, 1),
        },
        switchActive: {
            color: theme.palette.primary.main,
        },
        switchBase: {
            color: theme.palette.common.white,
            "&.Mui-checked": {
                color: theme.palette.success.main,
            },
        },
        switchColorPrimary: {},
        switchThumb: {
            color: theme.palette.success.main,
        },
        switchTrack: {
            backgroundColor: theme.palette.primary[500],
        },
        switchChecked: {
            color: theme.palette.success.main,
            // track color if switch is checked
            "& + .MuiSwitch-track": {
                backgroundColor: `${theme.palette.primary[500]} !important`,
            },
        },
        controlGroup: {
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
        },
        textField: {
            flex: 1,
            minWidth: "100px",
            marginRight: theme.spacing(1),
            "& input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& textarea": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset > legend > span": {
                paddingLeft: 0,
                paddingRight: "2px",
            },
        },
    })
);

export default useStyles;
