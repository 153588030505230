import { defineMessages } from "react-intl";

export default defineMessages({
    systemCatalogue: {
        id: "components.mapping.mappingPanel.systemCatalogue",
        defaultMessage: "System catalogue",
    },
    projectCatalogue: {
        id: "components.mapping.mappingPanel.projectCatalogue",
        defaultMessage: "Project catalogue",
    },
    labelMainView: {
        id: "components.mapping.mappingPanel.labelMainView",
        defaultMessage: "Main view",
    },
    labelDetailView: {
        id: "components.mapping.mappingPanel.labelDetailView",
        defaultMessage: "Detail view",
    },
    labelShowDerived: {
        id: "components.mapping.mappingPanel.labelShowDerived",
        defaultMessage: "Show derived",
    },
    labelShowHierarchy: {
        id: "components.mapping.mappingPanel.labelShowHierarchy",
        defaultMessage: "eBKP-H show hierarchy",
    }
});
