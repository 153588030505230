import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            padding: theme.spacing(1),
            flexBasis: "100%",
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(2),
                flexBasis: "50%",
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(4),
                flexBasis: "50%",
            },
        },
    })
);

export default useStyles;
