import React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";


const NullableTooltip: React.FC<TooltipProps> = ({
    title ,
    children,
    ...rest
}) => {
    return (
        <Tooltip title={title == null ? "" : title } {...rest}>
            {children}
        </Tooltip>
    );
};

export default NullableTooltip;
