import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const defaultFooterHeight = "20px";

export type FooterStylesProps = {
    footerHeight?: string;
    footerBarColor?: string;
    footerBarBackground?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: FooterStylesProps | undefined) => ({
            color: props?.footerBarColor ? props?.footerBarColor : theme.palette.grey["500"],
            background: props?.footerBarBackground ? props?.footerBarBackground : theme.palette.primary.dark,
            minHeight: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {},
            [theme.breakpoints.up("md")]: {
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        content: (props: FooterStylesProps | undefined) => ({
            color: props?.footerBarColor ? props?.footerBarColor : theme.palette.grey["500"],
            background: props?.footerBarBackground ? props?.footerBarBackground : theme.palette.primary.dark,
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            padding: "0 .4rem",
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
                flexWrap: "wrap",
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
            [theme.breakpoints.up("lg")]: {
                alignItems: "flex-start",
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        paragraph: (props: FooterStylesProps | undefined) => ({
            fontSize: "0.7rem",
            lineHeight: ".8rem",
            [theme.breakpoints.up("md")]: {
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        logo: {
            display: "inline-block",
            marginLeft: "0.7rem",
            marginRight: "0.7rem",
            "& img": {
                marginTop: ".2rem",
                height: ".8rem",
                [theme.breakpoints.up("md")]: {
                    marginTop: 0,
                    marginBottom: "2px",
                },
            },
        },
        logoClient: {
            transform: "scale(1.5)",
        },
        copyLogo: {
            marginTop: ".2rem",
            [theme.breakpoints.up("md")]: {
                order: 2,
                alignSelf: "center",
                marginTop: 0,
            },
        },
        copyUpdate: {
            textAlign: "center",
            [theme.breakpoints.up("md")]: {
                order: 3,
                alignSelf: "flex-end",
                textAlign: "right",
                width: "43%",
                marginBottom: "2px",
            },
        },
        copy: {
            textAlign: "center",
            paddingTop: "2px",
            paddingBottom: "4px",
            [theme.breakpoints.up("md")]: {
                order: 1,
                alignSelf: "flex-start",
                textAlign: "left",
                width: "43%",
                paddingTop: "5px",
                paddingBottom: 0,
            },
            "& a": {
                marginLeft: "0.3em",
                marginRight: "0.3em",
            }
        },
        lastUpdate: {
            cursor: "help",
        },
    })
);

export default useStyles;
