import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        circularProgress: {
            color: theme.palette.primary.main,
            marginLeft: "2px",
        },
    })
);

export default useStyles;
