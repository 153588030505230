import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexFlow: "column nowrap",
            flex: 1,
            height: "100%",
            width: "100%",
            position: "relative"
        },
        paper: {
            flex: 1,
            margin: theme.spacing(1.5),
            background: theme.palette.primaryVariant[50],
            "&:last-child": {
                marginTop: theme.spacing(0),
            },
        },
        paperViewer: {
            flexBasis: "50%",
            [theme.breakpoints.up("md")]: {
                flexBasis: "60%",
            },
        },
        paperViewerBox: {
            borderRadius: theme.shape.borderRadius,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            position: "relative",
        },
        instancesPaper: {
            position: "absolute",
            zIndex: 10,
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontWeight: 400,
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(1.5),
            },
            [theme.breakpoints.up("xl")]: {
                padding: theme.spacing(2),
                margin: theme.spacing(1.3),
            },
        },
        instancesListRoot: {
            marginTop: theme.spacing(1),
            padding: 0,
        },
        instancesListItemRoot: {
            padding: 0,
        },
        instancesListItemText: {
            margin: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        instancesListItemSelected: {
            color: theme.palette.success.main,
        },
    })
);

export default useStyles;
