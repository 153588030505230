import { defineMessages } from "react-intl";

export default defineMessages({
    heading: {
        id: "components.documentDeleteDialog.heading",
        defaultMessage: "Delete",
    },
    cancel: {
        id: "components.documentDeleteDialog.cancel",
        defaultMessage: "Cancel",
    },
    file: {
        id: "components.documentDeleteDialog.file",
        defaultMessage: `Are you sure you want to delete file {name}?`,
    },
    directory: {
        id: "components.documentDeleteDialog.directory",
        defaultMessage: `Are you sure you want to delete directory {name}?`,
    },
    delete: {
        id: "components.documentDeleteDialog.delete",
        defaultMessage: "Delete",
    },
});
