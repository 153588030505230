import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        positionBox: {
            display: "flex",
            flexFlow: "row nowrap",
        },
        positionIcon: {
            background: theme.palette.grey["100"],
            borderRadius: "100%",
            fontSize: theme.typography.pxToRem(9),
            width: "2.5rem",
            height: "2.5rem",
            textAlign: "center",
            lineHeight: "2.5rem",
            padding: theme.typography.pxToRem(6),
        },
        input: {
            border: 0,
            backgroundColor: "transparent",
            width: `3.6rem`,
            height: `calc(2.5rem - ${theme.typography.pxToRem(2*6)})`,
            fontSize: theme.typography.pxToRem(18),
            "&:focus": {
                outline: "none"
            }
        },
        position: {
            display: "flex",
            flexFlow: "column wrap",
            "& > span": {
                display: "none",
                fontSize: theme.typography.pxToRem(9),
                paddingBottom: "1px",
            },
            "& input": {
                padding: 0,
                fontWeight: 400,
            },
            "& div": {
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
                background: theme.palette.grey["100"],
                borderRadius: theme.shape.borderRadius,
                padding: theme.typography.pxToRem(6),
                height: "2.5rem",
                "& > span": {
                    fontWeight: 400,
                    fontSize: theme.typography.pxToRem(18),
                    lineHeight: theme.typography.pxToRem(24),
                },
                [theme.breakpoints.up("md")]: {
                    justifyContent: "flex-end",
                },
                "&.editable": {
                    background: "none",
                    outline: `1px solid ${theme.palette.grey["100"]}`,
                },
            },
        },
        position1: {
            "& div ": {
                minWidth: "2.1rem",
            }
        },
        position2: {
            "& div": {
                minWidth: "2.7rem",
            }
        },
        position3: {
            "& div": {
                minWidth: "2.7rem",
            }
        },
        position4: {
            "& div": {
                minWidth: "3.6rem",
            }
        },
        position5: {
            "& div": {
                minWidth: "3.6rem",
            }
        },
        divider: {
            color: theme.palette.common.black,
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-end",
            fontSize: "2rem",
            lineHeight: "2rem",
            width: "0.8rem",
            textAlign: "center",
            "& span": {
                height: "42px",
            },
            [theme.breakpoints.up("md")]: {
                lineHeight: "2rem",
            },
        },
    })
);

export default useStyles;
