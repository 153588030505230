import React, { useState } from "react";
import { Box, TextField } from "@material-ui/core";
import clsx from "clsx";
import Icon from "../Icon/Icon";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { PositionLevel } from "../Mapping/Dialogs/PositionDialogForm/type";

interface IPositionCodeProps {
    levels: Array<string|undefined>;
    editLastLevel?: boolean,
    onSuffixChange?: (value: string) => void
}

const PositionCode: React.FC<IPositionCodeProps> = ({
    levels,
    editLastLevel,
    onSuffixChange
}) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transPbOne = intl.formatMessage({ ...messages.pbOne });
    const transPbTwo = intl.formatMessage({ ...messages.pbTwo });
    const transPbThree = intl.formatMessage({ ...messages.pbThree });
    const transPbFour = intl.formatMessage({ ...messages.pbFour });
    const transPbFive = intl.formatMessage({ ...messages.pbFive });

    const [suffix, setSuffix] = useState<string|undefined>(levels[levels.length-1]);

    const getLevelClass = (level) => {
        switch (level) {
            case PositionLevel.ONE:
                return classes.position1;
            case PositionLevel.TWO:
                return classes.position2;
            case PositionLevel.THREE:
                return classes.position3;
            case PositionLevel.FOUR:
                return classes.position4;
            case PositionLevel.FIVE:
                return classes.position5;
        }
    }

    const getLevelLabel = (level) => {
        switch (level) {
            case PositionLevel.ONE:
                return transPbOne;
            case PositionLevel.TWO:
                return transPbTwo;
            case PositionLevel.THREE:
                return transPbThree;
            case PositionLevel.FOUR:
                return transPbFour;
            case PositionLevel.FIVE:
                return transPbFive;
        }
    }

    const isLast = (level) => {
        return levels?.length == level + 1;
    }

    const handleSuffixChange = (event) => {
        const newSuffix = event.target.value?.padStart(3,"0");
        setSuffix(newSuffix);

        if (onSuffixChange) {
            onSuffixChange(newSuffix);
        }
    }

    return (
        <Box className={classes.positionBox}>
            <Box className={clsx(classes.positionIcon)}>
                <Icon name="ebkph-expansion" fill="#000" size={20} />
            </Box>
            <Box className={classes.divider}>
                <span></span>
            </Box>
            {levels?.map((level, index) => {
                const last = isLast(index)
                return <>
                        <Box className={clsx(classes.position, getLevelClass(index+1))}>
                            <span>{getLevelLabel(index+1)}</span>
                            {last && editLastLevel ? (
                                <div className={"editable"}>
                                    <input className={classes.input} type="number" value={suffix} onChange={handleSuffixChange} />
                                </div>
                            ) : (
                                <div>
                                    <span>{level}</span>
                                </div>
                            )}
                        </Box>
                        {!last && (
                            <Box className={classes.divider}>
                                <span>{index >= 1 ? "." : ""}</span>
                            </Box>
                        )}
                    </>
                }
            )}
        </Box>
    )
}

export default PositionCode;