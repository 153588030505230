import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import React, { useCallback, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import Icon from "../Icon/Icon";
import { useDropzone } from "react-dropzone";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";

interface IImportDialog {
    title: string;
    handleClose: () => void;
    handleUpload: (data: FormData) => Promise<void>;
    onUploadDone?: (response: any) => void
}

const ImportDialog: React.FC<IImportDialog> = ({ title, handleClose, handleUpload, onUploadDone }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    const [importing, setImporting] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>();

    const intl = useIntl();
    const transChooseFile = intl.formatMessage({ ...messages.chooseFile });
    const transDropFile = intl.formatMessage({ ...messages.dropFile });
    const transUploadStarted = intl.formatMessage({ ...messages.uploadStarted });
    const transUploadFinished = intl.formatMessage({ ...messages.uploadFinished});
    const transSingleFileOnly = intl.formatMessage({ ...messages.singleFileOnly });

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const formData = new FormData();

            if (acceptedFiles.length > 1) {
                dispatch(addNotification({
                    notification: {
                        variant: "error",
                        message: transSingleFileOnly,
                }}));
            }

            setImporting(true);

            const file = acceptedFiles[0];

            formData.append("file", file, file.name);

            dispatch(addNotification({
                notification: {
                    variant: "success",
                    message: transUploadStarted,
                }}));

            handleUpload(formData)
                .then((response: any) => {
                    dispatch(addNotification({
                        notification: {
                            variant: "success",
                            message: transUploadFinished,
                        }}));
                    setImporting(false);

                    if (onUploadDone) {
                        onUploadDone(response);
                    }

                    if (response?.status != "ok") {
                        setErrors(response)
                    }
                    else {
                        handleClose();
                    }

                })
                .catch((error) => {
                    console.error(error);
                    setErrors(error);
                    setImporting(false);
                })
        },
        [handleClose]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const renderBoxContent = () => {
        if (importing) {
            return <Box className={classes.progressHolder}>
                <CircularProgress size={200} className={classes.progress} />
            </Box>
        } else if (errors && errors.status != "ok") {
            return <Box className={classes.errorRoot}>
                <Alert severity={errors.status ==  "partial" ? "warning" : "error"}>{errors.message}</Alert>
                {errors.errors?.map(e => {
                    return <Alert severity="error">{e.message}</Alert>
                })}
            </Box>;
        }
        else {
            return <>
                <input {...getInputProps()} />

                <Box className={classes.dropzone}>
                    <Box
                        className={clsx(classes.dropzoneIconBox, {
                            [classes.dropzoneDragActive]: isDragActive,
                        })}
                    >
                        <Icon name="cloud-upload-alt" size={100} fill={theme.palette.grey[500]} />
                    </Box>

                    <Typography variant="h3" component="h2" className={classes.dropzoneMessage}>
                        {transDropFile}
                    </Typography>
                    <Button variant="contained" className={classes.dropzoneButton}>
                        {transChooseFile}
                    </Button>
                </Box>
            </>
        }
    }

    return (
    <>
            <DialogTitle id="upload-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Box {...getRootProps()} className={classes.dropzoneRoot}>
                    {renderBoxContent()}
                </Box>
            </DialogContent>
    </>
    );
}

export default ImportDialog;
