import { DispatchAction } from "@iolabs/redux-utils";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, matchPath } from "react-router";
import { useHistory } from "react-router-dom";
import { getProjectList } from "../../packages/Api/data/projects/client";
import { IProjectListResponseData } from "../../packages/Api/data/projects/types";
import { Path } from "../../pages/PageRouter";
import { IPageParams } from "../../pages/type";
import { setActiveProject, setProjects, useActiveProject, useProjects } from "../../redux/project";
import ProjectSelector from "../ProjectSelector/ProjectSelector";
import { ProjectData } from "./type";

interface IProjectSelectorWrapper {
    pageParams: IPageParams;
    location: any; // location from react-router-dom
}

const ProjectSelectorWrapper: React.FC<IProjectSelectorWrapper> = ({ pageParams, location }) => {
    const { accountUrn, projectUrn, nodeId } = pageParams;

    const activeProject: ProjectData | undefined = useActiveProject();
    const dispatch = useDispatch<DispatchAction>();
    const history = useHistory();
    const projects: ProjectData[] | undefined = useProjects();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [loading, setLoading] = useState<boolean>(false);

    const handleGetProjectList = async () => {
        if (keycloakInitialized && keycloak?.token && !projects) {
            setLoading(true);
            await getProjectList(keycloak.token).then((projectList: IProjectListResponseData[]) => {
                dispatch(setProjects({ projects: projectList }));
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        if (projects) {
            // todo move to some outer scope
            const project = projectUrn ? projects.find(pr => pr?.externalData?.[0]?.projectUrn == projectUrn) : projects[0];
            dispatch(setActiveProject({ activeProject: project }));
        }
    }, [projectUrn, projects])

    useEffect(() => {
        handleGetProjectList();

        return () => {
            setLoading(false);
        };
    }, [keycloakInitialized, keycloak?.token]);

    useEffect(() => {
        if (activeProject && location && !projectUrn && !accountUrn) {
            history.push(
                    generatePath(Path.DOCUMENTS, {
                        accountUrn: activeProject?.externalData?.[0]?.accountUrn,
                        projectUrn: activeProject?.externalData?.[0]?.projectUrn,
                    })
                );
        }

    }, [activeProject]);


    return <ProjectSelector projects={projects} activeProject={activeProject} loading={loading} />;
};

export default ProjectSelectorWrapper;
