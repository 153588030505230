import deepmerge from "deepmerge";
import { isEqual, intersection } from "lodash";
import './styles.css'
import { StructureBrowserPanel } from "./StructureBrowserPanel";
import { ITreeNode } from "./StructureTreeDelegate";

export const ExtensionID = "Viewing.Extension.StructureBrowser";

let isRegistered = false;

export interface IStructureBrowserExtensionOptions {
    showUi: boolean;
    tree: ITreeNode|null;
}

const DefaultOptions: IStructureBrowserExtensionOptions = {
    showUi: true,
    tree: null
};

export const register = () => {
    if (isRegistered) return;
    class StructureBrowserExtension extends Autodesk.Viewing.Extension {

        options: IStructureBrowserExtensionOptions;
        private panel?: StructureBrowserPanel;
        private isOpened: boolean;

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: IStructureBrowserExtensionOptions) {
            const opts = options ? deepmerge(DefaultOptions, options) : DefaultOptions;
            super(viewer, opts);
            this.options = opts;
            this.viewer = viewer
            this.isOpened = false;
        }

        load() {
            console.log(`${ExtensionID} is now loaded!`);

            return true;
        }

        unload() {
            console.log(`${ExtensionID} is now unloaded!`);

            return true;
        }

        onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar): void {
            if (this.options.showUi) {
                const button1 = new Autodesk.Viewing.UI.Button("show-dialog-button");
                button1.onClick = () => this.toggleDialog();
                button1.setIcon(`material-icons`);
                button1.addClass("structure-browser-button");
                button1.setToolTip("Show structure browser");

                // SubToolbar
                const subToolbar = new Autodesk.Viewing.UI.ControlGroup(`${ExtensionID}Toolbar`);
                subToolbar.addControl(button1);

                toolbar.addControl(subToolbar);
            }
        }

        toggleDialog() {
            this.isOpened ? this.hideDialog() : this.showDialog();
        }

        hideDialog() {
            // @ts-ignore
            this.panel?.setVisible(false);
            this.isOpened = false;
        }

        showDialog() {

            if (!this.panel) {
                this.panel = new StructureBrowserPanel(this.viewer, this.viewer.container, 'structure-browser-panel', 'Model Struktur', {
                    tree: this.options.tree
                });
            }
            // @ts-ignore
            this.panel.setVisible(true);
            this.isOpened = true;
        }


    }
    // register extension - we need to do it here so extension is loaded by Viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, StructureBrowserExtension);

    isRegistered = true;
};
