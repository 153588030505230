import { defineMessages } from "react-intl";

export default defineMessages({
    select: {
        id: "components.versionSelector.select",
        defaultMessage: "Select", // Auswählen
    },
    uploaded: {
        id: "components.versionSelector.uploaded",
        defaultMessage: "Uploaded", // Hochgeladen
    },
    uploadedBy: {
        id: "components.versionSelector.uploadedBy",
        defaultMessage: "by", // von
    },
    uploadedAt: {
        id: "components.versionSelector.uploadedAt",
        defaultMessage: "at", // um
    },
});
