import { defineMessages } from "react-intl";

export default defineMessages({
    pushpin: {
        id: "components.listIssueWrapper.pushpin",
        defaultMessage: "Click a location to place the issue push pin", // Klicken Sie auf eine Stelle, um die Ausgabe-Push-Pin zu platzieren
    },
    box: {
        id: "components.listIssueWrapper.box",
        defaultMessage: "Issues", // Aufgaben
    },
    create: {
        id: "components.listIssueWrapper.create",
        defaultMessage: "Create issue", // Aufgabe erstellen
    },
});
