import { defineMessages } from "react-intl";

export default defineMessages({
    fieldPrice: {
        id: "components.mapping.stepWorkFormSubcontractor.fieldPrice",
        defaultMessage: "Price",
    },
    fieldSurcharge: {
        id: "components.mapping.stepWorkFormSubcontractor.fieldSurcharge",
        defaultMessage: "Surcharge",
    },
    fieldTotal: {
        id: "components.mapping.stepWorkFormSubcontractor.fieldTotal",
        defaultMessage: "Unit price",
    },
});
