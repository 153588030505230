import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { ITreeModuleState, reducer } from "./reducer";

export const TreeModule: IModule<ITreeModuleState> = {
    id: "tree-module",
    reducerMap: {
        treeState: reducer,
    },
    middlewares: middlewares,
};
