import { defineMessages } from "react-intl";

const messages = defineMessages({
    emptyFolder: {
        id: "components.dataTable.emptyFolder",
        defaultMessage: "This folder is empty.",
    },
});

export default messages;
