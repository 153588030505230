import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        previewBox: {
            alignSelf: "flex-start",
        },
        image: {
            borderRadius: theme.shape.borderRadius,
        },
        ionRouterLink: {
            color: theme.palette.common.black,
            cursor: "pointer",
        },
        icon: {
            fontSize: "5rem",
            fill: theme.palette.primary.main,
        },
    })
);

export default useStyles;
