import React from "react";
import Icon from "../../Icon/Icon";
import { ThumbFormat } from "../../Thumbnail/type";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import { IDocumentIconType } from "../type";
import useStyles from "./styles";

interface IDocumentIconProps {
    item: Data;
    type: IDocumentIconType;
}

const DocumentIcon: React.FC<IDocumentIconProps> = ({ item, type }) => {
    const classes = useStyles();

    const renderIcon = (item: Data, type: IDocumentIconType) => {
        const format = (item?.format as unknown) as ThumbFormat;
        const className = type === IDocumentIconType.Table ? classes.iconTable : classes.iconThumbnail;
        let icon: any = <Icon name="file" size={80} className={className} />;

        if ([ThumbFormat.PDF].includes(format)) {
            icon = <Icon name="file-pdf" size={80} className={className} />;
        } else if ([ThumbFormat.DOC, ThumbFormat.DOCX].includes(format)) {
            icon = <Icon name="file-word" size={80} className={className} />;
        } else if ([ThumbFormat.XLS, ThumbFormat.XLSX].includes(format)) {
            icon = <Icon name="file-excel" size={80} className={className} />;
        } else if ([ThumbFormat.PPT, ThumbFormat.PPTX].includes(format)) {
            icon = <Icon name="file-powerpoint" size={80} className={className} />;
        } else if (
            [ThumbFormat.JPG, ThumbFormat.JPEG, ThumbFormat.PNG, ThumbFormat.BMP, ThumbFormat.HEIC].includes(format)
        ) {
            icon = <Icon name="file-image" size={80} className={className} />;
        } else if ([ThumbFormat.MP3, ThumbFormat.M3U, ThumbFormat.WAV].includes(format)) {
            icon = <Icon name="file-music" size={80} className={className} />;
        } else if ([ThumbFormat.MP4, ThumbFormat.MOV].includes(format)) {
            icon = <Icon name="file-video" size={80} className={className} />;
        } else if ([ThumbFormat.ZIP].includes(format)) {
            icon = <Icon name="file-archive" size={80} className={className} />;
        } else if ([ThumbFormat.CSV].includes(format)) {
            icon = <Icon name="file-csv" size={80} className={className} />;
        }

        return icon;
    };

    return renderIcon(item, type);
};

export default DocumentIcon;
