import { Box, Typography } from "@material-ui/core";
import React from "react";
import useIssuesStyles from "../IssuesWrapper/styles";

export default function IssuesTabPanel({ children, value, index, ...other }) {
    const classes = useIssuesStyles();

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`issues-tabpanel-${index}`}
            key={`issues-tabpanel-${index}`}
            aria-labelledby={`issues-tab-${index}`}
            {...other}
            className={classes.issuesTabPanel}
        >
            {value === index && (
                <Box key={index} p={0}>
                    {children}
                </Box>
            )}
        </Typography>
    );
}
