import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { defaultDocumentToolbarHeight } from "../../../pages/DocumentPage/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: `${defaultDocumentToolbarHeight}px`,
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                marginBottom: 0,
            },
        },
        toggleButtonGroup: {
            margin: theme.spacing(0, 1),
        },
        toggleButton: {
            padding: "9px 11px",
            color: theme.palette.grey["500"],
            backgroundColor: theme.palette.grey["100"],
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
            },
            "&.Mui-selected": {
                color: theme.palette.success.dark,
                backgroundColor: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
            },
            "&:first-child": {
                borderRight: 0,
                "&:hover": {
                    borderRight: 0,
                },
            },
            "&:last-child": {
                borderLeft: 0,
                "&:hover": {
                    borderLeft: 0,
                },
            },
        },
        searchBox: {
            display: "flex",
            alignItems: "center",
            flexBasis: "100%",
            [theme.breakpoints.up("lg")]: {
                flexBasis: "60%",
            },
        },
        iconBox: {
            flexBasis: "100%",
            margin: theme.spacing(0, 1),
            [theme.breakpoints.up("lg")]: {
                flexBasis: "40%",
                textAlign: "end",
                margin: 0,
            },
        },
        search: {
            height: "40px",
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey["500"],
            backgroundColor: theme.palette.grey["100"],
            width: "100%",
        },
        searchIcon: {
            padding: theme.spacing(0, 0, 0, 1),
            fill: theme.palette.grey["500"],
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            ...theme.typography.body2,
            color: "inherit",
            fontWeight: 400,
            height: "100%",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(2.5)})`,
        },
        iconButton: {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.grey["100"],
            padding: "10px",
            margin: theme.spacing(0.5, 0.5, 0.5, 0),
            "&:hover": {
                backgroundColor: darken(theme.palette.grey["100"], 0.1),
            },
            [theme.breakpoints.up("md")]: {
                margin: theme.spacing(0, 1, 1, 0),
            },
            [theme.breakpoints.up("lg")]: {
                margin: theme.spacing(0, 1, 0, 0),
            },
        },
    })
);

export default useStyles;
