import { DispatchAction } from "@iolabs/redux-utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Box, Grid, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Icon from "../../components/Icon/Icon";
import IssuePanel from "../../components/IssuePanel/IssuePanel";
import IssuesWrapper from "../../components/Issues/IssuesWrapper/IssuesWrapper";
import ViewerManager from "../../components/Viewer/ViewerManager/ViewerManager";
import ViewerPanel from "../../components/ViewerPanel/ViewerPanel";
import { onIssueViewerChangeSplitView } from "../../redux/issue";
import Page from "../Page/Page";
import { IPageParams, IPageTool } from "../type";
import useStyles from "./styles";

export enum SplitViewType {
    "3D" = 1,
    "BOTH" = 2,
    "2D" = 3,
    "UNIVERSAL" = 4,
}

const ViewerPage: React.FC = () => {
    const { accountUrn, projectUrn, nodeId, urn: paramUrn, fileId, tool } = useParams<IPageParams>();

    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const dispatch = useDispatch<DispatchAction>();

    const [splitView, setSplitView] = useState<SplitViewType>(SplitViewType["3D"]);
    const [urn, setUrn] = useState<string>(paramUrn as string);
    const [versionId, setVersionId] = useState<string | null>(null);

    const handleChangeUrn = (urn: string) => {
        setUrn(urn);
    };

    const handleChangeVersionId = (versionId: string) => {
        setVersionId(versionId);
    };

    const handleChangeSplitView = (splitViewType: SplitViewType) => {
        setSplitView(splitViewType);
        dispatch(onIssueViewerChangeSplitView({ splitView: splitViewType }));
    };
    const setIssueSplitView = (splitView: SplitViewType) => {
        if (isIssuePage() && (splitView === SplitViewType.BOTH || splitView === SplitViewType.UNIVERSAL)) {
            return SplitViewType["3D"];
        }
        return splitView;
    };

    const isIssuePage = (): boolean => {
        return tool === IPageTool.ISSUES;
    };

    return (
        <Page noPadding>
            <Box className={classes.columnBox}>
                {isIssuePage() ? (
                    <IssuePanel
                        accountUrn={accountUrn}
                        projectUrn={projectUrn}
                        nodeId={nodeId as string}
                        fileId={fileId as string}
                        fileUrn={paramUrn as string}
                        handleChangeUrn={handleChangeUrn}
                    />
                ) : (
                    <ViewerPanel
                        accountUrn={accountUrn}
                        projectUrn={projectUrn}
                        nodeId={nodeId as string}
                        fileId={fileId as string}
                        fileUrn={paramUrn as string}
                        splitView={splitView}
                        handleChangeSplitView={handleChangeSplitView}
                        handleChangeUrn={handleChangeUrn}
                        handleChangeVersionId={handleChangeVersionId}
                    />
                )}
                <Box className={classes.rowBox}>
                    {!isIssuePage() && (
                        <Box className={classes.verticalBox}>
                            <IconButton
                                aria-label=""
                                className={classes.verticalButton}
                                onClick={() => setGlobalDialogOpen(true)}
                            >
                                <Icon name="comment-edit" size={20} />
                            </IconButton>
                            <IconButton
                                aria-label=""
                                className={classes.verticalButton}
                                onClick={() => setGlobalDialogOpen(true)}
                            >
                                <Icon name="exclamation-triangle" size={20} />
                            </IconButton>
                            <IconButton
                                aria-label=""
                                className={classes.verticalButton}
                                onClick={() => setGlobalDialogOpen(true)}
                            >
                                <Icon name="rfi" size={20} />
                            </IconButton>
                            <IconButton
                                aria-label=""
                                className={classes.verticalButton}
                                onClick={() => setGlobalDialogOpen(true)}
                            >
                                <Icon name="clone" size={20} />
                            </IconButton>
                            <IconButton
                                aria-label=""
                                className={classes.verticalButton}
                                onClick={() => setGlobalDialogOpen(true)}
                            >
                                <Icon name="clock" size={20} />
                            </IconButton>
                        </Box>
                    )}

                    <Box className={classes.viewerBox}>
                        <Grid container spacing={0} className={classes.gridContainer}>
                            <Grid item xs={12} sm={12} md={isIssuePage() ? 9 : 12} className={classes.gridItem}>
                                <Box className={classes.viewerBox}>
                                    <ViewerManager
                                        key="file-viewer"
                                        urn={urn}
                                        versionId={versionId}
                                        projectId={projectUrn}
                                        splitView={setIssueSplitView(splitView)}
                                        isViewerPage={!isIssuePage()}
                                        isIssuePage={isIssuePage()}
                                        showPushpins
                                    />
                                </Box>
                            </Grid>
                            {isIssuePage() && (
                                <Grid item xs={12} sm={12} md={3} className={classes.gridItem}>
                                    <IssuesWrapper />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};

export default ViewerPage;
