import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    addDerivedPositions,
    createElementsPosition,
    getUnitPrice,
    updateElementPositionFull,
    updatePositionBasics,
} from "../../../../packages/Api/data/elements/client";
import {
    ICreateElementsPosition,
    IElementsPosition,
    IUpdateElementPositionFull,
    IUpdatePositionBasicsRequestData,
} from "../../../../packages/Api/data/elements/types";
import {
    onBasicPositions,
    onDerivedPositions,
    onMappingPositions,
    useDetailPosition,
    useIndexedPositions,
    useMappingPositions,
    useMappingPositionsLoading,
} from "../../../../redux/mapping";
import { useActiveProject } from "../../../../redux/project";
import { ProjectData } from "../../../ProjectSelectorWrapper/type";
import PositionDialogFormNewPosition from "../PositionDialogFormNewPosition/PositionDialogFormNewPosition";
import messages from "./messages";
import useStyles from "./styles";
import { INewPositionFormValues, IPositionFormErrors, PositionDialogType, PositionLevel } from "./type";
import { FormApi } from "final-form";
import { IIndexedPosition } from "../../../../redux/mapping/type";
import { findIndexedPositions } from "../../../../utils/Positions";
import PositionCode from "../../../PositionCode/PositionCode";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";

const defaultCodeSuffix = 100;

interface IPositionDialogFormProps {
    type: PositionDialogType;
    showNewPositionForm?: boolean;
    handleShowNewPositionForm?: () => void;
    handleHideNewPositionForm?: () => void;
    handleClose: () => void;
    position?: IElementsPosition;
    allowMultiSelect?: PositionLevel[];
}

const PositionDialogForm: React.FC<IPositionDialogFormProps> = ({
    type,
    showNewPositionForm,
    handleShowNewPositionForm,
    handleHideNewPositionForm,
    handleClose,
    position,
    allowMultiSelect
}) => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const mappingPositions: IElementsPosition[] = useMappingPositions();
    const indexedPositions: IIndexedPosition[] = useIndexedPositions();
    const mappingPositionsLoading: boolean = useMappingPositionsLoading();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const [moveSuffix, setMoveSuffix] = useState<string|undefined>(position?.codeSuffix);

    const [pos1, setPos1] = useState<IElementsPosition | null>(null);
    const [pos2, setPos2] = useState<IElementsPosition | null>(null);
    const [pos3, setPos3] = useState<IElementsPosition | null>(null);
    const [pos4, setPos4] = useState<IElementsPosition | null>(null);
    const [pos5, setPos5] = useState<IElementsPosition | null>(null);

    const [searchedPositions, setSearchedPositions] = useState<IIndexedPosition[] | null>(null);

    const [selectedPosition, setSelectedPosition] = useState<IElementsPosition | undefined>(position);

    const [newPos4, setNewPos4] = useState<ICreateElementsPosition | null>(null);
    const [newPos5, setNewPos5] = useState<ICreateElementsPosition | null>(null);

    const [saving, setSaving] = useState<boolean>(false);
    const [initialFormValues, setInitialFormValues] = useState<INewPositionFormValues>({});
    const [minCodeSuffix, setMinCodeSuffix] = useState<number>(defaultCodeSuffix);

    // checked positions for new derived position - // TODO: ready for multiple adding using checkboxes (Jakub Jirous 2021-03-31 18:57:07)
    const [checkedPositionIDs, setCheckedPositionIDs] = useState<number[]>([]);

    // searching
    const [localMappingPositions, setLocalMappingPositions] = useState<IElementsPosition[]>([]);
    const [search, setSearch] = useState<string>("");

    const [unitPrice, setUnitPrice] = useState<number>();


    useEffect(() => {
        if (mappingPositions?.length !== 0) return;
        dispatch(onMappingPositions({ token: keycloak?.token as string }));
    }, [keycloakInitialized]);

    useEffect(() => {
        if (type == PositionDialogType.editPosition && keycloak?.token && activeProject?.projectID && selectedPosition?.positionID && keycloakInitialized) {
            getUnitPrice(keycloak.token, activeProject.projectID, selectedPosition.positionID)
                .then((response) => {
                    setUnitPrice(response);
                })
                .catch((error) => {
                    setUnitPrice(undefined);
                    console.warn("Unable to get the unit price");
                });
        }
    }, [keycloak?.token, activeProject?.projectID, selectedPosition?.positionID, keycloakInitialized]);

    // react final form submitting
    const onSubmit = useCallback(async (values) => {
        const data: ICreateElementsPosition = {
            parentPositionID: values?.parentPositionID,
            codeSuffix: values?.codeSuffix,
            name: values?.name,
            description: values?.description ? values?.description : "",
            shortText: values?.shortText ? values?.shortText : "",
            longText: values?.longText ? values?.longText : "",
            din276Structure: values?.din276Structure ? values?.din276Structure : "",
            omniclass: values?.omniclass ? values?.omniclass : "",
            stlbBauCode: values?.stlbBauCode ? values?.stlbBauCode : "",
            referenceUnitID: values?.referenceUnitID ? values?.referenceUnitID : null,
        };

        setSaving(true);
        let savingPromise;

        if (selectedPosition?.positionID) {
            (data as IUpdateElementPositionFull).positionID = selectedPosition?.positionID
            savingPromise = updateElementPositionFull(keycloak?.token as string, data as IUpdateElementPositionFull);
        }
        else {
            savingPromise = createElementsPosition(keycloak?.token as string, data);
        }

        await savingPromise
            .then(() => {
                setNewPos4(null);
                setNewPos5(null);
                setMinCodeSuffix(defaultCodeSuffix);
                dispatch(onMappingPositions({ token: keycloak?.token as string }));
                setSaving(false);
                handleClose();
            })
            .catch((error) => {


                if (error.response.status === 422) {
                    // validation erros
                    const validationErrros = error.response.data;
                    console.log(validationErrros);
                    const notification: INotification = {
                        variant: "warning",
                        message: `Request is not valid`,
                    };
                    dispatch(addNotification({ notification }));
                    Object.keys(validationErrros).forEach(attribute => {
                        validationErrros[attribute].forEach(error => {
                            const notification: INotification = {
                                variant: "error",
                                message: error,
                            };
                            dispatch(addNotification({ notification }));
                        })
                    });
                }
                else {
                    console.log(error);
                    const notification: INotification = {
                        variant: "error",
                        message: `Unable to submit the form, unknown error: ${error.message}`,
                    };
                    dispatch(addNotification({ notification }));
                }

            })
    }, []);

    const getMoveErrorMessage = (attribute, error) => {
        switch (error) {
            case "NON_UNIQUE_CODE":
                return transNonUniqueCode;
            case "PARENT_SELF":
                return transParentSelf;
            default:
                return `${attribute}: ${error}`;
        }
    }

    const handleMovePosition = async () => {
        setSaving(true);
        const data : IUpdatePositionBasicsRequestData = {
            parentPositionID: selectedPosition?.positionID,
            codeSuffix: moveSuffix,
        };

        await updatePositionBasics(
            keycloak?.token as string,
            activeProject?.projectID as number,
            position?.positionID as number,
            data
        ).then(() => {
            const notification: INotification = {
                variant: "success",
                message: transMovedSuccessfully,
            };
            dispatch(addNotification({ notification }));

            dispatch(
                onMappingPositions({
                    token: keycloak?.token as string,
                })
            );
            handleClose();
        })
        .catch((error) => {
            if (error.response.status === 422) {
                // validation erros
                const validationErrros = error.response.data.ErrorData.ValidationErrors;
                Object.keys(validationErrros).forEach(attribute => {
                    validationErrros[attribute].forEach(error => {
                        const notification: INotification = {
                            variant: "error",
                            message: getMoveErrorMessage(attribute, error),
                        };
                        dispatch(addNotification({ notification }));
                    })
                });
            }
            else {
                const notification: INotification = {
                    variant: "error",
                    message: `Unable to submit the form, unknown error: ${error.message}`,
                };
                dispatch(addNotification({ notification }));
            }
        })
        .finally(() => {
            setSaving(false);
        });
    }
    const handleAddDerivedPosition = async () => {
        if (!activeProject && !detailPosition && !selectedPosition) return;

        let selectedDerivedPositions: number[] = checkedPositionIDs;
        if (allowMultiSelect && allowMultiSelect.length > 0) {
            selectedDerivedPositions = checkedPositionIDs;
        }
        else {
            selectedDerivedPositions = [selectedPosition?.positionID as number];
        }

        setSaving(true);
        await addDerivedPositions(
            keycloak?.token as string,
            activeProject?.projectID as number,
            detailPosition?.positionID as number,
            selectedDerivedPositions,
            type == PositionDialogType.basicPosition,
        )
            .then(() => {
                dispatch(
                    type == PositionDialogType.basicPosition ?
                        onBasicPositions({
                            token: keycloak?.token as string,
                            projectID: activeProject?.projectID as number,
                            positionID: detailPosition?.positionID as number,
                        })
                        :
                        onDerivedPositions({
                            token: keycloak?.token as string,
                            projectID: activeProject?.projectID as number,
                            positionID: detailPosition?.positionID as number,
                        })

                );
                dispatch(
                    onMappingPositions({
                        token: keycloak?.token as string,
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setSaving(false);
                handleClose();
            });
    };

    const handlePosition = (form: FormApi, position: IElementsPosition, level: PositionLevel) => () => {
        setSelectedPosition(position);
        switch (level) {
            case PositionLevel.ONE: {
                setPos1(position);
                setPos2(null);
                setPos3(null);
                setPos4(null);
                if (type === PositionDialogType.editPosition) {
                    form.mutators.setValue("parentPositionID", undefined);
                    // setInitialFormValues({
                    //     ...initialFormValues,
                    //     parentPositionID: position?.positionID as number
                    // });
                }
                else {
                    setPos5(null);
                    setNewPos4(null);
                    setNewPos5(null);
                    setInitialFormValues({});
                }
                setMinCodeSuffix(defaultCodeSuffix);
                handleHideNewPositionForm && handleHideNewPositionForm();
                break;
            }
            case PositionLevel.TWO: {
                setPos2(position);
                setPos3(null);
                setPos4(null);
                if (type === PositionDialogType.editPosition) {
                    form.mutators.setValue("parentPositionID", undefined);
                }
                else {
                    setPos5(null);
                    setNewPos4(null);
                    setNewPos5(null);
                    setInitialFormValues({});
                }
                setMinCodeSuffix(defaultCodeSuffix);
                handleHideNewPositionForm && handleHideNewPositionForm();
                break;
            }
            case PositionLevel.THREE: {
                setPos3(position);
                setPos4(null);
                if (type === PositionDialogType.editPosition) {
                    form.mutators.setValue("parentPositionID", undefined);
                }
                else {
                    setPos5(null);
                    setNewPos4(null);
                    setNewPos5(null);
                    setInitialFormValues({});
                }
                setMinCodeSuffix(defaultCodeSuffix);
                handleHideNewPositionForm && handleHideNewPositionForm();
                break;
            }
            case PositionLevel.FOUR: {
                setPos4(position);
                if (type === PositionDialogType.editPosition) {
                    form.mutators.setValue("parentPositionID", position?.positionID as number);
                }
                else {
                    setPos5(null);
                    setNewPos5(null);
                }
                handleHideNewPositionForm && handleHideNewPositionForm();
                break;
            }
            case PositionLevel.FIVE: {
                setPos5(position);
                break;
            }
        }
    };

    const handleNewPosition4 = () => {
        handleShowNewPositionForm && handleShowNewPositionForm();
        setNewPos5(null);

        const lastPosition = pos3?.childPositions?.[pos3?.childPositions?.length - 1];
        const newCodeSuffix =
            lastPosition && lastPosition?.codeSuffix
                ? parseInt(lastPosition?.codeSuffix) < defaultCodeSuffix
                    ? defaultCodeSuffix
                    : parseInt(lastPosition?.codeSuffix) + 1
                : defaultCodeSuffix;

        setMinCodeSuffix(newCodeSuffix);
        setSelectedPosition(undefined)
        setNewPos4({
            parentPositionID: pos3?.positionID as number,
            codeSuffix: newCodeSuffix.toString(),
            name: "New position",
            description: "",
        });
        setInitialFormValues({
            newPos: 4,
            parentPositionID: pos3?.positionID as number,
            codeSuffix: newCodeSuffix.toString(),
            name: "New position",
        });
    };

    const handleNewPosition5 = () => {
        handleShowNewPositionForm && handleShowNewPositionForm();
        setNewPos4(null);

        const lastPosition = pos4?.childPositions?.[pos4?.childPositions?.length - 1];
        const newCodeSuffix =
            lastPosition && lastPosition?.codeSuffix
                ? parseInt(lastPosition?.codeSuffix) < defaultCodeSuffix
                    ? defaultCodeSuffix
                    : parseInt(lastPosition?.codeSuffix) + 1
                : defaultCodeSuffix;

        setMinCodeSuffix(newCodeSuffix);
        setSelectedPosition(undefined)
        setNewPos5({
            parentPositionID: pos4?.positionID as number,
            codeSuffix: newCodeSuffix.toString(),
            name: "New position",
            description: "",
        });

        setInitialFormValues({
            newPos: 5,
            parentPositionID: pos4?.positionID as number,
            codeSuffix: newCodeSuffix.toString(),
            name: "New position",
        });
    };

    const handleSetNewPosCodeSuffix = (codeSuffix: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                codeSuffix,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                codeSuffix,
            }));
        }
    };

    const handleSetNewPosName = (name: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                name,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                name,
            }));
        }
    };

    const handleSetNewPosDescription = (description: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                description,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                description,
            }));
        }
    };

    const handleSetNewPosShortText = (shortText: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                shortText,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                shortText,
            }));
        }
    };

    const handleSetNewPosLongText = (longText: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                longText,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                longText,
            }));
        }
    };


    const handleSetReferenceUnit = (referenceUnitID: number, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                referenceUnitID,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                referenceUnitID,
            }));
        }
    };

    const handleSetStlbBauCode = (stlbBauCode: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                stlbBauCode,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                stlbBauCode,
            }));
        }
    };

    const handleSetDin276Structure = (din276Structure: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                din276Structure,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                din276Structure,
            }));
        }
    };

    const handleSetOmniclass = (omniclass: string, newPos: number) => {
        if (type == PositionDialogType.editPosition) return;
        if (newPos === 4) {
            setNewPos4((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                omniclass: omniclass,
            }));
        } else {
            setNewPos5((prevState) => ({
                ...(prevState as ICreateElementsPosition),
                omniclass: omniclass,
            }));
        }
    };

    const isItemSelected = (position: IElementsPosition): boolean => {
        return (
            position.positionID === pos1?.positionID ||
            position.positionID === pos2?.positionID ||
            position.positionID === pos3?.positionID ||
            position.positionID === pos4?.positionID ||
            position.positionID === pos5?.positionID
        );
    };

    const handleTogglePosition = (positionID: number) => () => {
        const currentIndex = checkedPositionIDs?.indexOf(positionID);
        const newChecked = [...checkedPositionIDs];

        if (currentIndex === -1) {
            newChecked.push(positionID);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedPositionIDs(newChecked);
    };

    const getParentPosition = (position: IElementsPosition, level: number) : IElementsPosition | undefined => {
        let pos : IElementsPosition | undefined = position;
        while(pos) {
            if (pos.level === level) {
                return pos;
            }
            pos = pos.parent;
        }
        return undefined;
    }

    useEffect(() => {
        if (position) {
            let level = position.level as number;
            setInitialFormValues({
                newPos: level+1 as 4 | 5,
                parentPositionID: position.parentPositionID,
                codeSuffix: position.codeSuffix as string,
                name: position.name as string,
                description: position.description as string,
                shortText: position.shortText as string,
                longText: position.longText as string,
                din276Structure: position.din276Structure as string,
                stlbBauCode:  position.stlbBauCode as string,
                omniclass:  position.omniclass as string,
                unitPrice:  unitPrice,
                referenceUnitID:  position.referenceUnitID as number,
            });

            const pos1 = getParentPosition(position, 0) as IElementsPosition;
            const pos2 = getParentPosition(position, 1) as IElementsPosition;
            const pos3 = getParentPosition(position, 2) as IElementsPosition;
            const pos4 = getParentPosition(position, 3) as IElementsPosition;
            const pos5 = getParentPosition(position, 4) as IElementsPosition;

            setPos1(pos1);
            setPos2(pos2);
            setPos3(pos3);

            setPos4(level >= 3 ? pos4 : null);
            setPos5(level >= 4 ? pos5 : null);
        }
        // else {
        //     setInitialFormValues({});
        //     setPos1(null);
        //     setPos2(null);
        //     setPos3(null);
        //     setPos4(null);
        //     setPos5(null);
        //
        // }
    }, [position, mappingPositions, unitPrice])

    useEffect(() => {
        return () => {
            setNewPos4(null);
            setNewPos5(null);
        };
    }, []);

    const handleSearch = (event) => {
        setSearch(event?.target?.value);
    };

    const handleClearSearch = () => {
        setSearch("");
    };

    useEffect(() => {
        if (search === "") {
            setSearchedPositions([]);
            // setLocalMappingPositions(mappingPositions);
        } else {
            const foundPositions = findIndexedPositions(indexedPositions, search);
            setSearchedPositions(foundPositions);
            // setLocalMappingPositions(
            //     mappingPositions?.filter(
            //         (mp) =>
            //             mp?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
            //             mp?.codeSuffix?.toLowerCase()?.includes(search?.toLowerCase())
            //     )
            // );
        }
    }, [search]);

    useEffect(() => {
        setLocalMappingPositions(mappingPositions);
    }, [mappingPositions]);

    // translations
    const intl = useIntl();
    const transDialogTitle = intl.formatMessage({ ...messages.dialogTitle });
    const transDialogEditTitle = intl.formatMessage({ ...messages.dialogEditTitle });
    const transDialogEditCustomTitle = intl.formatMessage({ ...messages.dialogEditCustomTitle });
    const transDialogTitleDerived = intl.formatMessage({ ...messages.dialogTitleDerived });
    const transDialogTitleBasic = intl.formatMessage({ ...messages.dialogTitleBasic });
    const transMovedPositionCode = intl.formatMessage({ ...messages.movedPositionCode });
    const transSelectNewParent = intl.formatMessage({ ...messages.selectNewParent });
    const transDialogTitleMove = intl.formatMessage({ ...messages.dialogTitleMove });
    const transNonUniqueCode = intl.formatMessage({ ...messages.nonUniqueCode });
    const transParentSelf = intl.formatMessage({ ...messages.parentSelf });
    const transMovedSuccessfully = intl.formatMessage({ ...messages.movedSuccessfully });
    const transButtonAddPosition = intl.formatMessage({ ...messages.buttonAddPosition });
    const transFieldSearch = intl.formatMessage({ ...messages.fieldSearch });
    const transPbOne = intl.formatMessage({ ...messages.pbOne });
    const transPbTwo = intl.formatMessage({ ...messages.pbTwo });
    const transPbThree = intl.formatMessage({ ...messages.pbThree });
    const transPbFour = intl.formatMessage({ ...messages.pbFour });
    const transPbFive = intl.formatMessage({ ...messages.pbFive });
    const transButtonBack = intl.formatMessage({ ...messages.buttonBack });
    const transButtonAdd = intl.formatMessage({ ...messages.buttonAdd });
    const transButtonMove = intl.formatMessage({ ...messages.buttonMove });
    const transButtonSaving = intl.formatMessage({ ...messages.buttonSaving });
    const transErrorCode = intl.formatMessage({ ...messages.errorCode });
    const transErrorName = intl.formatMessage({ ...messages.errorName });
    const transErrorParentPositionID = intl.formatMessage({ ...messages.errorParentPositionID });

    const validate = (values: INewPositionFormValues): IPositionFormErrors | {} => {
        const errors: IPositionFormErrors | {} = {};
        if (!values.codeSuffix) {
            (errors as IPositionFormErrors).codeSuffix = transErrorCode;
        }
        if (!values.name) {
            (errors as IPositionFormErrors).name = transErrorName;
        }
        if (!values.parentPositionID) {
            (errors as IPositionFormErrors).parentPositionID = transErrorParentPositionID;
        }
        console.log(errors)
        return errors;
    };

    const renderNewPosition = (newPosition: ICreateElementsPosition) => {
        return (
            <ListItem
                key={newPosition.parentPositionID}
                dense
                button
                className={classes.listItem}
                disabled={type == PositionDialogType.editPosition && !position?.isCustom}
            >
                <ListItemText
                    primary={<span className={classes.newPosition}>{newPosition.codeSuffix}</span>}
                    className={clsx(classes.listItemText, classes.listItemTextPos)}
                />
                <ListItemText
                    primary={
                        <NullableTooltip
                            title={(newPosition?.description ? newPosition?.description : newPosition?.name) as string}
                            placement="bottom-start"
                        >
                            <span className={classes.newPosition}>{newPosition.name}</span>
                        </NullableTooltip>
                    }
                    className={clsx(classes.listItemText, classes.listItemTextValue)}
                />
            </ListItem>
        );
    };

    const isPositionSearched = (position: IElementsPosition, level: PositionLevel): boolean => {
        return !!searchedPositions?.some((sp: IIndexedPosition) => level <= sp.level  && sp.positionPath[level-1] == position.positionID);
    }

    const renderPosition = (form: FormApi, position: IElementsPosition, level: PositionLevel, disableSelection: boolean = false) => {
        return (
            position?.isFromEbkpCatalogue && (
                <ListItem
                    key={position.positionID}
                    dense
                    button
                    className={clsx(classes.listItem, {[classes.selectedPosition]: selectedPosition?.positionID == position.positionID, [classes.searched]: isPositionSearched(position, level)})}
                    onClick={handlePosition(form, position, level)}
                    disabled={disableSelection}
                >
                    {allowMultiSelect?.includes(level) && (
                        <ListItemIcon className={classes.listItemIcon} onClick={handleTogglePosition(position?.positionID)}>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": position?.name }}
                                size="small"
                                className={classes.checkbox}
                                checked={checkedPositionIDs?.indexOf(position?.positionID) !== -1}
                            />
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={
                            <span className={isItemSelected(position) && !disableSelection ? classes.selected : ""}>
                                {position.codeSuffix}
                            </span>
                        }
                        className={clsx(classes.listItemText, classes.listItemTextPos)}
                    />
                    <ListItemText
                        primary={
                            <NullableTooltip
                                title={position?.description ? position?.description : (position?.name as string)}
                                placement="bottom-start"
                            >
                                <span className={isItemSelected(position) && !disableSelection ? classes.selected : ""}>
                                    {position.name}
                                </span>
                            </NullableTooltip>
                        }
                        className={clsx(classes.listItemText, classes.listItemTextValue)}
                    />
                </ListItem>
            )
        );
    };

    const renderPositionWithCheckboxes = (
        form: FormApi,
        position: IElementsPosition,
        level: PositionLevel,
        disableSelection: boolean = false
    ) => {
        return (
            <ListItem key={position.positionID} dense button className={classes.listItem} disabled={disableSelection}>
                <ListItemIcon className={classes.listItemIcon} onClick={handleTogglePosition(position?.positionID)}>
                    <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": position?.name }}
                        size="small"
                        className={classes.checkbox}
                        checked={checkedPositionIDs?.indexOf(position?.positionID) !== -1}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <span className={isItemSelected(position) && !disableSelection ? classes.selected : ""}>
                            {position.codeSuffix}
                        </span>
                    }
                    className={clsx(classes.listItemText, classes.listItemTextPos)}
                    onClick={handlePosition(form, position, level)}
                />
                <ListItemText
                    primary={
                        <NullableTooltip
                            title={position?.description ? position?.description : (position?.name as string)}
                            placement="bottom-start"
                        >
                            <span className={isItemSelected(position) && !disableSelection ? classes.selected : ""}>
                                {position.name}
                            </span>
                        </NullableTooltip>
                    }
                    className={clsx(classes.listItemText, classes.listItemTextValue)}
                    onClick={handlePosition(form, position, level)}
                />
            </ListItem>
        );
    };

    const renderDialogTitle = (type: PositionDialogType) => {
        switch (type) {
            case PositionDialogType.newPosition:
                return transDialogTitle;

            case PositionDialogType.editPosition:
                return position?.isCustom ? transDialogEditCustomTitle : transDialogEditTitle;

            case PositionDialogType.derivedPosition:
                return transDialogTitleDerived;

            case PositionDialogType.basicPosition:
                return transDialogTitleBasic;

            case PositionDialogType.movePosition:
                return `${transDialogTitleMove} ${position?.code}`;

            default:
                return transDialogTitle;
        }
    };
    const getInitialFormValue = (): INewPositionFormValues => {
        if (selectedPosition && type != PositionDialogType.editPosition ) { // something selected, but not in edit mode
            return  {
                codeSuffix: selectedPosition.codeSuffix,
                name: selectedPosition.name,
                description: selectedPosition.description,
                shortText: selectedPosition.shortText,
                longText: selectedPosition.longText,
                stlbBauCode: selectedPosition.stlbBauCode,
                din276Structure: selectedPosition.din276Structure,
                omniclass: selectedPosition.omniclass,
                unitPrice: selectedPosition.unitPrice,
                referenceUnitID: selectedPosition.referenceUnitID,
            }
        }
        else {
            return initialFormValues;
        }
    }

    const getLevelsForMoveSuffix = () => {
        const levels : Array<string|undefined> = [];
        if (pos1?.codeSuffix) {
            levels.push(pos1.codeSuffix)
        }
        if (pos2?.codeSuffix) {
            levels.push(pos2.codeSuffix)
        }
        if (pos3?.codeSuffix) {
            levels.push(pos3.codeSuffix)
        }
        if (pos4?.codeSuffix) {
            levels.push(pos4.codeSuffix)
        }
        levels.push(moveSuffix);
        return levels;
    }

    return (
        <Form
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                }
            }}
            onSubmit={onSubmit} initialValues={getInitialFormValue()} validate={validate}
        >
            {({ form, handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="position-dialog-title" className={classes.title}>
                        {renderDialogTitle(type)}
                    </DialogTitle>
                    <DialogContent>
                        {(mappingPositions.length == 0 || mappingPositionsLoading) ? <Box className={classes.loadingOuter}>
                                <CircularProgress />
                        </Box> :
                            <>
                                {type == PositionDialogType.movePosition &&  (
                                    <Typography variant={"h4"} className={classes.groupLabel}>
                                        {transSelectNewParent}
                                    </Typography>
                                )}
                                <Grid container spacing={2} className={classes.headerRow}>
                                    <Grid item xs={12} sm={5} md={6} lg={5}>
                                        <Box className={classes.searchBox}>
                                            <TextField
                                                id="search"
                                                name="search"
                                                placeholder={transFieldSearch}
                                                value={search}
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                className={clsx(classes.textField, classes.search)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: search !== "" && (
                                                        <InputAdornment
                                                            position="end"
                                                            onClick={handleClearSearch}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <CloseIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={handleSearch}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={6} lg={7}>
                                        <Box className={classes.searchCodeBox}>
                                            <PositionCode
                                                levels={[
                                                    pos1?.codeSuffix,
                                                    pos2?.codeSuffix,
                                                    pos3?.codeSuffix,
                                                    newPos4 ? newPos4?.codeSuffix : pos4?.codeSuffix,
                                                    newPos5 ? newPos5?.codeSuffix : pos5?.codeSuffix
                                                ]}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box className={classes.listsBox}>
                                    <Box className={classes.paperBox}>
                                        <Paper variant="outlined" elevation={0} className={classes.paper}>
                                            <List
                                                dense
                                                subheader={
                                                    <ListSubheader
                                                        id="subheader-position-1"
                                                        component="div"
                                                        className={classes.listSubHeader}
                                                    >
                                                        {transPbOne}
                                                    </ListSubheader>
                                                }
                                                className={classes.list}
                                            >
                                                {mappingPositions?.map((pos) =>
                                                    renderPosition(form, pos, PositionLevel.ONE, type == PositionDialogType.editPosition && !position?.isCustom)
                                                )}
                                            </List>
                                        </Paper>
                                    </Box>
                                    <Box className={classes.paperBox}>
                                        <Paper variant="outlined" elevation={0} className={classes.paper}>
                                            <List
                                                dense
                                                subheader={
                                                    <ListSubheader
                                                        id="subheader-position-2"
                                                        component="div"
                                                        className={classes.listSubHeader}
                                                    >
                                                        {transPbTwo}
                                                    </ListSubheader>
                                                }
                                                className={classes.list}
                                            >
                                                {pos1 &&
                                                pos1?.childPositions &&
                                                pos1?.childPositions?.length > 0 &&
                                                pos1?.childPositions?.map((pos) =>
                                                    renderPosition(form, pos, PositionLevel.TWO, type == PositionDialogType.editPosition && !position?.isCustom)
                                                )}
                                            </List>
                                        </Paper>
                                    </Box>
                                    <Box className={classes.paperBox}>
                                        <Paper variant="outlined" elevation={0} className={classes.paper}>
                                            <List
                                                dense
                                                subheader={
                                                    <ListSubheader
                                                        id="subheader-position-3"
                                                        component="div"
                                                        className={classes.listSubHeader}
                                                    >
                                                        {transPbThree}
                                                    </ListSubheader>
                                                }
                                                className={classes.list}
                                            >
                                                {pos2 &&
                                                pos2?.childPositions &&
                                                pos2?.childPositions?.length > 0 &&
                                                pos2?.childPositions?.map((pos) =>
                                                    renderPosition(form, pos, PositionLevel.THREE, type == PositionDialogType.editPosition && !position?.isCustom)
                                                )}
                                            </List>
                                        </Paper>
                                    </Box>
                                    <Box className={classes.paperBox}>
                                        <Paper variant="outlined" elevation={0} className={classes.paper}>
                                            <List
                                                dense
                                                subheader={
                                                    <ListSubheader
                                                        id="subheader-position-4"
                                                        component="div"
                                                        className={classes.listSubHeader}
                                                    >
                                                        {transPbFour}
                                                    </ListSubheader>
                                                }
                                                className={classes.list}
                                            >
                                                {pos3 &&
                                                pos3?.childPositions &&
                                                pos3?.childPositions?.length > 0 &&
                                                pos3?.childPositions?.map((pos) =>
                                                    renderPosition(form, pos, PositionLevel.FOUR, (type == PositionDialogType.editPosition && !position?.isCustom) || newPos4 !== null)
                                                )}

                                                {type !== PositionDialogType.editPosition && newPos4 && renderNewPosition(newPos4)}
                                            </List>
                                        </Paper>
                                        {type === PositionDialogType.newPosition && (
                                            <Button
                                                variant="text"
                                                color="inherit"
                                                size="small"
                                                className={classes.addButton}
                                                startIcon={<AddCircleIcon className={classes.addIcon} />}
                                                onClick={handleNewPosition4}
                                                disabled={pos3 === null}
                                            >
                                                {transButtonAddPosition}
                                            </Button>
                                        )}
                                    </Box>
                                    <Box className={classes.paperBox}>
                                        <Paper variant="outlined" elevation={0} className={classes.paper}>
                                            <List
                                                dense
                                                subheader={
                                                    <ListSubheader
                                                        id="subheader-position-5"
                                                        component="div"
                                                        className={classes.listSubHeader}
                                                    >
                                                        {transPbFive}
                                                    </ListSubheader>
                                                }
                                                className={classes.list}
                                            >
                                                {pos4 &&
                                                pos4?.childPositions &&
                                                pos4?.childPositions?.length > 0 &&
                                                pos4?.childPositions?.map(
                                                    (pos) =>
                                                        renderPosition(form, pos, PositionLevel.FIVE, (type == PositionDialogType.movePosition || (type == PositionDialogType.editPosition && !position?.isCustom) || newPos5 !== null))

                                                    // type === PositionDialogType.newPosition
                                                    //       ? renderPosition(position, PositionLevel.FIVE, newPos5 !== null)
                                                    //       : renderPositionWithCheckboxes(position, PositionLevel.FIVE)
                                                )}

                                                {type !== PositionDialogType.editPosition && newPos5 && renderNewPosition(newPos5)}
                                            </List>
                                        </Paper>
                                        {type === PositionDialogType.newPosition && (
                                            <Button
                                                variant="text"
                                                color="inherit"
                                                size="small"
                                                className={classes.addButton}
                                                startIcon={<AddCircleIcon className={classes.addIcon} />}
                                                onClick={handleNewPosition5}
                                                disabled={pos4 === null}
                                            >
                                                {transButtonAddPosition}
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                {showNewPositionForm && (type === PositionDialogType.newPosition || type === PositionDialogType.editPosition) && (
                                    <PositionDialogFormNewPosition
                                        values={values}
                                        basicPropertiesReadonly={selectedPosition && !selectedPosition.isCustom && type == PositionDialogType.editPosition}
                                        minCodeSuffix={minCodeSuffix}
                                        handleSetNewPosCodeSuffix={handleSetNewPosCodeSuffix}
                                        handleSetNewPosName={handleSetNewPosName}
                                        handleSetNewPosDescription={handleSetNewPosDescription}
                                        handleSetNewPosShortText={handleSetNewPosShortText}
                                        handleSetNewPosLongText={handleSetNewPosLongText}
                                        handleSetDin276Structure={handleSetDin276Structure}
                                        handleSetOmniclass={handleSetOmniclass}
                                        handleSetStlbBauCode={handleSetStlbBauCode}
                                        handleSetReferenceUnit={handleSetReferenceUnit}
                                    />
                                )}
                                {type == PositionDialogType.movePosition && (
                                    <Box className={classes.moveDetail}>
                                        <Typography variant={"h4"} className={clsx(classes.groupLabel)}>
                                            {transMovedPositionCode}
                                        </Typography>
                                        <PositionCode
                                            levels={getLevelsForMoveSuffix()}
                                            editLastLevel={true}
                                            onSuffixChange={setMoveSuffix}
                                        />
                                    </Box>

                                )}
                            </>
                        }
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button variant="text" color="secondary" size="medium" onClick={handleClose}>
                            {transButtonBack}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disabled={
                                type === PositionDialogType.newPosition ||  type === PositionDialogType.editPosition
                                    ? pristine || submitting
                                    : (type === PositionDialogType.derivedPosition
                                        ? (!pos5?.positionID || (allowMultiSelect && allowMultiSelect.length > 0 && checkedPositionIDs.length == 0 ))
                                        : type === PositionDialogType.movePosition
                                                ? (!selectedPosition || (selectedPosition && ((selectedPosition?.level??0) < 2)) || selectedPosition == position)
                                                : !pos4?.positionID
                                    ) // checkedPositionIDs?.length === 0
                            }
                            onClick={() =>
                                (type === PositionDialogType.derivedPosition || type === PositionDialogType.basicPosition)
                                    ? handleAddDerivedPosition()
                                    : type === PositionDialogType.movePosition
                                        ? handleMovePosition()
                                        : null
                            }
                        >
                            {saving ? transButtonSaving :
                                type == PositionDialogType.movePosition
                                    ? transButtonMove
                                    : transButtonAdd
                            }
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Form>
    );
};

export default PositionDialogForm;
