import { DispatchAction } from "@iolabs/redux-utils";
import { Box, useTheme } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CloudIcon from "@material-ui/icons/Cloud";
import FolderIcon from "@material-ui/icons/Folder";
import HomeIcon from "@material-ui/icons/Home";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Skeleton, TreeView } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ITree } from "../../../packages/Api/data/tree/types";
import {
    onLoadNode,
    onLoadNodeContentDone,
    setActiveNodeId,
    setExpandedNodes,
    useExpandedNodes,
} from "../../../redux/tree";
import Icon from "../../Icon/Icon";
import StyledTreeItem from "../StyledTreeItem/StyledTreeItem";
import useStyles from "./styles";
import { DataType } from "../TreeWrapper/TreeWrapper";
import { useParams } from "react-router";
import { IPageParams } from "../../../pages/type";

interface ITreeProps {
    treeData: any;
    treeLoading: boolean;
    projectIdFromParam?: string;
    activeFoldersIds: string[];
}

const Tree: React.FC<ITreeProps> = ({ treeData, treeLoading, projectIdFromParam, activeFoldersIds }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();
    const expanded = useExpandedNodes();
    const [selected, setSelected] = useState<string[]>([]);
    const [nodeId, setNodeId] = useState<string>("");
    const [anchorMore, setAnchorMore] = useState<null | HTMLElement>(null);
    const openMore = Boolean(anchorMore);

    const { nodeId: routeNodeId } = useParams<IPageParams>();

    // const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    //
    // const handleOpenUploadDialog = () => {
    //     setOpenUploadDialog(true);
    // };
    //
    // const handleCloseUploadDialog = () => {
    //     setOpenUploadDialog(false);
    // };

    useEffect(() => {
        dispatch(setExpandedNodes({ expandedNodes: [...activeFoldersIds] }));
    }, []);


    useEffect(() => {
        if (routeNodeId) {
            // set active node Id of tree item
            dispatch(setActiveNodeId({ activeNodeId: routeNodeId }));
        }
    }, [routeNodeId]);

    useEffect(() => {
        if (activeFoldersIds?.length) {
            setSelected([activeFoldersIds[activeFoldersIds.length - 1]]);
        }
    }, [activeFoldersIds]);

    useEffect(() => {
        checkLoaded(treeData);
    }, [treeData, activeFoldersIds]);

    const checkLoaded = (items: ITree[]) => {
        items.forEach((item) => {
            if (!item.children && activeFoldersIds.includes(item.id)) {
                item.loaded = false;
                setNodeId(item.id);
                dispatch(onLoadNodeContentDone(undefined));
                dispatch(onLoadNode({ nodeId: item.id, depth: 1 }));
            } else if (item.children) {
                checkLoaded(item.children);
            }
        });
    };

    const handleClick = (event: React.ChangeEvent<{}>, item: ITree) => {
        if (!expanded.includes(item.id)) {
            dispatch(setExpandedNodes({ expandedNodes: [item.id, ...expanded] }));
        }
    };

    const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        // load children nodes on click on tree item arrow
        const difference = nodeIds.filter((x) => !expanded.includes(x));
        if (difference && difference[0]) {
            setNodeId(difference[0]);
            dispatch(onLoadNode({ nodeId: difference[0] as string, depth: 1 }));
        }

        dispatch(setExpandedNodes({ expandedNodes: nodeIds }));
    };

    // /**
    //  * Toggle tree node on arrow icon click
    //  * @param itemId
    //  */
    // const handleNodeToggle = (itemId: string): void => {
    //     let newExpanded;
    //     if (expanded.indexOf(itemId) !== -1) {
    //         newExpanded = expanded.filter(id => id !== itemId);
    //     } else {
    //         newExpanded = [itemId, ...expanded];
    //     }
    //     dispatch(setExpandedNodes({ expandedNodes: newExpanded }));
    //     setSelected([itemId, ...selected]);
    // };

    const handleMoreOpen = (event: React.MouseEvent<HTMLElement>, item: ITree) => {
        setNodeId(item.id);
        setAnchorMore(event.currentTarget);
    };

    const handleMoreClose = () => {
        setAnchorMore(null);
    };

    const renderIcon = (item: ITree) => {
        switch (item.data.type) {
            case DataType.Hub:
                return CloudIcon;
            case DataType.Project:
                return HomeIcon;
            case DataType.Folder:
                return FolderIcon;
            case DataType.Item:
                return InsertDriveFileIcon;
            case DataType.Version:
                return AccessTimeIcon;
            default:
                return FolderIcon;
        }
    };

    const loading = (item: ITree, parentIds: string[]) => {
        if (item.id === nodeId && treeLoading) {
            return (
                <Box className={classes.itemSkeleton}>
                    <Skeleton variant="text" width="90%" height={32} />
                    <Skeleton variant="text" width="90%" height={32} />
                </Box>
            );
        }
        return renderItems(item, parentIds);
    };

    const renderItems = (treeItem: ITree | ITree[], parentIds: string[]) => {
        const children: ITree[] = Array.isArray(treeItem) ? treeItem : (treeItem.children as ITree[]);
        const subfolderCount: number = !Array.isArray(treeItem) ? treeItem?.data?.subfoldersCount : 0;

        if (children && children.length) {
            return children.map((item) => {
                return renderItem(item, [...parentIds]);
            });
        }
        if (!children && subfolderCount !== 0) {
            return <></>;
        }
        if (!children) {
            return null;
        }
    };

    const renderItem = (item: ITree, parentIds: string[], followingActiveFolders?: string[]) => {
        if (item.data.type === DataType.Item || item.data.type === DataType.Version) {
            return null;
        }

        const isActive = Array.isArray(followingActiveFolders);

        return (
            <StyledTreeItem
                key={item.id}
                nodeId={item.id}
                labelText={item.text}
                labelIcon={renderIcon(item)}
                isActive={isActive}
                item={item}
                handleClick={handleClick}
                handleMoreOpen={handleMoreOpen}
                handleMoreClose={handleMoreClose}
                anchorMore={anchorMore}
                openMore={openMore}
                parentIds={parentIds}
            >
                {loading(item, [...parentIds, item.id])}
            </StyledTreeItem>
        );
    };

    return (
        <Box p={1}>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<Icon name="chevron-light-down" size={10} fill={theme.palette.primary.main} />}
                defaultExpandIcon={<Icon name="chevron-light-right" size={10} fill={theme.palette.primary.main} />}
                defaultExpanded={activeFoldersIds}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleNodeToggle}
            >
                {renderItems(treeData, projectIdFromParam ? [projectIdFromParam] : [])}
            </TreeView>
        </Box>
    );
};

export default Tree;
