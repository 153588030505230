import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import Icon from "../../components/Icon/Icon";
import Page from "../Page/Page";
import { Path } from "../PageRouter";
import messages from "./messages";
import useStyles from "./styles";
import { Link as RouterLink } from "react-router-dom";

const NotFoundPage: React.FC = () => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transNotFound = intl.formatMessage({ ...messages.notFound });
    const transHomepage = intl.formatMessage({ ...messages.homepage });

    return (
        <Page noProjects>
            <Box className={classes.root}>
                <Icon name="construction" size={60} />
                <Typography variant="body2">{transNotFound}</Typography>

                <Box mt={2}>
                    <Button component={RouterLink} to={Path.ROOT} variant="contained" color="primary">
                        {transHomepage}
                    </Button>
                </Box>
            </Box>
        </Page>
    );
};

export default NotFoundPage;
