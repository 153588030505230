import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { getMarkups } from "../../../packages/Api/data/markups/client";
import { IObjectMarkup } from "../../../packages/Api/data/markups/types";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IMarkupsSampleProps extends React.HTMLAttributes<HTMLElement> {
    objectID: string;
    stateHackValue?: any;
    onMarkupSelected?: (markup: IObjectMarkup) => void;
}

const MarkupsSample: React.FC<IMarkupsSampleProps> = ({ objectID, stateHackValue, onMarkupSelected }) => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [data, setData] = useState<IObjectMarkup[]>([]);

    useEffect(() => {
        if (keycloak?.token) {
            getMarkups(keycloak.token, objectID)
                .then((response: IObjectMarkup[]) => {
                    setData(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [objectID, keycloakInitialized, stateHackValue]);

    // translations
    const intl = useIntl();
    const transMarkups = intl.formatMessage({ ...messages.markups });

    return (
        <div className={classes.root}>
            <List
                component="nav"
                aria-label="main mailbox folders"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      {transMarkups}
                    </ListSubheader>
                }
            >
                {data.map((markup) => {
                    return (
                        <ListItem
                            key={`markup-${markup.markupID}`}
                            button
                            onClick={() => {
                                if (onMarkupSelected) {
                                    onMarkupSelected(markup);
                                }
                            }}
                        >
                            <ListItemText primary={markup.name} />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};

export default MarkupsSample;
