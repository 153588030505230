import { DispatchAction } from "@iolabs/redux-utils";
import React from "react";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box, Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography, useMediaQuery, useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { onDeleteMappingPositionsDone, useMappingPositionsDeleteResult } from "../../../../redux/mapping";
import {
    DeleteResponseStatus,
    IDeletePositionsResponse,
    IEntityToDelete,
} from "../../../../packages/Api/data/elements/types";
import { CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import messages from "./messages";

const MasterDataDeleteResponseDialog: React.FC = ({}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    const mappingPositionsDeleteResult: IDeletePositionsResponse = useMappingPositionsDeleteResult();

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


    // translations
    const intl = useIntl();
    const transEntityDeletedMessage = intl.formatMessage({ ...messages.entityDeletedMessage });
    const transEntityHasDependencies = intl.formatMessage({ ...messages.entityHasDependencies });
    const transEntityNotCustom = intl.formatMessage({ ...messages.entityNotCustom });
    const transEntityNotFound = intl.formatMessage({ ...messages.entityNotFound });
    const transDeletionResult = intl.formatMessage({ ...messages.deletionResult });
    const transCloseDialog = intl.formatMessage({ ...messages.closeDialog });
    const transEntityHasDependenciesInfo = intl.formatMessage({ ...messages.entityHasDependenciesInfo });
    const transRequestIdentifier = intl.formatMessage({ ...messages.requestIdentifier });

    const handleDeleteResultDialogClose = () => {
        dispatch(onDeleteMappingPositionsDone({ deletePositionsResponse: undefined }));
    }
    const getDeleteMessageIcon = (deleteEntityResult: IEntityToDelete) => {
        switch (deleteEntityResult.status) {
            case DeleteResponseStatus.ENTITY_DELETED:
                return <CheckCircleOutline className={classes.deleteStatusIcon} />;
            case DeleteResponseStatus.ENTITY_HAS_DEPENDENCIES:
            case DeleteResponseStatus.ENTITY_NOT_CUSTOM:
            case DeleteResponseStatus.ENTITY_NOT_FOUND:
                return <ErrorOutline className={classes.deleteStatusIcon} />;

        }
    }

    const getDeleteMessage = (deleteEntityResult: IEntityToDelete) => {
        switch (deleteEntityResult.status) {
            case DeleteResponseStatus.ENTITY_DELETED:
                return transEntityDeletedMessage;
            case DeleteResponseStatus.ENTITY_HAS_DEPENDENCIES:
                return transEntityHasDependencies;
            case DeleteResponseStatus.ENTITY_NOT_CUSTOM:
                return transEntityNotCustom;
            case DeleteResponseStatus.ENTITY_NOT_FOUND:
                return transEntityNotFound;
        }
    }

    const hasDeleteResponseDetails = (deleteEntityResult: IEntityToDelete) => {
        return deleteEntityResult.status == DeleteResponseStatus.ENTITY_HAS_DEPENDENCIES;
    }

    return (
        <Dialog
            open={!!mappingPositionsDeleteResult}
            onClose={handleDeleteResultDialogClose}
            aria-labelledby="new-position-dialog-title"
            aria-describedby="new-position-dialog-description"
            maxWidth="lg"
            fullWidth
            fullScreen={fullScreen}
            className={classes.deleteResultDialogRoot}
        >
            {mappingPositionsDeleteResult && <>
                <DialogTitle id="position-dialog-title" className={classes.deleteResultDialogTitle}>
                    {transDeletionResult}
                </DialogTitle>
                <DialogContent>
                    <IconButton aria-label="close" className={classes.deleteResultDialogCloseButton}  onClick={handleDeleteResultDialogClose}>
                        <CloseIcon />
                    </IconButton>
                    <Box>
                        {mappingPositionsDeleteResult?.entitiesToDelete?.map(entity =>
                            <Accordion className={hasDeleteResponseDetails(entity) ? undefined : classes.disabled}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {getDeleteMessageIcon(entity)}
                                    <Typography variant={"body2"} className={classes.accordionHeading}>{entity.entityCode?entity.entityCode:entity.entityID}</Typography>
                                    <Typography variant={"body2"} className={classes.secondaryHeading}>{getDeleteMessage(entity)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    {hasDeleteResponseDetails(entity) && <>
                                        <table className={classes.dependenciesTable}>
                                            <tr>
                                                <th>{transRequestIdentifier}</th>
                                                <td>{mappingPositionsDeleteResult.deleteRequest}</td>
                                            </tr>
                                            {entity?.dependantEntities && Object.keys(entity?.dependantEntities)?.map(entityName => <tr>
                                                <th>{entityName}</th>
                                                <td>{entity?.dependantEntities?.[entityName].join(", ")}</td>
                                            </tr>)}
                                        </table>
                                        <Typography variant={"body2"}>{transEntityHasDependenciesInfo}</Typography>
                                    </>}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="default" size="medium" onClick={handleDeleteResultDialogClose}>
                        {transCloseDialog}
                    </Button>
                </DialogActions>
            </>}
        </Dialog>
    );
 };

export default MasterDataDeleteResponseDialog;