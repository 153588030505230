import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            color: theme.palette.common.black,
            padding: "7px",
            margin: theme.spacing(0.5, 0.5, 0.5, 0),
            marginLeft: "auto!important",
            [theme.breakpoints.up("md")]: {
                margin: theme.spacing(0, 1, 1, 0),
            },
            [theme.breakpoints.up("lg")]: {
                margin: theme.spacing(0, 1, 0, 0),
            },
        },
        progressHolder: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
        },
        progress: {
            color: theme.palette.grey["200"],
        },
        dropzoneRoot: {
            width: "100%",
            height: "calc(100vh - 250px)",
            position: "relative",
            outline: "none",
        },
        dropzoneIconBox: {
            width: "10rem",
            height: "10rem",
            background: theme.palette.grey["50"],
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all .2s ease-in-out",
            margin: "auto",
            [theme.breakpoints.up("sm")]: {
                margin: 0,
                marginTop: "95px",
            },
        },
        dropzoneDragActive: {
            transform: "scale(1.2)",
        },
        dropzone: {
            outline: "none",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column nowrap",
            },
        },
        dropzoneMessage: {
            fontSize: "1rem",
            marginTop: theme.spacing(4),
        },
        dropzoneButton: {
            marginTop: theme.spacing(2),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            "&:hover": {
                backgroundColor: darken(theme.palette.success.dark, 0.1),
            },
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1.5),
            top: theme.spacing(1.5),
            color: theme.palette.grey["500"],
        },
        errorRoot: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        }
    })
);

export default useStyles;
