import { DispatchAction } from "@iolabs/redux-utils";
import { KeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import React, { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import { onEvent, onTokens } from "./action";
import ApiClient from "../../packages/Api/ApiClient";

const keycloakDefaultInitConfig: Keycloak.KeycloakInitOptions = {
    onLoad: "login-required",
    checkLoginIframe: false,
    // adapter: "cordova",
};

interface ISecurityProvider {
    keycloakInitConfig?: Keycloak.KeycloakInitOptions;
}

const SecurityProvider: React.FC<PropsWithChildren<ISecurityProvider>> = (
    props: PropsWithChildren<ISecurityProvider>
) => {
    const { keycloakInitConfig, children } = props;
    const dispatch = useDispatch<DispatchAction>();

    const keycloak: Keycloak.KeycloakInstance = Keycloak({
        clientId: config.security.clientId,
        realm: config.security.realm,
        url: config.security.realmUrl,
    });

    ApiClient.setKeycloakInstance(keycloak);

    return (
        <KeycloakProvider
            keycloak={keycloak}
            initConfig={keycloakInitConfig ? keycloakInitConfig : keycloakDefaultInitConfig}
            onEvent={(event) =>
                dispatch(
                    onEvent({
                        event: event,
                    })
                )
            }
            onTokens={(tokens) => {
                dispatch(onTokens(tokens));
            }}
        >
            {children}
        </KeycloakProvider>
    );
};

export default SecurityProvider;
