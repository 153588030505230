import { IonRouterLink } from "@ionic/react";
import { Box } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { thumbnail } from "../../../packages/Api/data/datamanagement/client";
import DocumentIcon from "../../DocumentView/DocumentIcon/DocumentIcon";
import { Data } from "../../DocumentView/DocumentViewWrapper/DocumentViewWrapper";
import { IDocumentIconType } from "../../DocumentView/type";
import useStyles from "./styles";
import { availableThumbFormats, ThumbFormat, ThumbStatus } from "../type";

// maximal number for thumb request repeat
const defaultMaxTimeToLive = 3;

interface IPreviewProps {
    item: Data;
    handleFolderClick: (item: Data) => (event: React.MouseEvent<unknown>) => void;
    handleFileClick: (item: Data) => (event: React.MouseEvent<unknown>) => void;
}

const Preview: React.FC<IPreviewProps> = ({ item, handleFolderClick, handleFileClick }) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const [source, setSource] = useState<any>(null);
    const [timeToLive, setTimeToLive] = useState<number>(0);

    useEffect(() => {
        const fetchThumbnail = async () => {
            if (keycloak?.token && availableThumbFormats.includes((item?.format as unknown) as ThumbFormat)) {
                await thumbnail(item?.lastVersionNodeId, keycloak.token).then((response) => {
                    if (response?.status === ThumbStatus.SUCCESS) {
                        setSource(URL.createObjectURL(response?.data));
                    } else if (response?.status === ThumbStatus.LATER) {
                        setTimeout(() => {
                            if (timeToLive <= defaultMaxTimeToLive) {
                                fetchThumbnail();
                                setTimeToLive(timeToLive + 1);
                            }
                        }, 5000);
                    } else if (response?.status === ThumbStatus.ERROR) {
                    }
                });
            }
        };

        fetchThumbnail();
    }, [item]);

    return (
        <Box className={source !== null ? classes.previewBox : ""}>
            {item.isDir ? (
                <IonRouterLink
                    routerLink={item.path}
                    className={classes.ionRouterLink}
                    onClick={handleFolderClick(item)}
                >
                    <FolderIcon className={classes.icon} />
                </IonRouterLink>
            ) : (
                <IonRouterLink
                    routerLink={item.path}
                    routerDirection="forward"
                    className={classes.ionRouterLink}
                    onClick={handleFileClick(item)}
                >
                    {source === null ? (
                        <DocumentIcon item={item} type={IDocumentIconType.Thumbnail} />
                    ) : (
                        <img src={source} alt={item.name} className={classes.image} />
                    )}
                </IonRouterLink>
            )}
        </Box>
    );
};

export default Preview;
