import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Theme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { deleteFile } from "../../../packages/Api/data/datamanagement/client";
import { onLoadNode, useActiveNodeId } from "../../../redux/tree";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import messages from "./messages";
import useStyles from "./styles";

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h3">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface IDocumentDeleteDialogProps {
    item: Data;
    open: boolean;
    handleClose: () => void;
}

const DocumentDeleteDialog: React.FC<IDocumentDeleteDialogProps> = ({ item, open, handleClose }) => {
    const activeNodeId = useActiveNodeId();
    const dispatch = useDispatch<DispatchAction>();

    const handleDelete = (item: Data) => {
        handleClose();

        deleteFile(item.nodeId)
            .then(() => {
                const notification: INotification = {
                    variant: "success",
                    message: `1 ${item.isDir ? "folder" : "document"} has been deleted`,
                };
                dispatch(addNotification({ notification }));
                dispatch(onLoadNode({ nodeId: activeNodeId, depth: 1 }));
            })
            .catch(() => {
                const notification: INotification = {
                    variant: "error",
                    message: "Delete error",
                };
                dispatch(addNotification({ notification }));
            });
    };

    // translations
    const intl = useIntl();
    const transHeading = intl.formatMessage({ ...messages.heading });
    const transCancel = intl.formatMessage({ ...messages.cancel });
    const transDelete = intl.formatMessage({ ...messages.delete });

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="delete-dialog-title">
            <DialogTitle id="delete-dialog-title" onClose={handleClose}>
                {transHeading}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom variant="body2">
                    {item.isDir
                        ? intl.formatMessage(
                              { ...messages.directory },
                              {
                                  name: <strong>{item.name}</strong>,
                              }
                          )
                        : intl.formatMessage(
                              { ...messages.file },
                              {
                                  name: <strong>{item.name}</strong>,
                              }
                          )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default" variant="contained">
                    {transCancel}
                </Button>
                <Button autoFocus onClick={() => handleDelete(item)} color="secondary" variant="contained">
                    {transDelete}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DocumentDeleteDialog;
