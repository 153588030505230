import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainViewBox: {
            display: "flex",
            flexFlow: "row nowrap",
            height: `calc(100% - ${theme.spacing(2)})`,
            justifyContent: "space-between",
            padding: theme.spacing(2),
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(4),
                height: `calc(100% - ${theme.spacing(4)})`,
            },
        },
        masterBox: {
            display: "flex",
            flexFlow: "column",
            height: "100%",
            width: "40%",
        },
        controlBox: {
            display: "flex",
            flexFlow: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: theme.spacing(1),
            width: "20%",
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(3),
            },
            [theme.breakpoints.up("xl")]: {
                padding: theme.spacing(4),
            },
        },
        controlFields: {
            alignItems: "center",
            display: "flex",
            flex: 2,
            width: "100%",
            flexFlow: "column",
            justifyContent: "center",
            "& form": {
                width: "100%",
            },
        },
        controlButtons: {
            alignItems: "center",
            display: "flex",
            flex: 6,
            flexFlow: "column",
            justifyContent: "center",
        },
        controlButton: {
            margin: theme.spacing(1),
            padding: theme.spacing(3),
            minWidth: 0,
        },
        projectBox: {
            display: "flex",
            flexFlow: "column",
            height: "100%",
            width: "40%",
        },
        headingBox: {
            paddingBottom: theme.spacing(0.5),
        },
        searchBox: {
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            marginBottom: theme.spacing(1),
        },
        contentBox: {
            height: "100%",
            overflow: "auto",
            padding: theme.spacing(1, 0),
        },
        listRoot: {
            padding: 0,
        },
        listItemRoot: {
            minWidth: "40px",
            padding: 0,
            "&.showDerived": {
                background: theme.palette.primary[100],
            },
        },
        checkboxRoot: {
            paddingBottom: 0,
            paddingTop: 0,
        },
        listItemCustom: {
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
        },
        listItemValue: {
            minWidth: "90px",
            flexBasis: "20%",
            "&.showDerived, &.showHierarchy": {
                flexBasis: "30%",
            },
        },
        listItemCustomIcons: {
            minWidth: "70px",
            margin: theme.spacing(0, 4),
            "& > span": {
                background: theme.palette.grey[400],
                borderRadius: "50px",
                color: theme.palette.common.white,
                height: 18,
                width: 18,
                textAlign: "center",
                fontSize: theme.typography.pxToRem(10),
                margin: "0 2px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
            },
            "& .error": {
                background: theme.palette.error.main,
            },
            "& .success": {
                background: theme.palette.success.main,
            },
            "& .warning": {
                background: theme.palette.warning.main,
            },
        },
        listItemDescription: {
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: theme.spacing(0.3),
        },
        textField: {
            "& input": {
                fontSize: ".8rem",
            },
            "& textarea": {
                fontSize: ".8rem",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "13px 14px",
                fontSize: ".8rem",
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 14px",
            },
        },
        searchField: {
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "50%",
            },
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.grey[400],
            },
        },
        exportImport: {
            marginLeft: "auto!important",
        },
    })
);

export default useStyles;
