import { defineMessages } from "react-intl";

export default defineMessages({
    fieldEbkpAttributes: {
        id: "components.mapping.positionDialogFormNewPosition.fieldEbkpAttributes",
        defaultMessage: "e BKP-H attributes",
    },
    fieldUserAttributes: {
        id: "components.mapping.positionDialogFormNewPosition.fieldUserAttributes",
        defaultMessage: "User attributes",
    },
    fieldCode: {
        id: "components.mapping.positionDialogFormNewPosition.fieldCode",
        defaultMessage: "Code",
    },
    fieldName: {
        id: "components.mapping.positionDialogFormNewPosition.fieldName",
        defaultMessage: "Name",
    },
    fieldDescription: {
        id: "components.mapping.positionDialogFormNewPosition.fieldDescription",
        defaultMessage: "Description",
    },
    fieldShortText: {
        id: "components.mapping.positionDialogFormNewPosition.fieldShortText",
        defaultMessage: "Short text",
    },
    fieldLongText: {
        id: "components.mapping.positionDialogFormNewPosition.fieldLongText",
        defaultMessage: "Long text",
    },
    fieldStlbBauCode: {
        id: "components.mapping.positionDialogFormNewPosition.fieldStbBauCode",
        defaultMessage: "STLB-Bau",
    },
    fieldDin276Structure: {
        id: "components.mapping.positionDialogFormNewPosition.fieldDin276Structure",
        defaultMessage: "DIN276 Structure",
    },
    fieldOmniclass: {
        id: "components.mapping.positionDialogFormNewPosition.fieldOmniclass",
        defaultMessage: "Omniclass",
    },
    fieldReferenceUnit: {
        id: "components.mapping.positionDialogFormNewPosition.fieldReferenceUnit",
        defaultMessage: "Reference unit",
    },
    fieldUnitPrice: {
        id: "components.mapping.positionDialogFormNewPosition.fieldUnitPrice",
        defaultMessage: "Unit price",
    },
    fieldUnitPriceTooltip: {
        id: "components.mapping.positionDialogFormNewPosition.fieldUnitPriceTooltip",
        defaultMessage: "To modify unit price, go to the detail view",
    },
    fieldSpecifyValue: {
        id: "components.mapping.positionDialogFormNewPosition.fieldSpecifyValue",
        defaultMessage: "Specify value",
    },
});
