import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { Vector3 } from "three";
import { IIssueTab } from "../../components/Issues/type";
import { Issue } from "../../graphql/generated/graphql";
import { IVersion } from "../../packages/Api/data/tree/types";
import { SplitViewType } from "../../pages/ViewerPage/ViewerPage";
import { IIssueCreateLocationInfo, IIssueCreateState } from "./reducer";

export enum ActionTypes {
    OnIssuesList = "issues/ON_ISSUES_LIST",
    OnIssueEdit = "issues/ON_ISSUE_EDIT",
    OnIssueEditReset = "issues/ON_ISSUE_EDIT_RESET",
    OnChangeTab = "issues/ON_CHANGE_TAB",
    OnCreateChange = "issues/ON_CREATE_CHANGE",
    OnCreateChangeLocationInfo = "issues/ON_CREATE_CHANGE_LOCATION_INFO",
    OnCreateModeActive = "issues/ON_CREATE_MODE_ACTIVE",
    OnCreatePushpinPlaced = "issues/ON_CREATE_PUSHPIN_PLACED",
    OnCreateDone = "issues/ON_CREATE_DONE",
    OnIssueViewer = "issues/ON_ISSUE_VIEWER",
    OnIssueViewerChangeVersion = "issues/ON_ISSUE_VIEWER_CHANGE_VERSION",
    OnIssueViewerChangeViewable = "issues/ON_ISSUE_VIEWER_CHANGE_VIEWABLE",
    OnIssueViewerChangePath = "issues/ON_ISSUE_VIEWER_CHANGE_PATH",
    OnIssueViewerChangeSplitView = "issues/ON_ISSUE_VIEWER_CHANGE_SPLIT_VIEW",
    OnIssueViewerReset = "issues/ON_ISSUE_VIEWER_RESET",
    OnIssuesChangeVisibility = "issues/ON_CHANGE_VISIBILITY",
}

export type OnIssuesListOptions = {
    issues: Issue[];
};

export type OnIssueEditOptions = {
    issueEditId: string;
    issueTab?: IIssueTab;
    fromViewerPage?: boolean;
};

export type OnChangeTabOptions = {
    issueTab: IIssueTab;
};

export type OnCreateChangeOptions = {
    prop: keyof IIssueCreateState;
    value: string | Date | boolean | null;
};

export type OnCreateChangeLocationInfoOptions = {
    locationInfo: IIssueCreateLocationInfo | null;
};

export type OnCreateModeActiveOptions = {
    isModeActive: boolean;
};

export type OnCreatePushpinPlacedOptions = {
    isPushpinPlaced: boolean;
    color?: string;
    coords?: Vector3;
};

export type OnIssueViewerOptions = {
    urn?: string | null;
    fileId?: string | null;
    version?: IVersion | null;
};

export type OnIssueViewerChangeVersionOptions = {
    version: IVersion | null;
};

export type OnIssueViewerChangeViewableOptions = {
    viewable: any;
};

export type OnIssueViewerChangePathOptions = {
    path: string;
};

export type OnIssueViewerChangeSplitViewOptions = {
    splitView: SplitViewType;
};

export type OnIssuesChangeVisibilityOptions = {
    isVisible: boolean;
};

const Actions = {
    onIssuesList: (options: OnIssuesListOptions): void => createAction(ActionTypes.OnIssuesList, options),
    onIssueEdit: (options: OnIssueEditOptions): void => createAction(ActionTypes.OnIssueEdit, options),
    onIssueEditReset: (): void => createAction(ActionTypes.OnIssueEditReset),
    onChangeTab: (options: OnChangeTabOptions): void => createAction(ActionTypes.OnChangeTab, options),
    onCreateChange: (options: OnCreateChangeOptions): void => createAction(ActionTypes.OnCreateChange, options),
    onCreateChangeLocationInfo: (options: OnCreateChangeLocationInfoOptions): void =>
        createAction(ActionTypes.OnCreateChangeLocationInfo, options),
    onCreateModeActive: (options: OnCreateModeActiveOptions): void =>
        createAction(ActionTypes.OnCreateModeActive, options),
    onCreatePushpinPlaced: (options: OnCreatePushpinPlacedOptions): void =>
        createAction(ActionTypes.OnCreatePushpinPlaced, options),
    onCreateDone: (): void => createAction(ActionTypes.OnCreateDone),
    onIssueViewer: (options: OnIssueViewerOptions): void => createAction(ActionTypes.OnIssueViewer, options),
    onIssueViewerChangeVersion: (options: OnIssueViewerChangeVersionOptions): void =>
        createAction(ActionTypes.OnIssueViewerChangeVersion, options),
    onIssueViewerChangeViewable: (options: OnIssueViewerChangeViewableOptions): void =>
        createAction(ActionTypes.OnIssueViewerChangeViewable, options),
    onIssueViewerChangePath: (options: OnIssueViewerChangePathOptions): void =>
        createAction(ActionTypes.OnIssueViewerChangePath, options),
    onIssueViewerChangeSplitView: (options: OnIssueViewerChangeSplitViewOptions): void =>
        createAction(ActionTypes.OnIssueViewerChangeSplitView, options),
    onIssueViewerReset: (): void => createAction(ActionTypes.OnIssueViewerReset),
    onIssueChangeVisibility: (options: OnIssuesChangeVisibilityOptions): void =>
        createAction(ActionTypes.OnIssuesChangeVisibility, options),
};

export function onIssuesList(options: OnIssuesListOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssuesList(options));
    };
}

export function onIssueEdit(options: OnIssueEditOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueEdit(options));
    };
}

export function onIssueEditReset(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueEditReset());
    };
}

export function onChangeTab(options: OnChangeTabOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onChangeTab(options));
    };
}

export function onCreateChange(options: OnCreateChangeOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCreateChange(options));
    };
}

export function onCreateChangeLocationInfo(options: OnCreateChangeLocationInfoOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCreateChangeLocationInfo(options));
    };
}

export function onCreateModeActive(options: OnCreateModeActiveOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCreateModeActive(options));
    };
}

export function onCreatePushpinPlaced(options: OnCreatePushpinPlacedOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCreatePushpinPlaced(options));
    };
}

export function onCreateDone(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onCreateDone());
    };
}

export function onIssueViewer(options: OnIssueViewerOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueViewer(options));
    };
}

export function onIssueViewerChangeVersion(options: OnIssueViewerChangeVersionOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueViewerChangeVersion(options));
    };
}

export function onIssueViewerChangeViewable(options: OnIssueViewerChangeViewableOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueViewerChangeViewable(options));
    };
}

export function onIssueViewerChangePath(options: OnIssueViewerChangePathOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueViewerChangePath(options));
    };
}

export function onIssueViewerChangeSplitView(options: OnIssueViewerChangeSplitViewOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueViewerChangeSplitView(options));
    };
}

export function onIssueViewerReset(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueViewerReset());
    };
}

export function onIssueChangeVisibility(options: OnIssuesChangeVisibilityOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onIssueChangeVisibility(options));
    };
}
