import { AnyAction } from "redux";
import { IDocumentView } from "../../components/DocumentView/type";
import { ActionTypes } from "./action";

export interface ILayoutModuleState {
    layoutState: ILayoutState;
}

export interface ILayoutState {
    splitView: boolean;
    drawer: boolean;
    documentView: IDocumentView;
}

export const initialState: Readonly<ILayoutState> = {
    splitView: false,
    drawer: false,
    documentView: IDocumentView.Table,
};

export function reducer(state: ILayoutState = initialState, action: AnyAction): ILayoutState {
    switch (action.type) {
        case ActionTypes.OnSplitView: {
            const { splitView } = action.payload;
            return {
                ...state,
                splitView,
            };
        }

        case ActionTypes.OnDrawer: {
            const { drawer } = action.payload;
            return {
                ...state,
                drawer,
            };
        }

        case ActionTypes.OnDocumentView: {
            const { documentView } = action.payload;
            return {
                ...state,
                documentView,
            };
        }

        default:
            return state;
    }
}
