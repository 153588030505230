import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detailViewBox: {
            display: "flex",
            flexFlow: "row nowrap",
            height: `calc(100% - ${theme.spacing(1)})`,
            justifyContent: "space-between",
            padding: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(2),
                height: `calc(100% - ${theme.spacing(2)})`,
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(3),
                height: `calc(100% - ${theme.spacing(3)})`,
            },
            [theme.breakpoints.up("xl")]: {
                padding: theme.spacing(4),
                height: `calc(100% - ${theme.spacing(4)})`,
            },
        },
        summaryBox: {
            display: "flex",
            flexFlow: "column",
            height: "100%",
            width: "20%",
            flexBasis: "20%",
            flex: 1,
            [theme.breakpoints.up("md")]: {
                width: "15%",
                flexBasis: "15%",
            },
            [theme.breakpoints.up("lg")]: {
                width: "13%",
                flexBasis: "13%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "10%",
                flexBasis: "10%",
            },
        },
        summaryPaper: {
            height: "100%",
            padding: theme.spacing(1),
            overflowY: "auto",
            overflowX: "hidden",
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(2),
            },
        },
        contentBox: {
            display: "flex",
            flexFlow: "column",
            height: "100%",
            width: "45%",
            flexBasis: "45%",
            overflow: "auto",
            flex: 1,
            [theme.breakpoints.up("md")]: {
                width: "50%",
                flexBasis: "50%",
            },
            [theme.breakpoints.up("lg")]: {
                width: "53%",
                flexBasis: "53%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "60%",
                flexBasis: "60%",
            },
        },
        stepperContent: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: theme.spacing(2),
            overflow: "auto",
        },
        stepBox: {
            paddingTop: theme.spacing(1),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        stepBackButton: {
            marginRight: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                marginRight: theme.spacing(2),
            },
        },
        viewerBox: {
            display: "flex",
            flexFlow: "column",
            height: "100%",
            width: "25%",
            flexBasis: "25%",
            flex: 1,
            [theme.breakpoints.up("sm")]: {},
            [theme.breakpoints.up("md")]: {},
            [theme.breakpoints.up("lg")]: {},
            [theme.breakpoints.up("xl")]: {},
        },
        viewerPaper: {
            height: "100%",
            overflow: "hidden",
        },
    })
);

export default useStyles;
