import { defineMessages } from "react-intl";

export default defineMessages({
    tooltipAvailableOffline: {
        id: "components.documentOffline.tooltipAvailableOffline",
        defaultMessage: "Available offline",
    },
    tooltipDownloadForOffline: {
        id: "components.documentOffline.tooltipDownloadForOffline",
        defaultMessage: "Download for offline usage",
    },
});
