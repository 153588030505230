import { AnyAction } from "redux";
import { IIssueState } from "../../components/Issues/type";
import { CreateIssueRemoteMutationVariables, Issue } from "../../graphql/generated/graphql";
import { ActionTypes } from "./action";
import { IIsolation } from "../../components/Viewer/extensions/Viewing.Extensions.BulkIsolation/Viewing.Extensions.BulkIsolationExtension";

export interface IViewerModuleState {
    viewerState: IViewerState;
}

export interface IViewerState {
    urn: string;
    urnBase64?: string;
    forgeId: string;
    isolateIds: number[];
    api?: string;
    isEmea?: boolean;
    fileId?: string;
    fileType?: string;
    objectsInContext: { [key: number]: any[] };
    objectsInContextSmart: string[];
    objectsInContextViewerIds: number[];
    issues: IIssueState[];
    issuables: IIssuable[];
    printables: IPrintable[];
    models: Model[];
    preferredViewableGuid?: string;
    bulkIsolations: { [key: string]: IIsolation };
    viewable: any;
    activeViewables?: any[];
    markupsData?: IMarkupsData;
    markupsExternalHandling: boolean;
    forgeToken?: string;
}

export type IIssuable = {
    id: string;
    name?: string;
    collectIssueData: (onUpdated: (partialData: Partial<CreateIssueRemoteMutationVariables>) => void) => void;
    stopCollecting?: () => void;
    renderIssues: (issuesRenderer: IIssueRenderer[]) => void;
    disabled?: () => boolean;
};

export type IIssueRenderer = {
    issue: Issue;
    editAllowed?: boolean;
};

export type IPrintable = {
    id: string;
    name: string;
    callback: () => void;
    disabled?: () => boolean;
};

export type IMarkupsData = {
    data: string;
    viewerState: any;
    name?: string;
    temp?: boolean;
};

export type IMarkupsHandler = {
    // id: string;
    // name: string;
    test: string;
    callback: (content: string, state: string, name?: string) => void;
    // disabled?: () => boolean;
};

export type Model = {
    id: number;
    versionId?: number;
    variantId?: number;
    issues: IIssueState[];
    selectedIds: string[];
    showOnlyIds?: string[];
};

export const initialState: Readonly<IViewerState> = {
    urn: "",
    forgeId: "",
    isolateIds: [],
    objectsInContext: {},
    objectsInContextSmart: [],
    objectsInContextViewerIds: [],
    issues: [],
    issuables: [],
    printables: [],
    models: [],
    bulkIsolations: {},
    viewable: "",
    markupsExternalHandling: false,
};

export function reducer(state: IViewerState = initialState, action: AnyAction): IViewerState {
    switch (action.type) {
        case ActionTypes.OnLoadUrn: {
            const { urn, forgeId, fileId, fileType } = action.payload;

            if (forgeId) {
                return { ...state, urn, forgeId };
            } else if (fileId && fileType) {
                return { ...state, urn, fileId, fileType };
            } else {
                return { ...state, urn };
            }
        }

        case ActionTypes.OnIsolateIds: {
            const { isolateIds } = action.payload;
            return { ...state, isolateIds };
        }

        case ActionTypes.OnProxyDone: {
            const { api, urn, isEmea } = action.payload;
            return { ...state, urnBase64: urn, api, isEmea };
        }

        case ActionTypes.SetObjectsToContext: {
            return {
                ...state,
                objectsInContext: action.payload.objects,
                objectsInContextSmart: action.payload.objectsSmart,
                objectsInContextViewerIds: action.payload.viewerIds,
            };
        }

        case ActionTypes.OnCreateIssue: {
            const { issue } = action.payload;
            return { ...state, issues: [...state.issues, issue] };
        }

        case ActionTypes.OnRegisterIssuable: {
            const { issuable } = action.payload;
            return { ...state, issuables: [...state.issuables, issuable] };
        }

        case ActionTypes.OnUnregisterIssuable: {
            const { id } = action.payload;
            return { ...state, issuables: [...state.issuables.filter((item: IIssuable) => item.id !== id)] };
        }

        case ActionTypes.OnRegisterPrintable: {
            const { printable } = action.payload;
            return { ...state, printables: [...state.printables, printable] };
        }

        case ActionTypes.OnUnregisterPrintable: {
            const { id } = action.payload;
            return { ...state, printables: [...state.printables.filter((item: IPrintable) => item.id !== id)] };
        }

        case ActionTypes.OnMarkupsSetData: {
            const { markupsData } = action.payload;
            return { ...state, markupsData: markupsData };
        }

        case ActionTypes.OnMarkupsSetExternalHandling: {
            const { externalHandling } = action.payload;
            return { ...state, markupsExternalHandling: externalHandling };
        }

        case ActionTypes.OnViewableSelected: {
            const { index, viewable } = action.payload;
            return {
                ...state,
                models: state.models.map((model, i) => {
                    return i === index ? { ...model, variantId: viewable.id } : { ...model };
                }),
            };
        }

        case ActionTypes.OnViewable: {
            const { viewable } = action.payload;
            return {
                ...state,
                viewable,
            };
        }

        case ActionTypes.OnViewableNavigate: {
            const { guid } = action.payload;
            return {
                ...state,
                preferredViewableGuid: guid,
            };
        }

        case ActionTypes.OnViewerToken: {
            const { token } = action.payload;
            return {
                ...state,
                forgeToken: token,
            };
        }

        case ActionTypes.OnActiveViewables: {
            const { viewables } = action.payload;
            return {
                ...state,
                activeViewables: viewables,
            };
        }

        default:
            return state;
    }
}
