import { DispatchAction } from "@iolabs/redux-utils";
import { Dialog, DialogContent, IconButton, Paper, PaperProps, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { ResizableBox } from "react-resizable";
import { onResetSpecificationView } from "../../../redux/specification";
import useStyles from "./styles";
import { SpecificationView } from "../type";

function PaperComponent(props: PaperProps) {
    const classes = useStyles();

    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} className={classes.dialogPaper}>
                <ResizableBox
                    {...props}
                    width={800}
                    height={600}
                    minConstraints={[400, 300]}
                    className={classes.resizable}
                />
            </Paper>
        </Draggable>
    );
}

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    handleClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
    const { children, handleClose, ...other } = props;
    const classes = useStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
            <Typography variant="h3">{children}</Typography>
            {handleClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    onTouchStart={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

interface IDialogExternProps {
    dialogTitle?: string;
    view: SpecificationView;
    children: React.ReactNode;
}

const DialogExtern: React.FC<IDialogExternProps> = ({ dialogTitle, view, children }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const handleClose = () => {
        dispatch(onResetSpecificationView({ view }));
    };

    return (
        <Dialog
            open
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth={false}
        >
            <DialogTitle id="draggable-dialog-title" handleClose={handleClose}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        </Dialog>
    );
};

export default DialogExtern;
