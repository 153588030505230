import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

export interface IHeaderContext {
    headerContent: React.ReactNode;
    headerSettings: React.ReactNode;
    setHeaderContent: Dispatch<SetStateAction<React.ReactNode | null>>;
    setHeaderSettings: Dispatch<SetStateAction<React.ReactNode | null>>;
}

export const HeaderContext = createContext<IHeaderContext>({
    headerContent: null,
    headerSettings: null,
    setHeaderContent: () => {},
    setHeaderSettings: () => {},
});

interface IHeaderProviderProps {}

const HeaderProvider: React.FC<PropsWithChildren<IHeaderProviderProps>> = ({ children }) => {
    const [headerContent, setHeaderContent] = useState<React.ReactNode>();
    const [headerSettings, setHeaderSettings] = useState<React.ReactNode>();

    const value = { headerContent, headerSettings, setHeaderContent, setHeaderSettings };

    return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

export default HeaderProvider;
