import { defineMessages } from "react-intl";

export default defineMessages({
    modelPosition: {
        id: "components.mapping.stepElement.modelPosition",
        defaultMessage: "Model position",
    },
    manualPosition: {
        id: "components.mapping.stepElement.manualPosition",
        defaultMessage: "Manual position", // Manuelle Position
    },
    positionCommon: {
        id: "components.mapping.stepElement.positionCommon",
        defaultMessage: "Common",
    },
    fieldValue: {
        id: "components.mapping.stepElement.manualPosition.fieldValue",
        defaultMessage: "Value",
    },
    fieldReference: {
        id: "components.mapping.stepElement.manualPosition.fieldReference",
        defaultMessage: "Reference",
    },
    fieldFormula: {
        id: "components.mapping.stepElement.manualPosition.fieldFormula",
        defaultMessage: "Formula",
    },
    fieldParameter: {
        id: "components.mapping.stepElement.manualPosition.fieldParameter",
        defaultMessage: "Parameter",
    },
    fieldSpecify: {
        id: "components.mapping.stepElement.manualPosition.fieldSpecify",
        defaultMessage: "Specify...",
    },
    validateValue: {
        id: "components.mapping.stepElement.manualPosition.validateValue",
        defaultMessage: "Please enter a value",
    },
    unknownPositionType: {
        id: "components.mapping.stepElement.manualPosition.unknownPositionType",
        defaultMessage: "Unknown position type",
    },
    switchModelPosition: {
        id: "components.mapping.stepElement.switchModelPosition",
        defaultMessage: "Model position",
    },
    switchManualPosition: {
        id: "components.mapping.stepElement.switchManualPosition",
        defaultMessage: "Manual position",
    },
    switchDerivedPosition: {
        id: "components.mapping.stepElement.switchDerivedPosition",
        defaultMessage: "Derived position",
    },
});
