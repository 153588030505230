import { DispatchAction } from "@iolabs/redux-utils";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import ForgeViewer from "iolabs-react-forge-viewer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../../config/config";
import { viewerProxy } from "../../../packages/Api/data/viewer/client";
import { IViewerProxyResponse } from "../../../packages/Api/data/viewer/types";
import { ViewerRole } from "../../ProjectViewer/ProjectViewer";
import { Emea, ViewerState } from "../type";
import ViewSelector from "../ViewSelector/ViewSelector";
import useStyles from "./styles";
import { isJwtExpired } from "../../../utils/Jwt";
import { onViewerTokenRequest, useForgeToken } from "../../../redux/viewer";

export interface IViewerLightProps {
    projectId: string;
    urn: string;
    showViewables?: boolean;
    onSelectViewable?: (viewable: any) => void;
    onSelectObject?: (viewer, objectIDs: any) => void;
    isolatedObjects?: number[];
}

const ViewerLight: React.FC<IViewerLightProps> = ({ projectId, urn, showViewables, onSelectViewable, isolatedObjects, onSelectObject}) => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const [viewer, setViewer] = useState<any>();
    const [viewerState, setViewerState] = useState<ViewerState>();
    const [modelLoaded, setModelLoaded] = useState<boolean>(false);

    // viewables
    const [viewable, setViewable] = useState<any>();
    const [viewables, setViewables] = useState<any>();
    const viewableRef = useRef(viewable);

    const forgeToken = useForgeToken();

    useEffect(() => {
        if (keycloak?.token && urn && projectId) {
            viewerProxy(keycloak.token, urn, projectId, false)
                .then((response: IViewerProxyResponse) => {
                    setViewerState({
                        api: response?.api,
                        urn: response?.urn,
                        isEmea: response?.isEmea,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        return () => {
            setViewerState(undefined);
        };
    }, [urn, projectId, keycloak, keycloakInitialized]);

    useEffect(() => {
        if (viewer && modelLoaded) {
            if (isolatedObjects?.length) {
                viewer.isolate(isolatedObjects)
                viewer.select(isolatedObjects[0])
            } else {
                viewer.isolate([]);
                viewer.clearSelection();
            }
        }
    }, [isolatedObjects, viewer, modelLoaded])

    const handleViewerError = () => {
        console.error("Error loading ViewerLight.");
    };

    const handleDocumentLoaded = (doc: any, viewables: any[]) => {
        setViewable(viewables[0]);
        setViewables(viewables);
    };

    const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
        setViewer(viewer);
    };

    const handleDocumentError = (viewer: any, error: any) => {
        console.error("Error loading a document.");
    };

    const handleSelectObject = (selection) => {
        const selected: number[]|undefined = selection.dbIdArray;
        if (onSelectObject) {
            onSelectObject(viewer, selected);
        }
    }

    const setViewerBackground = (viewer: Autodesk.Viewing.Viewer3D) => {
        viewer.setBackgroundColor(251, 251, 251, 251, 251, 251);
    };

    const handleModelLoaded = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => {
        if (viewer) {
            setViewerBackground(viewer);
            viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
                // viewer settings - disabled ground shadow
                viewer.setGroundShadow(false);
            });
            viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (selection) => {
                handleSelectObject(selection);
            });
            viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {});
        }

        setModelLoaded(true);
    };

    const handleForgeScriptLoaded = () => {};

    const handleModelError = () => {
        console.error("Error loading the model.");
    };

    const handleTokenRequested = (onAccessToken: any) => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            dispatch(
                onViewerTokenRequest({
                    onTokenFetched: onAccessToken
                })
            );
        }
        else {
            onAccessToken(forgeToken);
        }
    };

    const onUnmount = () => {};

    // viewables
    const handleSelectViewable = (viewable) => {
        handleSetViewable(viewable);
    };

    const handleSetViewable = (data) => {
        viewableRef.current = data;
        setViewable(data);
        if (onSelectViewable) {
            onSelectViewable(data);
        }
    };

    const getViewableName = (viewable: Autodesk.Viewing.BubbleNode): string => {
        return (viewable.data.name as unknown) as string;
    };

    return (
        <Box className={classes.root}>
            {viewerState?.urn ? (
                <Box className={classes.box}>
                    {showViewables && (
                        <ViewSelector
                            viewables={viewables}
                            viewable={viewable ? viewable : viewables?.[0]} // intentionally pass first viewable as default
                            onSelectViewable={handleSelectViewable}
                            getViewableName={getViewableName}
                            role={ViewerRole["3D"]}
                        />
                    )}

                    <ForgeViewer
                        version="7.51"
                        urn={viewerState?.urn}
                        api={viewerState?.isEmea ? Emea.eu : Emea.default}
                        view={viewable}
                        query={{ type: "geometry" }}
                        headless={false}
                        onViewerError={handleViewerError}
                        onTokenRequest={handleTokenRequested}
                        onDocumentLoad={handleDocumentLoaded}
                        onDocumentError={handleDocumentError}
                        onModelLoad={handleModelLoaded}
                        onModelError={handleModelError}
                        onViewerLoad={handleViewerLoaded}
                        onScriptLoaded={handleForgeScriptLoaded}
                        onUnmount={onUnmount}
                        forgeScriptAlreadyLoaded
                        config={{
                            memory: {
                                limit: 1000,
                            },
                        }}
                    />
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                    <Skeleton variant="rect" width="100%" className={classes.skeleton} />
                </Box>
            )}
        </Box>
    );
};

export default ViewerLight;
