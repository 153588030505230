import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "relative",
            width: "100%",
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            [theme.breakpoints.up("xl")]: {
                padding: theme.spacing(2),
            },
        },
        form: {
            width: "100%",
        },
        fieldBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "relative",
        },
        textField: {
            "&:not(:last-child)": {
                marginRight: theme.spacing(1),
            },
            "& input": {
                padding: "10px 15px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset > legend": {
                maxWidth: 0,
            },
            "& label": {
                paddingLeft: "5px",
                paddingRight: "5px",
                background: theme.palette.common.white,
            },
        },
        fieldType: {
            flex: 1,
        },
        fieldCode: {
            flex: 3,
        },
        fieldName: {
            flex: 4,
        },
        fieldDescription: {
            flex: 5,
        },
        fieldReferenceUnit: {
            flex: 2,
        },
        removeButton: {
            marginTop: theme.spacing(1),
        },
        derivedPositionPopover: {
            marginTop: theme.spacing(1),
            marginLeft: `-${theme.spacing(0.5)}`,
        },
        skeletonBox: {
            width: "100%",
            marginTop: theme.spacing(1.9),
        },
        skeleton: {
            marginBottom: theme.spacing(2.1),
            borderRadius: theme.shape.borderRadius,
        },
        skeletonCircle: {
            marginTop: `-${theme.spacing(0.5)}`,
            borderRadius: "50%",
        },
        ifConditionBadge: {
            width: "20px",
            height: "20px",
            fontSize: "12px",
            "& .MuiChip-label": {
                padding: "4px"
            },
            marginTop: theme.spacing(2.3),
            position: "absolute",
            left: "-10px",
            zIndex: 1,
        }
    })
);

export default useStyles;
