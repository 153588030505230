import { Dialog, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import Icon from "../../../Icon/Icon";
import PositionDialogForm from "../PositionDialogForm/PositionDialogForm";
import useStyles from "./styles";
import { DerivedPositionMode } from "../../StepElementDerivedPositions/StepElementDerivedPositions";
import { PositionDialogType, PositionLevel } from "../PositionDialogForm/type";

interface IDerivedDialogProps {
    mode: DerivedPositionMode;
}

const DerivedDialog: React.FC<IDerivedDialogProps> = ({mode}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="add"
                color="primary"
                className={clsx("globalAddButton", classes.addButton)}
                onClick={handleOpen}
            >
                <Icon name="plus" size={20} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="derived-position-dialog-title"
                aria-describedby="derived-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
                className={classes.root}
            >
                <PositionDialogForm
                    type={mode == DerivedPositionMode.To ? PositionDialogType.basicPosition : PositionDialogType.derivedPosition}
                    handleClose={handleClose}
                    allowMultiSelect={mode == DerivedPositionMode.To ? [PositionLevel.FOUR, PositionLevel.FIVE] : [PositionLevel.FIVE]}
                />
            </Dialog>
        </>
    );
};

export default DerivedDialog;
