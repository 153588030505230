import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export type HeaderStylesProps = {
    headerHeight?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: HeaderStylesProps | undefined) => ({
            flexWrap: "wrap",
            minHeight: props?.headerHeight ? props?.headerHeight : "45px",
            [theme.breakpoints.up("sm")]: {
                flexWrap: "nowrap",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minHeight: props?.headerHeight ? props?.headerHeight : "45px",
                height: props?.headerHeight ? props?.headerHeight : "45px",
            },
            zIndex: 200,
        }),
        close: {
            paddingLeft: `${theme.spacing(7)} !important`,
        },
        menu: {
            flex: "1 1 0%",
            textAlign: "right",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-end",
            alignItems: "center",
            order: 1,
            [theme.breakpoints.up("sm")]: {
                order: 2,
                flexBasis: "30%",
            },
            [theme.breakpoints.up("lg")]: {
                flexBasis: "50%",
            },
        },
        menuButton: {
            minWidth: "auto",
            paddingLeft: 0,
            paddingRight: 0,
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        headingBox: {
            display: "flex",
            flexGrow: 1,
            flexBasis: "100%",
            alignSelf: "flex-start",
            alignItems: "center",
            textAlign: "left",
            color: theme.palette.common.black,
            order: 2,
            minHeight: "35px",
            [theme.breakpoints.up("sm")]: {
                flexBasis: "70%",
                minHeight: "45px",
                order: 1,
            },
            [theme.breakpoints.up("lg")]: {
                flexBasis: "30%",
            },
        },
        popper: {
            zIndex: 10,
        },
        avatar: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.secondary.main,
            textTransform: "uppercase",
            width: "30px",
            height: "30px",
            fontSize: "1rem",
        },
        menuList: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.main,
            minWidth: theme.spacing(18),
            [theme.breakpoints.up("sm")]: {
                "& li": {
                    justifyContent: "flex-start",
                },
            },
        },
        menuBox: {
            display: "block",
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        menuItemIcon: {
            color: theme.palette.text.secondary,
            minWidth: "35px",
        },
        divider: {
            backgroundColor: theme.palette.text.secondary,
            opacity: 0.2,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
    })
);

export default useStyles;
