import { ProjectData } from "../components/ProjectSelectorWrapper/type";
import { useActiveProject } from "../redux/project";
import { generatePath } from "react-router";
import { AppPage } from "../pages/PageRouter";

/**
 * Detect active menu item
 * @param url
 * @param item
 */
const isMenuItemActive = (url: string, item: string): boolean => {
    return url.startsWith(item);
};


export const useGetPath = () => {
    const activeProject: ProjectData | undefined = useActiveProject();

    const getPath = (path: string): string => {
        return activeProject ? generatePath(path, {
            accountUrn: activeProject?.externalData?.[0]?.accountUrn,
            projectUrn: activeProject?.externalData?.[0]?.projectUrn,
        }) : "#";
    };

    const getPagePath = (page: AppPage): string => {
        return getPath(page.path)
    };

    return { getPath, getPagePath };
}

export default isMenuItemActive;
