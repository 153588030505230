import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Checkbox, CircularProgress, IconButton, Input, TableSortLabel } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import MuiVirtualizedTable from "mui-virtualized-table";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { IElementsPosition } from "../../../../packages/Api/data/elements/types";
import { IMappingEquipment } from "../../../../packages/Api/data/equipment/types";
import {
    onAddEquipmentCatalogue,
    onAddEquipmentToPosition,
    onDeleteEquipmentCatalogue,
    onEquipmentCatalogue,
    onUpdateEquipmentCatalogue,
    useDetailPosition,
    useMappingEquipmentCatalogue,
    useMappingEquipmentLoading,
} from "../../../../redux/mapping";
import { useActiveProject } from "../../../../redux/project";
import { formatNumber } from "../../../../utils/Formatter";
import Icon from "../../../Icon/Icon";
import { ProjectData } from "../../../ProjectSelectorWrapper/type";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import RowSubMenu from "../RowSubMenu/RowSubMenu";
import useStyles from "./styles";
import { getComparator, Order, stableSort } from "./utils";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IDefaultProps {
    headerHeight: number;
    rowHeight: number;
    minTableWidth: number;
}

interface IMappingEquipmentUpdate extends IMappingEquipment {
    isUpdateMode: boolean;
    isAddMode: boolean;
}

interface IEquipmentDialogProps {
    addModeActive: boolean;
    selectedEquipmentIds: number[];
    handleAddModeActive: (active: boolean) => void;
    handleSelectedEquipmentIds: (rowIDs: number[]) => void;
    handleClose: () => void;
    ref: any;
}

const EquipmentDialog: React.FC<IEquipmentDialogProps> = forwardRef(
    ({ addModeActive, selectedEquipmentIds, handleAddModeActive, handleSelectedEquipmentIds, handleClose }, ref) => {
        const classes = useStyles();
        const { keycloak, initialized: keycloakInitialized } = useKeycloak();
        const dispatch = useDispatch<DispatchAction>();
        const activeProject: ProjectData | undefined = useActiveProject();
        const detailPosition: IElementsPosition | undefined = useDetailPosition();
        const equipmentLoading: boolean = useMappingEquipmentLoading();
        const equipmentCatalogue: IMappingEquipment[] = useMappingEquipmentCatalogue();

        const addModeRef = useRef(null);

        const [defaultProps] = useState<IDefaultProps>({
            headerHeight: 28,
            rowHeight: 28,
            minTableWidth: 2000,
        });

        const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
        const [confirmDelete, setConfirmDelete] = useState<IMappingEquipmentUpdate | null>(null);

        const [updateEquipmentCatalogue, setUpdateEquipmentCatalogue] = useState<IMappingEquipmentUpdate[]>([]);
        const [previous, setPrevious] = useState({});

        const [order, setOrder] = useState<Order>(Order.ASC);
        const [orderBy, setOrderBy] = useState<keyof IMappingEquipmentUpdate>("itemNumber");

        const handleConfirmOpen = () => {
            setConfirmOpen(true);
        };

        const handleConfirmClose = () => {
            setConfirmOpen(false);
        };

        useImperativeHandle(ref, () => ({
            handlePrepareAddEquipment() {
                // @ts-ignore
                addModeRef?.current?.scrollIntoView();
                setUpdateEquipmentCatalogue((prevState) => {
                    return [
                        ...prevState,
                        {
                            equipmentID: -666,
                            productID: "New equipment",
                            productName: "",
                            itemNumber: "",
                            liPrice: 0,
                            surcharge: 0,
                            ep: 0,
                            isAddMode: true,
                            isUpdateMode: true,
                        },
                    ];
                });
                handleAddModeActive(true);
            },

            handleAddEquipmentToPosition() {
                if (!activeProject && !detailPosition) return;
                dispatch(
                    onAddEquipmentToPosition({
                        token: keycloak?.token as string,
                        projectID: activeProject?.projectID as number,
                        positionID: detailPosition?.positionID as number,
                        equipmentIDs: selectedEquipmentIds,
                    })
                );
                handleAddModeActive(false);
                handleSelectedEquipmentIds([]);
                handleClose();
            },
        }));

        const handleAddEquipment = (row: IMappingEquipmentUpdate) => {
            dispatch(
                onAddEquipmentCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    request: {
                        productID: row?.productID,
                        productName: row?.productName,
                        itemNumber: row?.itemNumber,
                        liPrice: row?.liPrice,
                        surcharge: row?.surcharge,
                        ep: row?.ep,
                    },
                })
            );
            handleAddModeActive(false);
        };

        const handleDiscardAddEquipment = (equipmentID: number) => {
            handleAddModeActive(false);
            setUpdateEquipmentCatalogue((prevState) => {
                return prevState?.filter((equipment) => equipment?.equipmentID !== equipmentID);
            });
        };

        const handleChange = (event, row: IMappingEquipmentUpdate) => {
            event.stopPropagation();

            if (!previous[row?.equipmentID] && !row?.isAddMode) {
                setPrevious((prevState) => ({ ...prevState, [row?.equipmentID]: row }));
            }
            const value = event.target.value;
            const name = event.target.name;
            const updatedEquipmentCatalogue = updateEquipmentCatalogue?.map((equipment) => {
                return Object.is(equipment?.equipmentID, row?.equipmentID) ? { ...equipment, [name]: value } : equipment;
            });
            setUpdateEquipmentCatalogue(updatedEquipmentCatalogue);
        };

        const handleToggleUpdateMode = (equipmentID: number) => {
            setUpdateEquipmentCatalogue((prevState) => {
                return prevState?.map((equipment) => {
                    return equipment?.equipmentID === equipmentID ? { ...equipment, isUpdateMode: !equipment.isUpdateMode } : equipment;
                });
            });
        };

        const handleUpdateEquipment = (equipmentID: number) => {
            const updatedEquipment = updateEquipmentCatalogue?.find((uwc) => uwc?.equipmentID === equipmentID);
            if (!updatedEquipment) return;

            dispatch(
                onUpdateEquipmentCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    equipmentID: equipmentID,
                    request: {
                        ep: updatedEquipment?.ep,
                        itemNumber: updatedEquipment?.itemNumber,
                        liPrice: updatedEquipment?.liPrice,
                        productID: updatedEquipment?.productID,
                        productName: updatedEquipment?.productName,
                        surcharge: updatedEquipment?.surcharge,
                    },
                })
            );
            handleToggleUpdateMode(equipmentID);
        };

        const handleRevertEquipment = (equipmentID: number) => {
            const revertedEquipmentCatalogue = updateEquipmentCatalogue?.map((equipment) => {
                return equipment.equipmentID === equipmentID && previous[equipmentID] ? previous[equipmentID] : equipment;
            });

            const revertedEquipment = revertedEquipmentCatalogue?.find((rwc) => rwc?.equipmentID === equipmentID);
            if (!revertedEquipment) return;

            setUpdateEquipmentCatalogue(revertedEquipmentCatalogue);
            setPrevious((prevState) => {
                delete prevState[equipmentID];
                return prevState;
            });

            dispatch(
                onUpdateEquipmentCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    equipmentID: equipmentID,
                    request: {
                        ep: revertedEquipment?.ep,
                        itemNumber: revertedEquipment?.itemNumber,
                        liPrice: revertedEquipment?.liPrice,
                        productID: revertedEquipment?.productID,
                        productName: revertedEquipment?.productName,
                        surcharge: revertedEquipment?.surcharge,
                    },
                })
            );
            handleToggleUpdateMode(equipmentID);
        };

        const handleDeleteEquipment = (row: IMappingEquipmentUpdate) => {
            setConfirmDelete(row);
            handleConfirmOpen();
        };

        const handleConfirmDeleteEquipment = () => {
            if (!confirmDelete) return;

            dispatch(
                onDeleteEquipmentCatalogue({
                    token: keycloak?.token as string,
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    equipmentID: confirmDelete?.equipmentID,
                })
            );
            setConfirmDelete(null);
            handleConfirmClose();
        };

        const handleRequestSort = (property: keyof IMappingEquipmentUpdate) => () => {
            const isAsc = orderBy === property && order === Order.ASC;
            setOrder(isAsc ? Order.DESC : Order.ASC);
            setOrderBy(property);
        };

        useEffect(() => {
            // @ts-ignore
            setUpdateEquipmentCatalogue(stableSort(updateEquipmentCatalogue, getComparator(order, orderBy)));
        }, [order, orderBy]);

        useEffect(() => {
            // @ts-ignore
            setUpdateEquipmentCatalogue(stableSort(equipmentCatalogue, getComparator(order, orderBy)));
        }, [equipmentCatalogue]);

        useEffect(() => {
            dispatch(onEquipmentCatalogue({ token: keycloak?.token as string }));
        }, [keycloakInitialized]);


        // translations
        const intl = useIntl();
        const transLabelItemNumber = intl.formatMessage({ ...messages.labelItemNumber });
        const transLabelProductId = intl.formatMessage({ ...messages.labelProductId });
        const transLabelProductName = intl.formatMessage({ ...messages.labelProductName });
        const transLabelListPrice = intl.formatMessage({ ...messages.labelListPrice });
        const transLabelSurcharge = intl.formatMessage({ ...messages.labelSurcharge });
        const transTooltipAddNewEquipment = intl.formatMessage({ ...messages.tooltipAddNewEquipment });
        const transTooltipSaveChanges = intl.formatMessage({ ...messages.tooltipSaveChanges });
        const transTooltipToReturn = intl.formatMessage({ ...messages.tooltipToReturn });
        const transTooltipDiscard = intl.formatMessage({ ...messages.tooltipDiscard });
        const transTooltipClose = intl.formatMessage({ ...messages.tooltipClose });
        const transTooltipUpdate = intl.formatMessage({ ...messages.tooltipUpdate });
        const transTooltipDelete = intl.formatMessage({ ...messages.tooltipDelete });
        const transConfirmDeleteTitle = intl.formatMessage({ ...messages.confirmDeleteTitle });
        const transConfirmDeleteMessage = intl.formatMessage({ ...messages.confirmDeleteMessage });
        const transConfirmClose = intl.formatMessage({ ...messages.confirmClose });
        const transConfirmDelete = intl.formatMessage({ ...messages.confirmDelete });

        return (
            <Box className={classes.dialogBox}>
                {equipmentLoading && (
                    <Box className={classes.skeletonBox}>
                        <CircularProgress size={40} className={classes.circularProgress} />
                    </Box>
                )}

                <AutoSizer>
                    {({ width, height }) => (
                        <MuiVirtualizedTable
                            data={updateEquipmentCatalogue}
                            columns={[
                                {
                                    name: "checkbox",
                                    header: (
                                        <Checkbox
                                            size="small"
                                            checked={selectedEquipmentIds.length > 0}
                                            onChange={() =>
                                                handleSelectedEquipmentIds(
                                                    selectedEquipmentIds?.length === equipmentCatalogue?.length
                                                        ? []
                                                        : updateEquipmentCatalogue?.map((equipment) => equipment?.equipmentID)
                                                )
                                            }
                                            {...(selectedEquipmentIds.length > 0 &&
                                                selectedEquipmentIds.length !== updateEquipmentCatalogue?.length && {
                                                    indeterminate: true,
                                                    color: "default",
                                                })}
                                        />
                                    ),
                                    cell: (row) => (
                                        <Checkbox
                                            size="small"
                                            checked={selectedEquipmentIds?.some((id) => row?.equipmentID === id)}
                                            tabIndex={row?.equipmentID}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "itemNumber",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "itemNumber"}
                                            direction={orderBy === "itemNumber" ? order : Order.ASC}
                                            onClick={handleRequestSort("itemNumber")}
                                        >
                                            {transLabelItemNumber}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="itemNumber"
                                                    value={row?.itemNumber}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.equipmentID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddEquipment(row)
                                                                : handleUpdateEquipment(row?.equipmentID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.itemNumber
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "subMenu",
                                    header: "",
                                    cell: (row) => (
                                        <RowSubMenu
                                            isAddMode={row?.isAddMode as boolean}
                                            isUpdateMode={row?.isUpdateMode as boolean}
                                            disabledRevert={!previous[row?.equipmentID]}
                                            row={row}
                                            rowID={row?.equipmentID}
                                            handleAdd={handleAddEquipment}
                                            handleUpdate={handleUpdateEquipment}
                                            handleRevert={handleRevertEquipment}
                                            handleDiscardAdd={handleDiscardAddEquipment}
                                            handleToggleUpdateMode={handleToggleUpdateMode}
                                            handleDelete={handleDeleteEquipment}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "productID",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "productID"}
                                            direction={orderBy === "productID" ? order : Order.ASC}
                                            onClick={handleRequestSort("productID")}
                                        >
                                            {transLabelProductId}
                                        </TableSortLabel>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="productID"
                                                    value={row?.productID}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.equipmentID}
                                                    autoFocus={row?.isAddMode}
                                                    ref={row?.isAddMode ? addModeRef : null}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddEquipment(row)
                                                                : handleUpdateEquipment(row?.equipmentID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.productID
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "productName",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "productName"}
                                            direction={orderBy === "productName" ? order : Order.ASC}
                                            onClick={handleRequestSort("productName")}
                                        >
                                            {transLabelProductName}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 500 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="productName"
                                                    value={row?.productName}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.equipmentID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddEquipment(row)
                                                                : handleUpdateEquipment(row?.equipmentID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.productName
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "liPrice",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "liPrice"}
                                            direction={orderBy === "liPrice" ? order : Order.ASC}
                                            onClick={handleRequestSort("liPrice")}
                                        >
                                            {transLabelListPrice}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="liPrice"
                                                    value={row?.liPrice}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.equipmentID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddEquipment(row)
                                                                : handleUpdateEquipment(row?.equipmentID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.liPrice && formatNumber(row?.liPrice)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "ep",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "ep"}
                                            direction={orderBy === "ep" ? order : Order.ASC}
                                            onClick={handleRequestSort("ep")}
                                        >
                                            EP
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="ep"
                                                    value={row?.ep}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.equipmentID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddEquipment(row)
                                                                : handleUpdateEquipment(row?.equipmentID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.ep && formatNumber(row?.ep)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "surcharge",
                                    header: (
                                        <TableSortLabel
                                            active={orderBy === "surcharge"}
                                            direction={orderBy === "surcharge" ? order : Order.ASC}
                                            onClick={handleRequestSort("surcharge")}
                                        >
                                            {transLabelSurcharge}
                                        </TableSortLabel>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="surcharge"
                                                    value={row?.surcharge}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.equipmentID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddEquipment(row)
                                                                : handleUpdateEquipment(row?.equipmentID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.surcharge && `${formatNumber(row?.surcharge)} %`
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "update",
                                    header: "",
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <>
                                                    <NullableTooltip
                                                        title={
                                                            row?.isAddMode
                                                                ? transTooltipAddNewEquipment // "Add new equipment"
                                                                : transTooltipSaveChanges // "Save changes"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="save"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                row?.isAddMode
                                                                    ? handleAddEquipment(row)
                                                                    : handleUpdateEquipment(row?.equipmentID);
                                                            }}
                                                            className={clsx(classes.iconButton, classes.iconButtonSave)}
                                                        >
                                                            <Icon name="check" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                    {!row?.isAddMode && (
                                                        <NullableTooltip title={transTooltipToReturn} placement="top">
                                                            {/*Revert*/}
                                                            <IconButton
                                                                size="small"
                                                                aria-label="revert"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleRevertEquipment(row?.equipmentID);
                                                                }}
                                                                className={clsx(
                                                                    classes.iconButton,
                                                                    classes.iconButtonRevert,
                                                                    {
                                                                        [classes.disabled]: !previous[row?.equipmentID],
                                                                    }
                                                                )}
                                                                disabled={!previous[row?.equipmentID]}
                                                            >
                                                                <Icon name="history" size={18} />
                                                            </IconButton>
                                                        </NullableTooltip>
                                                    )}
                                                    <NullableTooltip
                                                        title={
                                                            row?.isAddMode
                                                                ? transTooltipDiscard // "Discard"
                                                                : transTooltipClose // "Close"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="close"
                                                            onClick={(event) => {
                                                                event.stopPropagation();

                                                                row?.isAddMode
                                                                    ? handleDiscardAddEquipment(row?.equipmentID)
                                                                    : handleToggleUpdateMode(row?.equipmentID);
                                                            }}
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.iconButtonClose
                                                            )}
                                                        >
                                                            <Icon name="close" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                </>
                                            ) : (
                                                <NullableTooltip title={transTooltipUpdate} placement="top">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="update"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleToggleUpdateMode(row?.equipmentID);
                                                        }}
                                                        className={classes.iconButton}
                                                    >
                                                        <Icon name="pencil-solid" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </>
                                    ),
                                    width: 100,
                                },
                                {
                                    name: "delete",
                                    header: "",
                                    cell: (row) => (
                                        <>
                                            {!row?.isAddMode && (
                                                <NullableTooltip title={transTooltipDelete} placement="top">
                                                    {/*Delete*/}
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleDeleteEquipment(row);
                                                        }}
                                                        className={clsx(classes.iconButton, classes.iconButtonClose)}
                                                    >
                                                        <Icon name="trash-alt" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </>
                                    ),
                                    width: 50,
                                },
                            ]}
                            width={width}
                            maxHeight={height}
                            includeHeaders={true}
                            fixedRowCount={1}
                            fixedColumnCount={3}
                            rowHeight={defaultProps.rowHeight}
                            isCellSelected={(column, row) => selectedEquipmentIds?.some((id) => row && row?.equipmentID === id)}
                            onCellClick={(event, { column, rowData, data }) => {
                                const equipmentID = rowData?.equipmentID as number;
                                if (equipmentID) {
                                    handleSelectedEquipmentIds(
                                        selectedEquipmentIds?.some((id) => equipmentID === id)
                                            ? selectedEquipmentIds?.filter((id) => id !== equipmentID)
                                            : [...selectedEquipmentIds, equipmentID]
                                    );
                                }
                            }}
                            orderBy={orderBy}
                            orderDirection={order}
                            classes={{
                                table: classes.mvtTable,
                                cellHeader: classes.mvtCellHeader,
                                cellContents: classes.mvtCellContents,
                                cellSelected: classes.mvtCellSelected,
                            }}
                        />
                    )}
                </AutoSizer>
                <ConfirmDialog
                    open={confirmOpen}
                    handleClose={handleConfirmClose}
                    handleDelete={handleConfirmDeleteEquipment}
                    messages={{
                        dialogTitle: transConfirmDeleteTitle, // "Delete confirm"
                        confirmMessage: (
                            <>
                                {transConfirmDeleteMessage}
                                <strong>
                                    {confirmDelete?.productID} - {confirmDelete?.productName}
                                </strong>
                                ?
                            </>
                        ),
                        closeButton: transConfirmClose, // Close
                        deleteButton: transConfirmDelete, // Delete
                    }}
                />
            </Box>
        );
    }
);

export default EquipmentDialog;
