import { defineMessages } from "react-intl";

export default defineMessages({
    synchronizeVersions: {
        id: "pages.projectSettingsPage.synchronizeVersions",
        defaultMessage: "Synchronize versions",
    },
    buttonSynchronize: {
        id: "pages.projectSettingsPage.buttonSynchronize",
        defaultMessage: "Synchronize",
    },
    extractInstancesFromModel: {
        id: "pages.projectSettingsPage.extractInstancesFromModel",
        defaultMessage: "Extract instances from model",
    },
    buttonExtract: {
        id: "pages.projectSettingsPage.buttonExtract",
        defaultMessage: "Extract",
    },
    exportInstancesFromModel: {
        id: "pages.projectSettingsPage.exportInstancesFromModel",
        defaultMessage: "Export instances from model"
    },
    buttonExportInstances: {
        id: "pages.projectSettingsPage.buttonExportInstances",
        defaultMessage: "Export instances",
    },
    messageSynchronizationIsBeingProceed: {
        id: "pages.projectSettingsPage.messageSynchronizationIsBeingProceed",
        defaultMessage: "Synchronization is being proceed",
    },
    messageSynchronizationIsDone: {
        id: "pages.projectSettingsPage.messageSynchronizationIsDone",
        defaultMessage: "Synchronization is done.",
    },
    messageExtractingIsBeingProceed: {
        id: "pages.projectSettingsPage.messageExtractingIsBeingProceed",
        defaultMessage: "Extracting is being proceed.",
    },
    messageExtractingIsDone: {
        id: "pages.projectSettingsPage.messageExtractingIsDone",
        defaultMessage: "Extracting is done.",
    },
});
