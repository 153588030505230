/**
 * Custom theme forked form MUI default theme
 * https://material-ui.com/customization/default-theme/
 */

import "./fonts.css";
import { darken } from "@material-ui/core"; // load custom @font-face
import createMuiTheme from "@material-ui/core/styles/createTheme";

export const fontFamily = {
    primary: "Heebo",
    secondary: "Heebo",
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#454645",
            50: "#FAFBFA",
            100: "#F0F1F0",
            200: "#F0F1F0",
            300: "#E3E4E3",
            400: "#C0C1C0",
            500: "#A2A3A2",
            600: "#787978",
            700: "#646564",
            800: "#454645",
            900: "#242524",
        },
        primaryVariant: {
            main: "#D3DEDA",
            50: "#ECF2F1",
            100: "#D3DEDA",
            200: "#B7C8C3",
            300: "#99B1AA",
            400: "#839F97",
            500: "#6C8E85",
            600: "#607E75",
            700: "#506962",
            800: "#415550",
            900: "#303F3B",
        },
        secondary: {
            main: "#ED3C12",
            50: "#FDE8E6",
            100: "#FFCABA",
            200: "#FFA78D",
            300: "#FF845F",
            400: "#FF673B",
            500: "#FF4916",
            600: "#FC4312",
            700: "#ED3C12",
            800: "#DF3312",
            900: "#C72212",
        },
        secondaryVariant: {
            main: "#EEA895",
            50: "#F6E8E7",
            100: "#F4CABE",
            200: "#EEA895",
            300: "#EB866A",
            400: "#EB6C49",
            500: "#EA512A",
            600: "#DF4C26",
            700: "#D24521",
            800: "#C43F1D",
            900: "#AB3215",
        },
        success: {
            main: "#4ABA99",
            light: "#E7F7F2",
            dark: "#48C29D",
            // contrastText: "",
        },
        warning: {
            main: "#F0B654",
            // light: "",
            // dark: "",
            // contrastText: "",
        },
        error: {
            main: "#F0756C",
            // light: "",
            dark: "#F24236",
            // contrastText: "",
        },
        text: {
            primary: "#000",
            secondary: "#DCDCDC",
            disabled: "rgba(0, 0, 0, 0.26)",
            // hint: "",
        },
        grey: {
            50: "#F9F9F9",
            100: "#F3F3F3",
            200: "#bbbbbb",
            // 300: "",
            // 400: "",
            500: "#747474",
            // 600: "",
            // 700: "",
            // 800: "",
            // 900: "",
            // A100: "",
            // A200: "",
            // A400: "",
            // A700: "",
        },
        common: {
            white: "#fff",
            black: "#000",
        },
        background: {
            // paper: "",
            default: "#fff",
        },
        action: {
            // active: "",
            // hover: "",
            // hoverOpacity: number,
            // selected: "",
            // selectedOpacity: number,
            // disabled: "",
            // disabledBackground: "",
            // disabledOpacity: number,
            // focus: "",
            // focusOpacity: number,
            // activatedOpacity: number,
        },
        type: "light", // theme style switching
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            `${fontFamily.primary}`,
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    shape: {
        borderRadius: 6,
    },
    spacing: (factor) => `${0.6 * factor}rem`, // bootstrap strategy
    props: {
        MuiButton: {
            size: "small",
        },
        MuiFilledInput: {
            margin: "dense",
        },
        MuiFormControl: {
            margin: "dense",
        },
        MuiFormHelperText: {
            margin: "dense",
        },
        MuiIconButton: {
            size: "small",
        },
        MuiInputBase: {
            margin: "dense",
        },
        MuiInputLabel: {
            margin: "dense",
        },
        MuiListItem: {
            dense: true,
        },
        MuiOutlinedInput: {
            margin: "dense",
        },
        MuiFab: {
            size: "small",
        },
        MuiTable: {
            size: "small",
        },
        MuiTextField: {
            margin: "dense",
        },
        MuiToolbar: {
            variant: "dense",
        },
    },
});

theme.typography.h1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "4.166rem", // 50 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {
        fontSize: "3rem",
    },
};

theme.typography.h2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1.5rem", // 18 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
    },
};

theme.typography.h3 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1.166rem", // 14 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
    },
};

theme.typography.h4 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "1.166rem", // 14 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.h5 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "1.166rem", // 14 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.h6 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "1.166rem", // 14 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.subtitle1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.subtitle2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.body1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "1.166rem", // 14 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.body2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "0.916rem", // 11 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.button = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "0.916rem", // 11 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.caption = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "0.75rem", // 9 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.overline = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "0.916rem", // 11 pt
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.mixins.toolbar = {
    minHeight: 86,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 76,
    },
    [theme.breakpoints.up("sm")]: {
        minHeight: 96,
    },
};

theme.overrides = {
    MuiCssBaseline: {
        "@global": {
            "*": {
                "scrollbar-width": "thin",
            },
            "*::-webkit-scrollbar": {
                width: "5px !important",
                height: "5px !important",
                backgroundColor: "transparent",
            },
            "*::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "none !important",
                backgroundColor: "transparent",
                borderRadius: theme.shape.borderRadius * 2,
            },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey["500"],
                borderRadius: theme.shape.borderRadius * 2,
            },
            body: {
                marginTop: "env(safe-area-inset-top) !important",
                marginBottom: "env(safe-area-inset-bottom) !important",
                // autocomplete
                "& .MuiAutocomplete-popper": {
                    fontSize: theme.typography.pxToRem(12),
                },
                "& .MuiAutocomplete-listbox": {
                    fontSize: theme.typography.pxToRem(12),
                },
                "& .MuiAutocomplete-noOptions": {
                    fontSize: theme.typography.pxToRem(12),
                },
                "& .MuiAutocomplete-groupLabel": {
                    fontSize: theme.typography.pxToRem(12),
                },
                "& .MuiAutocomplete-groupUl": {
                    fontSize: theme.typography.pxToRem(12),
                },
                "& .globalAddButton": {
                    color: theme.palette.common.white,
                    backgroundColor: "transparent",
                    padding: 0,
                    "&:hover": {
                        backgroundColor: darken(theme.palette.grey["100"], 0.1),
                    },
                    "& svg": {
                        fill: theme.palette.success.main,
                    },
                },
                "& .globalRemoveButton": {
                    color: theme.palette.common.white,
                    backgroundColor: "transparent",
                    padding: 0,
                    "&:hover": {
                        backgroundColor: darken(theme.palette.grey["100"], 0.1),
                    },
                    "& svg": {
                        fill: theme.palette.error.main,
                    },
                },
            },
        },
    },
    MuiTableHead: {
        root: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    MuiTableCell: {
        sizeSmall: {
            padding: "6px 12px 6px 0",
        },
    },
    MuiOutlinedInput: {
        root: {
            "& $notchedOutline": {
                color: theme.palette.text.primary,
                borderColor: theme.palette.grey["100"],
            },
            "&:hover $notchedOutline": {
                color: theme.palette.common.black,
                borderColor: theme.palette.grey["100"],
            },
            "&$focused $notchedOutline": {
                color: theme.palette.common.black,
                borderColor: theme.palette.grey["100"],
            },
            "&$disabled $notchedOutline": {
                color: theme.palette.common.black,
                borderColor: theme.palette.grey["100"],
            },
            color: theme.palette.text.primary,
        },
        notchedOutline: {
            color: theme.palette.text.primary,
        },
    },
    MuiDivider: {
        root: {
            backgroundColor: theme.palette.grey["100"],
        },
    },
    MuiDialog: {
        paper: {
            borderRadius: theme.shape.borderRadius*2,
        },
    },
    MuiCheckbox: {
        // override color for secondary color checkbox
        colorSecondary: {
            color: `${theme.palette.text.secondary} !important`,
            "&$checked": {
                color: `${theme.palette.success.dark} !important`,
            },
        },
    },
    MuiChip: {
        colorPrimary: {
            backgroundColor: `${theme.palette.success.main} !important`,
        }
    },
    // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Button/Button.js
    MuiButton: {
        textSecondary: {
            color:`${theme.palette.success.main} !important`,
        },
        containedSecondary: {
            "&.Mui-disabled": {
                opacity: 0.5,
            },
            "&.Mui-disabled:hover": {
                color: `${theme.palette.primary.main} !important`,
                opacity: 0.6,
            },
            fontWeight: 400,
            color: `${theme.palette.common.white} !important`,
            backgroundColor: `${theme.palette.success.main} !important`,
            "&:hover": {
                backgroundColor: `${theme.palette.success.dark} !important`,
            },
            "&:focus": {
                backgroundColor: `${theme.palette.success.dark} !important`,
            },
        },
        outlinedSizeSmall: {
            minWidth: "100px",
            fontSize: theme.typography.pxToRem(10),
        },
        containedSizeSmall: {
            minWidth: "100px",
            fontSize: theme.typography.pxToRem(10),
        },
        outlinedSizeLarge: {
            padding: "6px 22px",
            minWidth: "100px",
            fontSize: theme.typography.pxToRem(14),
        },
        containedSizeLarge: {
            padding: "6px 22px",
            minWidth: "100px",
            fontSize: theme.typography.pxToRem(14),
        },
        contained: {
            boxShadow: "none",
            "&:hover, &:focus": {
                boxShadow: "none",
            },
        },
    },
    MuiButtonGroup: {
        contained: {
            boxShadow: "none",
        },
    },
    MuiTableSortLabel: {
        root: {
            "&:hover": {
                color: theme.palette.common.black,
            },
        },
        active: {
            color: theme.palette.common.black,
        },
        icon: {
            color: `${theme.palette.success.main} !important`,
        },
    },
};

export default theme;
