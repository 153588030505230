import { DispatchAction } from "@iolabs/redux-utils";
import { Box, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import { TextField } from "mui-rff";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork,
    IDerivedPosition,
    IElementsPosition,
    IManualPosition,
    IPositionFormConfiguration,
    PositionPriceSource,
    PositionType,
} from "../../../packages/Api/data/elements/types";
import {
    onPositionFormConfiguration,
    onUpdatePositionBasics,
    useDetailPosition,
    useMappingAttachedEquipment,
    useMappingAttachedMaterialAdditional,
    useMappingAttachedMaterialPhysical,
    useMappingAttachedWork,
    useMappingAttributeFormValues,
    useMappingAttributeLoading,
    useMappingBasicPositions,
    useMappingBasicPositionsLoading,
    useMappingDerivedPositions,
    useMappingDerivedPositionsLoading,
    useMappingEquipmentLoading,
    useMappingManualPosition,
    useMappingManualPositionLoading,
    useMappingMaterialLoading,
    useMappingPositionFormConfiguration,
    useMappingPositionFormConfigurationLoading,
    useMappingQuery,
    useMappingQueryLoading,
    useMappingUnitPrice,
    useMappingUnitPriceLoading,
    useMappingWorkLoading,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { formatNumber, normalizeUnit } from "../../../utils/Formatter";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import MappingDetailSummaryValue from "../MappingDetailSummaryValue/MappingDetailSummaryValue";
import { IStepperAttributeFormValues } from "../StepAttribute/type";
import { IQuery } from "../ElementQueryBuilder/type";
import messages from "./messages";

import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import MappingDetailSummaryConditionValue from "../MappingDetailSummaryConditionValue/MappingDetailSummaryConditionValue";

interface IPositionFormConfigurationFormValues {
    attributeTypeID: number;
}

interface IMappingDetailSummaryProps {}

const MappingDetailSummary: React.FC<IMappingDetailSummaryProps> = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    // query
    const queryLoading: boolean = useMappingQueryLoading();
    const query: IQuery | undefined = useMappingQuery();

    // derived positions
    const derivedPositionsLoading: boolean = useMappingDerivedPositionsLoading();
    const derivedPositions: IDerivedPosition[] = useMappingDerivedPositions();
    
    // basic positions
    const basicPositionsLoading: boolean = useMappingBasicPositionsLoading();
    const basicPositions: IDerivedPosition[] = useMappingBasicPositions();

    // attribute
    const attributeLoading: boolean = useMappingAttributeLoading();
    const attributeFormValues: IStepperAttributeFormValues | undefined = useMappingAttributeFormValues();

    // material
    const materialLoading: boolean = useMappingMaterialLoading();
    const attachedMaterialPhysical: IAttachedMaterial[] = useMappingAttachedMaterialPhysical();
    const attachedMaterialAdditional: IAttachedMaterial[] = useMappingAttachedMaterialAdditional();

    // work
    const workLoading: boolean = useMappingWorkLoading();
    const attachedWorks: IAttachedWork[] = useMappingAttachedWork();
    
    // equipment
    const equipmentLoading: boolean = useMappingEquipmentLoading();
    const attachedEquipment: IAttachedEquipment[] = useMappingAttachedEquipment();

    // unit price
    const unitPriceLoading: boolean = useMappingUnitPriceLoading();
    const unitPrice: number = useMappingUnitPrice();

    // manual position
    const manualPositionLoading: boolean = useMappingManualPositionLoading();
    const manualPosition: IManualPosition | undefined = useMappingManualPosition();

    // position form configuration
    const positionFormConfigurationLoading: boolean = useMappingPositionFormConfigurationLoading();
    const positionFormConfiguration: IPositionFormConfiguration | undefined = useMappingPositionFormConfiguration();

    const [
        initialPositionFormConfigurationFormValues,
        setInitialPositionFormConfigurationFormValues,
    ] = useState<IPositionFormConfigurationFormValues | null>(null);

    const onSubmitPositionFormConfiguration = useCallback(async (values: IPositionFormConfigurationFormValues) => {
        console.log("values");
        console.log(values);
    }, []);

    const onChangeUnit = (attributeTypeID: number) => {
        dispatch(
            onUpdatePositionBasics({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
                data: {
                    referenceUnitID: attributeTypeID,
                },
            })
        );
    };

    // translations
    const intl = useIntl();
    const transPlaceholder = intl.formatMessage({ ...messages.placeholder });

    const renderQuery = (query: IQuery, level: number): any => (
        <>
            {query?.rules?.length === 0 && level === 0 && <span className={classes.empty}>Leer</span>}
            {query?.rules?.map((rule, index) => {
                if ("combinator" in rule) {
                    return renderQuery(rule as IQuery, level + 1);
                } else {
                    return (
                        <div key={index} style={{ marginLeft: `${level}rem` }}>
                            {level === 0 && index === 0 ? null : (
                                <div>
                                    <span className={classes.queryComparator}>{query?.combinator?.toUpperCase()}</span>
                                </div>
                            )}
                            <MappingDetailSummaryConditionValue pset={rule?.field?.pset} attribute={rule?.field?.attribute} operator={rule?.operator} value={rule?.value} />
                        </div>
                    );
                }
            })}
        </>
    );

    const renderPopoverTable = (rows: [string, string|number|undefined][]) => {
        if(rows.length > 0) return (
            <Box>
                <Table className={classes.table}>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={`row-${index}`}>
                                <TableCell><Box className={classes.stronger}>{(row[0] ? row[0] : '-')}</Box></TableCell>
                                <TableCell>{(row[1] ? row[1] : '-')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        )
    }

    const renderAttributes = (attribute: IStepperAttributeFormValues): any => (
        <>
            <Box>
                <Typography variant="body2" className={classes.elementDescKey}>
                    Reference unit:
                </Typography>
                {attribute?.reference ? (
                    <MappingDetailSummaryValue
                        value={`${attribute?.reference.propertySet} ["${attribute?.reference.attribute}"]`}
                    >
                        {renderPopoverTable([
                            ['Funktion', attribute?.reference.function],  // todo translate de
                            ['Pset', attribute?.reference.propertySet],  // todo translate de
                            ['Attribute', attribute?.reference.attribute],  // todo translate de
                            ['Parameter', attribute?.reference.parameter]  // todo translate de
                        ])}
                    </MappingDetailSummaryValue>
                ) : (
                    <span className={classes.empty}>Leer</span>
                )}
            </Box>
            <Box mt={1}>
                <Typography variant="body2" className={classes.elementDescKey}>
                    Level:
                </Typography>
                {attribute?.level ? (
                    <MappingDetailSummaryValue
                        value={`${attribute?.level.propertySet} ["${attribute?.level.attribute}"]`}
                    >
                        {renderPopoverTable([
                            ['Funktion', attribute?.level.function],  // todo translate de
                            ['Pset', attribute?.level.propertySet],  // todo translate de
                            ['Attribute', attribute?.level.attribute],  // todo translate de
                            ['Parameter', attribute?.level.parameter]  // todo translate de
                        ])}
                    </MappingDetailSummaryValue>
                ) : (
                    <span className={classes.empty}>Leer</span>
                )}
            </Box>
            <Box mt={1}>
                {attribute?.additionalAttributes?.length > 0 && (
                    <>
                        <Box mb={1}>
                            <Typography variant="body2" className={clsx(classes.elementDescKey, classes.stronger)}>
                                Element attributes:
                            </Typography>
                        </Box>
                        {attribute?.additionalAttributes?.map((maa, index) => (
                            <Box key={`additionalAttributes-${index}`}>
                                <Typography variant="body2" className={classes.elementDescKey}>
                                    {maa?.name}:
                                </Typography>
                                <MappingDetailSummaryValue
                                    key={index}
                                    value={`${maa?.propertySet} ["${maa?.attribute}"]`}
                                >
                                    {renderPopoverTable([
                                        ['Name', maa?.name],  // todo translate de
                                        ['Pset', maa?.propertySet],  // todo translate de
                                        ['Attribute', maa?.attribute],  // todo translate de
                                        ['Data type', maa?.dataType],  // todo translate de
                                        ['Funktion', maa?.function],  // todo translate de
                                        ['Parameter', maa?.parameter],  // todo translate de
                                    ])}
                                </MappingDetailSummaryValue>
                            </Box>
                        ))}
                    </>
                )}
            </Box>
        </>
    );

    const renderDerivedPositions = (positions: IDerivedPosition[]): any => (
        <Box mb={1}>
            {positions?.map((dp, index) => (
                <MappingDetailSummaryValue
                    key={index}
                    value={`${dp?.position?.code}`}
                >
                    {renderPopoverTable([
                        ['eBKP-H', dp?.position?.code],  // todo translate de
                        ['Name', dp?.position?.name],  // todo translate de
                        ['Beschreibung', dp?.position?.description && dp?.position?.description]  // todo translate de
                    ])}
                </MappingDetailSummaryValue>
            ))}
        </Box>
    );

    const renderAttachedMaterials = (attachedMaterials: IAttachedMaterial[]): any => (
        <Box>
            {attachedMaterials?.map((am, index) => (
                <MappingDetailSummaryValue
                    key={index}
                    value={`${am?.material?.itemNumber}`}
                >
                    {renderPopoverTable([
                        ['Artikel Nr. o. G TIN', am?.material?.itemNumber],  // todo translate de
                        ['Produkt-ID', am?.material?.productID],  // todo translate de
                        ['K-Menge', am?.kMenge],  // todo translate de
                        ['Li.-Preis', am?.material?.liPrice && formatNumber(am?.material?.liPrice)],  // todo translate de
                        ['EP', am?.material?.ep && formatNumber(am?.material?.ep)],  // todo translate de
                        ['Zuschlag', am?.material?.surcharge && `${formatNumber(am?.material?.surcharge)} %`],  // todo translate de // Surcharge
                        ['EP/Menge', am?.epPerMenge && formatNumber(am?.epPerMenge)],  // todo translate de
                    ])}

                </MappingDetailSummaryValue>
            ))}
        </Box>
    );

    const renderAttachedWorks = (attachedWorks: IAttachedWork[]): any => (
        <Box>
            {attachedWorks?.map((aw, index) => (
                <MappingDetailSummaryValue
                    key={index}
                    value={`${aw?.work?.productName}`}
                >
                    {renderPopoverTable([
                        ['Artikel Nr. o. G TIN', aw?.work?.itemNumber],  // todo translate de
                        ['Produkt-ID', aw?.work?.productID],  // todo translate de
                        ['Name', aw?.work?.productName],  // todo translate de
                        ['K.-Menge', aw?.kMenge && formatNumber(aw?.kMenge)],  // todo translate de
                        ['Li.-Preis', aw?.work?.liPrice && formatNumber(aw?.work?.liPrice)],  // todo translate de
                        ['EP', aw?.work?.ep && formatNumber(aw?.work?.ep)],  // todo translate de
                        ['Zuschlag', aw?.work?.surcharge && `${formatNumber(aw?.work?.surcharge)} %`],  // todo translate de
                        ['EP/Menge', aw?.epPerMenge && formatNumber(aw?.epPerMenge)],  // todo translate de
                    ])}
                </MappingDetailSummaryValue>
            ))}
        </Box>
    );
    const renderAttachedEquipment = (attachedEquipment: IAttachedEquipment[]): any => (
        <Box>
            {attachedEquipment?.map((aw, index) => (
                <MappingDetailSummaryValue
                    key={index}
                    value={`${aw?.equipment?.productName}`}
                >
                    <Box>{aw?.equipment?.itemNumber} ["{aw?.equipment?.productID}"]</Box>
                    <Box>{aw?.equipment?.productName ? `- ${aw?.equipment?.productName}` : ""}</Box>
                    <Box>{aw?.kMenge ? `- ${formatNumber(aw?.kMenge)}` : ""}</Box>
                    <Box>{aw?.equipment?.liPrice ? `- ${formatNumber(aw?.equipment?.liPrice)}` : ""}</Box>
                    <Box>{aw?.equipment?.ep ? `- ${formatNumber(aw?.equipment?.ep)}` : ""}</Box>
                    <Box>{aw?.equipment?.surcharge ? `- ${formatNumber(aw?.equipment?.surcharge)} %` : ""}</Box>
                    <Box>{aw?.epPerMenge ? `- ${formatNumber(aw?.epPerMenge)}` : ""}</Box>
                </MappingDetailSummaryValue>
            ))}
        </Box>
    );

    const renderPositionFormConfiguration = (): any => {
        return (
            <Form
                onSubmit={onSubmitPositionFormConfiguration}
                initialValues={initialPositionFormConfigurationFormValues}
            >
                {({ values }) => (
                    <form>
                        <TextField
                            required={false}
                            name="attributeTypeID"
                            placeholder={transPlaceholder}
                            fullWidth={false}
                            margin="normal"
                            variant="outlined"
                            color="primary"
                            className={clsx(classes.textField, {
                                [classes.placeholder]: !values?.["attributeTypeID"],
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            SelectProps={{
                                displayEmpty: true,
                            }}
                        >
                            <MenuItem value="" disabled>
                                {transPlaceholder}
                            </MenuItem>
                            {positionFormConfiguration?.allowedReferenceUnits?.map((aru, index) => (
                                <MenuItem key={index} value={aru?.attributeTypeID}>
                                    {normalizeUnit(aru?.unit)}
                                </MenuItem>
                            ))}
                        </TextField>
                        <OnChange name="attributeTypeID">{(value) => onChangeUnit(value)}</OnChange>
                    </form>
                )}
            </Form>
        );
    };

    useEffect(() => {
        setInitialPositionFormConfigurationFormValues({
            attributeTypeID: detailPosition?.referenceUnitID as number,
        });
    }, [positionFormConfiguration]);

    useEffect(() => {
        dispatch(
            onPositionFormConfiguration({
                token: keycloak?.token as string,
            })
        );
    }, [detailPosition]);

    return (
        <Box>
            <Box className={classes.elementDescList}>
                <Typography variant="body2" className={clsx(classes.elementDescKey, classes.stronger)}>
                    Element:
                </Typography>
                <Typography variant="body2" className={clsx(classes.elementDescValue, classes.stronger)}>
                    {detailPosition?.code}
                </Typography>
            </Box>
            <Box className={classes.elementDescList}>
                <NullableTooltip title={detailPosition?.name as string} placement="top">
                    <Typography variant="body2" className={clsx(classes.elementNameValue)}>
                        {detailPosition?.name}
                    </Typography>
                </NullableTooltip>
            </Box>
            <Box className={classes.elementDescList}>
                <Typography variant="body2" className={classes.elementDescKey}>
                    Kosten pro Einheit:
                </Typography>
                <Typography variant="body2" className={classes.elementDescValue}>
                    {unitPriceLoading || manualPositionLoading ? (
                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                    ) : (
                        <>{formatNumber(unitPrice)} €</>
                    )}
                </Typography>
            </Box>
            <Box className={classes.elementDescList}>
                <Typography variant="body2" className={classes.elementDescKey}>
                    Referenzeinheit:
                </Typography>
                {detailPosition?.type == PositionType.MANUAL ? (
                    <Typography variant="body2" className={classes.elementDescValue}>
                        €
                    </Typography>
                ) : (
                    <>
                        {positionFormConfigurationLoading ? (
                            <Skeleton variant="rect" height={27} className={classes.skeleton} />
                        ) : (
                            <>{renderPositionFormConfiguration()}</>
                        )}
                    </>
                )}
            </Box>
            <Box className={classes.elementDescList} mt={4}>
                <Typography variant="body2" className={clsx(classes.elementDescTerm, classes.stronger)}>
                    Definition:
                </Typography>
            </Box>
            {detailPosition?.type == PositionType.MANUAL && (
                <>
                    <Typography variant="body2" className={classes.elementDescTerm}>
                        Manuelle Position:
                    </Typography>
                    {manualPositionLoading ? (
                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                    ) : (
                        <>
                            {manualPosition?.value ? (
                                <MappingDetailSummaryValue value={`${formatNumber(manualPosition?.value)} €`} />
                            ) : (
                                <span className={classes.empty}>Leer</span>
                            )}
                        </>
                    )}
                </>
            )}
            {detailPosition?.type == PositionType.MODEL && (
                <Box mb={1.5}>
                    <Typography variant="body2" className={classes.elementDescTerm}>
                        Model Position:
                    </Typography>
                    {queryLoading ? (
                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                    ) : (
                        <>{query ? renderQuery(query, 0) : <span className={classes.empty}>Leer</span>}</>
                    )}
                </Box>
            )}
            {detailPosition?.type == PositionType.MODEL && (
                <Box mt={0.5}>
                    <Typography variant="body2" className={classes.elementDescTerm}>
                        Abgeleite Positionen:
                    </Typography>
                    {derivedPositionsLoading || !derivedPositions ? (
                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                    ) : (
                        <>
                            {derivedPositions?.length > 0 ? (
                                renderDerivedPositions(derivedPositions)
                            ) : (
                                <span className={classes.empty}>Leer</span>
                            )}
                        </>
                    )}
                </Box>
            )}
            {detailPosition?.type == PositionType.DERIVED && (
                <Box mt={0.5}>
                    <Typography variant="body2" className={classes.elementDescTerm}>
                        Basisbauteile:
                    </Typography>
                    {basicPositionsLoading || !basicPositions ? (
                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                    ) : (
                        <>
                            {basicPositions?.length > 0 ? (
                                renderDerivedPositions(basicPositions)
                            ) : (
                                <span className={classes.empty}>Leer</span>
                            )}
                        </>
                    )}
                </Box>
            )}
            {detailPosition?.type == PositionType.MODEL && (
                <>
                    <Box className={classes.elementDescList} mt={2}>
                        <Typography variant="body2" className={clsx(classes.elementDescTerm, classes.stronger)}>
                            Attribute:
                        </Typography>
                    </Box>

                    <Box>
                        {attributeLoading ? (
                            <Skeleton variant="rect" height={27} className={classes.skeleton} />
                        ) : (
                            <>
                                {attributeFormValues ? (
                                    renderAttributes(attributeFormValues)
                                ) : (
                                    <span className={classes.empty}>Leer</span>
                                )}
                            </>
                        )}
                    </Box>
                </>
            )}

            {detailPosition?.type != PositionType.MANUAL && (
                <>
                    <Box className={classes.elementDescList} mt={2}>
                        <Typography variant="body2" className={clsx(classes.elementDescTerm, classes.stronger)}>
                            Material und Arbeit:
                        </Typography>
                    </Box>
                    <Box>
                        {detailPosition?.priceSource == PositionPriceSource.MODEL ? (
                            <>
                                {materialLoading ? (
                                    <Skeleton variant="rect" height={27} className={classes.skeleton} />
                                ) : (
                                    <>
                                        <Typography variant="body2" className={classes.elementDescTerm}>
                                            Material physisch:
                                        </Typography>
                                        {attachedMaterialPhysical && attachedMaterialPhysical?.length > 0 ? (
                                            renderAttachedMaterials(attachedMaterialPhysical)
                                        ) : (
                                            <span className={classes.empty}>Leer</span>
                                        )}

                                        <Box mt={1}>
                                            <Typography variant="body2" className={classes.elementDescTerm}>
                                                Material zusätzlich:
                                            </Typography>
                                            {attachedMaterialAdditional && attachedMaterialAdditional?.length > 0 ? (
                                                renderAttachedMaterials(attachedMaterialAdditional)
                                            ) : (
                                                <span className={classes.empty}>Leer</span>
                                            )}
                                        </Box>
                                    </>
                                )}
                                <Box mt={1}>
                                    {workLoading ? (
                                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                                    ) : (
                                        <>
                                            <Typography variant="body2" className={classes.elementDescTerm}>
                                                Arbeit:
                                            </Typography>
                                            {attachedWorks && attachedWorks?.length > 0 ? (
                                                renderAttachedWorks(attachedWorks)
                                            ) : (
                                                <span className={classes.empty}>Leer</span>
                                            )}
                                        </>
                                    )}
                                </Box>
                                <Box mt={1}>
                                    {equipmentLoading ? (
                                        <Skeleton variant="rect" height={27} className={classes.skeleton} />
                                    ) : (
                                        <>
                                            <Typography variant="body2" className={classes.elementDescTerm}>
                                                Geräte:
                                            </Typography>
                                            {attachedEquipment && attachedEquipment?.length > 0 ? (
                                                renderAttachedEquipment(attachedEquipment)
                                            ) : (
                                                <span className={classes.empty}>Leer</span>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography variant="body2" className={classes.elementDescTerm}>
                                    Nachunternehmer preis:{/* todo translate de // Subcontractor price */}
                                </Typography>
                                {detailPosition?.price ? (
                                    <MappingDetailSummaryValue value={`${formatNumber(detailPosition?.price?.total as number)} €`} />
                                ) : (
                                    <span className={classes.empty}>Leer</span>
                                )}
                            </>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default MappingDetailSummary;
