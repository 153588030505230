import './styles.css'
import deepmerge from "deepmerge";
import { CustomPropertiesPanel } from "./CustomPropertiesPanel";

export const ExtensionID = "Viewing.Extension.CustomProperties";

export type OnLoadPropertiesCallback = (ids: string, setProps: (any) => void) => void;

let isRegistered = false;

export interface ICustomPropertiesExtensionOptions {
    showUi: boolean;
    onLoadProperties?: OnLoadPropertiesCallback;
}

const DefaultOptions: ICustomPropertiesExtensionOptions = {
    showUi: true,
};


export const register = () => {
    if (isRegistered) return;

    class CustomPropertiesExtension extends Autodesk.Viewing.Extension {

        options: ICustomPropertiesExtensionOptions;
        // @ts-ignore
        private panel?: Autodesk.Viewing.Extension.ViewerPropertyPanel;
        private isOpened: boolean;


        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: ICustomPropertiesExtensionOptions) {
            const opts = options ? deepmerge(DefaultOptions, options) : DefaultOptions;
            super(viewer, opts);
            this.options = opts;
            this.viewer = viewer
            this.isOpened = false;
        }

        onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar): void {
            if (this.options.showUi) {
                const button1 = new Autodesk.Viewing.UI.Button("show-dialog-button");
                button1.onClick = () => this.toggleDialog();
                button1.setIcon(`material-icons`);
                button1.addClass("custom-properties-button");
                button1.setToolTip("Show Zapper properties");

                // SubToolbar
                const subToolbar = new Autodesk.Viewing.UI.ControlGroup(`${ExtensionID}Toolbar`);
                subToolbar.addControl(button1);

                toolbar.addControl(subToolbar);
            }
        }

        toggleDialog() {
            this.isOpened ? this.hideDialog() : this.showDialog();
        }

        hideDialog() {
            // @ts-ignore
            this.panel?.setVisible(false);
            this.isOpened = false;
        }

        showDialog() {

            if (!this.panel) {
                this.panel = new CustomPropertiesPanel(this.viewer, this.options.onLoadProperties);
            }
            // @ts-ignore
            this.panel.setVisible(true);
            this.isOpened = true;
        }



    }


    // register extension - we need to do it here so extension is loaded by Viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, CustomPropertiesExtension);

    isRegistered = true;

}