import deepmerge from "deepmerge";
import { isEqual, intersection } from "lodash";

export const ExtensionID = "Viewing.Extension.BulkIsolation";

let isRegistered = false;

export interface IIsolation {
    viewerIds: number[];
    title: string;
    isActive?: boolean;
}
export enum MultiMode {
    EXCLUSIVE = "exclusive",
    UNION = "union",
    INTERSECTION = "intersection",
}

export interface IBulkIsolationExtensionOptions {
    isolations: { [key: string]: IIsolation };
    showUi: boolean;
    multiMode: MultiMode;
    showIsolatedOnly?: boolean;
}

const DefaultOptions: IBulkIsolationExtensionOptions = {
    isolations: {},
    showUi: true,
    showIsolatedOnly: false,
    multiMode: MultiMode.EXCLUSIVE,
};

export const register = () => {
    if (isRegistered) return;
    class BulkIsolationExtension extends Autodesk.Viewing.Extension {
        private privateGroup?: Autodesk.Viewing.UI.ControlGroup;

        private privateButtons: { [key: string]: Autodesk.Viewing.UI.Button };

        private privateStarted?: boolean;

        private privateIsolations: { [key: string]: IIsolation };

        private privateIsolationIds: number[];

        private privateActiveIsolations: string[];

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: IBulkIsolationExtensionOptions) {
            // @ts-ignore
            const opts = deepmerge(DefaultOptions, options);
            super(viewer, opts);
            this.viewer = viewer;
            this.privateStarted = false;
            this.privateIsolations = opts.isolations;
            this.customize = this.customize.bind(this);
            this.privateIsolationIds = [];
            this.privateButtons = {};
            this.privateActiveIsolations = [];
        }

        setIsolations(isolations) {
            this.privateIsolations = isolations;
            this.privateIsolationIds = [];
            this.privateActiveIsolations = [];
            this.customizeIsolationsUi();
            this.updateToggles();
        }

        load() {
            if (this.viewer.model.getInstanceTree()) {
                this.customize();
            } else {
                this.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.customize);
            }
            this.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, (e) => this.onIsolationChanged(e));
            return true;
        }

        unload() {
            console.log("BulkIsolationExtension is now unloaded!");
            // Clean our UI elements if we added any
            if (this.privateGroup) {
                if (this.privateButtons) {
                    let group = this.privateGroup;
                    Object.values(this.privateButtons).forEach((button) => {
                        group.removeControl(button);
                    });
                }
                if (this.privateGroup.getNumberOfControls() === 0) {
                    this.viewer.toolbar.removeControl(this.privateGroup);
                }
            }
            return true;
        }

        onIsolationChanged(isolationChangedEvent) {
            if (!isEqual(isolationChangedEvent.nodeIdArray.sort(), this.privateIsolationIds.sort())) {
                this.privateIsolationIds = [];
                this.privateActiveIsolations = [];
                this.updateToggles();
            }
        }

        updateToggles() {
            Object.keys(this.privateIsolations)?.forEach((isolationCode) => {
                if (this.privateActiveIsolations.includes(isolationCode)) {
                    this.privateIsolations[isolationCode].isActive = true;
                    this.privateButtons[isolationCode].addClass("active");
                } else {
                    this.privateIsolations[isolationCode].isActive = false;
                    this.privateButtons[isolationCode].removeClass("active");
                }
            });
        }

        toggle(isolationCode) {
            const isolation = this.privateIsolations[isolationCode];
            const activate = !isolation.isActive;

            // button toggles
            if (this.options.multiMode === MultiMode.EXCLUSIVE) {
                this.privateActiveIsolations = [];
            }
            this.privateActiveIsolations = activate
                ? this.privateActiveIsolations.concat(isolationCode)
                : this.privateActiveIsolations.filter((i) => i !== isolationCode);

            this.calculateIdsToIsolate();

            // apply
            this.viewer.isolate(this.privateIsolationIds);
            if (this.options.showIsolatedOnly) {
                this.showIdsOnly(this.privateIsolationIds);
            }
            this.updateToggles();
        }

        calculateIdsToIsolate() {
            let ids: number[] = [];
            let firstPopulated = false;
            for (let isolationCode of Object.keys(this.privateIsolations)) {
                if (this.privateActiveIsolations.includes(isolationCode)) {
                    if (this.options.multiMode === MultiMode.EXCLUSIVE) {
                        ids = this.privateIsolations[isolationCode].viewerIds;
                        break;
                    } else if (this.options.multiMode === MultiMode.UNION) {
                        ids = ids.concat(this.privateIsolations[isolationCode].viewerIds);
                    } else if (this.options.multiMode === MultiMode.INTERSECTION) {
                        if (!firstPopulated) {
                            ids = this.privateIsolations[isolationCode].viewerIds;
                            firstPopulated = true;
                        } else {
                            ids = intersection(ids, this.privateIsolations[isolationCode].viewerIds);
                        }
                    }
                }
            }

            this.privateIsolationIds = ids;
        }

        showIdsOnly(ids: number[]) {
            const objectCount = this.viewer.model.getData().instanceTree.objectCount - 1;
            if (this.viewer.model) {
                for (let i: number = 0; i < objectCount; i++) {
                    this.viewer.impl.visibilityManager.setNodeOff(i, ids.length !== 0 && !(ids.indexOf(i) >= 0));
                }
            }
        }

        customize() {
            // todo live change isolation config
            if (this.viewer.toolbar && this.options.showUi) {
                // group
                const group = new Autodesk.Viewing.UI.ControlGroup("BulkIsolationsToolbar");

                this.viewer.toolbar.addControl(group);

                this.privateGroup = group;
                this.customizeIsolationsUi();
            }
        }

        customizeIsolationsUi() {
            Object.keys(this.privateButtons).forEach((isolationCode) => {
                this.privateGroup?.removeControl(this.privateButtons[isolationCode]);
            });

            // buttons
            const buttons: { [key: string]: Autodesk.Viewing.UI.Button } = {};
            if (this.privateIsolations) {
                Object.keys(this.privateIsolations).forEach((isolationCode) => {
                    const isolationDef = this.privateIsolations[isolationCode];
                    buttons[isolationCode] = new Autodesk.Viewing.UI.Button(`bulkIsolations-${isolationCode}`);
                    buttons[isolationCode].addClass(`bulkIsolations-${isolationCode}`);
                    buttons[isolationCode].setToolTip(isolationDef.title);

                    buttons[isolationCode].onClick = () => {
                        this.toggle(isolationCode);
                    };
                    this.privateGroup?.addControl(buttons[isolationCode]);
                });
            }
            this.privateButtons = buttons;
        }
    }
    // register extension - we need to do it here so extension is loaded by Viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, BulkIsolationExtension);

    isRegistered = true;
};
