import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, Typography, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { uploadFiles } from "../../../packages/Api/data/datamanagement/client";
import { onLoadNode } from "../../../redux/tree";
import Icon from "../../Icon/Icon";
import useStyles from "./styles";
import messages from "./messages";

interface IDropZoneProps {
    nodeId: string;
    handleClose: () => void;
}

const DropZone: React.FC<IDropZoneProps> = ({ nodeId, handleClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transDropZoneMessage = intl.formatMessage({ ...messages.dropZoneMessage });
    const transDropZoneButton = intl.formatMessage({ ...messages.dropZoneButton });
    const transUploadStarted = intl.formatMessage({ ...messages.dropZoneUploadStarted });
    const transUploadReady = intl.formatMessage({ ...messages.dropZoneUploadReady });
    const transUploadError = intl.formatMessage({ ...messages.dropZoneUploadError });

    const onDrop = useCallback(
        (acceptedFiles) => {
            const formData = new FormData();
            acceptedFiles?.map((file) => {
                formData.append("files", file, file.name);
            });

            const notification: INotification = {
                variant: "success",
                message: transUploadStarted,
            };
            dispatch(addNotification({ notification }));

            uploadFiles(nodeId, formData)
                .then((response) => {
                    if (response) {
                        response.map((item: any) => {
                            if (item.status === "ERROR") {
                                const notification: INotification = {
                                    variant: "error",
                                    message: `${item.data.errors[0].detail} (${item.fileName})`,
                                };
                                dispatch(addNotification({ notification }));
                            } else if (item.status === "SUCCESS") {
                                const notification: INotification = {
                                    variant: "success",
                                    message: transUploadReady,
                                };
                                dispatch(addNotification({ notification }));
                            }
                        });

                        // reload tree item by nodeId
                        dispatch(onLoadNode({ nodeId, depth: 1 }));
                    }
                })
                .catch(() => {
                    const notification: INotification = {
                        variant: "error",
                        message: transUploadError,
                    };
                    dispatch(addNotification({ notification }));
                });
            handleClose();
        },
        [dispatch, handleClose, nodeId, transUploadStarted, transUploadError, transUploadReady]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <Box {...getRootProps()} className={classes.root}>
            <input {...getInputProps()} />

            <Box className={classes.dropzone}>
                <Box
                    className={clsx(classes.iconBox, {
                        [classes.dragActive]: isDragActive,
                    })}
                >
                    <Icon name="cloud-upload-alt" size={100} fill={theme.palette.grey[500]} />
                </Box>

                <Typography variant="h3" component="h2" className={classes.message}>
                    {transDropZoneMessage}
                </Typography>
                <Button variant="contained" className={classes.button}>
                    {transDropZoneButton}
                </Button>
            </Box>
        </Box>
    );
};

export default DropZone;
