import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconTable: {
            fontSize: "1.3rem",
            width: "1.3rem",
            height: "1.3rem",
            fill: theme.palette.primary.main,
            marginRight: "0.6rem",
            verticalAlign: "middle",
        },
        iconThumbnail: {
            fontSize: "5rem",
            width: "5rem",
            height: "5rem",
            fill: theme.palette.primary.main,
        },
    })
);

export default useStyles;
