import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Checkbox, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { onIssueViewerChangePath } from "../../../redux/issue";
import { setActiveNodeId, setExpandedNodes, useExpandedNodes } from "../../../redux/tree";
import { onLoadUrn } from "../../../redux/viewer";
import { getParentPathParts } from "../../../utils/Urn";
import DocumentInlineEdit from "../../DocumentView/DocumentInlineEdit/DocumentInlineEdit";
import DocumentIssues from "../../DocumentView/DocumentIssues/DocumentIssues";
import DocumentMarkups from "../../DocumentView/DocumentMarkups/DocumentMarkups";
import DocumentOffline from "../../DocumentView/DocumentOffline/DocumentOffline";
import DocumentSubMenu from "../../DocumentView/DocumentSubMenu/DocumentSubMenu";
import { Data } from "../../DocumentView/DocumentViewWrapper/DocumentViewWrapper";
import { ISubmenuDots } from "../../DocumentView/type";
import Icon from "../../Icon/Icon";
import messages from "./messages";
import Preview from "../Preview/Preview";
import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IThumbnailProps {
    items: Data[];
    selectedNodes: string | undefined;
    loading?: boolean;
    activeProjectId?: string;
    activeFoldersIds: string[];
}

const ThumbnailWrapper: React.FC<IThumbnailProps> = ({
    items,
    selectedNodes,
    loading,
    activeProjectId,
    activeFoldersIds,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const expanded = useExpandedNodes();
    const [selected, setSelected] = useState<string[]>([]);
    const [openInlineEdit, setOpenInlineEdit] = useState<string | null>(null);

    const handleOpenInlineEdit = (item: Data) => {
        setOpenInlineEdit(item.nodeId);
    };

    const handleCloseInlineEdit = () => {
        setOpenInlineEdit(null);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = items.map((item) => item.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleCheckBoxClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleFileClick = (item: Data) => (event: React.MouseEvent<unknown>) => {
        dispatch(
            onLoadUrn({
                urn: item.urn,
                fileId: item.fileId as string,
                fileType: item.fileType as string,
            })
        );
        dispatch(onIssueViewerChangePath({ path: item?.pathIssues }));
    };

    const handleFolderClick = (item: Data) => (event: React.MouseEvent<unknown>) => {
        let expandedNodes = [...expanded];
        if (!expanded.includes(item.nodeId)) {
            if (selectedNodes) {
                expandedNodes = expandedNodes.concat(selectedNodes);
            } else {
                expandedNodes = [item.nodeId, ...expandedNodes];
            }
        }

        const parentPathParts = getParentPathParts(activeProjectId, activeFoldersIds, true);
        dispatch(setExpandedNodes({ expandedNodes: expandedNodes.concat(parentPathParts) }));

        // set active node Id of tree item
        dispatch(setActiveNodeId({ activeNodeId: item.nodeId }));
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // translations
    const intl = useIntl();
    const transEmptyFolder = intl.formatMessage({ ...messages.emptyFolder });

    return (
        <Box className={classes.root}>
            {loading ? (
                <Box className={classes.skeletonBox}>
                    {[...Array(~~(Math.random() * 10))]
                        .map(() => 0)
                        .map((item: number, index: number) => (
                            <Skeleton variant="rect" className={classes.skeleton} key={index} />
                        ))}
                </Box>
            ) : (
                <>
                    {items.length ? (
                        <Box className={classes.itemsBox}>
                            {items.map((item: Data, index: number) => {
                                const isItemSelected = isSelected(item.name);
                                const labelId = `item-checkbox-${index}`;

                                return (
                                    <Box className={classes.itemBox} key={index}>
                                        <Paper className={classes.itemPaper}>
                                            <Box className={classes.itemPaperCheckbox}>
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    size="small"
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    color="secondary"
                                                    onClick={(event) => handleCheckBoxClick(event, item.name)}
                                                />
                                            </Box>
                                            <Box className={classes.itemPaperSubMenu}>
                                                <DocumentSubMenu
                                                    item={item}
                                                    handleOpenInlineEdit={handleOpenInlineEdit}
                                                    dotsType={ISubmenuDots.Horizontal}
                                                />
                                            </Box>
                                            <Box className={classes.itemIcon}>
                                                <Preview
                                                    item={item}
                                                    handleFolderClick={handleFolderClick}
                                                    handleFileClick={handleFileClick}
                                                />
                                            </Box>
                                            <Box className={classes.itemPaperStats}>
                                                <DocumentOffline item={item} />
                                                {!item.isDir && (
                                                    <div className={classes.itemPaperStatsMarkups}>
                                                        <DocumentMarkups item={item} />
                                                    </div>
                                                )}
                                                {!item.isDir && (
                                                    <div className={classes.itemPaperStatsIssues}>
                                                        <DocumentIssues item={item} />
                                                    </div>
                                                )}
                                            </Box>
                                            <Box className={classes.itemPaperVersion}>
                                                {item.version && `V${item.version}`}
                                            </Box>
                                        </Paper>
                                        <Box className={classes.itemName}>
                                            {item.nodeId === openInlineEdit ? (
                                                <DocumentInlineEdit item={item} handleClose={handleCloseInlineEdit} />
                                            ) : (
                                                <NullableTooltip title={item.name}>
                                                    <Typography variant="body2" className={classes.truncate}>
                                                        {item.name}
                                                    </Typography>
                                                </NullableTooltip>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Box className={classes.emptyBox}>
                            <Icon name="folder-open" size={60} />
                            <Typography variant="body2">{transEmptyFolder}</Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default ThumbnailWrapper;
