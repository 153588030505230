import { defineMessages } from "react-intl";

export default defineMessages({
    // pbs
    pbOne: {
        id: "components.positionCode.positionBoxOne",
        defaultMessage: "Position 1",
    },
    pbTwo: {
        id: "components.positionCode.positionBoxTwo",
        defaultMessage: "Position 2",
    },
    pbThree: {
        id: "components.positionCode.positionBoxThree",
        defaultMessage: "Position 3",
    },
    pbFour: {
        id: "components.positionCode.positionBoxFour",
        defaultMessage: "Position 4",
    },
    pbFive: {
        id: "components.positionCode.positionBoxFive",
        defaultMessage: "Position 5",
    },
});
