import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import NewProjectWrapper from "../../components/NewProject/NewProjectWrapper/NewProjectWrapper";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import { exportPositions, extractProjectElements } from "../../packages/Api/data/elements/client";
import { syncProject } from "../../packages/Api/data/projects/client";
import { useActiveProject } from "../../redux/project";
import Page from "../Page/Page";
import useStyles from "./styles";
import { exportInstances } from "../../packages/Api/data/instances/client";
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl";
import messages from "./messages";

const ProjectSettingsPage: React.FC = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const activeProject: ProjectData | undefined = useActiveProject();

    const dispatch = useDispatch<DispatchAction>();

    const [syncLoading, setSyncLoading] = useState<boolean>(false);
    const [syncDone, setSyncDone] = useState<boolean>(false);
    const [syncError, setSyncError] = useState<any>();

    const [extractLoading, setExtractLoading] = useState<boolean>(false);
    const [extractDone, setExtractDone] = useState<boolean>(false);
    const [extractError, setExtractError] = useState<any>();

    const [exporting, setExporting] = useState<boolean>(false);

    const handleSyncProject = async () => {
        if (!activeProject) return;

        setSyncLoading(true);
        setSyncDone(false);
        setSyncError(undefined);
        await syncProject(keycloak?.token as string, activeProject?.projectID)
            .then((response) => {
                console.log(response);
                setSyncDone(true);
            })
            .catch((error) => {
                setSyncError(error);
            })
            .finally(() => {
                setSyncLoading(false);
            });
    };

    const handleExtractProject = async () => {
        if (!activeProject) return;

        setExtractLoading(true);
        setExtractDone(false);
        setExtractError(undefined);
        await extractProjectElements(keycloak?.token as string, activeProject?.projectID)
            .then((response) => {
                console.log(response);
                setExtractDone(true);
            })
            .catch((error) => {
                setExtractError(error);
            })
            .finally(() => {
                setExtractLoading(false);
            });
    };

    const handleExportInstances = async () => {
        if (!activeProject) return;
        setExporting(true);
        dispatch(addNotification({
            notification: {
                variant: "success",
                message: "Export has started",
            }}));
        await exportInstances(keycloak?.token as string, activeProject?.projectID).finally(() => {
            setExporting(false);
        });
    };

    useEffect(() => {
        setSyncLoading(false);
        setSyncDone(false);
        setSyncError(undefined);

        setExtractLoading(false);
        setExtractDone(false);
        setExtractError(undefined);

        return () => {
            setSyncLoading(false);
            setSyncDone(false);
            setSyncError(undefined);

            setExtractLoading(false);
            setExtractDone(false);
            setExtractError(undefined);
        };
    }, [activeProject]);


    // translations
    const intl = useIntl();
    const transSynchronizeVersions = intl.formatMessage({ ...messages.synchronizeVersions });
    const transButtonSynchronize = intl.formatMessage({ ...messages.buttonSynchronize });
    const transExtractInstancesFromModel = intl.formatMessage({ ...messages.extractInstancesFromModel });
    const transButtonExtract = intl.formatMessage({ ...messages.buttonExtract });
    const transExportInstancesFromModel = intl.formatMessage({ ...messages.exportInstancesFromModel });
    const transButtonExportInstances = intl.formatMessage({ ...messages.buttonExportInstances });
    const transMessageSynchronizationIsBeingProceed = intl.formatMessage({ ...messages.messageSynchronizationIsBeingProceed });
    const transMssageSynchronizationIsDone = intl.formatMessage({ ...messages.messageSynchronizationIsDone });
    const transMessageExtractingIsBeingProceed = intl.formatMessage({ ...messages.messageExtractingIsBeingProceed });
    const transMessageExtractingIsDone = intl.formatMessage({ ...messages.messageExtractingIsDone });

    return (
        <Page noOverflow>
            <Box className={classes.box}>
                <NewProjectWrapper editMode />
                {activeProject?.isMapped && (
                    <Box className={classes.boxSync}>
                        <Box className={classes.boxSyncInner}>
                            <Box flexBasis="50%">
                                <Typography variant="body2" style={{ marginBottom: "0.8rem" }}>
                                    {transSynchronizeVersions}
                                </Typography>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    disabled={syncLoading || extractLoading}
                                    onClick={handleSyncProject}
                                >
                                    {transButtonSynchronize}
                                </Button>
                            </Box>
                            <Box flexBasis="50%">
                                <Typography variant="body2" style={{ marginBottom: "0.8rem" }}>
                                    {transExtractInstancesFromModel}
                                </Typography>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={syncLoading || extractLoading}
                                    onClick={handleExtractProject}
                                >
                                    {transButtonExtract}
                                </Button>
                            </Box>

                            <Box flexBasis="50%">
                                <Typography variant="body2" style={{ marginBottom: "0.8rem" }}>
                                    {transExportInstancesFromModel}
                                </Typography>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={syncLoading || exporting}
                                    onClick={handleExportInstances}
                                >
                                    {transButtonExportInstances}
                                </Button>
                            </Box>
                        </Box>

                        <Box className={classes.boxSyncInner} mt={1}>
                            <Box flexBasis="50%">
                                <Box display="flex" flexDirection="row">
                                    {syncLoading && (
                                        <>
                                            <Box mr={1}>
                                                <CircularProgress size={18} className={classes.circularProgress} />
                                            </Box>
                                            <Typography variant="body2">{transMessageSynchronizationIsBeingProceed}</Typography>
                                        </>
                                    )}

                                    <Typography variant="body2" color={syncError ? "secondary" : "inherit"}>
                                        {syncError ? syncError?.message : syncDone && transMssageSynchronizationIsDone}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box flexBasis="50%">
                                <Box display="flex" flexDirection="row">
                                    {extractLoading && (
                                        <>
                                            <Box mr={1}>
                                                <CircularProgress size={18} className={classes.circularProgress} />
                                            </Box>
                                            <Typography variant="body2">{transMessageExtractingIsBeingProceed}</Typography>
                                        </>
                                    )}
                                </Box>
                                <Typography variant="body2" color={extractError ? "secondary" : "inherit"}>
                                    {extractError ? extractError?.message : extractDone && transMessageExtractingIsDone}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Page>
    );
};

export default ProjectSettingsPage;
