import { defineMessages } from "react-intl";

export default defineMessages({
    share: {
        id: "components.header.headerShare.share",
        defaultMessage: "Share",
    },
    copyToClipboard: {
        id: "components.header.headerShare.copyToClipboard",
        defaultMessage: "Copy to clipboard",
    },
    copiedToClipboard: {
        id: "components.header.headerShare.copiedToClipboard",
        defaultMessage: "Link to this page successfully copied to clipboard.",
    },
});
