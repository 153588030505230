import { Box, Link, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import defaultLogo from "../../assets/images/logo-iolabs.png";
import clientLogo from "../../assets/images/logo-sumoo.png";
import config from "../../config/config";
import useStyles, { FooterStylesProps } from "./styles";
import messages from "./messages";
import NullableTooltip from "../NullableTooltip/NullableTooltip";

interface IFooterProps {
    stylesProps?: FooterStylesProps;
}

const Footer: React.FC<IFooterProps> = ({ stylesProps }) => {
    const classes = useStyles(stylesProps);

    const getYear = () => {
        return new Date().getFullYear();
    };

    // translations
    const intl = useIntl();
    const transClient = intl.formatMessage({ ...messages.client });
    const transAuthor = intl.formatMessage({ ...messages.author });
    const transUpdate = intl.formatMessage({ ...messages.update });
    const transCopyright = intl.formatMessage(
        { ...messages.copyright },
        {
            year: getYear(),
        }
    );
    const transDevelopedBy = intl.formatMessage({ ...messages.developedBy });
    const transDevelopedByAnd = intl.formatMessage({ ...messages.developedByAnd });

    const dateLastUpdate = config.build.lastUpdate ? new Date(config.build.lastUpdate) : new Date();

    return (
        <Toolbar className={classes.root}>
            <Box display="flex" justifyContent="space-between" pl={1} pr={1} className={classes.content}>
                <Typography component="div" className={classes.copyLogo}>
                    <Link href={config.content.clientWebUrl} target="_blank" color="inherit" className={clsx(classes.logo, classes.logoClient)}>
                        <img alt="Logo" src={clientLogo} />
                    </Link>
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit" className={classes.logo}>
                        <img alt="Logo" src={defaultLogo} />
                    </Link>
                </Typography>
                <Typography component="p" className={clsx(classes.paragraph, classes.copyUpdate)}>
                    {config.build.lastUpdate && (
                        <>
                            {transUpdate} {` `}
                            <NullableTooltip title={config.build.lastVersion} placement="top">
                                <Typography
                                    component="span"
                                    variant="inherit"
                                    className={clsx(classes.paragraph, classes.lastUpdate)}
                                >
                                    {`${dateLastUpdate.toLocaleDateString("de-DE")} ${dateLastUpdate.toLocaleTimeString(
                                        "de-DE"
                                    )}`}
                                </Typography>
                            </NullableTooltip>
                        </>
                    )}
                </Typography>
                <Typography component="p" className={clsx(classes.paragraph, classes.copy)}>
                    {transDevelopedBy}
                    <Link href={config.content.clientWebUrl} target="_blank" color="inherit">
                        {transClient}
                    </Link>
                    {transDevelopedByAnd}
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit">
                        {transAuthor}
                    </Link>

                </Typography>
            </Box>
        </Toolbar>
    );
};

export default Footer;
