import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100px",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                marginBottom: 0,
            },
        },
        titleBox: {
            "& h1": {
                fontWeight: 700,
            },
            flexBasis: "100%",
            [theme.breakpoints.up("md")]: {
                flexBasis: "50%",
                textAlign: "start",
            },
        },
        iconBox: {
            flexBasis: "100%",
            [theme.breakpoints.up("md")]: {
                flexBasis: "50%",
                textAlign: "end",
            },
        },
        iconButton: {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.grey["100"],
            padding: "10px",
            margin: theme.spacing(0.5, 0.5, 0.5, 0),
            "&:hover": {
                backgroundColor: darken(theme.palette.grey["100"], 0.1),
            },
            [theme.breakpoints.up("md")]: {
                margin: theme.spacing(0, 1, 1, 0),
            },
            [theme.breakpoints.up("lg")]: {
                margin: theme.spacing(0, 1, 0, 0),
            },
        },
        iconButtonDialog: {
            padding: 0,
            backgroundColor: "transparent",
            "& svg": {
                fill: theme.palette.success.main,
            },
        },
        toggleButtonGroup: {
            margin: theme.spacing(0.5, 0, 0.5, 0),
            [theme.breakpoints.up("xl")]: {
                margin: theme.spacing(0),
            },
        },
        toggleButton: {
            padding: "9px 11px",
            color: theme.palette.grey["500"],
            backgroundColor: theme.palette.grey["100"],
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
            },
            "&.Mui-selected": {
                color: theme.palette.success.dark,
                backgroundColor: theme.palette.common.white,
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
            },
            "&:nth-child(2), &:first-child": {
                borderRight: 0,
                "&:hover": {
                    borderRight: 0,
                },
            },
            "&:nth-child(2), &:last-child": {
                borderLeft: 0,
                "&:hover": {
                    borderLeft: 0,
                },
            },
        },
        addButton: {
            textTransform: "inherit",
            margin: theme.spacing(0, 1),
            fontWeight: 400,
            minWidth: 0,
            padding: "8px 6px !important",
            color: theme.palette.grey["500"],
            whiteSpace: "nowrap",
        },
        addIcon: {
            fill: theme.palette.success.main,
            fontSize: theme.typography.pxToRem(18),
        },
    })
);

export default useStyles;
