import { defineMessages } from "react-intl";

export default defineMessages({
    upload: {
        id: "components.uploadButton.upload",
        defaultMessage: "Upload",
    },
    folder: {
        id: "components.uploadButton.folder",
        defaultMessage: "Folder", // Ordner
    },
    cameraUpload: {
        id: "components.uploadButton.cameraUpload",
        defaultMessage: "Camera upload", // Kamera Upload
    },
    fileUpload: {
        id: "components.uploadButton.fileUpload",
        defaultMessage: "File upload", // Datei hochladen
    },
    folderUpload: {
        id: "components.uploadButton.folderUpload",
        defaultMessage: "Folder upload", // Ordner hochladen
    },
});
