import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";
import KnownAttribute from "./KnownAttribute";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IStepAttributeMainProps {
}

const StepAttributeMain: React.FC<IStepAttributeMainProps> = ({ }) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transTypoReferenceUnit = intl.formatMessage({ ...messages.typoReferenceUnit });
    const transTypoLevel = intl.formatMessage({ ...messages.typoReferenceUnit });


    return (
        <Paper className={classes.paper}>
            <Box mt={0}>
                <Typography variant="body2" className={classes.fieldset}>
                    {transTypoReferenceUnit}
                </Typography>
                <KnownAttribute name={"reference"}/>
            </Box>
            <Box mt={1}>
                <Typography variant="body2" className={classes.fieldset}>
                    {transTypoLevel}
                </Typography>
                <KnownAttribute name={"level"}/>
            </Box>
        </Paper>
    );
};

export default StepAttributeMain;