import React from "react";
import { useParams } from "react-router";
import { ITree } from "../../../packages/Api/data/tree/types";
import { IPageParams } from "../../../pages/type";
import { useDocumentView } from "../../../redux/layout";
import { useData } from "../../../redux/tree";
import { getParentPathParts, getPath } from "../../../utils/Urn";
import DataTable from "../../DataTable/DataTable/DataTable";
import ThumbnailWrapper from "../../Thumbnail/ThumbnailWrapper/ThumbnailWrapper";
import { DataType } from "../../Tree/TreeWrapper/TreeWrapper";
import { useIntl } from "react-intl";
import messages from "./messages";

import { IDocumentView } from "../type";

export interface Data {
    nodeId: string;
    lastVersionNodeId: string;
    urn: string;
    name: string;
    offline: boolean;
    version: number;
    lastModified: string;
    updatedBy: string;
    mark: boolean;
    issue: boolean;
    isDir: boolean;
    format: string;
    size: number;
    path: string;
    pathIssues: string;
    fileId: string;
    fileType: string;
    issuesCount: number;
    markupsCount: number;
}

export interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
}

export const EMPTY = "--";

const getContentData = (treeData: ITree[], activeFolderIds: string[]) => {
    const id = activeFolderIds.shift();
    if (!treeData) {
        return undefined;
    }
    const nextItem = treeData.find((item) => item.id === id);
    if (nextItem) {
        if (activeFolderIds.length === 0) {
            return nextItem.children;
        }

        return getContentData(nextItem.children as ITree[], activeFolderIds);
    }
    return [];
};

interface IDocumentViewWrapperProps {
    activeProjectId?: string;
    activeFoldersIds: string[];
}

const DocumentViewWrapper: React.FC<IDocumentViewWrapperProps> = ({ activeProjectId, activeFoldersIds }) => {
    const { accountUrn, projectUrn } = useParams<IPageParams>();
    const documentView: IDocumentView = useDocumentView();
    const treeData = useData();
    const contentData = getContentData(treeData, getParentPathParts(activeProjectId, activeFoldersIds));

    const value = (value: any, condition: boolean, otherwiseValue = "") => {
        return condition ? value : otherwiseValue;
    };

    const getData = (contentData) => {
        if (!contentData) {
            return [];
        }
        const data: Data[] = [];
        contentData.forEach((item: ITree) => {
            const isFile = item.data.type === DataType.Item;
            const fileSize: number =
                item?.data?.lastVersionTreeNode?.data?.fileSize !== undefined
                    ? item?.data?.lastVersionTreeNode?.data?.fileSize
                    : 0;

            const lastModified = isFile
                ? new Date(item?.data?.lastVersionTreeNode?.data?.lastModifiedTime as string).toLocaleString("de-DE")
                : new Date(item?.data?.lastModifiedTime as string).toLocaleString("de-DE");

            const updatedBy = isFile
                ? (item?.data?.lastVersionTreeNode?.data?.lastModifiedUsername as string)
                : (item?.data?.lastModifiedUsername as string);

            data.push({
                nodeId: item.id,
                lastVersionNodeId: item?.data?.lastVersionTreeNode?.id as string,
                urn: item.data.urn,
                name: item.text,
                offline: false,
                isDir: !isFile,
                version: value(item?.data?.lastVersionTreeNode?.data?.version, isFile),
                lastModified,
                updatedBy,
                mark: value(false, isFile),
                issue: value(true, isFile),
                format: value(item.data.lastVersionTreeNode?.data?.fileType, isFile),
                size: value(fileSize, isFile),
                path: getPath(item, isFile, accountUrn, projectUrn, activeProjectId, activeFoldersIds, item.id),
                pathIssues: getPath(item, isFile, accountUrn, projectUrn, activeProjectId, activeFoldersIds, item.id),
                fileId: item?.data?.lastVersionTreeNode?.id as string,
                fileType: item?.data?.lastVersionTreeNode?.data?.fileType as string,
                issuesCount: item?.data?.lastVersionTreeNode?.data?.issuesCount as number,
                markupsCount: item?.data?.lastVersionTreeNode?.data?.markupsCount as number,
            });
        });
        return data;
    };

    const getSelectedNodes = () => {
        return activeFoldersIds?.length ? activeFoldersIds[activeFoldersIds.length - 1] : undefined;
    };


    // translations
    const intl = useIntl();
    const transName = intl.formatMessage({ ...messages.name });
    const transOffline = intl.formatMessage({ ...messages.offline });
    const transVersion = intl.formatMessage({ ...messages.version });
    const transLastModified = intl.formatMessage({ ...messages.lastModified });
    const transUpdatedBy = intl.formatMessage({ ...messages.updatedBy });
    const transMark = intl.formatMessage({ ...messages.mark });
    const transIssues = intl.formatMessage({ ...messages.issues });
    const transFormat = intl.formatMessage({ ...messages.format });
    const transSize = intl.formatMessage({ ...messages.size });


    const headCells: HeadCell[] = [
        { id: "name", numeric: false, label: transName },
        { id: "offline", numeric: false, label: transOffline },
        { id: "version", numeric: false, label: transVersion },
        { id: "lastModified", numeric: false, label: transLastModified },
        { id: "updatedBy", numeric: false, label: transUpdatedBy },
        { id: "mark", numeric: false, label: transMark },
        { id: "issue", numeric: false, label: transIssues },
        { id: "format", numeric: false, label: transFormat },
        { id: "size", numeric: false, label: transSize },
    ];

    return documentView === IDocumentView.Table ? (
        <DataTable
            loading={contentData === undefined}
            rows={getData(contentData)}
            headCells={headCells}
            selectedNodes={getSelectedNodes()}
            activeProjectId={activeProjectId}
            activeFoldersIds={activeFoldersIds}
        />
    ) : (
        <ThumbnailWrapper
            loading={contentData === undefined}
            items={getData(contentData)}
            selectedNodes={getSelectedNodes()}
            activeProjectId={activeProjectId}
            activeFoldersIds={activeFoldersIds}
        />
    );
};

export default DocumentViewWrapper;
