import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",

            "&>*": {
                pointerEvents: "all",
            },
        },
        label: {
            ...theme.typography.body2,
            marginRight: "0.5em",
        },
        button: {
            color: theme.palette.text.secondary,
            padding: "0 3px",
            margin: "0 3px",
            minWidth: 0,
            height: "100%",
            fontSize: "inherit",
        },
        listItemPrimary: {
            color: theme.palette.text.primary,
            fontWeight: 400,
        },
        listItemSecondary: {
            color: theme.palette.text.primary,
            fontSize: theme.typography.pxToRem(12),
        },
        circularProgress: {
            color: theme.palette.primary.main,
            margin: "16px 8px 8px 8px",
        },
    })
);

export default useStyles;
