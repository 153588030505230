import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const defaultFieldMinWidth = 185;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        mqbBox: {
            overflow: "auto",
            marginLeft: `-${theme.spacing(2)}`,
            "& .MuiToggleButtonGroup-root": {
                "& .MuiButtonBase-root": {
                    height: 30,
                },
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
            "& .MuiGrid-spacing-xs-1": {
                width: "auto",
                margin: 0,
            },
            "& .MuiGrid-spacing-xs-2": {
                width: "100%",
            },
            "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
                paddingBottom: 0,
            },
            "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
                paddingRight: theme.spacing(0),
            },
            "& .MuiAutocomplete-root": {
                minWidth: `${defaultFieldMinWidth}px !important`,
            },
            "& .MuiOutlinedInput-input": {
                "&.MuiAutocomplete-input": {
                    paddingRight: "56px !important",
                },
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiAutocomplete-inputRoot": {
                padding: "0 !important",
            },
            "& .MuiGrid-container": {
                flexWrap: "nowrap",
            },
            "& .MuiGrid-item": {
                display: "flex",
                alignItems: "center",
            },
            "& .MuiSvgIcon-root": {
                fontSize: theme.typography.pxToRem(20),
            },
            "& .MuiToggleButton-root.Mui-selected": {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.success.main,
            },
            "& .smooth-dnd-container": {
                width: "100%",
                overflow: "visible",
            },
            "& .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper": {
                overflow: "visible",
            },
            "& .MuiAutocomplete-clearIndicator": {
                padding: 0,
            },
            "& fieldset > legend > span": {
                paddingLeft: 0,
                paddingRight: "2px",
            },
            [theme.breakpoints.up("md")]: {
                marginLeft: `-${theme.spacing(1)}`,
            },
            [theme.breakpoints.up("lg")]: {
                marginLeft: 0,
            },
        },
        mqbGroup: {
            marginTop: `${theme.spacing(1)} !important`,
            marginBottom: `${theme.spacing(1)} !important`,
            borderLeftWidth: "1px !important",
            "& > .MuiGrid-root:first-child": {
                marginLeft: "-15px",
            },
            "& > div > .MuiGrid-container": {
                marginBottom: theme.spacing(0.5),
            },
        },
        mqbRule: {
            marginLeft: "-20px",
            border: theme.shape.borderRadius,
            "& .MuiGrid-root:last-child": {
                "& input": {
                    minWidth: `${defaultFieldMinWidth - 20}px !important`,
                },
                "& .MuiFormControl-fullWidth": {
                    width: "auto",
                },
            },
            "& > div": {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        mqbField: {},
        mqbOperator: {},
        mqbValue: {},
        mqbConditionsBtnGroup: {},
        mqbRemoveBtn: {
            marginTop: 0,
            marginRight: 0,
            "& svg": {
                marginTop: "2px",
                fill: theme.palette.secondary.main,
            },
            "&.Mui-disabled": {
                "& svg": {
                    fill: "rgba(0, 0, 0, 0.26)",
                },
            },
        },
        mqbClearBtn: {
            margin: 0,
            padding: 0,
            bottom: "6px",
            "& svg": {
                marginTop: "2px",
                fill: theme.palette.secondary.main,
            },
        },
        mqbAddRuleBtn: {
            "& svg": {
                marginTop: 0,
                fill: theme.palette.success.main,
            },
        },
        mqbAddGroupBtn: {
            "& svg": {
                marginTop: 0,
                fill: theme.palette.success.main,
            },
        },
        autocomplete: {
            minWidth: `${defaultFieldMinWidth}px !important`,
        },
        fieldAttribute: {
            marginLeft: theme.spacing(1),
        },
        skeletonBox: {
            marginTop: theme.spacing(1.5),
            marginLeft: `-${theme.spacing(1)}`,
        },
        skeleton: {
            marginBottom: theme.spacing(2.2),
            borderRadius: theme.shape.borderRadius,
        },
    })
);

export default useStyles;
