import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        value: {
            background: theme.palette.primary[100],
            borderRadius: "20px",
            padding: "1px 7px",
            margin: "1px 0",
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            fontSize: theme.typography.pxToRem(11)
        },
        popover: {
            pointerEvents: 'none',
            fontSize: theme.typography.pxToRem(12)
        },
        popoverInner: {
            padding: ".5rem .7rem"
        },
        stronger: {
          fontWeight: 400,
        },
    })
);

export default useStyles;
