import { AutocompleteData } from "mui-rff";
import { AnyAction } from "redux";
import { INormalizationFunction, IStepperAttributeFormValues } from "../../components/Mapping/StepAttribute/type";
import { getElements, getQuery } from "../../components/Mapping/StepContentWrapper/utils";

import { IQuery, IQueryOperator } from "../../components/Mapping/ElementQueryBuilder/type";
import {
    addEquipmentToPosition, addGraphicsToPosition,
    addMaterialToPosition,
    addProjectElementsPositions,
    addWorkToPosition, deleteAttachedEquipment,
    deleteAttachedMaterial,
    deleteAttachedWork,
    deleteDerivedPositions,
    deleteElementsPosition,
    deleteProjectElementsPositions, getAttachedEquipment, getAttachedGraphics,
    getAttachedMaterial,
    getAttachedWork, getBasicPositions,
    getDerivedPositions,
    getElementsPositions,
    getManualPosition,
    getPositionFormConfiguration,
    getProjectElementsPositions,
    getUnitPrice, updateAttachedEquipment,
    updateAttachedMaterial,
    updateAttachedWork,
    updateDerivedPosition,
    updateManualPosition,
    updatePositionBasics,
} from "../../packages/Api/data/elements/client";
import { IDerivedPosition } from "../../packages/Api/data/elements/types";
import {
    getMappingOperations,
    getMappingProjectAttributes,
    getMappingProjectElements, getNormalizationFunctions,
} from "../../packages/Api/data/mapping/client";
import {
    addMaterialCatalogue,
    deleteMaterialCatalogue,
    getMaterialCatalogue,
    updateMaterialCatalogue,
} from "../../packages/Api/data/material/client";
import {
    addWorkCatalogue,
    deleteWorkCatalogue,
    getWorkCatalogue,
    updateWorkCatalogue,
} from "../../packages/Api/data/work/client";
import { not } from "../../utils/Array";
import {
    ActionTypes,
    onAddCatalogPositionIDsDone,
    onAddCatalogPositionIDsFail,
    onAddEquipmentCatalogueDone,
    onAddEquipmentCatalogueFail,
    onAddEquipmentToPositionDone,
    onAddEquipmentToPositionFail,
    onAddGraphicsCatalogueDone,
    onAddGraphicsCatalogueFail,
    onAddGraphicsToPositionDone,
    onAddGraphicsToPositionFail,
    onAddMaterialCatalogueDone,
    onAddMaterialCatalogueFail,
    onAddMaterialToPositionDone,
    onAddMaterialToPositionFail,
    onAddWorkCatalogueDone,
    onAddWorkCatalogueFail,
    onAddWorkToPositionDone,
    onAddWorkToPositionFail,
    onAttachedEquipment,
    onAttachedEquipmentDone,
    onAttachedEquipmentFail,
    onAttachedGraphics,
    onAttachedGraphicsDone,
    onAttachedGraphicsFail,
    onAttachedMaterial,
    onAttachedMaterialDone,
    onAttachedMaterialFail,
    onAttachedWork,
    onAttachedWorkDone,
    onAttachedWorkFail,
    onAttributeDone,
    onAttributeFail,
    onAttributeFormInitDone,
    onBasicPositions,
    onBasicPositionsDone,
    onBasicPositionsFail,
    onCatalogPositionIDsDone,
    onCatalogPositionIDsFail,
    onCheckedCatalog,
    onCheckedMaster,
    onDeleteAttachedEquipmentDone,
    onDeleteAttachedEquipmentFail,
    onDeleteAttachedMaterialDone,
    onDeleteAttachedMaterialFail,
    onDeleteAttachedWorkDone,
    onDeleteAttachedWorkFail,
    onDeleteBasicPositionsDone,
    onDeleteBasicPositionsFail,
    onDeleteCatalogPositionIDsDone,
    onDeleteCatalogPositionIDsFail,
    onDeleteDerivedPositionsDone,
    onDeleteDerivedPositionsFail,
    onDeleteEquipmentCatalogueDone,
    onDeleteEquipmentCatalogueFail,
    onDeleteGraphicsCatalogueDone,
    onDeleteGraphicsCatalogueFail,
    onDeleteMappingPositionsDone,
    onDeleteMappingPositionsFail,
    onDeleteMaterialCatalogueDone,
    onDeleteMaterialCatalogueFail,
    onDeleteWorkCatalogueDone,
    onDeleteWorkCatalogueFail,
    onDerivedPositions,
    onDerivedPositionsDone,
    onDerivedPositionsFail,
    onElementQueryDone,
    onElementQueryFail,
    onElementQueryOperatorsDone,
    onEquipmentCatalogueDone,
    onEquipmentCatalogueFail,
    onGraphicsCatalogueDone,
    onGraphicsCatalogueFail,
    onGraphicsPatternCatalogue,
    onGraphicsPatternCatalogueDone,
    onGraphicsPatternCatalogueFail,
    onInstancesAttributesDone, onInstancesAttributesFail,
    onManualPositionDone,
    onManualPositionFail,
    onMappingPositions,
    onMappingPositionsDone,
    onMappingPositionsFail,
    onMaterialCatalogue,
    onMaterialCatalogueDone,
    onMaterialCatalogueFail,
    onPositionFormConfigurationDone,
    onPositionFormConfigurationFail,
    onUnitPrice,
    onUnitPriceDone,
    onUnitPriceFail,
    onUpdateAttachedEquipmentDone,
    onUpdateAttachedEquipmentFail,
    onUpdateAttachedMaterialDone,
    onUpdateAttachedMaterialFail,
    onUpdateAttachedWorkDone,
    onUpdateAttachedWorkFail,
    onUpdateDerivedPositionDone,
    onUpdateDerivedPositionsFail,
    onUpdateEquipmentCatalogueDone,
    onUpdateEquipmentCatalogueFail,
    onUpdateGraphicsCatalogueDone,
    onUpdateGraphicsCatalogueFail,
    onUpdateManualPositionDone,
    onUpdateManualPositionFail,
    onUpdateMaterialCatalogueDone,
    onUpdateMaterialCatalogueFail,
    onUpdatePositionBasicsDone,
    onUpdatePositionBasicsFail,
    onUpdateWorkCatalogueDone,
    onUpdateWorkCatalogueFail,
    onWorkCatalogueDone,
    onWorkCatalogueFail,
} from "./action";
import {
    selectElementQuery,
    selectMappingAttachedEquipment,
    selectMappingAttachedMaterial,
    selectMappingAttachedWork, selectMappingBasicPositions,
    selectMappingCatalogPositionIDs,
    selectMappingDerivedPositions, selectMappingEquipmentCatalogue, selectMappingGraphicsCatalogue,
    selectMappingMaterialCatalogue,
    selectMappingWorkCatalogue, selectViewable,
} from "./selector";
import {
    addEquipmentCatalogue,
    deleteEquipmentCatalogue,
    getEquipmentCatalogue, updateEquipmentCatalogue,
} from "../../packages/Api/data/equipment/client";
import {
    addGraphicsCatalogue, deleteGraphicsCatalogue,
    getGraphicsCatalogue, getGraphicsPatternCatalogue,
    updateGraphicsCatalogue,
} from "../../packages/Api/data/graphics/client";
import { loadMatchingInstancesDebounced } from "../../packages/Api/data/lookup/client";
import { reducer } from ".";

const onMappingMiddleware = ({ dispatch, getState }) => (next) => (action: AnyAction) => {
    const resolve = next(action);

    // mapping positions
    if (action.type === ActionTypes.OnMappingPositions) {
        const { token } = action.payload;

        getElementsPositions(token)
            .then((response) => {
                dispatch(onMappingPositionsDone({ positions: response }));
            })
            .catch((error) => {
                dispatch(onMappingPositionsFail({ error }));
            });
    }

    // delete mapping positions
    if (action.type === ActionTypes.OnDeleteMappingPositions) {
        const { token, positionIDs } = action.payload;
        deleteElementsPosition(token, positionIDs)
            .then((response) => {
                dispatch(onDeleteMappingPositionsDone({ deletePositionsResponse: response }));
                // if (response.deleted && )
                dispatch(onMappingPositions({ token }));
            })
            .catch((error) => {
                dispatch(onDeleteMappingPositionsFail({ error }));
            });
    }

    // catalog positions IDS
    if (action.type === ActionTypes.OnCatalogPositionIDs) {
        const { token, projectID } = action.payload;

        getProjectElementsPositions(token, projectID)
            .then((response) => {
                dispatch(onCatalogPositionIDsDone({ positionIDs: response }));
            })
            .catch((error) => {
                dispatch(onCatalogPositionIDsFail({ error }));
            });
    }

    // add catalog positions IDS
    if (action.type === ActionTypes.OnAddCatalogPositionIDs) {
        const { token, projectID, positionIDs } = action.payload;
        const catalogPositionIDs = selectMappingCatalogPositionIDs(getState());

        addProjectElementsPositions(token, projectID, positionIDs)
            .then((response) => {
                dispatch(onAddCatalogPositionIDsDone({ positionIDs: [...catalogPositionIDs, ...response] }));
                dispatch(onCheckedMaster({ checked: [] }));
            })
            .catch((error) => {
                dispatch(onAddCatalogPositionIDsFail({ error }));
            });
    }

    // delete catalog positions IDS
    if (action.type === ActionTypes.OnDeleteCatalogPositionIDs) {
        const { token, projectID, positionIDs } = action.payload;
        const catalogPositionIDs = selectMappingCatalogPositionIDs(getState());

        deleteProjectElementsPositions(token, projectID, positionIDs)
            .then((response) => {
                dispatch(onDeleteCatalogPositionIDsDone({ positionIDs: not(catalogPositionIDs, response) }));
                dispatch(onCheckedCatalog({ checked: [] }));
            })
            .catch((error) => {
                dispatch(onDeleteCatalogPositionIDsFail({ error }));
            });
    }

    // elements query
    if (action.type === ActionTypes.OnElementQuery) {
        const { token, projectID, positionID } = action.payload;

        getMappingProjectElements(token, projectID, positionID)
            .then((response) => {
                if (response) {
                    const newPsets: string[] = [];
                    const newAttributes: string[] = [];
                    const newQuery: IQuery = getQuery(response, newPsets, newAttributes);
                    dispatch(onElementQueryDone({ psets: newPsets, attributes: newAttributes, query: newQuery }));
                }
                else {
                    dispatch(onElementQueryDone({ psets: [], attributes: [], query: null }));
                }
            })
            .catch((error) => {
                dispatch(onElementQueryFail({ error }));
            });
    }

    // elements query operators
    if (action.type === ActionTypes.OnElementQuery) {
        const { token } = action.payload;

        getMappingOperations(token)
            .then((response) => {
                let newOperators: IQueryOperator[] = [];
                response?.map((operation) => {
                    newOperators?.push({
                        label: operation?.name,
                        value: operation?.code,
                        types: operation?.parameterDataType ? [...(operation?.parameterDataType as string[])] : [],
                    });
                });
                dispatch(onElementQueryOperatorsDone({ operators: newOperators }));
            })
            .catch((error) => {
                dispatch(onElementQueryFail({ error }));
            });
    }


    // elements query operators
    if (action.type === ActionTypes.OnInstancesAttributesQuery) {
        const { token, viewableID, rule } = action.payload;

        loadMatchingInstancesDebounced(token, viewableID, rule)
            .then((response) => {
                dispatch(onInstancesAttributesDone({ instancesAttributes: response }));
            })
            .catch((error) => {
                dispatch(onInstancesAttributesFail({ error: error }));
            })
    }

    // derived positions
    if (action.type === ActionTypes.OnDerivedPositions) {
        const { token, projectID, positionID } = action.payload;

        getDerivedPositions(token, projectID, positionID)
            .then((response) => {
                dispatch(onDerivedPositionsDone({ positions: response }));
            })
            .catch((error) => {
                dispatch(onDerivedPositionsFail(error));
            });
    }

    // delete derived positions
    if (action.type === ActionTypes.OnDeleteDerivedPositions) {
        const { token, derivedPositionIDs } = action.payload;
        const derivedPositions: IDerivedPosition[] = [...selectMappingDerivedPositions(getState())];

        deleteDerivedPositions(token, derivedPositionIDs)
            .then((response) => {
                if (response) {
                    const filteredDerivedPositions = derivedPositions.filter(
                        (dp) => dp.derivedPositionID !== derivedPositionIDs?.[0]
                    );
                    dispatch(
                        onDeleteDerivedPositionsDone({
                            positions: filteredDerivedPositions,
                        })
                    );
                    dispatch(
                        onMappingPositions({
                            token: token as string,
                        })
                    )
                }
            })
            .catch((error) => {
                dispatch(onDeleteDerivedPositionsFail(error));
            });
    }

    // basic positions
    if (action.type === ActionTypes.OnBasicPositions) {
        const { token, projectID, positionID } = action.payload;

        getBasicPositions(token, projectID, positionID)
            .then((response) => {
                dispatch(onBasicPositionsDone({ positions: response }));
            })
            .catch((error) => {
                dispatch(onBasicPositionsFail(error));
            });
    }

    // delete basic positions
    if (action.type === ActionTypes.OnDeleteBasicPositions) {
        const { token, basicPositionIDs } = action.payload;
        const basicPositions: IDerivedPosition[] = [...selectMappingBasicPositions(getState())];

        deleteDerivedPositions(token, basicPositionIDs)
            .then((response) => {
                if (response) {
                    const filteredBasicPositions = basicPositions.filter(
                        (dp) => dp.derivedPositionID !== basicPositionIDs?.[0]
                    );
                    dispatch(
                        onDeleteBasicPositionsDone({
                            positions: filteredBasicPositions,
                        })
                    );
                    dispatch(
                        onMappingPositions({
                            token: token as string,
                        })
                    )
                }
            })
            .catch((error) => {
                dispatch(onDeleteBasicPositionsFail(error));
            });
    }


    // update attached equipment
    if (action.type === ActionTypes.OnUpdateDerivedPosition) {
        const { token, derivedPositionID, derivedPosition, projectID, positionID } = action.payload;
        updateDerivedPosition(token, derivedPositionID, derivedPosition)
            .then((response) => {
                if (response) {
                    dispatch(
                        onUpdateDerivedPositionDone({
                            position: response,
                        })
                    );

                    dispatch(
                        onDerivedPositions({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onBasicPositions({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onMappingPositions({
                            token: token as string,
                        })
                    )
                }
            })
            .catch((error) => {
                dispatch(onUpdateDerivedPositionsFail(error));
            });
    }

    // attribute
    if (action.type === ActionTypes.OnAttribute) {
        const { token, projectID, positionID } = action.payload;

        getNormalizationFunctions(token)
            .then((response) => {
                dispatch(
                    onAttributeFormInitDone({
                        normalizationFunctions: response,
                    })
                );
            });

        getMappingProjectAttributes(token, projectID, positionID)
            .then((response) => {
                // TODO: load autocomplete data from API - next steps (Jakub Jirous 2021-03-31 17:13:27)
                const autocompletePsets: AutocompleteData[] = [];
                const autocompleteAttributes: AutocompleteData[] = [];

                if (response?.level?.propertySet !== undefined) {
                    autocompletePsets.push({
                        label: response?.level?.propertySet,
                        value: response?.level?.propertySet,
                    });
                }
                if (response?.referenceUnit?.propertySet !== undefined) {
                    autocompletePsets.push({
                        label: response?.referenceUnit?.propertySet,
                        value: response?.referenceUnit?.propertySet,
                    });
                }
                if (response?.level?.attribute !== undefined) {
                    autocompleteAttributes.push({
                        label: response?.level?.attribute,
                        value: response?.level?.attribute,
                    });
                }
                if (response?.referenceUnit?.attribute !== undefined) {
                    autocompleteAttributes.push({
                        label: response?.referenceUnit?.attribute,
                        value: response?.referenceUnit?.attribute,
                    });
                }

                // react final form initial values from API
                const formValues: IStepperAttributeFormValues = {
                    reference: response?.referenceUnit,
                    level: response?.level,
                    additionalAttributes: response?.additionalAttributes,
                    derivedPositionsReferenceUnits: response?.derivedPositionsReferenceUnits,
                };

                dispatch(
                    onAttributeDone({
                        attribute: response,
                        autocompletePsets,
                        autocompleteAttributes,
                        formValues,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAttributeFail(error));
            });
    }

    // work catalogue
    if (action.type === ActionTypes.OnWorkCatalogue) {
        const { token } = action.payload;

        getWorkCatalogue(token)
            .then((response) => {
                dispatch(
                    onWorkCatalogueDone({
                        catalogue: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onWorkCatalogueFail(error));
            });
    }

    // add work catalogue
    if (action.type === ActionTypes.OnAddWorkCatalogue) {
        const { token, projectID, positionID, request } = action.payload;
        const catalogue = selectMappingWorkCatalogue(getState());

        addWorkCatalogue(token, request)
            .then((response) => {
                dispatch(
                    onAddWorkCatalogueDone({
                        catalogue: [...catalogue, response],
                    })
                );
                dispatch(
                    onAttachedWork({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddWorkCatalogueFail(error));
            });
    }

    // update work catalogue
    if (action.type === ActionTypes.OnUpdateWorkCatalogue) {
        const { token, projectID, positionID, workID, request } = action.payload;
        const catalogue = selectMappingWorkCatalogue(getState());
        const updateIndex = catalogue?.findIndex((work) => work?.workID === workID);

        updateWorkCatalogue(token, workID, request)
            .then((response) => {
                catalogue[updateIndex] = response;
                dispatch(
                    onUpdateWorkCatalogueDone({
                        catalogue: catalogue,
                    })
                );
                dispatch(
                    onAttachedWork({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onUpdateWorkCatalogueFail(error));
            });
    }

    // delete work catalogue
    if (action.type === ActionTypes.OnDeleteWorkCatalogue) {
        const { token, projectID, positionID, workID } = action.payload;
        const catalogue = selectMappingWorkCatalogue(getState());
        const filteredCatalog = catalogue?.filter((work) => work?.workID !== workID);

        deleteWorkCatalogue(token, workID)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteWorkCatalogueDone({
                            catalogue: filteredCatalog,
                        })
                    );
                    dispatch(
                        onAttachedWork({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onDeleteWorkCatalogueFail(error));
            });
    }

    // graphics pattern catalogue
    if (action.type === ActionTypes.OnGraphicsPatternCatalogue) {
        const { token } = action.payload;

        getGraphicsPatternCatalogue(token)
            .then((response) => {
                dispatch(
                    onGraphicsPatternCatalogueDone({
                        catalogue: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onGraphicsPatternCatalogueFail(error));
            });
    }

    // graphics catalogue
    if (action.type === ActionTypes.OnGraphicsCatalogue) {
        const { token } = action.payload;

        getGraphicsCatalogue(token)
            .then((response) => {
                dispatch(
                    onGraphicsCatalogueDone({
                        catalogue: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onGraphicsCatalogueFail(error));
            });
    }

    // add graphics catalogue
    if (action.type === ActionTypes.OnAddGraphicsCatalogue) {
        const { token, projectID, positionID, request } = action.payload;
        const catalogue = selectMappingGraphicsCatalogue(getState());

        addGraphicsCatalogue(token, request)
            .then((response) => {
                dispatch(
                    onAddGraphicsCatalogueDone({
                        catalogue: [...catalogue, response],
                    })
                );
                dispatch(
                    onAttachedGraphics({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddGraphicsCatalogueFail(error));
            });
    }

    // update graphics catalogue
    if (action.type === ActionTypes.OnUpdateGraphicsCatalogue) {
        const { token, projectID, positionID, graphicsID, request } = action.payload;
        const catalogue = selectMappingGraphicsCatalogue(getState());
        const updateIndex = catalogue?.findIndex((graphics) => graphics?.graphicsID === graphicsID);

        updateGraphicsCatalogue(token, graphicsID, request)
            .then((response) => {
                catalogue[updateIndex] = response;
                dispatch(
                    onUpdateGraphicsCatalogueDone({
                        catalogue: catalogue,
                    })
                );
                dispatch(
                    onAttachedGraphics({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onUpdateGraphicsCatalogueFail(error));
            });
    }

    // delete graphics catalogue
    if (action.type === ActionTypes.OnDeleteGraphicsCatalogue) {
        const { token, projectID, positionID, graphicsID } = action.payload;
        const catalogue = selectMappingGraphicsCatalogue(getState());
        const filteredCatalog = catalogue?.filter((graphics) => graphics?.graphicsID !== graphicsID);

        deleteGraphicsCatalogue(token, graphicsID)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteGraphicsCatalogueDone({
                            catalogue: filteredCatalog,
                        })
                    );
                    dispatch(
                        onAttachedGraphics({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onDeleteGraphicsCatalogueFail(error));
            });
    }
    
    // equipment catalogue
    if (action.type === ActionTypes.OnEquipmentCatalogue) {
        const { token } = action.payload;

        getEquipmentCatalogue(token)
            .then((response) => {
                dispatch(
                    onEquipmentCatalogueDone({
                        catalogue: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onEquipmentCatalogueFail(error));
            });
    }

    // add equipment catalogue
    if (action.type === ActionTypes.OnAddEquipmentCatalogue) {
        const { token, projectID, positionID, request } = action.payload;
        const catalogue = selectMappingEquipmentCatalogue(getState());

        addEquipmentCatalogue(token, request)
            .then((response) => {
                dispatch(
                    onAddEquipmentCatalogueDone({
                        catalogue: [...catalogue, response],
                    })
                );
                dispatch(
                    onAttachedEquipment({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddEquipmentCatalogueFail(error));
            });
    }

    // update equipment catalogue
    if (action.type === ActionTypes.OnUpdateEquipmentCatalogue) {
        const { token, projectID, positionID, equipmentID, request } = action.payload;
        const catalogue = selectMappingEquipmentCatalogue(getState());
        const updateIndex = catalogue?.findIndex((equipment) => equipment?.equipmentID === equipmentID);

        updateEquipmentCatalogue(token, equipmentID, request)
            .then((response) => {
                catalogue[updateIndex] = response;
                dispatch(
                    onUpdateEquipmentCatalogueDone({
                        catalogue: catalogue,
                    })
                );
                dispatch(
                    onAttachedEquipment({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onUpdateEquipmentCatalogueFail(error));
            });
    }

    // delete equipment catalogue
    if (action.type === ActionTypes.OnDeleteEquipmentCatalogue) {
        const { token, projectID, positionID, equipmentID } = action.payload;
        const catalogue = selectMappingEquipmentCatalogue(getState());
        const filteredCatalog = catalogue?.filter((equipment) => equipment?.equipmentID !== equipmentID);

        deleteEquipmentCatalogue(token, equipmentID)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteEquipmentCatalogueDone({
                            catalogue: filteredCatalog,
                        })
                    );
                    dispatch(
                        onAttachedEquipment({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onDeleteEquipmentCatalogueFail(error));
            });
    }

    // material catalogue
    if (action.type === ActionTypes.OnMaterialCatalogue) {
        const { token } = action.payload;

        getMaterialCatalogue(token)
            .then((response) => {
                dispatch(
                    onMaterialCatalogueDone({
                        catalogue: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onMaterialCatalogueFail(error));
            });
    }

    // add material catalogue
    if (action.type === ActionTypes.OnAddMaterialCatalogue) {
        const { token, projectID, positionID, request } = action.payload;
        const catalogue = selectMappingMaterialCatalogue(getState());

        addMaterialCatalogue(token, request)
            .then((response) => {
                dispatch(
                    onAddMaterialCatalogueDone({
                        catalogue: [...catalogue, response],
                    })
                );
                dispatch(
                    onAttachedMaterial({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddMaterialCatalogueFail(error));
            });
    }

    // update material catalogue
    if (action.type === ActionTypes.OnUpdateMaterialCatalogue) {
        const { token, projectID, positionID, materialID, request } = action.payload;
        const catalogue = selectMappingMaterialCatalogue(getState());
        const updateIndex = catalogue?.findIndex((material) => material?.materialID === materialID);

        updateMaterialCatalogue(token, materialID, request)
            .then((response) => {
                catalogue[updateIndex] = response;
                dispatch(onMaterialCatalogue({ token }));
                dispatch(
                    onUpdateMaterialCatalogueDone({
                        catalogue: catalogue,
                    })
                );
                dispatch(
                    onAttachedMaterial({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onUpdateMaterialCatalogueFail(error));
            });
    }

    // delete material catalogue
    if (action.type === ActionTypes.OnDeleteMaterialCatalogue) {
        const { token, projectID, positionID, materialID } = action.payload;
        const catalogue = selectMappingMaterialCatalogue(getState());
        const filteredCatalog = catalogue?.filter((material) => material?.materialID !== materialID);

        deleteMaterialCatalogue(token, materialID)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteMaterialCatalogueDone({
                            catalogue: filteredCatalog,
                        })
                    );
                    dispatch(
                        onAttachedMaterial({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onDeleteMaterialCatalogueFail(error));
            });
    }

    // attached work
    if (action.type === ActionTypes.OnAttachedWork) {
        const { token, projectID, positionID } = action.payload;

        getAttachedWork(token, projectID, positionID)
            .then((response) => {
                dispatch(
                    onAttachedWorkDone({
                        attachedWork: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAttachedWorkFail(error));
            });
    }

    // attached graphics
    if (action.type === ActionTypes.OnAttachedGraphics) {
        const { token, projectID, positionID } = action.payload;

        getAttachedGraphics(token, projectID, positionID)
            .then((response) => {
                dispatch(
                    onAttachedGraphicsDone({
                        attachedGraphics: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAttachedGraphicsFail(error));
            });
    }

    // update attached work
    if (action.type === ActionTypes.OnUpdateAttachedWork) {
        const { token, projectID, positionID, attachedWorkID, data } = action.payload;
        updateAttachedWork(token, attachedWorkID, data)
            .then((response) => {
                if (response) {
                    dispatch(
                        onUpdateAttachedWorkDone({
                            attachedWork: response,
                        })
                    );
                    dispatch(
                        onAttachedWork({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onUnitPrice({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onUpdateAttachedWorkFail(error));
            });
    }

    // delete attached work
    if (action.type === ActionTypes.OnDeleteAttachedWork) {
        const { token, projectID, positionID, attachedWorkIDs } = action.payload;
        const attachedWork = selectMappingAttachedWork(getState());
        const filteredAttachedWork = attachedWork?.filter((work) => !attachedWorkIDs?.includes(work?.attachedWorkID));

        deleteAttachedWork(token, projectID, positionID, attachedWorkIDs)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteAttachedWorkDone({
                            attachedWork: filteredAttachedWork,
                        })
                    );
                    dispatch(
                        onAttachedWork({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onUnitPrice({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onDeleteAttachedWorkFail(error));
            });
    }

    // add work to position
    if (action.type === ActionTypes.OnAddWorkToPosition) {
        const { token, projectID, positionID, workIDs } = action.payload;
        const attachedWork = selectMappingAttachedWork(getState());

        addWorkToPosition(token, projectID, positionID, workIDs)
            .then((response) => {
                dispatch(
                    onAddWorkToPositionDone({
                        attachedWork: attachedWork ? [...attachedWork, ...response] : [...response],
                    })
                );
                dispatch(
                    onAttachedWork({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddWorkToPositionFail(error));
            });
    }

    // add graphics to position
    if (action.type === ActionTypes.OnAddGraphicsToPosition) {
        const { token, projectID, positionID, graphicsID } = action.payload;

        addGraphicsToPosition(token, projectID, positionID, graphicsID)
            .then((response) => {
                dispatch(
                    onAddGraphicsToPositionDone({
                        attachedGraphics: response,
                    })
                );
                dispatch(
                    onAttachedGraphics({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddGraphicsToPositionFail(error));
            });
    }

    // attached equipment
    if (action.type === ActionTypes.OnAttachedEquipment) {
        const { token, projectID, positionID } = action.payload;

        getAttachedEquipment(token, projectID, positionID)
            .then((response) => {
                dispatch(
                    onAttachedEquipmentDone({
                        attachedEquipment: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAttachedEquipmentFail(error));
            });
    }

    // update attached equipment
    if (action.type === ActionTypes.OnUpdateAttachedEquipment) {
        const { token, projectID, positionID, attachedEquipmentID, data } = action.payload;
        updateAttachedEquipment(token, attachedEquipmentID, data)
            .then((response) => {
                if (response) {
                    dispatch(
                        onUpdateAttachedEquipmentDone({
                            attachedEquipment: response,
                        })
                    );
                    dispatch(
                        onAttachedEquipment({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onUnitPrice({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onUpdateAttachedEquipmentFail(error));
            });
    }

    // delete attached equipment
    if (action.type === ActionTypes.OnDeleteAttachedEquipment) {
        const { token, projectID, positionID, attachedEquipmentIDs } = action.payload;
        const attachedEquipment = selectMappingAttachedEquipment(getState());
        const filteredAttachedEquipment = attachedEquipment?.filter((equipment) => !attachedEquipmentIDs?.includes(equipment?.attachedEquipmentID));

        deleteAttachedEquipment(token, projectID, positionID, attachedEquipmentIDs)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteAttachedEquipmentDone({
                            attachedEquipment: filteredAttachedEquipment,
                        })
                    );
                    dispatch(
                        onAttachedEquipment({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onUnitPrice({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(onDeleteAttachedEquipmentFail(error));
            });
    }

    // add equipment to position
    if (action.type === ActionTypes.OnAddEquipmentToPosition) {
        const { token, projectID, positionID, equipmentIDs } = action.payload;
        const attachedEquipment = selectMappingAttachedEquipment(getState());

        addEquipmentToPosition(token, projectID, positionID, equipmentIDs)
            .then((response) => {
                dispatch(
                    onAddEquipmentToPositionDone({
                        attachedEquipment: attachedEquipment ? [...attachedEquipment, ...response] : [...response],
                    })
                );
                dispatch(
                    onAttachedEquipment({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAddEquipmentToPositionFail(error));
            });
    }

    // attached material
    if (action.type === ActionTypes.OnAttachedMaterial) {
        const { token, projectID, positionID } = action.payload;

        getAttachedMaterial(token, projectID, positionID)
            .then((response) => {
                dispatch(
                    onAttachedMaterialDone({
                        attachedMaterial: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onAttachedMaterialFail(error));
            });
    }

    // update attached material
    if (action.type === ActionTypes.OnUpdateAttachedMaterial) {
        const { token, projectID, positionID, attachedMaterialID, data } = action.payload;
        updateAttachedMaterial(token, attachedMaterialID, data)
            .then((response) => {
                if (response) {
                    dispatch(
                        onUpdateAttachedMaterialDone({
                            attachedMaterial: response,
                        })
                    );
                    dispatch(
                        onAttachedMaterial({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onUnitPrice({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onMappingPositions({
                            token: token as string,
                        })
                    )
                }
            })
            .catch((error) => {
                dispatch(onUpdateAttachedMaterialFail(error));
            });
    }

    // delete attached material
    if (action.type === ActionTypes.OnDeleteAttachedMaterial) {
        const { token, projectID, positionID, attachedMaterialIDs } = action.payload;

        const attachedMaterial = selectMappingAttachedMaterial(getState());
        const filteredAttachedMaterial = attachedMaterial?.filter((work) =>
            attachedMaterialIDs?.includes(work?.attachedMaterialID)
        );

        deleteAttachedMaterial(token, projectID, positionID, attachedMaterialIDs)
            .then((response) => {
                if (response) {
                    dispatch(
                        onDeleteAttachedMaterialDone({
                            attachedMaterial: filteredAttachedMaterial,
                        })
                    );
                    dispatch(
                        onAttachedMaterial({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onUnitPrice({
                            token,
                            projectID,
                            positionID,
                        })
                    );
                    dispatch(
                        onMappingPositions({
                            token: token as string,
                        })
                    )
                }
            })
            .catch((error) => {
                dispatch(onDeleteAttachedMaterialFail(error));
            });
    }

    // add material to position
    if (action.type === ActionTypes.OnAddMaterialToPosition) {
        const { token, projectID, positionID, materialType, materialIDs } = action.payload;
        const attachedMaterial = selectMappingAttachedMaterial(getState());

        addMaterialToPosition(token, projectID, positionID, materialType, materialIDs)
            .then((response) => {
                dispatch(
                    onAddMaterialToPositionDone({
                        attachedMaterial: [...attachedMaterial, ...response],
                    })
                );
                dispatch(
                    onAttachedMaterial({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onUnitPrice({
                        token,
                        projectID,
                        positionID,
                    })
                );
                dispatch(
                    onMappingPositions({
                        token: token as string,
                    })
                )
            })
            .catch((error) => {
                dispatch(onAddMaterialToPositionFail(error));
            });
    }

    // unit price
    if (action.type === ActionTypes.OnUnitPrice) {
        const { token, projectID, positionID } = action.payload;

        getUnitPrice(token, projectID, positionID)
            .then((response) => {
                dispatch(
                    onUnitPriceDone({
                        unitPrice: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onUnitPriceFail(error));
            });
    }

    // update position basics
    if (action.type === ActionTypes.OnUpdatePositionBasics) {
        const { token, projectID, positionID, data } = action.payload;

        updatePositionBasics(token, projectID, positionID, data)
            .then((response) => {
                dispatch(
                    onUpdatePositionBasicsDone({
                        positionBasics: response,
                    })
                );
                dispatch(onMappingPositions({ token }));
            })
            .catch((error) => {
                dispatch(onUpdatePositionBasicsFail(error));
            });
    }

    // update manual position
    if (action.type === ActionTypes.OnUpdateManualPosition) {
        const { token, projectID, positionID, data } = action.payload;

        updateManualPosition(token, projectID, positionID, data)
            .then((response) => {
                dispatch(
                    onUpdateManualPositionDone({
                        manualPosition: response,
                    })
                );
                dispatch(
                    onMappingPositions({
                        token: token as string,
                    })
                )
            })
            .catch((error) => {
                dispatch(onUpdateManualPositionFail(error));
            });
    }

    // get manual position
    if (action.type === ActionTypes.OnManualPosition) {
        const { token, positionID } = action.payload;

        getManualPosition(token, positionID)
            .then((response) => {
                dispatch(
                    onManualPositionDone({
                        manualPosition: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onManualPositionFail(error));
            });
    }

    // get position form configuration
    if (action.type === ActionTypes.OnPositionFormConfiguration) {
        const { token } = action.payload;

        getPositionFormConfiguration(token)
            .then((response) => {
                dispatch(
                    onPositionFormConfigurationDone({
                        formConfiguration: response,
                    })
                );
            })
            .catch((error) => {
                dispatch(onPositionFormConfigurationFail(error));
            });
    }

    return resolve;
};

export default [onMappingMiddleware];
