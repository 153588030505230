import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            flex: 1,
            margin: theme.spacing(1.5),
            background: theme.palette.primaryVariant[50],
            "&:last-child": {
                marginTop: theme.spacing(0),
            },
        },
        paperTabs: {
            overflowY: "hidden",
            flexBasis: "50%",
            [theme.breakpoints.up("md")]: {
                flexBasis: "40%",
            },
        },
        boxStep1: {
            display: "flex",
            flexFlow: "column nowrap",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: theme.spacing(2),
            margin: "auto",
            [theme.breakpoints.up("lg")]: {
                maxWidth: "80%",
            },
            [theme.breakpoints.up("xl")]: {
                maxWidth: "50%",
            },
        },
        tabsRoot: {
            color: theme.palette.text.primary,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            background: theme.palette.primary[400],
            minHeight: "30px",
        },
        tabsIndicator: {
            display: "none",
        },
        tabRoot: {
            color: theme.palette.primary[500],
            background: theme.palette.primary[300],
            minHeight: "30px",
            minWidth: "100px",
            fontSize: theme.typography.pxToRem(12),
            opacity: 1,
        },
        tabSelected: {
            color: theme.palette.primary[700],
            background: theme.palette.primaryVariant[50],
        },
        tabPanel: {
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingBottom: "30px",
        },
        tabPanelBox: {
            padding: theme.spacing(1, 2),
            fontSize: theme.typography.pxToRem(11),
            "& dl": {
                display: "flex",
                flexFlow: "row nowrap",
                lineHeight: "1em",
            },
            "& dt": {
                flexBasis: "40%",
                fontWeight: '400',
            },
            "& dd": {
                flexBasis: "60%",
                marginLeft: 0,
            },
        },
    })
);

export default useStyles;
