import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginTop: "16px",
            marginBottom: "8px",
            "&:not(:last-child)": {
                marginRight: theme.spacing(1),
            },
            "& input": {
                fontSize: theme.typography.pxToRem(11),
                outline: "5px",
            },
            "& .MuiAutocomplete-input": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(13),
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "0.39rem 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 10px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px !important",
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                padding: 0,
            },
            "& fieldset": {
                padding: "0.75em",
            },
            "& fieldset > legend": {
                maxWidth: "auto",
                visibility: "visible",
                fontSize: "0.70rem",
                lineHeight: "1em",
                '& span': {
                    background: theme.palette.common.white,
                }
            },
            "& label": {
                paddingLeft: "5px",
                paddingRight: "5px",
                background: theme.palette.common.white,
            },
        }
    })
);

export default useStyles;
