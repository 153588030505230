import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        muiStepperRoot: {
            background: "transparent",
        },
        button: {
            margin: theme.spacing(0, 1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        stepLabel: {
            color: theme.palette.common.black,
            "&.MuiStepLabel-active, &.MuiStepLabel-completed": {
                fontWeight: 300,
            },
        },
        stepLabelActive: {
            color: theme.palette.common.black,
        },
        disabled: {
            opacity: 0.5
        }
    })
);

export const useQontoStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: 22,
            alignItems: "center",
        },
        circle: {
            width: 20,
            height: 20,
            borderRadius: "50%",
            border: `3px solid ${theme.palette.secondary.main}`,
            backgroundColor: "transparent",
        },
        active: {
            backgroundColor: theme.palette.secondary.main,
        },
        completed: {
            backgroundColor: theme.palette.secondary.main,
        },
    })
);

export default useStyles;
