import { createSelector } from "reselect";
import { ILayoutModuleState, ILayoutState } from "./reducer";

export function selectLayoutState(state: ILayoutModuleState): ILayoutState {
    return state.layoutState;
}

export const selectSplitView = createSelector(
    selectLayoutState,
    (state) => state.splitView
);
export const selectDrawer = createSelector(
    selectLayoutState,
    (state) => state.drawer
);
export const selectDocumentView = createSelector(
    selectLayoutState,
    (state) => state.documentView
);
export const selectIsDocumentViewActive = createSelector(
    selectDocumentView,
    (_, documentViewFromParam) => documentViewFromParam,
    (documentView, documentViewFromParam) => documentView === documentViewFromParam
);
