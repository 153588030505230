import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultViewerPanelSize } from "../../pages/ViewerPage/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            height: `${defaultViewerPanelSize}px`,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(0, 1),
            color: theme.palette.text.secondary,
            zIndex: 99
        },
        button: {
            color: theme.palette.text.secondary,
            margin: theme.spacing(0, 0.8),
            padding: theme.spacing(0.5, 0.5),
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.34)",
            },
        },
    })
);

export default useStyles;
