import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: "100%",
            overflow: "auto",
            padding: theme.spacing(1, 0),
            position: "relative",
        },
        listRoot: {
            padding: 0,
        },
        listItemRoot: {
            minWidth: "40px",
            padding: 0,
            "&.showDerived": {
                background: theme.palette.primary[100],
            },
        },
        listItemIcon: {
            minWidth: "40px",
        },
        checkboxRoot: {
            padding: 0,
            marginLeft: theme.spacing(1),
        },
        listItemCustom: {
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
            height: "20px",
        },
        listItemValue: {
            minWidth: "90px",
            flexBasis: "30%",
        },
        listItemDescription: {
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: theme.spacing(0.3),
            marginLeft: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                marginLeft: theme.spacing(2),
            },
        },
        listItemDerived: {
            background: theme.palette.primary[200],
        },
        listItemPopover: {
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
        },
        positionName: {
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        loading: {
            position: "absolute",
            display: "flex",
            flexFlow: "row nowrap",
            flex: 1,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
        emptyBox: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& p": {
                marginTop: theme.spacing(1),
            },
        },
    })
);

export default useStyles;
