import { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";

const dataManagementApi = new ApiClient("/DataManagement", config.api.baseUrl);

/**
 * Upload file
 * @param nodeId
 * @param data
 */
export const uploadFiles = (nodeId: string, data: any): Promise<any> =>
    dataManagementApi
        .post(`/upload/${nodeId}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response: AxiosResponse<any>) => {
            return Promise.resolve(response.data);
        });

/**
 * Rename file
 * @param nodeId
 * @param newName
 */
export const renameFile = (nodeId: string, newName: string): Promise<any> =>
    dataManagementApi.get(`/rename/${nodeId}?newName=${newName}`).then((response: AxiosResponse<any>) => {
        return Promise.resolve(response.data);
    });

/**
 * Delete file
 * @param nodeId
 */
export const deleteFile = (nodeId: string): Promise<any> =>
    dataManagementApi.get(`/Delete/${nodeId}`).then((response: AxiosResponse<any>) => {
        return Promise.resolve(response.data);
    });

/**
 * Get thumbnail of file
 * @param lastVersionNodeId
 * @param height
 * @param token
 * @param width
 * @param height
 */
export const thumbnail = (
    lastVersionNodeId: string,
    token: string,
    width: number = 200,
    height: number = 200
): Promise<any> =>
    dataManagementApi
        .get(`/Thumbnail/${lastVersionNodeId}?width=${width}&height=${height}&x-access-token=${token}`, {
            responseType: "blob",
        })
        .then((response: AxiosResponse<any>) => {
            return Promise.resolve(response);
        });

/**
 * Get issues count
 * @param nodeId
 * @return issues count
 */
export const getIssuesCount = (nodeId: string): Promise<number> =>
    dataManagementApi
        .get(`/Issues/${nodeId}/Count`, {
            cache: {
                maxAge: 5 * 60 * 1000, // 2 min instead of 15 min
                exclude: { query: false },
            },
        } as AxiosRequestConfig)
        .then((response: AxiosResponse<any>) => {
            return Promise.resolve(response.data);
        });
