import { defineMessages } from "react-intl";

export default defineMessages({
    tooltipHasIssues: {
        id: "components.documentIssues.tooltipHasIssues",
        defaultMessage: "Has issues",
    },
    tooltipNoIssues: {
        id: "components.documentIssues.tooltipNoIssues",
        defaultMessage: "No issues",
    },
});
