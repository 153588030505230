import { defineMessages } from "react-intl";

export default defineMessages({
    export: {
        id: "components.mapping.dialogs.graphicsDialogWrapper.export",
        defaultMessage: "Export graphics",
    },
    import: {
        id: "components.mapping.dialogs.graphicsDialogWrapper.import",
        defaultMessage: "Import graphics",
    },
    showCatalog: {
        id: "components.mapping.dialogs.graphicsDialogWrapper.showCatalog",
        defaultMessage: "Show graphics catalogue",
    },
    catalogue: {
        id: "components.mapping.dialogs.graphicsDialogWrapper.catalogue",
        defaultMessage: "Graphics Catalogue",
    },
    new: {
        id: "components.mapping.dialogs.graphicsDialogWrapper.new",
        defaultMessage: "Create new graphics",
    },
    select: {
        id: "components.mapping.dialogs.graphicsDialogWrapper.select",
        defaultMessage: "Add graphics to position",
    },
});
