import { IMappingMaterial } from "../material/types";
import { IMappingWork } from "../work/types";
import { key } from "ionicons/icons";
import { IMappingEquipment } from "../equipment/types";
import { IMappingElements } from "../mapping/types";

/**
 * Elements positions simple
 */
export interface IElementsPositionSimple {
    positionID: number; // Position identifier.
    parentPositionID: number; // Parent position identifier.
    parent?: IElementsPosition; // Parent position entity.
    code?: string; // Position code.
    codeSuffix?: string; // Position code suffix.
    name?: string; // Position name.
    description?: string; // Position description.
    shortText?: string; // Position short text - explanation.
    longText?: string; // Position long text.
    stlbBauCode?: string; // Stlb-BAU code.
    din276Structure?: string; // DIN276 Structure.
    omniclass?: string; // Omniclass.
    unitPrice?: number; // Unit price, the fixed one for manual position.
    isInstantiable: boolean; // Position is instantiable and mappable.
    isCustom: boolean; // Position is created by user.
    fixedQuantity?: number; // Fixed quantity
    type?: PositionType; // Position is manual
    isFromEbkpCatalogue: boolean; // Position is from ebkp catalogue.
    // optional - from flatten functions
    level?: number;

    childPositions?: IElementsPosition[]; // Child positions.
    derivedPositions?: IElementsPosition[]; // Derived positions.
    mappingStatus: ElementCategoryMappingStatus;
    priceSource?: PositionPriceSource;
    price?: IPrice;
    referenceUnitID: number; // Reference Unit ID.
    referenceUnit: string; // Reference Unit.

}

/**
 * Elements positions
 */
export interface IElementsPosition extends IElementsPositionSimple {
    childPositions?: IElementsPosition[]; // Child positions.
    derivedPositions?: IElementsPosition[]; // Derived positions.
    mappingStatus: ElementCategoryMappingStatus;
    priceSource?: PositionPriceSource;
    price?: IPrice;
    referenceUnitID: number; // Reference Unit ID.
    referenceUnit: string; // Reference Unit.
}
export interface ElementCategoryMappingStatusItem {
    passed: boolean;
    details?: string;
}
export interface ElementCategoryMappingStatus {
    status: ElementCategoryMappingStatusType;
    element: ElementCategoryMappingStatusItem;
    attributes: ElementCategoryMappingStatusItem;
    material: ElementCategoryMappingStatusItem;
    derivedCategories: ElementCategoryMappingStatusItem;
    subcontractor: ElementCategoryMappingStatusItem;
    manual: ElementCategoryMappingStatusItem;
}

export enum ElementCategoryMappingStatusType {
    NotCalculable = "not-calculable",
    EverythingOk = "everything-ok",
    OneError = "one-error",
    MultipleErrors = "multiple-errors",
}

export interface IDeletePositionsResponse {
    entitiesToDelete: IEntityToDelete[];
    deleted: boolean;
    deleteRequest: string;
}

export interface IEntityToDelete {
    entityID: number;
    entityCode: string;
    deleted: boolean;
    message: string;
    status: DeleteResponseStatus; // todo
    dependantEntities: {[key: string]: number[]}
}

export enum DeleteResponseStatus {
    ENTITY_NOT_FOUND = "EntityNotFound",
    ENTITY_NOT_CUSTOM = "EntityNotCustom",
    ENTITY_HAS_DEPENDENCIES = "EntityHasDepenedencies",
    ENTITY_DELETED = "EntityDeleted",
}

export type IIndexedPositions = {[key: number]: IElementsPosition};

/**
 * Elements positions for specific project
 */
export interface IProjectElementsPositionsResponseData {}

/**
 * Create new elements position
 */
export interface ICreateElementsPosition {
    parentPositionID: number;
    codeSuffix?: string;
    name?: string;
    description?: string;
    shortText?: string; // position short text - explanation
    longText?: string; // position long text
    referenceUnitID?: number;
    unitPrice?: number;
    stlbBauCode?: string;
    din276Structure?: string;
    omniclass?: string;
}

/**
 * Update elements position full
 */
export interface IUpdateElementPositionFull extends ICreateElementsPosition {
    positionID: number;
}

/**
 * Derived positions
 */
export interface IDerivedPosition {
    derivedPositionID: number;
    position: IElementsPosition;
    derivedPositionMappingType: DerivedPositionMappingType;
    conditionalRule?: IMappingElements;
}

/**
 * Attached work
 */
export interface IAttachedWork {
    attachedWorkID: number;
    work: IMappingWork;
    kMenge?: number; // Komponenten Menge - Number of pcs per unit.
    epPerMenge?: number; // EP in relation to menge.
}

/**
 * Attached equipment
 */
export interface IAttachedEquipment {
    attachedEquipmentID: number;
    equipment: IMappingEquipment;
    kMenge?: number; // Komponenten Menge - Number of pcs per unit.
    epPerMenge?: number; // EP in relation to menge.
}

/**
 * Attached material
 */
export interface IAttachedMaterial {
    attachedMaterialID: number;
    materialType: MaterialType;
    material: IMappingMaterial;
    kMenge?: number; // Komponenten Menge - Number of pcs per unit.
    epPerMenge?: number; // EP in relation to menge.
}

export interface IPrice {
    value?: number;
    surcharge?: number;
    total?: number;
}


export enum DerivedPositionMappingType {
    ONE_UNIT = "OneUnit",
    EQUAL_UNITS = "EqualUnits",
    MAPPING = "Mapping",
    FREE_FORM = "FreeForm"
}

export enum UnitPriceType {
    MODEL = "Model",
    SUBCONTRACTOR = "Subcontractor",
}

export enum MaterialType {
    PHYSICAL = "physical",
    ADDITIONAL = "additional",
}

export enum PositionType {
    MODEL = "model",
    MANUAL = "manual",
    DERIVED = "derived",
}

export enum PositionPriceSource {
    MODEL = "Model",
    SUBCONTRACTOR = "Subcontractor",
}

/**
 * Update attached material
 */
export interface IUpdateAttachedMaterialRequestData {
    liPrice?: number;
    kMenge?: number;
}

/**
 * Update attached work
 */
export interface IUpdateAttachedWorkRequestData {
    liPrice?: number;
    kMenge?: number;
}

/**
 * Update attached equipment
 */
export interface IUpdateAttachedEquipmentRequestData {
    liPrice?: number;
    kMenge?: number;
}

/**
 * Update position basics
 */
export interface IUpdatePositionBasicsRequestData {
    type?: PositionType; // Position type
    referenceUnitID?: number; // Reference Unit identifier.
    price?: IPrice;
    priceSource?: PositionPriceSource;
    fixedQuantity?: number;
    clearFixedQuantity?: boolean;
    parentPositionID?: number;
    codeSuffix?: string;
}

/**
 * Update manual position
 */
export interface IUpdateManualPositionRequestData {
    value?: number;
}

export interface IManualPosition {
    value?: number; // Value
    unitPrice?: number; // Calculated unit price.
}

/**
 * Position form configuration
 */
export interface IPositionFormConfiguration {
    allowedReferenceUnits?: IAllowedReferenceUnit[];
}

export interface IAllowedReferenceUnit {
    attributeTypeID: number;
    dataType: string; // Data types for project / element attributes.
    unit?: string;
    name?: string;
    createdDate: string;
    createdBy: number;
    modifiedDate?: string;
    modifiedBy?: number;
}
