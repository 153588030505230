import MuiQueryBuilder from "@iolabs/mui-querybuilder";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { saveMappingProjectElements } from "../../../packages/Api/data/mapping/client";
import { IMappingElements } from "../../../packages/Api/data/mapping/types";
import {
    onMappingPositions,
    setElementQuery,
    useDetailPosition,
    useMappingQuery, useMappingQueryAttributes,
    useMappingQueryLoading, useMappingQueryOperators, useMappingQueryPsets,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import Icon from "../../Icon/Icon";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { getElements } from "../StepContentWrapper/utils";
import useStyles from "./styles";
import { IQuery, IQueryCombinator, IQueryOperator } from "../ElementQueryBuilder/type";
import { Element } from "ionicons/dist/types/stencil-public-runtime";
import ElementQueryBuilder from "../ElementQueryBuilder/ElementQueryBuilder";

interface IStepElementQueryBuilderProps {}

const StepElementQueryBuilder: React.FC<IStepElementQueryBuilderProps> = ({}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    // query
    const loading: boolean = useMappingQueryLoading();
    const query: IQuery | undefined = useMappingQuery();
    const autocompletePsets: string[] = useMappingQueryPsets();
    const autocompleteAttributes: string[] = useMappingQueryAttributes();
    const operators: IQueryOperator[] | null = useMappingQueryOperators();

    const [saving, setSaving] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [validQuery, setValidQuery] = useState<boolean>(false);

    const handleSave = async () => {
        if (query) {
            setSaving(true);
            const newElements: IMappingElements = getElements(query);

            await saveMappingProjectElements(
                keycloak?.token as string,
                activeProject?.projectID as number,
                detailPosition?.positionID as number,
                newElements
            )
                .then(() => {
                    setSaved(true);
                    dispatch(
                        onMappingPositions({
                            token: keycloak?.token as string,
                        })
                    );
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
    };

    useEffect(() => {
        if (saved) {
            setTimeout(() => {
                setSaved(false);
            }, 3000);
        }
    }, [saved]);

    const handleChangeQuery = (query, valid) => {
        dispatch(setElementQuery({ query: query }));
        setValidQuery(
            query?.rules?.length === 0 ? false : MuiQueryBuilder.isQueryValid(query)
        );
    }

    return (
        <>
            <ElementQueryBuilder
                loading={loading}
                query={query}
                operators={operators}
                autocompletePsets={autocompletePsets}
                autocompleteAttributes={autocompleteAttributes}
                onQueryChange={handleChangeQuery}
            />
            <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="medium"
                    disabled={!validQuery || saving || saved}
                    onClick={handleSave}
                >
                    {saved ? "Saved" : saving ? "Saving ..." : "Save"}
                </Button>
            </Box>
        </>
    );
};

export default StepElementQueryBuilder;