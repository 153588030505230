import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useIssuesStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            "& > div": {
                height: "100%",
                "& > div": {
                    display: "flex",
                    flexFlow: "column nowrap",
                    height: "100%",
                },
            },
        },
        title: {
            ...theme.typography.h3,
            position: "relative",
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        content: {
            flex: 1,
            overflowY: "auto",
            padding: theme.spacing(2),
            maxHeight: "87%",
            height: "87%",
        },
        contentList: {
            padding: `${theme.spacing(2)} 0`,
        },
        actions: {
            padding: theme.spacing(1),
        },
        actionsList: {
            justifyContent: "center",
        },
        titleBack: {
            marginLeft: theme.spacing(5),
        },
        backButton: {
            position: "absolute",
            top: "0.8rem",
            left: "1rem",
        },
        box: {
            display: "flex",
            flexFlow: "column nowrap",
            flex: 1,
        },
        listSubheader: {
            backgroundColor: theme.palette.grey["100"],
            lineHeight: "30px",
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        datepicker: {
            width: "100%",
            "& .MuiIconButton-root": {
                padding: "6px",
            },
            "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "7px",
            },
        },
        checkbox: {
            fontSize: ".9rem",
        },
        status: {
            paddingRight: "6px",
            borderLeftWidth: "3px",
            borderLeftStyle: "solid",
            height: "20px",
            maxHeight: "20px",
        },
        statusDraft: {
            borderLeftColor: theme.palette.grey["500"],
        },
        statusOpen: {
            borderLeftColor: theme.palette.success.main,
        },
        statusAnswered: {
            borderLeftColor: theme.palette.warning.main,
        },
        statusClosed: {
            borderLeftColor: theme.palette.error.main,
        },
        statusVoid: {
            borderLeftColor: theme.palette.grey["100"],
        },
        boxIssue: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            cursor: "pointer",
            "& h2": {
                fontSize: "1.1rem",
                margin: `${theme.spacing(0.4)} 0`,
            },
            "& p": {
                color: theme.palette.grey["500"],
                fontSize: ".9rem",
                padding: 0,
                margin: 0,
            },
        },
        boxStatus: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            flex: 1,
        },
        highlight: {
            color: theme.palette.primary.main,
        },
        field: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            "& .MuiOutlinedInput-inputMarginDense": {
                paddingTop: "12px",
                paddingBottom: "12px",
            },
            "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
                paddingTop: "4px",
                paddingBottom: "4px",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.text.primary,
            },
            "& input, & .MuiSelect-root": {
                ...theme.typography.body2,
                color: theme.palette.text.primary,
            },
            "& textarea": {
                ...theme.typography.body2,
                color: theme.palette.text.primary,
            },
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.grey[400],
            },
        },
        skeletonBox: {
            width: "100%",
            "& div, & span": {
                borderRadius: theme.spacing(0.3),
                margin: "1rem 0",
                "&::before": {
                    display: "none",
                },
            },
        },
        skeletonBoxIssue: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            "& span": {
                borderRadius: theme.spacing(0.3),
                marginBottom: "0.4rem",
            },
        },
        noIssue: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
        issuesTabPanel: {
            position: "relative",
        },
        statusDescription: {
            fontSize: "0.8rem",
            paddingLeft: "10px",
        },
        selectStatuses: {
            display: "flex",
            flexFlow: "column wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
        },
        markupPreview: {
            maxWidth: "200px",
            maxHeight: "200px",
        },
    })
);

export default useIssuesStyles;
