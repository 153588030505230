import { StructureTreeDelegate } from "./StructureTreeDelegate";

export class StructureBrowserPanel extends Autodesk.Viewing.UI.DockingPanel {

    private viewer: Autodesk.Viewing.Viewer3D;
    private titleBar?: HTMLElement;
    private searchInput?: HTMLInputElement;
    private searchBox?: HTMLElement;
    private closeButton?: HTMLElement;
    // @ts-ignore
    private tree: Autodesk.Viewing.UI.Tree;
    private treeDelegate?: StructureTreeDelegate;
    private options: any;

    constructor(viewer: Autodesk.Viewing.Viewer3D, container, id, title, options) {
        super(container, id, title, options);
        this.viewer = viewer;
        this.options = options;
        this.initializeContent();
    }

    initialize() {
    }

    initializeContent() {

        this.container.style.top = "100px";
        this.container.style.left = "100px";
        this.container.style.resize = "auto";
        this.container.classList.add('model-structure-panel');

        // title bar
        this.titleBar = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.titleBar);

        // close button
        this.closeButton = this.createCloseButton();
        this.container.appendChild(this.closeButton);

        // search
        // @ts-ignore
        this.searchBox = new Autodesk.Viewing.UI.Searchbox(
            this.container.id + 'ViewerModelStructurePanel' + '-Searchbox',
            this.container,
            {
                excludeRoot: this.options.excludeRoot,
                searchFunction: this.filterIds.bind(this)
            });

        this.searchBox?.addEventListener("search-selected", (event) => {
            this.treeDelegate?.onIndexedNodeClick(event.id);
        })

        // @ts-ignore
        this.container.appendChild(this.searchBox.container);

        // allow move
        this.initializeMoveHandlers(this.titleBar);

        // the main content area
        this.createScrollContainer({} as Autodesk.Viewing.UI.ScrollContainerOptions);

        // footer
        this.container.appendChild(this.createFooter());

        this.treeDelegate = new StructureTreeDelegate(this.viewer, this.options.tree, this.scrollContainer)
        // @ts-ignore
        this.tree = new Autodesk.Viewing.UI.Tree(this.treeDelegate, this.options.tree, this.scrollContainer, {
            startCollapsed: true
        });


        this.tree.setAllCollapsed(true);

        (this.tree.getRootContainer() as any as HTMLElement).classList.add('docking-panel-container-gradient');
    }

    filterIds(query) {
        return [
            {
                ids: this.treeDelegate ? this.treeDelegate.filterIds(query) : [],
                delegate: this.treeDelegate
            }
        ]
    }

}
