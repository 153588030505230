import { defineMessages } from "react-intl";

export default defineMessages({
    notFound: {
        id: "pages.notFoundPage.notFound",
        defaultMessage: "Page not found",
    },
    homepage: {
        id: "pages.notFoundPage.homepage",
        defaultMessage: "Back to homepage",
    },
});
