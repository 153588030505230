import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            zIndex: 0,
            height: "100%",
            "& #print-button .material-icons": {
                "&::before": {
                    content: "'print'",
                },
            },
            "& #polygon-tool-button .adsk-button-icon": {
                "&::before": {
                    content: "'PG'",
                    // fontFamily: "Material Icons",
                    // content: "'face'",
                },
            },
            "& #polyline-tool-button .adsk-button-icon": {
                "&::before": {
                    content: "'PL'",
                },
            },
            "& #move-tool-button .adsk-button-icon": {
                "&::before": {
                    content: "'MV'",
                },
            },
            "& #polygon-edit-tool-button .adsk-button-icon": {
                "&::before": {
                    content: "'PE'",
                },
            },
            "& #insert-symbol-tool-button .adsk-button-icon": {
                "&::before": {
                    content: "'IS'",
                },
            },
            "& #copy-tool-button .adsk-button-icon": {
                "&::before": {
                    content: "'CP'",
                },
            },
            "& #bulkIsolations-isolate-kg .adsk-button-icon": {
                "&::before": {
                    content: "'A'",
                },
            },
            "& #bulkIsolations-isolate-kg.active .adsk-button-icon": {
                "&::before": {
                    content: "'B'",
                },
            },
            "& #bulkIsolations-uncategorized .adsk-button-icon": {
                "&::before": {
                    content: "'U'",
                },
            },
            "& #bulkIsolations-contains-unknown-attribute .adsk-button-icon": {
                "&::before": {
                    content: "'X'",
                },
            },
            "& #MarkupsGuiToolbar, & #MarkupsGuiEditMarkup": {
                "& .adsk-button-icon": {
                    fontSize: "21px",
                },
                "& .adsk-button-arrow": {
                    left: "unset",
                    right: "-5px",
                    bottom: "-5px",
                    top: "unset",
                    "& .adsk-button-icon": {
                        fontSize: "14px",
                    },
                },
            },
            "& #MarkupsGuiControlbar": {
                "& .adsk-button-icon": {
                    fontSize: "19px",
                },
                "& .text-btn": {
                    width: "auto",
                    fontFamily: "Heebo",
                },
            },
            "& .adsk-input": {
                float: "left",
                verticalAlign: "middle",
                height: "50px",
                "& input": {
                    color: "black",
                    margin: "2px",
                    height: "39px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                },
            },
        },
        box: {
            position: "relative",
            height: "100%",
        },
        skeleton: {
            height: "100%",
        },
        hiddenControls: {
            "& .adsk-viewing-viewer .adsk-toolbar": {
                display: "none !important",
            },
        },
    })
);

export default useStyles;
