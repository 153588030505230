import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Badge, IconButton, ListItemIcon, MenuItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import useStyles from "./styles";

interface IHeaderNotifications {
    isMobile?: boolean;
}

const HeaderNotifications: React.FC<IHeaderNotifications> = ({ isMobile }) => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();

    // translations
    const intl = useIntl();
    const transNotifications = intl.formatMessage({
        ...messages.notifications,
    });

    return isMobile ? (
        <MenuItem onClick={() => setGlobalDialogOpen(true)}>
            <ListItemIcon className={classes.listItemIcon}>
                <Badge badgeContent={2} color="secondary">
                    <NotificationsNoneIcon fontSize="small" />
                </Badge>
            </ListItemIcon>
            <Typography variant="inherit">{transNotifications}</Typography>
        </MenuItem>
    ) : (
        <IconButton
            aria-label="Notification"
            className={clsx(classes.responsiveButton, classes.iconButton)}
            onClick={() => setGlobalDialogOpen(true)}
        >
            <Badge badgeContent={2} color="secondary">
                <NotificationsNoneIcon fontSize="small" />
            </Badge>
        </IconButton>
    );
};

export default HeaderNotifications;
