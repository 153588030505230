import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    IconButton,
    InputAdornment,
    ListItemIcon,
    MenuItem,
    Popover,
    TextField,
    Tooltip,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";
import messages from "./messages";
import useStyles from "./styles";
import Icon from "../../Icon/Icon";
import copy from "clipboard-copy";
import { useDispatch } from "react-redux";

interface IHeaderNotifications {
    isMobile?: boolean;
}

const HeaderShare: React.FC<IHeaderNotifications> = ({ isMobile }) => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const openPopover = Boolean(anchorEl);

    const id = openPopover ? "header-share-popover" : undefined;

    const location = window.location.href;

    const [qrSize, setQrSize] = React.useState<number>(200);

    // translations
    const intl = useIntl();
    const transShare = intl.formatMessage({...messages.share});
    const transCopyToClipboard = intl.formatMessage({...messages.copyToClipboard});
    const transCopiedToClipboard = intl.formatMessage({...messages.copiedToClipboard});

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleCopyToClipboard = async () => {
        await copy(location);
        const notification: INotification = {
            variant: "success",
            message: transCopiedToClipboard,
        };
        dispatch(addNotification({ notification }));
    }
    const handleQrClick = () => {
        if (qrSize == 200) {
            setQrSize(500);
        }
        else {

            setQrSize(200);
        }
    }

    return (
        <>
            {
                isMobile ? (
                    <MenuItem onClick={handleOpenPopover}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <Icon name={"share-alt"} size={22} />
                        </ListItemIcon>
                        <Typography variant="inherit">{transShare}</Typography>
                    </MenuItem>
                ) : (
                    <IconButton
                        aria-label="Share"
                        className={clsx(classes.responsiveButton, classes.iconButton)}
                        onClick={handleOpenPopover}
                    >
                        <Icon name={"share-alt"} size={22} />
                    </IconButton>
                )
            }
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {openPopover && (
                    <Box className={classes.popoverContent}>
                        <QRCode
                            size={qrSize}
                            value={location}
                            onClick={handleQrClick}
                        />
                        <Tooltip title={transCopyToClipboard}>
                            <TextField
                                value={location}
                                aria-readonly={true}
                                variant="outlined"
                                className={classes.textField}
                                margin="normal"
                                onClick={handleCopyToClipboard}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        <Icon name={"copy"} size={20} />
                                    </InputAdornment>,
                                }}
                            />
                        </Tooltip>
                    </Box>
                )}
            </Popover>
        </>
    )
    ;
};

export default HeaderShare;
