import { DispatchAction } from "@iolabs/redux-utils";
import { IonRouterLink } from "@ionic/react";
import { Box, IconButton } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router";
import { Path } from "../../pages/PageRouter";
import { onChangeTab, onCreateDone, onIssueEditReset, useIssueCreate } from "../../redux/issue";
import { IIssueCreateState } from "../../redux/issue/reducer";
import Icon from "../Icon/Icon";
import { IIssueTab } from "../Issues/type";
import VersionSelectorWrapper from "../VersionSelectorWrapper/VersionSelectorWrapper";
import useStyles from "./styles";

interface IIssuePanelProps {
    accountUrn: string;
    projectUrn: string;
    nodeId: string;
    fileId: string;
    fileUrn: string;
    handleChangeUrn: (urn: string) => void;
}

const IssuePanel: React.FC<IIssuePanelProps> = ({
    accountUrn,
    projectUrn,
    nodeId,
    fileId,
    fileUrn,
    handleChangeUrn,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const issueCreate: IIssueCreateState = useIssueCreate();

    const handleClose = () => {
        // reset pushpin list to default
        dispatch(onChangeTab({ issueTab: IIssueTab.List }));
        dispatch(onIssueEditReset());

        // reset issue viewer to default
        // dispatch(onIssueViewerReset());

        // reset issue creating mode if active
        if (issueCreate.isModeActive) {
            dispatch(onCreateDone());
        }
    };

    return (
        <Box className={classes.box}>
            <div>
                <VersionSelectorWrapper fileId={fileId} handleChangeUrn={handleChangeUrn} isIssuesPanel />
            </div>
            <div>
                <IonRouterLink
                    routerLink={`${generatePath(Path.DOCUMENTS_WITH_VIEWER, {
                        accountUrn,
                        projectUrn,
                        nodeId,
                        urn: fileUrn,
                        fileId,
                    })}`}
                    routerDirection="back"
                    onClick={handleClose}
                >
                    <IconButton aria-label="go back" className={classes.button}>
                        <Icon name="times" size={20} />
                    </IconButton>
                </IonRouterLink>
            </div>
        </Box>
    );
};

export default IssuePanel;
