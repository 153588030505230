import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { Order } from "../../../utils/DataTable";
import { Data, HeadCell } from "../../DocumentView/DocumentViewWrapper/DocumentViewWrapper";
import useDataTableStyles from "../DataTable/styles";

interface IDataTableHeadProps {
    classes: ReturnType<typeof useDataTableStyles>;
    headCells: HeadCell[];
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const DataTableHead: React.FC<IDataTableHeadProps> = ({
    classes,
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
}) => {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableHeadRow}>
                <TableCell padding="checkbox" className={classes.tableHeadCell}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all rows" }}
                        size="small"
                    />
                </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding="none"
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeadCell}
                        colSpan={index === 0 ? 2 : 1}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.tableHeadLabel}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default DataTableHead;
