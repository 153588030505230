import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultMappingPanelSize } from "../../../pages/MappingManagerPage/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            width: "100%",
            height: "100%",
        },
        label: {
            fontSize: "1em"
        }
    })
);

export default useStyles;
