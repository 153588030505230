import { DispatchAction } from "@iolabs/redux-utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { IonRouterLink } from "@ionic/react";
import { Box, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router";
import { Path } from "../../pages/PageRouter";
import { IPageTool } from "../../pages/type";
import { SplitViewType } from "../../pages/ViewerPage/ViewerPage";
import { onIssueViewerReset } from "../../redux/issue";
import Icon from "../Icon/Icon";
import VersionSelectorWrapper from "../VersionSelectorWrapper/VersionSelectorWrapper";
import useStyles from "./styles";
import { useActiveViewables } from "../../redux/viewer";

interface IViewerPanelProps {
    accountUrn: string;
    projectUrn: string;
    nodeId: string;
    fileId: string;
    fileUrn: string;
    splitView: SplitViewType;
    handleChangeSplitView: (splitViewType: SplitViewType) => void;
    handleChangeUrn: (urn: string) => void;
    handleChangeVersionId: (versionId: string) => void;
}

const ViewerPanel: React.FC<IViewerPanelProps> = ({
    accountUrn,
    projectUrn,
    nodeId,
    fileId,
    fileUrn,
    splitView,
    handleChangeSplitView,
    handleChangeUrn,
    handleChangeVersionId,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const viewables: any[] = useActiveViewables();

    const handleClose = () => {
        // reset issue viewer to default
        dispatch(onIssueViewerReset());
    };

    const viewableTitle = (viewable) => {
        return <Box className={classes.sheetName}>{viewable?.data?.name}</Box>
    }

    return (
        <Box className={classes.box}>
            <Box className={classes.left}>
                {viewables && viewables[0] && viewableTitle(viewables[0])}
                <Box className={classes.versionSwitch}>
                    <VersionSelectorWrapper
                        fileId={fileId}
                        handleChangeUrn={handleChangeUrn}
                        handleChangeVersionId={handleChangeVersionId}
                    />
                </Box>
            </Box>
            <Box className={classes.right}>
                {viewables && viewables[1] ? viewableTitle(viewables[1]) : <div />}
                <div className={classes.icons}>
                    <IonRouterLink
                        // routerLink={issueViewer?.path as string}
                        routerLink={`${generatePath(Path.DOCUMENTS_WITH_VIEWER, {
                            accountUrn,
                            projectUrn,
                            nodeId,
                            urn: fileUrn,
                            fileId,
                            tool: IPageTool?.ISSUES,
                        })}`}
                        routerDirection="forward"
                    >
                        <IconButton aria-label="go to the issues" className={classes.button}>
                            <Icon name="blade" size={20} />
                        </IconButton>
                    </IonRouterLink>

                    <IconButton aria-label="" className={classes.button} onClick={() => setGlobalDialogOpen(true)}>
                        <Icon name="ellipsis-h" size={20} />
                    </IconButton>

                    <IonRouterLink
                        routerLink={`${generatePath(Path.DOCUMENTS, {
                            accountUrn,
                            projectUrn,
                            nodeId,
                        })}`}
                        routerDirection="back"
                        onClick={handleClose}
                    >
                        <IconButton aria-label="go back" className={classes.button}>
                            <Icon name="times" size={20} />
                        </IconButton>
                    </IonRouterLink>
                </div>
            </Box>

        </Box>
    );
};

export default ViewerPanel;
