import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import useStyles from "./styles";

interface IConfirmDialogProps {
    open: boolean;
    handleClose: (event: any) => void;
    handleDelete: (event: any) => void;
    messages: {
        dialogTitle: string;
        confirmMessage: any;
        closeButton: string;
        deleteButton: string;
    };
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({ open, handleClose, handleDelete, messages }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            maxWidth="sm"
            fullWidth
            className={classes.root}
            scroll="paper"
        >
            <DialogTitle disableTypography id="confirm-dialog-title" classes={{ root: classes.dialogTitleRoot }}>
                <Typography variant="h3">{messages?.dialogTitle}</Typography>
            </DialogTitle>
            <DialogContent dividers classes={{ root: classes.dialogContentRoot }}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Typography gutterBottom variant="body2">
                    {messages?.confirmMessage}
                </Typography>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                <Button variant="contained" color="default" size="small" onClick={handleClose}>
                    {messages?.closeButton}
                </Button>
                <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleDelete}
                >
                    {messages?.deleteButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
