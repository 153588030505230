import { defineMessages } from "react-intl";

export default defineMessages({
    ebkph: {
        id: "components.specification.dataTable.ebpkh",
        defaultMessage: "eBKP-H",
    },
    number: {
        id: "components.specification.dataTable.number",
        defaultMessage: "P. Number", // P. Nummer
    },
    name: {
        id: "components.specification.dataTable.name",
        defaultMessage: "Name", // Name
    },
    descriptionShort: {
        id: "components.specification.dataTable.descriptionShort",
        defaultMessage: "Short Description", // Kurzbeschreibung
    },
    source: {
        id: "components.specification.dataTable.source",
        defaultMessage: "Source", // Quelle
    },
    quantity: {
        id: "components.specification.dataTable.quantity",
        defaultMessage: "Quantity", // Menge
    },
    unit: {
        id: "components.specification.dataTable.unit",
        defaultMessage: "Unit", // Einheit
    },
    unitPrice: {
        id: "components.specification.dataTable.unitPrice",
        defaultMessage: "Unit Price", // Einheitpreis
    },
    surcharge: {
        id: "components.specification.dataTable.surcharge",
        defaultMessage: "Surcharge", // Zuschlag
    },
    total: {
        id: "components.specification.dataTable.total",
        defaultMessage: "Total", // Gesamt
    },
    // main table
    description: {
        id: "components.specification.dataTable.description",
        defaultMessage: "Description", // Beschreibung
    },
    gewerk: {
        id: "components.specification.dataTable.gewerk",
        defaultMessage: "Gewerk No.", // Gewerk Nr.
    },
    surchargePercentage: {
        id: "components.specification.dataTable.surchargePercentage",
        defaultMessage: "Surcharge [%]", // Zuschlag [%]
    },
    elementCategoryTypeModel: {
        id: "components.specification.dataTable.elementCategoryTypeModel",
        defaultMessage: "Model",
    },
    elementCategoryTypeManual: {
        id: "components.specification.dataTable.elementCategoryTypeManual",
        defaultMessage: "Manual",
    },
    elementCategoryTypeDerived: {
        id: "components.specification.dataTable.elementCategoryTypeDerived",
        defaultMessage: "Derived",
    },

});
