import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import { TextField } from "mui-rff";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IElementsPosition, IManualPosition, PositionType } from "../../../packages/Api/data/elements/types";
import {
    onManualPosition,
    onUpdateManualPosition,
    onUpdatePositionBasics,
    useDetailPosition,
    useMappingManualPosition,
    useMappingManualPositionLoading,
    useMappingQuery,
    useMappingQueryAttributes,
    useMappingQueryLoading, useMappingQueryOperators,
    useMappingQueryPsets,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import StepElementDerivedPositions, { DerivedPositionMode } from "../StepElementDerivedPositions/StepElementDerivedPositions";
import StepElementQueryBuilder from "../StepElementQueryBuilder/StepElementQueryBuilder";
import { IQuery, IQueryOperator } from "../ElementQueryBuilder/type";
import messages from "./messages";
import useStyles from "./styles";
import { IManualPositionFormErrors, IManualPositionFormValues } from "./type";
import StepElementGraphics from "../StepElementGraphics/StepElementGraphics";
import { Alert } from "@material-ui/lab";
import StepElementCommon from "../StepElementCommon/StepElementCommon";

interface IStepElementProps {
}

const StepElement: React.FC<IStepElementProps> = ({}) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();
    const manualPositionLoading: boolean = useMappingManualPositionLoading();
    const manualPosition: IManualPosition | undefined = useMappingManualPosition();

    const [
        initialManualPositionFormValues,
        setInitialManualPositionFormValues,
    ] = useState<IManualPositionFormValues | null>(null);

    // translations
    const intl = useIntl();
    const transModelPosition = intl.formatMessage({ ...messages.modelPosition });
    const transManualPosition = intl.formatMessage({ ...messages.manualPosition });
    const transPositionCommon = intl.formatMessage({ ...messages.positionCommon });
    const transFieldValue = intl.formatMessage({ ...messages.fieldValue });
    const transFieldReference = intl.formatMessage({ ...messages.fieldReference });
    const transFieldFormula = intl.formatMessage({ ...messages.fieldFormula });
    const transFieldParameter = intl.formatMessage({ ...messages.fieldParameter });
    const transFieldSpecify = intl.formatMessage({ ...messages.fieldSpecify });
    const transValidateValue = intl.formatMessage({ ...messages.validateValue });
    const transUnknownPositionType = intl.formatMessage({ ...messages.unknownPositionType });
    const transSwitchModelPosition = intl.formatMessage({ ...messages.switchModelPosition });
    const transSwitchManualPosition = intl.formatMessage({ ...messages.switchManualPosition });
    const transSwitchDerivedPosition = intl.formatMessage({ ...messages.switchDerivedPosition });

    const validateManualPosition = (values: IManualPositionFormValues): IManualPositionFormErrors | {} => {
        const errors: IManualPositionFormErrors | {} = {};

        if (!values.value) {
            (errors as IManualPositionFormErrors).value = transValidateValue;
        }

        return errors;
    };

    const onSubmitManualPosition = useCallback(async (values: IManualPositionFormValues) => {
        dispatch(
            onUpdateManualPosition({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
                data: {
                    value: values?.value,
                },
            })
        );
    }, []);

    const handleChangePositionType = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            onUpdatePositionBasics({
                token: keycloak?.token as string,
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
                data: {
                    type: event.target.value as PositionType,
                },
            })
        );
    };

    useEffect(() => {
        setInitialManualPositionFormValues({
            value: manualPosition?.value ? +(manualPosition?.value as number).toFixed(2) : 0,
        });
    }, [manualPosition]);

    useEffect(() => {
        dispatch(
            onManualPosition({
                token: keycloak?.token as string,
                positionID: detailPosition?.positionID as number,
            })
        );
    }, [detailPosition]);

    const renderStepContent = () => {
        switch (detailPosition?.type) {
            case PositionType.MANUAL:
                return (
                    <>
                        <Paper className={classes.paper}>
                            <Typography variant="body2">{transManualPosition}</Typography>
                            <Form
                                onSubmit={onSubmitManualPosition}
                                initialValues={initialManualPositionFormValues}
                                validate={validateManualPosition}
                                className={classes.form}
                            >
                                {({ handleSubmit, submitting, pristine }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box className={classes.fieldBox}>
                                            <TextField
                                                required
                                                name="value"
                                                label={transFieldValue}
                                                placeholder={transFieldSpecify}
                                                type="number"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                name="reference"
                                                label={transFieldReference}
                                                placeholder={transFieldSpecify}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                name="formula"
                                                label={transFieldFormula}
                                                placeholder={transFieldSpecify}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                name="parameter"
                                                label={transFieldParameter}
                                                placeholder={transFieldSpecify}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                        <Box mt={1} display="flex" flexDirection="row" justifyContent="center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                size="medium"
                                                disabled={submitting || pristine || manualPositionLoading}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Form>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Typography variant="body2">{transPositionCommon}</Typography>
                            <StepElementCommon />
                        </Paper>
                    </>
                );
            case PositionType.MODEL:
                return (
                    <>
                        <Paper className={classes.paper}>
                            <Typography variant="body2">{transModelPosition}</Typography>
                            <StepElementQueryBuilder />
                        </Paper>
                        <StepElementGraphics />
                        <StepElementDerivedPositions
                            mode={DerivedPositionMode.From}
                        />
                        <Paper className={classes.paper}>
                            <Typography variant="body2">{transPositionCommon}</Typography>
                            <StepElementCommon />
                        </Paper>
                    </>
                );
            case PositionType.DERIVED:
                return (
                    <>
                        <StepElementDerivedPositions
                            mode={DerivedPositionMode.To}
                        />
                        <Paper className={classes.paper}>
                            <Typography variant="body2">{transPositionCommon}</Typography>
                            <StepElementCommon />
                        </Paper>
                    </>
                );
            default:
                return (
                    <>
                        <Alert severity="error">{transUnknownPositionType}</Alert>
                    </>
                )
        }
    }

    return (
        <>
            <Box className={classes.switchBox}>
                <RadioGroup
                    color="primary"
                    value={detailPosition?.type}
                    row
                    onChange={handleChangePositionType}
                    className={classes.positionTypeSelector}
                >
                    <FormControlLabel  value={PositionType.MODEL} control={<Radio size={"small"} />} label={transSwitchModelPosition} />
                    <FormControlLabel  value={PositionType.MANUAL} control={<Radio size={"small"} />} label={transSwitchManualPosition} />
                    <FormControlLabel  value={PositionType.DERIVED} control={<Radio size={"small"} />} label={transSwitchDerivedPosition} />
                </RadioGroup>
            </Box>
            {renderStepContent()}
        </>
    );
};

export default StepElement;
