import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        pushpinBox: {
            position: "absolute",
            zIndex: 10,
            overflow: "visible",
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            touchAction: "none",
        },
        isDragging: {
            pointerEvents: "auto",
            cursor: "inherit !important",
        },
        modeActive: {
            pointerEvents: "auto",
            cursor: "crosshair",
        },
        pushpinPlaced: {
            pointerEvents: "none",
            cursor: "inherit !important",
        },
        pointerEvents: {
            pointerEvents: "auto",
        },
    })
);

export default useStyles;
