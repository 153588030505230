import { DispatchAction } from "@iolabs/redux-utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Box, IconButton } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { onDocumentView, useDocumentView } from "../../../redux/layout";
import { IDocumentView } from "../../DocumentView/type";
import Icon from "../../Icon/Icon";
import useStyles from "./styles";
import UploadButton from "../UploadButton/UploadButton";

const DataTablePanel: React.FC = () => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const dispatch = useDispatch<DispatchAction>();
    const documentView: IDocumentView = useDocumentView();
    const anchorRef = useRef<HTMLDivElement>(null);
    const [openUploadMenu, setOpenUploadMenu] = useState(false);

    const handleToggleUploadMenu = () => {
        setOpenUploadMenu((prevOpen) => !prevOpen);
    };

    const handleUploadMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenUploadMenu(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenUploadMenu(false);
        }
    }

    const handleChangeDocumentView = (event: React.MouseEvent<HTMLElement>, newDocumentView: IDocumentView | null) => {
        if (newDocumentView !== null) {
            dispatch(onDocumentView({ documentView: newDocumentView }));
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.searchBox}>
                <ToggleButtonGroup
                    value={documentView}
                    exclusive
                    onChange={handleChangeDocumentView}
                    aria-label="data table view"
                    className={classes.toggleButtonGroup}
                >
                    <ToggleButton
                        value={IDocumentView.Thumbnail}
                        aria-label="thumbnail"
                        className={classes.toggleButton}
                    >
                        <Icon name="grip-horizontal" size={20} />
                    </ToggleButton>
                    <ToggleButton value={IDocumentView.Table} aria-label="table" className={classes.toggleButton}>
                        <Icon name="grip-lines" size={20} />
                    </ToggleButton>
                </ToggleButtonGroup>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon fontSize="small" />
                    </div>
                    <InputBase
                        placeholder="Suche…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                    />
                </div>
                <UploadButton
                    anchorRef={anchorRef}
                    openUploadMenu={openUploadMenu}
                    handleToggleUploadMenu={handleToggleUploadMenu}
                    handleUploadMenuClose={handleUploadMenuClose}
                    handleListKeyDown={handleListKeyDown}
                />
            </Box>
            <Box className={classes.iconBox}>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="arrow-alt-to-bottom" size={20} />
                </IconButton>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="cloud-download" size={20} />
                </IconButton>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="arrow-from-left" size={20} />
                </IconButton>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="blade" size={20} />
                </IconButton>
                <IconButton aria-label="" className={classes.iconButton} onClick={() => setGlobalDialogOpen(true)}>
                    <Icon name="trash-alt" size={20} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default DataTablePanel;
