import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            "& svg": {
                color: theme.palette.text.secondary,
            },
        },
        popper: {
            zIndex: 10,
        },
        itemIcon: {
            color: theme.palette.common.black,
            minWidth: "35px",
        },
        itemIconSave: {
            "& svg": {
                fill: theme.palette.success.main,
            },
        },
        itemIconRevert: {
            "& svg": {
                fill: theme.palette.primary.main,
            },
        },
        itemIconClose: {
            "& svg": {
                fill: theme.palette.secondary.main,
            },
        },
    })
);
export default useStyles;
