import { defineMessages } from "react-intl";

export default defineMessages({
    fieldFixedQuantity: {
        id: "components.mapping.stepElementCommon.fieldFixedQuantity",
        defaultMessage: "Fixed quantity",
    },
    fieldFixedQuantityPlaceholder: {
        id: "components.mapping.stepElementCommon.fieldFixedQuantityPlaceholder",
        defaultMessage: "Enter value",
    },
    fieldFixedQuantityHelp: {
        id: "components.mapping.stepElementCommon.fieldFixedQuantityHelp",
        defaultMessage: "This quantity will be used in model for this position, if this position will be extracted from model or if the position will be manual",
    },

});
