import { AnyAction } from "redux";
import { ActionTypes, loadProjects, loadSpecialInstancesDone, setProjects } from "./action";
import { getSpecialInstances } from "../../packages/Api/data/instances/client";
import { logger } from "workbox-core/_private";
import { getProjects } from "../../packages/Api/data/tree/client";
import { getProjectList } from "../../packages/Api/data/projects/client";
import { IProjectListResponseData } from "../../packages/Api/data/projects/types";

const onProjectsMiddleware = ({ dispatch, getState }) => (next) => (action: AnyAction) => {
    const resolve = next(action);
    // load special instances
    if (action.type === ActionTypes.LoadSpecialInstances) {
        const { token, projectID, forceReload } = action.payload;
        const state = getState();

        if (!state.projectState.specialInstances || forceReload) {

            getSpecialInstances(token, projectID)
                .then((response) => {
                    dispatch(
                        loadSpecialInstancesDone({
                            response
                        })
                    );
                })
                .catch((error) => {
                    logger.error(error, "unable to load special instances")
                });
        }

    }

    // load projects
    if (action.type === ActionTypes.LoadProjects) {

        const { token, forceReload} = action.payload;

        getProjectList(token, forceReload)
            .then((response: IProjectListResponseData[]) => {
                dispatch(
                    setProjects({ projects: response })
                );
            })
            .catch((error) => {
                logger.error(error, "unable to load projects")
            });

    }

    return resolve;

};

export default [onProjectsMiddleware];
